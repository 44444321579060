export const CREATE_BACKGROUNDS_COLLECTION_REQUEST = 'CREATE_BACKGROUNDS_COLLECTION_REQUEST';
export const CREATE_BACKGROUNDS_COLLECTION_SUCCESS = 'CREATE_BACKGROUNDS_COLLECTION_SUCCESS';
export const CREATE_BACKGROUNDS_COLLECTION_ERROR = 'CREATE_BACKGROUNDS_COLLECTION_ERROR';

export const GET_BACKGROUNDS_COLLECTION_LIST_REQUEST = 'GET_BACKGROUNDS_COLLECTION_LIST_REQUEST';
export const GET_BACKGROUNDS_COLLECTION_LIST_SUCCESS = 'GET_BACKGROUNDS_COLLECTION_LIST_SUCCESS';
export const GET_BACKGROUNDS_COLLECTION_LIST_ERROR = 'GET_BACKGROUNDS_COLLECTION_LIST_ERROR';

export const GET_BACKGROUNDS_COLLECTION_REQUEST = 'GET_BACKGROUNDS_COLLECTION_REQUEST';
export const GET_BACKGROUNDS_COLLECTION_SUCCESS = 'GET_BACKGROUNDS_COLLECTION_SUCCESS';
export const GET_BACKGROUNDS_COLLECTION_ERROR = 'GET_BACKGROUNDS_COLLECTION_ERROR';

export const UPDATE_BACKGROUNDS_COLLECTION_REQUEST = 'UPDATE_BACKGROUNDS_COLLECTION_REQUEST';
export const UPDATE_BACKGROUNDS_COLLECTION_SUCCESS = 'UPDATE_BACKGROUNDS_COLLECTION_SUCCESS';
export const UPDATE_BACKGROUNDS_COLLECTION_ERROR = 'UPDATE_BACKGROUNDS_COLLECTION_ERROR';

export const DELETE_BACKGROUNDS_COLLECTION_REQUEST = 'DELETE_BACKGROUNDS_COLLECTION_REQUEST';
export const DELETE_BACKGROUNDS_COLLECTION_SUCCESS = 'DELETE_BACKGROUNDS_COLLECTION_SUCCESS';
export const DELETE_BACKGROUNDS_COLLECTION_ERROR = 'DELETE_BACKGROUNDS_COLLECTION_ERROR';

export const GET_BACKGROUND_REQUEST = 'GET_BACKGROUND_REQUEST';
export const GET_BACKGROUND_SUCCESS = 'GET_BACKGROUND_SUCCESS';
export const GET_BACKGROUND_ERROR = 'GET_BACKGROUND_ERROR';

export const RESET_BACKGROUND_REQUEST = 'RESET_BACKGROUND_REQUEST';
export const RESET_BACKGROUND_SUCCESS = 'RESET_BACKGROUND_SUCCESS';
