import React from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { updateMyAccountRequest } from '../../actions';

// Components
import AccountForm from './AccountForm';

// Helpers
import { normalizePhone } from '@/utils/formatters';

const Account = () => {
  const dispatch = useDispatch();

  const { account, requesting } = useSelector((state) => state.users);
  const { first_name, last_name, phone, email } = account || {};

  const saveAccount = (values) => dispatch(updateMyAccountRequest(values));

  return (
    <AccountForm
      onSubmit={saveAccount}
      requesting={requesting}
      initialValues={{
        first_name,
        last_name,
        phone: (phone && normalizePhone(phone)) || '',
        email
      }}
    />
  );
};

export default Account;
