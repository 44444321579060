import { Input } from '@/components/Shared/Forms';

const CreditSheet = () => {
  return (
    <div className="form-segment">
      <div className="flex">
        <div className="basis-full">
          <Input required name="name" label="Credit Sheet Name" placeholder="Give your credit sheet a name" />
        </div>
      </div>
    </div>
  );
};

export default CreditSheet;
