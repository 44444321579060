import * as api from '@/utils/http';
const API_URL = import.meta.env.VITE_API_URL;

export const getCollections = (payload) => {
  const endpoint = 'studio-theme-collections';
  const args = { ...payload };

  return api.get(endpoint, args);
};

export const getPhotoDayThemeCollections = () => {
  const endpoint = 'theme-collections';

  return api.get(endpoint);
};

export const getPhotoDayThemeCollectionsByLab = (payload) => {
  const { labId } = payload;

  const endpoint = `labs/${labId}/theme-collections`;

  return api.get(endpoint);
};

export const getAllCustomThemeLabs = (payload) => {
  const endpoint = 'labs';
  const args = { ...payload };

  return api.get(endpoint, args);
};

export const getThemeProducts = (payload) => {
  const { id } = payload;

  const endpoint = `labs/${id}/products`;
  const args = { ...payload };

  return api.get(endpoint, args);
};

export const createCollection = (payload) => {
  const endpoint = 'studio-theme-collections';
  const args = { ...payload };

  return api.post(endpoint, args);
};

export const updateCollection = (payload) => {
  const { collectionId } = payload;

  // This request can get large and doesn't seem to work through
  // the netlify proxy. So we request this one directly to the API -Greg
  const endpoint = `${API_URL}studio-theme-collections/${collectionId}`;
  const args = { ...payload };

  return api.put(endpoint, args);
};

export const getCollection = (payload) => {
  const { collectionId } = payload;

  const endpoint = `studio-theme-collections/${collectionId}`;

  return api.get(endpoint);
};

export const deleteCollection = (payload) => {
  const { collectionId } = payload;

  const endpoint = `/studio-theme-collections/${collectionId}`;

  return api.del(endpoint);
};

export const getPreview = (payload) => {
  const { layoutId } = payload;

  const endpoint = `layouts/${layoutId}/preview`;

  return api.get(endpoint);
};
