import { convertToText } from '@/utils/currency';

export const getPriceSheetId = (props) => {
  const {
    match: {
      params: { pricesheetId }
    }
  } = props;
  return pricesheetId;
};

export const selectInitialValuesFromPriceSheetItem = (pricesheetItem) => {
  const product_ids = (pricesheetItem.price_sheet_item_purchasables || []).map((item) => {
    const { product } = item;

    return {
      id: product.id,
      name: item.name,
      quantity: item.quantity,
      photoday_price_cents: product.photoday_price_cents,
      price_cents: product.price_cents,
      bulk_price_cents: product.bulk_price_cents,
      is_digital_download: product.is_digital_download,
      digital_bundle_enabled: product.digital_bundle_enabled
    };
  });

  const {
    id,
    name,
    description,
    image_url,
    image_filename,
    lab_price_cents,
    has_digital_downloads,
    digital_bundle,
    digital_bundle_pricing_type,
    digital_bundle_max_price_cents,
    digital_bundle_max_qty,
    digital_bundle_tiers
  } = pricesheetItem;

  const photoday_price_cents = pricesheetItem.photoday_price_cents || 0;
  const price_cents = pricesheetItem.price_cents || 0;
  const fees_cents = pricesheetItem.fees_cents || 0;
  const profit_cents = pricesheetItem.profit_cents || 0;
  const base_cost_cents = pricesheetItem.base_cost_cents || 0;
  const base_bulk_cost_cents = pricesheetItem.base_bulk_cost_cents || 0;
  const markup_cents = pricesheetItem.markup_cents || 0;
  const digitalProductsCount = product_ids.filter((product) => product.is_digital_download).length;
  const digitalBundleMaxPriceText = convertToText(digital_bundle_max_price_cents);

  return {
    id,
    name,
    description,
    photoday_price_cents,
    price_cents,
    fees_cents,
    profit_cents,
    lab_price_cents,
    product_ids,
    products_attributes: product_ids,
    image_url,
    image_filename,
    markup_cents,
    base_cost_cents,
    base_bulk_cost_cents,
    digitalProductsCount,
    has_digital_downloads,
    digital_bundle,
    digital_bundle_pricing_type,
    digital_bundle_max_price_cents: digitalBundleMaxPriceText,
    digital_bundle_max_qty,
    digital_bundle_tiers
  };
};

export const selectInitialValuesFromPriceSheet = (pricesheet) => {
  const { id, name, price_sheet_type, lab_id, shipping_type, jobs_count } = pricesheet;
  const markupAttributes = pricesheet.markup || {};

  if (markupAttributes.markup_type === 'fixed_amount' && markupAttributes.fixed_amount_cents) {
    markupAttributes.amount = (markupAttributes.fixed_amount_cents / 100).toFixed(2);
  } else if (markupAttributes.markup_type === 'fixed_percentage' && markupAttributes.fixed_percentage) {
    markupAttributes.amount = markupAttributes.fixed_percentage;
  }

  return {
    id,
    name,
    price_sheet_type,
    lab_id,
    shipping_type,
    jobs_count,
    markup_attributes: markupAttributes
  };
};

export const existingProductsIds = (state) => {
  const { pricesheet } = state?.pricesheets?.entities ?? {};

  if (!pricesheet || typeof pricesheet === 'undefined' || !pricesheet?.price_sheet_items) {
    return [];
  }

  return pricesheet.price_sheet_items
    .filter((price_sheet_item) => price_sheet_item?.price_sheet_item_type === 'product')
    .map(({ price_sheet_item_purchasables }) => price_sheet_item_purchasables[0]?.product?.id);
};

export const labSelector = (state, labId) => {
  const { labs } = state.pricesheets.entities;
  return labs && labs[labId] ? labs[labId] : undefined;
};
