import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Plugins
import moment from 'moment-timezone';
import { Tooltip } from 'react-tippy';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { getOrganization } from '../../Organizations/actions';
import { getShippingSummary } from '../../Storefront/Shipping/actions';
import { getPricesheetRequest, resetPriceSheets } from '../../Storefront/PriceSheets/actions';
import { getJobSubjectsSummary, getInsightsSalesSummary, getTagList, getPeopleList, getPeopleFieldOptions, getPeopleLookupConfig } from '../actions';

// Components
import Header from '../Header';
import TextLoader from '@/components/Shared/ContentLoader/TextLoader';

// Hooks
import { useCopyToClipboard } from '@/hooks';
import { useJobTimeZone } from '@/hooks';

// Images
import ReactImageExports from '@/assets/images/image-exports.svg?react';
import ReactImageSetup from '@/assets/images/image-setup-access.svg?react';
import ReactImageBulkShip from '@/assets/images/image-bulk-ship.svg?react';
import ReactImageMarketing from '@/assets/images/image-marketing.svg?react';
import ReactImageUpload from '@/assets/images/image-upload-photos.svg?react';
import ReactImageStatus from '@/assets/images/image-publish-gallery.svg?react';
import ReactImagePostProcessing from '@/assets/images/image-post-processing.svg?react';
import ReactImageSetupStorefront from '@/assets/images/image-setup-storefront.svg?react';
import ReactImageBackgrounds from '@/assets/images/image-backgrounds-dashboard.svg?react';
import ReactImageYearbook from '@/assets/images/image-yearbook-selection.svg?react';

// Styles
import './style.css';

const SERVICES_ENABLED = import.meta.env.VITE_SERVICES_ENABLED === '1';
const CAPTURE_QR_ENABLED = import.meta.env.VITE_CAPTURE_QR_ENABLED === '1';
const EXPORT_CROP_ENABLED = import.meta.env.VITE_EXPORT_CROP_ENABLED === '1';
const ALLOW_EMAIL_CAMPAIGNS = import.meta.env.VITE_ALLOW_EMAIL_CAMPAIGNS === '1';
const BULK_SHIPPING_ENABLED = import.meta.env.VITE_BULK_SHIPPING_ENABLED === '1';
const KNOCK_OUT_BACKGROUND_ENABLED = import.meta.env.VITE_KNOCK_OUT_BACKGROUND_ENABLED === '1';
const BULK_SHIPPING_SUMMARY_PAGE_ENABLED = import.meta.env.VITE_BULK_SHIPPING_SUMMARY_PAGE_ENABLED === '1';
const CODE_LOOKUP_ENABLED = import.meta.env.VITE_CODE_LOOKUP_ENABLED === '1';
const MY_PHOTODAY_URL = import.meta.env.VITE_MY_PHOTODAY_URL;
const MY_PHOTODAY_ORG_PAGE_URL = import.meta.env.VITE_MY_PHOTODAY_ORG_PAGE_URL;

const Dashboard = ({ match, history }) => {
  const dispatch = useDispatch();
  const [jobTimezone] = useJobTimeZone();
  const [, copyToClipboard] = useCopyToClipboard();

  const {
    params: { jobId }
  } = match;

  const {
    entities,
    user: { roles: userRoles }
  } = useSelector((state) => state.login);
  const studio = Object.values(entities.studio)[0] || {};
  const { labs: studioLabs, feature_flags: studioFeatureFlags, studio_slug: studioSlug } = studio;

  const {
    job,
    tags: { list: tags },
    people: { list: people, lookupConfig },
    insights: { salesSummary }
  } = useSelector((state) => state.jobs);
  const { organization, requesting: organizationRequesting } = useSelector((state) => state.organizations);
  const {
    entities: { pricesheet }
  } = useSelector((state) => state.pricesheets);
  const {
    entities: { shippingSummary }
  } = useSelector((state) => state.shippings);

  const { subjectsSummary } = job;
  const isStudioCaptureQrAllowed = CAPTURE_QR_ENABLED && studioFeatureFlags?.allow_capture_qr ? true : false;

  const [todoTasks, setTodoTasks] = useState([]);

  const checkStepsComplete = (steps) => {
    if (steps.published === true && steps.storefront === true && steps.gallery_access === true && steps.uploaded_photos === true) {
      return true;
    } else {
      return false;
    }
  };

  const studioBackgroundLabs = studioLabs && studioLabs.length ? studioLabs.filter((lab) => lab.allow_backgrounds).map((lab) => lab.name) : [];
  const jobLab = job.meta && job.meta.lab_name ? job.meta.lab_name : null;
  const showBackgrounds = jobLab && studioBackgroundLabs.includes(jobLab);

  const handleTextCopy = (text) => copyToClipboard(text ?? '');

  useEffect(() => {
    if (userRoles.includes('view_insights')) {
      dispatch(getInsightsSalesSummary({ id: jobId }));
    }
  }, []);

  useEffect(() => {
    if (job?.id) {
      // Set ToDo's tasks to complete
      const tasks = [];

      // Backgrounds
      if (KNOCK_OUT_BACKGROUND_ENABLED && showBackgrounds && job.setup_steps.setup_backgrounds === false) {
        tasks.push('backgrounds');
      }

      // Bulk Shipping
      if (BULK_SHIPPING_ENABLED === true && job.access_mode === 'access_per_subject' && pricesheet?.shipping_type?.includes('bulk') && job.bulk_ship === null) {
        tasks.push('bulk-shipping');
      }

      // Exports
      if (
        EXPORT_CROP_ENABLED &&
        job.access_mode === 'access_per_subject' &&
        job.setup_steps &&
        job.setup_steps.uploaded_subjects === true &&
        job.setup_steps.exports_created === false
      ) {
        tasks.push('exports');
      }

      // Knockouts
      if (SERVICES_ENABLED && job.setup_steps && job.setup_steps.knockouts_created !== true) {
        tasks.push('knockouts');
      }

      // Marketing
      if (ALLOW_EMAIL_CAMPAIGNS && job.access_mode === 'access_per_subject') {
        tasks.push('marketing');
      }

      // Yearbook
      if (job.access_mode === 'access_per_subject' && job.setup_steps?.setup_yearbook === false) {
        tasks.push('yearbook');
      }

      setTodoTasks(tasks);

      if (job.access_mode === 'access_per_subject') {
        if (!people.length) {
          dispatch(getPeopleList({ id: jobId, per_page: 10000, order: 'last_name', dir: 'asc' }));
          dispatch(getPeopleFieldOptions({ id: jobId }));
        }
        dispatch(getPeopleLookupConfig({ jobId }));
        dispatch(getJobSubjectsSummary({ id: jobId }));

        if (job.price_sheet_id) {
          dispatch(getPricesheetRequest(job.price_sheet_id));
        }

        if (BULK_SHIPPING_SUMMARY_PAGE_ENABLED && job.has_bulk_shipment) {
          dispatch(getShippingSummary({ jobId }));
        }
      } else {
        if (!tags.length) {
          dispatch(getTagList({ id: jobId }));
        }
      }

      if (!organization.id || organization.id !== job.client_id) {
        dispatch(getOrganization({ organizationId: job.client_id }));
      }
    } else {
      dispatch(resetPriceSheets());
    }
  }, [job?.id]);

  return (
    <>
      <Header history={history} jobId={jobId} title="Dashboard" />

      <main className="container flex">
        <aside className="basis-3/12 md:basis-full job-dashboard__sidebar">
          <header className="flex items-center job-dashboard__header">
            <h2 className="text-headline-sm">About this job</h2>
          </header>
          <article className="panel">
            <h3 className="text-headline-xs flex justify-between">
              Job Settings
              <Link className="text-body-sm" to={`/jobs/${job.id}/settings`}>
                Edit
              </Link>
            </h3>
            <hr className="job-dashboard__divider" />
            <dl>
              <dt>Job name</dt>
              <dd>{job.requesting ? <TextLoader height={20} /> : job.reporting_code ? `${job.name} (${job.reporting_code})` : job.name}</dd>

              {job?.access_mode === 'access_per_subject' && (
                <>
                  {/* Visible for private jobs only */}
                  <dt>Job URL</dt>
                  <dd className="relative">
                    {job.requesting ? (
                      <TextLoader height={20} />
                    ) : (
                      <>
                        <a className="block lowercase pr-8" href={`${MY_PHOTODAY_URL}gallery/${job.gallery_path}`} target="_blank" rel="noopener noreferrer">
                          {MY_PHOTODAY_URL}gallery/{job.gallery_path}
                        </a>
                        <button
                          className="button button--icon top-0.5"
                          name="copy"
                          type="button"
                          onClick={() => handleTextCopy(`${MY_PHOTODAY_URL}gallery/${job.gallery_path}`)}
                        >
                          <i className="icon-copy" />
                        </button>
                      </>
                    )}
                  </dd>
                </>
              )}

              {isStudioCaptureQrAllowed && (
                <>
                  <dt>FaceFind</dt>
                  <dd>{job.requesting ? <TextLoader height={20} /> : job.biometrics_enabled ? 'Enabled' : 'Disabled'}</dd>
                </>
              )}

              <dt>Date of shoot</dt>
              <dd>{job.requesting ? <TextLoader height={20} /> : moment.utc(job.date).format('L')}</dd>

              <dt>Job published date</dt>
              <dd>
                {job.requesting ? (
                  <TextLoader height={20} />
                ) : job.last_published_at && jobTimezone ? (
                  `${moment(job.last_published_at)
                    .tz(jobTimezone ?? '')
                    .format('L [@]h:mm A')} ${moment.tz(jobTimezone ?? '').zoneAbbr()}`
                ) : (
                  'N/A'
                )}
              </dd>

              <dt>Organization</dt>
              <dd>{organizationRequesting ? <TextLoader height={20} /> : organization.name}</dd>

              <dt>Organization Page</dt>
              <dd>
                {organizationRequesting ? (
                  <TextLoader height={20} />
                ) : organization?.org_page_enabled && job?.meta?.client_org_page_enabled ? (
                  'Enabled'
                ) : (
                  'Disabled'
                )}
              </dd>

              {organization?.org_page_enabled && job?.meta?.client_org_page_enabled && (
                <>
                  {/* Visible only when Org page enabled */}
                  <dt>Organization URL</dt>
                  <dd className="relative">
                    {organizationRequesting ? (
                      <TextLoader height={20} />
                    ) : (
                      <>
                        <a
                          className="block lowercase pr-8"
                          href={`${MY_PHOTODAY_ORG_PAGE_URL}${studioSlug}/${organization.org_page_slug}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {MY_PHOTODAY_ORG_PAGE_URL}
                          {studioSlug}/{organization.org_page_slug}
                        </a>
                        <button
                          className="button button--icon top-0.5"
                          name="copy"
                          type="button"
                          onClick={() => handleTextCopy(`${MY_PHOTODAY_ORG_PAGE_URL}${studioSlug}/${organization.org_page_slug}`)}
                        >
                          <i className="icon-copy" />
                        </button>
                      </>
                    )}
                  </dd>
                </>
              )}

              {job.access_mode === 'access_per_subject' && (
                <>
                  <dt>Number of Subjects</dt>
                  <dd>{job.requesting ? <TextLoader height={20} /> : subjectsSummary && subjectsSummary.uploaded >= 0 ? subjectsSummary.uploaded : 'N/A'}</dd>
                </>
              )}

              <dt>Access</dt>
              <dd>
                {job.requesting ? (
                  <TextLoader height={20} />
                ) : (
                  { access_per_subject: 'Private', access_per_job: 'Group', access_public: 'Public' }[job.access_mode] || 'N/A'
                )}
              </dd>

              <dt>Code</dt>
              <dd>
                {job.requesting ? (
                  <TextLoader height={20} />
                ) : (
                  <>
                    {!job.access_mode && 'N/A'}

                    {job.access_mode === 'access_public' && (
                      <>
                        Opt-In: <strong className="uppercase">{job.access_code}</strong>
                      </>
                    )}

                    {job.access_mode === 'access_per_job' && (
                      <>
                        Access/Opt-In: <strong className="uppercase">{job.access_code}</strong>
                      </>
                    )}
                    {job.access_mode === 'access_per_subject' && (
                      <small className="text--normalized">
                        This is a private job that assigns a unique access code to each person.{' '}
                        <span className="sm:hidden">
                          You can view each individual code <Link to={`/jobs/${job.id}/people`}>here</Link>.
                        </span>
                      </small>
                    )}
                  </>
                )}
              </dd>

              {CODE_LOOKUP_ENABLED && job.access_mode === 'access_per_subject' && (
                <>
                  <dt>Gallery Lookup</dt>
                  <dd>{job.requesting ? <TextLoader height={20} /> : lookupConfig?.enabled ? 'Enabled' : 'Disabled'}</dd>
                </>
              )}

              {job.bulk_ship_tracking ? (
                <>
                  {(job.shipping_type !== 'drop_shipping' || job.bulk_ship_tracking.length > 0) && (
                    <>
                      <dt>Bulk Shipping Tracking</dt>
                      {job.requesting ? (
                        <TextLoader height={20} />
                      ) : (
                        <dd>
                          {job.bulk_ship_tracking && job.bulk_ship_tracking.length ? (
                            <ul>
                              {job.bulk_ship_tracking.map((shipping) => (
                                <li key={shipping.num}>
                                  {shipping.tracking_number ? (
                                    <a href={shipping.tracking_url} target="_blank" rel="noopener noreferrer">
                                      {moment(shipping.processed_at).isValid() ? moment(shipping.processed_at).format('L') : 'N/P'} - {shipping.tracking_number}
                                    </a>
                                  ) : (
                                    <span>N/P</span>
                                  )}
                                </li>
                              ))}
                            </ul>
                          ) : (
                            'N/A'
                          )}
                        </dd>
                      )}
                    </>
                  )}
                </>
              ) : (
                ''
              )}

              {studio?.id !== 'all-studios' && ALLOW_EMAIL_CAMPAIGNS && job.access_mode === 'access_per_subject' && (
                <>
                  <dt>Email Campaigns</dt>
                  <dd>
                    <small className="text--normalized">
                      PhotoDay will automatically send emails for this job. You can turn off the campaign and view each email being sent out{' '}
                      <Link to={`/jobs/${job.id}/marketing/campaign-settings`}>here</Link>.
                    </small>
                  </dd>
                </>
              )}
            </dl>
          </article>
        </aside>
        <section className="basis-9/12 md:basis-full">
          {/* Get Your Job Started */}
          {studio?.id !== 'all-studios' && !job.requesting && job.setup_steps && checkStepsComplete(job.setup_steps) === false && (
            <section className="job-dashboard__section">
              <header className="flex items-center job-dashboard__header">
                <h2 className="text-headline-sm">Getting Your Job Started</h2>
              </header>
              <ul className="job-dashboard__steps-list">
                <li className="job-dashboard__steps">
                  <figure className="flex items-center justify-center">
                    <ReactImageSetup className="job-dashboard__steps-image" />
                  </figure>
                  <h5 className={`job-dashboard__steps-title ${job.setup_steps.gallery_access ? 'job-dashboard__steps-title--active' : ''}`}>
                    Set up gallery access
                  </h5>
                  <p className="job-dashboard__steps-description">Choose how customers will view the gallery.</p>
                  <Link
                    className="button button--outline text-primary-blue-500 job-dashboard__steps-button"
                    to={{ pathname: `/jobs/${job.id}/settings/access`, state: { sendBack: true } }}
                  >
                    {job.setup_steps.gallery_access ? 'View' : 'Set Up'}
                  </Link>
                </li>
                <li
                  className={`job-dashboard__steps ${
                    job.setup_steps.gallery_access === false && job.setup_steps.storefront === false ? 'job-dashboard__steps--disabled' : ''
                  }`}
                >
                  <figure className="flex items-center justify-center">
                    <ReactImageSetupStorefront className="job-dashboard__steps-image" />
                  </figure>
                  <h5 className={`job-dashboard__steps-title ${job.setup_steps.storefront ? 'job-dashboard__steps-title--active' : ''}`}>Set up storefront</h5>
                  <p className="job-dashboard__steps-description">Set up AdvancePay, create price sheets, offers, and themes.</p>
                  <Link
                    className="button button--outline text-primary-blue-500 job-dashboard__steps-button"
                    to={{ pathname: `/jobs/${job.id}/settings/store`, state: { sendBack: true } }}
                  >
                    {job.setup_steps.storefront ? 'Update' : 'Set Up'}
                  </Link>
                </li>
                {job.access_mode === 'access_per_subject' ? (
                  <li
                    className={`job-dashboard__steps ${
                      job.setup_steps.gallery_access === false || job.setup_steps.storefront === false ? 'job-dashboard__steps--disabled' : ''
                    }`}
                  >
                    <figure className="flex items-center justify-center">
                      <ReactImageUpload className="job-dashboard__steps-image" />
                    </figure>
                    <h5
                      className={`job-dashboard__steps-title ${
                        job.setup_steps.uploaded_photos && job.setup_steps.uploaded_subjects ? 'job-dashboard__steps-title--active' : ''
                      }`}
                    >
                      Add subjects and photos
                    </h5>
                    <p className="job-dashboard__steps-description">Add a file containing Subject Data, as well as your photos to the gallery.</p>
                    <Link
                      className="button button--outline text-primary-blue-500 job-dashboard__steps-button"
                      to={{ pathname: `/jobs/${job.id}/gallery`, state: { sendBack: true } }}
                    >
                      {job.setup_steps.uploaded_photos ? 'Add more' : 'Add'}
                    </Link>
                  </li>
                ) : (
                  <li
                    className={`job-dashboard__steps ${
                      job.setup_steps.gallery_access === false || job.setup_steps.storefront === false ? 'job-dashboard__steps--disabled' : ''
                    }`}
                  >
                    <figure className="flex items-center justify-center">
                      <ReactImageUpload className="job-dashboard__steps-image" />
                    </figure>
                    <h5 className={`job-dashboard__steps-title ${job.setup_steps.uploaded_photos ? 'job-dashboard__steps-title--active' : ''}`}>
                      Upload photos
                    </h5>
                    <p className="job-dashboard__steps-description">Upload jpegs or folders to the gallery.</p>
                    <Link
                      className="button button--outline text-primary-blue-500 job-dashboard__steps-button"
                      to={{ pathname: `/jobs/${job.id}/gallery`, state: { sendBack: true } }}
                    >
                      {job.setup_steps.uploaded_photos ? 'Upload more' : 'Upload'}
                    </Link>
                  </li>
                )}
                <li className={`job-dashboard__steps ${job.setup_steps.uploaded_photos && job.setup_steps.storefront ? '' : 'job-dashboard__steps--disabled'}`}>
                  <figure className="flex items-center justify-center">
                    <ReactImageStatus className="job-dashboard__steps-image" />
                  </figure>
                  <h5 className={`job-dashboard__steps-title ${job.setup_steps.published ? 'job-dashboard__steps-title--active' : ''}`}>Set gallery status</h5>
                  <p className="job-dashboard__steps-description">Use the status dropdown above to activate AdvancePay or publish your job.</p>
                </li>
              </ul>
            </section>
          )}

          {/* To Do's */}
          {studio?.id !== 'all-studios' && !job.requesting && todoTasks.length > 0 && (
            <section className="job-dashboard__section">
              <header className="flex items-center job-dashboard__header">
                <h2 className="text-headline-sm">Do More with PhotoDay</h2>
              </header>

              <ul className="flex gap-5">
                <li className={`panel panel--nomargin flex flex-col job-dashboard__todo-card ${todoTasks.includes('backgrounds') ? '' : 'hidden'}`}>
                  <figure className="job-dashboard__todo-card-figure job-dashboard__bg-red">
                    <ReactImageBackgrounds />
                  </figure>
                  <div className="job-dashboard__todo-card-content">
                    <aside>
                      <h5>Backgrounds</h5>
                      <small>
                        Bring life to your photos.{' '}
                        <a
                          href="https://support.photoday.io/en/articles/4873011-getting-started-with-photoday-s-backgrounds-feature"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Learn More.
                        </a>
                      </small>
                    </aside>
                    <Link
                      className="button button--outline text-primary-blue-500 button--block self-end"
                      to={{ pathname: `/jobs/${job.id}/settings/store/backgrounds`, state: { sendBack: true } }}
                    >
                      Set Up
                    </Link>
                  </div>
                </li>
                <li className={`panel panel--nomargin flex flex-col job-dashboard__todo-card ${todoTasks.includes('bulk-shipping') ? '' : 'hidden'}`}>
                  <figure className="job-dashboard__todo-card-figure job-dashboard__bg-purple">
                    <ReactImageBulkShip />
                  </figure>
                  <div className="job-dashboard__todo-card-content">
                    <aside>
                      <h5>Bulk Shipping</h5>
                      <small>
                        Ship all orders from a job to one location.{' '}
                        <a href="https://support.photoday.io/en/articles/4551069-how-do-i-implement-bulk-shipping" target="_blank" rel="noopener noreferrer">
                          Learn More.
                        </a>
                      </small>
                    </aside>
                    <Link
                      className="self-end button button--outline text-primary-blue-500 button--block"
                      to={{ pathname: `/jobs/${job.id}/settings/shipping/bulk`, state: { sendBack: true } }}
                    >
                      Set Up
                    </Link>
                  </div>
                </li>
                <li className={`panel panel--nomargin flex flex-col job-dashboard__todo-card ${todoTasks.includes('exports') ? '' : 'hidden'}`}>
                  <figure className="job-dashboard__todo-card-figure job-dashboard__bg-yellow">
                    <ReactImageExports />
                  </figure>
                  <div className="job-dashboard__todo-card-content">
                    <aside>
                      <h5>Exports</h5>
                      <small>
                        Export your photos and data for service items.{' '}
                        <a
                          href="https://support.photoday.io/en/articles/3379907-what-types-of-data-exports-are-available-to-me"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Learn More.
                        </a>
                      </small>
                    </aside>
                    <Link
                      className="self-end button button--outline text-primary-blue-500 button--block"
                      to={{ pathname: `/jobs/${job.id}/services/exports`, state: { sendBack: true } }}
                    >
                      Export
                    </Link>
                  </div>
                </li>
                <li className={`panel panel--nomargin flex flex-col flex-nowrap job-dashboard__todo-card ${todoTasks.includes('knockouts') ? '' : 'hidden'}`}>
                  <figure className="job-dashboard__todo-card-figure job-dashboard__bg-yellow">
                    <ReactImagePostProcessing />
                  </figure>
                  <div className="job-dashboard__todo-card-content">
                    <aside>
                      <h5>Post-Processing</h5>
                      <small>
                        NOW AVAILABLE! Improved Knockouts and brand new Color Correction services powered by AI.{' '}
                        <a
                          href="https://support.photoday.io/en/articles/6967528-post-processing-services-in-photoday"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Learn More.
                        </a>
                      </small>
                    </aside>
                    <Link
                      className="self-end button button--outline text-primary-blue-500 button--block"
                      to={{ pathname: `/jobs/${job.id}/services/postprocessing`, state: { sendBack: true } }}
                    >
                      Set Up
                    </Link>
                  </div>
                </li>
                <li className={`panel panel--nomargin flex flex-col job-dashboard__todo-card ${todoTasks.includes('marketing') ? '' : 'hidden'}`}>
                  <figure className="job-dashboard__todo-card-figure job-dashboard__bg-blue">
                    <ReactImageMarketing />
                  </figure>
                  <div className="job-dashboard__todo-card-content">
                    <aside>
                      <h5>Marketing</h5>
                      <small>Manage your marketing campaigns via flyers, email, and text.</small>
                    </aside>
                    <Link
                      className="self-end button button--outline text-primary-blue-500 button--block"
                      to={{ pathname: `/jobs/${job.id}/marketing/flyers`, state: { sendBack: true } }}
                    >
                      Create
                    </Link>
                  </div>
                </li>
                <li className={`panel panel--nomargin flex flex-col job-dashboard__todo-card ${todoTasks.includes('yearbook') ? '' : 'hidden'}`}>
                  <figure className="job-dashboard__todo-card-figure job-dashboard__bg-blue">
                    <ReactImageYearbook />
                  </figure>
                  <div className="job-dashboard__todo-card-content">
                    <aside>
                      <h5>Yearbook Selection</h5>
                      <small>Allow your customers to select their preferred photo(s) for their school's yearbook.</small>
                    </aside>
                    <Link
                      className="self-end button button--outline text-primary-blue-500 button--block"
                      to={{ pathname: `/jobs/${job.id}/settings/yearbook`, state: { sendBack: true } }}
                    >
                      Set Up
                    </Link>
                  </div>
                </li>
              </ul>
            </section>
          )}

          {/* Sales Summary */}
          {!job.requesting && userRoles.includes('view_insights') && (
            <section className="job-dashboard__section">
              <header className="flex items-center justify-between job-dashboard__header">
                <h2 className="text-headline-sm">Sales Summary</h2>
                <Link className={`button button--medium ${job.setup_steps.gallery_access ? '' : 'button--disabled'}`} to={`/jobs/${job.id}/insights`}>
                  View Insights
                </Link>
              </header>
              {salesSummary && (
                <ul className="grid justify-center panel job-dashboard__summary">
                  <li className="flex flex-col">
                    <span className="job-dashboard__summary-data">{'$' + (salesSummary.order_sales / 100).toFixed(2)}</span>
                    <span>Gross Order Sales</span>
                  </li>
                  <li className="flex flex-col">
                    <span className="job-dashboard__summary-data">{'$' + (salesSummary.avg_order_value / 100).toFixed(2)}</span>
                    <span>Average Order Value</span>
                  </li>
                  <li className="flex flex-col">
                    <span className="job-dashboard__summary-data">{'$' + (salesSummary.credit_sales / 100).toFixed(2)}</span>
                    <span>Gross Credit Sales</span>
                  </li>
                  <li className="flex flex-col">
                    <span className="job-dashboard__summary-data">{'$' + (salesSummary.used_credits / 100).toFixed(2)}</span>
                    <span>Used Credits</span>
                  </li>
                  <li className="flex flex-col">
                    <span className="job-dashboard__summary-data">{'$' + (salesSummary.total_sales / 100).toFixed(2)}</span>
                    <span>Total Gross Sales</span>
                  </li>
                  <li className="flex flex-col">
                    <span className="job-dashboard__summary-data">{'$' + (salesSummary.studio_payouts / 100).toFixed(2)}</span>
                    <span>Studio Payout</span>
                  </li>
                </ul>
              )}
            </section>
          )}

          {/* Subjects Summary */}
          {!job.requesting && job.access_mode === 'access_per_subject' && (
            <section className="job-dashboard__section">
              <header className="flex items-center justify-between job-dashboard__header">
                <h2 className="text-headline-sm">Subjects Summary</h2>
                <Link className="button button--medium" to={`/jobs/${jobId}/people`}>
                  View Subjects Data
                </Link>
              </header>

              <ul className="grid justify-center panel job-dashboard__summary">
                <li className="flex flex-col">
                  <span className="job-dashboard__summary-data">{subjectsSummary && subjectsSummary.uploaded >= 0 ? subjectsSummary.uploaded : 'NA'}</span>
                  <span>Uploaded</span>
                </li>
                <li className="flex flex-col">
                  <span className="job-dashboard__summary-data">{subjectsSummary && subjectsSummary.matched >= 0 ? subjectsSummary.matched : 'NA'}</span>
                  <span>Matched</span>
                </li>
                <li className="flex flex-col">
                  <span className="job-dashboard__summary-data">{subjectsSummary && subjectsSummary.unmatched >= 0 ? subjectsSummary.unmatched : 'NA'}</span>
                  <span>Unmatched</span>
                </li>
                <li className="flex flex-col">
                  <span className="job-dashboard__summary-data">{subjectsSummary && subjectsSummary.absent >= 0 ? subjectsSummary.absent : 'NA'}</span>
                  <span>Marked Absent</span>
                </li>
              </ul>
            </section>
          )}

          {/* Shipping Summary */}
          {BULK_SHIPPING_SUMMARY_PAGE_ENABLED && !job.requesting && job.has_bulk_shipment && shippingSummary && (
            <section className="job-dashboard__section">
              <header className="flex items-center justify-between job-dashboard__header">
                <h2 className="text-headline-sm">Shipping Summary</h2>
                <Link className="button button--medium button--outline" to={`/jobs/${jobId}/shipping`}>
                  View History
                </Link>
              </header>

              <aside className="panel panel--nomargin flex justify-between">
                <span>Recent Bulk Shipment - {moment(shippingSummary.shipped_at).format('L')}</span>
                {shippingSummary.fee_charged && (
                  <span>
                    Lab Charge: Minimum{' '}
                    <Tooltip title="This order was charged the lab minimum $15" theme="light" arrow={true} position="bottom">
                      <i className="icon-question-mark"></i>
                    </Tooltip>
                  </span>
                )}
              </aside>
              <ul className="grid justify-center panel panel--nomargin job-dashboard__summary">
                <li className="flex flex-col">
                  <span className="job-dashboard__summary-data">{shippingSummary.orders_count}</span>
                  <span>Individual Orders</span>
                </li>
                <li className="flex flex-col job-dashboard__summary-divider">
                  <span className="job-dashboard__summary-data">{shippingSummary.orders_count_percent}%</span>
                  <span>Bulk Order %</span>
                </li>
                <li className="flex flex-col">
                  <span className="job-dashboard__summary-data">
                    {Number(shippingSummary.orders_total_cents / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                  </span>
                  <span>Bulk Total</span>
                </li>
                <li className="flex flex-col">
                  <span className="job-dashboard__summary-data">{shippingSummary.orders_total_percent}%</span>
                  <span>Bulk Total %</span>
                </li>
              </ul>
            </section>
          )}
        </section>
      </main>
    </>
  );
};

Dashboard.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      jobId: PropTypes.string.isRequired
    })
  }),
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default Dashboard;
