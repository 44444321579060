import { GET_OFFERS_DDL_REQUEST, GET_OFFERS_DDL_SUCCESS, GET_OFFERS_DDL_ERROR } from './constants';

import { handleErrorState, handleRequestingState } from '@/utils/reducerHelpers';

const initialState = {
  options: [],
  requesting: false,
  successful: false,
  errors: []
};

const handleOffersDDLSuccess = (state, payload) => {
  const { options } = payload;

  return {
    ...state,
    ...{ options, errors: [], requesting: false, successful: true }
  };
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_OFFERS_DDL_REQUEST:
      return handleRequestingState(state);
    case GET_OFFERS_DDL_SUCCESS:
      return handleOffersDDLSuccess(state, payload);
    case GET_OFFERS_DDL_ERROR:
      return handleErrorState(state, payload);
    default:
      return state;
  }
};

export default reducer;
