import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import queryString from 'query-string';

// Components
import OrderRow from './order-row';
import SearchBox from '@/components/Shared/SearchBox';
import PhotoDayGrid from '@/components/Shared/PhotodayGrid';

import { getJobTimezoneList } from '../../Jobs/actions';
import { orderListRequest, setOrderNameSearch } from './actions';

// Styles
import './orders.css';

const Orders = ({ location }) => {
  const dispatch = useDispatch();

  const { search: querySearch } = queryString.parse(location.search);
  const { timezones } = useSelector((state) => state.jobs);
  const {
    studio: { id: studioId }
  } = useSelector((state) => state.login);
  const { result, entities, pager, requesting, isSearchRequesting } = useSelector((state) => state.orders);

  const handleFilterSearchOnChange = (value) => {
    dispatch(
      setOrderNameSearch({
        ...pager,
        search: value,
        page: 1,
        studioId
      })
    );
  };

  const fetchRecords = (pager) => {
    dispatch(orderListRequest({ studioId, ...pager }));
  };

  const gridHeader = [
    {
      fieldName: 'submitted_at',
      displayName: 'Date',
      sortable: true
    },
    {
      fieldName: 'num',
      displayName: 'Order #',
      sortable: true
    },
    {
      fieldName: 'advance_pay',
      displayName: 'Type',
      sortable: false
    },
    {
      fieldName: 'job.name',
      displayName: 'Job',
      sortable: true
    },
    {
      fieldName: 'customer.name',
      displayName: 'Customer Name',
      sortable: false
    },
    {
      fieldName: 'status',
      displayName: 'Status',
      sortable: true
    },
    {
      fieldName: 'tracking_number',
      displayName: 'Tracking #',
      sortable: false
    },
    {
      fieldName: 'credit',
      displayName: 'Credit Applied',
      sortable: false
    },
    {
      fieldName: 'total_with_credit',
      displayName: 'Order Total',
      sortable: false
    }
  ];

  useEffect(() => {
    dispatch(orderListRequest({ studioId, page: pager.page, perPage: pager.perPage, order: 'submitted_at', dir: 'DESC', search: querySearch }));

    if (!timezones.length) {
      dispatch(getJobTimezoneList());
    }
  }, []);

  return (
    <section id="storefront__orders">
      <header className="flex items-center justify-between mb-5">
        <div>
          <h2 className="text-headline-sm">Orders</h2>
          <p className="text-type-base">Keep track of all your orders below. Orders can take up to 12 hours to process.</p>
        </div>
        <SearchBox value={querySearch} onChange={handleFilterSearchOnChange} placeholder="Search Orders" requesting={isSearchRequesting} />
      </header>

      <PhotoDayGrid
        headers={gridHeader}
        table_id="order-table"
        pager={pager}
        fetchRecordsPage={fetchRecords}
        borderClass="yellow-border color-border"
        requesting={requesting}
        defaultContent="You currently have no orders."
      >
        {result.map((id) => {
          const order = entities.orders[id];

          return <OrderRow key={id} order={order} timezones={timezones} />;
        })}
      </PhotoDayGrid>
      <p className="order-annotation"> *Order total before applied AP credit</p>
    </section>
  );
};

Orders.propTypes = {
  result: PropTypes.arrayOf(PropTypes.string),
  entities: PropTypes.shape({
    jobs: PropTypes.object
  }),
  pager: PropTypes.shape({
    page: PropTypes.number,
    perPage: PropTypes.number,
    total: PropTypes.number,
    totalPages: PropTypes.number
  }),
  requesting: PropTypes.bool
};

export default Orders;
