import { call, put, takeLatest, all } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import errorHandler from '@/utils/errorResponseHandler';
import successHandler from '@/utils/successResponseHandler';
import parseNormalizeListResponse from '@/utils/responseParsers';
import * as api from '@/api/advance-api';
import giftCardSheet from './schema';
import {
  UPDATE_GIFT_CARD_REQUEST,
  GIFT_CARD_LIST_REQUEST,
  DESTROY_GIFT_CARD_REQUEST,
  CREATE_GIFT_CARD_REQUEST,
  GET_CREDIT_SHEET_DETAIL_REQUEST,
  CREDITSHEET_LIST_REQUEST,
  CREATE_CREDITSHEET_REQUEST,
  UPDATE_CREDITSHEET_REQUEST,
  DESTROY_CREDITSHEET_REQUEST
} from './constants';

import {
  updateGiftCardListRequestSuccess,
  updateGiftCardListRequestError,
  giftCardListRequestSuccess,
  giftCardListRequestError,
  deleteGiftCardListRequestSuccess,
  deleteGiftCardListRequestError,
  createGiftCardListRequestSuccess,
  createGiftCardListRequestError,
  getCreditSheetDetailSuccess,
  getCreditSheetDetailError,
  creditSheetListRequestError,
  creditSheetListRequestSuccess,
  createCreditSheetRequestSuccess,
  createCreditSheetRequestError,
  updateCreditSheetRequestSuccess,
  updateCreditSheetRequestError,
  destroyCreditSheetRequestSuccess,
  destroyCreditSheetRequestError
} from './actions';

function* updateGiftCardRequestFlow(action) {
  try {
    yield put(showLoading());

    const {
      payload: { giftCardId, amount, description },
      callback
    } = action;
    const { data } = yield call(api.updateGiftCard, {
      giftCardId,
      amount,
      description
    });
    yield call(successHandler('Credit option successfully updated'), updateGiftCardListRequestSuccess, {
      data
    });

    if (callback) yield call(callback);
  } catch (error) {
    yield call(errorHandler, updateGiftCardListRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* createGiftCardRequestFlow(action) {
  try {
    yield put(showLoading());

    const {
      payload: { creditSheetId, amount, description },
      callback
    } = action;

    const { data } = yield call(api.createGiftCard, {
      creditSheetId,
      amount,
      description
    });

    yield call(successHandler('Credit option successfully created'), createGiftCardListRequestSuccess, {
      data
    });

    if (callback) yield call(callback);
  } catch (error) {
    yield call(errorHandler, createGiftCardListRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getGiftCardListRequestFlow(action) {
  try {
    yield put(showLoading());

    const { payload, callback } = action;
    const { creditSheetId, page, perPage, search, order, dir } = action.payload;
    const response = yield call(api.giftCardList, creditSheetId, page, perPage, search, order, dir);
    yield put(giftCardListRequestSuccess(response.data));
    if (callback) callback(payload);
  } catch (error) {
    yield call(errorHandler, giftCardListRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* deleteGiftCardRequestFlow(action) {
  try {
    yield put(showLoading());

    const {
      payload: { giftCardId },
      callback
    } = action;
    yield call(api.deleteGiftCard, giftCardId);
    yield call(successHandler('Credit Option successfully deleted'), deleteGiftCardListRequestSuccess, giftCardId);
    if (callback) yield call(callback);
  } catch (error) {
    yield call(errorHandler, deleteGiftCardListRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getCreditSheetDetailRequestFlow(action) {
  try {
    yield put(showLoading());

    const { creditSheetId } = action.payload;
    const response = yield call(api.creditSheetDetail, creditSheetId);
    yield put(getCreditSheetDetailSuccess(response.data));
  } catch (error) {
    yield call(errorHandler, getCreditSheetDetailError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getCreditSheetListRequestFlow(action) {
  try {
    yield put(showLoading());

    const { callback } = action;
    const { page, perPage, search, order, dir } = action.payload;
    const response = yield call(api.creditSheetList, page, perPage, search, order, dir);
    const payload = parseNormalizeListResponse(response, giftCardSheet, order, dir, search);

    yield put(creditSheetListRequestSuccess(payload));

    if (callback) callback(payload);
  } catch (error) {
    yield call(errorHandler, creditSheetListRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* createCreditSheetRequestFlow(action) {
  try {
    yield put(showLoading());

    const {
      payload: { studioId, creditSheet, callback }
    } = action;
    const { data: newCreditSheet } = yield call(api.createCreditSheetOffer, {
      studioId,
      creditSheet: {
        ...creditSheet,
        description: null
      }
    });
    yield call(successHandler('Credit Sheet successfully created'), createCreditSheetRequestSuccess, {
      studioId,
      creditSheet: newCreditSheet
    });
    if (callback) callback(newCreditSheet);
  } catch (error) {
    yield call(errorHandler, createCreditSheetRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* updateCreditSheetRequestFlow(action) {
  try {
    yield put(showLoading());

    const {
      payload: { studioId, creditSheet, callback }
    } = action;
    const { data: updatedCreditSheet } = yield call(api.updateCreditSheetOffer, {
      studioId,
      creditSheet
    });
    yield call(successHandler('Credit Sheet successfully updated'), updateCreditSheetRequestSuccess, {
      studioId,
      creditSheet: updatedCreditSheet
    });
    if (callback) yield call(callback, updatedCreditSheet);
  } catch (error) {
    yield call(errorHandler, updateCreditSheetRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* deleteCreditSheetRequestFlow({ payload }) {
  try {
    yield put(showLoading());

    const { studioId, creditSheetId, callback } = payload;
    yield call(api.deleteCreditSheetOffer, payload);
    yield call(successHandler('Credit Sheet successfully deleted'), destroyCreditSheetRequestSuccess, payload);
    if (callback) yield call(callback, { studioId, creditSheetId });
  } catch (error) {
    yield call(errorHandler, destroyCreditSheetRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getAdvancePayWatcher() {
  yield all([
    takeLatest(UPDATE_GIFT_CARD_REQUEST, updateGiftCardRequestFlow),
    takeLatest(GIFT_CARD_LIST_REQUEST, getGiftCardListRequestFlow),
    takeLatest(DESTROY_GIFT_CARD_REQUEST, deleteGiftCardRequestFlow),
    takeLatest(CREATE_GIFT_CARD_REQUEST, createGiftCardRequestFlow),
    takeLatest(GET_CREDIT_SHEET_DETAIL_REQUEST, getCreditSheetDetailRequestFlow),
    takeLatest(CREDITSHEET_LIST_REQUEST, getCreditSheetListRequestFlow),
    takeLatest(CREATE_CREDITSHEET_REQUEST, createCreditSheetRequestFlow),
    takeLatest(UPDATE_CREDITSHEET_REQUEST, updateCreditSheetRequestFlow),
    takeLatest(DESTROY_CREDITSHEET_REQUEST, deleteCreditSheetRequestFlow)
  ]);
}

export default getAdvancePayWatcher;
