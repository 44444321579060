// Helpers
import * as api from '@/utils/http';
import qs from 'qs';

export const createPeople = (payload) => {
  const endpoint = `jobs/${payload.id}/subjects`;
  const args = { ...payload };

  return api.post(endpoint, args);
};

export const createPeopleAssociation = (payload) => {
  const endpoint = `subjects/${payload.id}/add-photo-faces`;
  const args = { photo_face_ids: payload.photo_face_ids };

  return api.post(endpoint, args);
};

export const createPeopleAssociationPhotos = (payload) => {
  const endpoint = `jobs/${payload.id}/photos/match`;
  const args = { ...payload };

  return api.post(endpoint, args);
};

export const createBulkFeaturePhotos = (payload) => {
  const endpoint = `jobs/${payload.id}/photos/bulk-feature`;
  const args = { ...payload };

  return api.post(endpoint, args);
};

export const createPeopleBulkYearbookSelection = (payload) => {
  const endpoint = `jobs/${payload.jobId}/photos/bulk-yearbook-selection`;
  const args = { ...payload };

  return api.post(endpoint, args);
};

export const createPeopleYearbookSelection = (payload) => {
  const endpoint = `s/yearbook-selection/subjects/${payload.subjectId}/selection`;
  const args = { ...payload };

  return api.put(endpoint, args);
};

export const createPeopleCsv = (payload) => {
  const endpoint = `jobs/${payload.id}/subjects/import`;
  const args = { ...payload };

  return api.multipartPost(endpoint, args);
};

export const createPeopleCsvDetails = (payload) => {
  const endpoint = `jobs/${payload.id}/subjects/import-details`;
  const args = { ...payload };

  return api.multipartPost(endpoint, args);
};

export const getSubject = (payload) => {
  const endpoint = `subjects/${payload.id}`;
  const args = { ...payload };

  return api.get(endpoint, args);
};

export const getPeopleList = (payload) => {
  // Remove null or undefined parameters before send it
  const params = Object.fromEntries(Object.entries(payload).filter(([, v]) => (v === null || v === undefined ? false : true)));
  const endpoint = `jobs/${payload.id}/subjects?${qs.stringify(params, { arrayFormat: 'brackets' })}`;

  return api.get(endpoint);
};

export const getPeopleFacesList = (payload) => {
  const endpoint = `jobs/${payload.id}/face-candidates`;
  const args = { ...payload };

  return api.get(endpoint, args);
};

export const getPeoplePhotoFacesList = (payload) => {
  const endpoint = `photos/${payload.id}/photo-faces`;
  const args = { ...payload };

  return api.get(endpoint, args);
};

export const getPeopleFieldOptions = (payload) => {
  const endpoint = `jobs/${payload.id}/subjects/field-options`;
  const args = { ...payload };

  return api.get(endpoint, args);
};

export const getPeopleByPhotoId = (payload) => {
  const endpoint = `jobs/${payload.id}/subjects?photo_id=${payload.photoId}`;
  const args = {};

  return api.get(endpoint, args);
};

export const updatePeople = (payload) => {
  const endpoint = `subjects/${payload.id}`;
  const args = { ...payload };

  return api.put(endpoint, args);
};

export const updatePeopleFaces = (payload) => {
  const endpoint = `jobs/${payload.jobId}/face-candidates/${payload.id}`;
  const args = { ...payload };

  return api.put(endpoint, args);
};

export const deletePeople = (payload) => {
  const endpoint = `jobs/${payload.id}/subjects/bulk-destroy`;
  const args = { ...payload };

  return api.del(endpoint, args);
};

export const deletePeoplePhoto = (payload) => {
  const endpoint = `subjects/${payload.id}/remove-photos`;
  const args = { ...payload };

  return api.del(endpoint, args);
};

export const deletePhotoPeople = (payload) => {
  const endpoint = `photos/${payload.id}/remove-subjects`;
  const args = { ...payload };

  return api.del(endpoint, args);
};

// Code LookUp Configuration
export const createPeopleLookupConfig = ({ jobId, lookupConfig }) => {
  const endpoint = `s/subject-upload/${jobId}/lookup-config`;
  return api.post(endpoint, lookupConfig);
};

export const createPeopleLookupValidate = ({ jobId, lookupConfig }) => {
  const endpoint = `s/subject-upload/${jobId}/lookup-config/validate`;
  return api.post(endpoint, lookupConfig);
};

export const getPeopleLookupConfig = ({ jobId, searchParams }) => {
  const endpoint = `s/subject-upload/${jobId}/lookup-config`;
  return api.get(endpoint, searchParams);
};

export const getPeopleCsvColumnConfig = () => {
  const endpoint = `s/subject-upload/csv-column-config`;
  return api.get(endpoint);
};
