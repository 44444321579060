import { FC, useRef, useState } from 'react';

// Components
import GalleryDragElement from '../GalleryDragElement';

// Plugins
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Helpers
import imageScaling from '@/utils/imageScaling';

// Types
import { QrCode, QrCodePhoto, QrCodeResolutionError } from '@/types';

interface QrPhotosProps {
  activeQrCode: QrCode | null;
  selectedPhotoId: string | null;
  getCodeTextStyle: (qrCode: QrCode) => string;
  onPhotoSelection: (photoId: string) => void;
}

interface ErrorMessage {
  message: string;
  suggestions?: string[];
}

const QrPhotos: FC<QrPhotosProps> = ({ activeQrCode, selectedPhotoId, getCodeTextStyle, onPhotoSelection }) => {
  const galleryDragElementRef = useRef<HTMLDivElement>(null);

  const activePhotos: QrCodePhoto[] = (activeQrCode?.photos.length ? activeQrCode?.photos : activeQrCode?.photo_candidates) ?? [];
  const isQrCodeResolved: boolean = activeQrCode?.resolution_error === null || activeQrCode?.resolution !== null;
  const isPhotoSelectionAllowed: boolean =
    activeQrCode?.discarded_at === null && !isQrCodeResolved && activeQrCode.resolution_error === QrCodeResolutionError.NoPQR;

  const errorMessages: Record<QrCodeResolutionError, ErrorMessage> = {
    [QrCodeResolutionError.NoCQR]: {
      message: 'The QR record is invalid, ignore this issue and continue resolving others. There could be a few reasons for this:',
      suggestions: [
        'There could be an issue with the job syncing in the Capture QR App, revisit the app to sync the job.',
        'This QR record could be from another job.'
      ]
    },
    [QrCodeResolutionError.NoPQR]: {
      message: "We couldn't find a QR reference photo.There are two ways to resolve this issue: ",
      suggestions: [
        'If there is a QR reference photo, drag it to the top area of the selected QR record to the left.',
        'If there is no QR reference photo, drag the subject images to the bottom area of the select QR record to the left.'
      ]
    },
    [QrCodeResolutionError.InvalidPqr]: {
      message: 'The QR reference photo found is invalid, ignore this issue and continue resolving others. There could be a few reasons for this:',
      suggestions: ['The QR in the reference photo is from a different source outside the Capture QR app.', 'The QR reference photo could be from another job.']
    },
    [QrCodeResolutionError.NoImage]: {
      message: 'There are no images associated with this QR record. Ignore this issue and continue resolving others.'
    },
    [QrCodeResolutionError.DiscardedWithImages]: {
      message: 'This QR record was discarded but there are images associated with it. There are two ways to resolve this issue:',
      suggestions: ['Restore it to use the QR Record', 'Ignore It']
    }
  };

  // State
  const [showPhotoPreview, setShowPhotoPreview] = useState<boolean>(false);
  const [photoPreview, setPhotoPreview] = useState<QrCodePhoto | null>(null);

  // Helper functions
  const renderResolutionErrorPanel = () => {
    if (!isQrCodeResolved) {
      const resolutionError = activeQrCode?.resolution_error as QrCodeResolutionError;
      const errorMessage = errorMessages[resolutionError];

      return (
        <aside className="panel panel--gray panel--nomargin mb-5 animate">
          <p className="m-0">{errorMessage?.message}</p>
          {errorMessage?.suggestions && (
            <ul className="list--number">
              {errorMessage.suggestions.map((suggestion, index) => (
                <li key={index}>{suggestion}</li>
              ))}
            </ul>
          )}
        </aside>
      );
    }

    if (isQrCodeResolved && activePhotos.length === 0) {
      return (
        <aside className="panel panel--gray panel--nomargin mb-5 animate">
          <p className="m-0">There are no images associated with this QR record</p>
        </aside>
      );
    }

    return null;
  };

  // UI Handlers
  const handlePhotoDragStart = ({ event, photoId }: { event: React.DragEvent<HTMLElement>; photoId: string }): void => {
    onPhotoSelection(photoId);
    event.dataTransfer.setDragImage(galleryDragElementRef?.current ?? new Image(), 60, 60);
  };

  const handlePhotoPreviewOpen = (selectedPhoto: QrCodePhoto): void => {
    setPhotoPreview(selectedPhoto);
    setShowPhotoPreview(true);
  };

  const handlePhotoPreviewClose = (): void => {
    setPhotoPreview(null);
    setShowPhotoPreview(false);
  };

  return (
    <>
      <section className="basis-8/12 md:basis-full modal__content-section pt-14">
        {activeQrCode ? (
          <>
            {renderResolutionErrorPanel()}
            {activePhotos.length > 0 && (
              <>
                <div className="job-qr__photo-list">
                  {activePhotos.map((qrPhoto: QrCodePhoto) => (
                    <figure
                      key={qrPhoto.id}
                      className={`job-qr__photo ${selectedPhotoId === qrPhoto.id ? 'job-qr__photo--selected' : ''} ${isPhotoSelectionAllowed ? '' : 'pointer-events-none'} animate`}
                      draggable={true}
                      onClick={() => onPhotoSelection(qrPhoto.id)}
                      onDragStart={(event) => handlePhotoDragStart({ event, photoId: qrPhoto.id })}
                    >
                      <header className="flex items-center justify-between pb-2.5 pl-2.5">
                        <h6 className="m-0">{qrPhoto.image_file_name}</h6>
                        <button
                          className={`button button--outline button--small button--noborder ${isPhotoSelectionAllowed ? '' : 'transparent'}`}
                          name="preview"
                          onClick={() => handlePhotoPreviewOpen(qrPhoto)}
                        >
                          <i className="icon-view"></i>
                        </button>
                      </header>
                      <figure className="job-qr__image-box">
                        <img
                          className="job-qr__image"
                          src={imageScaling({ url: qrPhoto.image_url, size: 'medium' })}
                          alt={qrPhoto.image_file_name}
                          draggable={false}
                        />
                      </figure>
                      <figcaption className={`p-2.5 justify-end ${getCodeTextStyle(activeQrCode)}`}>{qrPhoto.qr_friendly_id}</figcaption>
                    </figure>
                  ))}
                </div>
                <GalleryDragElement ref={galleryDragElementRef} dragItemsTotal={1} />
              </>
            )}
          </>
        ) : (
          <aside className="flex flex-col items-center justify-center">
            <h3 className="m-0 animate">Select a QR Record to begin.</h3>
          </aside>
        )}
      </section>

      {/* Preview */}
      {showPhotoPreview && (
        <aside className="modal modal--sub animate">
          <div className="modal__box modal__box--sub">
            <button className="button button--action modal__close" name="close" type="button" onClick={handlePhotoPreviewClose}>
              <i className="icon-close"></i>
            </button>
            <main className="modal__content">
              <figure>
                <LazyLoadImage
                  className="mb-2.5 image--placeholder image--responsive"
                  src={imageScaling({ url: photoPreview?.image_url, size: 'large' })}
                  alt={photoPreview?.image_file_name}
                  effect="opacity"
                />
                <figcaption className="text-body-lg font-bold">{photoPreview?.image_file_name}</figcaption>
              </figure>
            </main>
          </div>
        </aside>
      )}
    </>
  );
};

export default QrPhotos;
