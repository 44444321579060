import { call, put, takeLatest, all } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import parseNormalizeListResponse from '@/utils/responseParsers';
import errorHandler from '@/utils/errorResponseHandler';
import * as api from '@/api/product-api';
import productCategorySchema from './schema';

import { PRODUCT_CATEGORY_LIST_REQUEST } from './constants';

import { productCategoryListRequestSuccess, productCategoryListRequestError } from './actions';
function* getListRequestFlow(action) {
  try {
    yield put(showLoading());
    const { callback } = action;
    const { labId } = action.payload;
    const response = yield call(api.productCategoryList, { labId });
    const payload = parseNormalizeListResponse(response, productCategorySchema);
    yield put(productCategoryListRequestSuccess(payload, labId));

    if (callback) yield call(callback, payload);
  } catch (error) {
    yield call(errorHandler, productCategoryListRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getProductsWatcher() {
  yield all([takeLatest(PRODUCT_CATEGORY_LIST_REQUEST, getListRequestFlow)]);
}

export default getProductsWatcher;
