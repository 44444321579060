import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { debounce } from 'lodash';

// Styles
import './style.css';

const SearchBox = ({ onChange, placeholder = null, requesting = false, value: initialValue, children }) => {
  const WAIT_INTERVAL = 750;
  const ENTER_KEY = 13;

  const [value, setValue] = useState(initialValue);

  const triggerChange = debounce(() => onChange(value), WAIT_INTERVAL);

  useEffect(() => {
    triggerChange();
    // Cleanup function to cancel debounce on unmount
    return () => triggerChange.cancel();
  }, [value]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleKeyDown = (evt) => {
    if (evt.keyCode === ENTER_KEY) {
      onChange(value);
    }
  };

  return (
    <div className="search-and-btn">
      <div className="nav-search-field">
        <input type="text" placeholder={placeholder} className="input--block" value={value || ''} onChange={handleChange} onKeyDown={handleKeyDown} />
        <div className="button--icon">
          {!requesting && <i className="fa fa-search" aria-hidden="true" />}
          {requesting && <i className="fa fa-spinner fa-spin" aria-hidden="true" />}
        </div>
      </div>
      {children}
    </div>
  );
};

SearchBox.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  requesting: PropTypes.bool,
  value: PropTypes.string,
  children: PropTypes.node
};

export default SearchBox;
