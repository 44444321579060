import { FC, useState, useEffect, ChangeEvent } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { getPeopleList } from '../../../actions';

// Plugins
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Components
import YearbookSelectionModal from './YearbookSelectionModal';
import GridLoader from '@/components/Shared/ContentLoader/GridLoader';

// Helpers
import imageScaling from '@/utils/imageScaling';

// Types
import { Subject } from '@/types';

interface SubjectYearbookGridProps {
  onPhotosPeopleFilter?: (subject: Subject) => void;
}

const SubjectYearbookGrid: FC<SubjectYearbookGridProps> = ({ onPhotosPeopleFilter }) => {
  const dispatch = useDispatch();

  const {
    job: {
      id: jobId,
      yearbook_selection_photo_count: jobYearbookSelectionPhotoCount,
      yearbook_selection_photo_1_title: jobYearbookSelectionPhoto1Title,
      yearbook_selection_photo_2_title: jobYearbookSelectionPhoto2Title
    },
    query: { people: peopleQuery },
    people: { list: peopleList, requesting: peopleRequesting }
  } = useSelector((state: any) => state.jobs);

  // State
  const [selectedYearbookPose, setSelectedYearbookPose] = useState<number>(1);
  const [filteredPeople, setFilteredPeople] = useState<Subject[]>();
  const [personSelectedId, setPersonSelectedId] = useState<string>();
  const [showSelectionModal, setShowSelectionModal] = useState<boolean>(false);

  // UI Handlers
  const handleYearbookPoseSelect = (event: ChangeEvent<HTMLInputElement>): void => {
    const { target } = event;
    setSelectedYearbookPose(Number(target.value));
  };

  const handlePersonSelect = (personId: string): void => {
    setPersonSelectedId(personId);
    setShowSelectionModal(true);
  };

  const handleShowSelectionModalToggle = (): void => setShowSelectionModal(!showSelectionModal);

  useEffect(() => {
    dispatch(getPeopleList({ id: jobId, with_yearbook_selection: true, per_page: 10000, order: 'last_name', dir: 'asc' }));
  }, []);

  useEffect(() => {
    setFilteredPeople(
      peopleList.filter((person: Subject) => {
        // All subjects
        if (peopleQuery === 'yearbook' || peopleQuery === 'subjectsAll') {
          return person;
        }

        // Subjects with A yearbook photo
        if (peopleQuery === 'subjectsWithYearbookPhoto') {
          return person.yearbook_photos_selected_count > 0;
        }

        // Subjects with NO yearbook photo
        if (peopleQuery === 'subjectsWithoutYearbookPhoto') {
          return person.yearbook_photos_selected_count === 0;
        }

        // Subjects with ONLY pose ONE
        if (peopleQuery === 'subjectsWithOnlyPoseOne') {
          return !!person.yearbook_photos_photo_1_image_id && !person.yearbook_photos_photo_2_image_id;
        }

        // Subjects with ONLY pose TWO
        if (peopleQuery === 'subjectsWithOnlyPoseTwo') {
          return !!person.yearbook_photos_photo_2_image_id && !person.yearbook_photos_photo_1_image_id;
        }

        // Subjects with BOTH poses
        if (peopleQuery === 'subjectsWithBothPoses') {
          return !!person.yearbook_photos_photo_1_image_id && !!person.yearbook_photos_photo_2_image_id;
        }

        // Subjects with matched photo(s) and NO yearbook photo
        if (peopleQuery === 'subjectsMatchedWithoutYearbookPhoto') {
          return person.photos_count > 0 && person.yearbook_photos_selected_count === 0;
        }

        return false;
      })
    );
  }, [peopleList, peopleQuery]);

  return (
    <>
      {jobYearbookSelectionPhotoCount === 2 && (
        <form className="flex gap-5 animate mb-5">
          <label className="mb-0">Select Pose:</label>
          <fieldset className="flex items-center grow-0 mb-0">
            <input id="yearbookPosesOne" type="radio" name="yearbookPoses" value={1} checked={selectedYearbookPose === 1} onChange={handleYearbookPoseSelect} />
            <label htmlFor="yearbookPosesOne" className="label--clean text--normal">
              {jobYearbookSelectionPhoto1Title}
            </label>
          </fieldset>
          <fieldset className="flex items-center mb-0">
            <input id="yearbookPosesTwo" type="radio" name="yearbookPoses" value={2} checked={selectedYearbookPose === 2} onChange={handleYearbookPoseSelect} />
            <label htmlFor="yearbookPosesTwo" className="label--clean text--normal">
              {jobYearbookSelectionPhoto2Title}
            </label>
          </fieldset>
        </form>
      )}

      {filteredPeople?.length ? (
        <div className="job-photo-grid__row animate">
          {filteredPeople.map((person: Subject) => {
            let personYearbookPhotoUrl: string | null = null;

            if (selectedYearbookPose === 1) {
              personYearbookPhotoUrl = person.yearbook_photos_photo_1_image_url ?? null;
            } else if (selectedYearbookPose === 2) {
              personYearbookPhotoUrl = person.yearbook_photos_photo_2_image_url ?? null;
            }

            return (
              <div key={person.id} className="job-photo-grid__item" draggable="false" onClick={() => handlePersonSelect(person.id)}>
                <div className="job-photo-card__figure animate">
                  <header className="job-photo-card__image-header">
                    <span className="job-photo-card__image-name">
                      {person.yearbook_photos_selected_count === 0 ? (
                        `${person.photos_count} photo(s) available`
                      ) : (
                        <>
                          {selectedYearbookPose === 1 && (person.yearbook_photos_photo_1_image_filename ?? `${jobYearbookSelectionPhoto1Title} not selected`)}
                          {selectedYearbookPose === 2 && (person.yearbook_photos_photo_2_image_filename ?? `${jobYearbookSelectionPhoto2Title} not selected`)}
                        </>
                      )}
                    </span>
                  </header>
                  <figure className="job-photo-card__image-container">
                    {personYearbookPhotoUrl && (
                      <LazyLoadImage
                        className="job-photo-card__image"
                        src={imageScaling({ url: personYearbookPhotoUrl, size: 'small' })}
                        effect="opacity"
                        draggable={false}
                        alt="Pose Selection"
                      />
                    )}
                  </figure>
                  <footer className="job-photo-card__actions wrap">
                    <span className="job-photo-card__image-name">
                      {person.first_name} {person.last_name}
                      {person.yearbook_photos_selected_count > 0 ? (
                        <small
                          className={`block text-body-xs font-normal ${person.yearbook_photos_selected_count < person.yearbook_photos_required_count ? 'text-warning-500' : 'text-primary-blue-500'}`}
                        >
                          Selected {person.yearbook_photos_selected_count} of {person.yearbook_photos_required_count} photos
                        </small>
                      ) : (
                        <small className="block text-body-xs font-normal text-error-500">No selection made</small>
                      )}
                    </span>
                  </footer>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <>
          {peopleRequesting ? (
            <GridLoader rows={3} columns={5} gap={20} minHeight={264} />
          ) : (
            <aside className="flex items-center justify-center panel panel--secondary panel--tall animate">
              <h3 className="m-0">No subjects were found.</h3>
            </aside>
          )}
        </>
      )}

      {/* Photo Selection */}
      {showSelectionModal && (
        <YearbookSelectionModal
          personSelectedId={personSelectedId!}
          onPhotosPeopleFilter={onPhotosPeopleFilter!}
          onSelectionModalClose={handleShowSelectionModalToggle}
        />
      )}
    </>
  );
};

export default SubjectYearbookGrid;
