import { FC, useEffect } from 'react';

// Plugins
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { createPhoto, updatePhotoDropzone } from '../../../actions';

// Styles
import './style.css';

const Widget: FC = () => {
  const dispatch = useDispatch();
  const {
    upload: {
      jobId,

      failed,
      rejected,
      errored,

      time,
      queue,

      total,
      current,
      successful,

      hold,
      retry,
      processing
    }
  } = useSelector((state: any) => state.jobs);

  const uploadSample = time.slice(Math.ceil(time.length * -0.1)); // Rely only on 1% of time queue sample

  // Calculate remaining upload time
  const uploadAvrDurationMsec = Number(uploadSample.reduce((acc: any, curr: any) => acc + curr.duration, 0) / uploadSample.length) || 0;
  const uploadAvrDurationSeconds = Number(uploadAvrDurationMsec / 1000) || 0;
  const uploadRemainingDurationMsec = Number(uploadAvrDurationMsec * queue.length);

  // Calculate upload speed
  const uploadAvrSizeBytes = Number(uploadSample.reduce((acc: any, curr: any) => acc + curr.size, 0) / uploadSample.length) || 0;
  const uploadAvrSizeKilobyte = Number(uploadAvrSizeBytes / 1024) || 0;
  const uploadSpeedKbps = (uploadAvrSizeKilobyte / uploadAvrDurationSeconds).toFixed(2);

  const getErroredByCategory = (): { [category: string]: { filename: string; message: string }[] } => {
    if (errored.length > 0) {
      return errored.reduce((acc: any, curr: any) => {
        const { category, filename, message } = curr;

        if (acc[category]) {
          acc[category].push({ filename, message });
        } else {
          acc[category] = [{ filename, message }];
        }

        return acc;
      }, {});
    } else {
      return {};
    }
  };

  const handleRetryAll = (): void => {
    if (processing) {
      dispatch(updatePhotoDropzone({ retry: true }));
    } else {
      dispatch(updatePhotoDropzone({ retry: true, failed: [], processing: true, queue: failed }));
      dispatch(createPhoto());
    }
  };

  const handleDismiss = () => dispatch(updatePhotoDropzone({ failed: [], rejected: [], errored: [] }));

  const handleKeywordsStrategy = (strategy: 'use' | 'ignore'): void => {
    dispatch(updatePhotoDropzone({ hold: false, keywords: strategy === 'use' ? true : false }));
    dispatch(createPhoto());
  };

  const handleCancel = () => dispatch(updatePhotoDropzone({ cancel: true }));

  const handleDone = () => {
    dispatch(
      updatePhotoDropzone({
        jobId: jobId,

        failed: [],
        rejected: [],
        duplicated: [],

        time: [],
        queue: [],

        total: 0,
        current: 0,
        successful: 0,

        hold: false,
        cancel: false,
        retry: false,
        replace: false,
        retainAttributes: false,
        keywords: true,
        processing: false,
        requesting: false
      })
    );
  };

  useEffect(() => {
    window.onbeforeunload = () => {
      if (queue.length) {
        return 'Changes you make may not be saved.';
      } else {
        return;
      }
    };
  }, []);

  return (
    <aside className="job-widget">
      {/* Reject/Fail panel */}
      {(rejected.length > 0 || failed.length > 0 || errored.length > 0) && retry === false && (
        <section className="job-widget__panel animate">
          <h5 className="job-widget__title job-widget__title--error">
            Houston, we have a problem. <br /> <small className="job-widget__subtitle">Some files could not be uploaded.</small>
          </h5>

          <article className="job-widget__list-container">
            {/* Rejected files */}
            {rejected.length > 0 && (
              <>
                <h6>Rejected Files</h6>
                <ul className="job-widget__list">
                  {rejected.map((rejectedFile: { name: string; reason: string }, index: number) => (
                    <li className="job-widget__item job-widget__item--file" key={`${index}-${rejectedFile.name}`}>
                      {rejectedFile.name} <span>{rejectedFile.reason}</span>
                    </li>
                  ))}
                </ul>
              </>
            )}

            {/* Failed files */}
            {failed.length > 0 && (
              <>
                <h6>Failed to Upload</h6>
                <ul className="job-widget__list">
                  {failed.map((failedFile: File, index: number) => (
                    <li className="job-widget__item job-widget__item--image" key={`${index}-${failedFile.name}`}>
                      <span className="job-widget__item-name">{failedFile.name}</span>
                    </li>
                  ))}
                </ul>
              </>
            )}

            {/* Errored files */}
            {errored.length > 0 && (
              <>
                {Object.entries(getErroredByCategory()).map(([category, files]) => (
                  <div key={category}>
                    <h6>{category}</h6>
                    <ul className="job-widget__list">
                      {files.map((file, index) => (
                        <li key={`${category}-${index}`} className="job-widget__item job-widget__item--file">
                          <span className="job-widget__item-name">{file.filename}</span> {file.message}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </>
            )}
          </article>

          <aside className="job-widget__actions">
            <button className={`button button--outline ${failed.length ? '' : 'hidden'}`} type="button" onClick={handleRetryAll}>
              Retry all
            </button>
            <button className="button" type="button" onClick={handleDismiss}>
              Dismiss all
            </button>
          </aside>
        </section>
      )}

      {/* Keywords */}
      {hold === true && (
        <section className="job-widget__panel animate">
          <h5 className="job-widget__title job-widget__title--warning">
            Keywords! <br />
            <p className="job-widget__subtitle">
              PhotoDay has detected keywords in the photos you’re about to upload. We can use the keywords and convert them into tags or we can ignore them.
            </p>
            <br />
            <br />
            <p className="job-widget__subtitle">What would you like PhotoDay to do?</p>
          </h5>

          <aside className="job-widget__actions">
            <button className="button job-widget__actions-button" type="button" onClick={() => handleKeywordsStrategy('use')}>
              Use keywords
            </button>
            <button className="button" type="button" onClick={() => handleKeywordsStrategy('ignore')}>
              Ignore
            </button>
          </aside>
        </section>
      )}

      {/* Progress status panel */}
      {processing === true && queue.length > 0 && (
        <section className="job-widget__panel animate">
          <div className="job-widget__status">
            <figure className="job-widget__figure">
              <img
                className={`job-widget__thumbnail ${queue[0].data['Orientation'] ? `job-widget__thumbnail--orientation-${queue[0].data['Orientation']}` : ''}`}
                src={queue[0].data['Thumbnail']?.blob ? URL.createObjectURL(queue[0].data['Thumbnail']?.blob) : URL.createObjectURL(queue[0])}
                alt="Thumbnail"
              />
            </figure>

            <article className="job-widget__progress">
              <aside className="job-widget__info">
                {hold === false ? (
                  <div>
                    <span className="job-widget__uploading">Uploading Photos</span>
                    <span className="job-widget__uploading-subtitle">For Gallery</span>
                  </div>
                ) : (
                  <div className="flex items-center">
                    <div className="job-widget-spinner">
                      <div className="job-widget-spinner__circle"></div>
                    </div>
                    <span className="job-widget__uploading">Processing</span>
                  </div>
                )}
                {hold === false && (
                  <button className="button" type="button" onClick={handleCancel}>
                    Cancel
                  </button>
                )}
              </aside>

              {hold === false ? (
                <footer>
                  <span className="job-widget__image-name">{queue[0].name}</span>
                  <div className="job-widget-progress-bar">
                    <span className="job-widget-progress-bar__bar">
                      <span className="job-widget-progress-bar__progress" style={{ width: `${Math.round((current / total) * 100)}%` }}></span>
                    </span>
                  </div>
                  <aside className="job-widget__progress-status">
                    <span>
                      {current} of {total}
                    </span>
                    {time.length >= 10 ? (
                      <>
                        <span>
                          {moment.duration(uploadRemainingDurationMsec).format('h [hr] m [min]', { minValue: 1 }).replace('<', 'less than')} remaining
                        </span>
                        <span>
                          <i>{uploadSpeedKbps}Kbps</i>
                        </span>
                      </>
                    ) : (
                      <span>calculating time...</span>
                    )}
                  </aside>
                </footer>
              ) : null}
            </article>
          </div>
        </section>
      )}

      {/* Processing panel */}
      {processing === true && queue.length === 0 && (
        <section className="job-widget__panel animate">
          <div className="job-widget__status">
            <figure className="job-widget__figure job-widget__figure--gear"></figure>

            <article className="job-widget__progress">
              <aside className="p-3">
                <div className="job-widget-spinner">
                  <div className="job-widget-spinner__circle job-widget-spinner__circle--purple"></div>
                </div>
                <span className="job-widget__uploading">Processing Photos</span>
                <p className="m-0 block">Please be patient while we upload your images, this process could take several minutes</p>
              </aside>

              <footer>
                <div className="job-widget-progress-bar">
                  <span className="job-widget-progress-bar__bar">
                    <span
                      className="job-widget-progress-bar__progress job-widget-progress-bar__progress--purple"
                      style={{ width: `${Math.round((successful / queue.length) * 100)}%` }}
                    ></span>
                  </span>
                </div>
              </footer>
            </article>
          </div>
        </section>
      )}

      {/* Done panel */}
      {processing === false && successful > 0 && total === successful && (
        <section className="job-widget__panel animate">
          <header className="flex items-center justify-between job-widget__header">
            <h5 className="job-widget__title job-widget__title--success m-0">Upload Successful</h5>
            <button className="button" type="button" onClick={handleDone}>
              Done
            </button>
          </header>
          <p className="job-widget__subtitle">
            Great news! Your {successful} photo(s) are uploaded and now in the magic-making process. If everything's good to go, they'll be shining in the
            gallery real soon. Thanks for your patience – we're almost there! 😊
          </p>
        </section>
      )}
    </aside>
  );
};

export default Widget;
