import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  getMyAccountStudioRequest,
  updateMyAccountStudioRequest,
  deleteStripeAccountRequest,
  createStripeExpressOnboarding,
  createStripeExpressComplete,
  updateMyAccountStudioFromCable
} from '../../actions';

// Plugins
// @ts-ignore
import { useActionCable } from 'use-action-cable';

// Components
import GridLoader from '@/components/Shared/ContentLoader/GridLoader';

// Helpers
import { getStripeAuthUri } from './Stripe/selectors';

// Images
import imageStripe from '@/assets/images/stripe-logo.svg';
import imageStripeExpress from '@/assets/images/stripe-express.png';

// Types
import { StudioStripeOnboardingStatus, StudioStripeMigrationStatus, StudioStripeAccountType } from '@/types';

const APP_STUDIO_URL: string = import.meta.env.VITE_STUDIO_URL;
const STRIPE_EXPRESS_REFRESH_URL: string = `${APP_STUDIO_URL}stripe/express/refresh`;
const STRIPE_EXPRESS_RETURN_URL: string = `${APP_STUDIO_URL}stripe/express/return`;

const StudioPayments: FC = () => {
  const dispatch = useDispatch();
  const location: { pathname: string; state: { expressCallbackStatus?: StudioStripeOnboardingStatus } } = useLocation();

  const { studio: loginStudio } = useSelector((state: any) => state.login);
  const { studio: usersStudio, requesting: usersRequesting } = useSelector((state: any) => state.users);
  const {
    id: studioId,
    stripe_express_onboarding_status: studioStripeExpressStatus,
    stripe_user_id: stripeUserId,
    stripe_account_type: stripeAccountType,
    is_multi_studio: isMultiStudio,
    is_parent_studio: isParentStudio,
    resolved_stripe_migration_status: resolvedStripeMigrationStatus,
    use_parents_stripe_account: useParentsStripeAccount
  } = Object.keys(usersStudio ?? {}).length ? usersStudio : loginStudio;

  // UI Handlers
  const handleParentStudioAccountChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const useParentStudioSwitch = event.currentTarget.checked ? true : false;
    dispatch(updateMyAccountStudioRequest({ id: studioId, use_parents_stripe_account: useParentStudioSwitch }));
  };

  const handleExpressMigrationSwitch = (): void => {
    dispatch(
      createStripeExpressOnboarding({ studioId, refresh_url: STRIPE_EXPRESS_REFRESH_URL, return_url: STRIPE_EXPRESS_RETURN_URL }, ({ data }: any) => {
        const { onboarding_url: onboardingUrl } = data;
        window.open(onboardingUrl, '_self');
      })
    );
  };

  const handleStripeStandardDisconnect = (): void => {
    dispatch(deleteStripeAccountRequest(studioId));
  };

  const handleMigrationMessageReceive = {
    received(message: any) {
      if (message.type === 'stripe_migration_status_updated') {
        dispatch(
          updateMyAccountStudioFromCable({
            resolved_stripe_migration_status: message.resolved_stripe_migration_status,
            stripe_migration_deadline: message.stripe_migration_deadline,
            stripe_express_onboarding_status: message.stripe_express_onboarding_status
          })
        );
      }
    }
  };

  useActionCable({ channel: 'StudioEventsChannel', studio_id: studioId }, handleMigrationMessageReceive);

  useEffect(() => {
    const { expressCallbackStatus } = location.state ?? {};

    if (expressCallbackStatus === StudioStripeOnboardingStatus.Complete) {
      dispatch(createStripeExpressComplete({ studioId }));
    } else {
      dispatch(getMyAccountStudioRequest({ studioId }));
    }
  }, []);

  return (
    <>
      <header className="flex items-center justify-between my-studio__header">
        <h2 className="text-headline-sm">Payment Settings</h2>
      </header>

      {usersRequesting && !usersStudio?.id ? (
        <GridLoader rows={3} columns={1} gap={20} minHeight={100} />
      ) : (
        <>
          {/* Multi-Studio */}
          {!isParentStudio && isMultiStudio && (
            <section className="flex justify-between flex-nowrap gap-5 pb-10 mb-10 border-b border-neutral-300">
              <figure className="shrink-0">
                <img src={imageStripeExpress} width="64" height="64" alt="Stripe Express" />
              </figure>
              <article className="grow">
                <h4>Use Parent Studio Stripe Account</h4>
                <p>
                  The Parent Studio for your account has already connected a Stripe account. Select this option if you don’t want to connect a separate Stripe
                  account.
                </p>
              </article>
              <input
                id="useParentsStripeAccount"
                className="hidden"
                name="useParentsStripeAccount"
                type="checkbox"
                checked={useParentsStripeAccount}
                onChange={handleParentStudioAccountChange}
              />
              <label
                htmlFor="useParentsStripeAccount"
                className={`label-switch ${usersRequesting ? 'label-switch--disabled' : ''}`}
                data-loading={usersRequesting}
              />
            </section>
          )}
          {/* Use Parent Studio Stripe Account */}
          {(isParentStudio || (!isParentStudio && !useParentsStripeAccount)) && (
            <div className="animate">
              {/* Switch to Stripe Express */}
              {studioStripeExpressStatus === StudioStripeOnboardingStatus.NotStarted && (
                <section className="flex justify-between flex-nowrap gap-5 pb-10 mb-2.5 border-b border-neutral-300">
                  <figure className="shrink-0">
                    <img src={imageStripeExpress} width="64" height="64" alt="Stripe Express" />
                  </figure>
                  <article>
                    <h4>Switch to Stripe Express</h4>
                    <p>
                      PhotoDay is migrating all users from Stripe Standard to Stripe Express. It’s a secure and easy way to receive the money you earn from
                      selling prints and products in PhotoDay.{' '}
                      <strong>Please create a new Stripe Express account before 12/31/25 to avoid any payment disruptions.</strong>
                    </p>
                  </article>
                  <button className="button button--large" type="button" name="switch" data-loading={usersRequesting} onClick={handleExpressMigrationSwitch}>
                    Make the Switch
                  </button>
                </section>
              )}
              {/* Switch to Stripe Express: Onboarding in Progress */}
              {studioStripeExpressStatus === StudioStripeOnboardingStatus.Incomplete && (
                <section className="flex justify-between flex-nowrap gap-5 pb-10 mb-10 border-b border-neutral-300">
                  <figure className="shrink-0">
                    <img src={imageStripeExpress} width="64" height="64" alt="Stripe Express" />
                  </figure>
                  <article>
                    <h4>Switch to Stripe Express: Onboarding in Progress</h4>
                    <p>
                      <strong>Finish switching to Stripe Express before 12/31/25!</strong> Click the button to resume setting up your account. It’s a secure and
                      easy way to receive the money you earn from selling prints and products in PhotoDay.
                    </p>
                  </article>
                  <button className="button button--large" type="button" name="switch" onClick={handleExpressMigrationSwitch}>
                    Resume Onboarding
                  </button>
                </section>
              )}
              {/* Stripe Express: Connected */}
              {studioStripeExpressStatus === StudioStripeOnboardingStatus.Complete && (
                <section className="flex justify-between flex-nowrap gap-5 pb-10 mb-10 border-b border-neutral-300">
                  <figure className="shrink-0">
                    <img src={imageStripeExpress} width="64" height="64" alt="Stripe Express" />
                  </figure>
                  <article>
                    <h4>Stripe Express: Connected</h4>
                    <p>
                      Receive the money you earn from selling prints and products in PhotoDay securely and easily with our payment processor, Stripe Express.
                      Proceeds from sales are automatically deposited into your bank account every Monday. Instant payout options are available.
                    </p>
                  </article>
                  <a className="button button--large" href="https://connect.stripe.com/express_login" target="_blank" rel="noopener noreferrer">
                    View Dashboard
                  </a>
                </section>
              )}

              {/* Stripe Standard Account */}
              {resolvedStripeMigrationStatus === StudioStripeMigrationStatus.Unavailable && !stripeUserId && (
                <section className="flex justify-between flex-nowrap gap-5">
                  <figure className="shrink-0">
                    <img src={imageStripe} width="64" height="64" alt="Stripe Express" />
                  </figure>
                  <article>
                    <h4>Stripe Standard Account</h4>
                    <p>
                      Stripe is a safe and easy way for you to accept credit card payments from around the world on prints that are being ordered. To start
                      receiving payments, please select the "Connect with Stripe" button below. Proceeds from any print sales will be automatically deposited
                      into your bank account within 7 days.
                    </p>
                  </article>
                  <aside className="flex flex-col gap-5">
                    <a className="button button--large" href={getStripeAuthUri()} target="_blank">
                      Connect with STRIPE
                    </a>
                  </aside>
                </section>
              )}
              {/* Stripe Standard Account: Connected */}
              {stripeAccountType === StudioStripeAccountType.Standard && stripeUserId && (
                <section className="flex justify-between flex-nowrap gap-5">
                  <figure className="shrink-0">
                    <img src={imageStripe} width="64" height="64" alt="Stripe Express" />
                  </figure>
                  <article>
                    <h4>Stripe Standard Account: Connected</h4>
                    {resolvedStripeMigrationStatus === StudioStripeMigrationStatus.Unavailable ? (
                      <p>Your stripe account is connected. Your store can now accept payments.</p>
                    ) : (
                      <p>
                        PhotoDay is migrating all users from Stripe Standard to Stripe Express. Make the switch before 12/31/25 to keep receiving the money you
                        earn from selling prints and products in PhotoDay.
                      </p>
                    )}
                  </article>
                  <aside className="flex flex-col gap-5">
                    <a className="button button--large" href="https://dashboard.stripe.com" target="_blank" rel="noopener noreferrer">
                      View STRIPE Dashboard
                    </a>
                    <button className="button button--danger-outline button--large" type="button" name="disconnect" onClick={handleStripeStandardDisconnect}>
                      Disconnect STRIPE
                    </button>
                  </aside>
                </section>
              )}
              {stripeAccountType === StudioStripeAccountType.Express && (
                <p>
                  Existing users looking for their Stripe standard account,{' '}
                  <a className="button button--link" href="https://dashboard.stripe.com" target="_blank" rel="noopener noreferrer">
                    click here
                  </a>
                  .
                </p>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default StudioPayments;
