import PropTypes from 'prop-types';
import { useState } from 'react';

// Helpers
import * as api from '@/utils/http';
import { successToast } from '@/utils/toast';

const ModalForgotPassword = ({ onClose, isVisible = false }) => {
  const [emailAddress, setEmailAddress] = useState('');
  const [requesting, setRequesting] = useState(false);
  const [userNotFound, setUserNotFound] = useState(false);
  const [emailAddressBlank, setEmailAddressBlank] = useState(true);

  const handleEmailAddress = (evt) => {
    const email = evt.target.value;
    setEmailAddress(email);
    setEmailAddressBlank(email === '');
  };

  const close = () => {
    setEmailAddress('');
    setUserNotFound(false);
    setEmailAddressBlank(true);
    setRequesting(false);
    onClose();
  };

  const onSubmit = (evt) => {
    evt.preventDefault();

    const endpoint = 'studios/forgot-password';
    setUserNotFound(false);
    setRequesting(true);

    api.post(endpoint, { email: emailAddress }, false).then(
      () => {
        close();
        successToast('E-mail with password reset link has been sent.');
      },
      () => {
        setUserNotFound(true);
        setRequesting(false);
      }
    );
  };

  const submitDisabled = requesting || emailAddressBlank;

  if (isVisible) {
    return (
      <aside className="modal animate" data-cy="cy-login-forgot-modal">
        <div className="modal__box modal__box--small">
          <header className="modal__header">
            <button className="button button--action modal__close" name="button" type="button" onClick={close}>
              <i className="icon-close"></i>
            </button>
            <h3>Forgot your password?</h3>
            <p>No worries! Just enter the email address used in your PhotoDay account and we'll send you an email on how you can reset your password.</p>
          </header>
          <main className="modal__content">
            <form onSubmit={onSubmit}>
              <div className={` ${userNotFound && 'has-error'}`}>
                <input
                  className="input--block"
                  name="forgot-email"
                  type="email"
                  value={emailAddress}
                  placeholder="Enter your email address"
                  data-cy="cy-forget-email"
                  onChange={handleEmailAddress}
                />
                <small
                  style={{
                    visibility: userNotFound ? 'visible' : 'hidden'
                  }}
                  className="block text-error-500"
                >
                  User with such E-mail not found
                </small>
              </div>
            </form>
          </main>
          <footer className="text-center modal__footer flex gap-2.5">
            <button className="button button--outline" onClick={close}>
              Cancel
            </button>
            <button className="button" disabled={submitDisabled || requesting} data-cy="cy-forget-submit" onClick={onSubmit}>
              Submit
            </button>
          </footer>
        </div>
      </aside>
    );
  }

  return null;
};

ModalForgotPassword.propTypes = {
  onClose: PropTypes.func,
  isVisible: PropTypes.bool.isRequired
};

export default ModalForgotPassword;
