// Helpers
import * as api from '@/utils/http';

// Types
import { Organization, TableFilter } from '@/types';

export const createOrganization = ({ studioId, newOrganization }: { studioId: string; newOrganization: Partial<Organization> }) => {
  const endpoint = `studios/${studioId}/clients`;
  return api.post(endpoint, newOrganization);
};

export const getOrganizationList = ({ studioId, searchParams }: { studioId: string; searchParams: TableFilter }) => {
  const endpoint = `studios/${studioId}/clients`;
  return api.get(endpoint, searchParams);
};

export const getOrganization = ({ organizationId }: { organizationId: string }) => {
  const endpoint = `clients/${organizationId}`;
  return api.get(endpoint);
};

export const updateOrganization = ({ organizationId, updatedOrganizationInfo }: { organizationId: string; updatedOrganizationInfo: Partial<Organization> }) => {
  const endpoint = `clients/${organizationId}`;
  return api.put(endpoint, updatedOrganizationInfo);
};

export const deleteOrganization = ({ organizationId }: { organizationId: string }) => {
  const endpoint = `clients/${organizationId}`;
  return api.del(endpoint);
};
