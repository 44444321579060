export const orderIdSelector = (props) => {
  const {
    match: {
      params: { orderId }
    }
  } = props;
  return orderId;
};

export const getImagesInOrder = (orderItems) => {
  const images = {};

  orderItems.forEach((item) => {
    item.order_item_products?.forEach((product) =>
      product.order_item_product_nodes?.forEach((node) => {
        if (node.photo?.id) {
          images[node.photo.id] = { ...node.photo };
        }
      })
    );
  });

  return images;
};
