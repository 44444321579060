const alphaCompare = (a, b, dir) => {
  const aName = a.toLocaleUpperCase();
  const bName = b.toLocaleUpperCase();
  if (aName < bName) return dir === 'desc' ? 1 : -1;
  if (aName > bName) return dir === 'desc' ? -1 : 1;
  return 0;
};

const numericalCompare = (a, b, dir) => {
  if (a == null) a = 999999; // Null should be added onto the end
  if (b == null) b = 999999;
  if (a < b) return dir === 'desc' ? 1 : -1;
  if (a > b) return dir === 'desc' ? -1 : 1;
  return 0;
};

const tagsSorter = (tags, sortType) => {
  switch (sortType) {
    case 'tags_alpha_asc':
      return tags.sort((tag1, tag2) => alphaCompare(tag1.name, tag2.name, 'asc'));
    case 'tags_alpha_desc':
      return tags.sort((tag1, tag2) => alphaCompare(tag1.name, tag2.name, 'desc'));
    case 'tags_photo_count_asc':
      return tags.sort((tag1, tag2) => numericalCompare(tag1.photo_count, tag2.photo_count, 'asc'));
    case 'tags_photo_count_desc':
      return tags.sort((tag1, tag2) => numericalCompare(tag1.photo_count, tag2.photo_count, 'desc'));
    case 'tags_manual':
      return tags.sort((tag1, tag2) => numericalCompare(tag1.display_priority, tag2.display_priority, 'asc'));
    default:
      return tags;
  }
};

export default tagsSorter;
