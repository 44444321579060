import * as types from './constants';
import { handleErrorState, handleRequestingState } from '@/utils/reducerHelpers';

const initialState = {
  academy: {
    weekly: {
      id: 'weekly',
      player: 'youtube',
      title: 'PhotoDay Weekly',
      description: '',
      videos: []
    },
    academy: {
      id: 'academy',
      player: 'youtube',
      title: 'Academy',
      description: '',
      videos: []
    },
    webinars: {
      id: 'webinars',
      player: 'youtube',
      title: 'Webinars',
      description: '',
      videos: []
    },
    basics: {
      id: 'basics',
      player: 'vimeo',
      title: 'Basics',
      description: '',
      videos: []
    },
    tips: {
      id: 'tips',
      player: 'vimeo',
      title: 'Tips',
      description: '',
      videos: []
    },
    trailblazer: {
      id: 'trailblazer',
      player: 'vimeo',
      title: 'Trailblazer Talks',
      description: '',
      videos: []
    },
    gallery: {
      id: 'gallery',
      player: 'vimeo',
      title: 'The Gallery',
      description: '',
      videos: []
    },
    'summer-series': {
      id: 'summer_series',
      player: 'vimeo',
      title: 'Summer Series',
      description: '',
      videos: []
    }
  },
  upcomingWebinars: [],
  requesting: false,
  successful: false,
  errors: []
};

const handleAcademyPlaylistSuccess = (state, { payload }) => {
  const { data } = payload;
  const currentAcademy = state.academy;

  data.forEach((playlist) => {
    currentAcademy[playlist.id.replace('_', '-')] = { ...playlist };
  });

  return {
    ...state,
    academy: { ...currentAcademy },
    requesting: false,
    successful: true,
    errors: []
  };
};

const handleGetUpcomingWebinarsSuccess = (state, { payload }) => {
  return {
    ...state,
    upcomingWebinars: [...payload.data],
    requesting: false,
    successful: true,
    errors: []
  };
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_ACADEMY_PLAYLIST_REQUEST:
    case types.GET_UPCOMING_WEBINARS_REQUEST:
      return handleRequestingState(state);

    case types.GET_ACADEMY_PLAYLIST_SUCCESS:
      return handleAcademyPlaylistSuccess(state, payload);

    case types.GET_UPCOMING_WEBINARS_SUCCESS:
      return handleGetUpcomingWebinarsSuccess(state, payload);

    case types.GET_ACADEMY_PLAYLIST_ERROR:
    case types.GET_UPCOMING_WEBINARS_ERROR:
      return handleErrorState(state, payload);

    default: {
      return state;
    }
  }
};

export default reducer;
