import * as types from './constants';

export const addressValidate = (payload, callback) => ({
  type: types.ADDRESS_VALIDATE,
  payload: { payload, callback }
});

export const addressValidateSuccess = (payload) => ({
  type: types.ADDRESS_VALIDATE_SUCCESS,
  payload: { payload }
});

export const addressValidateError = (error) => ({
  type: types.ADDRESS_VALIDATE_ERROR,
  payload: { error }
});
