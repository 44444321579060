import { FC, useRef, useState, useEffect } from 'react';

// Components
import ExportType from './Type';
import ExportPhotos from './Photos';
import ExportCropping from './Cropping';
import ExportFilterGroup from './FilterGroupSubjects';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { createJobExportReady, getJobExport, updateJobExport } from '../../../actions';

// Types
import { Export } from '@/types';

// Images
import ReactImageCrop from '@/assets/images/icon-set-crop.svg?react';
import ReactImageExportType from '@/assets/images/icon-export-type.svg?react';
import ReactImagePrimaryPhoto from '@/assets/images/icon-primary-photo.svg?react';
import ReactImageSelectGroupSubjects from '@/assets/images/icon-select-group-subjects.svg?react';

// Styles
import './style.css';

// Types
interface ExportsSettingsProps {
  match: {
    params: {
      jobId: string;
      id: string;
    };
  };
  history: { push: (location: string) => void; goBack: () => void };
}

const ExportsSettings: FC<ExportsSettingsProps> = ({ match, history }) => {
  const dispatch = useDispatch();

  const jobExportInitRef = useRef<{ values: Export }>({ values: {} });

  const {
    params: { jobId, id: exportId }
  } = match;
  const { job, export: jobExport, requesting } = useSelector((state: any) => state.jobs);

  const [inputExportName, setInputExportName] = useState<string>('');
  const [editInputExportName, setEditInputExportName] = useState<boolean>(false);

  const [exportCurrentStep, setExportCurrentStep] = useState<string | null>(null);
  const [exportStepsCompleted, setExportStepsCompleted] = useState<string[]>([]);

  const [showConfirmExportModal, setShowConfirmExportModal] = useState<boolean>(false);

  const isStartExportDisabled =
    requesting ||
    (jobExport?.export_type?.match(/subject_data|compositing/) && jobExport?.export_type === null) ||
    (jobExport?.export_type?.match(/subject_data|compositing/) === null && typeof jobExport?.crop !== 'boolean') ||
    (JSON.stringify(jobExportInitRef.current.values) === JSON.stringify(jobExport) && exportStepsCompleted.length === 4 && jobExport?.status !== 'created');

  const handleSaveExportName = () => {
    setEditInputExportName(false);
    dispatch(updateJobExport({ exportId, name: inputExportName }));
  };

  const handleExportStepModalClose = () => setExportCurrentStep(null);

  const handleExportStepCompleted = (newValues: Export, step: string) => {
    setExportCurrentStep(null);
    setExportStepsCompleted([...exportStepsCompleted, step]);

    dispatch(updateJobExport({ exportId, ...newValues }));
  };

  const handleReviewExport = () => {
    dispatch(createJobExportReady({ exportId }, () => history.push(`/jobs/${jobId}/services/exports`)));
  };

  useEffect(() => {
    if (exportId !== jobExport.id) {
      dispatch(
        getJobExport({ exportId }, (response: { data: Export }) => {
          const { data } = response;

          setInputExportName(data?.name || '-');
          jobExportInitRef.current.values = data;
        })
      );
    } else {
      setInputExportName(jobExport?.name || '-');
      jobExportInitRef.current.values = { ...jobExport };
    }
  }, []);

  useEffect(() => {
    if (Object.keys(jobExport).length) {
      // Complete steps if already exists
      const stepsCompleted = [];

      // Filter Subjects
      if (jobExport?.subject_ids?.length) {
        stepsCompleted.push('filter');
      }

      // Export Type
      if (jobExport?.export_type) {
        stepsCompleted.push('type');
      }

      // Primary Photos
      if (jobExport?.export_preference) {
        stepsCompleted.push('photos');
      }

      // Set Crop
      if (typeof jobExport?.crop === 'boolean') {
        stepsCompleted.push('cropping');
      }

      if (stepsCompleted.length) {
        setExportStepsCompleted(stepsCompleted);
      }
    }
  }, [jobExport]);

  return (
    <>
      <header className="flex items-center justify-between job-exports__header">
        <aside className="flex items-center">
          <button className="button button--clean button--noborder" onClick={() => history.push(`/jobs/${jobId}/services/exports`)}>
            <i className="icon-back"></i>
          </button>
          <h2 className="text-headline-sm">Exports</h2>
        </aside>
        <button
          className="button button--medium"
          disabled={isStartExportDisabled}
          onClick={() => (jobExport?.export_type?.match(/subject_data|compositing/) === null ? setShowConfirmExportModal(true) : handleReviewExport())}
        >
          Start Export
        </button>
      </header>

      <div className="flex items-start gap-5">
        <div className="basis-3/12 md:basis-full">
          <aside className="panel panel--nomargin">
            <dl>
              <dt>Export File Name</dt>
              <dd className="flex justify-between">
                <fieldset className="fieldset--block fieldset--clean">
                  <input
                    className="input--clean"
                    type="text"
                    name="name"
                    value={inputExportName}
                    onChange={({ target }) => setInputExportName(target.value)}
                    readOnly={editInputExportName === false}
                    maxLength={50}
                  />
                  {editInputExportName ? (
                    <button className="button button--input" disabled={inputExportName === jobExport?.name} onClick={handleSaveExportName}>
                      Save
                    </button>
                  ) : (
                    <button className="button button--icon" onClick={() => setEditInputExportName(true)}>
                      <i className="icon-edit-pencil icon-edit-pencil--gray"></i>
                    </button>
                  )}
                </fieldset>
              </dd>

              <dt>Subjects Included</dt>
              <dd className="text--normalized">{jobExport?.subject_count ? `${jobExport.subject_count} of ${job?.photo_stats?.subjects}` : '-'}</dd>

              <dt>Group Subjects by</dt>
              <dd>{jobExport?.group_by?.replace('session_start', 'check-in_date').replace('_', ' ') || '-'}</dd>

              <dt>Primary Photos Selected</dt>
              <dd>{jobExport?.export_preference?.replace('session_start', 'check-in_date').replace('_', ' ') || '-'}</dd>

              <dt>Export Type</dt>
              <dd>{jobExport?.export_type?.replace('_', ' ') || '-'}</dd>

              {jobExport?.export_type?.match(/subject_data|compositing/) === null && (
                <>
                  <dt>Crop</dt>
                  <dd>{jobExport?.crop === true ? 'Yes' : jobExport?.crop === false ? 'No' : '-'}</dd>
                </>
              )}

              <dt>Export Status</dt>
              <dd>{jobExport?.status?.replace(/_/g, ' ') || '-'}</dd>
            </dl>
          </aside>
        </div>
        <ul className="grid grid-cols-4 md:grid-cols-2 basis-9/12 md:basis-full">
          <li className="job-exports__steps">
            <figure className="flex items-center justify-center job-exports__steps-figure">
              <ReactImageSelectGroupSubjects />
            </figure>
            <h5 className={`job-exports__steps-title ${exportStepsCompleted.includes('filter') ? 'job-exports__steps-title--completed' : ''}`}>
              Select Subjects
            </h5>
            <p className="job-exports__steps-description">Choose which subjects will get exported.</p>
            <button className="button button--lean job-exports__steps-button" onClick={() => setExportCurrentStep('filter')}>
              <b>{exportStepsCompleted.includes('filter') ? 'Update' : 'Select'}</b>
            </button>
          </li>
          <li className={`job-exports__steps ${exportStepsCompleted.includes('filter') ? '' : 'job-exports__steps--disabled'}`}>
            <figure className="flex items-center justify-center job-exports__steps-figure">
              <ReactImageExportType />
            </figure>
            <h5 className={`job-exports__steps-title ${exportStepsCompleted.includes('type') ? 'job-exports__steps-title--completed' : ''}`}>
              Select Export Type
            </h5>
            <p className="job-exports__steps-description">Choose what kind of files to export.</p>
            <button className="button button--lean job-exports__steps-button" onClick={() => setExportCurrentStep('type')}>
              <b>{exportStepsCompleted.includes('type') ? 'Update' : 'Select'}</b>
            </button>
          </li>
          {jobExport?.export_type?.match(/subject_data|compositing/) === null && (
            <>
              <li className={`job-exports__steps ${exportStepsCompleted.includes('type') ? '' : 'job-exports__steps--disabled'} animate`}>
                <figure className="flex items-center justify-center job-exports__steps-figure">
                  <ReactImagePrimaryPhoto />
                </figure>
                <h5 className={`job-exports__steps-title ${exportStepsCompleted.includes('photos') ? 'job-exports__steps-title--completed' : ''}`}>
                  Select Primary Photos
                </h5>
                <p className="job-exports__steps-description">Choose which subject’s photo will get exported.</p>
                <button className="button button--lean job-exports__steps-button" onClick={() => setExportCurrentStep('photos')}>
                  <b>{exportStepsCompleted.includes('photos') ? 'Update' : 'Select'}</b>
                </button>
              </li>
              <li className={`job-exports__steps ${exportStepsCompleted.includes('photos') ? '' : 'job-exports__steps--disabled'} animate`}>
                <figure className="flex items-center justify-center job-exports__steps-figure">
                  <ReactImageCrop />
                </figure>
                <h5 className={`job-exports__steps-title ${exportStepsCompleted.includes('cropping') ? 'job-exports__steps-title--completed' : ''}`}>
                  Set Crop
                </h5>
                <p className="job-exports__steps-description">Let PhotoDay automatically crop your photos.</p>
                <button className="button button--lean job-exports__steps-button" onClick={() => setExportCurrentStep('cropping')}>
                  <b>{exportStepsCompleted.includes('cropping') ? 'Update' : 'Set'}</b>
                </button>
              </li>
            </>
          )}
        </ul>
      </div>

      {/* Select Subjects */}
      {exportCurrentStep === 'filter' && (
        <ExportFilterGroup jobExport={jobExport} onExportStepModalClose={handleExportStepModalClose} onExportStepComplete={handleExportStepCompleted} />
      )}

      {/* Select Export Type */}
      {exportCurrentStep === 'type' && (
        <ExportType jobExport={jobExport} onExportStepModalClose={handleExportStepModalClose} onExportStepComplete={handleExportStepCompleted} />
      )}

      {/* Select Primary Photos */}
      {exportCurrentStep === 'photos' && (
        <ExportPhotos jobExport={jobExport} onExportStepModalClose={handleExportStepModalClose} onExportStepComplete={handleExportStepCompleted} />
      )}

      {/* Set Crop */}
      {exportCurrentStep === 'cropping' && (
        <ExportCropping jobExport={jobExport} onExportStepModalClose={handleExportStepModalClose} onExportStepComplete={handleExportStepCompleted} />
      )}

      {/* Confirm Export */}
      {showConfirmExportModal && (
        <aside className="modal animate">
          <div className="modal__box modal__box--xsmall">
            <button className="button button--action modal__close" name="button" type="button" onClick={() => setShowConfirmExportModal(false)}>
              <i className="icon-close"></i>
            </button>
            {jobExport?.crop ? (
              <article className="text-left">
                <h3>Confirm Export File</h3>
                <p>
                  <small>
                    PhotoDay will begin processing your export file. Once it's completed, you'll be able to review the subjects and photos selected and adjust
                    crop.
                  </small>
                </p>
              </article>
            ) : (
              <article className="text-left">
                <h3>Confirm Export File</h3>
                <p>
                  <small>
                    Before PhotoDay begins processing your export file, please be sure that your photos are cropped correctly according to the export type. If
                    the aspect ratio is incorrect, PhotoDay will automatically resize the photo. Once we've completed processing the file, you'll be able to
                    review the subjects and photos selected.
                  </small>
                </p>
              </article>
            )}
            <footer className="modal__footer modal__footer--fixed">
              <button className="button button--medium button--center" data-loading={requesting} onClick={handleReviewExport}>
                Process Export
              </button>
              <hr />
              <p className="m-0">
                <small>
                  Need help? Check out our{' '}
                  <a
                    href="https://support.photoday.io/en/articles/3379907-what-types-of-data-exports-are-available-to-me"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    support article on export files
                  </a>
                  .
                </small>
              </p>
            </footer>
          </div>
        </aside>
      )}
    </>
  );
};

export default ExportsSettings;
