import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

// Plugins
import Select from 'react-select';

// Redux
import { useDispatch } from 'react-redux';
import { createFlyer, updateFlyer } from '../../../actions';

const flyerOfferTypes = [
  { value: 'pre_picture', label: 'Pre-Picture Day' },
  { value: 'advance_pay', label: 'AdvancePay' },
  { value: 'published', label: 'Published' },
  { value: 'post_picture', label: 'Post-Picture Day' }
];

const AddEdit = ({ history, job, list, flyer, requesting, onAddEditToggle, duplicate }) => {
  const dispatch = useDispatch();
  const [newFlyer, setNewFlyer] = useState({ name: '', type: '' });

  const handleSave = () => {
    const publicText = {
      pre_picture: {
        name: 'Picture Day is Coming!',
        body: 'It’s almost time for photos! Get ready for picture day by signing up for text message updates. Just click the link below to get started. We’ll let you know when the gallery is published and ready for you to shop for prints and products!'
      },
      advance_pay: {
        name: 'Picture Day Is Coming!',
        body: job.advancepay_offer
          ? "Want to save on pictures? Buy an AdvancePay credit to use when your photos are ready, and you'll receive a special offer! You'll get a text message when the gallery is published, and the credit will be automatically applied—just sign in with your phone number at checkout."
          : "Get ready for picture day by buying an AdvancePay credit to use when pictures are ready! You'll receive a text message when the gallery is published, and your credit will be automatically applied to your purchase when you sign in with your mobile number at checkout. Buy in now!"
      },
      published: {
        name: 'Picture Day Information',
        body: 'Exciting news! Your picture day photos are now available! The code below will give you access to your gallery.'
      },
      post_picture: {
        name: 'Your Photos are Ready!',
        body: 'Don’t forget to check out all your great photos from picture day. Use the code below to access your gallery.'
      }
    };

    const privateText = {
      pre_picture: {
        name: 'Picture Day is Coming!',
        body: 'It’s almost time for photos! Get ready for picture day by signing up for text message updates. Just click the link below to get started. We’ll let you know when the gallery is published and ready for you to shop for prints and products!'
      },
      advance_pay: {
        name: 'Picture Day is Coming!',
        body: job.advancepay_offer
          ? "Want to save on pictures? Buy an AdvancePay credit to use when your photos are ready, and you'll receive a special offer! You'll get a text message when the gallery is published, and the credit will be automatically applied—just sign in with your phone number at checkout."
          : "Get ready for picture day by buying an AdvancePay credit to use when pictures are ready! You'll receive a text message when the gallery is published, and your credit will be automatically applied to your purchase when you sign in with your mobile number at checkout. Buy in now!"
      },
      published: {
        name: 'Picture Day Information',
        body: 'Exciting news! Your picture day photos are now available! The code below will give you direct access to your gallery.'
      },
      post_picture: {
        name: 'Your Photos are Ready!',
        body: 'Don’t forget to check out all your great photos from picture day. Use the code below to access your gallery.'
      }
    };

    const newFlyerText = job.access_mode === 'access_per_subject' ? privateText : publicText;
    const newFlyerType = newFlyer.type?.value;

    const values = {
      jobId: job.id,
      internal_name: newFlyer.name,
      ...newFlyerText[newFlyer.type?.value],
      flyer_offer_type: newFlyerType
    };

    const duplicateValues = { ...flyer };

    if (flyer?.id && !duplicate) {
      dispatch(
        updateFlyer({ id: flyer.id, ...values, offer_ids: newFlyerType === 'advance_pay' ? [] : flyer.offer_ids, status: 'saved' }, () => onAddEditToggle())
      );
    } else if (duplicate) {
      const offerIds = [];

      duplicateValues?.offers?.forEach((offer) => offerIds.push(offer.id));

      dispatch(
        createFlyer(
          {
            ...duplicateValues,
            id: null,
            created_at: null,
            updated_at: null,
            jobId: job.id,
            internal_name: newFlyer.name,
            offer_ids: offerIds,
            status: 'saved',
            image: duplicateValues?.image_url
          },
          ({ data }) => history.push(`/jobs/${job.id}/marketing/flyers/${data.id}/design`)
        )
      );
    } else {
      dispatch(createFlyer({ ...values, status: 'unsaved' }, ({ data }) => history.push(`/jobs/${job.id}/marketing/flyers/${data.id}/design`)));
    }
  };
  useEffect(() => {
    if (flyer?.id) {
      const flyerType = flyer.flyer_offer_type ? flyer.flyer_offer_type : 'published';

      setNewFlyer({
        name: duplicate ? `${flyer.internal_name} - copy` : `${flyer.internal_name}`,
        type: flyerOfferTypes.find((type) => type.value === flyerType)
      });
    } else {
      setNewFlyer({ name: `${job.name} ${list ? list.length + 1 : ''} `, type: '' });
    }
  }, []);

  return (
    <aside className="modal animate">
      <div className="modal__box modal__box--xsmall">
        <header className="modal__header">
          <h3 className="text-left">{duplicate ? 'Duplicate Flyer' : flyer?.id ? 'Flyer Settings' : 'Create A New Flyer'}</h3>
          <button className="button button--action modal__close" name="close" type="button" onClick={onAddEditToggle}>
            <i className="icon-close"></i>
          </button>
        </header>
        <main className="modal__content">
          <input
            className="input--block mb-5"
            type="text"
            name="name"
            value={newFlyer.name}
            placeholder="Flyer Name"
            onChange={({ target }) => setNewFlyer({ ...newFlyer, name: target.value })}
            maxLength="50"
            required
          />
          <Select
            className="select"
            classNamePrefix="select"
            placeholder="Flyer Type"
            options={flyerOfferTypes}
            value={newFlyer.type}
            onChange={(select) => setNewFlyer({ ...newFlyer, type: select })}
            isDisabled={duplicate}
          />
        </main>
        <footer className="modal__footer modal__footer--fixed">
          <button
            className="button button--medium button--center"
            name="save"
            type="button"
            onClick={handleSave}
            data-loading={requesting}
            disabled={requesting || !newFlyer.name}
          >
            {flyer?.id && !duplicate ? 'Update' : 'Create'}
          </button>
          <hr />
          <small>
            Need help? Check out our{' '}
            <a href="https://support.photoday.io/en/articles/2225051-how-do-i-create-flyers-to-promote-my-gallery" target="_blank" rel="noopener noreferrer">
              support article on creating flyers
            </a>
            .
          </small>
        </footer>
      </div>
    </aside>
  );
};

AddEdit.propTypes = {
  history: PropTypes.object.isRequired,
  job: PropTypes.object.isRequired,
  flyer: PropTypes.object,
  requesting: PropTypes.bool
};

AddEdit.defaultProps = {
  history: {},
  job: {},
  flyer: {},
  requesting: false
};

export default AddEdit;
