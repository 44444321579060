export const OFFER_LIST_REQUEST = 'OFFER_LIST_REQUEST';
export const OFFER_LIST_SUCCESS = 'OFFER_LIST_SUCCESS';
export const OFFER_LIST_ERROR = 'OFFER_LIST_ERROR';

export const OFFER_GENERATE_CODE_REQUEST = 'OFFER_GENERATE_CODE_REQUEST';
export const OFFER_GENERATE_CODE_SUCCESS = 'OFFER_GENERATE_CODE_SUCCESS';
export const OFFER_GENERATE_CODE_ERROR = 'OFFER_GENERATE_CODE_ERROR';

export const CREATE_OFFER_REQUEST = 'CREATE_OFFER_REQUEST';
export const CREATE_OFFER_SUCCESS = 'CREATE_OFFER_SUCCESS';
export const CREATE_OFFER_ERROR = 'CREATE_OFFER_ERROR';

export const UPDATE_OFFER_REQUEST = 'UPDATE_OFFER_REQUEST';
export const UPDATE_OFFER_SUCCESS = 'UPDATE_OFFER_SUCCESS';
export const UPDATE_OFFER_ERROR = 'UPDATE_OFFER_ERROR';

export const DESTROY_OFFER_REQUEST = 'DESTROY_OFFER_REQUEST';
export const DESTROY_OFFER_SUCCESS = 'DESTROY_OFFER_SUCCESS';
export const DESTROY_OFFER_ERROR = 'DESTROY_OFFER_ERROR';

export const PAUSE_OFFER_REQUEST = 'PAUSE_OFFER_REQUEST';
export const PAUSE_OFFER_SUCCESS = 'PAUSE_OFFER_SUCCESS';
export const PAUSE_OFFER_ERROR = 'PAUSE_OFFER_ERROR';

export const UNPAUSE_OFFER_REQUEST = 'UNPAUSE_OFFER_REQUEST';
export const UNPAUSE_OFFER_SUCCESS = 'UNPAUSE_OFFER_SUCCESS';
export const UNPAUSE_OFFER_ERROR = 'UNPAUSE_OFFER_ERROR';
