const defaultPager = {
  page: 1,
  perPage: 15,
  total: 0,
  totalPages: 0,
  order: null,
  dir: null,
  search: null,
  searchField: null
};

export default defaultPager;
