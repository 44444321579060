// https://docs.imagizer.com/api_reference/
// NOTE: If you provide both crop and width parameters to the final url,
// Imagizer will resize first based on width and then use that image to apply the crop.
// So here we have to apply width (grossWidth) larger than the new cropped width.
// Imgix cropping is based on original image size.

const imagizerCropping = (url, x, y, width, height, options = null) => {
  if (options != null) {
    const { downsizeRatio, grossWidth, grossHeight } = options;

    if (downsizeRatio) {
      // Mutates a crop x,y,width,height base on a ratio...

      x = Math.max(Math.round(x * downsizeRatio), 0);
      y = Math.max(Math.round(y * downsizeRatio), 0);
      width = Math.round(width * downsizeRatio);
      height = Math.round(height * downsizeRatio);

      // Make sure crop width and height are in bounds after scaling
      if ((width && grossWidth && width + x >= grossWidth) || (height && grossHeight && height + y >= grossHeight)) {
        const deltaWidth = width + x - grossWidth + 1;
        const deltaHeight = height + y - grossHeight + 1;
        const delta = Math.max(deltaWidth, deltaHeight);

        width -= delta;
        height -= delta;
      }

      return `${url}&crop=${x},${y},${width},${height}&width=${grossWidth}`;
    }
  }
  return `${url}&crop=${x},${y},${width},${height}`;
};

const imageCropping = ({ url, x, y, width, height, options } = {}) => {
  if (('' + url).substr(0, 5).toLowerCase() === 'data:') {
    return url;
  }
  if (!url) {
    return null;
  }
  // No cropping provided so just return original
  if (x == null || y == null || width == null || height == null) {
    return url;
  }

  return imagizerCropping(url, x, y, width, height, options);
};

// Takes a server crop and converts it to x,y,width,height format
export const serverCropToParams = (crop, photo) => {
  if (crop == null) {
    return null;
  }
  const x = Math.round(photo.width * crop.top_left_x);
  const y = Math.round(photo.height * crop.top_left_y);
  const x2 = Math.round(photo.width * crop.bottom_right_x);
  const y2 = Math.round(photo.height * crop.bottom_right_y);
  return { x: x, y: y, width: x2 - x, height: y2 - y };
};

export const cropImage = (crop, photo, newWidth) => {
  const params = serverCropToParams(crop, photo);
  const downsizeRatio = newWidth / params.width;
  const grossWidth = Math.round(photo.width * downsizeRatio);
  const grossHeight = Math.round(photo.height * downsizeRatio);

  return imageCropping({
    url: photo.image_url,
    ...params,
    ...{ options: { downsizeRatio, grossWidth, grossHeight } }
  });
};
