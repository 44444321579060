export const CREATE_STUDIO_PROMOTION = 'CREATE_STUDIO_PROMOTION';
export const CREATE_STUDIO_PROMOTION_SUCCESS = 'CREATE_STUDIO_PROMOTION_SUCCESS';
export const CREATE_STUDIO_PROMOTION_ERROR = 'CREATE_STUDIO_PROMOTION_ERROR';

export const GET_STUDIO_PROMOTION = 'GET_STUDIO_PROMOTION';
export const GET_STUDIO_PROMOTION_SUCCESS = 'GET_STUDIO_PROMOTION_SUCCESS';
export const GET_STUDIO_PROMOTION_ERROR = 'GET_STUDIO_PROMOTION_ERROR';

export const UPDATE_STUDIO_PROMOTION = 'UPDATE_STUDIO_PROMOTION';
export const UPDATE_STUDIO_PROMOTION_SUCCESS = 'UPDATE_STUDIO_PROMOTION_SUCCESS';
export const UPDATE_STUDIO_PROMOTION_ERROR = 'UPDATE_STUDIO_PROMOTION_ERROR';

export const CANCEL_STUDIO_PROMOTION = 'CANCEL_STUDIO_PROMOTION';
export const CANCEL_STUDIO_PROMOTION_SUCCESS = 'CANCEL_STUDIO_PROMOTION_SUCCESS';
export const CANCEL_STUDIO_PROMOTION_ERROR = 'CANCEL_STUDIO_PROMOTION_ERROR';

export const GET_PHOTODAY_PROMOTION_LIST = 'GET_PHOTODAY_PROMOTION_LIST';
export const GET_PHOTODAY_PROMOTION_LIST_SUCCESS = 'GET_PHOTODAY_PROMOTION_LIST_SUCCESS';
export const GET_PHOTODAY_PROMOTION_LIST_ERROR = 'GET_PHOTODAY_PROMOTION_LIST_ERROR';

export const GET_STUDIO_PROMOTION_JOB_LIST = 'GET_STUDIO_PROMOTION_JOB_LIST';
export const GET_STUDIO_PROMOTION_JOB_LIST_SUCCESS = 'GET_STUDIO_PROMOTION_JOB_LIST_SUCCESS';
export const GET_STUDIO_PROMOTION_JOB_LIST_ERROR = 'GET_STUDIO_PROMOTION_JOB_LIST_ERROR';
