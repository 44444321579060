import { PRODUCT_CATEGORY_LIST_ERROR, PRODUCT_CATEGORY_LIST_REQUEST, PRODUCT_CATEGORY_LIST_SUCCESS } from './constants';

import { handleErrorState, handleRequestingState } from '@/utils/reducerHelpers';

const initialState = {
  entities: {},
  requesting: false,
  successful: false,
  isLoaded: {},
  errors: []
};

const handleListSuccess = (state, payload) => {
  const { entities, labId } = payload;
  const { entities: origEntities, isLoaded } = state;
  const newEntities = Object.assign({}, origEntities, { [labId]: entities });
  const newIsLoaded = Object.assign({}, isLoaded, { [labId]: true });

  return {
    ...state,
    ...{
      entities: newEntities,
      isLoaded: newIsLoaded,
      errors: [],
      requesting: false,
      successful: true
    }
  };
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case PRODUCT_CATEGORY_LIST_REQUEST:
      return handleRequestingState(state);
    case PRODUCT_CATEGORY_LIST_SUCCESS:
      return handleListSuccess(state, payload);
    case PRODUCT_CATEGORY_LIST_ERROR:
      return handleErrorState(state, payload);

    default:
      return state;
  }
};

export default reducer;
