import { FC } from 'react';
import { Switch, Route } from 'react-router-dom';

// Components
import Deleted from './Deleted';
import Archived from './Archived';

const NotFound: FC = () => {
  return (
    <Switch>
      <Route path="/jobs/:jobId/notfound/archived" component={Archived} />
      <Route path="/jobs/:jobId/notfound/deleted" component={Deleted} />
    </Switch>
  );
};

export default NotFound;
