import PropTypes from 'prop-types';
import { memo } from 'react';

// Plugins
import moment from 'moment';

// Helpers
import { normalizePhone } from '@/utils/formatters';
import imageScaling from '@/utils/imageScaling';

// Styles
import './style.css';

// Important Notes
//  This component is used to generate a pdf on the server and act ast the template for html email, because of this, semantic html5 elements and many css properties cannot be used i.e. <figure>, <article>, flexbox, grid, etc. The current cli for PDF service is wkhtmltopdf. Also, the markup and styles are being extracted and sent to the server when the flyer is updated. The extractCSS tool used in the Design component, will extract only single class selectors and remove all spaces, so styles cannot be written in short form like border: 1px solid #f4f4f4; This must be written in long form as border-width: 1px; border-style: solid; border-color: #f4f4f4; Otherwise, this style will not be readable on the server.

const GALLERY_URL = import.meta.env.VITE_GALLERY_URL;
const MY_PHOTODAY_URL = import.meta.env.VITE_MY_PHOTODAY_URL;

const LayoutModernVert = memo(function ({ layoutRef, job, flyer }) {
  const isPrivateJob = job.access_mode === 'access_per_subject';
  const accessCode = isPrivateJob ? '{{access_code}}' : job.access_code;
  const galleryUrl = job.gallery_v4 ? `${MY_PHOTODAY_URL}g/${accessCode}` : GALLERY_URL;

  return (
    <div className="animate" ref={layoutRef}>
      <table className="animate" cellPadding="0" cellSpacing="0" border="0" width="100%">
        <tbody>
          <tr>
            <td align="center">
              <table className="job-marketing-flyers__modern-vert-template" cellPadding="0" cellSpacing="0" border="0" align="center" width="100%">
                <tbody>
                  {/* Sorting Data */}
                  {isPrivateJob && (
                    <tr height="36px">
                      <td align="right">
                        <span className="job-marketing-flyers__modern-vert-sorting">{'{{sorting_data}}'}</span>
                      </td>
                    </tr>
                  )}

                  {/* Banner */}
                  {flyer?.flyer_offer_type === 'advance_pay' && job.advancepay_offer && (
                    <tr>
                      <td>
                        <span className="job-marketing-flyers__modern-vert-banner">
                          {`Get ${job.advancepay_offer.description}`}
                          <br />
                          <small>with an AdvancePay purchase</small>
                        </span>
                      </td>
                    </tr>
                  )}

                  {flyer?.flyer_offer_type !== 'advance_pay' && flyer?.offers && flyer.offers?.length > 0 && (
                    <tr>
                      <td>
                        <span className="job-marketing-flyers__modern-vert-banner">
                          {flyer.offers.length === 1 ? 'Get ' + flyer.offers[0].description : 'Multiple offers available. Buy today!'}
                        </span>
                      </td>
                    </tr>
                  )}

                  {/* Main Image and Body */}
                  <tr>
                    <td>
                      <table cellPadding="0" cellSpacing="0" border="0" align="center" width="100%">
                        <tbody>
                          <tr>
                            {/* Main Image */}
                            {flyer?.show_image && (
                              <td width="38%" valign="middle">
                                <div
                                  className={`job-marketing-flyers__modern-vert-figure animate ${
                                    isPrivateJob && flyer.show_image && !flyer.image_url ? 'job-marketing-flyers__modern-vert-figure-private' : ''
                                  } ${!isPrivateJob && flyer.show_image && !flyer.image_url ? 'job-marketing-flyers__modern-vert-figure--placeholder' : ''}${
                                    flyer.image_url ? 'job-marketing-flyers__modern-vert-figure-bg--none' : ''
                                  }`}
                                >
                                  {isPrivateJob && flyer.show_image && !flyer.image_url && (
                                    <img className="job-marketing-flyers__modern-vert-image" src="{{subject_image}}" alt="" />
                                  )}

                                  {flyer.show_image && flyer.image_url && (
                                    <img
                                      className="job-marketing-flyers__modern-vert-image"
                                      src={imageScaling({ url: flyer.image_url, size: 'large' })}
                                      alt={flyer.image_filename}
                                    />
                                  )}
                                </div>
                              </td>
                            )}

                            {/* Main Details */}
                            <td width={flyer?.show_image ? '62%' : '100%'} valign="top">
                              <div className="job-marketing-flyers__modern-vert-details">
                                {/* Job Logo */}
                                {flyer.show_job_logo && job.logo_url && (
                                  <div className="job-marketing-flyers__modern-vert-job-logo">
                                    <img
                                      src={imageScaling({ url: job.logo_url, size: 'small' })}
                                      alt={job.name}
                                      height="50"
                                      style={{ width: 'auto', height: 'auto', maxHeight: '100%', maxWidth: '100%' }}
                                    />
                                  </div>
                                )}

                                <h1 className="job-marketing-flyers__modern-vert-title">{flyer.name}</h1>
                                {flyer.show_job_date && (
                                  <p className="animate job-marketing-flyers__modern-vert-date">
                                    {`Your picture date ${flyer.flyer_offer_type?.match(/pre_picture|advance_pay/) ? 'is' : 'was'} on`}{' '}
                                    {moment.utc(job.date).format('L')}
                                  </p>
                                )}
                                <div className="job-marketing-flyers__modern-vert-text-small">
                                  {isPrivateJob ? "{{subject_name}}'s" : 'Your'} Access Code for {job.name} is
                                </div>
                                <span className="job-marketing-flyers__modern-vert-access">{isPrivateJob ? '{{access_code}}' : job.access_code}</span>

                                <p className="job-marketing-flyers__modern-vert-body">{flyer.body}</p>

                                <p className="job-marketing-flyers__modern-vert-body">
                                  Text the access code{' '}
                                  <span>
                                    <b>{isPrivateJob ? '{{access_code}}' : job.access_code}</b>{' '}
                                  </span>
                                  to <b>{job.access_phone && normalizePhone(job.access_phone)}</b> to receive access and ongoing alerts* or visit{' '}
                                  <a href={galleryUrl} target="_blank" rel="noopener noreferrer">
                                    {' '}
                                    <b>{galleryUrl}</b>{' '}
                                  </a>{' '}
                                  on your phone or computer.
                                  <br />
                                  {isPrivateJob && <span>Share access to {'{{subject_name}}'}'s gallery by giving this code or link to others.</span>}
                                </p>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>

                  {/* Offers && Job logo */}
                  {((job.advancepay_offer && flyer?.flyer_offer_type === 'advance_pay') ||
                    (flyer.flyer_offer_type !== 'advance_pay' && flyer.offers && flyer.offers.length > 0)) && (
                    <tr>
                      <td>
                        <div className="job-marketing-flyers__modern-vert-offer-container">
                          <table cellPadding="0" cellSpacing="0" border="0" width="100%">
                            <tbody>
                              <tr>
                                {/* AdvancePay Offers */}
                                {job.advancepay_offer && flyer.flyer_offer_type === 'advance_pay' && (
                                  <td style={{ tableLayout: 'fixed', maxWidth: flyer.show_job_logo && job.logo_url ? '70%' : '100%' }} align="center">
                                    <div className="job-marketing-flyers__modern-vert-offers">
                                      {(job.advancepay_offer.max_redemptions || job.advancepay_offer.min_order_price_cents) && (
                                        <>
                                          <div className="job-marketing-flyers__modern-vert-offer-rules">
                                            Offer details:
                                            {job.advancepay_offer.max_redemptions && ` Valid for the first ${job.advancepay_offer.max_redemptions} customers`}
                                            {job.advancepay_offer.max_redemptions && job.advancepay_offer.min_order_price_cents ? ' • ' : '.'}
                                            {job.advancepay_offer.min_order_price_cents &&
                                              `Orders must be $${(job.advancepay_offer.min_order_price_cents / 100).toFixed(2)} or more.`}
                                            {job.advancepay_offer.expiration && (
                                              <>
                                                <br />
                                                <small>
                                                  Expires <b>{moment(job.advancepay_offer.expiration).format('MMMM Do, YYYY')}</b> at <i>11:59pm PST</i>.
                                                </small>
                                              </>
                                            )}
                                          </div>
                                        </>
                                      )}
                                      <p className="job-marketing-flyers__modern-vert-offer-rules">
                                        After you purchase an AdvancePay credit, you will receive a text with the coupon code for this AdvancePay offer. To
                                        redeem your credit, visit the published gallery and log in using the same phone number used when purchasing it. At
                                        checkout, you’ll notice the credit and offer will automatically be applied.{' '}
                                      </p>
                                    </div>
                                  </td>
                                )}

                                {/* Gallery Offers */}
                                {flyer.offers && flyer.offers.length > 0 && (
                                  <>
                                    <td align="center">
                                      {flyer.offers.map((offer, index) => {
                                        if (index > 1) {
                                          return null;
                                        } else {
                                          return (
                                            <div className="job-marketing-flyers__modern-vert-offers" key={index}>
                                              {offer.offer_type === 'shipping' ? (
                                                <span className="job-marketing-flyers__modern-vert-offer-title">Get FREE SHIPPING</span>
                                              ) : (
                                                <span className="job-marketing-flyers__modern-vert-offer-title">
                                                  Save
                                                  {offer.offer_type === 'fixed_amount'
                                                    ? ` $${(offer.fixed_amount_cents / 100).toFixed(2)}`
                                                    : ` ${offer.fixed_percentage}%`}{' '}
                                                  {offer.includes_free_shipping ? '+ FREE SHIPPING! ' : ''}
                                                </span>
                                              )}
                                              <span>
                                                Use coupon code: <span className="job-marketing-flyers__modern-vert-coupon-code">{offer.code}</span>
                                              </span>

                                              <div className="job-marketing-flyers__modern-vert-offer-rules">
                                                {(offer.min_order_price_cents || offer.max_redemptions) && (
                                                  <>
                                                    Offer details:
                                                    {offer.max_redemptions && ` Valid for the first ${offer.max_redemptions} customers`}
                                                    {offer.max_redemptions && offer.min_order_price_cents ? ' • ' : '.'}
                                                    {offer.min_order_price_cents &&
                                                      `Orders must be $${(offer.min_order_price_cents / 100).toFixed(2)} or more.`}
                                                  </>
                                                )}
                                                {offer.expiration && (
                                                  <>
                                                    <br />
                                                    Expires <b> {moment(offer.expiration).format('MMMM Do, YYYY')}</b> at <i>11:59pm PST</i>.
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          );
                                        }
                                      })}
                                    </td>
                                  </>
                                )}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  )}

                  {/* Studio Info */}
                  <tr>
                    {job.meta && (
                      <td>
                        <div className="job-marketing-flyers__modern-vert-studio">
                          <table cellPadding="0" cellSpacing="0" border="0" width="100%">
                            <tbody>
                              <tr>
                                {job.meta?.studio_logo && flyer?.show_studio_logo && (
                                  <td width="30%" align="right">
                                    <div className="animate job-marketing-flyers__modern-vert-studio-logo">
                                      <img src={imageScaling({ url: job.meta.studio_logo, size: 'small' })} alt={job.meta.studio_name} height="24" />
                                    </div>
                                  </td>
                                )}
                                <td
                                  width={job.meta?.studio_logo && flyer?.show_studio_logo ? '50%' : '100%'}
                                  align={job.meta?.studio_logo && flyer?.show_studio_logo ? 'left' : 'center'}
                                  valign="middle"
                                  style={{ verticalAlign: 'middle' }}
                                >
                                  <div>
                                    <h3 className="job-marketing-flyers__modern-vert-studio-name">Photos by {job.meta.studio_name}</h3>
                                    <div> {job.meta.studio_website_url}</div>
                                    <div> {job.meta.studio_contact_email}</div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    )}
                  </tr>

                  {/* Disclaimer */}
                  <tr>
                    <td>
                      <table align="center" width="100%">
                        <tbody>
                          <tr>
                            <td align="center" valign="middle">
                              <div className="job-marketing-flyers__modern-vert-disclaimer">
                                <span>*Data and message rates may apply. Message frequency may vary. Text HELP for assistance.</span>
                                <br />
                                <span>
                                  Text Stop to opt-out. Visit{' '}
                                  <a
                                    className="job-marketing-flyers__modern-vert-instructions-link"
                                    href="https://www.photoday.com/customer-terms"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    https://www.photoday.com/customer-terms
                                  </a>{' '}
                                  for our Terms of <br /> Service and Privacy Policy.
                                </span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
});

LayoutModernVert.propTypes = {
  job: PropTypes.object.isRequired,
  flyer: PropTypes.object.isRequired,
  layoutRef: PropTypes.object.isRequired
};

LayoutModernVert.defaultProps = {
  job: {},
  flyer: {},
  layoutRef: {}
};

export default LayoutModernVert;
