import { call, put, takeLatest, all } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import * as types from './constants';
import * as actions from './actions';

import * as api from '@/api/backgrounds-api';

import errorHandler from '@/utils/errorResponseHandler';

function* createBackgroundsCollectionRequest(action) {
  const {
    payload: { callback, payload }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.createBackgroundsCollection, payload);

    yield put(actions.createBackgroundsCollectionSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createBackgroundsCollectionError, error);

    if (callback) {
      callback(error?.response);
    }
  } finally {
    yield put(hideLoading());
  }
}

function* getBackgroundsCollectionListRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.getBackgroundsCollectionList, payload);

    yield put(actions.getBackgroundsCollectionListSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getBackgroundsCollectionListError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getBackgroundsCollectionRequest(action) {
  const {
    payload: { callback, payload }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.getBackgroundsCollection, payload);

    yield put(actions.getBackgroundsCollectionSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getBackgroundsCollectionError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getBackgroundRequest(action) {
  const {
    payload: { callback, payload }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.getBackground, payload);

    yield put(actions.getBackgroundSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getBackgroundError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* updateBackgroundsCollectionRequest(action) {
  const {
    payload: { callback, payload }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.updateBackgroundsCollection, payload);

    yield put(actions.updateBackgroundsCollectionSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.updateBackgroundsCollectionError, error);

    if (callback) {
      callback(error?.response);
    }
  } finally {
    yield put(hideLoading());
  }
}

function* deleteBackgroundsCollectionRequest(action) {
  const {
    payload: { callback, payload }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.deleteBackgroundsCollection, payload);

    yield put(actions.deleteBackgroundsCollectionSuccess({ response, ...payload }));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.deleteBackgroundsCollectionError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* resetBackgroundRequest(action) {
  const {
    payload: { callback }
  } = action;

  try {
    yield put(showLoading());

    yield put(actions.resetBackgroundSuccess());
  } finally {
    yield put(hideLoading());

    if (callback) {
      callback();
    }
  }
}

function* getBackgroundsWatcher() {
  yield all([
    takeLatest(types.CREATE_BACKGROUNDS_COLLECTION_REQUEST, createBackgroundsCollectionRequest),
    takeLatest(types.GET_BACKGROUNDS_COLLECTION_LIST_REQUEST, getBackgroundsCollectionListRequest),
    takeLatest(types.GET_BACKGROUNDS_COLLECTION_REQUEST, getBackgroundsCollectionRequest),
    takeLatest(types.GET_BACKGROUND_REQUEST, getBackgroundRequest),
    takeLatest(types.UPDATE_BACKGROUNDS_COLLECTION_REQUEST, updateBackgroundsCollectionRequest),
    takeLatest(types.DELETE_BACKGROUNDS_COLLECTION_REQUEST, deleteBackgroundsCollectionRequest),
    takeLatest(types.RESET_BACKGROUND_REQUEST, resetBackgroundRequest)
  ]);
}

export default getBackgroundsWatcher;
