import * as types from './constants';
import { handleErrorState, handleRequestingState } from '@/utils/reducerHelpers';

const initialState = {
  address: {},
  errors: [],
  requesting: false,
  successful: false
};

const handleAddressValidateSuccess = (state, payload) => {
  return {
    ...state,
    address: { ...payload.payload },

    errors: [],
    successful: true,
    requesting: false
  };
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.ADDRESS_VALIDATE:
      return handleRequestingState(state);

    case types.ADDRESS_VALIDATE_SUCCESS:
      return handleAddressValidateSuccess(state, payload);

    case types.ADDRESS_VALIDATE_ERROR:
      return handleErrorState(state, payload);

    default:
      return state;
  }
};

export default reducer;
