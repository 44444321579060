import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

// Plugins
import Select from 'react-select';
import { Tooltip } from 'react-tippy';

// Components
import Header from '../Header';
import Dropdown from '@/components/Shared/Dropdown';
import TableLoader from '@/components/Shared/ContentLoader/TableLoader';

// Redux
import { getJobList } from '../../Jobs/actions';
import { useSelector, useDispatch } from 'react-redux';
import { salesReportRequest, salesReportDownloadRequest } from '../actions';

const headers = [
  { label: 'Month', value: 'month', dynamic: false, orderBy: true },
  { label: 'Gross Sales', value: 'studio_totals', dynamic: false, orderBy: true },
  { label: 'Products Costs', value: 'lab_costs', dynamic: true, orderBy: false },
  { label: 'Lab Shipping Costs', value: 'lab_shipping_costs', dynamic: true, orderBy: false },
  { label: 'PD Fee', value: 'pd_fee', dynamic: true, orderBy: false },
  { label: 'CC Fee', value: 'processing_fee', dynamic: true, orderBy: false },
  {
    label: 'Taxed Amount',
    value: 'taxable_amount',
    dynamic: true,
    orderBy: false,
    tooltip: (
      <p className="text-left m-0">
        Taxable items are determined by the laws of your state. AdvancePay is never taxed.{' '}
        <a href="https://support.photoday.io/en/articles/2208792-how-are-taxes-calculated-with-photoday" target="_blank" rel="noopener noreferrer">
          Learn More
        </a>
        .
      </p>
    )
  },
  {
    label: 'Sales Tax',
    value: 'tax_collectable',
    dynamic: true,
    orderBy: false,
    tooltip: (
      <p className="text-left m-0">
        Click export on this page to download a CSV that includes a breakdown of tax collected.{' '}
        <a href="https://support.photoday.io/en/articles/6705669-taxes-on-items-sold-through-photoday" target="_blank" rel="noopener noreferrer">
          Learn More about taxes in PhotoDay
        </a>
        .
      </p>
    )
  },
  { label: 'State Delivery Fee', value: 'retail_delivery_fees', dynamic: true, orderBy: false },
  {
    label: 'Studio Payout',
    value: 'studio_payouts',
    dynamic: false,
    orderBy: false,
    tooltip: (
      <p className="text-left m-0">
        Total payout to the studio through Stripe. Payouts prior to 2023 include sales tax. Payouts in 2023 and after exclude sales tax.{' '}
        <a href="https://support.photoday.io/en/articles/6705669-taxes-on-items-sold-through-photoday" target="_blank" rel="noopener noreferrer">
          Learn More
        </a>
        .
      </p>
    )
  }
];

const Sales = ({ match }) => {
  const dispatch = useDispatch();

  const { user, studio } = useSelector((state) => state.login);
  const {
    result: { monthly, years, year },
    requesting: reportsRequesting
  } = useSelector((state) => state.reports);
  const {
    jobs: { list },
    requesting: jobsRequesting
  } = useSelector((state) => state.jobs);

  const requesting = reportsRequesting || jobsRequesting;

  const [selectYear, setSelectYear] = useState('');
  const [selectJobs, setSelectJobs] = useState([]);

  const [labels, setLabels] = useState([]);

  const [orderBy, setOrderBy] = useState({});

  const handleYearChange = (year) => setSelectYear(year);

  const handleJobsChange = (jobs) => setSelectJobs(jobs);

  const handleFilter = () =>
    dispatch(salesReportRequest({ year: selectYear.value, jobs: selectJobs && selectJobs.length ? selectJobs.map((item) => item.value) : [] }));

  const handleLabelsChange = (e) => setLabels(e.target.checked ? [...labels, e.target.name] : [...labels.filter((label) => label !== e.target.name)]);

  const handleReportExport = () =>
    dispatch(
      salesReportDownloadRequest({
        year: selectYear?.value,
        jobs: setSelectJobs && selectJobs && selectJobs.length ? selectJobs.map((item) => item.value) : [],
        filter_fields: labels,
        format: 'csv',
        fileName: `${studio.name} - ${year} Taxes.csv`
      })
    );

  const handleOrderBy = (order, dir) => {
    setOrderBy({ order, dir });
    dispatch(salesReportRequest({ year, order, dir }));
  };

  useEffect(() => {
    dispatch(salesReportRequest());
    dispatch(getJobList({ order: 'name', dir: 'ASC', per_page: 10000 }));
  }, []);

  useEffect(() => {
    // Check if all State Delivery Fee values are zero, if so hide it by default
    if (monthly.some((item) => item.retail_delivery_fees > 0)) {
      setLabels(headers.map((item) => item.value));
    } else {
      setLabels(headers.map((item) => item.value).filter((item) => item !== 'retail_delivery_fees'));
    }

    setSelectYear({ value: year, label: year });
  }, [year]);

  return (
    <section className="reports">
      <Header match={match} user={user} />

      <main className="container">
        <aside className="flex justify-between mb-2.5">
          <div className="flex flex-nowrap gap-2.5">
            <Select
              className="select"
              classNamePrefix="select"
              value={selectYear}
              isSearchable={false}
              isLoading={requesting}
              options={years.map((item) => ({ value: item, label: item }))}
              onChange={handleYearChange}
            />
            <Select
              className="select"
              classNamePrefix="select"
              isMulti={true}
              value={selectJobs}
              isClearable={true}
              placeholder="All Jobs"
              isLoading={requesting}
              options={list && list.length && list.map((job) => ({ value: job.id, label: job.name }))}
              onChange={handleJobsChange}
            />
            <button className="button" name="filter" type="button" onClick={handleFilter} disabled={requesting}>
              Filter
            </button>
          </div>
          <div className="flex justify-end md:start flex-nowrap gap-2.5 md:hidden">
            <Dropdown buttonName={'Edit Columns'} buttonExtraClass={'button--lean'}>
              <ul className="panel panel-dropdown">
                <li className="panel-dropdown__item">
                  <h5 className="m-0">Data labels</h5>
                </li>
                <li className="panel-dropdown__item">
                  {headers
                    .filter((item) => !item.dynamic)
                    .map((item, index) => (
                      <fieldset className={index === 2 ? 'fieldset--clean' : ''} key={item.value}>
                        <input id={item.value} className="hidden" type="checkbox" name={item.value} checked={true} readOnly={true} />
                        <label className="label--checkbox label--disabled capitalize" htmlFor={item.value}>
                          {item.label}
                        </label>
                      </fieldset>
                    ))}
                </li>
                <li className="panel-dropdown__item">
                  {headers
                    .filter((item) => item.dynamic)
                    .map((item, index) => (
                      <fieldset className={headers.length - 4 === index ? 'fieldset--clean' : ''} key={item.value}>
                        <input
                          id={item.value}
                          className="hidden"
                          type="checkbox"
                          name={item.value}
                          checked={labels.includes(item.value)}
                          onChange={handleLabelsChange}
                        />
                        <label className="label--checkbox capitalize" htmlFor={item.value}>
                          {item.label}
                        </label>
                      </fieldset>
                    ))}
                </li>
              </ul>
            </Dropdown>
            <Tooltip {...{ title: 'Download CSV', position: 'top', arrow: true }}>
              <button
                className="button button--outline button--small"
                name="export"
                type="button"
                disabled={monthly.length === 0 || requesting}
                onClick={handleReportExport}
              >
                <i className="icon-download-sharp"></i>
              </button>
            </Tooltip>
          </div>
        </aside>
        <article className="table-box">
          <table className="table table--secondary table--compact">
            <thead className="table__header">
              <tr>
                {headers
                  .filter((item) => labels.includes(item.value))
                  .map((item, index) => (
                    <th key={item.value}>
                      {item.orderBy ? (
                        <Dropdown buttonName={item.label} buttonExtraClass={'button--filter'}>
                          <ul className={`panel panel-dropdown panel-dropdown--small ${index === 0 ? 'panel-dropdown--left' : ''}`}>
                            <li
                              className={`panel-dropdown__item ${orderBy.order === item.value && orderBy.dir === 'ASC' ? 'panel-dropdown__item--active' : ''}`}
                              onClick={() => handleOrderBy(item.value, 'ASC')}
                            >
                              Ascending
                            </li>
                            <li
                              className={`panel-dropdown__item ${orderBy.order === item.value && orderBy.dir === 'DESC' ? 'panel-dropdown__item--active' : ''}`}
                              onClick={() => handleOrderBy(item.value, 'DESC')}
                            >
                              Descending
                            </li>
                          </ul>
                        </Dropdown>
                      ) : (
                        <span>
                          {item.label}{' '}
                          {item.tooltip && (
                            <Tooltip html={item.tooltip} theme="light" distance={20} interactive arrow>
                              <i className="fa fa-question-circle-o" />
                            </Tooltip>
                          )}
                        </span>
                      )}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody className="table__body">
              {requesting ? (
                <tr>
                  <td colSpan="15">
                    <TableLoader rows={12} rowHeight={61} />
                  </td>
                </tr>
              ) : monthly.length ? (
                <>
                  {monthly.map((data) => (
                    <tr key={data.month_num}>
                      <td data-header="Month">
                        <Link
                          className="text--normalized"
                          to={{ pathname: '/reports/orders', state: { month: data.month_num, year, ordersFilter: selectJobs } }}
                        >
                          <b>{data.month}</b>
                        </Link>
                      </td>
                      {headers
                        .filter((item) => labels.includes(item.value) && item.value !== 'month')
                        .map((item) => (
                          <td data-header={item.label} key={item.value}>
                            {data[item.value] ? Number(data[item.value] / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '-'}
                          </td>
                        ))}
                    </tr>
                  ))}
                </>
              ) : (
                <tr className="text-center">
                  <td colSpan="13">No items were found.</td>
                </tr>
              )}
            </tbody>
          </table>
        </article>
      </main>
    </section>
  );
};

Sales.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired
  })
};

export default Sales;
