import { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

// Plugins
import moment from 'moment-timezone';
import { isEmpty } from 'lodash';

// Components
import SafeLink from '@/components/Shared/Link';
import Dropdown from '@/components/Shared/Dropdown';
import OrderItemPackage from './OrderItemPackage';
import OrderItemProduct from './OrderItemProducts';
import OrderBasicRetouch from './OrderBasicRetouch';
import FlipContainer from '@/components/Shared/FlipContainer';
import ReprintFullRequestModal from './ReprintFullRequestModal';
import ReprintPartialRequestModal from './ReprintPartialRequestModal';
import TableLoader from '@/components/Shared/ContentLoader/TableLoader';

// Redux
import { connect } from 'react-redux';
import { getJobTimezoneList } from '../../Jobs/actions';
import { orderDetailRequest, createOrderResendReceipt, getRetouchPhotos, getOrderReprints, createOrderReprint } from './actions';

// Helpers
import { orderIdSelector, getImagesInOrder } from './selectors';
import { convertToText } from '@/utils/currency';
import { normalizePhone } from '@/utils/formatters';

// Styles
import './order-detail.css';

const ALLOW_REPRINTS = import.meta.env.VITE_ALLOW_REPRINTS === '1';

const mapStateToProps = (state, ownProps) => {
  const {
    login: {
      studio: { id: studioId }
    },
    jobs: { timezones, requesting: jobRequesting },
    orders: {
      entities: { retouchPhotos, reprintRequests }
    }
  } = state;

  const orderId = orderIdSelector(ownProps);
  const fromInsights = ownProps.location.search.includes('from_insights');
  const fromReports = ownProps.location.state ? ownProps.location.state.fromReports : '';

  return {
    orderId,
    studioId,
    timezones,
    fromReports,
    fromInsights,
    retouchPhotos,
    reprintRequests,
    getImagesInOrder,
    order: state.orders.entities.order || {},
    requesting: state.orders.requesting || jobRequesting
  };
};

const mapDispatchToProps = { orderDetailRequest, createOrderResendReceipt, getJobTimezoneList, getRetouchPhotos, getOrderReprints, createOrderReprint };

const reprintReasonOptions = [
  { label: 'Damaged', value: 'damaged' },
  { label: 'Incorrect Items', value: 'incorrect_items' },
  { label: 'Never Arrived', value: 'never_arrived' },
  { label: 'Incorrect Image, Crop, or Customization', value: 'incorrect_image' }
];

class OrderDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showReprintFullRequestModal: false,
      showReprintPartialRequestModal: false
    };
  }

  componentDidMount() {
    const { orderDetailRequest, studioId, orderId, timezones, getJobTimezoneList, getRetouchPhotos, getOrderReprints } = this.props;

    orderDetailRequest({ studioId, orderId }, ({ data }) => {
      if (data.basic_retouch_items_count) {
        getRetouchPhotos({ id: data.id });
      }
    });

    if (!timezones.length) {
      getJobTimezoneList();
    }

    if (ALLOW_REPRINTS) {
      getOrderReprints({ orderId });
    }
  }

  handleResendReceipt = () => {
    const { orderId, createOrderResendReceipt } = this.props;

    createOrderResendReceipt({ id: orderId });
  };

  renderOrderList() {
    const {
      retouchPhotos,
      order: { order_items, currency, basic_retouch_items_count, basic_retouch_items_price_cents }
    } = this.props;

    const orderItemsPackages = [];
    const orderItemsProducts = [];
    const orderItemDigitalBundles = [];

    const retouchArray = retouchPhotos && basic_retouch_items_count ? Object.values(retouchPhotos).filter((photo) => photo.enabled === true) : [];
    const orderImages = getImagesInOrder(order_items);

    order_items.forEach((orderItem) => {
      const { id, type, price_sheet_item: priceSheetItem } = orderItem;
      const { digital_bundle: isDigitalBundle } = priceSheetItem;

      switch (type) {
        case 'package':
          orderItemsPackages.push(<OrderItemPackage key={id} orderItem={orderItem} retouchArray={retouchArray} />);
          break;

        case 'product':
          if (isDigitalBundle) {
            orderItemDigitalBundles.push(<OrderItemPackage key={id} orderItem={orderItem} retouchArray={retouchArray} />);
          } else {
            orderItemsProducts.push(<OrderItemProduct key={id} orderItem={orderItem} retouchArray={retouchArray} />);
          }
          break;

        default:
      }
    });

    return (
      <div className="order-list">
        {orderItemsPackages.length ? (
          <>
            <div className="order-category__header">
              <h4 className="order-invoice__label">Packages</h4>
            </div>

            {orderItemsPackages}
          </>
        ) : null}

        {orderItemDigitalBundles.length ? (
          <>
            <div className="order-category__header">
              <h4 className="order-invoice__label">Bundles</h4>
            </div>
            {orderItemDigitalBundles}
          </>
        ) : null}

        {orderItemsProducts.length ? (
          <>
            <div className="order-category__header">
              <h4 className="font-bold">A La Carte </h4>
            </div>
            {orderItemsProducts}
            <div className="order-invoice__hr">
              <hr />
            </div>
          </>
        ) : null}

        {basic_retouch_items_count > 0 && (
          <Fragment>
            <div className="order-category__header">
              <h4 className="bold-font">Add-ons</h4>
            </div>
            <OrderBasicRetouch
              currency={currency}
              orderImages={orderImages}
              retouchArray={retouchArray}
              basicRetouchingCount={basic_retouch_items_count}
              basicRetouchSubtotal={basic_retouch_items_price_cents}
              basicRetouchPriceCents={basic_retouch_items_count > 0 ? basic_retouch_items_price_cents / basic_retouch_items_count : 0}
            />
            <div className="order-invoice__hr">
              <hr />
            </div>
          </Fragment>
        )}
      </div>
    );
  }

  getReprintStatusLabel(status) {
    switch (status) {
      case 'requested':
        return 'requested';
      case 'in_progress':
        return 'in progress';
      case 'approved':
        return 'approved';
      case 'approved_manual':
        return 'manually approved';
      case 'failed':
        return 'failed';
      case 'denied':
        return 'denied';
      case 'complete':
        return 'completed';
      default:
        return '';
    }
  }

  handleOpenReprintFullRequestModal = () => {
    this.setState({ showReprintFullRequestModal: true });
  };

  handleOpenReprintPartialRequestModal = () => {
    this.setState({ showReprintPartialRequestModal: true });
  };

  handleSubmitReprintFullRequest = (requestInfo) => {
    const { getOrderReprints, createOrderReprint, orderId } = this.props;
    const { reason, extra_info } = requestInfo;

    createOrderReprint({ orderId, reason, extra_info }, () => {
      this.setState({ showReprintFullRequestModal: false });
      getOrderReprints({ orderId });
    });
  };

  handleSubmitReprintPartialRequest = (requestInfo) => {
    const { getOrderReprints, createOrderReprint, orderId } = this.props;
    const { reason, extraInfo, allProductsSelected, reprintProductIds } = requestInfo;

    let orderReprintProductsAttributes;

    /*
      If selected ALL products for reprint, then need to send empty products list
      to the server, so reprint type will be “full” in this case, b/c if some product
      list was provided, then server will automatically set “partial” to reprint type
    */
    if (allProductsSelected) {
      orderReprintProductsAttributes = [];
    } else {
      orderReprintProductsAttributes = reprintProductIds.map((productId) => ({ order_item_product_id: productId }));
    }

    createOrderReprint({ orderId, reason, extra_info: extraInfo, order_reprint_products_attributes: orderReprintProductsAttributes }, () => {
      this.setState({ showReprintPartialRequestModal: false });
      getOrderReprints({ orderId });
    });
  };

  handleCancelReprintFullRequest = () => {
    this.setState({ showReprintFullRequestModal: false });
  };

  handleCancelReprintPartialRequest = () => {
    this.setState({ showReprintPartialRequestModal: false });
  };

  render() {
    const { order, requesting, fromInsights, orderId, fromReports, location, reprintRequests, timezones } = this.props;
    const { showReprintFullRequestModal, showReprintPartialRequestModal } = this.state;

    if (requesting || isEmpty(order) || orderId !== order.id) {
      return (
        <div id="OrderDetails" className="loading">
          <TableLoader rows={15} />
        </div>
      );
    }

    const {
      num,
      email,
      ex_id,
      offer,
      phone,
      taxes,
      total,
      job_id,
      subject,
      job_name,
      lab_name,
      shipping,
      subtotal,
      bulk_ship,
      gift_cards,
      order_value,
      order_items,
      download_url,
      order_status,
      submitted_at,
      tracking_url,
      offer_invalid,
      customer_name,
      offer_discount,
      tracking_number,
      digital_downloads_only,
      job: { shipping_type, studio_name, studio_reporting_code, meta, time_zone: jobTimeZone },
      retail_delivery_fee_cents
    } = order || {};
    const insightsUrl = `/jobs/${job_id}/insights/sales`;
    const { code: offerCode, description: offerDescription } = order.offer || {};
    const { recipient, line1, line2, city, state, zip, skip_verification: skipVerification } = order.address || {};
    const lastReprintObject = reprintRequests?.length > 0 && reprintRequests[reprintRequests.length - 1];
    const foundJobTimezone = timezones.find((timezone) => timezone.name === jobTimeZone);

    return (
      <>
        <FlipContainer id="storefront__order_detail">
          <div className="flex items-center justify-between order-detail-print--hidden">
            <div>
              {fromInsights && (
                <SafeLink Component={Link} to={insightsUrl} disabled={requesting}>
                  Back to Insights
                </SafeLink>
              )}
              {fromReports && (
                <SafeLink Component={Link} to={{ pathname: fromReports, state: { ...location.state } }} disabled={requesting}>
                  Back to Reports
                </SafeLink>
              )}
              {!fromInsights && !fromReports && (
                <SafeLink Component={Link} to="/storefront/orders" disabled={requesting}>
                  View all orders
                </SafeLink>
              )}
            </div>
            <aside className="flex gap-2.5 justify-end">
              <Link
                className="button button--outline"
                to={{
                  pathname: '/support/refund-request',
                  search: `?customer_name=${customer_name}&customer_email=${email}&order_number=${num}&lab_used=${lab_name}&purchase_type=product&refund_amount=${total}`
                }}
                disabled={requesting}
              >
                Request Refund
              </Link>
              {ALLOW_REPRINTS && !digital_downloads_only && (
                <Dropdown
                  buttonName={'Request Reprint'}
                  buttonExtraClass={'button--outline button-dropdown'}
                  buttonDisabled={reprintRequests?.length > 0 ? reprintRequests[reprintRequests.length - 1].status !== 'complete' || requesting : requesting}
                >
                  <ul className="panel panel-dropdown">
                    <li className="panel-dropdown__item" onClick={this.handleOpenReprintFullRequestModal}>
                      Full
                    </li>
                    <li className="panel-dropdown__item" onClick={this.handleOpenReprintPartialRequestModal}>
                      Partial
                    </li>
                  </ul>
                </Dropdown>
              )}
              <button className="button button--outline" name="resend" type="button" onClick={this.handleResendReceipt} disabled={requesting}>
                Resend Receipt
              </button>
              <button className="button sm:hidden" name="print" type="button" onClick={window.print} disabled={requesting}>
                Print Invoice
              </button>
            </aside>
          </div>

          <div className="order-detail-page">
            {ALLOW_REPRINTS && reprintRequests?.length > 0 && (
              <div className="order-customer__details">
                <h4 className="order-invoice__label">
                  Reprint status: <i>{this.getReprintStatusLabel(lastReprintObject?.status)}</i>
                </h4>
                {lastReprintObject?.tracking_url && (
                  <>
                    <p>
                      <span className="order-invoice__label">Tracking: </span>
                      <a href={lastReprintObject?.tracking_url} target="_blank" rel="noopener noreferrer">
                        {lastReprintObject?.tracking_number}
                      </a>
                    </p>
                    <span className="order-customer__shipping">
                      <p className="order-invoice__label">Ship to: </p>
                      <p>{lastReprintObject?.address?.recipient}</p>
                      <p>{lastReprintObject?.address?.line1}</p>
                      {lastReprintObject?.address?.line2 && <p>{lastReprintObject?.address?.line2}</p>}
                      <p>
                        {lastReprintObject?.address?.city}, {lastReprintObject?.address?.state}, {lastReprintObject?.address?.zip}
                      </p>
                    </span>
                  </>
                )}
              </div>
            )}

            <div className="order-invoice__hr">
              <hr />
            </div>

            <div className="flex order-invoice__details">
              <div className="basis-8/12">
                <h4 className="order-invoice__label"> Order Number - {num} </h4>
                {ex_id && (
                  <p>
                    <span className="order-invoice__label">Lab order number: </span>
                    {ex_id}
                  </p>
                )}
                <p>
                  <span className="order-invoice__label">Order status: </span>
                  <span className="capitalize">{order_status}</span>{' '}
                </p>
                <p>
                  <span className="order-invoice__label">Tracking: </span>
                  {bulk_ship?.shipped_at ? (
                    <Link to={`/jobs/${job_id}/shipping`}>Shipped - {moment(bulk_ship.shipped_at).format('L')}</Link>
                  ) : (
                    <>
                      {tracking_url ? (
                        <a href={tracking_url} target="_blank" rel="noopener noreferrer">
                          {tracking_number}
                        </a>
                      ) : (
                        tracking_number || <i>N/P</i>
                      )}
                    </>
                  )}
                </p>
                <p>
                  <span className="order-invoice__label">Studio: </span>
                  {studio_name} {studio_reporting_code ? `(${studio_reporting_code})` : ''}
                </p>
                <p>
                  <span className="order-invoice__label">Gallery: </span>
                  <Link to={`/jobs/${job_id}/dashboard`}>{job_name}</Link>
                </p>
                <p>
                  <span className="order-invoice__label">Lab: </span>
                  {lab_name}
                </p>
                <p>
                  <span className="order-invoice__label">Date: </span>
                  {submitted_at && foundJobTimezone
                    ? `${moment(submitted_at)?.tz(foundJobTimezone?.zone)?.format('L [@]h:mm A')} (${moment.tz(foundJobTimezone?.zone).zoneAbbr()})`
                    : 'N/A'}
                </p>
              </div>
              <div className="flex basis-2/6 justify-end sm:basis-6/12 sm:justify-start">
                {download_url && (
                  <a className="button button--dark" href={download_url} target="_blank" rel="noopener noreferrer">
                    Download Photos
                  </a>
                )}
              </div>
            </div>

            <div className="order-invoice__hr">
              <hr />
            </div>

            <div className="order-customer__details">
              <h4 className="order-invoice__label">Seller Information</h4>
              <p>
                <span className="order-invoice__label">Studio:</span> {meta.studio_name}
              </p>
              <p>
                <span className="order-invoice__label">Email:</span> {meta.studio_contact_email}
              </p>
              <p>
                <span className="order-invoice__label">Phone:</span> {normalizePhone(meta.studio_contact_phone)}
              </p>
              <p>
                <span className="order-invoice__label">Address:</span> {meta.studio_verification_address}
              </p>
            </div>

            <div className="order-invoice__hr">
              <hr />
            </div>

            <div className="order-customer__details">
              <h4 className="order-invoice__label">Customer Information</h4>
              <span className="order-customer__shipping">
                <p className="order-invoice__label">Ship to: </p>
                {bulk_ship ? (
                  <>
                    <p>{bulk_ship.address.recipient}</p>
                    <p>{bulk_ship.address.line1}</p>
                    {bulk_ship.address.line2 && <p>{bulk_ship.address.line2}</p>}
                    <p>{`${bulk_ship.address.city}, ${bulk_ship.address.state}  ${bulk_ship.address.zip}`}</p>
                    <p className="order-invoice__label">Shipping notes: </p>

                    <p>{bulk_ship.notes}</p>
                  </>
                ) : (
                  <>
                    {order.address ? (
                      <Fragment>
                        <p>{recipient}</p>
                        <p>{line1}</p>
                        {line2 && <p>{line2}</p>}
                        <p>{`${city}, ${state}  ${zip}`}</p>
                        {skipVerification && (
                          <aside className="panel panel--lean">
                            <p className="m-0">
                              <strong>Note:</strong> The address on the order could not be verified but was confirmed and used by the customer.
                            </p>
                          </aside>
                        )}
                      </Fragment>
                    ) : (
                      <p>No address collected</p>
                    )}
                  </>
                )}
              </span>
              {bulk_ship && (
                <span className="order-customer__additional-details">
                  <p className="order-invoice__label">Customer Address: </p>
                  <>
                    {order.address ? (
                      <Fragment>
                        <p>{recipient}</p>
                        <p>{line1}</p>
                        {line2 && <p>{line2}</p>}
                        <p>{`${city}, ${state}  ${zip}`}</p>
                      </Fragment>
                    ) : (
                      <p>No address collected</p>
                    )}
                  </>
                </span>
              )}
              <span className="order-customer__additional-details">
                <p>
                  {' '}
                  <span className="order-invoice__label">Customer Name: </span>
                  {customer_name}{' '}
                </p>
                {shipping_type.includes('bulk') && subject && (
                  <p>
                    <span className="order-invoice__label">Subject Name: </span>
                    {`${subject.first_name} ${subject.last_name}`}
                  </p>
                )}
                <p>
                  {' '}
                  <span className="order-invoice__label">Email: </span>
                  {email}{' '}
                </p>
                <p>
                  {' '}
                  <span className="order-invoice__label">Phone: </span>
                  {normalizePhone(phone)}{' '}
                </p>
              </span>
            </div>

            <div className="order-invoice__hr">
              <hr />
            </div>

            {this.renderOrderList()}

            <footer className="order-invoice__footer">
              {offer && offer_invalid === false && (
                <div className="order-invoice__discount-info">
                  <p className="order-invoice__label">Discount Applied</p>
                  <p>
                    <span className="order-invoice__label">Code: </span>
                    {offerCode}
                  </p>
                  <p>
                    <span className="order-invoice__label">Offer: </span>
                    {offerDescription}
                  </p>
                </div>
              )}

              <div className="order-invoice__total-container">
                <p className="total-container__row">
                  <span className="order-invoice__label"> Subtotal:</span>
                  <span>{convertToText(subtotal, '$')}</span>
                </p>
                <p className="total-container__row">
                  <span className="order-invoice__label"> Shipping:</span>
                  <span>{convertToText(shipping, '$')}</span>
                </p>
                {retail_delivery_fee_cents > 0 && (
                  <p className="total-container__row">
                    <span className="order-invoice__label"> Retail Delivery Fees:</span>
                    <span>{convertToText(retail_delivery_fee_cents, '$')}</span>
                  </p>
                )}
                <p className="total-container__row">
                  <span className="order-invoice__label"> Taxes:</span>
                  <span>{convertToText(taxes, '$')}</span>
                </p>
                <p className="total-container__row">
                  <span className="order-invoice__label">Order Total:</span>
                  <span>{convertToText(order_value, '$')}</span>
                </p>

                {offer_discount > 0 && (
                  <p className="total-container__row">
                    <span className="order-invoice__label">Discount:</span>
                    <span>{convertToText(offer_discount, '-$')}</span>
                  </p>
                )}
                {gift_cards.length > 0 && (
                  <ul className="total-container__row--advancepay">
                    {gift_cards.map((credit) => (
                      <li className="flex justify-between flex-nowrap mb-2.5" key={credit.id}>
                        AdvancePay #{credit.num}:<span>{convertToText(credit.redemption_cents, '-$')}</span>
                      </li>
                    ))}
                  </ul>
                )}

                <p className="total-container__row total-container__row--total">
                  <span className="order-invoice__label"> Balance Paid:</span>
                  <span>
                    <b>{convertToText(total, '$')}</b>
                  </span>
                </p>
              </div>
            </footer>
          </div>
        </FlipContainer>

        {/* Reprint Request Modals */}
        {showReprintFullRequestModal && (
          <ReprintFullRequestModal
            reprintReasonOptions={reprintReasonOptions}
            onSubmit={this.handleSubmitReprintFullRequest}
            onCancel={this.handleCancelReprintFullRequest}
          />
        )}
        {showReprintPartialRequestModal && (
          <ReprintPartialRequestModal
            reprintReasonOptions={reprintReasonOptions}
            orderItems={order_items}
            onSubmit={this.handleSubmitReprintPartialRequest}
            onCancel={this.handleCancelReprintPartialRequest}
          />
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);
