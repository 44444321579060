import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';

// Plugins
import moment from 'moment';
import DatePicker from 'react-datepicker';

// Components
import Sidebar from '../Sidebar';
import Header from '../../Header';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { updateJob } from '../../actions';

// Helpers
import { successToast } from '@/utils/toast';
import { pickerDate } from '@/utils/displayFormats';

// Images
import ReactImageCaptureQR from '@/assets/images/access-capture-qr.svg?react';
import ReactImageSearchAll from '@/assets/images/access-search-all.svg?react';
import ReactIconCircleCheck from '@/assets/images/icon-round-check.svg?react';
import ReactImageSearchTags from '@/assets/images/access-search-tags.svg?react';
import ReactImageGroupGallery from '@/assets/images/access-gallery-group.svg?react';
import ReactImagePublicGallery from '@/assets/images/access-gallery-public.svg?react';
import ReactImagePrivateGallery from '@/assets/images/access-gallery-private.svg?react';
import ReactImageSearchFaceFind from '@/assets/images/access-search-facefind.svg?react';
import ReactImageCaptureFaceFind from '@/assets/images/access-capture-facefind.svg?react';

// Styles
import './style.css';

const GALLERY_URL = import.meta.env.VITE_GALLERY_URL;
const MY_PHOTODAY_URL = import.meta.env.VITE_MY_PHOTODAY_URL;
const CAPTURE_QR_ENABLED = import.meta.env.VITE_CAPTURE_QR_ENABLED === '1';

const Access = ({ match, location, history }) => {
  const urlTextAreaRef = useRef();

  const dispatch = useDispatch();

  const {
    params: { jobId },
    path
  } = match;
  const { sendBack } = location.state ? location.state : false;

  const { job } = useSelector((state) => state.jobs);
  const {
    studio: { feature_flags: studioFeatureFlags }
  } = useSelector((state) => state.login);

  const isStudioCaptureQrAllowed = CAPTURE_QR_ENABLED && studioFeatureFlags?.allow_capture_qr ? true : false;

  const [initialInformation, setInitialInformation] = useState({});
  const [confirmationShow, setConfirmationShow] = useState(false);
  const [information, setInformation] = useState({
    access_mode: '',
    gallery_path: '',
    access_code: '',
    expires_at: '',
    search_opt_tags: false,
    search_opt_face_find: false,
    search_opt_show_all_photos: false,
    show_on_org_page: false,
    show_draft_on_org_page: false,
    show_expired_on_org_page: false
  });

  const showDisplayOrgPage = job?.meta?.client_org_page_enabled;

  const handleAccessTypeChange = (type) => setInformation({ ...information, access_mode: type });

  const handleGallerySearchChange = (searchMethod) => setInformation({ ...information, [searchMethod]: !information[searchMethod] });

  const handleInputChange = ({ target }) => {
    const targetName = target.name;
    const targetValue = target.type === 'checkbox' ? target.checked : target.value.replace(/[^a-zA-Z0-9-]/g, '');

    setInformation({ ...information, [targetName]: targetValue });
  };

  const handleDateChange = (date) => setInformation({ ...information, expires_at: date ? new Date(date) : '' });

  const handleSave = (e) => {
    e.preventDefault();

    dispatch(
      updateJob(
        {
          id: jobId,
          ...information,
          expires_at: information.expires_at ? pickerDate(information.expires_at) : null,
          access_code: initialInformation.access_code === information.access_code ? null : information.access_code
        },
        () => sendBack && history.goBack()
      )
    );
  };

  const handleGalleryPathConfirm = (e) => {
    e.preventDefault();

    if (information.gallery_path !== initialInformation.gallery_path) {
      return setConfirmationShow(true);
    }
    handleSave(e);
  };

  const handleConfirmPathChange = (e) => {
    handleSave(e);

    setConfirmationShow(false);
    setInitialInformation({ ...initialInformation, gallery_path: information.gallery_path });
  };

  const handleCopyUrl = (e) => {
    urlTextAreaRef.current.select();
    document.execCommand('copy');
    e.target.focus();
    successToast('The gallery link has been Successfully copied!');
  };

  const jobInformationSet = (data) => {
    const isAccessModeSet = data.access_mode !== null;
    const showOnOrgPageDefault = !isAccessModeSet && data.meta?.client_auto_show_new_jobs_on_org_page;
    const showOnOrgPageDraftDefault = !isAccessModeSet && data.meta?.client_auto_show_draft_on_org_page;
    const showOnOrgPageExpiredDefault = !isAccessModeSet && data.meta?.client_auto_show_expired_on_org_page;

    const information = {
      expires_at: data.expires_at ? moment(data.expires_at).toDate() : '',
      access_code: data.access_code,
      access_mode: data.access_mode,
      gallery_path: data.gallery_path ? data.gallery_path : '',
      search_opt_tags: data.search_opt_tags,
      search_opt_face_find: data.search_opt_face_find,
      search_opt_show_all_photos: data.search_opt_show_all_photos,
      show_on_org_page: data.show_on_org_page || showOnOrgPageDefault,
      show_draft_on_org_page: data.show_draft_on_org_page || showOnOrgPageDraftDefault,
      show_expired_on_org_page: data.show_expired_on_org_page || showOnOrgPageExpiredDefault
    };

    setInformation(information);
    setInitialInformation(information);
  };

  useEffect(() => {
    if (job?.id) {
      jobInformationSet(job);
    }
  }, [job?.id]);

  return (
    <>
      <Header history={history} jobId={jobId} title="Settings" />

      <main className="container flex job-settings__access">
        <Sidebar jobType={job.access_mode} jobId={jobId} path={path} />

        <form className="basis-9/12 md:basis-full job-settings-store__container" onSubmit={job.job_status === 'onsale' ? handleGalleryPathConfirm : handleSave}>
          <header className="flex items-center justify-between job-settings-access__header">
            <h2 className="text-headline-sm">Gallery Access</h2>
            <button
              type="submit"
              className="button button--medium"
              disabled={job.requesting || JSON.stringify(information) === JSON.stringify(initialInformation)}
            >
              Save
            </button>
          </header>

          <aside className="job-settings-access__type-container">
            <ul className="grid gap-2.5">
              <li
                className={`job-settings-access__type ${job.access_mode ? 'job-settings-access__type--disabled' : ''} ${
                  information.access_mode === 'access_per_subject' ? ' job-settings-access__type--active' : ''
                }`}
                onClick={() => handleAccessTypeChange('access_per_subject')}
              >
                <figure className="job-settings-access__figure">
                  <div className="job-settings__access__image-container job-settings-access__image-gradient-1">
                    <ReactImagePrivateGallery className="job-settings-access__image" alt="private gallery" />
                  </div>
                  <figcaption className="job-settings-access__figcaption">
                    <div>
                      <h3 className="job-settings-access__title">Private</h3>
                      <p>
                        A private gallery job creates separate, closed-access galleries for each individual subject within one main job. Private galleries
                        require subject data and are best used with the Capture App.{' '}
                        <a
                          href="https://support.photoday.io/en/articles/3365562-what-are-the-different-gallery-types-available-in-photoday"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Learn More
                        </a>
                      </p>
                      <p>Note: Bulk shipping is only available for private galleries.</p>
                    </div>
                    <p className="m-0">
                      <strong className="text-body-lg">Examples</strong>
                      <br />
                      Preschool, Elementary, Underclass, etc.
                    </p>
                  </figcaption>
                </figure>
              </li>
              <li
                className={`job-settings-access__type ${job.access_mode ? 'job-settings-access__type--disabled' : ''} ${
                  information.access_mode === 'access_public' ? ' job-settings-access__type--active' : ''
                }`}
                onClick={() => handleAccessTypeChange('access_public')}
              >
                <figure className="job-settings-access__figure">
                  <div className="job-settings__access__image-container job-settings-access__image-gradient-2">
                    <ReactImagePublicGallery className="job-settings-access__image" alt="public gallery" />
                  </div>

                  <figcaption className="job-settings-access__figcaption">
                    <div>
                      <h3 className="job-settings-access__title">Public</h3>
                      <p>
                        A public gallery <strong>does not require an access code </strong> and can be accessed via a direct link.
                      </p>
                      <p>
                        You can control how customers find their photos via{' '}
                        {job.biometrics_enabled ? '3 search options: FaceFind, Tags,' : '2 search options: Tags'} or All Photos
                      </p>
                    </div>
                    <p className="m-0">
                      <strong className="text-body-lg">Examples</strong>
                      <br />
                      Events, Graduations, Youth Sports, etc.
                    </p>
                  </figcaption>
                </figure>
              </li>
              <li
                className={`job-settings-access__type ${job.access_mode ? 'job-settings-access__type--disabled' : ''} ${
                  information.access_mode === 'access_per_job' ? ' job-settings-access__type--active' : ''
                }`}
                onClick={() => handleAccessTypeChange('access_per_job')}
              >
                <figure className="job-settings-access__figure">
                  <div className="job-settings__access__image-container job-settings-access__image-gradient-3">
                    <ReactImageGroupGallery className="job-settings-access__image" alt="group gallery" />
                  </div>
                  <figcaption className="job-settings-access__figcaption">
                    <div>
                      <h3 className="job-settings-access__title">Group</h3>
                      <p>
                        A group gallery <strong>requires an access code</strong> to view photos.
                      </p>
                      <p>
                        You can control how customers find their photos via{' '}
                        {job.biometrics_enabled ? '3 search options: FaceFind, Tags,' : '2 search options: Tags'} or All Photos
                      </p>
                    </div>
                    <p className="m-0">
                      <strong className="text-body-lg">Examples</strong>
                      <br />
                      Events, Graduations, Youth Sports, etc.
                    </p>
                  </figcaption>
                </figure>
              </li>
            </ul>
          </aside>

          {information.access_mode ? (
            <>
              {information.access_mode === 'access_public' && (
                <section className="panel panel--round panel--section animate">
                  <h3 className="text-body-lg">Gallery Link</h3>
                  <p className="job-settings-access__panel-sub-title">
                    {information.access_mode === 'access_public'
                      ? 'Create a unique gallery link that you can share with your customers so they can view their photos.'
                      : 'Customize the gallery link below. Customers will need to enter their unique access code to view their photos.'}
                  </p>
                  <input
                    type="text"
                    value={information.gallery_path}
                    pattern="[a-zA-Z0-9-]+"
                    required
                    minLength="3"
                    maxLength="20"
                    autoComplete="off"
                    placeholder="job-name"
                    name="gallery_path"
                    title="link may contain only numbers, letters and dashes"
                    onChange={handleInputChange}
                  />
                  <div className="job-settings-access__link-container">
                    <textarea
                      className="job-settings-access__link"
                      ref={urlTextAreaRef}
                      rows="1"
                      value={`${job.gallery_v4 ? MY_PHOTODAY_URL : GALLERY_URL}gallery/${information.gallery_path}`}
                      readOnly={true}
                    />
                    <button
                      className="button button--lean job-setting-access__button"
                      type="button"
                      name="url"
                      onClick={handleCopyUrl}
                      disabled={!information.gallery_path}
                    >
                      Copy Link
                    </button>
                  </div>
                </section>
              )}

              {information.access_mode === 'access_per_subject' && (
                <>
                  {job.biometrics_enabled ? (
                    <section className="panel panel--round panel--nopadding animate">
                      <figure className="job-settings-access__figure job-settings-access__figure--hz">
                        <div className="job-settings__access__image-container job-settings__access__image-container--hz job-settings-access__image-gradient-2">
                          <ReactImageCaptureFaceFind className="job-settings-access__image" alt="Capture FaceFind" />
                        </div>
                        <figcaption className="job-settings-access__figcaption job-settings-access__figcaption--hz">
                          <h3 className="job-settings-access__title">FaceFind</h3>
                          <p>
                            Let FaceFind work its magic! Reference photos taken with PhotoDay Capture will automatically match with your finished picture day
                            photos.{' '}
                            <a href="https://support.photoday.io/en/articles/3296074-what-is-photoday-capture" target="_blank" rel="noopener noreferrer">
                              Learn more
                            </a>
                            .
                          </p>
                          {isStudioCaptureQrAllowed && (
                            <small className="mt-auto text-body-sm">
                              FaceFind uses biometric technology to match photos. Where you conduct business may limit the use of FaceFind.{' '}
                              <a href="https://www.photoday.com/biometrics" target="_blank" rel="noopener noreferrer">
                                Learn more
                              </a>
                              .
                            </small>
                          )}
                        </figcaption>
                      </figure>
                    </section>
                  ) : (
                    <>
                      {isStudioCaptureQrAllowed && (
                        <section className="panel panel--round panel--nopadding animate">
                          <figure className="job-settings-access__figure job-settings-access__figure--hz">
                            <div className="job-settings__access__image-container job-settings__access__image-container--hz job-settings-access__image-gradient-4">
                              <ReactImageCaptureQR className="job-settings-access__image" alt="Capture QR" />
                            </div>
                            <figcaption className="job-settings-access__figcaption job-settings-access__figcaption--hz">
                              <h3 className="job-settings-access__title">QR</h3>
                              <p>
                                CaptureQR uses QR codes (instead of FaceFind) to match photos to subjects.{' '}
                                <a
                                  href="https://support.photoday.io/en/articles/8287531-how-do-i-create-qr-codes-with-photoday-capture"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Learn more
                                </a>
                                .
                              </p>
                            </figcaption>
                          </figure>
                        </section>
                      )}
                    </>
                  )}
                </>
              )}
              {showDisplayOrgPage && (
                <section className="panel panel--round panel--section animate">
                  <div className="mb-5">
                    <input
                      id="visible"
                      className="hidden"
                      type="checkbox"
                      name="show_on_org_page"
                      checked={!!information.show_on_org_page}
                      onChange={handleInputChange}
                    />
                    <label htmlFor="visible" className="label--checkbox text-body-lg font-bold">
                      Display Job on Organization Page
                    </label>
                  </div>
                  <div className={information.show_on_org_page ? '' : 'disabled'}>
                    <p>
                      When this job is in AdvancePay or Published, it will automatically be visible on the Organization Page. Select additional gallery statuses
                      you want to display.
                    </p>
                    <fieldset className="mb-0 pl-5">
                      <input
                        id="draft"
                        className="hidden"
                        type="checkbox"
                        name="show_draft_on_org_page"
                        checked={!!information.show_draft_on_org_page}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="draft" className="label--checkbox">
                        Draft
                      </label>
                      <input
                        id="expired"
                        className="hidden"
                        type="checkbox"
                        name="show_expired_on_org_page"
                        checked={!!information.show_expired_on_org_page}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="expired" className="label--checkbox">
                        Expired
                      </label>
                    </fieldset>
                  </div>
                </section>
              )}

              <section className="panel panel--round panel--section animate">
                <h3 className="text-body-lg">{information.access_mode === 'access_public' ? 'Opt-in Code' : 'Access and Opt-in Code'}</h3>
                <p className="job-settings-access__panel-sub-title">
                  {information.access_mode === 'access_per_subject' &&
                    'PhotoDay automatically generates a unique access and opt-in code for each person. Customers will use this code to see only the photos containing that individual.'}
                  {information.access_mode === 'access_per_job' && 'The code below will allow customers to opt-in and access every photo in the job.'}
                  {information.access_mode === 'access_public' && 'Customers can text this code to 90738 and receive gallery communications via text message.'}
                </p>
                {information.access_mode !== 'access_per_subject' && (
                  <>
                    <input
                      className="mb-2.5"
                      type="text"
                      value={information.access_code}
                      name="access_code"
                      pattern="[a-zA-Z0-9-]+"
                      required
                      minLength="3"
                      maxLength="20"
                      autoComplete="off"
                      placeholder="EVENT2019"
                      title="code may contain only numbers, letters and dashes"
                      onChange={handleInputChange}
                    />
                    <ul className="list--bullet">
                      <li>
                        <small>
                          Access codes are <em>NOT</em> case sensitive
                        </small>
                      </li>
                      <li>
                        <small>
                          Special characters and spaces are <em>prohibited</em>
                        </small>
                      </li>
                      <li>
                        <small>
                          To avoid potential confusion, we recommend adding a prefix specific to your studio before any generic access codes. For example,
                          instead of just <em>SPRING22</em>, a studio named PhotoDay could use <em>PDSPRING22</em>
                        </small>
                      </li>
                    </ul>
                  </>
                )}
              </section>

              <section className="panel panel--round panel--section animate">
                <h3 className="text-body-lg">Expiration Date</h3>
                <p className="job-settings-access__panel-sub-title">
                  Set the gallery’s expiration date below, and a text will go out on that date to let your customers know it’s the last day to purchase photos.
                  Once expired, the gallery will go back to Draft mode.
                </p>
                <DatePicker
                  id="date"
                  className="input--date"
                  name="date"
                  autoComplete="off"
                  isClearable={true}
                  minDate={new Date()}
                  dateFormat="MM/dd/yy"
                  placeholderText="MM/DD/YY"
                  selected={information.expires_at}
                  onChange={handleDateChange}
                />
              </section>

              {!job.access_mode && information.access_mode !== 'access_per_subject' && (
                <section className="mt-10">
                  <h2>Gallery Search Methods</h2>
                  <ul>
                    {job.biometrics_enabled && (
                      <li
                        className={`panel panel--round panel--nopadding animate job-settings-search__method ${
                          information.search_opt_face_find ? ' job-settings-search__method--active' : ''
                        }`}
                        onClick={() => handleGallerySearchChange('search_opt_face_find')}
                      >
                        <figure className="job-settings-access__figure job-settings-access__figure--hz">
                          <ReactIconCircleCheck
                            className={`job-settings-search__icon ${information.search_opt_face_find ? 'job-settings-search__icon--active' : ''}`}
                          />
                          <div className="job-settings__access__image-container job-settings__access__image-container--hz job-settings-access__image-gradient-1">
                            <ReactImageSearchFaceFind className="job-settings-access__image" alt="Search FaceFind" />
                          </div>
                          <figcaption className="job-settings-access__figcaption job-settings-access__figcaption--hz">
                            <h3 className="job-settings-access__title">FaceFind (Recommended)</h3>
                            <p>
                              With FaceFind, customers can upload a photo of their desired subject to quickly view a gallery of photos that only contains that
                              subject.{' '}
                              <a
                                href="https://support.photoday.io/en/articles/3307105-can-i-change-the-way-customers-find-their-photos"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Learn more
                              </a>
                            </p>
                            {isStudioCaptureQrAllowed && (
                              <small className="mt-auto text-body-sm">
                                FaceFind uses biometric technology to match photos. Where you conduct business may limit the use of FaceFind.{' '}
                                <a href="https://www.photoday.com/biometrics" target="_blank" rel="noopener noreferrer">
                                  Learn more
                                </a>
                              </small>
                            )}
                          </figcaption>
                        </figure>
                      </li>
                    )}
                    <li
                      className={`panel panel--round panel--nopadding animate job-settings-search__method ${
                        information.search_opt_tags ? ' job-settings-search__method--active' : ''
                      }`}
                      onClick={() => handleGallerySearchChange('search_opt_tags')}
                    >
                      <figure className="job-settings-access__figure job-settings-access__figure--hz">
                        <ReactIconCircleCheck
                          className={`job-settings-search__icon ${information.search_opt_tags ? 'job-settings-search__icon--active' : ''}`}
                        />
                        <div className="job-settings__access__image-container job-settings__access__image-container--hz job-settings-access__image-gradient-4">
                          <ReactImageSearchTags className="job-settings-access__image" alt="Search Tags" />
                        </div>
                        <figcaption className="job-settings-access__figcaption job-settings-access__figcaption--hz">
                          <h3 className="job-settings-access__title">Tags</h3>
                          <p>
                            Tags allow your customers to narrow their search by selecting categories that you create (e.g. team, classroom, etc.).{' '}
                            <a href="https://support.photoday.io/en/articles/3307091-tagging-best-practices" target="_blank" rel="noopener noreferrer">
                              Learn more
                            </a>
                          </p>
                        </figcaption>
                      </figure>
                    </li>
                    <li
                      className={`panel panel--round panel--nopadding animate job-settings-search__method ${
                        information.search_opt_show_all_photos ? ' job-settings-search__method--active' : ''
                      }`}
                      onClick={() => handleGallerySearchChange('search_opt_show_all_photos')}
                    >
                      <figure className="job-settings-access__figure job-settings-access__figure--hz">
                        <ReactIconCircleCheck
                          className={`job-settings-search__icon ${information.search_opt_show_all_photos ? 'job-settings-search__icon--active' : ''}`}
                        />
                        <div className="job-settings__access__image-container job-settings__access__image-container--hz job-settings-access__image-gradient-5">
                          <ReactImageSearchAll className="job-settings-access__image" alt="Search All" />
                        </div>
                        <figcaption className="job-settings-access__figcaption job-settings-access__figcaption--hz">
                          <h3 className="job-settings-access__title">All Photos</h3>
                          <p>Customers can view every photo uploaded into the gallery.</p>
                        </figcaption>
                      </figure>
                    </li>
                  </ul>
                </section>
              )}
            </>
          ) : (
            <aside className="panel panel--round animate">
              <p className="m-0">
                <strong>Note:</strong> Once a gallery type is selected, it cannot be changed.{' '}
                <a
                  href="https://support.photoday.io/en/articles/3365562-what-are-the-different-gallery-types-available-in-photoday"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more about gallery types
                </a>
                .
              </p>
            </aside>
          )}
        </form>
      </main>

      {/* Confirm modal */}
      <aside className={`modal ${confirmationShow ? '' : 'transparent'}`}>
        <div className="modal__box modal__box--small">
          <header className="modal__header">
            <button className="button button--action modal__close" name="button" type="button" onClick={() => setConfirmationShow(false)}>
              <i className="icon-close"></i>
            </button>
            <h3>Confirm</h3>
          </header>
          <main className="modal__content">
            <p>
              You're changing the way customers access this gallery. Any customers who had previous access will no longer be able to access the gallery unless
              they were logged in. Are you sure you want to make this change?
            </p>
          </main>
          <footer className="modal__footer">
            <button className="button button--large" name="button" type="button" onClick={handleConfirmPathChange}>
              Yes, make the change
            </button>
          </footer>
        </div>
      </aside>
    </>
  );
};

Access.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      jobId: PropTypes.string.isRequired
    })
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({
      sendBack: PropTypes.bool.isRequired
    })
  }),
  history: PropTypes.object.isRequired
};

Access.defaultProps = {
  match: {
    params: {
      jobId: ''
    }
  },
  location: {
    state: {
      sendBack: false
    }
  },
  history: {}
};

export default Access;
