import { useEffect, useState } from 'react';

import './style.css';

type GridLoaderProps = { className?: string; rows?: number; columns?: number; gap?: number; minHeight?: number };

const GridLoader = ({ className = '', rows = 1, columns = 5, gap = 10, minHeight }: GridLoaderProps) => {
  const [height, setHeight] = useState('');
  const num: any[] = [...Array(columns * rows)];

  const updateSize = () => {
    const container: any = document.querySelector('.grid-loader__item');

    if (container) {
      const newHeight: any = minHeight ? minHeight + 'px' : container.clientHeight;
      setHeight(newHeight);
    }
  };

  useEffect(() => {
    document.addEventListener('resize', updateSize);

    updateSize();

    return () => {
      document.removeEventListener('resize', updateSize);
    };
  }, []);

  return (
    <div
      className={`grid-loader animate ${className}`}
      style={{ gridTemplateColumns: `repeat(${columns}, 1fr)`, gridGap: `${gap}px`, minHeight: `;${minHeight ? minHeight + 'px' : 'unset'}` }}
    >
      {num.map((_, index) => (
        <div className="grid-loader__item" style={{ height }} key={index}></div>
      ))}
    </div>
  );
};

export default GridLoader;
