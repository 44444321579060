import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useState } from 'react';

// Helpers
import { shortDate } from '@/utils/displayFormats';

// Images
import icon_settings_filled from '@/assets/images/icon-gear.png';
import icon_duplicate from '@/assets/images/icon-duplicate.png';
import icon_trash from '@/assets/images/icon-trash.png';

const VITE_BULK_SHIPPING_ENABLED = import.meta.env.VITE_BULK_SHIPPING_ENABLED === '1';

const PriceSheetsRow = ({ priceSheet, canManagePriceSheet, deleteFunc, cloneFunc }) => {
  const [duplicating, setDuplicating] = useState(false);

  const handleClone = () => {
    setDuplicating(true);
    cloneFunc(priceSheet.id);
  };

  const priceSheetCreated = shortDate(priceSheet.created_at);

  return (
    <tr>
      <td>{priceSheetCreated}</td>
      <td>
        {canManagePriceSheet ? (
          <Link to={`price-sheets/${priceSheet.id}`}>
            <b>{priceSheet.name}</b>
          </Link>
        ) : (
          <span>{priceSheet.name}</span>
        )}
      </td>
      <td>{priceSheet.lab ? priceSheet.lab.name : ''}</td>
      {VITE_BULK_SHIPPING_ENABLED && (
        <td>
          {
            {
              drop_shipping: 'Drop',
              bulk_shipping: 'Bulk',
              drop_or_bulk_shipping: 'Drop & Bulk'
            }[priceSheet.shipping_type]
          }
        </td>
      )}
      {canManagePriceSheet && (
        <td className="!flex">
          <Link to={`price-sheets/${priceSheet.id}`}>
            <img src={icon_settings_filled} className="pricesheet-table-actions" alt="edit" />
          </Link>
          {duplicating ? <span className="loader" /> : <img src={icon_duplicate} onClick={handleClone} className="pricesheet-table-actions" alt="duplicate" />}
          <img src={icon_trash} onClick={() => deleteFunc(priceSheet)} className="pricesheet-table-actions" alt="delete" />
        </td>
      )}
    </tr>
  );
};

PriceSheetsRow.propTypes = {
  priceSheet: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    price_sheet_type: PropTypes.string,
    lab: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  }).isRequired,
  deleteFunc: PropTypes.func.isRequired,
  cloneFunc: PropTypes.func.isRequired,
  canManagePriceSheet: PropTypes.bool.isRequired
};

export default PriceSheetsRow;
