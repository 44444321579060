import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

const TableLoader = ({ rows = 1, rowHeight = 50 }) => {
  const num = [...Array(rows)];

  return (
    <>
      {num.map((row, index) => (
        <div className="table-loader animate" style={{ height: rowHeight }} key={index}></div>
      ))}
    </>
  );
};

TableLoader.propTypes = {
  rows: PropTypes.number,
  rowHeight: PropTypes.number
};

export default TableLoader;
