import { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Plugins
import moment from 'moment-timezone';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { updateJob, getInsightsCustomersSummary, getJobUnsubscribeList } from '../../actions';

// Types
import { Job } from '@/types';

const CampaignSettings: FC = () => {
  const dispatch = useDispatch();

  const params: { jobId: string } = useParams();
  const { jobId } = params;

  const { job, insights, unsubscribes } = useSelector((state: any) => state.jobs);
  const { customersSummary } = insights;
  const isPrivateJob: boolean = job?.access_mode === 'access_per_subject';

  const [initialSwitchInformation, setInitialSwitchInformation] = useState<string>('');
  const [showUnsubscribe, setShowUnsubscribe] = useState<boolean>(false);
  const [seriesSwitches, setSeriesSwitches] = useState<{ preSwitch: boolean; postSwitch: boolean; expirationSwitch: boolean; yearbookSwitch: boolean }>({
    preSwitch: false,
    postSwitch: false,
    expirationSwitch: false,
    yearbookSwitch: false
  });

  const setInitialSetupSwitches = (job: Job) => {
    const switchValues = {
      preSwitch: job.email_campaigns_pre_enabled ?? false,
      postSwitch: job.email_campaigns_post_enabled ?? false,
      expirationSwitch: job.email_campaigns_expires_enabled ?? false,
      yearbookSwitch: job.email_campaigns_yearbook_enabled ?? false
    };

    setSeriesSwitches({ ...switchValues });

    if (!initialSwitchInformation) {
      setInitialSwitchInformation(JSON.stringify(switchValues));
    }
  };

  const handleSave = () => {
    const values = {
      email_campaigns_pre_enabled: seriesSwitches.preSwitch,
      email_campaigns_post_enabled: seriesSwitches.postSwitch,
      email_campaigns_expires_enabled: seriesSwitches.expirationSwitch,
      email_campaigns_yearbook_enabled: seriesSwitches.yearbookSwitch
    };

    dispatch(updateJob({ id: jobId, ...values }));
    setInitialSwitchInformation(JSON.stringify(seriesSwitches));
  };

  const handleUnsubscribeToggle = () => {
    setShowUnsubscribe(true);
  };

  const handleUnsubscribeClose = () => {
    setShowUnsubscribe(false);
  };

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSeriesSwitches({ ...seriesSwitches, [e.target.name]: e.target.checked });
  };

  useEffect(() => {
    if (!job?.requesting) {
      setInitialSetupSwitches(job);
    }
  }, [job]);

  useEffect(() => {
    dispatch(getJobUnsubscribeList({ id: jobId }));
    dispatch(getInsightsCustomersSummary({ id: jobId }));
  }, []);

  return (
    <>
      <header className="job-marketing__header">
        <h2 className="text-headline-sm">Auto Campaign Settings</h2>
        <button
          type="button"
          className="button button--medium"
          onClick={handleSave}
          disabled={job.requesting || JSON.stringify(seriesSwitches) === initialSwitchInformation}
        >
          Save
        </button>
      </header>
      <section className="panel panel--section">
        <header className="mb-7">
          <h4>Email Campaigns</h4>
          <p>PhotoDay will automatically send the following campaigns:</p>
        </header>

        <h4 className="mb-5">Campaign Series</h4>
        <div className="flex mb-2.5">
          <div className="basis-2/6 basis-8/12-md">
            <span>AdvancePay Series</span>
          </div>
          <div className="flex basis-8/12 gap-2.5 items-center basis-2/6-md">
            <a href="https://support.photoday.io/en/articles/5976248-email-campaign-advancepay-series" target="_blank" rel="noopener noreferrer">
              View
            </a>
            <input id="preSwitch" className="hidden" name="preSwitch" type="checkbox" checked={seriesSwitches.preSwitch} onChange={handleSwitchChange} />
            <label htmlFor="preSwitch" className="label-switch label-switch--small" />
          </div>
        </div>
        <div className="flex mb-2.5">
          <div className="basis-2/6 basis-8/12-md">
            <span>Post-Picture Day Series</span>
          </div>
          <div className="flex basis-8/12 gap-2.5 items-center basis-2/6-md">
            <a href="https://support.photoday.io/en/articles/5976254-email-campaign-post-picture-day-series" target="_blank" rel="noopener noreferrer">
              View
            </a>
            <input id="postSwitch" className="hidden" name="postSwitch" type="checkbox" checked={seriesSwitches.postSwitch} onChange={handleSwitchChange} />
            <label className="label-switch label-switch--small" htmlFor="postSwitch" />
          </div>
        </div>
        <div className="flex mb-2.5">
          <div className="basis-2/6 basis-8/12-md">
            <span>Expiration Series</span>
          </div>
          <div className="flex basis-8/12 gap-2.5 items-center basis-2/6-md">
            <a href="https://support.photoday.io/en/articles/5976259-email-campaign-expiration-series" target="_blank" rel="noopener noreferrer">
              View
            </a>
            <input
              id="expirationSwitch"
              className="hidden"
              name="expirationSwitch"
              type="checkbox"
              checked={seriesSwitches.expirationSwitch}
              onChange={handleSwitchChange}
            />
            <label className="label-switch label-switch--small" htmlFor="expirationSwitch" />
          </div>
        </div>
        {isPrivateJob && (
          <div className="flex mb-7">
            <div className="basis-2/6 basis-8/12-md">
              <span>Yearbook Selection Deadline Series</span>
            </div>
            <div className="flex basis-8/12 gap-2.5 items-center basis-2/6-md">
              <a
                href="https://support.photoday.io/en/articles/9503836-email-campaign-yearbook-selection-deadline-series"
                target="_blank"
                rel="noopener noreferrer"
              >
                View
              </a>
              <input
                id="yearbookSwitch"
                className="hidden"
                name="yearbookSwitch"
                type="checkbox"
                checked={seriesSwitches.yearbookSwitch}
                onChange={handleSwitchChange}
              />
              <label className="label-switch label-switch--small" htmlFor="yearbookSwitch" />
            </div>
          </div>
        )}
        <div className="flex">
          <div className="basis-2/6 basis-8/12-md">
            <h4>Unsubscribes</h4>
          </div>
          <div className="flex basis-8/12 basis-2/6-md">
            <button className="button button--link" disabled={unsubscribes.length === 0} onClick={handleUnsubscribeToggle}>
              <b>{unsubscribes.length > 0 ? unsubscribes.length : '00'}</b>
            </button>
          </div>
        </div>
      </section>
      <section className="panel panel--section">
        <header className="mb-7">
          <h4>SMS Campaigns</h4>
          <p>PhotoDay will automatically send the following sms text campaigns:</p>
        </header>

        <h4>Campaign Series</h4>
        <div className="flex mb-2.5">
          <div className="basis-2/6 basis-8/12-md">
            <span>AdvancePay Series</span>
          </div>
          <div className="basis-8/12 basis-2/6-md">
            <a href="https://support.photoday.io/en/articles/5998549-sms-campaign-pre-picture-day-series" target="_blank" rel="noopener noreferrer">
              View
            </a>
          </div>
        </div>
        <div className="flex mb-2.5">
          <div className="basis-2/6 basis-8/12-md">
            <span>Post-Picture Day Series</span>
          </div>
          <div className="basis-8/12 basis-2/6-md">
            <a href="https://support.photoday.io/en/articles/6001290-sms-campaign-post-picture-day-series" target="_blank" rel="noopener noreferrer">
              View
            </a>
          </div>
        </div>
        <div className="flex mb-2.5">
          <div className="basis-2/6 basis-8/12-md">
            <span>Expiration Series</span>
          </div>
          <div className="basis-8/12 basis-2/6-md">
            <a href="https://support.photoday.io/en/articles/6010596-sms-campaign-expiration-series" target="_blank" rel="noopener noreferrer">
              View
            </a>
          </div>
        </div>
        {isPrivateJob && (
          <div className="flex mb-7">
            <div className="basis-2/6 basis-8/12-md mb-2.5">
              <span>Yearbook Selection Deadline Series</span>
            </div>
            <div className="basis-8/12 basis-2/6-md">
              <a
                href="https://support.photoday.io/en/articles/9503830-sms-campaign-yearbook-selection-deadline-series"
                target="_blank"
                rel="noopener noreferrer"
              >
                View
              </a>
            </div>
          </div>
        )}
        <div className="flex">
          <div className="basis-2/6 basis-8/12-md">
            <h4>Opt-outs</h4>
          </div>
          <div className="basis-8/12 basis-2/6-md">
            <span className="font-bold">{customersSummary.opt_outs > 0 ? customersSummary.opt_outs : '00'}</span>
          </div>
        </div>
      </section>

      {/* Unsubscribe modal */}
      <aside className={`modal ${showUnsubscribe ? '' : 'transparent'} text-left`}>
        <div className="modal__box modal__box--small modal__box--nomin">
          <header className="modal__header">
            <h3>Unsubscribed</h3>
            <p>The following emails have unsubscribed to this job's campaigns:</p>
          </header>
          <main className="modal__content">
            <table className="table table--lean">
              <thead className="table__header">
                <tr>
                  <th>Email</th>
                  <th>Unsubscribed on</th>
                </tr>
              </thead>
              <tbody className="table__body">
                {unsubscribes.map((item: { email: string; created_at: string }) => (
                  <tr key={item.created_at}>
                    <td data-header="Email" className="whitespace-nowrap">
                      {item.email}
                    </td>
                    <td data-header="Unsubscribed on">{moment(item.created_at).format('L')}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </main>
          <footer className="text-center modal__footer">
            <button className="button button--large" name="button" type="button" onClick={handleUnsubscribeClose}>
              Done
            </button>
          </footer>
        </div>
      </aside>
    </>
  );
};

export default CampaignSettings;
