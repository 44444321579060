import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { change } from 'redux-form';

import { ReactSelect } from '@/components/Shared/Forms';

const mapStateToProps = (state) => {
  const requesting = state.pricesheets.requesting;
  return { requesting };
};

const mapDispatchToProps = { change };

const GiveawayMain = ({ pricesheets, requesting, change }) => {
  const resetSelectedData = () => {
    change('OfferForm', 'required_products_attributes', [], true);
    change('OfferForm', 'free_products_attributes', [], true);
  };

  return (
    <div className="form-segment">
      <div className="flex">
        <div className="basis-6/12">
          <ReactSelect
            label="Select Type of Giveaway"
            name="freebie_type"
            placeholder="Select..."
            required={true}
            options={[
              { label: 'Free Product', value: 'free_product' },
              { label: 'BOGO', value: 'bogo' }
            ]}
          />
        </div>
        <div className="basis-6/12">
          <ReactSelect
            label="Select Price Sheet"
            name="price_sheet_id"
            placeholder="Select..."
            options={pricesheets}
            required={true}
            loading={requesting}
            onChange={resetSelectedData}
          />
        </div>
      </div>
    </div>
  );
};

GiveawayMain.propTypes = {
  pricesheets: PropTypes.array,
  requesting: PropTypes.bool,
  change: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(GiveawayMain);
