import { expiredToken } from '@/components/Login/actions';
import { put, call } from 'redux-saga/effects';
import { errorToast } from './toast';

function* errorHandler(fn, error) {
  const { message, response } = error;
  const { status: code, data: errorData } = response || {};

  if (code === 401) {
    yield put(expiredToken());
  } else {
    let errorMessage;

    if (errorData?.error_localized) {
      errorMessage = errorData?.error_localized;
    } else if (errorData?.error) {
      errorMessage = errorData?.error;
    } else {
      errorMessage = message;
    }

    yield call(errorToast, errorMessage);
  }

  return yield put(fn(error));
}

export default errorHandler;
