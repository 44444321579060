import * as types from './constants';
import { handleErrorState, handleRequestingState } from '@/utils/reducerHelpers';

const HEADER_PAGE = 'x-page';
const HEADER_PER_PAGE = 'x-per-page';
const HEADER_TOTAL = 'x-total';
const DEFAULT_PAGINATION = { page: 1, perPage: 15, total: 0 };

const initialState = {
  jobs: [],
  promos: {
    current: { list: [], pagination: DEFAULT_PAGINATION },
    past: { list: [], pagination: DEFAULT_PAGINATION },
    custom: { list: [], pagination: DEFAULT_PAGINATION }
  },
  promotions: {
    non_custom: { list: [], pagination: DEFAULT_PAGINATION },
    custom: { list: [], pagination: DEFAULT_PAGINATION }
  },
  requesting: false,
  successful: false,
  errors: []
};

const handleCreateStudioPromotionSuccess = (state, { payload }) => {
  const { promos: statePromos } = state;
  const updatedPromos = {
    ...statePromos,
    current: { ...statePromos.current, list: [...statePromos.current.list, payload.data] }
  };

  return {
    ...state,
    promos: updatedPromos,
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetStudioPromotionSuccess = (state, { payload }) => {
  let type;

  if (payload.archived) {
    type = 'past';
  } else if (payload.custom) {
    type = 'custom';
  } else {
    type = 'current';
  }

  const updatedPromos = {
    ...state.promos,
    [type]: {
      list: payload.data,
      pagination: {
        page: Number(payload.headers[HEADER_PAGE]),
        perPage: Number(payload.headers[HEADER_PER_PAGE]),
        total: Number(payload.headers[HEADER_TOTAL])
      }
    }
  };

  return {
    ...state,
    promos: updatedPromos,
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleUpdateStudioPromotionSuccess = (state, { payload }) => {
  const { promos: statePromos } = state;
  const { data: payloadPromo } = payload;
  const updatedPromos = {
    ...statePromos,
    current: { ...statePromos.current, list: statePromos.current.list.map((promo) => (promo.id === payloadPromo.id ? payloadPromo : promo)) }
  };

  return {
    ...state,
    promos: updatedPromos,
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleCancelStudioPromotionSuccess = (state, { payload }) => {
  const { promos: statePromos } = state;
  const { data: payloadPromo } = payload;
  const updatedPromos = {
    ...statePromos,
    current: { ...statePromos.current, list: statePromos.current.list.map((promo) => (promo.id === payloadPromo.id ? payloadPromo : promo)) }
  };

  return {
    ...state,
    promos: updatedPromos,
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetPhotoDayPromotionListSuccess = (state, { payload }) => {
  let type;

  if (payload.custom) {
    type = 'custom';
  } else {
    type = 'non_custom';
  }

  const updatedPromotions = {
    ...state.promotions,
    [type]: {
      list: payload.data,
      pagination: {
        page: Number(payload.headers[HEADER_PAGE]),
        perPage: Number(payload.headers[HEADER_PER_PAGE]),
        total: Number(payload.headers[HEADER_TOTAL])
      }
    }
  };

  return {
    ...state,
    promotions: updatedPromotions,
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetStudioPromotionJobListSuccess = (state, { payload }) => {
  return {
    ...state,
    jobs: Object.values(payload),
    errors: [],
    successful: true,
    requesting: false
  };
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.CREATE_STUDIO_PROMOTION:
    case types.GET_STUDIO_PROMOTION:
    case types.UPDATE_STUDIO_PROMOTION:
    case types.CANCEL_STUDIO_PROMOTION:
    case types.GET_PHOTODAY_PROMOTION_LIST:
    case types.GET_STUDIO_PROMOTION_JOB_LIST:
      return handleRequestingState(state);

    case types.CREATE_STUDIO_PROMOTION_SUCCESS:
      return handleCreateStudioPromotionSuccess(state, payload);
    case types.GET_STUDIO_PROMOTION_SUCCESS:
      return handleGetStudioPromotionSuccess(state, payload);
    case types.UPDATE_STUDIO_PROMOTION_SUCCESS:
      return handleUpdateStudioPromotionSuccess(state, payload);
    case types.CANCEL_STUDIO_PROMOTION_SUCCESS:
      return handleCancelStudioPromotionSuccess(state, payload);
    case types.GET_PHOTODAY_PROMOTION_LIST_SUCCESS:
      return handleGetPhotoDayPromotionListSuccess(state, payload);
    case types.GET_STUDIO_PROMOTION_JOB_LIST_SUCCESS:
      return handleGetStudioPromotionJobListSuccess(state, payload);

    case types.CREATE_STUDIO_PROMOTION_ERROR:
    case types.GET_STUDIO_PROMOTION_ERROR:
    case types.UPDATE_STUDIO_PROMOTION_ERROR:
    case types.CANCEL_STUDIO_PROMOTION_ERROR:
    case types.GET_PHOTODAY_PROMOTION_LIST_ERROR:
    case types.GET_STUDIO_PROMOTION_JOB_LIST_ERROR:
      return handleErrorState(state, payload);

    default:
      return state;
  }
};

export default reducer;
