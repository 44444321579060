import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

// Plugins
import { Tooltip } from 'react-tippy';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Components
import AddEdit from './AddEdit';
import GridLoader from '@/components/Shared/ContentLoader/GridLoader';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { getFlyerList, deleteFlyer, clearFlyer } from '../../actions';

// Helpers
import imageScaling from '@/utils/imageScaling';
//
// Images
import imageFlyerPlaceholder from '@/assets/images/image-flyer-placeholder.jpg';

// Styles
import './style.css';

const ALLOW_EMAIL_CAMPAIGNS = import.meta.env.VITE_ALLOW_EMAIL_CAMPAIGNS === '1';

const Flyers = ({ history, match }) => {
  const dispatch = useDispatch();

  const {
    params: { jobId }
  } = match;
  const {
    job,
    flyers: { list, requesting: flyersRequesting },
    requesting
  } = useSelector((state) => state.jobs);

  const [showCreate, setShowCreate] = useState(false);

  const [showDuplicate, setShowDuplicate] = useState(false);
  const [duplicateFlyer, setDuplicateFlyer] = useState({});

  const [selected, setSelected] = useState([]);

  const [showDelete, setShowDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState('');

  const handleFlyerSelect = (flyer) =>
    setSelected(selected.some((select) => select === flyer) ? selected.filter((select) => select !== flyer) : [...selected, flyer]);

  const handleFlyerView = (e, flyerId) => {
    e.stopPropagation();
    history.push(`/jobs/${jobId}/marketing/flyers/${flyerId}/design`);
  };

  const handleFlyerHistory = (e, flyerId) => {
    e.stopPropagation();
    history.push(`/jobs/${jobId}/marketing/flyers/${flyerId}/history`);
  };

  const handleViewCampaignSetup = (e) => {
    e.stopPropagation();
    history.push(`/jobs/${jobId}/marketing/campaign-settings`);
  };

  const handleCreateToggle = () => setShowCreate(!showCreate);

  const handleDuplicateToggle = (flyer) => {
    setDuplicateFlyer(flyer);
    setShowDuplicate(!showDuplicate);
  };

  const handleDeleteShow = () => setShowDelete(true);
  const handleDeleteChange = (e) => setConfirmDelete(e.target.value);
  const handleDeleteCancel = () => {
    setSelected([]);
    setConfirmDelete('');
    setShowDelete(false);
  };
  const handleDelete = () => {
    dispatch(
      deleteFlyer({ id: jobId, flyer_ids: selected }, () => {
        handleDeleteCancel();
        dispatch(getFlyerList({ id: jobId }));
      })
    );
  };

  useEffect(() => {
    // Clear previous selected flyer
    dispatch(clearFlyer());
    dispatch(getFlyerList({ id: jobId }));
  }, []);

  return (
    <>
      <header className="job-marketing__header">
        <h2 className="text-headline-sm">Manual Flyers</h2>
      </header>
      <section>
        <header className="flex items-center justify-between">
          <h2 className="text-headline-sm">Studio Marketing</h2>

          <aside className="flex justify-end button-group">
            <Tooltip title="New Flyer" position="top">
              <button className="button button--outline button--small" name="add" type="button" onClick={handleCreateToggle}>
                <i className="icon-add"></i>
              </button>
            </Tooltip>
            {ALLOW_EMAIL_CAMPAIGNS && job.access_mode === 'access_per_subject' && (
              <Tooltip title="Manage Campaign" position="top">
                <button className="button button--outline button--small" name="add" type="button" onClick={handleViewCampaignSetup}>
                  <i className="icon-gear"></i>
                </button>
              </Tooltip>
            )}
            {selected.length === 1 && (
              <button className="button button--outline button--small animate" name="view" type="button" onClick={(e) => handleFlyerView(e, selected)}>
                <i className="icon-view"></i>
              </button>
            )}
            {selected.length > 0 && (
              <button className="button button--outline button--small animate" name="delete" type="button" onClick={handleDeleteShow}>
                <i className="icon-trash"></i>
              </button>
            )}
          </aside>
        </header>
        <p className="mb-5">
          Create your own marketing flyers to email, download, or print.{' '}
          <a
            href="https://support.photoday.io/en/articles/5614221-how-do-i-communicate-with-customers-about-picture-day#h_85d580728d"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </a>
          .
          <br />
          These flyers differ from auto-email campaigns and will not be sent automatically.{' '}
          <a href="https://support.photoday.io/en/articles/6066274-automatic-email-sms-campaigns" target="_blank" rel="noopener noreferrer">
            Learn more about automatic campaigns
          </a>
          .
        </p>

        {flyersRequesting ? (
          <GridLoader rows={1} columns={4} gap={20} minHeight={337} />
        ) : list.length ? (
          <ul className="grid gap-5 job-marketing-flyers">
            {list.map((flyer) => (
              <li key={flyer.id} onClick={() => handleFlyerSelect(flyer.id)}>
                <figure className="flex flex-col items-center justify-between job-marketing-flyers__list-figure">
                  <aside className="flex job-marketing-flyers__list-buttons">
                    <Tooltip title="Duplicate Flyer" position="top">
                      <button className="button button--outline button--small" type="button" draggable="false" onClick={() => handleDuplicateToggle(flyer)}>
                        <i className="icon-duplicate"></i>
                      </button>
                    </Tooltip>
                    <Tooltip title="Open Flyer" position="top">
                      <button className="button button--outline button--small" type="button" draggable="false" onClick={(e) => handleFlyerView(e, flyer.id)}>
                        <i className="icon-edit"></i>
                      </button>
                    </Tooltip>
                    <Tooltip title="Sent History" position="top">
                      <button className="button button--outline button--small" type="button" draggable="false" onClick={(e) => handleFlyerHistory(e, flyer.id)}>
                        <i className="icon-history"></i>
                      </button>
                    </Tooltip>
                  </aside>
                  <LazyLoadImage
                    className={`job-marketing-flyers__list-image ${flyer.status === 'unsaved' ? 'job-marketing-flyers__list-image--center' : ''} ${
                      selected.indexOf(flyer.id) > -1 ? 'job-marketing-flyers__list-image--active' : ''
                    }`}
                    src={flyer.status === 'unsaved' ? imageFlyerPlaceholder : imageScaling({ url: flyer.preview_image_url, size: 'large' })}
                    placeholder={<div className="job-marketing-flyers__list-image job-marketing-flyers__list-image--placeholder" />}
                    alt={flyer.preview_image_filename}
                    draggable="false"
                  />
                  {flyer.flyers_version === 1 && flyer.status !== 'unsaved' && <span className="job-marketing-flyers__list-upgrade">Upgrade</span>}
                  <figcaption className="job-marketing-flyers__list-figcaption">{flyer.internal_name}</figcaption>
                </figure>
              </li>
            ))}
          </ul>
        ) : (
          <aside className="flex justify-center items-center flex-col panel panel--section job-marketing-flyers">
            <h3>Create beautiful flyers to help promote your job and get more sales.</h3>
            <p>
              To begin,{' '}
              <button className="button button--link" name="add" type="button" onClick={handleCreateToggle}>
                create a new flyer
              </button>
              .
            </p>
          </aside>
        )}
      </section>

      {/* Create modal */}
      {showCreate && <AddEdit history={history} job={job} list={list} requesting={requesting} onAddEditToggle={handleCreateToggle} duplicate={false} />}

      {/* Duplicate modal */}
      {showDuplicate && (
        <AddEdit
          history={history}
          job={job}
          list={list}
          requesting={requesting}
          onAddEditToggle={handleDuplicateToggle}
          duplicate={true}
          flyer={duplicateFlyer}
        />
      )}

      {/* Delete modal */}
      <aside className={`modal ${showDelete ? '' : 'transparent'} text-left`}>
        <div className="modal__box modal__box--xsmall modal__box--nomin">
          <header className="modal__header">
            <button className="button button--action modal__close" name="close" type="button" onClick={handleDeleteCancel}>
              <i className="icon-close"></i>
            </button>
            <h3>Delete Flyer(s)</h3>
          </header>
          <main className="modal__content">
            <p>Are you sure you want to delete the selected flyer(s)?</p>
            <p>
              Type <b>"delete"</b> to permanently delete.
            </p>
            <input className="input--block" type="text" name="delete" value={confirmDelete} onChange={handleDeleteChange} maxLength="10" />
          </main>
          <footer className="text-center modal__footer">
            <button
              className="button button--danger button--large"
              name="button"
              type="button"
              onClick={handleDelete}
              disabled={requesting || !(confirmDelete.toLowerCase() === 'delete')}
            >
              Delete
            </button>
          </footer>
        </div>
      </aside>
    </>
  );
};

Flyers.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      jobId: PropTypes.string.isRequired
    })
  })
};

Flyers.defaultProps = {
  history: {},
  match: {
    params: {
      jobId: ''
    }
  }
};

export default Flyers;
