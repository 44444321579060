import * as types from './constants';

export const createStudioRequest = (params) => ({
  type: types.REGISTER_REQUEST,
  payload: {
    params
  }
});

export const createStudioRequestSuccess = (user) => ({
  type: types.REGISTER_SUCCESS,
  payload: {
    user
  }
});

export const createStudioRequestError = (error) => ({
  type: types.REGISTER_ERROR,
  payload: {
    error
  }
});

export const setStudioInformation = (params) => ({
  type: types.SET_STUDIO_INFORMATION,
  payload: {
    params
  }
});

export const setRegisterStage = (params) => ({
  type: types.SET_REGISTER_STAGE,
  payload: {
    params
  }
});

export const getWhccLinkRequest = () => ({
  type: types.GET_WHCC_LINK_REQUEST,
  payload: {}
});

export const getWhccLinkRequestSuccess = (params) => ({
  type: types.GET_WHCC_LINK_SUCCESS,
  payload: {
    ...params
  }
});

export const getWhccLinkRequestError = (error) => ({
  type: types.GET_WHCC_LINK_ERROR,
  payload: {
    error
  }
});

export const getWhccStudioInformation = (whData) => ({
  type: types.GET_WHCC_STUDIO_INFORMATION_REQUEST,
  payload: whData
});

export const getWhccStudioInformationSuccess = (params) => ({
  type: types.GET_WHCC_STUDIO_INFORMATION_SUCCESS,
  payload: {
    ...params
  }
});

export const getWhccStudioInformationError = (error) => ({
  type: types.GET_WHCC_STUDIO_INFORMATION_ERROR,
  payload: {
    error
  }
});

export const resendVerificationEmail = (email) => ({
  type: types.RESEND_VERIFICATION_REQUEST,
  payload: email
});

export const resendVerificationEmailSuccess = () => ({
  type: types.RESEND_VERIFICATION_SUCCESS,
  payload: {}
});

export const resendVerificationEmailError = (error) => ({
  type: types.RESEND_VERIFICATION_ERROR,
  payload: {
    error
  }
});

export const verify = (token) => ({
  type: types.VERIFICATION_REQUEST,
  payload: token
});
