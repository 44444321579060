import { OnChangeValue } from 'react-select';
import { SelectOptionType } from '@/types';

const subjectsFilterOptions: OnChangeValue<SelectOptionType, true> = [
  { label: 'All Subjects', value: 'all_subjects' },
  { label: 'Subjects with a reference photo', value: 'with_ref' },
  { label: 'Subjects with no reference photo', value: 'without_ref' },
  { label: 'Subjects with matched photos', value: 'has_photos' },
  { label: 'Subjects with no matched photos', value: 'has_no_photos' },
  { label: 'Subjects with a reference photo and no matched photos', value: 'with_ref_no_photos' },
  { label: 'Only subjects marked absent', value: 'absent' }
];

export default subjectsFilterOptions;
