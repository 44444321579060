import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

// Plugins
import { IntercomProvider } from 'react-use-intercom';

const INTERCOM_APP_ID = import.meta.env.VITE_INTERCOM_APP_ID ?? '';

interface PhotoDayIntercomProps {
  children: React.ReactNode;
}

const PhotoDayIntercom: React.FC<PhotoDayIntercomProps> = ({ children }) => {
  const location = useLocation();

  const { entities, jwt, user_hash: userHash, isAuthenticated } = useSelector((state: any) => state.login);

  const { login: loginEntity, user: userEntity } = entities;
  const user_id = jwt ? (loginEntity[jwt] || {}).user : null;
  const user = user_id ? userEntity[user_id] : {};

  return (
    <>
      {isAuthenticated ? (
        <IntercomProvider
          appId={INTERCOM_APP_ID}
          autoBoot={true}
          autoBootProps={{
            userId: user?.id,
            email: user?.email,
            name: `${user?.first_name} ${user?.last_name}`,
            userHash,
            customAttributes: {
              pathname: location.pathname
            }
          }}
        >
          {children}
        </IntercomProvider>
      ) : (
        children
      )}
    </>
  );
};

export default PhotoDayIntercom;
