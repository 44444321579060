import { schema } from 'normalizr';
const productCategoryEntity = new schema.Entity('productCategory');
const lab = new schema.Entity('lab');
const products = new schema.Entity('products');

const productCategory = new schema.Entity('productCategories', {
  productCategory: productCategoryEntity,
  products: [products],
  lab: lab
});

const productCategorySchema = [productCategory];

export default productCategorySchema;
