import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Field, reduxForm, formValueSelector } from 'redux-form';
import { integerValidate } from '@/utils/validateHelpers';
import {
  OfferType,
  OfferMain,
  CreditSheet,
  PercentOrFixedOffers,
  OfferOptions,
  FreeShippingForm,
  BogoRequiredOptions,
  BogoFreeOptions,
  GiveawayMain,
  GiveawayOptions
} from './Partials';

const mapStateToProps = (state, ownProps) => {
  let initialValues;

  if (['credit_sheets', 'shipping', 'freebie'].includes(ownProps.offerTypeSelected)) {
    initialValues = Object.assign({ offer_type: ownProps.offerTypeSelected }, ownProps.offer);
  } else {
    initialValues = Object.assign({ offer_type: 'fixed_amount' }, ownProps.offer);
  }

  /* adjust amount initial value */
  if (initialValues.offer_type === 'fixed_amount' && initialValues.fixed_amount_cents) {
    initialValues.amount = (initialValues.fixed_amount_cents / 100).toFixed(2);
  } else if (initialValues.offer_type === 'fixed_percentage' && initialValues.fixed_percentage) {
    initialValues.amount = initialValues.fixed_percentage;
  }

  if (initialValues.shipping_type === 'free') initialValues.sw2 = true;

  if (initialValues.min_order_price_cents) {
    initialValues.min_order_price = initialValues.min_order_price_cents / 100;
  }

  if (initialValues.offer_type === 'freebie') {
    initialValues.free_products_attributes = (initialValues.offer_free_products || []).map(({ product_id, quantity }) => ({ id: product_id, quantity }));
    initialValues.required_products_attributes = (initialValues.offer_required_products || []).map(({ product_id, quantity }) => ({
      id: product_id,
      quantity
    }));
    initialValues.freebie_type = initialValues.required_products_attributes.length > 0 ? 'bogo' : 'free_product';

    initialValues.discount_type = 'free';
    if (initialValues.fixed_amount_cents) {
      initialValues.amount = (initialValues.fixed_amount_cents / 100).toFixed(2);
      initialValues.discount_type = 'fixed_amount';
    } else if (initialValues.fixed_percentage) {
      initialValues.amount = initialValues.fixed_percentage;
      initialValues.discount_type = 'fixed_percentage';
    }
  }

  const offerType = formValueSelector('OfferForm')(state, 'offer_type') || initialValues.offer_type || 'fixed_amount';

  const discountValue = formValueSelector('OfferForm')(state, 'discount_type');
  const discountType = (discountValue && discountValue.value) || discountValue || initialValues.discount_type;

  const freebieValue = formValueSelector('OfferForm')(state, 'freebie_type');
  const freebieType = (freebieValue && freebieValue.value) || freebieValue || initialValues.freebie_type;

  const { requestingOffers } = state.offers;
  const { requesting: requestingAdvancepay } = state.advancepay;

  return {
    offerType,
    freebieType,
    discountType,
    initialValues,
    requestingOffers,
    requestingAdvancepay,
    amount: initialValues.amount
  };
};

class OfferForm extends Component {
  static propTypes = {
    initialValues: PropTypes.shape({
      offer_type: PropTypes.oneOf(['fixed_amount', 'fixed_percentage', 'freebie', 'shipping', 'credit_sheets']).isRequired
    }),
    requestingOffers: PropTypes.bool,
    requestingAdvancepay: PropTypes.bool,
    invalid: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    mode: PropTypes.oneOf(['create', 'edit']).isRequired,
    step: PropTypes.string.isRequired,
    pricesheets: PropTypes.array,
    offerType: PropTypes.oneOf(['fixed_amount', 'fixed_percentage', 'freebie', 'shipping', 'credit_sheets']).isRequired,
    offerTypeSelected: PropTypes.string,
    onChangeStep: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
  };

  static defaultProps = {};

  static STEPS = {
    CHOOSE_TYPE: 'ChooseType',
    DISCOUNT_MAIN: 'DiscountMain',
    DISCOUNT_OPTIONS: 'DiscountOptions',
    DISCOUNT_FREE_SHIPPING: 'DiscountFreeShipping',
    GIVEAWAY_MAIN: 'GiveawayMain',
    GIVEAWAY_OPTIONS: 'GiveawayOptions',
    BOGO_REQUIRED_OPTIONS: 'BogoRequiredOptions',
    BOGO_FREE_OPTIONS: 'BogoFreeOptions',
    GIVEAWAY_FREE_SHIPPING: 'GiveawayFreeShipping',
    FREE_SHIPPING_MAIN: 'FreeShippingMain',
    CREDIT_SHEETS: 'CreditSheet'
  };

  static COMPONENTS = {
    GiveawayOptions: GiveawayOptions,
    BogoRequiredOptions: BogoRequiredOptions,
    BogoFreeOptions: BogoFreeOptions
  };

  static validate = (values) => {
    /* Synchronous form validation method */
    const errors = {};

    if (values && values.length) {
      const { offer_type, amount, name, code, min_order_price } = values;
      const positiveWithTwoDecimalsPattern = /^(0|([1-9][0-9]*))(\.[0-9]{1,2})?$/; // positive with 2 decimals max

      if (offer_type === 'fixed_amount') {
        if (amount) {
          let number = parseFloat('' + amount);
          if (isNaN(number)) {
            errors.amount = 'Please enter a number';
          } else {
            if (!positiveWithTwoDecimalsPattern.test('' + amount)) {
              errors.amount = 'Please enter a valid number';
            }
          }
        }
      } else if (offer_type === 'fixed_percentage') {
        if (amount) {
          if (!integerValidate(amount)) {
            errors.amount = 'Please enter a number justify-between 0 and 50';
          } else {
            let number = parseInt('' + amount, 10);

            if (number < 1 || number > 50) {
              errors.amount = 'Please enter a number justify-between 0 and 50';
            }
          }
        }
      }

      if (!errors.name && !name) {
        errors.name = 'Required';
      }

      if (!errors.code && !code) {
        errors.code = 'Required';
      }

      if (offer_type === 'fixed_amount' || offer_type === 'fixed_percentage') {
        if (!errors.amount && !amount) {
          errors.amount = 'Required';
        }
      }

      if (min_order_price) {
        let number = parseFloat('' + min_order_price);
        if (isNaN(number)) {
          errors.min_order_price = 'Please enter a number';
        } else {
          if (!positiveWithTwoDecimalsPattern.test('' + min_order_price)) {
            errors.min_order_price = 'Please enter a valid dollar amount';
          }
        }
      }

      return errors;
    }

    return;
  };

  handleNextOnClick = (nextStep) => {
    const { onChangeStep } = this.props;

    onChangeStep(nextStep);
  };

  handlePercentOrFixedChange = (values) => {
    const { change } = this.props;

    let num = parseFloat(values.amount);

    if (values.offer_type === 'fixed_amount') {
      change('fixed_amount_cents', !isNaN(num) ? Math.round(num * 100) : null);
      change('fixed_percentage', null);
    } else if (values.offer_type === 'fixed_percentage') {
      change('fixed_amount_cents', null);
      change('fixed_percentage', !isNaN(num) ? num : null);
    }
  };

  renderHiddenInput = ({ input }) => {
    return <input {...input} type="hidden" className="input--block" />;
  };

  render() {
    const { step, handleSubmit } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        {[OfferForm.STEPS.CHOOSE_TYPE].includes(step) && this.chooseTypeContent}
        {[OfferForm.STEPS.DISCOUNT_MAIN /*, OfferForm.STEPS.DISCOUNT_OPTIONS*/, OfferForm.STEPS.DISCOUNT_FREE_SHIPPING].includes(step) && this.discountContent}
        {[
          OfferForm.STEPS.GIVEAWAY_MAIN,
          OfferForm.STEPS.GIVEAWAY_OPTIONS,
          OfferForm.STEPS.BOGO_FREE_OPTIONS,
          OfferForm.STEPS.BOGO_REQUIRED_OPTIONS,
          OfferForm.STEPS.GIVEAWAY_FREE_SHIPPING
        ].includes(step) && this.giveawayContent}
        {[OfferForm.STEPS.FREE_SHIPPING_MAIN].includes(step) && this.freeShippingContent}
        {[OfferForm.STEPS.CREDIT_SHEETS].includes(step) && this.creditSheetsContent}
      </Form>
    );
  }

  get chooseTypeContent() {
    const { onChangeStep, offerTypeSelected } = this.props;

    return (
      <OfferType
        offerTypeSelected={offerTypeSelected}
        onSelectOfferType={(selectedOfferType) => {
          let nextStep = OfferForm.STEPS.DISCOUNT_MAIN;
          if (selectedOfferType === 'freebie') {
            nextStep = OfferForm.STEPS.GIVEAWAY_MAIN;
          } else if (selectedOfferType === 'shipping') {
            nextStep = OfferForm.STEPS.FREE_SHIPPING_MAIN;
          } else if (selectedOfferType === 'credit_sheets') {
            nextStep = OfferForm.STEPS.CREDIT_SHEETS;
          }
          onChangeStep(nextStep);
        }}
        {...this.props}
      />
    );
  }

  get discountContent() {
    const { mode, step, valid, requestingOffers, requestingAdvancepay, handleSubmit } = this.props;

    if (step === OfferForm.STEPS.DISCOUNT_MAIN) {
      /* step 1 */
      return (
        <div className={'offer-step ' + step}>
          <OfferMain {...this.props} />
          <hr />
          <PercentOrFixedOffers onChange={this.handlePercentOrFixedChange} {...this.props} required />
          <Field name="fixed_amount_cents" component={this.renderHiddenInput} type="hidden" />
          <Field name="fixed_percentage" component={this.renderHiddenInput} type="hidden" />
          <button
            className="button button--large button--center"
            type="button"
            onClick={
              valid && !requestingOffers && !requestingAdvancepay
                ? () => {
                    this.handleNextOnClick(/*OfferForm.STEPS.DISCOUNT_OPTIONS*/ OfferForm.STEPS.DISCOUNT_FREE_SHIPPING);
                  }
                : undefined
            }
            disabled={!valid || requestingOffers || requestingAdvancepay}
          >
            Next
          </button>
        </div>
      );
    } else if (step === OfferForm.STEPS.DISCOUNT_FREE_SHIPPING) {
      /* step 3 */
      return (
        <div className={'offer-step ' + step}>
          <OfferOptions {...this.props} />
          <hr />
          <FreeShippingForm {...this.props} />
          <button
            className="button button--large button--center"
            type="button"
            onClick={valid && !requestingOffers && !requestingAdvancepay ? handleSubmit : undefined}
            disabled={!valid || requestingOffers || requestingAdvancepay}
          >
            {mode === 'create' ? 'Create Offer' : 'Update Offer'}
          </button>
        </div>
      );
    }

    return null;
  }

  get giveawayContent() {
    const { mode, step, freebieType, onChangeStep, valid, requestingOffers, requestingAdvancepay, handleSubmit } = this.props;

    if (step === OfferForm.STEPS.GIVEAWAY_MAIN) {
      /* step 1 */

      return (
        <div className={'offer-step ' + step}>
          <OfferMain {...this.props} />
          <hr />
          <GiveawayMain {...this.props} />
          <button
            type="button"
            className="button button--large button--center"
            onClick={
              valid && !requestingOffers && !requestingAdvancepay
                ? () => onChangeStep(freebieType === 'bogo' ? OfferForm.STEPS.BOGO_REQUIRED_OPTIONS : OfferForm.STEPS.GIVEAWAY_OPTIONS)
                : undefined
            }
            disabled={!valid || requestingOffers || requestingAdvancepay}
          >
            Next
          </button>
        </div>
      );
    } else if ([OfferForm.STEPS.GIVEAWAY_OPTIONS, OfferForm.STEPS.BOGO_FREE_OPTIONS, OfferForm.STEPS.BOGO_REQUIRED_OPTIONS].includes(step)) {
      /* step 2 */
      const nextStep = [OfferForm.STEPS.GIVEAWAY_OPTIONS, OfferForm.STEPS.BOGO_FREE_OPTIONS].includes(step)
        ? OfferForm.STEPS.GIVEAWAY_FREE_SHIPPING
        : OfferForm.STEPS.BOGO_FREE_OPTIONS;

      const TagName = OfferForm.COMPONENTS[step];

      return (
        <div className={'offer-step ' + step}>
          <TagName {...this.props} />
          <button
            type="button"
            className="button button--large button--center"
            onClick={valid && !requestingOffers && !requestingAdvancepay ? () => onChangeStep(nextStep) : undefined}
            disabled={!valid || requestingOffers || requestingAdvancepay}
          >
            Next
          </button>
        </div>
      );
    } else if (step === OfferForm.STEPS.GIVEAWAY_FREE_SHIPPING) {
      /* step 3 */

      return (
        <div className={'offer-step ' + step}>
          <OfferOptions {...this.props} />
          <hr />
          <FreeShippingForm {...this.props} />
          <button
            type="button"
            className="button button--large button--center"
            onClick={valid && !requestingOffers && !requestingAdvancepay ? handleSubmit : undefined}
            disabled={!valid || requestingOffers || requestingAdvancepay}
            data-loading={requestingOffers || requestingAdvancepay}
          >
            {mode === 'create' ? 'Create Offer' : 'Update Offer'}
          </button>
        </div>
      );
    }

    return null;
  }

  get freeShippingContent() {
    const { mode, step, valid, requestingOffers, requestingAdvancepay, handleSubmit } = this.props;

    if (step === OfferForm.STEPS.FREE_SHIPPING_MAIN) {
      /* step 1 */

      return (
        <div className={'offer-step ' + step}>
          <OfferMain {...this.props} />
          <hr />
          <OfferOptions {...this.props} />
          <button
            className="button button--large button--center"
            type="button"
            onClick={valid && !requestingOffers && !requestingAdvancepay ? handleSubmit : undefined}
            disabled={!valid || requestingOffers || requestingAdvancepay}
            data-loading={requestingOffers || requestingAdvancepay}
          >
            {mode === 'create' ? 'Create Offer' : 'Update Offer'}
          </button>
        </div>
      );
    }

    return null;
  }

  get creditSheetsContent() {
    const { mode, step, valid, requestingOffers, requestingAdvancepay, handleSubmit } = this.props;

    return (
      <div className={'offer-step ' + step}>
        <CreditSheet {...this.props} />
        <button
          className="button button--large button--center mt-10"
          type="button"
          disabled={!valid || requestingOffers || requestingAdvancepay}
          data-loading={requestingOffers || requestingAdvancepay}
          onClick={valid && !requestingOffers && !requestingAdvancepay ? handleSubmit : undefined}
        >
          {mode === 'create' ? 'Create' : 'Save'}
        </button>
      </div>
    );
  }
}

export default connect(mapStateToProps)(
  reduxForm({
    form: 'OfferForm',
    enableReinitialize: true,
    destroyOnUnmount: false,
    validate: OfferForm.validate
  })(OfferForm)
);
