import { useState } from 'react';
import { shape, string, bool, number, arrayOf, object, func, array } from 'prop-types';

// Plugins
import moment from 'moment';

// Redux
import { useDispatch } from 'react-redux';
import { getStudioPromotion, cancelStudioPromotion } from '../actions';

// Components
import Pagination from '@/components/Shared/Pagination';

// Images
import ReactImageCancel from '@/assets/images/icon-calendar-cancel.svg?react';

const SEASONAL_GRAPHICS_LINK = import.meta.env.VITE_SEASONAL_GRAPHICS_LINK === '1';

PromosCustom.propTypes = {
  section: string,
  request: string,
  jobs: array.isRequired,
  promos: shape({
    list: arrayOf(
      shape({
        id: string,
        studio_id: string,
        promotion_id: string,
        audience: string,
        is_enabled: bool,
        title: string,
        views_count: number,
        orders_count: number,
        sales_cents: number,
        job_ids: arrayOf(string),
        offer: object,
        promotion: object,
        support_request: object,
        start_at: string,
        end_at: string
      })
    ).isRequired,
    pagination: object
  }),
  onRequestChange: func.isRequired,
  promosRequesting: bool
};

function PromosCustom({ collapse, section, request, jobs, promos, onRequestChange, promosRequesting }) {
  const [showConfirmCancel, setShowConfirmCancel] = useState(false);
  const [showList, setShowList] = useState(false);
  const [promo, setPromo] = useState();
  const [confirmDelete, setConfirmDelete] = useState('');

  const dispatch = useDispatch();

  const formatDate = (date) => {
    return moment(date.split('T')[0]).format('L');
  };

  const formatJobList = (promo) => {
    if (promo?.job_ids?.length > 1) {
      return (
        <span
          className="promo-job-list"
          onClick={() => {
            setPromo(promo);
            setShowList(true);
          }}
        >
          {promo.job_ids.length} Jobs
        </span>
      );
    } else {
      const job = jobs?.find((j) => j.id === promo.job_ids[0]);
      return job ? job.name : '';
    }
  };

  const createJobList = (promo) => {
    if (promo)
      return promo.job_ids.map((id) => {
        const job = jobs?.find((j) => j.id === id);
        return job ? <p key={job.id}>{job.name}</p> : '';
      });
  };

  // Example: in_progress -> In Progress
  const formatStatus = (status) => {
    return status
      .replace('_', ' ')
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  };

  const getPromotionName = (promo) => {
    const promotionName = promo?.promotion?.name ?? 'N/A';
    const offerCode = promo?.offer?.code;

    if (promotionName?.toUpperCase()?.trim() === 'FLASH SALE' && offerCode) {
      return `${promotionName} - ${offerCode}`;
    }

    return promotionName;
  };

  const getPromotionAudience = (promo) => {
    const audience = promo?.audience ?? 'All';
    return audience === 'without_orders' ? 'Not Ordered' : audience.charAt(0).toUpperCase() + audience.substring(1);
  };

  const handlePagination = (page) => {
    dispatch(getStudioPromotion({ custom: true, page }));
  };

  const handleToggleConfirmCancel = (promo) => {
    setPromo(promo);
    setShowConfirmCancel(true);
  };

  const handleDeleteChange = (e) => setConfirmDelete(e.target.value);

  const handleCancelStudioPromotion = () => {
    dispatch(
      cancelStudioPromotion(promo, () => {
        dispatch(getStudioPromotion({ custom: true, page: promos.pagination.page }));
        setShowConfirmCancel(false);
      })
    );
  };

  return (
    <section
      className={`basis-9/12 md:basis-full promos-section ${section === 'Custom' && request !== section ? 'promos-section--active' : ''} ${
        collapse ? 'promos-section--expand' : ''
      }`}
    >
      <header className="flex items-center justify-between promos-section__header">
        <h2 className="text-headline-sm">Custom Promos</h2>
        <aside className="button-group animate">
          <button className="button button--medium" name="request-custom-promo" type="button" onClick={(e) => onRequestChange(e, section)}>
            Request Custom Promo
          </button>
        </aside>
      </header>
      <aside className="promos-banner">
        <h1 className="promos-banner__title">Spread the word with Custom Promos!</h1>
        <h5 className="text--normal mb-5">
          Our Custom Promo campaigns are designed as friendly SMS reminders to promote flash sales, newly uploaded photos, and more.{' '}
          {SEASONAL_GRAPHICS_LINK && (
            <a href="https://support.https://supportphotoday.io/en/articles/8395561-holiday-marketing-graphics" target="_blank" rel="noopener noreferrer">
              Learn more
            </a>
          )}
        </h5>
        <button className="button button--medium" name="request-custom-promo" type="button" onClick={(e) => onRequestChange(e, section)}>
          Request Custom Promo
        </button>
      </aside>
      <article className="table-box">
        <table className="table">
          <thead className="table__header">
            <tr>
              <th>Series Name</th>
              <th>Request Date</th>
              <th>Start Date</th>
              <th>Last Day</th>
              <th>Audience</th>
              <th>Job List</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="table__body">
            {promos?.list?.length > 0 ? (
              <>
                {promos.list.map((promo) => (
                  <tr key={promo.id}>
                    <td data-header="Series Name">{getPromotionName(promo)}</td>
                    <td data-header="Request Date">{formatDate(promo.support_request.created_at)}</td>
                    <td data-header="Start Date">{formatDate(promo.start_at)}</td>
                    <td data-header="Last Day">{formatDate(promo.end_at)}</td>
                    <td data-header="Audience">{getPromotionAudience(promo)}</td>
                    <td data-header="Job List">{formatJobList(promo)}</td>
                    <td data-header="Status">{formatStatus(promo.support_request.status)}</td>
                    <td className="flex" data-header="Action">
                      {promo.support_request.status === 'requested' && (
                        <button className="button button--clean button--small" onClick={() => handleToggleConfirmCancel(promo)}>
                          <ReactImageCancel className="promos-custom__icon-cancel" />
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td>You don't have any custom Promos.</td>
              </tr>
            )}
          </tbody>
        </table>
      </article>
      {promos?.pagination?.total > promos?.pagination?.perPage && (
        <Pagination pagination={promos?.pagination} onPagination={handlePagination} showPagesCount={4} />
      )}
      <aside className={`modal ${showList && promo ? '' : 'transparent'} text-left`}>
        <div className="modal__box modal__box--dark modal__box--small modal__box--nomin">
          <header className="modal__header">
            <button className="button button--action modal__close" name="close" type="button" onClick={() => setShowList(false)}>
              <i className="icon-close"></i>
            </button>
            <h3>Job List for {promo?.promotion?.name}</h3>
          </header>
          <main className="modal__content">{createJobList(promo)}</main>
          <footer className="text-center modal__footer button-group">
            <button className="button button--outline button--medium" name="no" type="button" onClick={() => setShowList(false)}>
              Close
            </button>
          </footer>
        </div>
      </aside>
      <aside className={`modal ${showConfirmCancel && promo ? '' : 'transparent'} text-left`}>
        <div className="promos-custom__modal modal__box modal__box--dark modal__box--medium modal__box--nomin">
          <header className="modal__header">
            <button className="button button--action modal__close" name="close" type="button" onClick={() => setShowConfirmCancel(false)}>
              <i className="icon-close"></i>
            </button>
            <h3>Cancel Custom Promo Request</h3>
          </header>
          <p>
            You are about to cancel your request for the custom promo campaign, <b>{promo?.promotion?.name}</b>. This action cannot be undone.
          </p>
          <p>
            Type <b>"delete"</b> to cancel your request for this custom promo.
          </p>
          <input className="input--block" type="text" name="delete" value={confirmDelete} onChange={handleDeleteChange} maxLength="10" />

          <footer className="text-center modal__footer button-group">
            <button
              className="promos-cancel__button button button--danger button--large"
              name="button"
              type="button"
              onClick={handleCancelStudioPromotion}
              disabled={promosRequesting || !(confirmDelete.toLowerCase() === 'delete')}
            >
              Delete
            </button>
          </footer>
        </div>
      </aside>
    </section>
  );
}

export default PromosCustom;
