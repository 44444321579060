import { call, put, takeLatest, all } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import * as types from './constants';
import * as api from '@/api/gift-card-api';

import errorHandler from '@/utils/errorResponseHandler';
import successHandler from '@/utils/successResponseHandler';

import {
  createGiftCardResendReceiptSuccess,
  createGiftCardResendReceiptError,
  getGiftCardTransactionsListSuccess,
  getGiftCardTransactionsListError,
  giftCardDetailRequestSuccess,
  giftCardDetailRequestError
} from './actions';

function* createGiftCardResendReceiptRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.createGiftCardResendReceipt, payload);

    yield call(successHandler('Receipt resent Successfully!'), createGiftCardResendReceiptSuccess, response);

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, createGiftCardResendReceiptError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getGiftCardTransactionsListRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.getGiftCardTransactionsList, payload);

    yield put(getGiftCardTransactionsListSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, getGiftCardTransactionsListError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getGiftCardDetailRequestFlow(action) {
  try {
    yield put(showLoading());

    const { giftCardId } = action.payload;
    const response = yield call(api.giftCardDetail, giftCardId);
    yield put(giftCardDetailRequestSuccess(response));
  } catch (error) {
    yield call(errorHandler, giftCardDetailRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* watcher() {
  yield all([
    takeLatest(types.CREATE_GIFT_CARD_RESEND_RECEIPT_REQUEST, createGiftCardResendReceiptRequest),
    takeLatest(types.GET_GIFT_CARD_TRANSACTIONS_LIST_REQUEST, getGiftCardTransactionsListRequest),
    takeLatest(types.GIFT_CARD_DETAIL_REQUEST, getGiftCardDetailRequestFlow)
  ]);
}

export default watcher;
