import React from 'react';

// Components
import ShippingTable from '@/components/Shared/ShippingTable';

const DropShip = function DropShip({ labId, labName, shippingType }) {
  return <ShippingTable labId={labId} labName={labName} shippingType={shippingType} />;
};

export default DropShip;
