import qs from 'qs';
import axios from 'axios';
import FileSaver from 'file-saver';

import * as storage from './storage';
import defaultPager from './defaultPager';
import entitiesToMap from './entitiesToMap';

const BASE_URL = import.meta.env.VITE_BASE_URL;
const API_URL = import.meta.env.VITE_API_URL;
const JWT_TOKEN = import.meta.env.VITE_JWT_TOKEN;
const LOGIN_ENTITIES = import.meta.env.VITE_LOGIN_ENTITIES;

const SESSION_HASH = 'SESSION_HASH';
const APP_VERSION = import.meta.env.VITE_VERSION;
const COMMIT_REF = import.meta.env.VITE_COMMIT_REF;

const buildFullUrl = (endpoint) => {
  // Our regular endpoint which is proxied through netlify to reduce OPTIONS calls
  if (endpoint?.indexOf(BASE_URL) !== -1) return endpoint;
  // Some requests need to hit the non proxied endpoint
  if (endpoint?.indexOf(API_URL) !== -1) return endpoint;
  // If its a path we assume we use the proxied endpoint. This is the most common use case
  return `${BASE_URL}${endpoint}`;
};

const getJwtToken = () => {
  const jwt = storage.get(JWT_TOKEN);

  return jwt ? `Bearer ${jwt}` : null;
};

export const getSessionHash = () => {
  let hash = storage.get(SESSION_HASH);

  if (!hash) {
    hash = Math.random().toString(36).slice(2);
    storage.set(SESSION_HASH, hash);
  }

  return hash;
};

const urlEncodeParams = (params = {}) => {
  const empty = Object.keys(params).length === 0 && params.constructor === Object;

  return empty ? '' : `?${qs.stringify(params)}`;
};

const sessionHash = () => {
  return { 'Session-Hash': getSessionHash() };
};

const studioHeaders = () => {
  try {
    const json = storage.get(LOGIN_ENTITIES);
    const entities = JSON.parse(json);

    const { user: userEntities, studio: studioEntities } = entities;
    const user = entitiesToMap(userEntities)[0];
    const studio = entitiesToMap(studioEntities)[0];
    const isAdminUser = user?.is_photoday || user?.roles?.includes('studios_admin') ? true : false;

    return isAdminUser ? { Studio: studio.id } : {};
  } catch (e) {
    return {};
  }
};

const httpHeaders = (auth = {}, contentType = {}, version = {}, hash = {}, restricted = true) => {
  const config = restricted
    ? Object.assign({}, auth, contentType, version, hash, studioHeaders(), photodayClient())
    : Object.assign({}, sessionHash(), contentType, version, hash, photodayClient());

  return { headers: config };
};

const authHeader = () => {
  const token = getJwtToken();

  return token ? { Authorization: `${token}` } : {};
};

const jsonContentType = () => ({
  'Content-Type': 'application/json'
});

const formDataContentType = () => ({
  'Content-Type': 'multipart/form-data'
});

const versionHeader = () => {
  const version = APP_VERSION;

  return version ? { 'X-App-Version': `${version}` } : {};
};

const hashHeader = () => {
  const commitRef = COMMIT_REF;

  return commitRef ? { 'X-App-Hash': `${commitRef}` } : {};
};

const photodayClient = () => {
  return { 'Photoday-Client': 'Studio-Web' };
};

const formatJsonParams = (params) => JSON.stringify(params);

const formatFormDataParams = (params) => {
  const formData = new FormData();

  for (const key in params) {
    if (params[key]) {
      const value = params[key];
      formData.append(key, value.constructor === File ? value : formatJsonParams(value));
    }
  }

  return formData;
};

const errorParser = (error) => {
  const { message, response } = error;

  return { message, response };
};

export const getPaginationParams = (headers) => {
  const { 'x-page': xPage, 'x-per-page': xPerPage, 'x-total': xTotal } = headers;
  const page = xPage ? parseInt(xPage, 10) : defaultPager.page;
  const perPage = xPerPage ? parseInt(xPerPage, 10) : defaultPager.perPage;
  const total = xTotal ? parseInt(xTotal, 10) : defaultPager.total;
  const totalPages = Math.ceil(total / perPage);

  return { page, perPage, total, totalPages };
};

export const get = (endpoint, params = {}, restricted = true) => {
  const url = buildFullUrl(endpoint);
  const encodedParams = urlEncodeParams(params);
  const finalUrl = `${url}${encodedParams}`;
  const headers = httpHeaders(authHeader(), jsonContentType(), versionHeader(), hashHeader(), restricted);

  return new Promise((resolve, reject) => {
    axios
      .get(finalUrl, headers)
      .then((resp) => resolve(resp))
      .catch((errResp) => reject(errorParser(errResp)));
  });
};

export const post = (endpoint, params = {}, restricted = true) => {
  const url = buildFullUrl(endpoint);
  const args = formatJsonParams(params);
  const headers = httpHeaders(authHeader(), jsonContentType(), versionHeader(), hashHeader(), restricted);

  return new Promise((resolve, reject) => {
    axios
      .post(url, args, headers)
      .then((resp) => resolve(resp))
      .catch((errResp) => reject(errorParser(errResp)));
  });
};

export const multipartPost = (endpoint, params = {}, restricted = true) => {
  const url = buildFullUrl(endpoint);
  const args = formatFormDataParams(params);
  const headers = httpHeaders(authHeader(), formDataContentType(), versionHeader(), hashHeader(), restricted);

  return new Promise((resolve, reject) => {
    axios
      .post(url, args, headers)
      .then((resp) => resolve(resp))
      .catch((errResp) => reject(errorParser(errResp)));
  });
};

export const del = (endpoint, params = {}, restricted = true) => {
  const url = buildFullUrl(endpoint);
  const args = formatJsonParams(params);
  const headers = httpHeaders(authHeader(), jsonContentType(), versionHeader(), hashHeader(), restricted).headers;
  const config = {
    method: 'DELETE',
    url,
    data: args,
    headers
  };

  return new Promise((resolve, reject) => {
    axios(config)
      .then((resp) => resolve(resp))
      .catch((errResp) => reject(errorParser(errResp)));
  });
};

export const put = (endpoint, params = {}, restricted = true) => {
  const url = buildFullUrl(endpoint);
  const args = formatJsonParams(params);
  const headers = httpHeaders(authHeader(), jsonContentType(), versionHeader(), hashHeader(), restricted);

  return new Promise((resolve, reject) => {
    axios
      .put(url, args, headers)
      .then((resp) => resolve(resp))
      .catch((errResp) => reject(errorParser(errResp)));
  });
};

export const patch = (endpoint, params = {}, restricted = true) => {
  const url = buildFullUrl(endpoint);
  const args = formatJsonParams(params);
  const headers = httpHeaders(authHeader(), jsonContentType(), versionHeader(), hashHeader(), restricted);

  return new Promise((resolve, reject) => {
    axios
      .patch(url, args, headers)
      .then((resp) => resolve(resp))
      .catch((errResp) => reject(errorParser(errResp)));
  });
};

export const getBlob = (endpoint, params = {}, fileName, restricted = true) => {
  const url = buildFullUrl(endpoint);
  const encodedParams = urlEncodeParams(params);
  const finalUrl = `${url}${encodedParams}`;
  const headers = httpHeaders(authHeader(), jsonContentType(), versionHeader(), hashHeader(), restricted);

  fetch(finalUrl, headers)
    .then((resp) => resp.blob())
    .then((blob) => FileSaver.saveAs(blob, fileName, { autoBom: true }))
    .catch((error) => console.warn('getBlob error', error));
};

export const postBlob = (endpoint, params = {}, fileName, restricted = true) => {
  const url = buildFullUrl(endpoint);
  const headers = httpHeaders(authHeader(), jsonContentType(), versionHeader(), hashHeader(), restricted);

  fetch(url, { method: 'POST', ...headers, body: JSON.stringify(params) })
    .then((r) => r.blob())
    .then((blob) => FileSaver.saveAs(blob, fileName, { autoBom: true }))
    .catch((error) => console.warn('postBlob error', error));
};
