import { useEffect, useState } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { getJobTimezoneList } from '@/components/Jobs/actions';

interface TimeZone {
  name: string;
  zone: string;
}

export function useJobTimeZone(): [string, (currJobRegion: string) => string] {
  const dispatch = useDispatch();

  const {
    timezones,
    job: { time_zone: jobStateRegion }
  } = useSelector((state: any) => state.jobs);

  const [timeZone, setTimeZone] = useState<string>('');

  const getJobTimezone = (currJobRegion: string): string => {
    const foundTimezoneObject: TimeZone | undefined = timezones.find((timezone: TimeZone) => timezone.name === currJobRegion);

    return foundTimezoneObject?.zone ?? '';
  };

  useEffect(() => {
    if (!timezones.length) {
      dispatch(getJobTimezoneList());
    }
  }, []);

  useEffect(() => {
    const jobTimeZone = getJobTimezone(jobStateRegion);

    if (jobTimeZone) {
      setTimeZone(jobTimeZone);
    }
  }, [jobStateRegion]);

  return [timeZone, getJobTimezone];
}
