import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

// Plugins
import CreatableSelect from 'react-select/creatable';
import { Tooltip } from 'react-tippy';

// Redux
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { createTag, createTagAssociation, getTagList } from '../../../actions';

import './style.css';

function TagManager({
  modalView,
  filterTags,
  filterPhotos,
  photoSelected,
  unselectedPhotos,
  allPhotosSelected,
  onTagModalCancel,
  onTagManagerShow,
  onPhotoSelectClear
}) {
  const dispatch = useDispatch();

  const {
    query,
    job,
    photos: { list: photos },
    tags: { list: tags }
  } = useSelector((state) => state.jobs);

  const [options, setOptions] = useState([]);
  const [tagsInput, setTagsInput] = useState('');
  const [tagError, setTagError] = useState(false);
  const [tagsRequesting, setTagsRequesting] = useState();

  const [selectTags, setSelectTags] = useState([]);
  const [currentPhotoTags, setCurrentPhotoTags] = useState([]);
  const [currentPhotoRemoveTags, setCurrentPhotoRemoveTags] = useState([]);

  const createOptions = () => {
    const options = tags.filter((tag) => !selectTags.some((selectTag) => selectTag.label === tag.name)).map((tag) => ({ value: tag.name, label: tag.name }));

    setOptions(options);
  };

  const handleTagChange = (tags = []) => {
    let newTags = [];

    if (Array.isArray(selectTags) && tags && tags.length) {
      newTags = [...selectTags, ...tags].sort(function (a, b) {
        let labelA = a.label.toUpperCase();
        let labelB = b.label.toUpperCase();

        if (labelA < labelB) {
          return -1;
        }

        if (labelA > labelB) {
          return 1;
        }

        return 0;
      });
    }

    setSelectTags(newTags);

    if (tags && tags.length && currentPhotoRemoveTags.includes(tags[0].label)) {
      setCurrentPhotoRemoveTags(currentPhotoRemoveTags.filter((current) => current !== tags[0].label));
    }
  };

  const handleSelectTagRemove = (tag) => {
    setSelectTags(selectTags.filter((current) => current.label !== tag));

    if (currentPhotoTags.some((initial) => initial.label === tag)) {
      setCurrentPhotoRemoveTags([...currentPhotoRemoveTags, tag]);
    }
  };

  const handleNewTagCreate = (newTag) => {
    const normalizedTag = newTag.trim();
    const invalidChars = /[^0-9a-zA-Z _-]+/.test(normalizedTag);

    if (invalidChars) {
      setTagError(true);
      setTagsInput(newTag);
    } else {
      dispatch(
        createTag({ id: job.id, tag: normalizedTag }, () => {
          dispatch(
            getTagList({ id: job.id }, ({ data }) => {
              setTagError(false);
              const added = data.find((tag) => tag.name === normalizedTag);

              if (added) {
                setSelectTags([...selectTags, { value: added.name, label: added.name }]);
              }
            })
          );
        })
      );
    }
  };

  const handleTagInputChange = (input) => {
    setTagsInput(input);
    setTagError(false);
  };

  const handleAddTagsToPhotos = () => {
    const tagsChanged = JSON.stringify(currentPhotoTags) !== JSON.stringify(selectTags);

    if (tagsChanged) {
      setTagsRequesting(true);

      dispatch(
        createTagAssociation(
          {
            id: job.id,
            photo_ids: photoSelected,
            tags: selectTags ? selectTags.map((tag) => tag.value) : [],
            untagged: filterPhotos === 'untagged' ? true : null,
            filter_tags: filterTags.length ? filterTags : null,
            perform_all: allPhotosSelected,
            last_uploaded: query.tags === 'last_uploaded',
            unselected_photo_ids: unselectedPhotos,
            replace: currentPhotoRemoveTags.length > 0
          },
          () => {
            onPhotoSelectClear();

            if (modalView) {
              dispatch(getTagList({ id: job.id }, () => setTagsRequesting(false)));
            } else {
              filterPhotos !== 'untagged' &&
                ((filterTags.length && selectTags.some((tag) => filterTags.includes(tag.value))) || !filterTags.length) &&
                setTagsRequesting(false);
            }
          }
        )
      );
    } else {
      if (onTagManagerShow && typeof onTagManagerShow === 'function') {
        onTagManagerShow(photoSelected[0]);
      }
    }
  };

  useEffect(() => {
    if (photoSelected.length === 1) {
      const foundPhotos = photos.find((photo) => photoSelected[0] === photo.id);
      const currentPhotoTags = foundPhotos ? foundPhotos.tags.map((tag) => ({ value: tag.name, label: tag.name })) : [];

      currentPhotoTags.sort(function (a, b) {
        const labelA = a.label.toUpperCase();
        const labelB = b.label.toUpperCase();

        if (labelA < labelB) {
          return -1;
        }

        if (labelA > labelB) {
          return 1;
        }

        return 0;
      });

      setSelectTags(currentPhotoTags);
      setCurrentPhotoTags(currentPhotoTags);
    }
  }, []);

  useEffect(() => {
    createOptions();
  }, [selectTags]);

  useEffect(() => {
    if (tagsRequesting === false) {
      modalView ? onTagModalCancel() : onTagManagerShow(photoSelected[0]);
    }
  }, [tagsRequesting]);

  return (
    <aside className="tag-manager animate">
      <main className="tag-manager__main">
        <CreatableSelect
          className="select select--block"
          classNamePrefix="select"
          isMulti={true}
          value={[]}
          options={options}
          inputValue={tagsInput}
          onInputChange={handleTagInputChange}
          onChange={(tag) => handleTagChange(tag)}
          onCreateOption={handleNewTagCreate}
          placeholder="Add tag and hit enter"
        />

        <div className={`tag-manager__tag-cloud ${modalView ? 'tag-manager__tag-cloud--modal' : ''}`}>
          {tagError && <p className="text-error-500">No special characters, letters and numbers only.</p>}
          {selectTags.length > 0 &&
            selectTags.map((tag, i) => (
              <small className={`pill pill--tag ${modalView ? 'tag-manager__pill--modal' : ''}`} key={i}>
                <Tooltip
                  title={tag.label}
                  position="bottom"
                  arrow={false}
                  delay={400}
                  distance="18"
                  disabled={tag && tag.label && tag.label.length > 15 ? false : true}
                  zIndex={10000}
                >
                  <div className="tag-manager__tag-label">{tag.label}</div>
                </Tooltip>

                <button className="tag-manager__tag-remove" type="button" onClick={() => handleSelectTagRemove(tag.label)}>
                  <i className="icon-close"></i>
                </button>
              </small>
            ))}
        </div>
      </main>

      <footer className="flex items-center justify-center tag-manager__footer ">
        <button
          className={`button ${modalView ? 'tag-manager__button--modal' : 'button--outline tag-manager__button'}`}
          name="button"
          type="button"
          onClick={handleAddTagsToPhotos}
          data-loading-dark={!modalView && tagsRequesting}
          data-loading={modalView && tagsRequesting}
          disabled={tagsRequesting}
        >
          Update
        </button>
      </footer>
    </aside>
  );
}

TagManager.propTypes = {
  filterTags: PropTypes.array.isRequired,
  photoSelected: PropTypes.array.isRequired,
  unselectedPhotos: PropTypes.array.isRequired,
  allPhotosSelected: PropTypes.bool.isRequired,
  modalView: PropTypes.bool,
  onTagModalCancel: PropTypes.func,
  onTagManagerShow: PropTypes.func
};

TagManager.defaultProps = {
  allPhotosSelected: false,
  filterTags: [],
  photoSelected: [],
  unselectedPhotos: [],
  modalView: false
};

export default TagManager;
