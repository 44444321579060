import * as types from './constants';

// Create studio promotion
export const createStudioPromotion = (payload, callback) => ({
  type: types.CREATE_STUDIO_PROMOTION,
  payload: { payload, callback }
});

export const createStudioPromotionSuccess = (payload) => ({
  type: types.CREATE_STUDIO_PROMOTION_SUCCESS,
  payload: { payload }
});

export const createStudioPromotionError = (error) => ({
  type: types.CREATE_STUDIO_PROMOTION_ERROR,
  payload: { error }
});

// Read studio promotion list
export const getStudioPromotion = (payload, callback) => ({
  type: types.GET_STUDIO_PROMOTION,
  payload: { payload, callback }
});

export const getStudioPromotionSuccess = (payload) => ({
  type: types.GET_STUDIO_PROMOTION_SUCCESS,
  payload: { payload }
});

export const getStudioPromotionError = (error) => ({
  type: types.GET_STUDIO_PROMOTION_ERROR,
  payload: { error }
});

// Update studio promotion
export const updateStudioPromotion = (payload, callback) => ({
  type: types.UPDATE_STUDIO_PROMOTION,
  payload: { payload, callback }
});

export const updateStudioPromotionSuccess = (payload) => ({
  type: types.UPDATE_STUDIO_PROMOTION_SUCCESS,
  payload: { payload }
});

export const updateStudioPromotionError = (error) => ({
  type: types.UPDATE_STUDIO_PROMOTION_ERROR,
  payload: { error }
});

// Cancel studio promotion
export const cancelStudioPromotion = (payload, callback) => ({
  type: types.CANCEL_STUDIO_PROMOTION,
  payload: { payload, callback }
});

export const cancelStudioPromotionSuccess = (payload) => ({
  type: types.CANCEL_STUDIO_PROMOTION_SUCCESS,
  payload: { payload }
});

export const cancelStudioPromotionError = (error) => ({
  type: types.CANCEL_STUDIO_PROMOTION_ERROR,
  payload: { error }
});

// Read PhotoDay promotion list
export const getPhotoDayPromotionList = (payload, callback) => ({
  type: types.GET_PHOTODAY_PROMOTION_LIST,
  payload: { payload, callback }
});

export const getPhotoDayPromotionListSuccess = (payload) => ({
  type: types.GET_PHOTODAY_PROMOTION_LIST_SUCCESS,
  payload: { payload }
});

export const getPhotoDayPromotionListError = (error) => ({
  type: types.GET_PHOTODAY_PROMOTION_LIST_ERROR,
  payload: { error }
});

// Read Studio promotion job list
export const getStudioPromotionJobList = (payload, callback) => ({
  type: types.GET_STUDIO_PROMOTION_JOB_LIST,
  payload: { payload, callback }
});

export const getStudioPromotionJobListSuccess = (payload) => ({
  type: types.GET_STUDIO_PROMOTION_JOB_LIST_SUCCESS,
  payload: { payload }
});

export const getStudioPromotionJobListError = (error) => ({
  type: types.GET_STUDIO_PROMOTION_JOB_LIST_ERROR,
  payload: { error }
});
