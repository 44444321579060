import * as api from '@/utils/http';

export const createGiftCardResendReceipt = (payload) => {
  const endpoint = `gift-cards/${payload.giftCardId}/resend-receipt`;

  return api.post(endpoint);
};

export const getGiftCardTransactionsList = (payload) => {
  const endpoint = `gift-cards/${payload.giftCardId}/transactions`;

  return api.get(endpoint);
};

export const giftCardDetail = (giftCardId) => {
  const endpoint = `gift-cards/${giftCardId}`;
  return api.get(endpoint);
};
