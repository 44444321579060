import { GET_STATES_REQUEST, GET_STATES_SUCCESS, GET_STATES_ERROR } from './constants';

export const getStatesListRequest = () => ({
  type: GET_STATES_REQUEST
});

export const getStatesListRequestSuccess = (states) => ({
  type: GET_STATES_SUCCESS,
  payload: {
    states
  }
});

export const getStatesListRequestError = (error) => ({
  type: GET_STATES_ERROR,
  payload: {
    error
  }
});
