import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Plugins
import moment from 'moment';

// Components
import TableLoader from '@/components/Shared/ContentLoader/TableLoader';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { getFlyer } from '../../../actions';

// Styles
import './style.css';

const History = ({ match }) => {
  const dispatch = useDispatch();

  const {
    params: { jobId, flyerId }
  } = match;
  const { flyer, requesting } = useSelector((state) => state.jobs);

  useEffect(() => {
    if (!flyer.id || flyer.id !== flyerId) {
      dispatch(getFlyer({ id: flyerId }));
    }
  }, []);

  return (
    <>
      <header className="job-marketing__header">
        <h2 className="text-headline-sm">Flyers</h2>
      </header>
      <section className="job-marketing-flyers__history">
        <header className="flex items-center job-marketing-flyers__history-header">
          <Link className="button button--outline button--noborder" to={`/jobs/${jobId}/marketing/flyers`}>
            <i className="icon-back"></i>
          </Link>
          <h2 className="text-headline-sm">Sent History</h2>
        </header>

        <article className="table-box">
          <table className="table">
            <thead className="table__header">
              <tr>
                <th>Email address</th>
                <th>Sent on</th>
              </tr>
            </thead>
            <tbody className="table__body">
              {requesting ? (
                <tr>
                  <td colSpan="2">
                    <TableLoader />
                  </td>
                </tr>
              ) : (
                flyer.email_activities &&
                flyer.email_activities
                  .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                  .map((item) => (
                    <tr key={item.id}>
                      <td data-header="Email address">{item.parameters.to && item.parameters.to.join(', ')}</td>
                      <td data-header="Sent on">{item.created_at ? moment(item.created_at).format('L') : 'requested'}</td>
                    </tr>
                  ))
              )}
            </tbody>
          </table>
        </article>
      </section>
    </>
  );
};

History.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      jobId: PropTypes.string.isRequired,
      flyerId: PropTypes.string.isRequired
    })
  }),
  history: PropTypes.object.isRequired
};

History.defaultProps = {
  match: {
    params: {
      jobId: '',
      flyerId: ''
    }
  },
  history: {}
};

export default History;
