import { formatCurrency } from './displayFormats';

export const convertToText = (value = 0, prefix = '') => {
  if (typeof value === 'string') return value;

  return prefix + formatCurrency(value);
};

export const convertToCents = (input = '') => {
  const value = input.toString().replace(/[^0-9.]/g, '');

  return value.length > 0 ? Math.round(parseFloat(value) * 100) : null;
};

export const maskValue = (input, prefix = '') => {
  let value = input.replace(/[^0-9.]/g, '');

  value = value.replace(/\.+/g, '.');
  value = value.replace(/(.*\.[0-9][0-9]?).*/g, '$1');
  value = value.replace(/^0+(.*)$/, '0$1');

  return prefix + value.replace(/^0([^.].*)$/, '$1');
};
