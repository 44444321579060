import { useEffect, useState } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { getJobTimezoneList } from '@/components/Jobs/actions';

export function useStudioTimeZone() {
  const dispatch = useDispatch();

  const { timezones } = useSelector((state: any) => state.jobs);

  const {
    studio: { time_zone }
  } = useSelector((state: any) => state.login);

  const [timeZone, setTimeZone] = useState<string>('');

  const studioTimeZone = timezones.find((zone: { name: string; zone: string }) => zone.name === time_zone);

  useEffect(() => {
    if (studioTimeZone) {
      setTimeZone(studioTimeZone.zone);
    }
  });

  useEffect(() => {
    if (!timezones.length) {
      dispatch(getJobTimezoneList());
    }
  }, []);

  return timeZone;
}
