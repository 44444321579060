export const SALES_REPORT_REQUEST = 'SALES_REPORT_REQUEST';
export const SALES_REPORT_SUCCESS = 'SALES_REPORT_SUCCESS';
export const SALES_REPORT_ERROR = 'SALES_REPORT_ERROR';

export const SALES_SUMMARY_REQUEST = 'SALES_SUMMARY_REQUEST';
export const SALES_SUMMARY_SUCCESS = 'SALES_SUMMARY_SUCCESS';
export const SALES_SUMMARY_ERROR = 'SALES_SUMMARY_ERROR';

export const SALES_MONTH_REPORT_REQUEST = 'SALES_MONTH_REPORT_REQUEST';
export const SALES_MONTH_REPORT_SUCCESS = 'SALES_MONTH_REPORT_SUCCESS';
export const SALES_MONTH_REPORT_ERROR = 'SALES_MONTH_REPORT_ERROR';

export const SALES_MONTH_SUMMARY_REQUEST = 'SALES_MONTH_SUMMARY_REQUEST';
export const SALES_MONTH_SUMMARY_SUCCESS = 'SALES_MONTH_SUMMARY_SUCCESS';
export const SALES_MONTH_SUMMARY_ERROR = 'SALES_MONTH_SUMMARY_ERROR';

export const PRODUCTS_REPORT_REQUEST = 'PRODUCTS_REPORT_REQUEST';
export const PRODUCTS_REPORT_SUCCESS = 'PRODUCTS_REPORT_SUCCESS';
export const PRODUCTS_REPORT_ERROR = 'PRODUCTS_REPORT_ERROR';

export const SALES_REPORT_DOWNLOAD_REQUEST = 'SALES_REPORT_DOWNLOAD_REQUEST';
export const SALES_MONTH_REPORT_DOWNLOAD_REQUEST = 'SALES_MONTH_REPORT_DOWNLOAD_REQUEST';
export const PRODUCTS_REPORT_DOWNLOAD_REQUEST = 'PRODUCTS_REPORT_DOWNLOAD_REQUEST';
