import PropTypes from 'prop-types';
import { useRef, useState, useEffect, memo } from 'react';

// Plugins
import { Tooltip } from 'react-tippy';
import queryString from 'query-string';

// Components
import Dropdown from '@/components/Shared/Dropdown';
import TableLoader from '@/components/Shared/ContentLoader/TableLoader';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { createTag, getTagList, renameTag, sortTag, deleteTag, createTagAssociation, updateJob } from '../../../actions';

// Styles
import './style.css';

const Sidebar = memo(function Sidebar({
  location = {},
  jobId = '',
  filterTags = [],
  filterPhotos,
  photoSelected = [],
  unselectedPhotos = [],
  allPhotosSelected = false,
  onPhotosTagFilter,
  onMouseOverSidebar,
  onShowDropzoneSplashEnable
}) {
  const inputSearchRef = useRef();
  const inputNewNameRef = useRef();
  const tagSortRef = useRef();

  const dispatch = useDispatch();
  const {
    query,
    tags: { list: tags, requesting },
    job
  } = useSelector((state) => state.jobs);

  const [selectTag, setSelectTag] = useState('all');
  const [selectTags, setSelectTags] = useState([]);

  const [dragTag, setDragTag] = useState({ name: '', index: '' });

  const [newTag, setNewTag] = useState('');
  const [showAdd, setShowAdd] = useState(false);
  const [tagError, setTagError] = useState(false);
  const [duplicateTag, setDuplicateTag] = useState();

  const [search, setSearch] = useState('');
  const [showSearch, setShowSearch] = useState(false);

  const [newName, setNewName] = useState('');
  const [showRename, setShowRename] = useState(false);

  const [showDragHandle, setShowDragHandle] = useState(false);

  const [showDelete, setShowDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState('');

  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

  const handleTagsSelect = (e, tag) => {
    let filterSelection;

    if (e.shiftKey) {
      const startIndex = tags.findIndex((item) => item.name === selectTags.slice(-1)[0]);
      const endIndex = tags.findIndex((item) => item.name === tag);

      filterSelection = tags.slice(Math.min(startIndex, endIndex), Math.max(startIndex, endIndex) + 1).map((tag) => tag.name);
    } else if (e.metaKey) {
      filterSelection = selectTags.some((select) => select === tag) ? selectTags.filter((select) => select !== tag) : [...selectTags, tag];
    } else {
      filterSelection = [tag];
    }

    setSelectTags(filterSelection);
    onPhotosTagFilter(filterSelection);
  };

  const handleSearchToggle = () => {
    setShowAdd(false);
    setShowSearch(!showSearch);
  };
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleAddToggle = () => {
    setShowSearch(false);
    setShowAdd(!showAdd);
  };
  const handleAddChange = (e) => {
    setNewTag(e.target.value);
    setTagError(false);
    setDuplicateTag();
  };
  const handleAddCancel = () => {
    setNewTag('');
    setShowAdd(false);
  };
  const handleAdd = (e) => {
    e.preventDefault();

    const normalizedTag = newTag.trim();
    const invalidChars = /[^0-9a-zA-Z _-]+/.test(normalizedTag);
    const duplicate = tags.find((tag) => tag.name === normalizedTag);

    if (invalidChars || duplicate) {
      setTagError(true);
      setDuplicateTag(duplicate);
    } else {
      dispatch(createTag({ id: jobId, tag: normalizedTag }, () => handleAddCancel()));
    }
  };

  const handleRenameShow = (e, tag) => {
    e.stopPropagation();

    setNewName(tag);
    setSelectTag(tag);
    setShowRename(true);
  };
  const handleRenameChange = (e) => setNewName(e.target.value);
  const handleRenameCancel = () => {
    setNewName('');
    setSelectTag('');
    setShowRename(false);
  };
  const handleRename = () => {
    dispatch(
      renameTag({ id: jobId, tag: { old: selectTag, new: newName } }, () => {
        setSelectTags([]);
        handleRenameCancel();
        onPhotosTagFilter([]);
      })
    );
  };

  const handleDeleteShow = (e, tag) => {
    e.stopPropagation();

    setSelectTag(tag);
    setShowDelete(true);
  };

  const handleDeleteChange = (e) => setConfirmDelete(e.target.value);
  const handleDeleteCancel = () => {
    setSelectTag('');
    setConfirmDelete('');
    setShowDelete(false);
  };

  const handleDelete = () => {
    dispatch(
      deleteTag({ id: jobId, tag: selectTag }, () => {
        setSelectTags([]);
        handleDeleteCancel();
        onPhotosTagFilter([]);
      })
    );
  };

  const handleToggleSidebar = () => {
    setSidebarIsOpen(!sidebarIsOpen);
  };

  const handleTagDrag = (e, tag) => {
    e.stopPropagation();

    const element = e.target;

    if (element.classList.contains('job-tags__item')) {
      element.classList.add('job-tags__item--drag');
    }

    setDragTag(tag);
    onShowDropzoneSplashEnable(false);
  };

  const handleTagDragEnd = (e) => e.target.classList.remove('job-tags__item--drag');

  const handleDragEnter = (e, dropTagIndex) => {
    e.stopPropagation();

    const element = e.target;

    if (element.classList.contains('job-tags__item')) {
      if (dragTag.name) {
        if (dragTag.index !== dropTagIndex - 1) {
          element.classList.add('job-tags__item--drop-sort');
        }
      } else {
        element.classList.add('job-tags__item--drop');
      }
    }
  };

  const handleDragLeave = (e) => e.target.classList.remove('job-tags__item--drop', 'job-tags__item--drop-sort');

  const handleDrop = (e, dropTagName, dropTagIndex) => {
    e.stopPropagation();

    // Drag sort order
    if (dragTag.name) {
      if (dragTag.name !== dropTagName && dragTag.index !== dropTagIndex - 1) {
        const newTagsOrder = tags.map((tag, index) => {
          const currentTag = { ...tag, display_priority: index };
          const { display_priority: currentTagPriority } = currentTag;

          if ((currentTagPriority > dragTag.index && currentTagPriority < dropTagIndex) || currentTagPriority === tags.length - 1) {
            return { ...currentTag, display_priority: currentTagPriority - 1 };
          } else if (currentTag.name === dragTag.name) {
            return { ...currentTag, display_priority: dragTag.index > dropTagIndex || dropTagIndex === tags.length - 1 ? dropTagIndex : dropTagIndex - 1 };
          } else if (currentTagPriority < dragTag.index && currentTagPriority >= dropTagIndex) {
            return { ...currentTag, display_priority: currentTagPriority + 1 };
          } else {
            return currentTag;
          }
        });

        dispatch(
          sortTag({
            id: jobId,
            list: newTagsOrder,
            sort: newTagsOrder.reduce((result, item) => ({ ...result, [item.name]: item.display_priority }), {})
          })
        );
      }

      setDragTag({ name: '', index: '' });
      onShowDropzoneSplashEnable(true);
    } else {
      // Photo association on drop
      dispatch(
        createTagAssociation(
          {
            id: jobId,
            photo_ids: photoSelected,
            tags: [dropTagName],
            untagged: filterPhotos === 'untagged' ? true : null,
            filter_tags: selectTags,
            perform_all: allPhotosSelected,
            unselected_photo_ids: unselectedPhotos,
            last_uploaded: query.tags === 'last_uploaded',
            replace: false
          },
          () => {
            dispatch(getTagList({ id: jobId }));
          }
        )
      );
    }

    e.target.classList.remove('job-tags__item--drop', 'job-tags__item--drop-sort');
  };

  const handleSortBy = (sort) => {
    dispatch(updateJob({ id: jobId, tag_sort_type: sort }, () => dispatch(getTagList({ id: jobId }))));
  };

  useEffect(() => {
    const { tags: stateParamTags } = query;
    const { tags: urlParamTags } = queryString.parse(location.search);

    let filterParam = urlParamTags || stateParamTags || [];

    // load current tags if navigating back from another screen within same job
    filterParam = filterParam === 'all' || filterParam === 'untagged' ? [] : typeof filterParam === 'string' ? filterParam.split(',') : filterParam;

    setSelectTags(filterParam);
    onPhotosTagFilter(filterParam);
    !tags.length && dispatch(getTagList({ id: jobId }));
  }, []);

  useEffect(() => {
    if (showSearch && inputSearchRef && inputSearchRef.current) {
      window.setTimeout(() => inputSearchRef.current.focus(), 500);
    }
  }, [showSearch]);

  useEffect(() => {
    if (showRename && inputNewNameRef && inputNewNameRef.current) {
      window.setTimeout(() => inputNewNameRef.current.focus(), 500);
    }
  }, [showRename]);

  useEffect(() => {
    setSelectTags(filterTags);
  }, [filterTags]);

  useEffect(() => {
    if (tagSortRef.current !== 'tags_manual' && job.tag_sort_type === 'tags_manual') {
      setShowDragHandle(true);
    }

    tagSortRef.current = job.tag_sort_type;
  }, [job.tag_sort_type]);

  useEffect(() => {
    if (showDragHandle) {
      setTimeout(() => {
        setShowDragHandle(false);
      }, 5000);
    }
  }, [showDragHandle]);

  return (
    <>
      <aside className="flex items-center justify-between job-tags__header--mobile">
        <button className="button button--outline" onClick={handleToggleSidebar}>
          <h3 className="m-0 truncate capitalize">{selectTags.length === 1 ? selectTags[0] : selectTags.length > 1 ? 'Multiple Selected' : 'All Photos'}</h3>
          <i className="icon-tag job-tags__icon" />
        </button>
      </aside>

      <div className={`job-tags__container ${sidebarIsOpen ? 'job-tags__container--open' : ''}`} onMouseEnter={onMouseOverSidebar}>
        <header className="flex items-center justify-between button-group job-tags__header">
          <h2 className="text-headline-sm">Tags</h2>
          <aside className="flex items-center end sm:hidden button-group">
            {tags.length !== 0 && (
              <Tooltip title="Search" position="top" arrow={false} delay={300}>
                <button className="button button--outline button--small" name="search" type="button" onClick={handleSearchToggle}>
                  <i className="icon-search" />
                </button>
              </Tooltip>
            )}

            <Tooltip title="Add Tags" position="top" arrow={false} delay={300}>
              <button className="button button--outline button--small" name="add" type="button" onClick={handleAddToggle}>
                <i className="icon-add-circle" />
              </button>
            </Tooltip>

            {tags.length !== 0 && (
              <Tooltip title="Sort" position="top" arrow={false} delay={300}>
                <Dropdown buttonIcon={'icon-sort'} buttonExtraClass={'button-dropdown button--lean button--small'}>
                  <ul className="panel panel-dropdown">
                    <li
                      className={`panel-dropdown__item ${job.tag_sort_type === 'tags_alpha_asc' ? 'panel-dropdown__item--active' : ''}`}
                      onClick={() => handleSortBy('tags_alpha_asc')}
                    >
                      A-Z
                    </li>
                    <li
                      className={`panel-dropdown__item ${job.tag_sort_type === 'tags_alpha_desc' ? 'panel-dropdown__item--active' : ''}`}
                      onClick={() => handleSortBy('tags_alpha_desc')}
                    >
                      Z-A
                    </li>
                    <li
                      className={`panel-dropdown__item ${job.tag_sort_type === 'tags_photo_count_desc' ? 'panel-dropdown__item--active' : ''}`}
                      onClick={() => handleSortBy('tags_photo_count_desc')}
                    >
                      Photo Count High to Low
                    </li>
                    <li
                      className={`panel-dropdown__item ${job.tag_sort_type === 'tags_photo_count_asc' ? 'panel-dropdown__item--active' : ''}`}
                      onClick={() => handleSortBy('tags_photo_count_asc')}
                    >
                      Photo Count Low to High
                    </li>
                    <li
                      className={`panel-dropdown__item ${job.tag_sort_type === 'tags_manual' ? 'panel-dropdown__item--active' : ''}`}
                      onClick={() => handleSortBy('tags_manual')}
                    >
                      Manual
                    </li>
                  </ul>
                </Dropdown>
              </Tooltip>
            )}
          </aside>
          <button className="button button--small font-bold sidebar__mobile-action" onClick={handleToggleSidebar}>
            Done
          </button>
        </header>

        {/* Search tag */}
        <form className={`job-tags__search ${showSearch ? 'job-tags__search--active' : ''}`}>
          <input
            ref={inputSearchRef}
            className="input--block"
            type="text"
            name="search"
            value={search}
            onChange={handleSearchChange}
            placeholder="Enter tag name to search"
            maxLength="21"
            required
          />
        </form>

        {/* Add new tag */}
        <form className={`flex items-center justify-start flex-nowrap job-tags__add ${showAdd ? 'job-tags__add--active' : ''}`} onSubmit={handleAdd}>
          <input
            className="input--group"
            type="text"
            name="name"
            value={newTag}
            autoComplete="off"
            onChange={handleAddChange}
            placeholder="Enter new tag name"
            maxLength="20"
            required
          />
          <button className="button button--group button--small" name="add" type="submit" disabled={!newTag}>
            Add
          </button>
          {tagError && (
            <div className="error-container">
              {duplicateTag ? (
                <small>
                  Tag <span className="capitalize font-bold">{duplicateTag.name}</span> already exists.
                </small>
              ) : (
                <small>No special characters, letters and numbers only.</small>
              )}
            </div>
          )}
        </form>

        {requesting && tags.length === 0 && <TableLoader rows={15} />}
        {!requesting && !tags.length && (
          <aside className="panel panel--secondary text-center">
            <p className="m-0">
              To create a tag click <b>+</b> above.
            </p>
          </aside>
        )}
        {tags.length > 0 && (
          <ul className="job-tags__list">
            {tags
              .filter((tag) => tag.name.toLowerCase().includes(search.toLowerCase()))
              .map((tag, index) => (
                <li
                  className={`flex flex-nowrap items-center job-tags__item ${selectTags.includes(tag.name) ? 'job-tags__item--active' : ''} ${
                    job.tag_sort_type === 'tags_manual' ? 'job-tags__item--draggable' : ''
                  } ${showDragHandle ? 'job-tags__item--show' : ''}`}
                  key={tag.name}
                  draggable={tags.length > 1 && job.tag_sort_type === 'tags_manual'}
                  onClick={(e) => handleTagsSelect(e, tag.name)}
                  onTouchMove={(e) => e.preventDefault()}
                  onDrag={(e) => handleTagDrag(e, { name: tag.name, index })}
                  onDragEnd={handleTagDragEnd}
                  onDragEnter={(e) => handleDragEnter(e, index)}
                  onDragLeave={handleDragLeave}
                  onDragOver={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  onDrop={(e) => handleDrop(e, tag.name, index)}
                >
                  <i className="icon-drag-list job-tags__icon-drag" />
                  <i className="icon-tag job-tags__icon" />
                  <Tooltip
                    className="truncate"
                    title={tag.name}
                    position="bottom"
                    arrow={true}
                    disabled={tag && tag.name && tag.name.length > 15 ? false : true}
                  >
                    {tag.name}&nbsp;
                  </Tooltip>
                  <small>({tag.photo_count})</small>
                  <aside className="flex flex-nowrap justify-end button-group job-tags__actions">
                    <button className="button button--link button--small" name="rename" type="button" onClick={(e) => handleRenameShow(e, tag.name)}>
                      Rename
                    </button>
                    <button className="button button--link button--small" name="delete" type="button" onClick={(e) => handleDeleteShow(e, tag.name)}>
                      Delete
                    </button>
                  </aside>
                </li>
              ))}
          </ul>
        )}
      </div>

      {/* Rename tag */}
      <aside className={`modal ${showRename ? '' : 'transparent'}`}>
        <div className="modal__box modal__box--small">
          <header className="modal__header">
            <button className="button button--action modal__close" name="close" type="button" onClick={handleRenameCancel}>
              <i className="icon-close"></i>
            </button>
            <h3>Rename Tag</h3>
          </header>
          <main className="modal__content">
            <p>
              Change the <b>tag</b> name bellow.
            </p>
            <input ref={inputNewNameRef} className="input--block" type="text" name="tag" value={newName} onChange={handleRenameChange} maxLength="50" />
          </main>
          <footer className="grid justify-center gap-5 modal__footer">
            <button className="button button--large" name="button" type="button" onClick={handleRename} disabled={selectTag === newName}>
              Rename
            </button>
            <button className="button button--clean button--large" name="button" type="button" onClick={handleRenameCancel}>
              Cancel
            </button>
          </footer>
        </div>
      </aside>

      {/* Delete tag confirmation */}
      <aside className={`modal ${showDelete ? '' : 'transparent'} text-left`}>
        <div className="modal__box modal__box--xsmall modal__box--nomin">
          <header className="modal__header">
            <button className="button button--action modal__close" name="close" type="button" onClick={handleDeleteCancel}>
              <i className="icon-close"></i>
            </button>
            <h3>Delete Tag</h3>
          </header>
          <main className="modal__content">
            <p>
              Are you sure you want to delete <strong className="capitalize">{selectTag || 'this'}</strong> tag?
            </p>
            <p>
              Type <b>"delete"</b> to permanently delete this tag.
            </p>
            <input className="input--block" type="text" name="delete" value={confirmDelete} onChange={handleDeleteChange} maxLength="10" />
          </main>
          <footer className="text-center modal__footer">
            <button
              className="button button--danger button--large"
              name="button"
              type="button"
              onClick={handleDelete}
              disabled={!(confirmDelete.toLowerCase() === 'delete')}
            >
              Delete
            </button>
          </footer>
        </div>
      </aside>
    </>
  );
});

Sidebar.propTypes = {
  location: PropTypes.object,
  jobId: PropTypes.string.isRequired,
  filterTags: PropTypes.array,
  photoSelected: PropTypes.array,
  unselectedPhotos: PropTypes.array,
  allPhotosSelected: PropTypes.bool
};

export default Sidebar;
