export const GET_WHCC_LINK_REQUEST = 'GET_WHCC_LINK_REQUEST';
export const GET_WHCC_LINK_SUCCESS = 'GET_WHCC_LINK_SUCCESS';
export const GET_WHCC_LINK_ERROR = 'GET_WHCC_LINK_ERROR';
export const GET_WHCC_STUDIO_INFORMATION_REQUEST = 'GET_WHCC_STUDIO_INFORMATION_REQUEST';
export const GET_WHCC_STUDIO_INFORMATION_SUCCESS = 'GET_WHCC_STUDIO_INFORMATION_SUCCESS';
export const GET_WHCC_STUDIO_INFORMATION_ERROR = 'GET_WHCC_STUDIO_INFORMATION_ERROR';
export const SET_STUDIO_INFORMATION = 'SET_STUDIO_INFORMATION';
export const SET_STUDIO_INFORMATION_SUCCESS = 'SET_STUDIO_INFORMATION_SUCCESS';
export const SET_REGISTER_STAGE = 'SET_REGISTER_STAGE';
export const SET_REGISTER_STAGE_SUCCESS = 'SET_REGISTER_STAGE_SUCCESS';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR = 'REGISTER_ERROR';
export const RESEND_VERIFICATION_REQUEST = 'RESEND_VERIFICATION_REQUEST';
export const RESEND_VERIFICATION_SUCCESS = 'RESEND_VERIFICATION_SUCCESS';
export const RESEND_VERIFICATION_ERROR = 'RESEND_VERIFICATION_ERROR';
export const VERIFICATION_REQUEST = 'VERIFICATION_REQUEST';
export const VERIFICATION_SUCCESS = 'VERIFICATION_SUCCESS';
export const VERIFICATION_ERROR = 'VERIFICATION_ERROR';
export const REGISTER_WHCC_LAB_KEY = 'whcc';
export const REGISTER_REEDY_LAB_KEY = 'reedy';
export const REGISTER_APS_LAB_KEY = 'aps';
export const REGISTER_MILLERS_LAB_KEY = 'millers';
export const REGISTER_BLACK_RIVER_LAB_KEY = 'bri';
export const REGISTER_BAY_PHOTO_LAB_KEY = 'bay';
export const REGISTER_RICHMOND_LAB_KEY = 'richmond';
export const REGISTER_STAGE_CONNECT = 0;
export const REGISTER_STAGE_INFORMATION = 1;
export const REGISTER_STAGE_STUDIO_INFORMATION = 2;
export const REGISTER_STAGE_CREATE_ACCOUNT = 3;
export const REGISTER_STAGE_EMAIL_VERIFICATION_SENT = 4;
export const STAGE_MAPPINGS = {
  whcc: { first: REGISTER_STAGE_INFORMATION, last: REGISTER_STAGE_EMAIL_VERIFICATION_SENT },
  reedy: { first: REGISTER_STAGE_INFORMATION, last: REGISTER_STAGE_EMAIL_VERIFICATION_SENT },
  aps: { first: REGISTER_STAGE_INFORMATION, last: REGISTER_STAGE_EMAIL_VERIFICATION_SENT },
  millers: { first: REGISTER_STAGE_INFORMATION, last: REGISTER_STAGE_EMAIL_VERIFICATION_SENT },
  bri: { first: REGISTER_STAGE_INFORMATION, last: REGISTER_STAGE_EMAIL_VERIFICATION_SENT },
  bay: { first: REGISTER_STAGE_INFORMATION, last: REGISTER_STAGE_EMAIL_VERIFICATION_SENT },
  richmond: { first: REGISTER_STAGE_INFORMATION, last: REGISTER_STAGE_EMAIL_VERIFICATION_SENT }
};
