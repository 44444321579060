export enum OrderReprintReason {
  Damaged = 'damaged',
  IncorrectItems = 'incorrect_items',
  NeverArrived = 'never_arrived',
  IncorrectImage = 'incorrect_image'
}

export interface Order {
  address: string;
  ap_balance: number;
  ap_expire: string;
  bulk_shipped_at: string | null;
  carrier: string | null;
  city_tax_amount: number | null;
  city_tax_rate: number | null;
  county: string | null;
  county_tax_amount: number | null;
  county_tax_rate: number | null;
  customer_email: string;
  customer_name: string;
  customer_phone: string;
  digital_costs: number;
  discount_applied: number;
  district_tax_amount: number | null;
  district_tax_rate: number | null;
  fees: number;
  fulfilled_at: string | null;
  gross_amount: number;
  id: string;
  is_new_tax_system: boolean;
  job_access_code: string;
  job_id: string;
  job_name: string;
  job_timezone: string;
  lab_costs: number;
  lab_shipping_costs: number;
  lab_total_costs: number;
  num: string;
  organization_name: string | null;
  pd_fee: number;
  processing_fee: number;
  retail_delivery_fees: number;
  retail_delivery_rate: number;
  retouch_costs: number;
  shipping_costs: number;
  state_tax_amount: number | null;
  state_tax_rate: number | null;
  status: string;
  studio_payout: number;
  studio_sales_tax: number | null;
  studio_totals: number;
  submitted_at: string | null;
  subtotal: number;
  tax_collectable: number | null;
  tax_rate: number;
  taxable_amount: number | null;
  tracking_number: string | null;
  tracking_url: string | null;
  type: string;
  used_credits: number | null;
  zip: string | null;
}
