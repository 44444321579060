import { FC, useState } from 'react';

// Plugins
import * as Sentry from '@sentry/browser';
import PersonaReact from 'persona-react';

// Redux
import { useDispatch } from 'react-redux';
import { updateMyAccountStudioRequest } from '../../Settings/actions';

// Images
import imageOtoLogo from '@/assets/images/oto-logo-black.png';

// Styles
import './style.css';

const PERSONA_TEMPLATE_ID = import.meta.env.VITE_PERSONA_TEMPLATE_ID;
const PERSONA_ENVIRONMENT_ID = import.meta.env.VITE_PERSONA_ENVIRONMENT_ID;

// Types
import { StudioAddress, StudioVerificationStatus, StudioSellerType } from '@/types';

interface VerificationModalProps {
  studioId: string;
  studioVerificationUid?: string;
  onCloseVerificationModal: () => void;
}

const VerificationModal: FC<VerificationModalProps> = ({ studioId, studioVerificationUid, onCloseVerificationModal }) => {
  const dispatch = useDispatch();

  const [isPersonaReady, setIsPersonaReady] = useState<boolean>(false);

  // UI Handlers
  const handlePersonaInquiryReady = (): void => setIsPersonaReady(true);
  const handlePersonaInquiryEvent = (name: string, metadata?: { [key: string]: unknown }): void => {
    const EVENT_START = 'start';

    // Save initial inquiry id so we can keep track and re-use it
    if (name === EVENT_START && !studioVerificationUid) {
      dispatch(
        updateMyAccountStudioRequest({
          id: studioId,
          verification_status: StudioVerificationStatus.Pending,
          studio_verification_attributes: { verification_uid: metadata?.inquiryId }
        })
      );
    }
  };
  const handlePersonaInquiryError = (error: unknown): string => Sentry.captureException(error);
  const handlePersonaInquiryComplete = ({ inquiryId, status, fields }: { inquiryId: string; status: string; fields: any }): void => {
    const sellerType: StudioSellerType = fields['user-type']['value'];

    let studioVerificationAttributes;
    let verifiedAddress: StudioAddress | undefined;

    if (sellerType === StudioSellerType.Individual) {
      const firstName: string = fields['name-first']['value'];
      const lastName: string = fields['name-last']['value'];

      verifiedAddress = {
        line1: fields['address-street-1']['value'],
        line2: fields['address-street-2']['value'],
        city: fields['address-city']['value'],
        state: fields['address-subdivision']['value'],
        zip: fields['address-postal-code']['value']
      };

      studioVerificationAttributes = {
        seller_type: sellerType,
        first_name: firstName,
        last_name: lastName
      };
    } else if (sellerType.match(StudioSellerType.Company)) {
      const businessName: string = fields['business-name']['value'];
      const dba: string = fields['dba']['value'];

      verifiedAddress = {
        line1: fields['Business-address-street-1']['value'],
        line2: fields['Business-address-street-2']['value'],
        city: fields['Business-address-city']['value'],
        state: fields['Business-address-subdivision']['value'],
        zip: fields['Business-address-postal-code']['value']
      };

      studioVerificationAttributes = {
        seller_type: StudioSellerType.Company,
        business_name: businessName,
        dba
      };
    }

    // First call with verification attributes
    dispatch(
      updateMyAccountStudioRequest(
        {
          id: studioId,
          ...(verifiedAddress ? { address_attributes: verifiedAddress } : {}),
          studio_verification_attributes: studioVerificationAttributes
        },
        // Second call with Persona verification status
        () =>
          dispatch(
            updateMyAccountStudioRequest(
              {
                id: studioId,
                verification_status: status === 'completed' ? StudioVerificationStatus.Verified : StudioVerificationStatus.NotVerified,
                studio_verification_attributes: { verification_uid: inquiryId }
              },
              () => onCloseVerificationModal()
            )
          )
      )
    );
  };

  return (
    <aside className="modal animate">
      <div className="modal__box modal__box--clean business-verification">
        <button className="button button--action modal__close" name="close" type="button" onClick={onCloseVerificationModal}>
          <i className="icon-close"></i>
        </button>
        {!isPersonaReady && (
          <figure className="business-verification__oto">
            <img src={imageOtoLogo} alt="Oto Logo" width="300" />
          </figure>
        )}
        <PersonaReact
          environmentId={PERSONA_ENVIRONMENT_ID}
          templateId={studioVerificationUid ? undefined : PERSONA_TEMPLATE_ID}
          inquiryId={studioVerificationUid}
          onEvent={handlePersonaInquiryEvent}
          onReady={handlePersonaInquiryReady}
          onCancel={onCloseVerificationModal}
          onError={handlePersonaInquiryError}
          onComplete={({ inquiryId, status, fields }) => handlePersonaInquiryComplete({ inquiryId, status, fields })}
        />
      </div>
    </aside>
  );
};

export default VerificationModal;
