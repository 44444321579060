import * as api from '@/utils/http';

// Users
export const getUserList = (payload) => {
  const endpoint = 'users';
  const args = { ...payload };

  return api.get(endpoint, args);
};

export const getUser = (payload) => {
  const endpoint = `users/${payload.id}`;

  return api.get(endpoint);
};

export const updateUser = (payload) => {
  const endpoint = `users/${payload.id}`;
  const args = { ...payload };

  return api.put(endpoint, args);
};

export const createStudioUserInvitation = (payload) => {
  const endpoint = 'studio_users/invite';
  const args = { first_name: payload.firstName, last_name: payload.lastName, email: payload.email, studio_role: payload.studioRole };

  return api.post(endpoint, args);
};

export const createStudioUserAcceptInvitation = (payload) => {
  const endpoint = `studio_users/accept-invitation/${payload.id}`;
  const args = { password: payload.password };

  return api.post(endpoint, args);
};

export const getStudioUserInvitationDetails = (payload) => {
  const endpoint = `studio_users/invitation-details/${payload.id}`;

  return api.get(endpoint);
};

export const getStudioUsers = (payload) => {
  const endpoint = 'studio_users';
  const args = { ...payload };

  return api.get(endpoint, args);
};

export const deleteStudioUserInvitation = (payload) => {
  const endpoint = `users/${payload.id}`;

  return api.del(endpoint);
};

export const getMyAccount = () => {
  const endpoint = 'users/self';

  return api.get(endpoint);
};

export const updateAccount = (payload) => {
  const { accountInfo } = payload;
  const endpoint = 'users/self';

  return api.post(endpoint, accountInfo);
};

// Studio
export const getStudios = (payload) => {
  const endpoint = 'studios';
  const args = { ...payload };

  return api.get(endpoint, args);
};

export const getStudio = (payload) => {
  const endpoint = `studios/${payload.studioId}`;
  return api.get(endpoint);
};

export const getStudioTransactions = (payload) => {
  const { studioId, ...args } = payload;
  const endpoint = `studios/${studioId}/wallet-transactions`;

  if (payload.format) {
    return api.getBlob(endpoint, args, args.fileName);
  }

  return api.get(endpoint, args);
};

export const updateStudio = (payload) => {
  const { studio } = payload;
  const endpoint = `studios/${studio.id}`;
  return api.put(endpoint, studio);
};

// Stripe
export const updateStripeAccount = (payload) => {
  const { studioId, code } = payload;
  const endpoint = `studios/${studioId}/stripe-account`;
  return api.post(endpoint, { code });
};

export const deleteStripeAccount = (studioId) => {
  const endpoint = `studios/${studioId}/stripe-account`;
  return api.del(endpoint);
};

export const createStripeExpressOnboarding = (payload) => {
  const endpoint = `s/stripe-express/studios/${payload.studioId}/onboarding`;
  const args = { ...payload };

  return api.post(endpoint, args);
};

export const createStripeExpressComplete = (payload) => {
  const endpoint = `s/stripe-express/studios/${payload.studioId}/onboarding/complete`;
  const args = { ...payload };

  return api.post(endpoint, args);
};

export const createNexus = (payload) => {
  const endpoint = `studios/${payload.studioId}/nexus_states`;
  const args = {
    zip: payload.nexus.zip,
    state: payload.nexus.state
  };

  return api.post(endpoint, args);
};

export const getNexusList = (payload) => {
  const endpoint = `studios/${payload}/nexus_states`;

  return api.get(endpoint);
};

export const deleteNexus = (payload) => {
  const endpoint = `nexus_states/${payload}`;
  return api.del(endpoint);
};

// Subscriptions
export const createSubscription = (payload) => {
  const endpoint = 'subscriptions';
  const args = {
    subscription_plan_id: payload.plan,
    payment_token: payload.token,
    payment_type: payload.type,
    coupon: payload.coupon
  };

  return api.post(endpoint, args);
};

export const getSubscriptions = () => {
  const endpoint = 'subscriptions';

  return api.get(endpoint);
};

export const getSubscription = (payload) => {
  const endpoint = `subscriptions/${payload.id}`;

  return api.get(endpoint);
};

export const getSubscriptionPlans = () => {
  const endpoint = 'subscription-plans';

  return api.get(endpoint);
};

export const getSubscriptionCoupon = (payload) => {
  const endpoint = `subscriptions/coupons/${payload.coupon}`;

  return api.get(endpoint);
};

export const deleteSubscription = (payload) => {
  const endpoint = `subscriptions/${payload.id}`;

  return api.del(endpoint);
};

// Policy Terms
export const getCurrentTerms = () => {
  const endpoint = 'terms/current';

  return api.get(endpoint);
};
