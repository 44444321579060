import { put, call } from 'redux-saga/effects';
import { successToast } from './toast';

const successHandler = (message) => {
  return function* (callback, args) {
    yield call(successToast, message);
    return yield put(callback(args));
  };
};

export default successHandler;
