import { useEffect } from 'react';

export const useKeyDown = (callback: () => void, keys: string[]): void => {
  const onKeyDown = (event: KeyboardEvent): void => {
    const wasAnyKeyPressed = keys.some((key) => event.key === key);

    if (wasAnyKeyPressed) {
      event.preventDefault();
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);
};
