import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

// Plugins
import { Tooltip } from 'react-tippy';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { updateJob } from '../../../../actions';
import { getStudioThemeCollections, getPhotoDayThemeCollectionsByLab } from '../../../../../Storefront/CustomThemes/actions';

// Components
import Sidebar from '../../../Sidebar';
import Header from '../../../../Header';
import GridLoader from '@/components/Shared/ContentLoader/GridLoader';

// Images
import imageCustomThemes from '@/assets/images/icon-custom-themes.png';

// Styles
import '../../style.css';

const CustomThemes = ({ match, location, history }) => {
  const dispatch = useDispatch();

  const {
    params: { jobId },
    path
  } = match;
  const {
    jobs: { job },
    customThemes: { requesting: customThemesRequesting }
  } = useSelector((state) => state);

  const [sendBack, setSendBack] = useState(false);

  const [initialInformation, setInitialInformation] = useState('');
  const [information, setInformation] = useState({ collections: [] });

  const [labCustomThemesCollections, setLabCustomThemesCollections] = useState([]);
  const [photoDayThemesCollections, setphotoDayThemesCollections] = useState([]);

  const handleSelectAllCollections = () => {
    const mergedCollections = [...labCustomThemesCollections, ...photoDayThemesCollections];

    if (mergedCollections.concat(photoDayThemesCollections).every((collection) => information.collections.includes(collection.id))) {
      setInformation({ collections: [] });
    } else {
      setInformation({ collections: mergedCollections.map((collection) => collection.id) });
    }
  };

  const handleCollectionRefresh = () => {
    dispatch(
      getStudioThemeCollections({ per_page: 1000 }, ({ data }) => {
        const { meta } = job;
        const labCollections = data.filter((collection) => collection.lab.id === meta.lab_id);

        if (labCollections) {
          setLabCustomThemesCollections(labCollections);
        }
      })
    );
  };

  const handleCollectionSelect = (id) => {
    if (information.collections.some((collectionId) => collectionId === id)) {
      setInformation({ collections: information.collections.filter((collectionId) => collectionId !== id) });
    } else {
      setInformation({ collections: [...information.collections, id] });
    }
  };

  const handleSave = (e) => {
    e.preventDefault();

    const values = { studio_theme_collection_ids: information.collections };

    setInitialInformation(JSON.stringify(information));

    dispatch(updateJob({ ...values, id: jobId }, () => sendBack && history.push(`/jobs/${jobId}/dashboard`)));
  };

  useEffect(() => {
    const { sendBack: shouldSendBack } = location.state ? location.state : false;

    if (shouldSendBack) {
      setSendBack(true);
    }
  }, []);

  useEffect(() => {
    if (job?.id) {
      const jobInfo = { collections: job.studio_theme_collections.map((collection) => collection.id) };
      const { meta } = job;

      setInformation(jobInfo);
      setInitialInformation(JSON.stringify(jobInfo));

      dispatch(
        getStudioThemeCollections({ per_page: 1000 }, ({ data }) => {
          const labCollections = data.filter((collection) => collection.lab.id === meta.lab_id);

          if (labCollections) {
            setLabCustomThemesCollections(labCollections);
          }
        })
      );

      dispatch(
        getPhotoDayThemeCollectionsByLab({ labId: meta.lab_id }, ({ data }) => {
          const photoDayCollections = data;

          if (photoDayCollections) {
            setphotoDayThemesCollections(photoDayCollections);
          }
        })
      );
    }
  }, [job?.id]);

  return (
    <>
      <Header history={history} jobId={jobId} title="Settings" />

      <main className="container flex job-settings-store">
        <Sidebar jobType={job.access_mode} jobId={jobId} path={path} />
        <form className="basis-9/12 md:basis-full job-settings-store__container" onSubmit={handleSave}>
          <header className="flex justify-between items-center job-settings-store__header">
            <h2 className="text-headline-sm">Store</h2>
            <button type="submit" className="button button--medium" disabled={JSON.stringify(information) === initialInformation}>
              Save
            </button>
          </header>

          <section className="panel panel--section">
            <header className="job-settings-store__icon-header">
              <img src={imageCustomThemes} className="job-settings-store__icon" alt="card" />
              <div className="flex justify-between start">
                <div className="flex flex-col justify-between">
                  <h2 className="job-settings-store__panel-title text-headline-sm">Custom Themes</h2>
                  <p className="m-0">Select a collection below.</p>
                </div>
                {labCustomThemesCollections.length > 0 && (
                  <button className="button button--link button--inline" type="button" onClick={handleSelectAllCollections}>
                    {labCustomThemesCollections.concat(photoDayThemesCollections).every((collection) => information.collections.includes(collection.id))
                      ? 'Clear All'
                      : 'Select All'}
                  </button>
                )}
                <button className="button button--outline button--small button--inline sm:hidden" type="button" onClick={handleCollectionRefresh}>
                  <i className="icon-refresh"></i>
                </button>
                <button className="button button--outline button--small sm:hidden" type="button">
                  <a href="/storefront/custom-themes" target="_blank" rel="noopener noreferrer">
                    <i className="icon-add"></i>
                  </a>
                </button>
              </div>
            </header>

            <fieldset>
              {customThemesRequesting ? (
                <GridLoader columns={6} rows={1} />
              ) : (
                <>
                  {labCustomThemesCollections.length > 0 ? (
                    <ul className="grid gap-2.5 animate job-settings-store__themes">
                      {labCustomThemesCollections.map((collection, index) => (
                        <li
                          className={`job-settings-store__theme-container ${
                            information.collections.find((value) => value === collection.id) ? 'job-settings-store__theme-container--active' : ''
                          }`}
                          key={collection.id + index}
                          onClick={() => handleCollectionSelect(collection.id)}
                        >
                          <figure className="job-settings-store__theme">
                            <div className="job-settings-store__icon-container">
                              <span className="text-neutral-500 font-bold text-capitalize job-settings-store__collection-letter">{collection.name[0]}</span>
                            </div>
                            <figcaption>
                              <Tooltip title={collection.name} position="top" arrow="true" distance="10">
                                {collection.name}
                              </Tooltip>
                            </figcaption>
                          </figure>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <aside className="flex flex-col justify-center items-center animate job-settings-store__collections">
                      <p>
                        Upload your custom theme{' '}
                        <a
                          href="/storefront/custom-themes"
                          className="button--link font-bold"
                          target="_blank"
                          rel="noopener noreferrer"
                          disabled={!information.priceSheetId}
                        >
                          here
                        </a>
                      </p>
                    </aside>
                  )}
                </>
              )}
            </fieldset>

            {/* Complementary PhotoDay Theme Collection */}
            {photoDayThemesCollections.length > 0 && (
              <fieldset>
                <div className="flex justify-between start mb-5">
                  <div className="flex flex-col justify-between">
                    <h2 className="job-settings-store__panel-title text-headline-sm">{`PhotoDay Themes for ${job.meta.lab_name}`}</h2>
                    <p className="m-0">Select a collection below.</p>
                  </div>
                </div>
                <ul className="grid gap-2.5 animate job-settings-store__themes">
                  {photoDayThemesCollections.map((collection, index) => (
                    <li
                      className={`job-settings-store__theme-container ${
                        information.collections.find((value) => value === collection.id) ? 'job-settings-store__theme-container--active' : ''
                      }`}
                      key={collection.id + index}
                      onClick={() => handleCollectionSelect(collection.id)}
                    >
                      <figure className="job-settings-store__theme">
                        <div className="job-settings-store__icon-container">
                          <span className="text-neutral-500 font-bold text-capitalize job-settings-store__collection-letter">{collection.name[0]}</span>
                        </div>
                        <figcaption>
                          <Tooltip title={collection.name} position="top" arrow="true" distance="10">
                            {collection.name}
                          </Tooltip>
                        </figcaption>
                      </figure>
                    </li>
                  ))}
                </ul>
              </fieldset>
            )}
          </section>
        </form>
      </main>
    </>
  );
};

CustomThemes.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      jobId: PropTypes.string.isRequired
    })
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({
      sendBack: PropTypes.bool.isRequired
    })
  }),
  history: PropTypes.object.isRequired
};

CustomThemes.defaultProps = {
  match: {
    params: {
      jobId: ''
    }
  },
  location: {
    state: {
      sendBack: false
    }
  },
  history: {}
};

export default CustomThemes;
