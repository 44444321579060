import {
  CREATE_OFFER_ERROR,
  CREATE_OFFER_REQUEST,
  CREATE_OFFER_SUCCESS,
  DESTROY_OFFER_ERROR,
  DESTROY_OFFER_REQUEST,
  DESTROY_OFFER_SUCCESS,
  OFFER_GENERATE_CODE_ERROR,
  OFFER_GENERATE_CODE_REQUEST,
  OFFER_GENERATE_CODE_SUCCESS,
  OFFER_LIST_ERROR,
  OFFER_LIST_REQUEST,
  OFFER_LIST_SUCCESS,
  PAUSE_OFFER_ERROR,
  PAUSE_OFFER_REQUEST,
  PAUSE_OFFER_SUCCESS,
  UNPAUSE_OFFER_ERROR,
  UNPAUSE_OFFER_REQUEST,
  UNPAUSE_OFFER_SUCCESS,
  UPDATE_OFFER_ERROR,
  UPDATE_OFFER_REQUEST,
  UPDATE_OFFER_SUCCESS
} from './constants';

import defaultPager from '@/utils/defaultPager';
import { handleErrorState, handleRequestingState } from '@/utils/reducerHelpers';

const initialState = {
  result: [],
  entities: {
    offers: {},
    offer: {}
  },
  pager: Object.assign({}, defaultPager),
  requesting: false,
  successful: false,
  errors: [],
  saveSuccessRedirect: false
};

const handleOfferListSuccess = (state, payload) => {
  const {
    entities: { offers },
    pager,
    result
  } = payload;

  return {
    ...state,
    ...{
      entities: { ...state.entities, offers },
      pager,
      result,
      errors: [],
      requesting: false,
      successful: true
    }
  };
};

const handleOfferRequest = (state) => {
  const requestState = handleRequestingState(state);
  return Object.assign({}, requestState, { saveSuccessRedirect: false });
};

const handleOfferGenerateCodeSuccess = (state) => ({
  ...state,
  requesting: false
});

const handleOfferCreateSuccess = (state, payload) => {
  const { entities } = state;
  const { offer } = payload;
  const newOffers = { ...entities.offers, ...{ [offer.id]: offer } };

  return {
    ...state,
    ...{
      entities: { ...entities, ...{ offers: newOffers } },
      errors: [],
      requesting: false,
      successful: true,
      saveSuccessRedirect: true
    }
  };
};

const handleOfferUpdateSuccess = (state, payload) => {
  const { entities } = state;
  const { offer } = payload;
  const newOffers = { ...entities.offers, ...{ [offer.id]: offer } };
  return {
    ...state,
    ...{
      entities: { ...entities, ...{ offers: newOffers } },
      errors: [],
      requesting: false,
      successful: true,
      saveSuccessRedirect: true
    }
  };
};

const handleOfferDestroySuccess = (state, { offerId }) => {
  const { entities } = state;
  const { offers } = entities;
  delete offers[offerId];
  return {
    ...state,
    ...{
      entities: { ...entities, ...{ offers } },
      result: state.result.filter((id) => id !== offerId),
      errors: [],
      requesting: false,
      successful: true,
      saveSuccessRedirect: true
    }
  };
};

const handleOfferError = (state, payload) =>
  Object.assign({}, handleErrorState(state, payload), {
    saveSuccessRedirect: false
  });

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case OFFER_LIST_REQUEST:
      return handleRequestingState(state);
    case OFFER_GENERATE_CODE_SUCCESS:
      return handleOfferGenerateCodeSuccess(state);
    case OFFER_LIST_SUCCESS:
      return handleOfferListSuccess(state, payload);
    case OFFER_LIST_ERROR:
      return handleErrorState(state, payload);
    case DESTROY_OFFER_SUCCESS:
      return handleOfferDestroySuccess(state, payload);
    case CREATE_OFFER_SUCCESS:
      return handleOfferCreateSuccess(state, payload);

    case CREATE_OFFER_REQUEST:
    case OFFER_GENERATE_CODE_REQUEST:
    case UPDATE_OFFER_REQUEST:
    case DESTROY_OFFER_REQUEST:
    case PAUSE_OFFER_REQUEST:
    case UNPAUSE_OFFER_REQUEST:
      return handleOfferRequest(state);

    case UPDATE_OFFER_SUCCESS:
    case PAUSE_OFFER_SUCCESS:
    case UNPAUSE_OFFER_SUCCESS:
      return handleOfferUpdateSuccess(state, payload);

    case CREATE_OFFER_ERROR:
    case OFFER_GENERATE_CODE_ERROR:
    case UPDATE_OFFER_ERROR:
    case DESTROY_OFFER_ERROR:
    case PAUSE_OFFER_ERROR:
    case UNPAUSE_OFFER_ERROR:
      return handleOfferError(state, payload);

    default:
      return state;
  }
};

export default reducer;
