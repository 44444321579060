import { call, put, takeLatest, all } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import * as types from './constants';
import * as actions from './actions';

import * as customThemesApi from '@/api/custom-themes-api';

import errorHandler from '@/utils/errorResponseHandler';

function* getStudioThemeCollectionsRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(customThemesApi.getCollections, payload);

    yield put(actions.getStudioThemeCollectionsSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getStudioThemeCollectionsError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getPhotoDayThemeCollectionsRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(customThemesApi.getPhotoDayThemeCollections, payload);

    yield put(actions.getPhotoDayThemeCollectionsSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getPhotoDayThemeCollectionsError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getPhotoDayThemeCollectionsByLabRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(customThemesApi.getPhotoDayThemeCollectionsByLab, payload);

    yield put(actions.getPhotoDayThemeCollectionsByLabSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getPhotoDayThemeCollectionsByLabError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getAllCustomThemeLabsRequest(action) {
  const {
    payload: { callback, payload }
  } = action;
  try {
    yield put(showLoading());

    const response = yield call(customThemesApi.getAllCustomThemeLabs, payload);

    yield put(actions.getAllCustomThemeLabsSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getAllCustomThemeLabsError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getLabCustomThemeProductsRequest(action) {
  const {
    payload: { callback, payload }
  } = action;
  try {
    yield put(showLoading());

    const response = yield call(customThemesApi.getThemeProducts, payload);

    yield put(actions.getLabCustomThemeProductsSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getLabCustomThemeProductsError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* createThemeCollectionRequest(action) {
  const {
    payload: { callback, payload }
  } = action;
  try {
    yield put(showLoading());

    const response = yield call(customThemesApi.createCollection, payload);

    yield put(actions.createThemeCollectionSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createThemeCollectionError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getThemeCollectionRequest(action) {
  const {
    payload: { callback, payload }
  } = action;
  try {
    yield put(showLoading());

    const response = yield call(customThemesApi.getCollection, payload);

    yield put(actions.getThemeCollectionSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getThemeCollectionError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* updateThemeCollectionRequest(action) {
  const {
    payload: { callback, payload }
  } = action;
  try {
    yield put(showLoading());

    const response = yield call(customThemesApi.updateCollection, payload);

    yield put(actions.updateThemeCollectionSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.updateThemeCollectionError, error);

    if (callback) {
      callback(error?.response);
    }
  } finally {
    yield put(hideLoading());
  }
}

function* deleteThemeCollectionRequest(action) {
  const {
    payload: { callback, payload }
  } = action;
  try {
    yield put(showLoading());

    const response = yield call(customThemesApi.deleteCollection, payload);

    yield put(actions.deleteThemeCollectionSuccess({ response, payload }));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.deleteThemeCollectionError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getCustomThemeProductPreviewRequest(action) {
  const {
    payload: { callback, payload }
  } = action;
  try {
    yield put(showLoading());

    const response = yield call(customThemesApi.getPreview, payload);

    yield put(actions.getCustomThemeProductPreviewSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getCustomThemeProductPreviewError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getCustomThemesWatcher() {
  yield all([
    takeLatest(types.GET_STUDIO_THEME_COLLECTIONS_REQUEST, getStudioThemeCollectionsRequest),
    takeLatest(types.GET_PHOTODAY_THEME_COLLECTIONS_REQUEST, getPhotoDayThemeCollectionsRequest),
    takeLatest(types.GET_PHOTODAY_THEME_COLLECTIONS_BY_LAB_REQUEST, getPhotoDayThemeCollectionsByLabRequest),
    takeLatest(types.GET_ALL_CUSTOM_THEME_LABS_REQUEST, getAllCustomThemeLabsRequest),
    takeLatest(types.GET_LAB_CUSTOM_THEME_PRODUCTS_REQUEST, getLabCustomThemeProductsRequest),
    takeLatest(types.CREATE_THEME_COLLECTION_REQUEST, createThemeCollectionRequest),
    takeLatest(types.GET_THEME_COLLECTION_REQUEST, getThemeCollectionRequest),
    takeLatest(types.UPDATE_THEME_COLLECTION_REQUEST, updateThemeCollectionRequest),
    takeLatest(types.DELETE_THEME_COLLECTION_REQUEST, deleteThemeCollectionRequest),
    takeLatest(types.GET_CUSTOM_THEME_PRODUCT_PREVIEW_REQUEST, getCustomThemeProductPreviewRequest)
  ]);
}

export default getCustomThemesWatcher;
