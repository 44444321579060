import * as api from '@/utils/http';
import setPagingSortFilter from '@/utils/setPagingSortFilter';

export const pricesheetList = (page = null, perPage = 15, search = null, order = null, dir = null) => {
  const endpoint = `price-sheets`;
  const args = setPagingSortFilter(page, perPage, search, order, dir);

  return api.get(endpoint, args).then((res) => {
    const { data, headers } = res;
    const pager = api.getPaginationParams(headers);

    return { data, pager };
  });
};

export const pricesheet = (payload) => {
  const { pricesheetId } = payload;
  const endpoint = `price-sheets/${pricesheetId}`;

  return api.get(endpoint);
};

export const pricesheetCSV = (pricesheetId, fileName) => {
  const endpoint = `price-sheets/${pricesheetId}/price-sheet-items?csv=true`;

  return api.getBlob(endpoint, {}, fileName);
};

export const pricesheetsDDL = (type) => {
  const appendType = type ? `-${type}` : '';
  const args = { per_page: 1000, order: 'name', dir: 'ASC' };
  const endpoint = `price-sheets${appendType}`;

  return api.get(endpoint, args);
};

export const createPricesheet = (payload) => {
  const { studioId, pricesheetInfo } = payload;
  const endpoint = `studios/${studioId}/price-sheets`;

  return api.post(endpoint, pricesheetInfo);
};

export const updatePricesheet = (payload) => {
  const { pricesheetInfo } = payload;
  const endpoint = `price-sheets/${pricesheetInfo.id}`;

  return api.put(endpoint, pricesheetInfo);
};

export const deletePricesheet = (pricesheetId, force) => {
  const endpoint = `price-sheets/${pricesheetId}`;
  const args = force ? { force } : {};

  return api.del(endpoint, args);
};

export const copyPricesheet = (payload) => {
  const { id } = payload;
  const endpoint = `price-sheets/${id}/copy`;

  return api.put(endpoint);
};

export const getPriceSheetThemeProperties = (payload) => {
  const primaryProperties = { ...payload };
  delete primaryProperties.id;

  const primaryProperty = Object.keys(primaryProperties).length ? Object.keys(primaryProperties)[0] : null;
  let endpoint = `price-sheets/${payload.id}/theme-properties`;

  if (primaryProperty) {
    primaryProperties[primaryProperty].forEach((value, i) => {
      endpoint += `${i ? '&' : '?'}${primaryProperty}[]=${value}`;
    });
  }

  return api.get(endpoint, {});
};
