import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useState, useEffect } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { getPricesheetRequest } from '../../../Storefront/PriceSheets/actions';

// Components
import TextLoader from '@/components/Shared/ContentLoader/TextLoader';

const CUSTOM_THEMES_ENABLED = import.meta.env.VITE_CUSTOM_THEMES_ENABLED === '1';
const BULK_SHIPPING_ENABLED = import.meta.env.VITE_BULK_SHIPPING_ENABLED === '1';
const KNOCK_OUT_BACKGROUND_ENABLED = import.meta.env.VITE_KNOCK_OUT_BACKGROUND_ENABLED === '1';

const Sidebar = ({ jobId, path, shippingSection }) => {
  const dispatch = useDispatch();

  const {
    studio: { id: studioId, feature_flags: studioFeatureFlags }
  } = useSelector((state) => state.login);
  const { job } = useSelector((state) => state.jobs);
  const { price_sheet_shipping_type: jobShippingType, price_sheet_id: jobPriceSheetId, requesting: jobRequesting } = job;

  const {
    entities: { pricesheet },
    requesting: priceSheetsRequesting
  } = useSelector((state) => state.pricesheets);
  const priceSheetLab = pricesheet && pricesheet.lab;
  const priceSheetMatchesJob = pricesheet && pricesheet.id === jobPriceSheetId;

  const labAllowsCustomThemes = priceSheetLab && priceSheetLab.allow_custom_themes;
  const labAllowBackgrounds = priceSheetLab && priceSheetLab.allow_backgrounds;
  const studioAllowsCustomThemes =
    priceSheetLab && studioFeatureFlags && studioFeatureFlags.allow_custom_themes && studioFeatureFlags.allow_custom_themes.includes(priceSheetLab.key);

  const customThemesAllowed = priceSheetMatchesJob && (studioAllowsCustomThemes || labAllowsCustomThemes);
  const backgroundsAllowed = KNOCK_OUT_BACKGROUND_ENABLED && priceSheetMatchesJob && labAllowBackgrounds;

  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

  const handleToggleSidebar = () => {
    setSidebarIsOpen(!sidebarIsOpen);
  };

  const setSectionTitle = (path) => {
    const pathArr = path.split('/');
    const section = pathArr[pathArr.length - 1];

    return (
      {
        details: 'Job Details',
        access: 'Access and Expiration',
        store: 'Store',
        'custom-themes': 'Custom Themes',
        backgrounds: 'Backgrounds',
        school: 'School',
        promotions: 'Gallery Promotions',
        bulk: 'Shipping',
        drop: 'Shipping',
        yearbook: 'Yearbook'
      }[shippingSection || section] || ''
    );
  };

  useEffect(() => {
    const currentSection = setSectionTitle(path);

    if (currentSection !== 'Store' && job && jobPriceSheetId && jobPriceSheetId !== pricesheet.id) {
      dispatch(getPricesheetRequest(jobPriceSheetId));
    }
  }, [job]);

  return (
    <aside className="basis-3/12 md:basis-full sidebar-container">
      <header className="flex items-center justify-between sidebar-header">
        <h2 className="text-headline-sm">Settings</h2>
        <button className="sidebar__mobile-action button button--outline" onClick={handleToggleSidebar}>
          {path && setSectionTitle(path)}
        </button>
      </header>

      <div className={`sidebar-navigation-container ${sidebarIsOpen ? 'sidebar-navigation-container--open' : ''}`} onClick={handleToggleSidebar}>
        <nav className="sidebar-navigation-submenu">
          <NavLink className="sidebar-navigation__item" to={`/jobs/${jobId}/settings/details`} activeClassName="sidebar-navigation__item--active">
            Job Details
          </NavLink>
          <NavLink className="sidebar-navigation__item" to={`/jobs/${jobId}/settings/access`} activeClassName="sidebar-navigation__item--active">
            Gallery Access
          </NavLink>
          {studioId !== 'all-studios' && (
            <>
              <NavLink className="sidebar-navigation__item" to={`/jobs/${jobId}/settings/store`} activeClassName="sidebar-navigation__item--active">
                Store
              </NavLink>
              <NavLink className="sidebar-navigation__item" to={`/jobs/${jobId}/settings/promotions`} activeClassName="sidebar-navigation__item--active">
                Gallery Promotions
              </NavLink>
              <NavLink
                className="sidebar-navigation__item"
                activeClassName="sidebar-navigation__item--active"
                to={`/jobs/${jobId}/marketing/campaign-settings`}
              >
                Auto Campaign Settings
              </NavLink>
              {BULK_SHIPPING_ENABLED && (
                <NavLink
                  className="sidebar-navigation__item"
                  to={`/jobs/${jobId}/settings/shipping/${jobShippingType?.includes('drop') ? 'drop' : 'bulk'}`}
                  isActive={(_, location) => location.pathname.includes('/settings/shipping/')}
                  activeClassName="sidebar-navigation__item--active"
                >
                  Shipping
                </NavLink>
              )}
              {job.access_mode === 'access_per_subject' && (
                <NavLink className="sidebar-navigation__item" activeClassName="sidebar-navigation__item--active" to={`/jobs/${jobId}/settings/yearbook`}>
                  Yearbook Selection
                </NavLink>
              )}
            </>
          )}
        </nav>

        {/* Submenu */}
        {studioId !== 'all-studios' && (
          <nav className="sidebar-navigation-submenu">
            {jobRequesting || priceSheetsRequesting ? (
              <TextLoader height={40} />
            ) : (
              <>
                {CUSTOM_THEMES_ENABLED && customThemesAllowed && (
                  <NavLink
                    className="sidebar-navigation__item"
                    to={`/jobs/${jobId}/settings/store/custom-themes`}
                    activeClassName="sidebar-navigation__item--active"
                  >
                    Custom Themes
                  </NavLink>
                )}
                {backgroundsAllowed && (
                  <NavLink
                    className="sidebar-navigation__item"
                    to={`/jobs/${jobId}/settings/store/backgrounds`}
                    activeClassName="sidebar-navigation__item--active"
                  >
                    Backgrounds
                  </NavLink>
                )}
              </>
            )}
          </nav>
        )}
      </div>
    </aside>
  );
};

Sidebar.propTypes = {
  jobType: PropTypes.string,
  jobId: PropTypes.string.isRequired,
  path: PropTypes.string,
  shippingSection: PropTypes.string
};

export default Sidebar;
