import { memo, useEffect } from 'react';

// Plugins
import { Tooltip } from 'react-tippy';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Components
import TagManager from '../../Tags/TagManager';
import PeopleManager from '../../People/PeopleManager';

// Helpers
import imageScaling from '@/utils/imageScaling';

// Styles
import 'react-lazy-load-image-component/src/effects/opacity.css';

const PhotoCard = memo(function PhotoCard({
  photo,
  person,
  requesting,
  filterTags,
  galleryType,
  biometricsEnabled,
  qrEnabled,
  filterPeople,
  filterPhotos,
  photoSelected,
  unselectedPhotos,
  allPhotosSelected,
  tagManagerOpenList,
  isReferencePhoto,
  peopleManagerOpenList,
  onDestroy,
  onPhotoSelect,
  onPhotoDragEnd,
  onPhotoFeature,
  onPhotoDragStart,
  onTagManagerShow,
  onPhotoSelectClear,
  onDigitalDownload,
  onPeopleManagerShow,
  onPhotosPeopleFilter,
  onShowLightboxToggle
}) {
  const actionDisabled = photoSelected.length > 1 || requesting;

  useEffect(() => {
    return () => {
      if (onDestroy && typeof onDestroy === 'function') {
        onDestroy();
      }
    };
  }, []);

  return (
    <>
      <figure className={`job-photo-card__figure ${isReferencePhoto ? 'pointer-events-none' : ''}`}>
        <div className="job-photo-card__image-header">
          <span className="job-photo-card__image-name">{photo.image_filename}</span>
          <Tooltip title="Expand View" position="top" arrow={false}>
            <button
              className={`button button--outline button--small button--noborder ${isReferencePhoto ? 'transparent' : ''}`}
              type="button"
              disabled={actionDisabled}
              draggable="false"
              onClick={() => {
                onPhotoSelect(photo.id, true);
                onShowLightboxToggle();
              }}
            >
              <i className="icon-view"></i>
            </button>
          </Tooltip>
        </div>

        <div className={`job-photo-card__image-container ${photo?.processing ? 'job-photo-card__image-container--processing' : ''}`}>
          {photo.image_url && (
            <LazyLoadImage
              className="job-photo-card__image"
              alt={photo.image_filename}
              src={imageScaling({ url: photo.image_url, size: 'medium' })}
              draggable="true"
              effect={'opacity'}
              delayTime={100}
              delayMethod={'debounce'}
              threshold={600}
              onTouchMove={(e) => e.preventDefault()}
              onDragStart={(e) => onPhotoDragStart(e, photo.id)}
              onDragEnd={onPhotoDragEnd}
              onClick={() => (requesting ? null : onPhotoSelect(photo.id))}
            />
          )}

          {/* Image training */}
          {photo.trained === false && <span className="job-photo-card__processing">Processing...</span>}
        </div>

        <div className={`job-photo-card__actions justify-end ${isReferencePhoto ? 'transparent' : ''}`}>
          {galleryType === 'private' ? (
            <>
              {filterPeople?.id && person && (
                <>
                  <Tooltip title={person.primary_photo_id && person.primary_photo_id === photo.id ? 'Unfeature' : 'Feature'} position="top" arrow={false}>
                    <button
                      className={`button button--outline button--small button--noborder job-photo-card__icon ${
                        person.primary_photo_id === photo.id ? 'job-photo-card__icon--active' : ''
                      }`}
                      type="button"
                      disabled={actionDisabled}
                      draggable="false"
                      onClick={() =>
                        onPhotoFeature(person.primary_photo_id && person.primary_photo_id === photo.id ? 'unfeature' : 'feature', photo.id, person.id)
                      }
                    >
                      <i className={`icon-star  ${person.primary_photo_id === photo.id ? '' : 'icon-star--empty'}`}></i>
                    </button>
                  </Tooltip>
                </>
              )}

              {biometricsEnabled && (
                <Tooltip title="Match Subject" position="top" arrow={false}>
                  <button
                    className="button button--outline button--small button--noborder button--nogray"
                    type="button"
                    disabled={actionDisabled}
                    draggable="false"
                    onClick={() => onPeopleManagerShow(photo.id)}
                  >
                    {photo.matched ? ( // "matched" means: when ALL faces within the image are matched
                      <i className="icon-match-full"></i>
                    ) : (
                      <>
                        {photo.subject_count > 0 && photo.subject_count < photo.face_count ? (
                          <>
                            <span className="job-photo-card__match-count">{photo.subject_count}</span>
                            <i className="icon-match-half"></i>
                          </>
                        ) : (
                          <i className="icon-match-empty"></i>
                        )}
                      </>
                    )}
                  </button>
                </Tooltip>
              )}

              {qrEnabled && (
                <Tooltip title="Match Subject" position="top" arrow={false}>
                  <button
                    className={`button button--outline button--small button--noborder ${photo.matched ? 'button--active' : ''}`}
                    type="button"
                    name="qr"
                    disabled={actionDisabled}
                    draggable="false"
                    onClick={() => onPeopleManagerShow(photo.id)}
                  >
                    <i className="icon-qr-contacts"></i>
                  </button>
                </Tooltip>
              )}
            </>
          ) : (
            <Tooltip title="Tag Photo" position="top" arrow={false}>
              <button
                className={`button button--outline button--small button--noborder job-photo-card__icon ${photo.tags?.length ? 'job-photo-card__icon--active' : ''}`}
                type="button"
                disabled={actionDisabled}
                draggable="false"
                onClick={() => onTagManagerShow(photo.id)}
              >
                <i className="icon-tag icon-tag--small"></i>
              </button>
            </Tooltip>
          )}
          <Tooltip title={photo.forbid_download ? 'Allow Digital Download' : 'Forbid Digital Download'} position="top" arrow={false}>
            <button
              className={`button button--outline button--small button--noborder job-photo-card__icon ${photo.forbid_download ? 'job-photo-card__icon--active' : ''}`}
              type="button"
              disabled={actionDisabled}
              draggable="false"
              onClick={() => onDigitalDownload(photo.forbid_download ? false : true, photo.id)}
            >
              <i className="icon-download"></i>
            </button>
          </Tooltip>
        </div>
      </figure>

      {galleryType !== 'private' && tagManagerOpenList.includes(photo.id) && (
        <div className="job-photo-grid__tag-card">
          <div className="flex justify-between items-center flex-nowrap job-photo-card__image-header">
            <span className="font-bold job-photo-card__image-name">{photo.image_filename}</span>

            <button
              className="button button--outline button--small button--noborder"
              type="button"
              disabled={actionDisabled}
              draggable="false"
              onClick={() => {
                onTagManagerShow(photo.id);
              }}
            >
              <i className="icon-close"></i>
            </button>
          </div>

          <TagManager
            filterTags={filterTags}
            photoSelected={[photo.id]}
            filterPhotos={filterPhotos}
            onTagManagerShow={onTagManagerShow}
            unselectedPhotos={unselectedPhotos}
            allPhotosSelected={allPhotosSelected}
            onPhotoSelectClear={onPhotoSelectClear}
          />
        </div>
      )}

      {galleryType === 'private' && peopleManagerOpenList.includes(photo?.id) && (filterPeople?.id === person?.id || !filterPeople?.id) && (
        <div className="job-photo-grid__tag-card">
          <div className="flex justify-between items-center flex-nowrap job-photo-card__image-header">
            <span className="font-bold job-photo-card__image-name">{photo.image_filename}</span>

            <button
              className={`button button--outline button--small button--noborder job-photo-card__icon ${photo.subject_count > 0 ? 'job-photo-card__icon--active' : ''}`}
              type="button"
              disabled={actionDisabled}
              draggable="false"
              onClick={() => {
                onPeopleManagerShow(photo.id);
              }}
            >
              <i className="icon-close"></i>
            </button>
          </div>

          <PeopleManager onPhotosPeopleFilter={onPhotosPeopleFilter} onPeopleManagerShow={onPeopleManagerShow} photo={photo} />
        </div>
      )}
    </>
  );
});

export default PhotoCard;
