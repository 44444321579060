import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

// Styles
import './navigation.css';

const Navigation = ({ path }) => {
  const [active, setActive] = useState(false);
  const [section, setSection] = useState('Quick Tips');

  const setSectionTitle = (path) => {
    const pathArr = path && path.split('/');
    const section = pathArr && pathArr[pathArr.length - 1];

    if (section === 'quicktips' && section !== 'Quick Tips') {
      setSection('Quick Tips');
    } else if (section === 'orders' && section !== 'Orders') {
      setSection('Orders');
    } else if (section === 'price-sheets' && section !== 'Price Sheets') {
      setSection('Price Sheets');
    } else if (section === 'offers-and-credits' && section !== 'Offers') {
      setSection('Offers');
    } else if (section === 'advance-pay' && section !== 'AdvancePay') {
      setSection('AdvancePay');
    } else if (section === 'promos' && section !== 'Promos') {
      setSection('Promos');
    } else if (section === 'settings' && section !== 'Settings') {
      setSection('Settings');
    }
  };

  const toggleMenu = () => {
    setActive(!active);
  };

  const collapseMenu = (event) => {
    const newSection = event.target.innerHTML;
    setActive(false);
    setSection(newSection);
  };

  useEffect(() => {
    setSectionTitle(path);
  }, [path]);

  return (
    <aside className="subnavigation">
      <header className={`subnavigation__header subnavigation__hide-desktop ${active ? 'subnavigation__header--active' : ''}`} onClick={toggleMenu}>
        <h5 className="subnavigation__item"> Store </h5>
        <h5 className="subnavigation__link--narrow">
          <b style={{ textTransform: 'capitalize' }}>{section}</b>
          <i className={`fa fa-angle-down sidebar__icon ${active ? 'sidebar__icon--active' : ''}`} />
        </h5>
      </header>

      <nav className={`subnavigation__container ${active ? 'subnavigation__container--active' : ''}`}>
        <header className="subnavigation__header subnavigation__hide-mobile">
          <h5 className="subnavigation__item"> Store </h5>
        </header>

        <ul className="subnavigation__list" onClick={collapseMenu}>
          <li className="subnavigation__item">
            <NavLink className="subnavigation__link" activeClassName="subnavigation__link--active" to="/storefront/quicktips">
              Quick Tips
            </NavLink>
          </li>
          <li className="subnavigation__item">
            <NavLink className="subnavigation__link" activeClassName="subnavigation__link--active" to="/storefront/orders">
              Orders
            </NavLink>
          </li>
          <li className="subnavigation__item">
            <NavLink className="subnavigation__link" activeClassName="subnavigation__link--active" to="/storefront/price-sheets">
              Price Sheets
            </NavLink>
          </li>
          <li className="subnavigation__item">
            <NavLink className="subnavigation__link" activeClassName="subnavigation__link--active" to="/storefront/offers-and-credits">
              Offers
            </NavLink>
          </li>
          <li className="subnavigation__item">
            <NavLink className="subnavigation__link" activeClassName="subnavigation__link--active" to="/storefront/advance-pay">
              AdvancePay
            </NavLink>
          </li>
          <li className="subnavigation__item">
            <NavLink className="subnavigation__link" activeClassName="subnavigation__link--active" to="/storefront/promos">
              Promos
            </NavLink>
          </li>
        </ul>
      </nav>
    </aside>
  );
};

export default Navigation;
