export const GIFT_CARD_LIST_REQUEST = 'GIFT_CARD_LIST_REQUEST';
export const GIFT_CARD_LIST_SUCCESS = 'GIFT_CARD_LIST_SUCCESS';
export const GIFT_CARD_LIST_ERROR = 'GIFT_CARD_LIST_ERROR';

export const CREATE_GIFT_CARD_REQUEST = 'CREATE_GIFT_CARD_REQUEST';
export const CREATE_GIFT_CARD_SUCCESS = 'CREATE_GIFT_CARD_SUCCESS';
export const CREATE_GIFT_CARD_ERROR = 'CREATE_GIFT_CARD_ERROR';

export const UPDATE_GIFT_CARD_REQUEST = 'UPDATE_GIFT_CARD_REQUEST';
export const UPDATE_GIFT_CARD_SUCCESS = 'UPDATE_GIFT_CARD_SUCCESS';
export const UPDATE_GIFT_CARD_ERROR = 'UPDATE_GIFT_CARD_ERROR';

export const DESTROY_GIFT_CARD_REQUEST = 'DESTROY_GIFT_CARD_REQUEST';
export const DESTROY_GIFT_CARD_SUCCESS = 'DESTROY_GIFT_CARD_SUCCESS';
export const DESTROY_GIFT_CARD_ERROR = 'DESTROY_GIFT_CARD_ERROR';

export const GET_CREDIT_SHEET_DETAIL_REQUEST = 'GET_CREDIT_SHEET_DETAIL_REQUEST';
export const GET_CREDIT_SHEET_DETAIL_SUCCESS = 'GET_CREDIT_SHEET_DETAIL_SUCCESS';
export const GET_CREDIT_SHEET_DETAIL_ERROR = 'GET_CREDIT_SHEET_DETAIL_ERROR';

export const CREATE_CREDITSHEET_REQUEST = 'CREATE_CREDITSHEET_REQUEST';
export const CREATE_CREDITSHEET_SUCCESS = 'CREATE_CREDITSHEET_SUCCESS';
export const CREATE_CREDITSHEET_ERROR = 'CREATE_CREDITSHEET_ERROR';

export const UPDATE_CREDITSHEET_REQUEST = 'UPDATE_CREDITSHEET_REQUEST';
export const UPDATE_CREDITSHEET_SUCCESS = 'UPDATE_CREDITSHEET_SUCCESS';
export const UPDATE_CREDITSHEET_ERROR = 'UPDATE_CREDITSHEET_ERROR';

export const CREDITSHEET_LIST_REQUEST = 'CREDITSHEET_LIST_REQUEST';
export const CREDITSHEET_LIST_SUCCESS = 'CREDITSHEET_LIST_SUCCESS';
export const CREDITSHEET_LIST_ERROR = 'CREDITSHEET_LIST_ERROR';

export const DESTROY_CREDITSHEET_REQUEST = 'DESTROY_CREDITSHEET_REQUEST';
export const DESTROY_CREDITSHEET_SUCCESS = 'DESTROY_CREDITSHEET_SUCCESS';
export const DESTROY_CREDITSHEET_ERROR = 'DESTROY_CREDITSHEET_ERROR';
