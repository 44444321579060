export const handleErrorState = (state, payload) => {
  const { error } = payload;
  const { message, response } = error;
  const { status, data } = response || {};

  const errorBody = data ? data : message;
  const errors = state.errors.concat([
    {
      code: status || 500,
      body: errorBody,
      time: new Date()
    }
  ]);

  return {
    ...state,
    ...{ errors, requesting: false, successful: false, invalidLogin: false }
  };
};

export const handleRequestingState = (state) => ({
  ...state,
  ...{ requesting: true, successful: false, errors: [], invalidLogin: false }
});

export const handleSuccessState = (state) => {
  return {
    ...state,

    errors: [],
    requesting: false,
    successful: true
  };
};
