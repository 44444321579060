import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

// Helpers
import * as api from '@/utils/http';

// Images
import logo from '@/assets/images/image_PD_logo.svg';
import logo_oto_hair from '@/assets/images/logo_oto_hair.png';

// Styles
import './forgot-password.css';

const ForgotPassword = () => {
  const [showNewPasswordField, setShowNewPasswordField] = useState(false);
  const [displayError, setDisplayError] = useState({ message: '' });
  const [showSuccessPasswordReset, setShowSuccessPasswordReset] = useState(false);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const { token } = useParams();

  useEffect(() => {
    const endpoint = `studios/check-forgot-password?token=${token}`;

    api
      .get(endpoint, {}, false)
      .then(() => {
        setShowNewPasswordField(true);
      })
      .catch(() => {
        setDisplayError({ message: 'Invalid Token!' });
      });
  }, [token]);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const endpoint = 'studios/reset-password';

    api
      .post(endpoint, { token, password }, false)
      .then(() => {
        setShowNewPasswordField(false);
        setShowSuccessPasswordReset(true);
      })
      .catch(() => {
        setDisplayError({ message: 'Error trying to reset password.' });
      });
  };

  const handleInputOnChange = (evt) => {
    const { target } = evt;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    if (name === 'password') setPassword(value);
    if (name === 'showPassword') setShowPassword(value);
    if (name === 'rememberMe') setRememberMe(value);
  };

  return (
    <section id="forgot_password" className="onboarding-bg">
      <img src={logo} className="gray-logo" alt="Logo" />

      <main className="container-fluid flex-page-centered">
        <div className="white-container basis-9/12 login-screen">
          <figure className="blue-gradient left-onboarding-container">
            <img src={logo_oto_hair} alt="Logo Oto" />
            <h1 className="font-bold"> Password Reset</h1>
            <p> Please enter your email and password to log back in. </p>
          </figure>

          {showNewPasswordField && (
            <article className="right-onboarding-container">
              <form onSubmit={handleSubmit} className="reset-password-form">
                <div>
                  <label className="font-bold">New Password</label>
                  <input
                    value={password}
                    onChange={handleInputOnChange}
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    className="input--block"
                    id="exampleInputPassword1"
                    placeholder="••••••"
                    autoComplete="current-password"
                  />
                </div>
                <fieldset>
                  <input id="showPassword" className="hidden" type="checkbox" name="showPassword" checked={showPassword} onChange={handleInputOnChange} />
                  <label htmlFor="showPassword" className="label--checkbox label--clean">
                    Show Password
                  </label>
                </fieldset>

                <button type="submit" style={{ display: 'none' }} />
              </form>

              <button
                className="button button--block login-btn"
                onClick={() => {
                  document.querySelector('button[type="submit"]').click();
                }}
              >
                Log In
              </button>

              <fieldset>
                <input id="rememberMe" className="hidden" type="checkbox" name="rememberMe" checked={rememberMe} onChange={handleInputOnChange} />
                <label htmlFor="rememberMe" className="label--checkbox label--clean">
                  Remember Me
                </label>
              </fieldset>

              <a href="https://www.photoday.io/signup/" className="font-bold no-account-link">
                {' '}
                Don't have an account?
              </a>
            </article>
          )}
          {showSuccessPasswordReset && (
            <aside className="right-onboarding-container centered">
              <h3>Password updated successfully!</h3>
              <Link to="/">
                <h4>Login</h4>
              </Link>
            </aside>
          )}
          {displayError.message && (
            <aside className="right-onboarding-container centered">
              <h2>{displayError.message}</h2>
            </aside>
          )}
        </div>
      </main>
    </section>
  );
};

export default ForgotPassword;
