import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

const SkeletonGallery = ({ height = 'calc(100vh - 320px)', width = '100%' }) => {
  const style = { height, ...(width ? { width } : {}) };

  return (
    <div style={style}>
      {Array(10)
        .fill(null)
        .map((_, i) => (
          <div key={i} className="shared__skeleton_photo photo-container" />
        ))}
    </div>
  );
};

SkeletonGallery.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string
};

export default SkeletonGallery;
