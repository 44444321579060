import PropTypes from 'prop-types';
import { useRef, useState, useEffect, memo } from 'react';

// Plugins
import { Tooltip } from 'react-tippy';

// Styles
import './style.css';

const Dropdown = memo(function Dropdown({
  selected,
  extraClass,
  buttonIcon,
  buttonName,
  buttonSubtitle,
  buttonExtraClass,
  buttonDisabled,
  buttonTooltip,
  children
}) {
  const buttonRef = useRef();

  const [open, setOpen] = useState(false);

  const handleClickOutside = (e) => {
    if (buttonRef?.current?.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setOpen(false);
  };

  const handleDropdown = () => setOpen(!open);

  useEffect(() => {
    if (open) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [open]);

  return (
    <div className={`dropdown ${extraClass}`}>
      <button
        ref={buttonRef}
        className={`button ${buttonExtraClass} ${open ? 'button-dropdown--active' : ''} ${selected ? 'button-dropdown--selected' : ''}`}
        name="dropdown"
        type="button"
        onClick={handleDropdown}
        disabled={buttonDisabled}
      >
        {buttonIcon ? <i className={buttonIcon} /> : buttonName}
        {buttonSubtitle && <small className="button-dropdown__subtitle">{buttonSubtitle}</small>}
        {buttonTooltip && (
          <Tooltip {...{ title: `${buttonTooltip}` }}>
            <i className="fa fa-question-circle-o" />
          </Tooltip>
        )}
      </button>
      {children}
    </div>
  );
});

Dropdown.propTypes = {
  selected: PropTypes.bool,
  extraClass: PropTypes.string,
  buttonIcon: PropTypes.string,
  buttonName: PropTypes.string,
  buttonSubtitle: PropTypes.string,
  buttonExtraClass: PropTypes.string,
  buttonTooltip: PropTypes.string,
  buttonDisabled: PropTypes.bool
};

export default Dropdown;
