import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateMyAccountRequest } from '../../actions';
import NotificationForm from './NotificationForm';

const Notification = () => {
  const dispatch = useDispatch();
  const { account, requesting } = useSelector((state) => state.users);
  const loading = !(account && account.id);

  const saveAccount = (values) => {
    dispatch(updateMyAccountRequest(values));
  };

  const { daily_order_notification, daily_nps_summary, weekly_nps_summary } = account || {};

  return (
    <NotificationForm
      onSubmit={saveAccount}
      initialValues={{ daily_order_notification, daily_nps_summary, weekly_nps_summary }}
      requesting={requesting}
      loading={loading}
    />
  );
};

export default Notification;
