import { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Redux
import { createJobKnockout, updateJobKnockout } from '../../../actions';
import { useSelector, useDispatch } from 'react-redux';

// Types
import { Knockout } from '@/types';

interface AddEditProps {
  jobId?: string;
  postProcessingId?: string;
  postProcessingItem?: Knockout;
  onClose: () => void;
}

const AddEdit: FC<AddEditProps> = ({ jobId, postProcessingId, postProcessingItem, onClose }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { requesting } = useSelector((state: any) => state.jobs);

  const [postProcessingJobName, setPostProcessingJobName] = useState<string>('');

  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const [knockoutRemoveSpill, setKnockoutRemoveSpill] = useState<boolean>(false);

  const handlePostProcessingJobNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => setPostProcessingJobName(event.currentTarget.value);

  const handleSelectedServicesChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const {
      target: { checked: serviceChecked, name: serviceName }
    } = event;

    if (serviceChecked) {
      setSelectedServices([...selectedServices, serviceName]);
    } else {
      setSelectedServices([...selectedServices.filter((service) => service !== serviceName)]);
    }
  };

  const handleKnockoutRemoveSpillChange = (event: React.ChangeEvent<HTMLInputElement>): void => setKnockoutRemoveSpill(event.currentTarget.checked);

  const handleAddEdit = (): void => {
    const servicesSelectedType = {
      extraction: selectedServices.includes('knockouts'),
      color_correction: selectedServices.includes('color-correction'),
      remove_spill: knockoutRemoveSpill
    };

    if (postProcessingId) {
      dispatch(updateJobKnockout({ knockoutId: postProcessingId, ...servicesSelectedType }, () => onClose()));
    } else {
      dispatch(
        createJobKnockout(
          {
            jobId,
            name: postProcessingJobName,
            ...servicesSelectedType
          },
          (response: { data: { id: string } }) => {
            const { data } = response;

            history.push(`/jobs/${jobId}/services/postprocessing/${data.id}/settings`);
          }
        )
      );
    }
  };

  useEffect(() => {
    if (postProcessingItem) {
      // Set name
      setPostProcessingJobName(postProcessingItem.name);

      // Set services
      setSelectedServices((): string[] => {
        const services = [];

        if (postProcessingItem.extraction === true) {
          services.push('knockouts');
        }

        if (postProcessingItem.color_correction === true) {
          services.push('color-correction');
        }

        return services;
      });

      // Set remove_spill
      setKnockoutRemoveSpill(postProcessingItem?.remove_spill);
    }
  }, [postProcessingItem]);

  return (
    <aside className="modal animate">
      <div className="modal__box modal__box--small">
        <header className="modal__header text-left">
          <button className="button button--action modal__close" name="button" type="button" onClick={onClose}>
            <i className="icon-close"></i>
          </button>
          <h3>Job Name</h3>
          <input
            className="input--block"
            type="text"
            name="name"
            value={postProcessingJobName}
            onChange={handlePostProcessingJobNameChange}
            placeholder="Give your job a unique name"
            maxLength={50}
          />
        </header>
        <main className="modal__content text-left">
          <h5>Select Service(s)</h5>
          {/* Extraction */}
          <fieldset>
            <input
              id="knockouts"
              className="hidden"
              type="checkbox"
              name="knockouts"
              checked={selectedServices.includes('knockouts')}
              onChange={handleSelectedServicesChange}
            />
            <label htmlFor="knockouts" className="label--checkbox label--lighter label--section">
              <strong>Knockouts</strong> - Remove the background of your photo(s)
            </label>
            <ul className="list--sublist">
              <li className="flex items-start nowrap">
                <div className="job-settings-store__switch">
                  <input
                    id="removeSpill"
                    className="hidden"
                    name="removeSpill"
                    type="checkbox"
                    checked={knockoutRemoveSpill}
                    onChange={handleKnockoutRemoveSpillChange}
                    disabled={selectedServices.includes('knockouts') === false}
                  />
                  <label
                    className={`label-switch label-switch--small ${selectedServices.includes('knockouts') === false ? 'disabled' : ''}`}
                    htmlFor="removeSpill"
                  />
                </div>
                <label
                  htmlFor="removeSpill"
                  className={`whitespace-pre-line text--normal ${selectedServices.includes('knockouts') === false ? 'disabled' : ''}`}
                >
                  <strong>Green Spill Correction</strong>
                  <br />
                  Removes green spill on subjects photographed using a green screen backdrop
                </label>
              </li>
            </ul>
          </fieldset>
          {/* Color Correction */}
          <fieldset>
            <input
              id="color-correction"
              className="hidden"
              type="checkbox"
              name="color-correction"
              checked={selectedServices.includes('color-correction')}
              onChange={handleSelectedServicesChange}
            />
            <label htmlFor="color-correction" className="label--checkbox label--lighter label--section">
              <strong>Color Correction</strong> - Automatically edit your photos by adjusting exposure, contrast, white balance, saturation, and gamma.
            </label>
          </fieldset>
        </main>
        <footer className="modal__footer">
          <button
            className="button button--large button--center"
            name="create-update"
            type="button"
            data-loading={requesting}
            onClick={handleAddEdit}
            disabled={requesting === true || postProcessingJobName === '' || selectedServices.length === 0}
          >
            {postProcessingId ? 'Update' : 'Create'}
          </button>
          <hr />
          <p className="m-0">
            <small>
              Need help?{' '}
              <a href="https://support.photoday.io/en/articles/6967528-post-processing-services-in-photoday" target="_blank" rel="noopener noreferrer">
                Learn more about PhotoDay’s Post-Processing Services
              </a>
              .
            </small>
          </p>
        </footer>
      </div>
    </aside>
  );
};

export default AddEdit;
