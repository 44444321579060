import React from 'react';
import { NavLink } from 'react-router-dom';

import './styles.css';

const SafeLink = ({ Component = NavLink, disabled = false, children, to, ...options }) => {
  if (disabled) {
    return (
      <button className={['shared__safe_link', options.className || ''].join(' ')} disabled>
        {children}
      </button>
    );
  }

  return (
    <Component {...options} to={to || ''}>
      {children}
    </Component>
  );
};

export default SafeLink;
