export const ORDER_LIST_REQUEST = 'ORDER_LIST_REQUEST';
export const ORDER_LIST_SUCCESS = 'ORDER_LIST_SUCCESS';
export const ORDER_LIST_ERROR = 'ORDER_LIST_ERROR';
export const ORDER_DETAIL_REQUEST = 'ORDER_DETAIL_REQUEST';
export const ORDER_DETAIL_LIST_SUCCESS = 'ORDER_DETAIL_LIST_SUCCESS';
export const ORDER_DETAIL_LIST_ERROR = 'ORDER_DETAIL_LIST_ERROR';
export const SET_ORDER_NAME_SEARCH = 'SET_ORDER_NAME_SEARCH';

export const CREATE_ORDER_RESEND_RECEIPT_REQUEST = 'CREATE_ORDER_RESEND_RECEIPT_REQUEST';
export const CREATE_ORDER_RESEND_RECEIPT_SUCCESS = 'CREATE_ORDER_RESEND_RECEIPT_SUCCESS';
export const CREATE_ORDER_RESEND_RECEIPT_ERROR = 'CREATE_ORDER_RESEND_RECEIPT_ERROR';

export const CREATE_ORDER_REPRINT_REQUEST = 'CREATE_ORDER_REPRINT_REQUEST';
export const CREATE_ORDER_REPRINT_SUCCESS = 'CREATE_ORDER_REPRINT_SUCCESS';
export const CREATE_ORDER_REPRINT_ERROR = 'CREATE_ORDER_REPRINT_ERROR';

export const GET_ORDER_REPRINTS_REQUEST = 'GET_ORDER_REPRINTS_REQUEST';
export const GET_ORDER_REPRINTS_SUCCESS = 'GET_ORDER_REPRINTS_SUCCESS';
export const GET_ORDER_REPRINTS_ERROR = 'GET_ORDER_REPRINTS_ERROR';

// Retouching
export const GET_RETOUCH_PHOTOS_REQUEST = 'GET_RETOUCH_PHOTOS_REQUEST';
export const GET_RETOUCH_PHOTOS_SUCCESS = 'GET_RETOUCH_PHOTOS_SUCCESS';
export const GET_RETOUCH_PHOTOS_ERROR = 'GET_RETOUCH_PHOTOS_ERROR';
