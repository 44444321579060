import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

const TextLoader = ({ height = 20, marginTop = 0, marginBottom = 0, minWidth, maxWidth }) => {
  return <div className="text-loader animate" style={{ height, marginTop, marginBottom, minWidth, maxWidth }}></div>;
};

TextLoader.propTypes = {
  height: PropTypes.number,
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
  minWidth: PropTypes.number,
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default TextLoader;
