import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Plugins
import moment from 'moment';

// Helpers
import { formatCurrency } from '@/utils/displayFormats';

const OrderRow = ({ order, timezones }) => {
  const styles = { dateCell: { whiteSpace: 'nowrap' } };
  const foundJobTimezone = timezones?.find((timezone) => timezone.name === order.job_timezone);

  return (
    <tr>
      <td style={styles.dateCell}>
        {order?.submitted_at && foundJobTimezone
          ? `${moment(order.submitted_at)?.tz(foundJobTimezone?.zone)?.format('L [@]h:mm A')} (${moment.tz(foundJobTimezone?.zone).zoneAbbr()})`
          : 'N/A'}
      </td>
      <td>
        <Link to={order.advance_pay ? `orders/advance-pay/credits/${order.id}` : `orders/${order.id}`}>
          <b>{order.has_reprint ? `${order.num} (RP)` : `${order.num}`}</b>
        </Link>
      </td>
      <td>{order.advance_pay ? 'AdvancePay' : 'Product'}</td>
      <td>{order.job_name}</td>
      <td>{order.customer_name}</td>
      <td className="capitalize">{{ paid: 'Paid', fulfilled: `Fulfilled ${order.bulk_ship ? '- Bulk' : ''}` }[order.status] || ''}</td>
      <td>
        {order.bulk_shipped_at ? (
          <Link to={`/jobs/${order.job_id}/shipping`}>Shipped - {moment(order.bulk_shipped_at).format('L')}</Link>
        ) : (
          <>
            {order.tracking_url ? (
              <a href={order.tracking_url} target="_blank" rel="noopener noreferrer">
                {order.tracking_number}
              </a>
            ) : (
              order.tracking_number || <i>N/P</i>
            )}
          </>
        )}
      </td>
      <td>{order.credit > 0 ? 'Yes' : 'No'}</td>
      <td>
        ${formatCurrency(order.total_with_credit)}
        {order.credit > 0 ? '*' : ''}
      </td>
    </tr>
  );
};

OrderRow.propTypes = {
  order: PropTypes.shape({
    submitted_at: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    job_name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    tracking_number: PropTypes.string,
    total_with_credit: PropTypes.number.isRequired
  }).isRequired
};

export default OrderRow;
