import { FC, ChangeEvent, useState } from 'react';
import { useHistory } from 'react-router';

// Redux
import { createOrganization } from './actions';
import { useDispatch, useSelector } from 'react-redux';

// Plugins
import { PatternFormat } from 'react-number-format';

// Images
import imageOrgGraph from '@/assets/images/illustration-org-page.svg';

// Types
import { Organization, OrganizationContact } from '@/types';

interface AddNewModalProps {
  studioId: string;
  onModalClose: () => void;
}

const AddNewModal: FC<AddNewModalProps> = ({ studioId, onModalClose }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { requesting } = useSelector((state: any) => state.organizations);

  const [organizationInfo, setOrganizationInfo] = useState<Partial<Organization>>();
  const [contactInfo, setContactInfo] = useState<Partial<OrganizationContact>>();

  const handleOrgInfoChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>): void => {
    const targetName = target.name;
    const targetValue = target.value;

    setOrganizationInfo((prevState?: Partial<Organization>) => ({ ...prevState, [targetName]: targetValue }));
  };

  const handleContactInfoChange = ({ target }: ChangeEvent<HTMLInputElement>): void => {
    setContactInfo((prevState?: Partial<OrganizationContact>) => ({ ...prevState, [target.name]: target.value }));
  };

  const handleAdd = (): void => {
    dispatch(
      createOrganization(
        {
          studioId,
          newOrganization: {
            ...organizationInfo,
            ...(contactInfo ? { contacts_attributes: [contactInfo] } : {})
          }
        },
        ({ data: newOrganization }: { data: Organization }) => {
          if (newOrganization?.id) {
            history.push(`/organizations/${newOrganization.id}`);
          }
        }
      )
    );
  };

  const handleEditCancel = (): void => onModalClose();

  return (
    <aside className="modal animate">
      <div className="modal__box modal__box--nopadding">
        <main className="modal__content flex flex-nowrap text-left">
          <aside className="basis-2/6 bg-gray">
            <div className="p-5">
              <img className="mb-5" src={imageOrgGraph} width="213" alt="Single place" />
              <h1 className="text-headline-md">Associate jobs under one entity with Organizations</h1>
              <p className="text-body-sm">
                An organization is the party for which you’re taking photos (e.g., league, school, individual client, etc.). When setting up an organization,
                you can include important contact information and notes.
              </p>
            </div>
          </aside>
          <form className="flex flex-col justify-between basis-8/12 p-5">
            <fieldset className="animate">
              <legend>Organization Information</legend>
              <div className="mb-5">
                <label htmlFor="name">Organization Name</label>
                <input
                  id="name"
                  type="text"
                  name="name"
                  placeholder="e.g. Hill Valley High School"
                  value={organizationInfo?.name ?? ''}
                  maxLength={50}
                  onChange={handleOrgInfoChange}
                />
              </div>
              <div className="mb-5">
                <label htmlFor="reportingCode">Organization Reporting ID (Optional)</label>
                <input
                  id="reportingCode"
                  type="text"
                  name="reporting_code"
                  value={organizationInfo?.reporting_code ?? ''}
                  maxLength={50}
                  onChange={handleOrgInfoChange}
                />
              </div>
              <div className="mb-7">
                <label htmlFor="notes">Notes (Optional)</label>
                <textarea id="notes" name="notes" value={organizationInfo?.notes ?? ''} rows={5} maxLength={244} onChange={handleOrgInfoChange} />
              </div>
              <legend>Contact Information</legend>
              <div className="flex flex-nowrap gap-2.5 mb-5">
                <div className="basis-6/12">
                  <label htmlFor="firstName">First Name</label>
                  <input
                    id="firstName"
                    type="text"
                    name="first_name"
                    placeholder="First Name"
                    value={contactInfo?.first_name ?? ''}
                    maxLength={50}
                    onChange={handleContactInfoChange}
                  />
                </div>
                <div className="basis-6/12">
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    id="lastName"
                    type="text"
                    name="last_name"
                    placeholder="Last Name"
                    value={contactInfo?.last_name ?? ''}
                    maxLength={50}
                    onChange={handleContactInfoChange}
                  />
                </div>
              </div>
              <div className="mb-5">
                <label htmlFor="role">Role</label>
                <input
                  id="role"
                  type="text"
                  name="title"
                  placeholder="e.g. Head Coach"
                  value={contactInfo?.title ?? ''}
                  maxLength={50}
                  onChange={handleContactInfoChange}
                />
              </div>
              <div className="flex flex-nowrap gap-2.5">
                <div className="basis-6/12">
                  <label htmlFor="email">Email</label>
                  <input
                    id="email"
                    type="text"
                    name="email"
                    placeholder="first.last@email.com"
                    value={contactInfo?.email ?? ''}
                    maxLength={50}
                    onChange={handleContactInfoChange}
                  />
                </div>
                <div className="basis-6/12">
                  <label htmlFor="name">Phone</label>
                  <PatternFormat
                    id="phone"
                    name="phone"
                    type="tel"
                    format="+1 (###) ###-####"
                    allowEmptyFormatting
                    mask="_"
                    placeholder="(555) 555-5555"
                    value={contactInfo?.phone}
                    onChange={handleContactInfoChange}
                  />
                </div>
              </div>
            </fieldset>

            <footer className="flex grow-0 justify-between">
              <div className="flex justify-end gap-2.5">
                <button className="button button--blue-outline" type="button" name="cancel" onClick={handleEditCancel}>
                  Cancel
                </button>
                <button
                  className="button"
                  type="button"
                  name="addOrganization"
                  data-loading={requesting}
                  disabled={organizationInfo?.name === undefined || organizationInfo?.name === ''}
                  onClick={handleAdd}
                >
                  Create Organization
                </button>
              </div>
            </footer>
          </form>
        </main>
      </div>
    </aside>
  );
};

export default AddNewModal;
