import React from 'react';

// Images
import image_logo from '@/assets/images/logo_oto_hair.png';

// Styles
import './style.css';

const ConsentModal = ({ onAccept }) => {
  return (
    <aside className="modal consent-modal animate">
      <div className="modal__box">
        <main className="modal__content">
          <figure>
            <img src={image_logo} alt="" />
            <figcaption>
              <h2 className="text-white-base">Welcome to PhotoDay</h2>
            </figcaption>
          </figure>

          <p>
            Before you continue, please agree to our{' '}
            <a href="https://photoday.io/terms" className="consent-modal__link" target="_blank" rel="noopener noreferrer">
              Terms of Service
            </a>{' '}
            and{' '}
            <a href="https://www.photoday.io/brand_guidelines/" className="consent-modal__link" target="_blank" rel="noopener noreferrer">
              Brand Guidelines
            </a>
          </p>
        </main>
        <footer className="modal__footer">
          <button className="button font-bold" name="button" type="button" onClick={onAccept}>
            Accept and Continue
          </button>
        </footer>
      </div>
    </aside>
  );
};

export default ConsentModal;
