import * as api from '@/utils/http';
const API_URL = import.meta.env.VITE_API_URL;

export const createBackgroundsCollection = (payload) => {
  const endpoint = 'background-collections';
  const args = { ...payload };

  return api.post(endpoint, args);
};

export const getBackgroundsCollectionList = (payload) => {
  const endpoint = 'background-collections';
  const args = { ...payload };

  return api.get(endpoint, args);
};

export const getBackgroundsCollection = (payload) => {
  const { collectionId } = payload;

  const endpoint = `background-collections/${collectionId}`;

  return api.get(endpoint);
};

export const getBackground = (payload) => {
  const { id } = payload;

  const endpoint = `backgrounds/${id}`;

  return api.get(endpoint);
};

export const updateBackgroundsCollection = (payload) => {
  const { id: collectionId } = payload;

  // This request can get large and doesn't seem to work through
  // the netlify proxy. So we request this one directly to the API -Greg
  const endpoint = `${API_URL}background-collections/${collectionId}`;
  const args = { ...payload };

  return api.put(endpoint, args);
};

export const deleteBackgroundsCollection = (payload) => {
  const { collectionId } = payload;

  const endpoint = `/background-collections/${collectionId}`;

  return api.del(endpoint);
};
