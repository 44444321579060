import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

const CustomCheckboxList = ({ name, label = null, options, onChange, className = '' }) => {
  const renderCheckboxes = ({ input }) => {
    const itemClass = `basis-${12 / options.length}`;

    return (
      <div className={`${className}`}>
        {options.map((CheckBoxComponent, index) => (
          <div key={`cl-${name}-${index}`} className={itemClass}>
            <CheckBoxComponent {...input} />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      {label && <label>{label}</label>}
      <Field
        name={name}
        component={renderCheckboxes}
        onChange={(event, newValue) => {
          onChange && onChange(newValue);
        }}
      />
    </div>
  );
};

CustomCheckboxList.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.element])).isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string
};

export default CustomCheckboxList;
