import { PhotoType, Crop } from '.';

export enum ExportType {
  SubjectData = 'subject_data',
  Yearbook = 'yearbook',
  ServiceItem = 'service_item',
  Compositing = 'compositing',
  Spoa = 'spoa'
}

export interface Export extends Crop {
  id?: string;
  name?: string;
  export_type?: ExportType | null;
  export_format?: 'csv' | 'txt' | null;
  crop?: boolean | null;
  crop_preset?: 'default' | 'id_card_1' | 'id_card_2' | 'id_card_3' | 'custom' | null;
  crop_preset_fitting?: 'standard_fitting' | 'tight_fitting' | 'loose_fitting' | null;
  crop_preset_resolution?: 'standard_resolution' | 'custom_resolution' | null;
  subject_filter?: string;
  subject_ids?: string[];
  subject_count?: number;
  field_options?: { [key: string]: string[] };
  filename_format?: string[];
  status?: string;
  export_preference?: PhotoType | null;
  photo_filename_filter?: string;
  export_when_no_photo?: boolean;
  group_by?: string;
}
