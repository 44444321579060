import PropTypes from 'prop-types';
import { Field } from 'redux-form';

// Styles
import './style.css';

const Switch = ({ name, label, note, size = 'default' }) => {
  const id = `sw-${name}`;

  return (
    <fieldset className="flex justify-between items-center">
      <div>
        {label && (typeof label === 'string' ? <h4>{label}</h4> : label)}
        {note && <p>{note}</p>}
      </div>
      <div className="switch-container">
        <div className="switch">
          <Field
            id={id}
            className={`cmn-toggle cmn-toggle-round ${size === 'small' ? 'cmn-toggle-round--small' : ''}`}
            name={name}
            type="checkbox"
            component="input"
          />
          <label className="label--clean" htmlFor={id} />
        </div>
      </div>
    </fieldset>
  );
};

Switch.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  note: PropTypes.string,
  size: PropTypes.string
};

export default Switch;
