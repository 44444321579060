import { FC } from 'react';

// Plugins
import Select, { SingleValue } from 'react-select';

// Images
import imageQrToQr from '@/assets/images/match-qr-qr.png';
import imageSubjectToQr from '@/assets/images/match-subject-qr.png';

// Types
import { QrSession, QrSessionFilter, QrCode, QrCodeResolutionAction, QrCodeResolutionError, SelectOptionType } from '@/types';

interface QrRecordsProps {
  qrSessions: QrSession[];
  requesting: boolean;

  activeSession: QrSession | null;
  activeSessionOption: SingleValue<SelectOptionType>;
  sessionFilter: QrSessionFilter | null;
  activeQrCode: QrCode | null;

  selectedPhotoId: string | null;

  formatSessionOption: (qrSession: QrSession) => SelectOptionType;
  filterQrCodes: () => QrCode[];
  getCodeTextStyle: (qrCode: QrCode) => string;

  onSessionChange: (selectedOption: SingleValue<SelectOptionType>) => void;
  onSessionFilterChange: (filter: QrSessionFilter) => void;

  onQrCodeChange: ({ event, qrCode }: { event: React.MouseEvent<HTMLElement>; qrCode: QrCode }) => void;
  onResolutionActions: ({
    event,
    actionType,
    qrCodeId
  }: {
    event?: React.MouseEvent<HTMLElement>;
    actionType: QrCodeResolutionAction;
    qrCodeId?: string;
  }) => void;
}

const QrRecords: FC<QrRecordsProps> = ({
  qrSessions,
  requesting,

  activeSession,
  activeSessionOption,
  sessionFilter,
  activeQrCode,

  selectedPhotoId,

  formatSessionOption,
  filterQrCodes,
  getCodeTextStyle,

  onSessionChange,
  onSessionFilterChange,

  onQrCodeChange,
  onResolutionActions
}) => {
  const isQrCodeResolved = (qrCode: QrCode): boolean => qrCode?.resolution_error === null || qrCode?.resolution !== null;
  const isDropZoneAvailable = (qrCode: QrCode): boolean =>
    qrCode?.discarded_at === null &&
    !isQrCodeResolved(qrCode) &&
    qrCode?.photo_candidates?.length > 0 &&
    qrCode.resolution_error === QrCodeResolutionError.NoPQR;

  return (
    <section className="flex flex-col basis-2/6 md:basis-full modal__content-section modal__content-section--dark">
      <header className="flex items-center justify-between grow-0 mb-5">
        <h3 className="m-0">Session</h3>
      </header>
      <main>
        <Select
          className="select mb-5"
          classNamePrefix="select"
          placeholder="Select a session"
          value={activeSessionOption}
          options={qrSessions.map((qrSession) => formatSessionOption(qrSession))}
          onChange={onSessionChange}
        />
        <h5>QR Records</h5>
        <nav className="mb-7">
          <ul className="flex gap-5">
            <li
              className={`navigation__item navigation__item--small ${sessionFilter === QrSessionFilter.All ? 'navigation__item--active' : ''}`}
              onClick={() => onSessionFilterChange(QrSessionFilter.All)}
            >
              All ({activeSession?.total_count})
            </li>
            <li
              className={`navigation__item navigation__item--small ${sessionFilter === QrSessionFilter.Issues ? 'navigation__item--active' : ''} ${
                activeSession?.errors_count === 0 ? 'disabled' : ''
              }`}
              onClick={() => onSessionFilterChange(QrSessionFilter.Issues)}
            >
              Issues ({activeSession?.errors_count})
            </li>
            <li
              className={`navigation__item navigation__item--small ${sessionFilter === QrSessionFilter.Resolved ? 'navigation__item--active' : ''} ${
                activeSession?.resolved_count === 0 ? 'disabled' : ''
              }`}
              onClick={() => onSessionFilterChange(QrSessionFilter.Resolved)}
            >
              Resolved ({activeSession?.resolved_count})
            </li>
          </ul>
        </nav>
        <ul className="job-qr__list">
          {filterQrCodes().map((qrCode: QrCode) => (
            <li
              key={qrCode.id}
              className={`job-qr__list-item animate ${qrCode.id === activeQrCode?.id ? 'job-qr__list-item--active' : ''}`}
              onClick={(event) => onQrCodeChange({ event, qrCode })}
            >
              <header className="flex flex-nowrap mb-5">
                <hgroup className={getCodeTextStyle(qrCode)}>
                  <h4 className="mb-0.5">
                    {qrCode.friendly_id} ({qrCode.photos?.length ?? 0})
                  </h4>
                  <h6 className="m-0 text--normal">
                    {qrCode.title}
                    <small className="m-0 text-body-xs block">{qrCode.subtitle}</small>
                  </h6>
                </hgroup>
                <aside className="flex justify-end flex-nowrap gap-2.5">
                  {qrCode.discarded_at && (
                    <button
                      className="button button--glassy button--xmedium"
                      type="button"
                      name="restore"
                      data-loading={requesting}
                      disabled={requesting}
                      onClick={(event) => onResolutionActions({ event, actionType: QrCodeResolutionAction.RestoreQrCode, qrCodeId: qrCode.id })}
                    >
                      Restore
                    </button>
                  )}
                  {!isQrCodeResolved(qrCode) && (
                    <button
                      className="button button--glassy button--xmedium"
                      type="button"
                      name="ignore"
                      data-loading={requesting}
                      disabled={requesting}
                      onClick={(event) => onResolutionActions({ event, actionType: QrCodeResolutionAction.IgnoreIssue, qrCodeId: qrCode.id })}
                    >
                      Ignore
                    </button>
                  )}
                </aside>
              </header>
              {/* DropZones */}
              {isDropZoneAvailable(qrCode) && (
                <>
                  <div
                    className={`job-qr__dropzone ${selectedPhotoId ? '' : 'disabled'}`}
                    onDrop={() => onResolutionActions({ actionType: QrCodeResolutionAction.CodeIdentification, qrCodeId: qrCode.id })}
                    onClick={(event) => onResolutionActions({ event, actionType: QrCodeResolutionAction.CodeIdentification, qrCodeId: qrCode.id })}
                  >
                    <img src={imageQrToQr} height="36" alt="QR to QR" draggable={false} />
                    <small>
                      Drop or Click Here to <br /> Match Single QR Image
                    </small>
                  </div>
                  <div
                    className={`job-qr__dropzone ${selectedPhotoId ? '' : 'disabled'}`}
                    onDrop={() => onResolutionActions({ actionType: QrCodeResolutionAction.PhotoAssociation, qrCodeId: qrCode.id })}
                    onClick={(event) => onResolutionActions({ event, actionType: QrCodeResolutionAction.PhotoAssociation, qrCodeId: qrCode.id })}
                  >
                    <img src={imageSubjectToQr} height="36" alt="Subject to QR" draggable={false} />
                    <small>
                      Drop or Click Here to <br /> Match Photo
                    </small>
                  </div>
                </>
              )}
            </li>
          ))}
        </ul>
      </main>
      <footer className="flex flex-nowrap grow-0 mt-auto gap-2.5">
        <button
          className="button button--block button--danger"
          name="ignore"
          type="button"
          data-loading={requesting}
          disabled={activeSession?.errors_count === 0 || sessionFilter === QrSessionFilter.Resolved || requesting}
          onClick={() => onResolutionActions({ actionType: QrCodeResolutionAction.IgnoreAllIssues })}
        >
          Ignore All Issues
        </button>
        <button
          className="button button--block button--danger-outline"
          name="ignore"
          type="button"
          data-loading={requesting}
          disabled={activeSession?.total_photo_count === 0 || requesting}
          onClick={() => onResolutionActions({ actionType: QrCodeResolutionAction.DeleteAllImages })}
        >
          Delete Image Batch
        </button>
      </footer>
    </section>
  );
};

export default QrRecords;
