import React, { useState, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';

// Components
import Support from './Support/';
import Header from './Header';
import Collateral from './Collateral';
import TopNav from '@/components/Shared/TopNav';
import PrivateRoute from '@/components/Shared/PrivateRoute';

// Styles
import './resources.css';

const StudioCollateral = () => {
  const location = useLocation();

  const [section, setSection] = useState('Collateral');
  const [active, setActive] = useState(false);

  const setSectionTitle = (path) => {
    const pathArr = path.split('/');
    const section = pathArr[pathArr.length - 1];

    if (section === 'sales' && section !== 'Collateral') {
      setSection('Collateral');
    }
  };

  const collapseMenu = (event) => {
    const innerHTML = event.target.innerHTML;
    const newSection = `${innerHTML.includes('Collateral') ? 'Collateral' : innerHTML.includes('Support') ? 'Support' : 'Webinars & Videos'}`;
    setActive(false);
    setSection(newSection);
  };

  const toggleMenu = () => {
    setActive(!active);
  };

  useEffect(() => {
    setSectionTitle(location.pathname);
  }, []);

  return (
    <div id="studio__collateral">
      <TopNav />
      <Header path={location.pathname} />

      <div className="container flex">
        <Switch>
          <PrivateRoute path="/collateral" type="sales" component={Collateral} />
          <PrivateRoute path="/support" type="support" component={Support} />
        </Switch>
      </div>
    </div>
  );
};

export default StudioCollateral;
