import { FC, useState } from 'react';

// Plugins
import { Swiper, SwiperSlide, SwiperClass } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Helpers
import imageScaling from '@/utils/imageScaling';

// Hooks
import { useKeyDown } from '@/hooks';

// Styles
import './style.css';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

interface LightboxProps {
  lightboxPhotos: { imageUrl: URL; imageName: string }[];
  onLightboxClose: () => void;
}

const Lightbox: FC<LightboxProps> = ({ lightboxPhotos, onLightboxClose }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass | null>(null);

  const handleSetSwiperThumbs = (swiper: SwiperClass): void => setThumbsSwiper(swiper);

  useKeyDown(() => {
    onLightboxClose();
  }, ['Escape']);

  return (
    <aside className="lightbox animate" onClick={onLightboxClose}>
      <button className="button button--clean lightbox__close" name="close" type="button" onClick={onLightboxClose}>
        <i className="icon-close-light"></i>
      </button>

      <div className="lightbox__box" onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
        <Swiper
          className="lightbox__swiper"
          navigation={true}
          thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
          modules={[FreeMode, Navigation, Thumbs]}
        >
          {lightboxPhotos?.map(({ imageUrl, imageName }: { imageUrl: URL; imageName: string }, index: number) => (
            <SwiperSlide key={index}>
              <LazyLoadImage
                className="lightbox__image"
                src={imageScaling({ url: imageUrl, size: 'xlarge' })}
                alt={imageName}
                effect="opacity"
                draggable="false"
              />
              <span className="lightbox__caption">{imageName}</span>
            </SwiperSlide>
          ))}
        </Swiper>
        {lightboxPhotos.length > 1 && (
          <Swiper
            className="lightbox__thumbnails"
            slidesPerView={10}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            onSwiper={handleSetSwiperThumbs}
          >
            {lightboxPhotos.map(({ imageUrl, imageName }: { imageUrl: URL; imageName: string }, index: number) => (
              <SwiperSlide key={index}>
                <LazyLoadImage
                  className="lightbox__image"
                  src={imageScaling({ url: imageUrl, size: 'small' })}
                  alt={imageName}
                  effect="opacity"
                  draggable="false"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </aside>
  );
};

export default Lightbox;
