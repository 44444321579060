import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
import { getWhccStudioInformation } from '../../actions';

// Plugins
import * as qs from 'qs';
import { ToastContainer } from 'react-toastify';

// Components
import Container from '@/components/SignUp/Shared/Container';

// Images
import whcc_logo from '@/assets/images/whcc_logo.png';

const getWhData = (props) => {
  return qs.parse(props.location.search)['?whData'];
};

const mapStateToProps = (state, ownProps) => {
  const whData = getWhData(ownProps);

  return {
    ...state.signUp,
    ...ownProps,
    whData
  };
};

const mapDispatchToProps = { getWhccStudioInformation };

const ConnectCallback = (props) => {
  const { location, lab, successful, getWhccStudioInformation, whData } = props;

  useEffect(() => {
    getWhccStudioInformation(whData);
  }, []);

  if (successful) {
    return <Redirect to={`/whcc-register${location.search}`} />;
  }

  return (
    <>
      <ToastContainer />
      <Container id="register" labImage={lab.image}>
        <div className="whcc-connect">
          <img src={whcc_logo} alt="WHCC Logo" />
          <br />
          {!!successful && <p>Loaded in WHCC account information...</p>}
          {!successful && <p>Loading in WHCC account information...</p>}
          <Link to={`/whcc-register${location.search}`}> Don't have a WHCC account? </Link>
        </div>
      </Container>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectCallback);
