import { call, put, takeLatest, all } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import errorHandler from '@/utils/errorResponseHandler';
import { GET_STATES_REQUEST } from './constants';
import * as api from '@/api/state-api';
import { getStatesListRequestSuccess, getStatesListRequestError } from './actions';

function* getStatesRequestFlow() {
  try {
    yield put(showLoading());

    const response = yield call(api.stateList);
    yield put(getStatesListRequestSuccess(response));
  } catch (error) {
    yield call(errorHandler, getStatesListRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getStatesWatcher() {
  yield all([takeLatest(GET_STATES_REQUEST, getStatesRequestFlow)]);
}

export default getStatesWatcher;
