import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import PrivateRoute from '@/components/Shared/PrivateRoute';

// Redux
import { connect } from 'react-redux';

// Components
import TopNav from '@/components/Shared/TopNav';

// Components
import ReportsSalesRoute from './Sales';
import ReportsOrdersRoute from './Orders';
import ReportsProductsRoute from './Products';

const mapStateToProps = (state) => {
  const {
    login: { user }
  } = state;
  const roles = (user && user.roles) || [];

  return { roles };
};

const Reports = ({ roles }) => (
  <>
    <TopNav />
    <Switch>
      {roles.includes('view_sales') ? <Redirect exact from="/reports" to="/reports/sales" /> : <Redirect exact from="/reports" to="/reports/orders" />}
      <PrivateRoute path="/reports/sales" component={ReportsSalesRoute} requiredPermission={'view_sales'} />
      <PrivateRoute path="/reports/orders" component={ReportsOrdersRoute} requiredPermission={'view_sales'} />
      <PrivateRoute path="/reports/products" component={ReportsProductsRoute} requiredPermission={'view_sales'} />
    </Switch>
  </>
);

export default connect(mapStateToProps)(Reports);
