import { FC } from 'react';
import { Switch, Route } from 'react-router-dom';

// Components
import PostProcessingList from './List';
import PostProcessingSettings from './Settings';
import PostProcessingReview from './Review';

// Styles
import './style.css';

const PostProcessing: FC = () => {
  return (
    <Switch>
      <Route exact path="/jobs/:jobId/services/postprocessing/:id/settings" component={PostProcessingSettings} />
      <Route exact path="/jobs/:jobId/services/postprocessing/:id/review" component={PostProcessingReview} />
      <Route component={PostProcessingList} />
    </Switch>
  );
};

export default PostProcessing;
