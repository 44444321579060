import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import { loadingBarReducer } from 'react-redux-loading-bar';

import home from '@/components/Home/reducer';
import jobs from '@/components/Jobs/reducer';
import users from '@/components/Settings/reducer';
import signUp from '@/components/SignUp/reducer';
import reports from '@/components/Reports/reducer';
import resources from '@/components/Resources/reducer';
import states from '@/components/Shared/StatesDDL/reducer';
import offers from '@/components/Storefront/Offers/reducer';
import orders from '@/components/Storefront/Orders/reducer';
import promos from '@/components/Storefront/Promos/reducer';
import { reducer as login } from '@/components/Login/reducer';
import offersDDL from '@/components/Shared/OffersDDL/reducer';
import organizations from '@/components/Organizations/reducer';
import shippings from '@/components/Storefront/Shipping/reducer';
import advancepay from '@/components/Storefront/AdvancePay/reducer';
import pricesheets from '@/components/Storefront/PriceSheets/reducer';
import address from '@/components/Shared/AddressVerification/reducer';
import credits from '@/components/Storefront/Orders/Credits/reducer';
import customThemes from '@/components/Storefront/CustomThemes/reducer';
import backgrounds from '@/components/Storefront/Backgrounds/reducer';
import productCategoriesDDL from '@/components/Shared/ProductCategoriesDDL/reducer';

const IndexReducer = combineReducers({
  home,
  jobs,
  login,
  users,
  offers,
  orders,
  promos,
  states,
  signUp,
  address,
  credits,
  reports,
  resources,
  offersDDL,
  shippings,
  advancepay,
  pricesheets,
  customThemes,
  backgrounds,
  organizations,
  productCategoriesDDL,
  form: reduxFormReducer,
  loadingBar: loadingBarReducer
});

export default IndexReducer;
