import * as types from './constants';

// Create Backgrounds Collection
export const createBackgroundsCollection = (payload, callback) => ({
  type: types.CREATE_BACKGROUNDS_COLLECTION_REQUEST,
  payload: { payload, callback }
});

export const createBackgroundsCollectionSuccess = (payload) => ({
  type: types.CREATE_BACKGROUNDS_COLLECTION_SUCCESS,
  payload: { payload }
});

export const createBackgroundsCollectionError = (error) => ({
  type: types.CREATE_BACKGROUNDS_COLLECTION_ERROR,
  payload: { error }
});

// Read Backgrounds Collection List
export const getBackgroundsCollectionList = (payload, callback) => ({
  type: types.GET_BACKGROUNDS_COLLECTION_LIST_REQUEST,
  payload: { payload, callback }
});

export const getBackgroundsCollectionListSuccess = (payload) => ({
  type: types.GET_BACKGROUNDS_COLLECTION_LIST_SUCCESS,
  payload: { payload }
});

export const getBackgroundsCollectionListError = (error) => ({
  type: types.GET_BACKGROUNDS_COLLECTION_LIST_ERROR,
  payload: { error }
});

// Read Backgrounds Collection
export const getBackgroundsCollection = (payload, callback) => ({
  type: types.GET_BACKGROUNDS_COLLECTION_REQUEST,
  payload: { payload, callback }
});

export const getBackgroundsCollectionSuccess = (payload) => ({
  type: types.GET_BACKGROUNDS_COLLECTION_SUCCESS,
  payload: { payload }
});

export const getBackgroundsCollectionError = (error) => ({
  type: types.GET_BACKGROUNDS_COLLECTION_ERROR,
  payload: { error }
});

// Update Backgrounds Collection
export const updateBackgroundsCollection = (payload, callback) => ({
  type: types.UPDATE_BACKGROUNDS_COLLECTION_REQUEST,
  payload: { payload, callback }
});

export const updateBackgroundsCollectionSuccess = (payload) => ({
  type: types.UPDATE_BACKGROUNDS_COLLECTION_SUCCESS,
  payload: { payload }
});

export const updateBackgroundsCollectionError = (error) => ({
  type: types.UPDATE_BACKGROUNDS_COLLECTION_ERROR,
  payload: { error }
});

// Delete Backgrounds Collection
export const deleteBackgroundsCollection = (payload, callback) => ({
  type: types.DELETE_BACKGROUNDS_COLLECTION_REQUEST,
  payload: { payload, callback }
});

export const deleteBackgroundsCollectionSuccess = (payload) => ({
  type: types.DELETE_BACKGROUNDS_COLLECTION_SUCCESS,
  payload: { payload }
});

export const deleteBackgroundsCollectionError = (error) => ({
  type: types.DELETE_BACKGROUNDS_COLLECTION_ERROR,
  payload: { error }
});

// Read Background
export const getBackground = (payload, callback) => ({
  type: types.GET_BACKGROUND_REQUEST,
  payload: { payload, callback }
});

export const getBackgroundSuccess = (payload) => ({
  type: types.GET_BACKGROUND_SUCCESS,
  payload: { payload }
});

export const getBackgroundError = (error) => ({
  type: types.GET_BACKGROUND_ERROR,
  payload: { error }
});

// Reset Background
export const resetBackground = (callback) => ({
  type: types.RESET_BACKGROUND_REQUEST,
  payload: { callback }
});

export const resetBackgroundSuccess = (payload) => ({
  type: types.RESET_BACKGROUND_SUCCESS,
  payload: { payload }
});
