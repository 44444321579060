import * as api from '@/utils/http';

export const getAcademyPlaylist = () => {
  const endpoint = `academy/playlists`;

  return api.get(endpoint);
};

export const getUpcomingWebinars = () => {
  const endpoint = `academy/upcoming-webinars`;

  return api.get(endpoint);
};
