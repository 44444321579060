import React, { useState } from 'react';
import Select from 'react-select';

export default function ReprintFullRequestModal({ onCancel, onSubmit, reprintReasonOptions }) {
  const [reprintReason, setReprintReason] = useState([]);
  const [extra_info, setExtraInfo] = useState('');

  const handleCancel = () => {
    onCancel();
  };

  const handleSubmit = () => {
    const requestInfo = {
      reason: reprintReason[0]?.value || '',
      extra_info: extra_info
    };

    onSubmit(requestInfo);
  };

  const handleInputOnChange = (event) => {
    setExtraInfo(event.target.value);
  };

  const handleInputOnKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleSelectChange = (selectedOption) => {
    setReprintReason([{ label: selectedOption.label, value: selectedOption.value }]);
  };

  const selectedReprintReason = reprintReason[0] || { label: '', value: '' };

  return (
    <aside className="modal text-left">
      <div className="modal__box">
        <header className="modal__header">
          <h2>Request Reprint</h2>
        </header>
        <main className="modal__content">
          <label>Please select the reason for your request:</label>
          <Select
            className="select mb-5"
            classNamePrefix="select"
            name="reprintReason"
            required={true}
            value={reprintReason}
            options={reprintReasonOptions}
            onChange={handleSelectChange}
          />
          {selectedReprintReason.value === 'incorrect_image' ? (
            <aside className="panel panel--error animate">
              <p className="m-0">
                Please note we cannot accommodate an automatic reprint that requires corrections to an image, crop, or customization. For these requests, please
                reach out to our support team via the Bubble or at <a href="mailto:support@photoday.io">support@photoday.io</a>.
              </p>
            </aside>
          ) : (
            <fieldset className="fieldset animate">
              <label>Enter any additional information:</label>
              <textarea
                className="input--block"
                type="text"
                name="additional-info"
                rows={6}
                maxLength={244}
                value={extra_info}
                onChange={handleInputOnChange}
              />
            </fieldset>
          )}
        </main>
        <footer className="modal__footer flex justify-end gap-2.5">
          <button className="button button--blue-outline" type="button" name="cancel" onClick={handleCancel}>
            Cancel
          </button>
          <button
            className="button"
            name="submit"
            type="button"
            onClick={handleSubmit}
            disabled={!selectedReprintReason || selectedReprintReason.value === 'incorrect_image'}
          >
            Submit
          </button>
        </footer>
      </div>
    </aside>
  );
}
