// Plugins
import axios from 'axios';

// Helpers
import * as api from '@/utils/http';

// Photos
export const createPhotoCheckDuplicate = (payload) => {
  const endpoint = `jobs/${payload.jobId}/photos/check-duplicates`;
  const args = { file_names: payload.fileNames };

  return api.post(endpoint, args);
};

export const createPhotoPreSign = (payload) => {
  const endpoint = `jobs/${payload.jobId}/photos/presign`;
  const args = { filename: payload.name, contentType: payload.type };

  return api.post(endpoint, args);
};

export const createUploadGroup = (payload) => {
  const endpoint = `jobs/${payload.jobId}/photos/create-upload-group`;

  return api.post(endpoint, {});
};

export const updatePhotoS3 = (payload) => {
  const endpoint = payload.url;

  return axios.put(endpoint, payload.file, { headers: { 'Content-Type': payload.type } });
};

export const createPhotoFile = (payload) => {
  const endpoint = `jobs/${payload.jobId}/photos/uploaded`;
  const args = {
    filename: payload.name,
    tags: payload.tags,
    photo_upload_group_id: payload.groupId,
    duplicate_strategy: payload.duplicateStrategy
  };

  return api.post(endpoint, args);
};

export const getPhotoList = (payload) => {
  const endpoint = `jobs/${payload.id}/photos`;
  const args = { ...payload };

  return api.get(endpoint, args);
};

export const updatePhotos = (payload) => {
  const endpoint = `jobs/${payload.id}/photos/update`;
  const args = { ...payload };

  return api.post(endpoint, args);
};

export const getPhoto = (payload) => {
  const endpoint = `photos/${payload.id}`;
  const args = { ...payload };

  return api.get(endpoint, args);
};

export const updatePhoto = (payload) => {
  const endpoint = `jobs/${payload.id}`;
  const args = { ...payload };

  return api.put(endpoint, args);
};

export const deletePhoto = (payload) => {
  const endpoint = `jobs/${payload.id}/photos/delete`;
  const args = { ...payload };

  return api.del(endpoint, args);
};

// Tags
export const createTag = (payload) => {
  const endpoint = `jobs/${payload.id}/tags`;
  const args = [payload.tag];

  return api.post(endpoint, args);
};

export const createTagAssociation = (payload) => {
  const endpoint = `jobs/${payload.id}/photos/tag`;
  const args = { ...payload };

  return api.post(endpoint, args);
};

export const getTagList = (payload) => {
  const endpoint = `jobs/${payload.id}/tags`;

  return api.get(endpoint);
};

export const renameTag = (payload) => {
  const endpoint = `jobs/${payload.id}/tags/rename`;
  const args = { old: payload.tag.old, new: payload.tag.new };

  return api.post(endpoint, args);
};

export const sortTag = (payload) => {
  const endpoint = `jobs/${payload.id}/tags/sort`;
  const args = { sort: payload.sort };

  return api.post(endpoint, args);
};

export const deleteTag = (payload) => {
  const endpoint = `jobs/${payload.id}/tags`;
  const args = [payload.tag];

  return api.del(endpoint, args);
};

export const deleteTagAssociation = (payload) => {
  const endpoint = `jobs/${payload.id}/photos/tag`;
  const args = { ...payload };

  return api.del(endpoint, args);
};

// Photo Type Options
export const getPhotoTypeOptions = (payload) => {
  const endpoint = `jobs/${payload.jobId}/primary-photo-options`;
  const args = { ...payload };

  return api.get(endpoint, args);
};
