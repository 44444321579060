import { useState } from 'react';

//Helpers
import moment from 'moment';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Redux
import { useSelector } from 'react-redux';

// Components
import VideoPlayer from '@/components/Shared/VideoPlayer';
import GridLoader from '@/components/Shared/ContentLoader/GridLoader';

const Webinars = function Live() {
  const {
    academy: { webinars }
  } = useSelector((state) => state.home);

  const videos = webinars.videos;

  const [showPlayer, setShowPlayer] = useState(false);
  const [showUrl, setShowUrl] = useState('');

  const handlePlayerToggle = (url) => {
    if (!showPlayer) {
      setShowUrl(url);
      setShowPlayer(true);
    } else {
      setShowUrl('');
      setShowPlayer(false);
    }
  };

  return (
    <>
      <header className="flex items-center animate academy-container__header">
        <h2 className="text-headline-sm">Webinars</h2>
      </header>
      {videos.length > 0 ? (
        <section className="animate academy__events-grid">
          {videos
            .sort((a, b) => new Date(b.published_at) - new Date(a.published_at))
            .map((video) => (
              <figure key={video.id} className="flex items-center flex-col academy__event">
                <LazyLoadImage className="academy__event-image" src={video.thumbnail_url} alt={video.title} draggable={false} effect="opacity" />
                <figcaption className="flex flex-col justify-between">
                  <header>
                    <small className="font-bold academy__event-title">{`Released on ${moment(video.published_at).format('M.D.YY')}`}</small>
                    <p className="font-bold summary__event-subtitle">{video.title}</p>
                    <div className="academy__event-description">
                      {video.description.split('\n').map((line, i) => (
                        <p key={i}>{line}</p>
                      ))}
                    </div>
                  </header>
                  <button
                    className="button button--outline button--block"
                    type="button"
                    disabled={video.upcoming}
                    onClick={() => handlePlayerToggle(video.url)}
                  >
                    {video.upcoming ? 'Register' : 'Watch Now'}
                  </button>
                </figcaption>
              </figure>
            ))}
        </section>
      ) : (
        <GridLoader rows={4} columns={3} gap={20} minHeight={450} />
      )}

      <VideoPlayer className="academy__video-player" url={showUrl} showPlayer={showPlayer} onPlayerToggle={handlePlayerToggle} />
    </>
  );
};

export default Webinars;
