import * as types from './constants';

export const orderListRequest = ({ studioId, page, perPage, order, dir, search }) => ({
  type: types.ORDER_LIST_REQUEST,
  payload: {
    studioId,
    page,
    perPage,
    order,
    dir,
    search
  }
});

export const orderListRequestSuccess = ({ entities, pager, result }) => ({
  type: types.ORDER_LIST_SUCCESS,
  payload: {
    entities,
    pager,
    result
  }
});

export const orderListRequestError = (error) => ({
  type: types.ORDER_LIST_ERROR,
  payload: {
    error
  }
});

export const orderDetailRequest = (payload, callback) => ({
  type: types.ORDER_DETAIL_REQUEST,
  payload: { payload, callback }
});

export const orderDetailRequestSuccess = (result) => ({
  type: types.ORDER_DETAIL_LIST_SUCCESS,
  payload: {
    result
  }
});

export const orderDetailRequestError = (error) => ({
  type: types.ORDER_DETAIL_LIST_ERROR,
  payload: {
    error
  }
});

export const setOrderNameSearch = ({ studioId, search, page, perPage, order, dir }) => ({
  type: types.SET_ORDER_NAME_SEARCH,
  payload: {
    studioId,
    search,
    page,
    perPage,
    order,
    dir
  }
});

export const createOrderResendReceipt = (payload, callback) => ({
  type: types.CREATE_ORDER_RESEND_RECEIPT_REQUEST,
  payload: { payload, callback }
});

export const createOrderResendReceiptSuccess = (payload) => ({
  type: types.CREATE_ORDER_RESEND_RECEIPT_SUCCESS,
  payload: { payload }
});

export const createOrderResendReceiptError = (error) => ({
  type: types.CREATE_ORDER_RESEND_RECEIPT_ERROR,
  payload: { error }
});

export const createOrderReprint = (payload, callback) => ({
  type: types.CREATE_ORDER_REPRINT_REQUEST,
  payload: { payload, callback }
});

export const createOrderReprintSuccess = (payload) => ({
  type: types.CREATE_ORDER_REPRINT_SUCCESS,
  payload: { payload }
});

export const createOrderReprintError = (error) => ({
  type: types.CREATE_ORDER_REPRINT_ERROR,
  payload: { error }
});

export const getOrderReprints = (payload, callback) => ({
  type: types.GET_ORDER_REPRINTS_REQUEST,
  payload: { payload, callback }
});

export const getOrderReprintsSuccess = (payload) => ({
  type: types.GET_ORDER_REPRINTS_SUCCESS,
  payload: { payload }
});

export const getOrderReprintsError = (error) => ({
  type: types.GET_ORDER_REPRINTS_ERROR,
  payload: { error }
});

// Read retouch photos in order
export const getRetouchPhotos = (payload, callback) => ({
  type: types.GET_RETOUCH_PHOTOS_REQUEST,
  payload: { payload, callback }
});

export const getRetouchPhotosSuccess = (payload) => ({
  type: types.GET_RETOUCH_PHOTOS_SUCCESS,
  payload: { payload }
});

export const getRetouchPhotosError = (error) => ({
  type: types.GET_RETOUCH_PHOTOS_ERROR,
  payload: { error }
});
