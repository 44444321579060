import { GET_OFFERS_DDL_REQUEST, GET_OFFERS_DDL_SUCCESS, GET_OFFERS_DDL_ERROR } from './constants';

export const getOffersDDLRequest = (studioId) => ({
  type: GET_OFFERS_DDL_REQUEST,
  payload: {
    studioId
  }
});

export const getOffersDDLRequestSuccess = (options) => ({
  type: GET_OFFERS_DDL_SUCCESS,
  payload: {
    options
  }
});

export const getOffersDDLRequestError = (error) => ({
  type: GET_OFFERS_DDL_ERROR,
  payload: {
    error
  }
});
