import { FC, useState, useEffect } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { getPeopleFieldOptions, getPeopleList, getPhotoTypeOptions } from '../../../../actions';

// Plugins
import CreatableSelect from 'react-select/creatable';
import Select, { SingleValue, MultiValue } from 'react-select';

// Types
import { SelectOptionType, Export, ExportType, Subject } from '@/types';

// Styles
import './style.css';

const ALLOW_CUSTOM_EXPORT_FILENAMES = import.meta.env.VITE_ALLOW_CUSTOM_EXPORT_FILENAMES === '1';

// Types
interface ExportPhotosProps {
  jobExport: Export;
  onExportStepModalClose: () => void;
  onExportStepComplete: (newValues: Export, step: string) => void;
}
interface OptionType extends SelectOptionType {
  custom?: boolean;
}

const separatorOptions = [
  { label: 'None', value: '' },
  { label: 'Hyphen', value: '-' },
  { label: 'Underscore', value: '_' }
];

const ExportPhotos: FC<ExportPhotosProps> = ({ jobExport, onExportStepModalClose, onExportStepComplete }) => {
  const dispatch = useDispatch();

  const {
    job,
    photoTypeOptions,
    export: { filename_format: exportFilenameFormat },
    people: { fields: peopleFields, list: peopleList }
  } = useSelector((state: any) => state.jobs);

  // Remove yearbook type options if export not yearbook
  const filteredPhotoTypeOptions =
    jobExport.export_type === ExportType.Yearbook
      ? photoTypeOptions
      : photoTypeOptions.filter(({ value: optionValue }: SelectOptionType) => !optionValue?.includes(ExportType.Yearbook));

  const [filterValue, setFilterValue] = useState<string>('');

  const [selectExportPhotos, setSelectExportPhotos] = useState<SingleValue<OptionType>>(filteredPhotoTypeOptions[0]);

  const [toggleCustomization, setToggleCustomization] = useState<boolean>(false);

  const [selectedValues, setSelectedValues] = useState<MultiValue<OptionType>>([]);
  const [filenameOptions, setFilenameOptions] = useState<any>([]);
  const [selectedSeparator, setSelectedSeparator] = useState<SingleValue<OptionType>>(null);

  const [inputError, setInputError] = useState<boolean>(false);
  const [missingFieldError, setMissingFieldError] = useState<boolean>(false);
  const [subjectError, setSubjectError] = useState<boolean>(false);
  const [customFilenameInput, setCustomFilenameInput] = useState<string>('');
  const [exampleString, setExampleString] = useState<string>('');

  const handleExportSelectChange = (value: SingleValue<SelectOptionType>) => {
    setFilterValue('');
    setSelectExportPhotos(value);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setFilterValue(value);
  };

  const handleSubmit = () => {
    const newFilenameFormat: string[] = [];

    if (toggleCustomization) {
      selectedValues.forEach((selectedValue: any, index) => {
        newFilenameFormat.push(selectedValue.value);

        if (index !== selectedValues.length - 1) {
          newFilenameFormat.push(selectedSeparator?.value || '');
        }
      });
    } else {
      newFilenameFormat.push('{{image_filename}}');
    }

    const newValues: Export = {
      export_preference: selectExportPhotos?.value as any,
      ...(newFilenameFormat.length > 0 ? { filename_format: newFilenameFormat } : {})
    };

    if (selectExportPhotos?.value === 'filename_filter' && filterValue) {
      newValues.photo_filename_filter = filterValue;
    }

    onExportStepComplete(newValues, 'photos');
  };

  const handleSwitchChange = () => setToggleCustomization(!toggleCustomization);

  const createFilenameOptions = () => {
    if (Object.keys(peopleFields).length > 0) {
      const allOptions = [
        { value: '{{image_filename}}', label: 'original filename' },
        ...Object.keys(peopleFields)
          .filter((key) => key !== 'absent' && key !== 'added_by')
          .map((key) => ({ value: `{{${key}}}`, label: key, custom: false }))
      ];

      const filteredOptions = allOptions
        .filter((option) => !selectedValues?.some((selectedValue) => selectedValue.value === option.value))
        .map((option) => ({ value: option.value, label: option.label.replace('session_start', 'check-in_date').replace('_', ' '), custom: false }));

      setFilenameOptions(filteredOptions);
    }
  };

  const handleFilenameInputChange = (input: string) => {
    if (input.length < 30) {
      setCustomFilenameInput(input);
      setInputError(false);
    }
  };

  const handleCreateOption = (newOption: string) => {
    const normalizedOption = newOption.trim();
    const invalidChars = /[^0-9a-zA-Z _-]+/.test(newOption);

    if (invalidChars) {
      setInputError(true);
      setCustomFilenameInput(newOption);
    } else {
      const newValue = { value: normalizedOption, label: normalizedOption, custom: true };

      setSelectedValues([...(selectedValues ? selectedValues : []), newValue]);
    }
  };

  const handleFilenameValueChange = (option: any) => {
    // existing types unavailable for CreatableSelect
    setSelectedValues([...(selectedValues ? selectedValues : []), option]);
  };

  const handleSelectSeparator = (separator: SingleValue<SelectOptionType>) => setSelectedSeparator(separator);

  const handleRemoveSelection = (option: OptionType) => setSelectedValues(selectedValues.filter((current) => current !== option));

  const initializeFilenameFormat = () => {
    let separator = { label: 'None', value: '' };
    let currentFilenameFormat = exportFilenameFormat;

    if (exportFilenameFormat.length > 1) {
      if (exportFilenameFormat[1] === '_') separator = { label: 'Underscore', value: '_' };
      if (exportFilenameFormat[1] === '-') separator = { label: 'Hyphen', value: '-' };

      currentFilenameFormat = exportFilenameFormat.filter((value: string) => value !== separator.value);
    }

    if (currentFilenameFormat.length > 0) {
      if ((currentFilenameFormat.length === 1 && currentFilenameFormat[0] !== '{{image_filename}}') || currentFilenameFormat.length > 1) {
        setToggleCustomization(true);
      }
    }

    const newSelectedValues = currentFilenameFormat.map((key: string) => {
      const friendlyLabel = key === '{{image_filename}}' ? 'original filename' : key.replace('{{', '').replace('}}', '').replace('_', ' ');
      const isCustom =
        key === '{{image_filename}}' || Object.prototype.hasOwnProperty.call(peopleFields, key.replace('{{', '').replace('}}', '')) ? false : true;

      return {
        value: key,
        label: friendlyLabel,
        custom: isCustom
      };
    });

    setSelectedSeparator(separator);
    setSelectedValues(newSelectedValues);
  };

  const createExampleString = () => {
    const exampleSubject = peopleList.find((subject: Subject) => subject.photos_count > 0) || null;
    const exampleFields = selectedValues.map((field) => ({ value: field.value, custom: field.custom }));
    const exampleValues: string[] = [];

    let subjectMissingValue = false;

    if (exampleSubject) {
      exampleFields.forEach((field: any) => {
        if (field.custom) {
          exampleValues.push(field.value);
        } else {
          if (field.value === '{{image_filename}}') {
            exampleValues.push('imageFilename');
          } else {
            const normalizedField = field.value.replace('{{', '').replace('}}', '');
            const exampleValue = exampleSubject[normalizedField] !== null ? `${exampleSubject[normalizedField]}` : '';

            exampleValues.push(exampleValue);
            subjectMissingValue = exampleValue === '';
          }
        }
      });

      const exampleValuesString = exampleValues.filter((value: string) => value).join(selectedSeparator?.value || '');

      setExampleString(exampleValuesString);
      setMissingFieldError(subjectMissingValue);
      setSubjectError(false);
    } else {
      setSubjectError(true);
    }
  };

  useEffect(() => {
    if (jobExport?.export_preference) {
      const foundExportPhotosOption = photoTypeOptions.find((option: SelectOptionType) => option.value === jobExport.export_preference);
      const filenameFilter = jobExport.photo_filename_filter;

      if (foundExportPhotosOption) {
        setSelectExportPhotos(foundExportPhotosOption);
      }

      if (foundExportPhotosOption?.value === 'filename_filter' && filenameFilter) {
        setFilterValue(filenameFilter);
      }
    }
  }, [jobExport]);

  useEffect(() => {
    createFilenameOptions();

    if (peopleList.length > 0) {
      createExampleString();
    }
  }, [selectedValues, selectedSeparator, peopleFields]);

  useEffect(() => {
    if (peopleFields && peopleList) {
      initializeFilenameFormat();
    }
  }, [peopleFields, peopleList]);

  useEffect(() => {
    dispatch(getPeopleFieldOptions({ id: job.id }));
    dispatch(getPeopleList({ id: job.id, per_page: 10000 }));
    dispatch(getPhotoTypeOptions({ jobId: job.id }));
  }, []);

  return (
    <aside className="modal job-exports-photos animate">
      <div className={`modal__box modal__box--dark modal__box--xsmall ${ALLOW_CUSTOM_EXPORT_FILENAMES ? 'job-exports-photos__box--tall' : ''}`}>
        <header className="modal__header">
          <h3>Select Primary Photo</h3>
          <button className="button button--action modal__close" name="button" type="button" onClick={onExportStepModalClose}>
            <i className="icon-close"></i>
          </button>
        </header>
        <main className="modal__content">
          <p className="text-body-sm">Select which photos you want to use as primary photos for this export.</p>
          <Select
            className="select animate"
            classNamePrefix="select"
            value={selectExportPhotos}
            options={filteredPhotoTypeOptions}
            onChange={handleExportSelectChange}
          />
          {selectExportPhotos?.value === 'filename_filter' && (
            <input className="animate input--block" type="text" value={filterValue} autoFocus={true} onChange={handleInputChange} />
          )}
          {ALLOW_CUSTOM_EXPORT_FILENAMES && (
            <>
              <div className="job-exports-photos__customization-switch">
                <h6 className="m-0">Customize File Name</h6>
                <input
                  id="filenameSwitch"
                  className="hidden"
                  name="filenameSwitch"
                  type="checkbox"
                  onChange={handleSwitchChange}
                  checked={toggleCustomization}
                />
                <label className="label-switch label-switch--small" htmlFor="filenameSwitch" />
              </div>
              <p className="text-body-sm">
                You can customize filenames by selecting up to three available values below. Please select values that will result in unique filenames.
              </p>
              {toggleCustomization && (
                <div>
                  <CreatableSelect
                    className="select select--capitalize job-exports-photos__select mb-2.5"
                    classNamePrefix="select"
                    placeholder="Add or create custom value and hit enter"
                    options={filenameOptions}
                    value={[]}
                    inputValue={customFilenameInput}
                    onInputChange={handleFilenameInputChange}
                    onCreateOption={handleCreateOption}
                    onChange={handleFilenameValueChange}
                    isDisabled={selectedValues.length === 3}
                  />
                  <Select
                    className="select animate mb-1"
                    classNamePrefix="select"
                    value={selectedSeparator}
                    options={separatorOptions}
                    onChange={handleSelectSeparator}
                    placeholder="Select Separator"
                  />

                  {missingFieldError && <p className="text-error-500 text-body-xs">The example subject is missing one or more fields you have selected.</p>}
                  {inputError && <p className="text-error-500 text-body-xs">No special characters, letters and numbers only.</p>}
                  {subjectError && <p className="text-error-500 text-body-xs">There are no photos for any of your subjects.</p>}

                  <div className="job-exports-photos__value-cloud">
                    {selectedValues.length > 0 &&
                      selectedValues.map((value, i) => (
                        <small className="pill pill--tag" key={i}>
                          <div className="job-exports-photos__value-label capitalize">
                            {typeof value.label === 'string' ? value.label?.replace('session_start', 'check-in_date').replace('_', ' ') : ''}
                          </div>
                          <button className="job-exports-photos__value-remove" type="button" onClick={() => handleRemoveSelection(value)}>
                            <i className="icon-close"></i>
                          </button>
                        </small>
                      ))}
                  </div>
                  {selectedValues.length > 0 && !subjectError && (
                    <p className="text-body-sm">
                      Example: <br /> {exampleString}.jpg
                    </p>
                  )}
                </div>
              )}
            </>
          )}
        </main>
        <footer className="modal__footer modal__footer--fixed">
          <button
            className="button button--center button--large"
            name="submit"
            type="button"
            onClick={handleSubmit}
            disabled={selectExportPhotos === null || (toggleCustomization && selectedValues.length === 0)}
          >
            Submit
          </button>
          <hr />
          <p className="m-0">
            <small>
              Need help? Check out our{' '}
              <a
                href="https://support.photoday.io/en/articles/3379907-what-types-of-data-exports-are-available-to-me"
                target="_blank"
                rel="noopener noreferrer"
              >
                support article on export files
              </a>
              .
            </small>
          </p>
        </footer>
      </div>
    </aside>
  );
};

export default ExportPhotos;
