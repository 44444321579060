import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutRequest } from '@/components/Login/actions';

const mapStateToProps = (state) => {
  const {
    login: { user, showExpiredSessionModal }
  } = state;
  const showModal = !user?.roles || showExpiredSessionModal;

  return { showModal };
};

const mapDispatchToProps = { logoutRequest };

const ExpiredSessionModal = ({ showModal, logoutRequest }) => {
  const close = (evt) => {
    evt.preventDefault();
    logoutRequest();
  };

  if (showModal) {
    return (
      <aside className="modal z-[10000] animate">
        <div className="modal__box modal__box--small">
          <header className="modal__header">
            <button className="button button--action modal__close" name="button" type="button" onClick={close}>
              <i className="icon-close"></i>
            </button>
            <h4 className="center-header-text">Your Session has Expired!</h4>
          </header>
          <main className="modal__content">
            <p>Your session has expired. By clicking on the close button you will be redirected to the login page.</p>
          </main>
          <footer className="text-center modal__footer">
            <button onClick={close} type="button" className="button">
              Close
            </button>
          </footer>
        </div>
      </aside>
    );
  }

  return null;
};

ExpiredSessionModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  logoutRequest: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpiredSessionModal);
