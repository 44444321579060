import * as types from './constants';

export const createGiftCardResendReceipt = (payload, callback) => ({
  type: types.CREATE_GIFT_CARD_RESEND_RECEIPT_REQUEST,
  payload: { payload, callback }
});

export const createGiftCardResendReceiptSuccess = (payload) => ({
  type: types.CREATE_GIFT_CARD_RESEND_RECEIPT_SUCCESS,
  payload: { payload }
});

export const createGiftCardResendReceiptError = (error) => ({
  type: types.CREATE_GIFT_CARD_RESEND_RECEIPT_ERROR,
  payload: { error }
});

export const getGiftCardTransactionsList = (payload, callback) => ({
  type: types.GET_GIFT_CARD_TRANSACTIONS_LIST_REQUEST,
  payload: { payload, callback }
});

export const getGiftCardTransactionsListSuccess = (payload) => ({
  type: types.GET_GIFT_CARD_TRANSACTIONS_LIST_SUCCESS,
  payload: { payload }
});

export const getGiftCardTransactionsListError = (error) => ({
  type: types.GET_GIFT_CARD_TRANSACTIONS_LIST_ERROR,
  payload: { error }
});

export const giftCardDetailRequest = (giftCardId) => ({
  type: types.GIFT_CARD_DETAIL_REQUEST,
  payload: { giftCardId }
});

export const giftCardDetailRequestSuccess = (result) => ({
  type: types.GIFT_CARD_DETAIL_SUCCESS,
  payload: {
    result
  }
});

export const giftCardDetailRequestError = (error) => ({
  type: types.GIFT_CARD_DETAIL_ERROR,
  payload: {
    error
  }
});
