import React from 'react';
import { shape, string, bool, number, arrayOf, object } from 'prop-types';

// Plugins
import moment from 'moment';
import { convertToText } from '@/utils/currency';

// Redux
import { useDispatch } from 'react-redux';
import { getStudioPromotion } from '../actions';

// Components
import Pagination from '@/components/Shared/Pagination';

PromosPast.propTypes = {
  promos: shape({
    list: arrayOf(
      shape({
        id: string,
        studio_id: string,
        promotion_id: string,
        audience: string,
        is_enabled: bool,
        title: string,
        views_count: number,
        orders_count: number,
        sales_cents: number,
        job_ids: arrayOf(string),
        offer: object,
        promotion: object
      })
    ).isRequired,
    pagination: object
  })
};

function PromosPast({ collapse, section, promos }) {
  const dispatch = useDispatch();

  const handlePagination = (page) => {
    dispatch(getStudioPromotion({ archived: true, page }));
  };

  return (
    <section
      className={`basis-9/12 md:basis-full promos-section ${section === 'Past' ? 'promos-section--active' : ''} ${collapse ? 'promos-section--expand' : ''}`}
    >
      <header className="flex items-center justify-between promos-section__header">
        <h2 className="text-headline-sm">History</h2>
      </header>

      <article className="table-box">
        <table className="table">
          <thead className="table__header">
            <tr>
              <th>Promo Name</th>
              <th>Date</th>
              <th>Offer</th>
              <th>Viewed</th>
              <th>Used</th>
              <th>Sales</th>
            </tr>
          </thead>
          <tbody className="table__body">
            {promos?.list?.length > 0 ? (
              <>
                {promos.list.map((promo) => (
                  <tr key={promo.id}>
                    <td data-header="Promo Name">{promo.promotion?.name}</td>
                    <td data-header="Date">{`${moment(promo.promotion?.start_at).format('L')} - ${moment(promo.promotion?.end_at).format('L')}`}</td>
                    <td data-header="Offer">{promo.offer?.description}</td>
                    <td data-header="Viewed">{promo.views_count}</td>
                    <td data-header="Used">{promo.orders_count}</td>
                    <td data-header="Sales">{convertToText(promo.sales_cents, '$')}</td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td>You don't have any past Promos.</td>
              </tr>
            )}
          </tbody>
        </table>
      </article>
      {promos?.pagination?.total > promos?.pagination?.perPage && (
        <Pagination pagination={promos?.pagination} onPagination={handlePagination} showPagesCount={4} />
      )}
    </section>
  );
}

export default PromosPast;
