// Helpers
import * as api from '@/utils/http';

export const createJobExport = (payload) => {
  const endpoint = `jobs/${payload.jobId}/exports`;
  const args = { ...payload };

  return api.post(endpoint, args);
};

export const createJobExportPrepare = (payload) => {
  const endpoint = `exports/${payload.exportId}/prepare`;

  return api.post(endpoint);
};

export const createJobExportReady = (payload) => {
  const endpoint = `exports/${payload.exportId}/ready`;

  return api.post(endpoint);
};

export const createJobExportAssignSubjects = (payload) => {
  const endpoint = `exports/${payload.exportId}/assign-subjects`;
  const args = { ...payload };

  return api.post(endpoint, args);
};

export const getJobExportList = (payload) => {
  const endpoint = `jobs/${payload.jobId}/exports`;
  const args = { ...payload };

  return api.get(endpoint, args);
};

export const getJobExport = (payload) => {
  const endpoint = `exports/${payload.exportId}`;

  return api.get(endpoint);
};

export const getJobExportItems = (payload) => {
  const endpoint = `exports/${payload.exportId}/export-items`;

  return api.get(endpoint);
};

export const getJobExportPeopleFieldOptions = (payload) => {
  const endpoint = `exports/${payload.id}/field-options`;
  const args = { ...payload };

  return api.get(endpoint, args);
};

export const updateJobExport = (payload) => {
  const endpoint = `exports/${payload.exportId}`;
  const args = { ...payload };

  return api.put(endpoint, args);
};

export const updateJobExportItem = (payload) => {
  const endpoint = `export-items/${payload.exportId}`;
  const args = { ...payload };

  return api.put(endpoint, args);
};

export const deleteJobExport = (payload) => {
  const endpoint = `jobs/${payload.jobId}/exports/${payload.exportId}`;

  return api.del(endpoint);
};

export const deleteJobExportItem = (payload) => {
  const endpoint = `export-items/${payload.id}`;

  return api.del(endpoint, {});
};
