import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'redux-form';

const NestedField = ({ name, component, label, moreLabel = '+ Add more', maxCount = Infinity }) => {
  const renderNested = ({ fields, meta: { error, submitFailed } }) => {
    const ComponentItem = component;

    if (fields.length === 0) fields.push({});

    return (
      <div>
        <div className="flex">
          {fields.map((fieldName, index) => (
            <ComponentItem
              {...{ name, component, label, moreLabel, maxCount }}
              key={`${name}-${fieldName}`}
              name={fieldName}
              index={index}
              onRemove={() => fields.remove(index)}
            />
          ))}
        </div>
        {fields.length < maxCount && (
          <button type="button" onClick={() => fields.push({})} className="notifications-icon button--link text-left">
            {moreLabel}
          </button>
        )}
        {submitFailed && error && <span>{error}</span>}
      </div>
    );
  };

  return (
    <div className="nested-container">
      {label && <label>{label}</label>}
      <FieldArray name={name} component={renderNested} />
    </div>
  );
};

NestedField.propTypes = {
  name: PropTypes.string.isRequired,
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  label: PropTypes.string,
  moreLabel: PropTypes.string,
  maxCount: PropTypes.number
};

export default NestedField;
