export const isLocalStorageAvailable = () => {
  const testItem = 'available';

  try {
    window.localStorage.setItem(testItem, testItem);
    window.localStorage.removeItem(testItem);

    return true;
  } catch (error) {
    console.warn('This app will not work with your current browser settings');

    return false;
  }
};

export const set = (key, value, isLocal = true) => {
  try {
    if (isLocal) {
      window.localStorage.setItem(key, value);
    } else {
      window.sessionStorage.setItem(key, value);
    }
  } catch (error) {
    console.warn('This app will not work with your current browser settings');

    return null;
  }
};

export const get = (key) => {
  try {
    return window.localStorage.getItem(key) || window.sessionStorage.getItem(key);
  } catch (error) {
    console.warn('This app will not work with your current browser settings');

    return null;
  }
};

export const remove = (key, isLocal = true) => {
  try {
    if (isLocal) {
      window.localStorage.removeItem(key);
    } else {
      window.sessionStorage.removeItem(key);
    }
  } catch (error) {
    console.warn('This app will not work with your current browser settings');

    return null;
  }
};

export const clear = (isLocal = true) => {
  try {
    if (isLocal) {
      window.localStorage.clear();
    } else {
      window.sessionStorage.clear();
    }
  } catch (error) {
    console.warn('This app will not work with your current browser settings');

    return null;
  }
};

export const clearAll = () => {
  try {
    window.sessionStorage.clear();
    window.localStorage.clear();
  } catch (error) {
    console.warn('This app will not work with your current browser settings');

    return null;
  }
};
