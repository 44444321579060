import * as types from './constants';
import { handleErrorState, handleRequestingState } from '@/utils/reducerHelpers';

// Types
import { Organization } from '@/types';

const HEADER_PAGE = 'x-page';
const HEADER_PER_PAGE = 'x-per-page';
const HEADER_TOTAL = 'x-total';

const initialState = {
  organizations: [],
  organization: {},
  pagination: { page: 1, perPage: 15, total: 0 },

  errors: [],
  requesting: false,
  successful: false
};

const handleCreateOrganizationSuccess = (state: any, { payload }: any) => {
  const data: Organization = payload.data;
  const { organizations: stateOrganizations } = state;

  return {
    ...state,
    organizations: [data, ...stateOrganizations],
    organization: payload.data,

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetOrganizationListSuccess = (state: any, { payload }: any) => {
  const data: Organization[] = payload.data;

  return {
    ...state,
    organizations: data,
    pagination: {
      page: Number(payload.headers[HEADER_PAGE]),
      perPage: Number(payload.headers[HEADER_PER_PAGE]),
      total: Number(payload.headers[HEADER_TOTAL])
    },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetOrganizationSuccess = (state: any, { payload }: any) => {
  const data: Organization = payload.data;

  return {
    ...state,
    organization: data,

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleUpdateOrganizationSuccess = (state: any, { payload }: any) => {
  const data: Organization = payload.data;
  const { organizations: stateOrganizations } = state;

  const updatedOrganizations = stateOrganizations.map((organization: Organization) => (organization.id === data.id ? data : organization));

  return {
    ...state,
    organizations: updatedOrganizations,
    organization: data,

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleDeleteOrganizationSuccess = (state: any, { payload }: any) => {
  const { organizationId: payloadOrganizationId } = payload;
  const { organizations: stateOrganizations } = state;

  const updatedOrganizations = stateOrganizations.filter((organization: Organization) => organization.id !== payloadOrganizationId);

  return {
    ...state,
    organizations: updatedOrganizations,
    organization: {},

    errors: [],
    successful: true,
    requesting: false
  };
};

const reducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case types.CREATE_ORGANIZATION_REQUEST:
    case types.GET_ORGANIZATION_LIST_REQUEST:
    case types.GET_ORGANIZATION_REQUEST:
    case types.UPDATE_ORGANIZATION_REQUEST:
    case types.DELETE_ORGANIZATION_REQUEST:
      return handleRequestingState(state);

    case types.CREATE_ORGANIZATION_SUCCESS:
      return handleCreateOrganizationSuccess(state, payload);
    case types.GET_ORGANIZATION_LIST_SUCCESS:
      return handleGetOrganizationListSuccess(state, payload);
    case types.GET_ORGANIZATION_SUCCESS:
      return handleGetOrganizationSuccess(state, payload);
    case types.UPDATE_ORGANIZATION_SUCCESS:
      return handleUpdateOrganizationSuccess(state, payload);
    case types.DELETE_ORGANIZATION_SUCCESS:
      return handleDeleteOrganizationSuccess(state, payload);

    case types.CREATE_ORGANIZATION_ERROR:
    case types.GET_ORGANIZATION_LIST_ERROR:
    case types.GET_ORGANIZATION_ERROR:
    case types.UPDATE_ORGANIZATION_ERROR:
    case types.DELETE_ORGANIZATION_ERROR:
      return handleErrorState(state, payload);

    default:
      return state;
  }
};

export default reducer;
