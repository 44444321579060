import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { formatCurrency } from '@/utils/displayFormats';

const OrderItem = ({ item }) => {
  const { id, num, credit, total_with_credit, advance_pay } = item;

  const orderAmount = `$${formatCurrency(total_with_credit)}`;

  return (
    <tr>
      <td>
        <Link className="text--normalized" to={advance_pay ? `/storefront/orders/advance-pay/credits/${id}` : `/storefront/orders/${id}`}>
          <b>{num}</b>
        </Link>
      </td>
      <td className="text-right">
        {orderAmount}
        {credit > 0 ? '*' : ''}
      </td>
    </tr>
  );
};

OrderItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    num: PropTypes.string.isRequired,
    credit: PropTypes.number.isRequired,
    advance_pay: PropTypes.bool.isRequired,
    total_with_credit: PropTypes.number.isRequired
  })
};

export default OrderItem;
