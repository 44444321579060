import { schema } from 'normalizr';
const orderEntity = new schema.Entity('order');
const job = new schema.Entity('job');

const order = new schema.Entity('orders', {
  order: orderEntity,
  job: job
});

const orderSchema = [order];

export default orderSchema;
