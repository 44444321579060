import { useState, FC, MouseEvent } from 'react';

interface ChecklistAccordionProps {
  title: string;
  children: React.ReactNode;
}

const ChecklistAccordion: FC<ChecklistAccordionProps> = ({ title, children }) => {
  const [accordionExpanded, setAccordionExpanded] = useState(false);

  const handleAccordionToggle = (event: MouseEvent) => {
    event.stopPropagation();
    setAccordionExpanded((prevAccordionExpanded) => !prevAccordionExpanded);
  };

  return (
    <section className={`panel panel-accordion ${accordionExpanded ? '' : 'panel-accordion--contracted'} panel-accordion--group`}>
      <header className="panel-accordion__header" onClick={handleAccordionToggle}>
        {title}
      </header>
      <main className="panel-accordion__content">{children}</main>
    </section>
  );
};

export default ChecklistAccordion;
