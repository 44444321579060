const CREDIT_VALUE_CENTS = 10;

export const getWalletCreditValue = (studioCreditCents: number = 0): number => {
  // Normalize cents into dollars
  const creditDollar = Number(CREDIT_VALUE_CENTS / 100) ?? 0;
  const studioCreditsDollar = Number(studioCreditCents / 100) ?? 0;

  // Calculate credit(s) value
  const creditValue = Number(studioCreditsDollar / creditDollar) ?? 0;
  const creditValueRounded = Math.round(creditValue * 100) / 100;

  return creditValueRounded;
};
