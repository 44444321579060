import { PureComponent } from 'react';
import { Draggable } from 'react-beautiful-dnd';

// Components
import ProfitCalculator from './ProfitCalculator';

// Helpers
import PropTypes from 'prop-types';
import { errorToast } from '@/utils/toast';
import { NumericFormat } from 'react-number-format';
import imageScaling from '@/utils/imageScaling';
import { convertToCents, convertToText } from '@/utils/currency';
import { getProductCostByShippingType } from '@/utils/productCostByShippingType';

// Images
import icon_duplicate from '@/assets/images/icon-duplicate.png';
import sampleRetouchImage from '@/assets/images/retouch-basic.jpg';

// Styles
import './pricesheet-detail.css';
import { DIGITAL_PRICE_MINIMUM } from './constants';

const VITE_BULK_SHIPPING_ENABLED = import.meta.env.VITE_BULK_SHIPPING_ENABLED === '1';

class PriceSheetItemRow extends PureComponent {
  state = {
    baseCostCents: this.props.priceSheetItem.base_cost_cents,
    baseBulkCostCents: this.props.priceSheetItem.base_bulk_cost_cents,
    markupCents: this.props.priceSheetItem.markup_cents,
    priceCents: this.props.priceSheetItem.price_cents,
    photodayFeeCents: this.props.priceSheetItem.photoday_fee_cents,
    initialPriceCents: this.props.priceSheetItem.price_cents,
    formattedPriceCents: this.props.priceSheetItem.price_cents,
    labPriceCents: this.props.priceSheetItem.lab_price_cents,
    photodayPriceCents: this.props.priceSheetItem.photoday_price_cents,
    hasDigitalDownloads: this.props.priceSheetItem.has_digital_downloads,
    isPackage: this.props.priceSheetItem.price_sheet_item_type === 'package',
    digitalProductsCount: this.props.digitalProductsCount || 0
  };

  static propTypes = {
    priceSheetItem: PropTypes.shape({
      id: PropTypes.string.isRequired,
      image_url: PropTypes.string,
      name: PropTypes.string.isRequired,
      price_sheet_item_type: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      featured_at: PropTypes.string,
      lab_price_cents: PropTypes.number,
      photoday_price_cents: PropTypes.number.isRequired,
      base_cost_cents: PropTypes.number.isRequired,
      base_bulk_cost_cents: PropTypes.number,
      markup_cents: PropTypes.number.isRequired,
      price_cents: PropTypes.number.isRequired,
      photoday_fee_cents: PropTypes.number.isRequired
    }).isRequired,
    index: PropTypes.number.isRequired,
    selectItems: PropTypes.array,
    onEdit: PropTypes.func,
    onSelectItem: PropTypes.func,
    onCopy: PropTypes.func,
    prefix: PropTypes.string,
    shippingType: PropTypes.string,
    pdFee: PropTypes.number
  };

  static defaultProps = {
    prefix: '$',
    pdFee: 0
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.priceSheetItem !== this.props.priceSheetItem && !this.props.disabled) {
      const { base_cost_cents, base_bulk_cost_cents, markup_cents, price_cents, photoday_fee_cents, has_digital_downloads, price_sheet_item_purchasables } =
        this.props.priceSheetItem;

      let digitalProductsCount = 0;

      if (has_digital_downloads) {
        digitalProductsCount = price_sheet_item_purchasables.filter(({ product }) => product.is_digital_download);
      }

      this.setState({
        baseCostCents: base_cost_cents,
        baseBulkCostCents: base_bulk_cost_cents,
        markupCents: markup_cents,
        priceCents: price_cents,
        photodayFeeCents: photoday_fee_cents,
        digitalProductsCount
      });
    }
  };

  storePrice = () => {
    if (this.isPriceLessThanCost()) {
      return false;
    }

    const {
      priceSheetItem: { id, price_cents }
    } = this.props;
    const { markupCents, priceCents, photodayFeeCents } = this.state;

    this.setState({ priceCents: priceCents });

    if (priceCents && price_cents !== priceCents && priceCents - (markupCents - photodayFeeCents) >= 0) {
      this.normalizePrice();
      this.props.onUpdate({
        id: id,
        price_cents: priceCents,
        markup_cents: markupCents,
        photoday_fee_cents: photodayFeeCents
      });
    }
  };

  isPriceLessThanCost = () => {
    const { baseCostCents, baseBulkCostCents, markupCents, initialPriceCents, digitalProductsCount } = this.state;
    const { pdFee, shippingType } = this.props;
    const errors = this.validationErrors();

    if (errors.length > 0) {
      const calc = new ProfitCalculator({
        digitalProductsCount: digitalProductsCount,
        baseCostCents: getProductCostByShippingType(baseCostCents, baseBulkCostCents, shippingType),
        markupCents: markupCents,
        priceCents: initialPriceCents,
        pdFee: pdFee
      });

      calc.calculate();
      const price = convertToText(calc.priceCents, this.props.prefix);

      this.setState({
        markupCents: calc.markupCents,
        priceCents: calc.priceCents,
        photodayFeeCents: calc.photodayFeeCents,
        minimumPriceCents: calc.minimumPriceCents,
        formattedPrice: price
      });
      errorToast(errors[0]);
      return true;
    }

    return false;
  };

  onKeyDown = (e) => {
    const errors = this.validationErrors();

    if (e.key !== 'Enter') return;

    if (errors.length === 0) {
      this.storePrice();
    } else {
      errorToast(errors[0]);
    }
  };

  validationErrors = () => {
    const { markupCents, priceCents, hasDigitalDownloads, isPackage, minimumPriceCents } = this.state;

    let errors = [];

    if (priceCents < minimumPriceCents) {
      errors.push(`Minimum retail price must be ${convertToText(minimumPriceCents, '$')} or higher.`);
    }

    if (hasDigitalDownloads && !isPackage && markupCents < DIGITAL_PRICE_MINIMUM) {
      errors.push('The retail price must be at least $5.00.');
    }

    return errors;
  };

  onPriceChange = (input) => {
    const {
      priceSheetItem: { base_cost_cents, base_bulk_cost_cents },
      pdFee,
      shippingType
    } = this.props;

    const price = convertToCents(input.value);
    const calc = new ProfitCalculator({
      digitalProductsCount: this.state.digitalProductsCount,
      baseCostCents: getProductCostByShippingType(base_cost_cents, base_bulk_cost_cents, shippingType),
      priceCents: price,
      pdFee: pdFee
    });

    calc.calculate();

    this.setState({
      markupCents: calc.markupCents,
      priceCents: calc.priceCents,
      photodayFeeCents: calc.photodayFeeCents,
      formattedPrice: input.formattedValue,
      minimumPriceCents: calc.minimumPriceCents
    });
  };

  normalizePrice = () => {
    const price = convertToText(this.state.priceCents, this.props.prefix);

    this.setState({ formattedPrice: price });
  };

  render() {
    const { priceSheetItem, onEdit, onSelectItem, onCopy, prefix, disabled, showQuantity, duplicating, selectItems, index, shippingType } = this.props;
    const {
      price_sheet_item_purchasables,
      image_url,
      image_filename,
      has_digital_downloads: isDigitalDownload,
      digital_bundle: isDigitalBundle
    } = priceSheetItem;

    const baseCostValue = convertToText(this.state.baseCostCents, prefix);
    const markupValue = convertToText(this.state.markupCents, prefix);
    const priceValue = convertToText(this.state.priceCents, prefix);
    const photodayFeeValue = convertToText(this.state.photodayFeeCents, prefix);

    const quantity = price_sheet_item_purchasables ? price_sheet_item_purchasables[0].quantity : 1;
    const products = price_sheet_item_purchasables
      ? price_sheet_item_purchasables.map((price_sheet_item_purchasable) => price_sheet_item_purchasable.product)
      : [];

    const imageUrl = image_url === 'sampleImage' ? sampleRetouchImage : imageScaling({ url: image_url, size: 'small' });

    return (
      <Draggable draggableId={priceSheetItem.id} key={priceSheetItem.id} index={index}>
        {(provided, snapshot) => (
          <tr
            className={`storefront__pricesheets__item ${snapshot.isDragging ? 'storefront__pricesheets__item--drag' : ''} ${
              snapshot.isDragging && !snapshot.draggingOver ? 'storefront__pricesheets__item--drag-out' : ''
            }`}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {priceSheetItem.price_sheet_item_type === 'product' || priceSheetItem.price_sheet_item_type === 'add-on' ? (
              <>
                <td>
                  <input
                    id={priceSheetItem.id}
                    className="hidden"
                    type="checkbox"
                    name={priceSheetItem.first_name}
                    checked={selectItems && selectItems.length && selectItems.some((select) => select === priceSheetItem.id)}
                    onChange={(e) => onSelectItem(e, priceSheetItem.id)}
                  />
                  <label htmlFor={priceSheetItem.id} className="label--checkbox label--clean" />
                </td>
                <td onClick={onEdit}>
                  <div className="product-image-container">
                    <img src={imageUrl} alt={image_filename} width="100" />
                  </div>
                </td>
                <td data-header="Name" onClick={onEdit}>
                  {priceSheetItem.name}
                </td>

                {showQuantity && <td data-header="Qty">{quantity}</td>}

                <td data-header="Unit Cost">
                  <div className="flex flex-col">
                    {(shippingType !== 'bulk_shipping' || priceSheetItem.base_bulk_cost_cents === priceSheetItem.base_cost_cents) && baseCostValue}
                    {VITE_BULK_SHIPPING_ENABLED &&
                      shippingType !== 'drop_shipping' &&
                      priceSheetItem.base_bulk_cost_cents > 0 &&
                      priceSheetItem.base_bulk_cost_cents !== priceSheetItem.base_cost_cents && (
                        <span>
                          {convertToText(priceSheetItem.base_bulk_cost_cents, this.props.prefix)} {shippingType === 'drop_or_bulk_shipping' && <>Bulk*</>}
                        </span>
                      )}
                  </div>
                </td>

                <td className={this.state.markupCents <= 0 ? 'text-error-500' : ''} data-header="Markup">
                  {markupValue}
                </td>
                <td data-header="Retail Price">
                  {isDigitalBundle ? (
                    <span>{priceValue}</span>
                  ) : (
                    <NumericFormat
                      prefix={'$'}
                      value={priceValue}
                      className="input--block input--clean"
                      allowNegative={false}
                      decimalScale={2}
                      onValueChange={this.onPriceChange}
                      disabled={disabled}
                      onKeyDown={this.onKeyDown}
                      onBlur={this.storePrice}
                    />
                  )}
                </td>
                <td data-header="PhotoDay Fee">{photodayFeeValue}</td>
                {isDigitalDownload && (
                  <td data-header="Bundle">
                    {isDigitalBundle ? (priceSheetItem?.digital_bundle_pricing_type === 'digitals_tiered_pricing' ? 'Tiered' : 'Max') : 'N/A'}
                  </td>
                )}
                <td data-header="Description" onClick={onEdit}>
                  {priceSheetItem.description}
                </td>
                <td className="text-center" data-header="Feature">
                  {priceSheetItem.featured_at ? (
                    <button
                      className="button button--clean"
                      name="star"
                      type="button"
                      onClick={() => this.props.onProductUnfavorite(priceSheetItem.id)}
                      disabled={disabled}
                    >
                      <i className="icon-star" />
                    </button>
                  ) : (
                    <button
                      className="button button--link"
                      name="star"
                      type="button"
                      onClick={() => this.props.onProductFavorite(priceSheetItem.id)}
                      disabled={disabled}
                    >
                      <i className="icon-star icon-star--empty" />
                    </button>
                  )}
                </td>
              </>
            ) : (
              <>
                <td>
                  <input
                    id={priceSheetItem.id}
                    className="hidden"
                    type="checkbox"
                    name={priceSheetItem.first_name}
                    checked={selectItems && selectItems.length && selectItems.some((select) => select === priceSheetItem.id)}
                    onChange={(e) => onSelectItem(e, priceSheetItem.id)}
                  />
                  <label htmlFor={priceSheetItem.id} className="label--checkbox label--clean" />
                </td>
                <td colSpan="2" data-header="Name" onClick={onEdit}>
                  {priceSheetItem.name}
                </td>
                <td data-header="Unit Cost">
                  <div className="flex flex-col">
                    {(shippingType !== 'bulk_shipping' || priceSheetItem.base_bulk_cost_cents === priceSheetItem.base_cost_cents) && baseCostValue}
                    {VITE_BULK_SHIPPING_ENABLED &&
                      shippingType !== 'drop_shipping' &&
                      products.some((product) => product.bulk_price_cents > 0 && product.bulk_price_cents !== product.price_cents) && (
                        <span>
                          {convertToText(priceSheetItem.base_bulk_cost_cents, this.props.prefix)} {shippingType === 'drop_or_bulk_shipping' && <>Bulk*</>}
                        </span>
                      )}
                  </div>
                </td>
                <td className={this.state.markupCents <= 0 ? 'text-error-500' : ''} data-header="Markup">
                  {markupValue}
                </td>
                <td data-header="Retail Price">
                  <NumericFormat
                    prefix={'$'}
                    value={priceValue}
                    className="input--block"
                    allowNegative={false}
                    decimalScale={2}
                    onValueChange={this.onPriceChange}
                    disabled={disabled}
                    onKeyDown={this.onKeyDown}
                    onBlur={this.storePrice}
                  />
                </td>
                <td data-header="PhotoDay Fee">{photodayFeeValue}</td>
                <td onClick={onEdit} data-header="Description">
                  {priceSheetItem.description}
                </td>
                <td className="text-center" data-header="Actions">
                  {!duplicating && (
                    <img
                      src={icon_duplicate}
                      className={`pricesheet-table-actions ${disabled ? 'disabled' : ''}`}
                      alt=""
                      onClick={disabled ? undefined : onCopy}
                    />
                  )}
                  {duplicating && <span className="loader" />}
                </td>
              </>
            )}
          </tr>
        )}
      </Draggable>
    );
  }
}

export default PriceSheetItemRow;
