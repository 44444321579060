import { Switch } from '@/components/Shared/Forms';

const FreeShippingForm = ({ offer }) => {
  return (
    <div className="form-segment">
      <Switch
        name="sw2"
        label="Add Free Shipping"
        note="You will be responsible for shipping fees if you toggle this on."
        checked={offer.shipping_type === 'free'}
      />
    </div>
  );
};

export default FreeShippingForm;
