import { useCallback, useState } from 'react';

// Helpers
import { successToast } from '@/utils/toast';

// Types
type CopiedValue = string | null;
type CopyFn = (text: string) => Promise<boolean>;

export function useCopyToClipboard(): [CopiedValue, CopyFn] {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null);

  const copyToClipboard: CopyFn = useCallback(async (text) => {
    if (!navigator?.clipboard) {
      console.warn('Clipboard not supported');
      return false;
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      successToast(`${text} has been successfully copied!`);

      return true;
    } catch (error) {
      console.warn('Copy failed', error);
      setCopiedText(null);

      return false;
    }
  }, []);

  return [copiedText, copyToClipboard];
}
