import { useMemo } from 'react';

import icon_tip from './icon_tip_lightbulb.png';

import './Tip.css';

const Tip = ({ type, list, text, children }) => {
  const renderContent = useMemo(() => {
    switch (type) {
      case 'list':
        return (
          <ul className="tip-text">
            {list.map((item, key) => (
              <li key={key}>{item}</li>
            ))}
          </ul>
        );
      case 'text':
        return <p className="tip-text">{text}</p>;
      default:
        return <p className="tip-text">{children}</p>;
    }
  }, [type, list, text, children]);

  return (
    <div className="tip-container tip-align-top">
      <span className="badge-tip">
        <img src={icon_tip} alt="Tip Icon" />
      </span>
      {renderContent}
    </div>
  );
};

export default Tip;
