import { FC, FormEvent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { createStudioUserAcceptInvitation, getStudioUserInvitationDetails } from '../Settings/actions';

// Plugins
import queryString from 'query-string';
import { ToastContainer } from 'react-toastify';

// Images
import imagePhotodayLogo from '@/assets/images/image_PD_logo.svg';
import imageAppStore from '@/assets/images/app-store-button.png';
import imageGoogleStore from '@/assets/images/google-play-button.png';

// Styles
import './style.css';

const Capture: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { requesting } = useSelector((state: any) => state.users);

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [registerSuccess, setRegisterSuccess] = useState<boolean>(false);
  const [inviterEmail, setInviterEmail] = useState<string>('');

  const { token } = queryString.parse(location.search);

  const handlePasswordInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleToggleShowPassowrd = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    dispatch(
      createStudioUserAcceptInvitation(
        {
          id: token,
          password: password
        },
        () => setRegisterSuccess(true)
      )
    );
  };

  useEffect(() => {
    dispatch(
      getStudioUserInvitationDetails({ id: token }, ({ data }: { data: { invited_email: string; inviter_email: string } }) => {
        setEmail(data?.invited_email);
        setInviterEmail(data?.inviter_email);
      })
    );
  }, []);

  return (
    <>
      <ToastContainer />

      <section className="onboarding-bg capture">
        <img src={imagePhotodayLogo} className="gray-logo" alt="Logo" />

        <main className="container-fluid flex-page-centered">
          <div className="white-container basis-9/12">
            <figure className="capture__message">
              {!registerSuccess ? (
                <>
                  <h1 className="text-white-base">Welcome to PhotoDay</h1>
                  <p className="text-white-base">Please enter a password to create a PhotoDay Account.</p>
                </>
              ) : (
                <>
                  <h1 className="text-white-base">All Done!</h1>
                  <p className="text-white-base">
                    Hooray! Your account has been created.{' '}
                    <span role="img" aria-label="celebrate">
                      &#127881;
                    </span>
                  </p>
                  <p className="text-white-base">Welcome to PhotoDay!</p>
                </>
              )}
            </figure>

            <article className="capture__container">
              {!registerSuccess ? (
                <form onSubmit={handleSubmit} className="capture-form">
                  <div>
                    <label className="capture-form__label">Email</label>
                    <div className={requesting ? 'input--block-loading' : ''}>
                      <input className="capture-form__input" type="email" name="email" value={email} autoComplete="username" disabled />
                    </div>
                  </div>
                  <div>
                    <label className="capture-form__label">Create a password</label>
                    <input
                      className="capture-form__input"
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      value={password}
                      onChange={handlePasswordInputChange}
                      autoComplete="current-password"
                      placeholder="••••••"
                    />
                  </div>
                  <div className="capture-form__checkbox">
                    <label>
                      <input
                        name="showPassword"
                        className="capture-form__input--checkbox"
                        checked={showPassword}
                        onChange={handleToggleShowPassowrd}
                        type="checkbox"
                      />{' '}
                      Show Password
                    </label>
                  </div>
                  <button type="submit" className="button capture-form__button" disabled={!email || !password || requesting} data-loading={requesting}>
                    Create PhotoDay Account
                  </button>
                  <p className="capture-form__terms">
                    By creating an account with PhotoDay, you agree to our <a href="https://photoday.io/terms">Terms of Service</a> and{' '}
                    <a href="https://photoday.io/brand_guidelines/">Brand Guidelines</a>.
                  </p>
                </form>
              ) : (
                <aside className="capture-success">
                  <h1 className="text-neutral-500">What's Next: </h1>
                  <p>Download the Capture App to get started!</p>
                  <div className="capture__buttons">
                    <a href="https://apps.apple.com/us/app/photoday-capture/id1370434730" target="_blank" rel="noopener noreferrer">
                      <img src={imageAppStore} alt="App store link" width="120" />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=io.photoday.capture" target="_blank" rel="noopener noreferrer">
                      <img src={imageGoogleStore} alt="Google store link" width="120" />
                    </a>
                  </div>
                  <div className="panel">
                    <p>
                      <i className="icon-key-vertical" /> If you have studio permissions, log in <a href="https://studio.photoday.io">here</a>.
                    </p>
                    <p>
                      <i className="icon-book" /> Not sure what permissions are? Check out this{' '}
                      <a
                        href="https://support.photoday.io/en/articles/5025769-how-can-i-manage-my-user-s-permissions"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        article.
                      </a>
                    </p>
                    <p>
                      <i className="icon-question" /> Need help or have questions? Contact your <a href={`mailto: ${inviterEmail}`}>studio admin</a> for
                      assisstance.
                    </p>
                    <p>
                      <i className="icon-support" /> Need to report an error? Reach out to <a href="mailto: support@photoday.io">PhotoDay support</a>.
                    </p>
                  </div>
                  <p className="capture__bottom-message">
                    <a href={`mailto: ${inviterEmail}`}>I didn't ask to be invited</a>
                  </p>
                </aside>
              )}
            </article>
          </div>
        </main>
      </section>
    </>
  );
};

export default Capture;
