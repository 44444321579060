import { useState } from 'react';

// Plugins
import Select from 'react-select';

// Components
import DownloadFileLink from '../DownloadFileLink';

// Images
import videoPoster1 from '@/assets/images/collateral/sales-kit/video-poster-1.jpg';
import videoPoster2 from '@/assets/images/collateral/sales-kit/video-poster-2.jpg';
import videoPoster3 from '@/assets/images/collateral/sales-kit/video-poster-3.jpg';

import sheetSports from '@/assets/images/collateral/sales-kit/sell-sheet-sports.jpg';
import sheetUnderclass from '@/assets/images/collateral/sales-kit/sell-sheet-underclass.jpg';
import sheetPreschool from '@/assets/images/collateral/sales-kit/sell-sheet-preschool.jpg';
import sheetDance from '@/assets/images/collateral/sales-kit/sell-sheet-dance.jpg';
import sheetGeneral from '@/assets/images/collateral/sales-kit/sell-sheet-general.jpg';

import elevatorPitchCard from '@/assets/images/collateral/sales-kit/elevator-pitch-card.jpg';

const mediaOptions = [
  { label: 'Sell Sheets', value: 'sheets' },
  { label: 'Elevator Pitch Card', value: 'cards' }
];

const kitData = {
  videos: [
    {
      name: 'A New Picture Day Video',
      description:
        "Make it your own by adding your studio's logo and contact information, or recording your own intro and outro. Share video with a live audience, include in an email, or post on social media to get organizations and parents excited about PhotoDay.",
      url: 'https://media.photoday.io/videos/a_new_picture_day.mp4',
      poster: videoPoster1
    },
    {
      name: 'Powered by PhotoDay Video',
      description:
        "Make it your own by adding your studio's logo and contact information, or recording your own intro and outro. Share video with a live audience, include in an email, or post on social media.",
      url: 'https://media.photoday.io/videos/powered_by_photoday.mp4',
      poster: videoPoster2
    },
    {
      name: 'Ordering Photos on PhotoDay',
      description:
        'A step-by-step, comprehensive how-to video that breaks down the photo ordering process in PhotoDay. Perfect to send to organizations or your customers!',
      url: 'https://media.photoday.io/videos/Ordering_Photos_How_To.mp4',
      poster: videoPoster3
    }
  ],
  media: {
    sheets: [
      {
        name: 'PhotoDay Sell Sheet - Sports',
        pdf: 'https://media.photoday.io/saleskit/sports_sellsheet.pdf',
        psdt: 'https://media.photoday.io/saleskit/sports_sellsheet.psdt',
        image: sheetSports
      },
      {
        name: 'PhotoDay Sell Sheet - Underclass',
        pdf: 'https://media.photoday.io/saleskit/school_sellsheet.pdf',
        psdt: 'https://media.photoday.io/saleskit/school_sellsheet.psdt',
        image: sheetUnderclass
      },
      {
        name: 'PhotoDay Sell Sheet - Pre-School',
        pdf: 'https://media.photoday.io/saleskit/preschool_sellsheet.pdf',
        psdt: 'https://media.photoday.io/saleskit/preschool_sellsheet.psdt',
        image: sheetPreschool
      },
      {
        name: 'PhotoDay Sell Sheet - Dance',
        pdf: 'https://media.photoday.io/saleskit/dance_sellsheet.pdf',
        psdt: 'https://media.photoday.io/saleskit/dance_sellsheet.psdt',
        image: sheetDance
      },
      {
        name: 'PhotoDay Sell Sheet - General',
        pdf: 'https://media.photoday.io/saleskit/general_sellsheet.pdf',
        psdt: 'https://media.photoday.io/saleskit/general_sellsheet.psdt',
        image: sheetGeneral
      }
    ],
    cards: [
      {
        name: 'Elevator Pitch Card',
        description:
          "Have a chance meeting with a prospective organization? Don't be caught off-guard - sell your studio using PhotoDay with a simple flick of a business card!",
        pdf: 'https://media.photoday.io/saleskit/pitch-card-4x3.pdf',
        psdt: 'https://media.photoday.io/saleskit/pitch-card-4x3.psdt',
        image: elevatorPitchCard
      }
    ]
  }
};

const Sales = () => {
  const [activeSection, setActiveSection] = useState('videos');
  const [activeOption, setActiveOption] = useState(mediaOptions[0]);

  const handleMediaOptionChange = (option) => {
    setActiveOption(option);
  };

  return (
    <>
      <header className="flex items-center resources-container__header">
        <h2 className="text-headline-sm">Sales Kit</h2>
      </header>
      <nav className="flex gap-2.5 mt-2.5">
        <button className={`button ${activeSection === 'videos' ? '' : 'button--lean'}`} name="videos" type="button" onClick={() => setActiveSection('videos')}>
          Videos
        </button>
        <button className={`button ${activeSection === 'media' ? '' : 'button--lean'}`} name="media" type="button" onClick={() => setActiveSection('media')}>
          Print & Media
        </button>
      </nav>
      {activeSection === 'media' && (
        <Select className="select mt-7" classNamePrefix="select" value={activeOption} options={mediaOptions} onChange={handleMediaOptionChange} />
      )}
      <section className={`mt-7 mb-7 animate ${activeSection === 'videos' ? '' : 'hidden'}`}>
        <ul className="grid grid-cols-2 sm:grid-rows-1 gap-5">
          {kitData.videos?.map((item, index) => (
            <li className="resources-container__item" key={index}>
              <div className="video-info">
                <video className="sales-video" controls width="603" height="339" poster={item.poster}>
                  <source src={item.url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              <DownloadFileLink title={item.name} description={item.description} videoUrl={item.url} />
            </li>
          ))}
        </ul>
      </section>
      <section className={`mt-7 mb-7 animate ${activeSection === 'media' ? '' : 'hidden'}`}>
        {activeOption.value === 'sheets' ? (
          <>
            <p className="mb-2">
              Give a quick rundown of how your studio using PhotoDay is the best choice for your organization. Insert your own branding and imagery to make it
              truly yours!
            </p>
            <ul className="grid grid-cols-3 sm:grid-rows-1 gap-2.5">
              {kitData.media?.sheets?.map((item, index) => (
                <li className="resources-container__item" key={index}>
                  <div className="sheet-image">
                    <img src={item.image} alt={item.name} />
                  </div>
                  <DownloadFileLink title={item.name} filename={item.pdf} psdtFilename={item.psdt} height={80} />
                </li>
              ))}
            </ul>
          </>
        ) : (
          <ul className="grid grid-cols-2 sm:grid-rows-1 gap-5">
            {kitData.media?.cards?.map((item, index) => (
              <li className="resources-container__item" key={index}>
                <div className="example-image">
                  <img src={item.image} alt={item.name} />
                </div>
                <DownloadFileLink title={item.name} description={item.description} filename={item.pdf} psdtFilename={item.psdt} />
              </li>
            ))}
          </ul>
        )}
      </section>
    </>
  );
};

export default Sales;
