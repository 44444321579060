import * as api from '@/utils/http';
import setPagingSortFilter from '@/utils/setPagingSortFilter';

export const offerList = (page, perPage = 15, search = null, order = null, dir = null, filter = null, searchField = null, full = false) => {
  const endpoint = `offers`;
  const args = setPagingSortFilter(page, perPage, search, order, dir, filter, searchField);

  return api.get(endpoint, { ...args, full }).then((res) => {
    const { data, headers } = res;
    const pager = api.getPaginationParams(headers);

    return { data, pager };
  });
};

export const creditSheetList = (page, perPage = 15, search = null, order = null, dir = null) => {
  const endpoint = `gift-card-sheets`;
  const args = setPagingSortFilter(page, perPage, search, order, dir);

  return api.get(endpoint, args).then((res) => {
    const { data, headers } = res;
    const pager = api.getPaginationParams(headers);

    return { data, pager };
  });
};

export const offersDDL = () => {
  const endpoint = `offers`;

  return api.get(endpoint, { per_page: 10000, order: 'name', dir: 'ASC' });
};

export const generateCode = ({ studioId }) => {
  const endpoint = `studios/${studioId}/generate-offer-code`;

  return api.post(endpoint);
};

export const createOffer = ({ studioId, offer }) => {
  const endpoint = `studios/${studioId}/offers`;

  return api.post(endpoint, offer);
};

export const updateOffer = ({ offer }) => {
  const endpoint = `offers/${offer.id}`;

  return api.put(endpoint, offer);
};

export const deleteOffer = ({ offerId }) => {
  const endpoint = `offers/${offerId}`;

  return api.del(endpoint);
};

export const createCreditSheetOffer = ({ studioId, offer }) => {
  const endpoint = `studios/${studioId}/gift-card-sheets`;

  return api.post(endpoint, offer);
};

export const updateCreditSheetOffer = ({ offer }) => {
  const endpoint = `/gift-card-sheets/${offer.id}`;

  return api.put(endpoint, offer);
};

export const deleteCreditSheetOffer = ({ offerId }) => {
  const endpoint = `/gift-card-sheets/${offerId}`;

  return api.del(endpoint);
};

export const pauseOffer = () => ({});
export const unpauseOffer = () => ({});
