export const CREATE_ORGANIZATION_REQUEST: any = 'CREATE_ORGANIZATION_REQUEST';
export const CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS';
export const CREATE_ORGANIZATION_ERROR = 'CREATE_ORGANIZATION_ERROR';

export const GET_ORGANIZATION_LIST_REQUEST: any = 'GET_ORGANIZATION_LIST_REQUEST';
export const GET_ORGANIZATION_LIST_SUCCESS = 'GET_ORGANIZATION_LIST_SUCCESS';
export const GET_ORGANIZATION_LIST_ERROR = 'GET_ORGANIZATION_LIST_ERROR';

export const GET_ORGANIZATION_REQUEST: any = 'GET_ORGANIZATION_REQUEST';
export const GET_ORGANIZATION_SUCCESS = 'GET_ORGANIZATION_SUCCESS';
export const GET_ORGANIZATION_ERROR = 'GET_ORGANIZATION_ERROR';

export const UPDATE_ORGANIZATION_REQUEST: any = 'UPDATE_ORGANIZATION_REQUEST';
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS';
export const UPDATE_ORGANIZATION_ERROR = 'UPDATE_ORGANIZATION_ERROR';

export const DELETE_ORGANIZATION_REQUEST: any = 'DELETE_ORGANIZATION_REQUEST';
export const DELETE_ORGANIZATION_SUCCESS = 'DELETE_ORGANIZATION_SUCCESS';
export const DELETE_ORGANIZATION_ERROR = 'DELETE_ORGANIZATION_ERROR';
