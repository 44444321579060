import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import PhotoDayGrid from '@/components/Shared/PhotodayGrid';
import OfferRow from './OfferRow';
import CreateOrEditOfferModal from './CreateOrEditOfferModal';
import DeleteOfferModal from './DeleteOfferModal';

import { getPricesheetListRequest } from '../PriceSheets/actions';
import { offerListRequest, destroyOfferRequest, createOfferRequest, updateOfferRequest } from './actions';
import { pricesheetsToOptions, getOfferType } from './selectors';

import SafeLink from '@/components/Shared/Link';

import './offers.css';

const mapStateToProps = (state, ownProps) => {
  const {
    login: {
      user,
      studio: { id: studioId }
    },
    users: { studio }
  } = state;

  const roles = (user && user.roles) || [];
  const offerType = getOfferType(ownProps);
  const pricesheets = pricesheetsToOptions(state);
  const lab = { id: studio.lab_id, name: studio.lab_name };

  return {
    studioId,
    lab,
    roles,
    pricesheets,
    offerType,
    ...state.offers
  };
};

const mapDispatchToProps = {
  offerListRequest,
  getPricesheetListRequest,
  destroyOfferRequest,
  createOfferRequest,
  updateOfferRequest
};

const Offers = (props) => {
  const [activeOffer, setActiveOffer] = useState(null);
  const [showOfferCreateOrEditModal, setShowOfferCreateOrEditModal] = useState(false);
  const [showOfferRemoveModal, setShowOfferRemoveModal] = useState(false);

  const { offerListRequest, getPricesheetListRequest, offerType, studioId, result, roles, entities, pager, pricesheets, requesting } = props;

  useEffect(() => {
    getPricesheetListRequest({
      page: 1,
      perPage: 1000,
      order: 'name',
      dir: 'ASC'
    });
    offerListRequest({
      page: 1,
      perPage: 15,
      order: 'created_at',
      dir: 'DESC',
      search: offerType === 'discounts' ? 'fixed_amount|fixed_percentage' : offerType,
      searchField: 'offer_type'
    });
  }, [offerListRequest, getPricesheetListRequest, offerType]);

  const fetchOffersPage = (pager) => {
    offerListRequest(
      {
        ...pager,
        search: offerType === 'discounts' ? 'fixed_amount|fixed_percentage' : offerType,
        searchField: 'offer_type'
      },
      (payload) => {
        const { pager, result } = payload;
        const { page, totalPages } = pager;

        if (!result.length && page > 1) {
          offerListRequest({
            ...pager,
            page: Math.min(page - 1, totalPages),
            search: offerType === 'discounts' ? 'fixed_amount|fixed_percentage' : offerType,
            searchField: 'offer_type'
          });
        }
      }
    );
  };

  const handleOfferCreateOrEditDialogSave = (offer) => {
    const { studioId, lab, pager } = props;
    const { createOfferRequest, updateOfferRequest } = props;

    if (offer.id) {
      updateOfferRequest({ offer }, () => {
        handleOfferDialogsCancel();
        fetchOffersPage(pager);
      });
    } else {
      createOfferRequest({ studioId, offer: { ...offer, lab_id: lab.id } }, () => {
        handleOfferDialogsCancel();
        fetchOffersPage(pager);
      });
    }
  };

  const handleOfferDialogsCancel = () => {
    setActiveOffer(null);
    setShowOfferCreateOrEditModal(false);
    setShowOfferRemoveModal(false);
  };

  const handleDeleteOfferDialogConfirm = (offer) => {
    const { destroyOfferRequest, pager } = props;

    destroyOfferRequest({ offerId: offer.id }, () => {
      handleOfferDialogsCancel();
      fetchOffersPage(pager);
    });
  };

  const handleAddOfferClick = () => {
    setActiveOffer({});
    setShowOfferCreateOrEditModal(true);
  };

  const handleOfferGridRemoveAction = (offer) => {
    setActiveOffer(Object.assign({}, offer));
    setShowOfferRemoveModal(true);
  };

  const handleOfferGridSettingsAction = (offer) => {
    setActiveOffer(Object.assign({}, offer));
    setShowOfferCreateOrEditModal(true);
  };

  // Permissions
  const canManageOffers = roles.includes('manage_offers');

  const gridHeader = [
    {
      fieldName: 'name',
      displayName: 'Name',
      sortable: true
    },
    {
      fieldName: 'code',
      displayName: 'Code',
      sortable: true
    },
    {
      fieldName: 'description',
      displayName: 'Offer',
      sortable: false
    },
    {
      fieldName: 'expiration',
      displayName: 'Expires',
      sortable: true
    },
    {
      fieldName: 'redemptions',
      displayName: 'Redemptions',
      sortable: false
    },
    {
      fieldName: null,
      displayName: 'Actions',
      sortable: false
    }
  ].filter((header) => {
    if (header.displayName === 'Actions' && !canManageOffers) {
      return null;
    }
    return header;
  });

  let offersAndCreditsTypes = {
    discounts: {
      type: 'discounts',
      title: 'Discounts',
      subtitle: '',
      button: 'Create a Discount',
      colorClass: 'bright-green-border'
    },
    freebie: {
      type: 'freebie',
      title: 'Giveaways',
      subtitle: '',
      button: 'Create a Giveaway',
      colorClass: 'pink-border'
    },
    shipping: {
      type: 'shipping',
      title: 'Shipping',
      subtitle: 'Create offers to add to your price sheets.',
      button: 'Create a Shipping Offer',
      colorClass: 'blue-border'
    }
  };

  const offerDetail = offersAndCreditsTypes[offerType];

  return (
    <div id="storefront__offers">
      {showOfferCreateOrEditModal && (
        <CreateOrEditOfferModal
          offer={activeOffer}
          offerType={offerType}
          pricesheets={pricesheets}
          onSave={handleOfferCreateOrEditDialogSave}
          onCancel={handleOfferDialogsCancel}
        />
      )}
      {showOfferRemoveModal && activeOffer && (
        <DeleteOfferModal offer={activeOffer} onConfirm={handleDeleteOfferDialogConfirm} onCancel={handleOfferDialogsCancel} />
      )}

      <div className="flex items-center justify-between">
        {offerDetail && (
          <div>
            <h2 className="text-headline-sm">{offerDetail.title}</h2>
            <p>{offerDetail.subtitle}</p>
          </div>
        )}
        {canManageOffers && studioId !== 'all-studios' && (
          <div>
            <SafeLink className="button" Component="button" disabled={requesting} onClick={() => handleAddOfferClick(offerDetail.type)}>
              {offerDetail.button}
            </SafeLink>
          </div>
        )}
      </div>

      <PhotoDayGrid
        headers={gridHeader}
        table_id="offer-table"
        extraClasses="offer-table"
        pager={pager}
        fetchRecordsPage={fetchOffersPage}
        borderClass={`${offerDetail.colorClass} color-border`}
        requesting={requesting}
        defaultContent="You have not added any offers yet."
      >
        {result.map((id) => (
          <OfferRow
            key={id}
            offerType={offerType}
            offer={entities.offers[id]}
            canManageOffers={canManageOffers}
            onClickRemove={handleOfferGridRemoveAction}
            onClickSettings={handleOfferGridSettingsAction}
          />
        ))}
      </PhotoDayGrid>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Offers);
