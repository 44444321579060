import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

// Styles
import './style.css';

const Pagination = ({ position = 'bottom', pagination = { page: 1, perPage: 15, total: 0 }, showPagesCount = 4, onPagination }) => {
  const { page, perPage, total } = pagination;
  const [pagesArray, setPagesArray] = useState([]);

  const totalPages = Math.ceil(total / perPage);
  const maxPagesToShow = Math.min([totalPages], [showPagesCount]);

  const prevPage = page > 1 ? page - 1 : page;
  const nextPage = page < totalPages ? page + 1 : page;

  const showPrevGroup = page > maxPagesToShow;
  const showNextGroup = totalPages > showPagesCount && pagesArray.slice(-1) < totalPages;

  const handlePrevSet = () => {
    const nextPage = Number(pagesArray[0]) - showPagesCount;
    const prevGroup = nextPage >= 1 ? nextPage : 1;

    onPagination(prevGroup);
  };

  const handleNextSet = () => {
    const nextPage = Number(pagesArray.slice(-1)) + 1;
    const nextGroup = nextPage <= totalPages ? nextPage : totalPages;

    onPagination(nextGroup);
  };

  const pagesGroupCreate = (page, totalPages, maxPagesToShow) => {
    const pageArray = [];
    const groupFactor = page <= maxPagesToShow ? 1 : Math.ceil(page / maxPagesToShow);
    const firstItem = maxPagesToShow * groupFactor - (maxPagesToShow - 1);

    let n = firstItem;

    while (n <= totalPages && pageArray.length < maxPagesToShow) {
      pageArray.push(n);
      n++;
    }

    setPagesArray(pageArray.length > 0 ? pageArray : [1]);
  };

  useEffect(() => {
    pagesGroupCreate(page, totalPages, showPagesCount);
  }, [pagination]);

  return (
    <aside className={`table__pagination table__pagination--${position}`}>
      {total > 0 && pagesArray.length > 0 && (
        <>
          <button className="button button--clean table__pagination-arrow" onClick={() => onPagination(prevPage)} disabled={prevPage === page}>
            <i className="icon-arrow-left" />
          </button>

          {showPrevGroup && (
            <button className="button button--clean" onClick={handlePrevSet}>
              ...
            </button>
          )}

          <ul className="table__pagination-list">
            {pagesArray.map((pageNum, i) => (
              <li
                className={`table__pagination-page ${page === pageNum ? 'table__pagination-page--active' : ''}`}
                key={i}
                onClick={() => onPagination(pageNum)}
              >
                <button className="button button--clean">{pageNum}</button>
              </li>
            ))}
          </ul>

          {showNextGroup && (
            <button className="button button--clean" onClick={handleNextSet}>
              ...
            </button>
          )}

          <button className="button button--clean table__pagination-arrow" onClick={() => onPagination(nextPage)} disabled={nextPage === page}>
            <i className="icon-arrow-right" />
          </button>
        </>
      )}
    </aside>
  );
};

Pagination.propTypes = {
  position: PropTypes.oneOf(['top', 'bottom']),
  pagination: PropTypes.shape({
    page: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired
  }),
  showPagesCount: PropTypes.number
};

export default Pagination;
