import React from 'react';
import PropTypes from 'prop-types';

const SalesItem = ({ item: { key, value } }) => {
  return (
    <tr>
      <td>{key}</td>
      <td className="text-right quickglance__item-value">{value}</td>
    </tr>
  );
};

SalesItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    key: PropTypes.any,
    value: PropTypes.any
  })
};

export default SalesItem;
