import {
  CREATE_GIFT_CARD_REQUEST,
  CREATE_GIFT_CARD_SUCCESS,
  CREATE_GIFT_CARD_ERROR,
  UPDATE_GIFT_CARD_REQUEST,
  UPDATE_GIFT_CARD_SUCCESS,
  UPDATE_GIFT_CARD_ERROR,
  DESTROY_GIFT_CARD_REQUEST,
  DESTROY_GIFT_CARD_SUCCESS,
  DESTROY_GIFT_CARD_ERROR,
  GIFT_CARD_LIST_REQUEST,
  GIFT_CARD_LIST_SUCCESS,
  GIFT_CARD_LIST_ERROR,
  GET_CREDIT_SHEET_DETAIL_REQUEST,
  GET_CREDIT_SHEET_DETAIL_SUCCESS,
  GET_CREDIT_SHEET_DETAIL_ERROR,
  CREATE_CREDITSHEET_REQUEST,
  CREATE_CREDITSHEET_SUCCESS,
  CREATE_CREDITSHEET_ERROR,
  UPDATE_CREDITSHEET_REQUEST,
  UPDATE_CREDITSHEET_SUCCESS,
  UPDATE_CREDITSHEET_ERROR,
  CREDITSHEET_LIST_REQUEST,
  CREDITSHEET_LIST_SUCCESS,
  CREDITSHEET_LIST_ERROR,
  DESTROY_CREDITSHEET_REQUEST,
  DESTROY_CREDITSHEET_SUCCESS,
  DESTROY_CREDITSHEET_ERROR
} from './constants';

export const createGiftCardListRequest = ({ creditSheetId, amount, description }, callback) => ({
  type: CREATE_GIFT_CARD_REQUEST,
  payload: {
    creditSheetId,
    amount,
    description
  },
  callback
});

export const createGiftCardListRequestSuccess = (result) => ({
  type: CREATE_GIFT_CARD_SUCCESS,
  payload: result
});

export const createGiftCardListRequestError = (error) => ({
  type: CREATE_GIFT_CARD_ERROR,
  payload: {
    error
  }
});

export const updateGiftCardListRequest = ({ giftCardId, amount, description }, callback) => ({
  type: UPDATE_GIFT_CARD_REQUEST,
  payload: {
    giftCardId,
    amount,
    description
  },
  callback
});

export const updateGiftCardListRequestSuccess = (result) => ({
  type: UPDATE_GIFT_CARD_SUCCESS,
  payload: result
});

export const updateGiftCardListRequestError = (error) => ({
  type: UPDATE_GIFT_CARD_ERROR,
  payload: {
    error
  }
});

export const giftCardListRequest = (creditSheetId, callback) => ({
  type: GIFT_CARD_LIST_REQUEST,
  payload: {
    creditSheetId
  },
  callback
});

export const giftCardListRequestSuccess = (result) => ({
  type: GIFT_CARD_LIST_SUCCESS,
  payload: result
});

export const giftCardListRequestError = (error) => ({
  type: GIFT_CARD_LIST_ERROR,
  payload: {
    error
  }
});

export const deleteGiftCardListRequest = (giftCardId, callback) => ({
  type: DESTROY_GIFT_CARD_REQUEST,
  payload: {
    giftCardId
  },
  callback
});

export const deleteGiftCardListRequestSuccess = (result) => ({
  type: DESTROY_GIFT_CARD_SUCCESS,
  payload: result
});

export const deleteGiftCardListRequestError = (error) => ({
  type: DESTROY_GIFT_CARD_ERROR,
  payload: {
    error
  }
});

export const getCreditSheetDetailRequest = (creditSheetId) => ({
  type: GET_CREDIT_SHEET_DETAIL_REQUEST,
  payload: {
    creditSheetId
  }
});

export const getCreditSheetDetailSuccess = (result) => ({
  type: GET_CREDIT_SHEET_DETAIL_SUCCESS,
  payload: result
});

export const getCreditSheetDetailError = (error) => ({
  type: GET_CREDIT_SHEET_DETAIL_ERROR,
  payload: {
    error
  }
});

export const creditSheetListRequest = ({ page, perPage, order, dir, search }, callback) => ({
  type: CREDITSHEET_LIST_REQUEST,
  payload: {
    page,
    perPage,
    order,
    dir,
    search
  },
  callback
});

export const creditSheetListRequestSuccess = ({ entities, pager, result }) => ({
  type: CREDITSHEET_LIST_SUCCESS,
  payload: {
    entities,
    pager,
    result
  }
});

export const creditSheetListRequestError = (error) => ({
  type: CREDITSHEET_LIST_ERROR,
  payload: {
    error
  }
});

export const destroyCreditSheetRequest = ({ studioId, creditSheetId }, callback) => ({
  type: DESTROY_CREDITSHEET_REQUEST,
  payload: {
    studioId,
    creditSheetId,
    callback
  }
});

export const destroyCreditSheetRequestSuccess = ({ creditSheetId }) => ({
  type: DESTROY_CREDITSHEET_SUCCESS,
  payload: {
    creditSheetId
  }
});

export const destroyCreditSheetRequestError = (error) => ({
  type: DESTROY_CREDITSHEET_ERROR,
  payload: {
    error
  }
});

const actionCreditSheetRequest =
  (type) =>
  ({ studioId, creditSheet }, callback) => ({
    type: type,
    payload: {
      studioId,
      creditSheet,
      callback
    }
  });

const actionCreditSheetSuccess =
  (type) =>
  ({ studioId, creditSheet }) => ({
    type: type,
    payload: {
      studioId,
      creditSheet
    }
  });

const actionCreditSheetError = (type) => (error) => ({
  type: type,
  payload: {
    error
  }
});

export const createCreditSheetRequest = actionCreditSheetRequest(CREATE_CREDITSHEET_REQUEST);
export const createCreditSheetRequestSuccess = actionCreditSheetSuccess(CREATE_CREDITSHEET_SUCCESS);
export const createCreditSheetRequestError = actionCreditSheetError(CREATE_CREDITSHEET_ERROR);

export const updateCreditSheetRequest = actionCreditSheetRequest(UPDATE_CREDITSHEET_REQUEST);
export const updateCreditSheetRequestSuccess = actionCreditSheetSuccess(UPDATE_CREDITSHEET_SUCCESS);
export const updateCreditSheetRequestError = actionCreditSheetError(UPDATE_CREDITSHEET_ERROR);
