import React from 'react';
import PropTypes from 'prop-types';
import FlipMove from 'react-flip-move';

const FlipContainer = ({ component = 'div', animate = true, children, ...options }) => {
  return (
    <FlipMove typeName={component} enterAnimation="fade" leaveAnimation="fade" easing="ease-out" disableAllAnimations={animate} {...options}>
      {children}
    </FlipMove>
  );
};

FlipContainer.propTypes = {
  animate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

export default FlipContainer;
