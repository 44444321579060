import * as types from './constants';

// Create Order Resolution
export const createOrderResolution = (payload, callback) => ({
  type: types.ORDER_RESOLUTION_REQUEST,
  payload: { payload, callback }
});

export const createOrderResolutionSuccess = (payload) => ({
  type: types.ORDER_RESOLUTION_SUCCESS,
  payload: { payload }
});

export const createOrderResolutionError = (error) => ({
  type: types.ORDER_RESOLUTION_ERROR,
  payload: { error }
});
