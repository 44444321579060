import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { connect } from 'react-redux';
import { resendVerificationEmail, verify } from '../actions';

import { ToastContainer } from 'react-toastify';

import Container from '@/components/SignUp/Shared/Container';
import VerifyForm from '../Forms/VerifyForm';

const mapStateToProps = (state, ownProps) => {
  return {
    ...state.signUp,
    ...ownProps,
    isAuthenticated: state.login.isAuthenticated
  };
};
const mapDispatchToProps = { resendVerificationEmail, verify };

const Verify = ({ match, verify, resendVerificationEmail, lab, email, requesting, verificationFailed, isAuthenticated, title, subtitle }) => {
  const env = import.meta.env.VITE_ENVIRONMENT;

  const getToken = () => {
    if (match && match.params && match.params.token) {
      return match.params.token;
    }
    return null;
  };

  const handleSubmit = (values) => {
    const { email } = values;
    resendVerificationEmail(email);
  };

  useEffect(() => {
    const token = getToken();
    if (token) verify(token);
  }, [verify, match]);

  if (isAuthenticated) return <Redirect to="/home" />;

  return (
    <>
      <ToastContainer />
      <Container id="register" labImage={lab.image} title={title} subtitle={subtitle}>
        {requesting && (
          <div className="sign-up-form">
            <div className="flex">
              <div className="flex-offset-2 basis-8/12 text-center">
                <p>Verifying email...</p>
              </div>
            </div>
          </div>
        )}
        {!requesting && (
          <div>
            {verificationFailed === true && (
              <div className="text-center">
                <p>Failed to verify account. Your verification email may be expired.</p>
                <p>Please enter your email below and try again.</p>
              </div>
            )}
            {!verificationFailed && (
              <div className="text-center">
                <p>
                  We have sent you an email with instructions to verify your PhotoDay account. <br />
                  If you did not receive an email, select <b>Resend Verification Email</b> and we will send another verification email.
                </p>
              </div>
            )}
            <VerifyForm
              onSubmit={(values) => {
                handleSubmit(values);
              }}
              className="sign-up-form text-center"
              initialValues={{ email: email }}
            />
          </div>
        )}
      </Container>
    </>
  );
};

Verify.propTypes = {
  email: PropTypes.string,
  lab: PropTypes.shape({
    key: PropTypes.string,
    image: PropTypes.string
  })
};

export default connect(mapStateToProps, mapDispatchToProps)(Verify);
