// Helpers
import * as api from '@/utils/http';

export const createFlyer = (payload) => {
  const endpoint = `jobs/${payload.jobId}/flyers`;
  const args = { ...payload };

  return api.post(endpoint, args);
};

export const createFlyerEmail = (payload) => {
  const endpoint = `flyers/${payload.id}/email-async`;
  const args = { ...payload };

  return api.post(endpoint, args);
};

export const getFlyerList = (payload) => {
  const endpoint = `jobs/${payload.id}/flyers`;
  const args = { ...payload };

  return api.get(endpoint, args);
};

export const getFlyer = (payload) => {
  const endpoint = `flyers/${payload.id}`;
  const restricted = false;

  return api.get(endpoint, {}, restricted);
};

export const getFlyerPdf = (payload) => {
  const endpoint = `flyers/${payload.id}/pdf-async`;
  const args = payload?.subject_fields ? { subject_fields: payload.subject_fields } : {};

  api.post(endpoint, args);
};

export const updateFlyer = (payload) => {
  const endpoint = `flyers/${payload.id}`;
  const args = { ...payload };

  return api.put(endpoint, args);
};

export const deleteFlyer = (payload) => {
  const endpoint = `jobs/${payload.id}/flyers/bulk-destroy`;
  const args = { ...payload };

  return api.del(endpoint, args);
};
