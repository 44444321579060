export const normalizePhone = (phoneString) => {
  if (phoneString) {
    const regEx = /^\+1|([^0-9])/g;

    let rawString = phoneString.replace(regEx, '');

    if (rawString.length > 11) {
      return phoneString;
    }

    if (!/^1/.test(rawString)) {
      rawString = '1' + rawString;
    }

    const elevenDigits = rawString.length >= 11 ? rawString.slice(rawString.length - 11) : null;
    const arr = elevenDigits ? elevenDigits.split('') : null;

    if (elevenDigits) {
      const normalized = `+${arr.slice(0, 1).join('')} (${arr.slice(1, 4).join('')}) ${arr.slice(4, 7).join('')}-${arr.slice(7).join('')}`;

      return normalized;
    }
  }

  return phoneString;
};

export const formatHours = (hours) => {
  const period = hours >= 12 ? 'pm' : 'am';
  // Find current hour in AM-PM Format
  hours = hours % 12;
  // To display "0" as "12"
  hours = hours ? hours : 12;
  return hours + ':00' + period;
};
