import React from 'react';
import PropTypes from 'prop-types';
import { convertToText } from '@/utils/currency';
import { cropImage } from '@/utils/imageCropping';

// Helpers
import imageScaling from '@/utils/imageScaling';

const OrderItemPackage = ({ orderItem, retouchArray }) => {
  const {
    name,
    description,
    quantity,
    price_cents,
    order_item_products,
    digital_bundle_size: digitalBundleSize,
    price_sheet_item: { digital_bundle: isDigitalBundle }
  } = orderItem;

  const productRows = [];
  const productNames = [];

  order_item_products?.forEach((orderItemProduct, index) => {
    const {
      layout_theme,
      is_adaptive_crop,
      has_logo: orderItemHasLogo,
      supports_logo: orderItemSupportsLogo,
      order_item_product_nodes: orderItemProductNodes,
      price_sheet_item_purchasable: priceSheetItemPurchasable
    } = orderItemProduct || {};

    if (priceSheetItemPurchasable) {
      const { full_name: productName, product } = priceSheetItemPurchasable;
      const layout_theme_attributes = layout_theme ? layout_theme.layout_theme_attributes : [];

      const imageNodes = orderItemProductNodes.filter((node) => node.photo) || [];
      const textNodes = orderItemProductNodes.filter((node) => node.layout_node_type === 'text');

      const productImage = layout_theme && layout_theme.image_url ? layout_theme.image_url : product ? product.image_url : '';

      const hasLayoutAttributes = Object.values(layout_theme_attributes).length > 0;
      const hasCustomization = (layout_theme && !hasLayoutAttributes) || hasLayoutAttributes || textNodes.length > 0;

      productNames.push(productName);

      productRows.push(
        <div className="order-product__row" key={productName + index}>
          <div className="order-product__container">
            <div className="order-image__wrapper">
              <img className="order-image" src={imageScaling({ url: productImage, size: 'xsmall' })} alt={productName} width="100" />
            </div>
            <div className="order-product__item-details">
              <p className="order-invoice__label">Product Name: </p>
              <p>{productName}</p>
            </div>
          </div>

          <div className="selected-images__container">
            {imageNodes &&
              imageNodes.map((node, index) => (
                <div className="selected-image_node" key={node.id}>
                  <div
                    className={`order-image__wrapper ${
                      retouchArray.some((photo) => photo.photo_id === node.photo.id) ? 'order-image__wrapper--retouched' : ''
                    }`}
                  >
                    <img
                      className="order-image"
                      style={{
                        backgroundColor: '#eee',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundImage: node.background ? `url(${imageScaling({ url: node.background.image_url, size: 'xsmall' })})` : 'none',
                        transform: node.overlay_padding ? `scale(${Number(node.overlay_padding.substring(0, 1)) / 100 + 1})` : ''
                      }}
                      src={
                        node.crop
                          ? cropImage(node.crop, node.photo, 100)
                          : imageScaling({
                              url: node.photo.image_url,
                              size: 'xsmall'
                            })
                      }
                      alt={node.photo.image_filename}
                      width="100"
                    />
                    {node.overlay_url && (
                      <img
                        src={`${node.overlay_url}${is_adaptive_crop && node?.crop?.aspect > 1 ? '&rotate=270' : ''}`}
                        className="order-image__overlay"
                        alt="product overlay"
                      />
                    )}
                  </div>
                  <div className="order-product__item-details">
                    <p className="order-invoice__label">Image Name: </p>
                    <p>{node.photo.image_filename}</p>
                    {retouchArray.some((photo) => photo.photo_id === node.photo.id) && <p>Basic Retouching Added</p>}
                    {imageNodes.length > 1 && (
                      <p>
                        {' '}
                        (Image {index + 1} of {imageNodes.length}){' '}
                      </p>
                    )}
                    {orderItemSupportsLogo && (
                      <p>
                        <strong>Logo:</strong> {orderItemHasLogo ? 'Yes' : 'No'}
                      </p>
                    )}
                  </div>
                </div>
              ))}
          </div>

          <div className="order-product__details">
            {hasCustomization && <div className="order-invoice__label"> Product Customizations:</div>}

            {/*Deprecated: Use until all labs converted to use layout_theme_attributes */}
            {layout_theme && !hasLayoutAttributes && (
              <ul>
                <li>
                  <span className="order-invoice__label">Collection: </span>
                  {layout_theme.theme_name}
                </li>
                <li>
                  <span className="order-invoice__label">Theme: </span>
                  {layout_theme.activity_name}
                </li>
              </ul>
            )}

            {hasLayoutAttributes && (
              <ul>
                {layout_theme.theme_name && (
                  <li>
                    <span className="order-invoice__label">Theme Name: </span>
                    {layout_theme.theme_name}
                  </li>
                )}
                {layout_theme_attributes.map((attr, index) => (
                  <li key={index}>
                    <span className="order-invoice__label">{`${attr.prop_title}:`} </span>
                    {attr.prop_value}
                  </li>
                ))}
              </ul>
            )}

            {textNodes && (
              <ul>
                {textNodes.map((textNode) => (
                  <li key={textNode.id}>
                    <span className="order-invoice__label">{textNode.description}: </span>
                    {textNode.value || <em>no text entered</em>}
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className="order-product__unit-cost" />
        </div>
      );
    }
  });

  return (
    <>
      <div className="order-package__header">
        <h4 className="order-invoice__label">
          {isDigitalBundle ? (
            'Digital Download Bundle'
          ) : (
            <>
              {name}
              <small> {description}</small> {quantity > 1 && <small>({quantity}x)</small>}
            </>
          )}
        </h4>
        <div className="order-package__details">
          {isDigitalBundle ? (
            <p>{`Includes ${digitalBundleSize} ${name}${digitalBundleSize === 1 ? '' : 's'}`}</p>
          ) : (
            <p>Package contents: {productNames.join(', ')}</p>
          )}
          <span>{convertToText(price_cents, '$')}</span>
        </div>
      </div>
      {productRows}
      <div className="order-invoice__hr">
        <hr />
      </div>
    </>
  );
};

OrderItemPackage.propTypes = {
  orderItem: PropTypes.object.isRequired,
  retouchArray: PropTypes.array
};

export default OrderItemPackage;
