import { all } from 'redux-saga/effects';

import JobsSaga from '@/components/Jobs/sagas';
import UserSaga from '@/components/Settings/sagas';
import HomeSagas from '@/components/Home/sagas';
import SignUpSaga from '@/components/SignUp/sagas';
import ReportsSaga from '@/components/Reports/sagas';
import ResourcesSaga from '@/components/Resources/sagas';
import StatesSaga from '@/components/Shared/StatesDDL/sagas';
import OffersSaga from '@/components/Storefront/Offers/sagas';
import OrdersSaga from '@/components/Storefront/Orders/sagas';
import PromosSaga from '@/components/Storefront/Promos/sagas';
import OffersDDLSaga from '@/components/Shared/OffersDDL/sagas';
import OrganizationsSaga from '@/components/Organizations/sagas';
import ShippingSaga from '@/components/Storefront/Shipping/sagas';
import { loginWatcher as LoginSaga } from '@/components/Login/sagas';
import AdvancePaySaga from '@/components/Storefront/AdvancePay/sagas';
import AddressSaga from '@/components/Shared/AddressVerification/sagas';
import PricesheetsSaga from '@/components/Storefront/PriceSheets/sagas';
import CustomThemeSaga from '@/components/Storefront/CustomThemes/sagas';
import BackgroundsSaga from '@/components/Storefront/Backgrounds/sagas';
import CreditsSaga from '@/components/Storefront/Orders/Credits/sagas';
import ProductCategoriesDDLSaga from '@/components/Shared/ProductCategoriesDDL/sagas';

export default function* IndexSaga() {
  yield all([
    LoginSaga(),
    JobsSaga(),
    UserSaga(),
    HomeSagas(),
    SignUpSaga(),
    OffersSaga(),
    OrdersSaga(),
    PromosSaga(),
    StatesSaga(),
    AddressSaga(),
    CreditsSaga(),
    ReportsSaga(),
    ShippingSaga(),
    ResourcesSaga(),
    OffersDDLSaga(),
    AdvancePaySaga(),
    PricesheetsSaga(),
    CustomThemeSaga(),
    BackgroundsSaga(),
    OrganizationsSaga(),
    ProductCategoriesDDLSaga()
  ]);
}
