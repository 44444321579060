import { schema } from 'normalizr';
const shippingEntity = new schema.Entity('shipping');
const lab = new schema.Entity('lab');

const shipping = new schema.Entity('shippings', {
  shipping: shippingEntity,
  lab: lab
});

const shippingSchema = [shipping];

export default shippingSchema;
