import React from 'react';
import { Link } from 'react-router-dom';

import Tip from '@/components/Shared/Tip';
import StepContainer from './Step';

import workflow_step1 from '@/assets/images/workflow_sell_step1.jpg';
import workflow_step2 from '@/assets/images/workflow_sell_step2.jpg';
import workflow_step3 from '@/assets/images/workflow_sell_step3.jpg';
import workflow_step4 from '@/assets/images/workflow_sell_step4.jpg';
import workflow_step5 from '@/assets/images/workflow_sell_step5.jpg';
import workflow_step6 from '@/assets/images/workflow_sell_step6.jpg';
import workflow_step7 from '@/assets/images/workflow_sell_step7.jpg';
import workflow_step8 from '@/assets/images/workflow_sell_step8.jpg';
import workflow_step9 from '@/assets/images/workflow_sell_step9.jpg';
import workflow_step10 from '@/assets/images/workflow_sell_step10.jpg';
import workflow_step11 from '@/assets/images/workflow_sell_step11.jpg';
import workflow_step12 from '@/assets/images/workflow_sell_step12.jpg';
import workflow_step13 from '@/assets/images/workflow_sell_step13.jpg';

import './steps.css';

const StepByStepSellBeforePictureDay = () => {
  return (
    <div className="step-by-step">
      <header className="flex items-center resources-container__header">
        <h2 className="text-headline-sm">How to Sell Before Picture Day</h2>
      </header>

      <div className="panel flex items-center justify-between">
        <p className="m-0">A step-by-step guide for using AdvancePay to drive more sales.</p>
        <a
          className="button button--medium"
          href="https://media.photoday.io/pdfs/step_by_step_advancepay.pdf"
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          Download PDF
        </a>
      </div>

      <div className="steps-list">
        <StepContainer
          step="Step 1"
          image={workflow_step1}
          title="When you schedule a job"
          description="Set up AdvancePay credit sheet and the price sheet you’d like to use for this job."
        />

        <StepContainer
          step="Step 2"
          image={workflow_step2}
          title="When you schedule a job"
          description={
            <>
              Create an offer in the Studio Panel Store that you’d like to use with AdvancePay - and any other offers you’d like to use for this job.
              <Tip type="text" text="AdvancePay offer should expire soon after gallery is published to create sense of urgency to complete purchase." />
            </>
          }
        />

        <StepContainer
          step="Step 3"
          image={workflow_step3}
          title="When you schedule a job"
          description="Set up the organization and create the job. Associate the price sheet, AdvancePay, and AdvancePay offer with the job."
        />

        <StepContainer
          step="Step 4"
          image={workflow_step4}
          title="4 WEEKS BEFORE PICTURE DAY"
          description={
            <>
              Find out how organization communicates with parents. Prepare and print all marketing materials to encourage purchase of AdvancePay credits.
              <Tip
                type="text"
                text={
                  <span>
                    Plan ahead to have print materials ready for use before and during picture day. See <Link to="/collateral/marketing">Marketing Kit</Link>.
                  </span>
                }
              />
            </>
          }
        />

        <StepContainer
          step="Step 5"
          image={workflow_step5}
          title="2-4 WEEKS BEFORE"
          description="Set the Gallery Status to AdvancePay. This will allow customers to purchase AdvancePay credits before picture day."
        />

        <StepContainer
          step="Step 6"
          image={workflow_step6}
          title="2-4 WEEKS BEFORE"
          description="Create a flyer that announces picture day and promotes special offer with purchase of AdvancePay credits."
        />

        <StepContainer
          step="Step 7"
          image={workflow_step7}
          title="2-4 WEEKS BEFORE"
          description="Send flyer PDF and website URL to your contact at the organization and make sure they send it to parents right away!"
        />

        <StepContainer
          step="Step 8"
          image={workflow_step8}
          title="DAY BEFORE OR MORNING OF"
          description="Set up tents and tables. Make sure your staff is trained to tell parents to text the event’s unique access code to 90738 so they can view AdvancePay credits with special offer and be notified when their child’s photos are ready."
        />

        <StepContainer
          step="Step 9"
          image={workflow_step9}
          title="Picture Day!"
          description="Set up banners, posters, reminder cards, and equipment - and make sure staff is trained to hand out reminder cards to each child."
        />

        <StepContainer
          step="Step 10"
          image={workflow_step10}
          title="Picture Day!"
          description="If parents are present, ask parents to text the access code before their child’s photo is taken. Use a time-sensitive offer to create order urgency. Hand out those reminder cards!"
        />

        <StepContainer
          step="Step 11"
          image={workflow_step11}
          title="After picture day!"
          description="Upload photos and change Gallery Status to Published. PhotoDay will send a text message to all customers who texted the access code, letting them know that the gallery is live."
        />

        <StepContainer
          step="Step 12"
          image={workflow_step12}
          title="After picture day!"
          description="Drive sales after picture day with a new offer (one that doesn’t compete with the AdvancePay offer). Create a flyer that announces that the picture day photos have been published and includes the new offer."
        />

        <StepContainer
          step="Step 13"
          image={workflow_step13}
          title="After picture day!"
          description="PhotoDay will send an automatic text message to customers who opted in and/or purchased AdvancePay credits."
        />
      </div>
    </div>
  );
};

export default StepByStepSellBeforePictureDay;
