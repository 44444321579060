import PropTypes from 'prop-types';
import { Fragment, memo } from 'react';

// Plugins
import moment from 'moment';

// Helpers
import { normalizePhone } from '@/utils/formatters';
import imageScaling from '@/utils/imageScaling';

// Styles
import './style.css';

// Important Notes
//  This component is used to generate a pdf on the server and act ast the template for html email, because of this, semantic html5 elements and many css properties cannot be used i.e. <figure>, <article>, flexbox, grid, etc. The current cli for PDF service is wkhtmltopdf. Also, the markup and styles are being extracted and sent to the server when the flyer is updated. The extractCSS tool used in the Design component, will extract only single class selectors and remove all spaces, so styles cannot be written in short form like border: 1px solid #f4f4f4; This must be written in long form as border-width: 1px; border-style: solid; border-color: #f4f4f4; Otherwise, this style will not be readable on the server.

const GALLERY_URL = import.meta.env.VITE_GALLERY_URL;
const MY_PHOTODAY_URL = import.meta.env.VITE_MY_PHOTODAY_URL;

const LayoutBasic = memo(function ({ layoutRef, job, flyer }) {
  const isPrivateJob = job.access_mode === 'access_per_subject';
  const accessCode = isPrivateJob ? '{{access_code}}' : job.access_code;
  const galleryUrl = job.gallery_v4 ? `${MY_PHOTODAY_URL}g/${accessCode}` : GALLERY_URL;

  return (
    <div ref={layoutRef}>
      <table className="animate" cellPadding="0" cellSpacing="0" border="0" width="100%">
        <tbody>
          <tr>
            <td align="center">
              <table className="job-marketing-flyers__basic-template" cellPadding="0" cellSpacing="0" border="0" align="center">
                <tbody>
                  {isPrivateJob && (
                    <tr>
                      <td align="right">
                        <span className="job-marketing-flyers__basic-sorting">{'{{sorting_data}}'}</span>
                      </td>
                    </tr>
                  )}
                  {flyer?.show_job_logo && job.logo_url && (
                    <tr>
                      <td align="center">
                        <table width="100%">
                          <tbody>
                            <tr>
                              <td align="center">
                                <div className="job-marketing-flyers__basic-header">
                                  <img src={imageScaling({ url: job.logo_url, size: 'small' })} alt={job.name} height="40" style={{ margin: '0 auto' }} />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>
                      <table className="job-marketing-flyers__basic-body" width="100%">
                        <tbody>
                          <tr>
                            {flyer.show_image && (
                              <td style={{ verticalAlign: 'top' }}>
                                <div
                                  className={`job-marketing-flyers__basic-figure animate ${
                                    isPrivateJob && flyer.show_image && !flyer.image_url ? 'job-marketing-flyers__basic-figure--private' : ''
                                  } ${flyer.image_url ? 'job-marketing-flyers__basic-figure--bg-none' : ''}`}
                                >
                                  {isPrivateJob && flyer.show_image && !flyer.image_url && (
                                    <img className="job-marketing-flyers__basic-image" src="{{subject_image}}" alt="" />
                                  )}

                                  {flyer.show_image && flyer.image_url && (
                                    <img
                                      className="job-marketing-flyers__basic-image"
                                      src={imageScaling({ url: flyer.image_url, size: 'medium' })}
                                      alt={flyer.image_filename}
                                    />
                                  )}
                                </div>
                              </td>
                            )}
                            <td style={{ verticalAlign: 'top' }}>
                              <table>
                                <tbody>
                                  <tr>
                                    <td>
                                      <div className="job-marketing-flyers__basic-details">
                                        <h1 className="job-marketing-flyers__basic-title">{flyer.name}</h1>
                                        {flyer.show_job_date && (
                                          <p className="animate">
                                            {`Your picture date ${flyer.flyer_offer_type === 'post_picture' ? 'was' : 'is'} on`}{' '}
                                            <b>{moment.utc(job.date).format('L')}</b>
                                          </p>
                                        )}
                                        <div className="job-marketing-flyers__basic-body-main">{flyer.body}</div>
                                        <div className="job-marketing-flyers__basic-subject">
                                          <span>{isPrivateJob ? "{{subject_name}}'s" : 'Your'}</span> Access Code for {job.name} is
                                        </div>
                                        <span className="job-marketing-flyers__basic-access">{isPrivateJob ? '{{access_code}}' : job.access_code}</span>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <table width="100%">
                        <tbody>
                          <tr>
                            <td>
                              <hr className="job-marketing-flyers__basic-separator" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>

                  <tr>
                    <td cellPadding="10" cellSpacing="20">
                      <table width="100%">
                        <tbody>
                          <tr>
                            <td>
                              <h2>
                                {flyer.flyer_offer_type === 'pre_picture' &&
                                  `Get Updates About ${isPrivateJob ? "{{subject_name}}'s Photos for" : ''} ${job.name}`}
                                {flyer.flyer_offer_type === 'advance_pay' &&
                                  `How to Purchase AdvancePay Credits for ${isPrivateJob ? "{{subject_name}}'s" : ''} ${job.name} Photos`}
                                {flyer.flyer_offer_type?.match(/published|post_picture/) &&
                                  `How to View and Purchase ${isPrivateJob ? "{{subject_name}}'s" : ''} ${job.name} Photos`}
                              </h2>
                              <div>
                                Text the access code{' '}
                                <span className="uppercase">
                                  <b>{isPrivateJob ? '{{access_code}}' : job.access_code}</b>
                                </span>{' '}
                                to <b>{job.access_phone && normalizePhone(job.access_phone)}</b> to receive access and ongoing alerts* or visit{' '}
                                <a className="job-marketing-flyers__basic-instructions-link" href={galleryUrl} target="_blank" rel="noopener noreferrer">
                                  <b>{galleryUrl}</b>
                                </a>{' '}
                                on your phone or computer.
                              </div>
                              {isPrivateJob && (
                                <>
                                  {flyer.flyer_offer_type === 'pre_picture' ? (
                                    <span>Share access to {'{{subject_name}}'}'s gallery by giving this code or link to others.</span>
                                  ) : (
                                    <span>Share this code or link to allow access to {'{{subject_name}}'}'s gallery.</span>
                                  )}
                                </>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <hr className="job-marketing-flyers__basic-separator" />
                    </td>
                  </tr>

                  {job.advancepay_offer && flyer?.flyer_offer_type === 'advance_pay' ? (
                    <tr>
                      <td>
                        <table width="100%">
                          <tbody>
                            <tr>
                              <td>
                                <div className="job-marketing-flyers__basic-offers">
                                  {job.advancepay_offer.offer_type === 'shipping' ? (
                                    <h3>Get FREE SHIPPING by purchasing an AdvancePay credit</h3>
                                  ) : (
                                    <h3>
                                      Save
                                      {job.advancepay_offer.offer_type === 'fixed_amount'
                                        ? ` $${(job.advancepay_offer.fixed_amount_cents / 100).toFixed(2)}`
                                        : ` ${job.advancepay_offer.fixed_percentage}%`}{' '}
                                      {job.advancepay_offer.includes_free_shipping ? '+ FREE SHIPPING! ' : ''} by purchasing AdvancePay credit
                                    </h3>
                                  )}

                                  {(job.advancepay_offer.max_redemptions || job.advancepay_offer.min_order_price_cents) && (
                                    <div>
                                      Offer details:
                                      {job.advancepay_offer.max_redemptions && ` Valid for the first ${job.advancepay_offer.max_redemptions} customers`}
                                      {job.advancepay_offer.max_redemptions && job.advancepay_offer.min_order_price_cents ? ' • ' : '.'}
                                      {job.advancepay_offer.min_order_price_cents &&
                                        `Orders must be $${(job.advancepay_offer.min_order_price_cents / 100).toFixed(2)} or more.`}
                                    </div>
                                  )}

                                  {job.advancepay_offer.expiration && (
                                    <div style={{ marginBottom: '10px' }}>
                                      <small>
                                        Expires <b>{moment(job.advancepay_offer.expiration).format('MMMM Do, YYYY')}</b> at <i>11:59pm PST</i>.
                                      </small>
                                      <br></br>
                                    </div>
                                  )}
                                  <div>
                                    <small>
                                      After you purchase an AdvancePay credit, you will receive a text with the coupon code for this AdvancePay offer.
                                    </small>
                                    <br />
                                    <small>
                                      To redeem your credit, visit the published gallery and log in using the same phone number used when purchasing it.
                                      <br />
                                      At checkout, you’ll notice the credit and offer will automatically be applied.
                                    </small>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  ) : (
                    <>
                      {flyer?.offers?.length > 0 ? (
                        <tr>
                          <td align="center">
                            <table>
                              <tbody>
                                <tr>
                                  {flyer?.offers?.map((offer, index) => {
                                    if (index > 1) {
                                      return null;
                                    } else {
                                      return (
                                        <Fragment key={index}>
                                          <td width="5"></td>
                                          <td className="job-marketing-flyers__basic-coupon" key={offer.id} align="center" height="0">
                                            <table>
                                              <tbody>
                                                <tr>
                                                  <td>
                                                    <div className="job-marketing-flyers__basic-coupon--row job-marketing-flyers__basic-coupon--title">
                                                      <b>
                                                        {offer.offer_type === 'shipping'
                                                          ? 'Get FREE SHIPPING '
                                                          : `Save ${
                                                              offer.offer_type === 'fixed_amount'
                                                                ? `$${(offer.fixed_amount_cents / 100).toFixed(2)}`
                                                                : `${offer.fixed_percentage || 0}%`
                                                            } ${offer.includes_free_shipping ? '+ FREE SHIPPING ' : ''}`}
                                                        {offer.min_order_price_cents && (
                                                          <span>with orders of ${(offer.min_order_price_cents / 100).toFixed(2)} or more!</span>
                                                        )}
                                                      </b>
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <div className="job-marketing-flyers__basic-coupon--row">
                                                      <span className="block text-body-xs">Use coupon code:</span>
                                                      <span className="uppercase job-marketing-flyers__basic-coupon-code">
                                                        <b>{offer.code}</b>
                                                      </span>
                                                    </div>
                                                  </td>
                                                </tr>

                                                {(offer.max_redemptions || offer.expiration) && (
                                                  <tr>
                                                    <td>
                                                      <div className="job-marketing-flyers__basic-coupon--row">
                                                        {offer.max_redemptions && (
                                                          <small className="block text-body-xs">Available only for the first {offer.max_redemptions}</small>
                                                        )}
                                                        {offer.expiration && (
                                                          <small className="block text-body-xs job-marketing-flyers__basic-coupon-expiration">
                                                            Expires {moment(offer.expiration).format('MM/DD/YY')}.
                                                          </small>
                                                        )}
                                                      </div>
                                                    </td>
                                                  </tr>
                                                )}
                                              </tbody>
                                            </table>
                                          </td>
                                          <td width="5"></td>
                                        </Fragment>
                                      );
                                    }
                                  })}
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td>
                            <div className="job-marketing-flyers__basic-offers"></div>
                          </td>
                        </tr>
                      )}
                    </>
                  )}

                  {job.meta && (
                    <tr>
                      <td>
                        <table align="center" width="100%">
                          <tbody>
                            <tr>
                              <td align="center" valign="middle">
                                <div className="job-marketing-flyers__basic-meta">
                                  {job.meta.studio_logo && flyer.show_studio_logo && (
                                    <span className="animate job-marketing-flyers__basic-logo" height="50">
                                      <img src={imageScaling({ url: job.meta.studio_logo, size: 'small' })} alt={job.meta.studio_name} height="24" />
                                    </span>
                                  )}
                                  <h2>Photos by {job.meta.studio_name}</h2>
                                  <span>{job.meta.studio_contact_email}</span>
                                  <br />
                                  <span>{job.meta.studio_website_url}</span>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}

                  {/* Disclaimer */}
                  <tr>
                    <td>
                      <table align="center" width="100%">
                        <tbody>
                          <tr>
                            <td align="center" valign="middle">
                              <div className="job-marketing-flyers__basic-disclaimer">
                                <span>*Data and message rates may apply. Message frequency may vary. Text HELP for assistance.</span>
                                <br />
                                <span>
                                  Text Stop to opt-out. Visit{' '}
                                  <a
                                    className="job-marketing-flyers__basic-instructions-link"
                                    href="https://www.photoday.com/customer-terms"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    https://www.photoday.com/customer-terms
                                  </a>{' '}
                                  for our Terms of <br /> Service and Privacy Policy.
                                </span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
});

LayoutBasic.propTypes = {
  job: PropTypes.object.isRequired,
  flyer: PropTypes.object.isRequired,
  layoutRef: PropTypes.object.isRequired
};

LayoutBasic.defaultProps = {
  job: {},
  flyer: {},
  layoutRef: {}
};

export default LayoutBasic;
