import { FC } from 'react';

// Types
interface InputCharacterRemainerProps {
  inputCharsCount: number;
  inputMaxLength: number;
}

const InputCharacterRemainer: FC<InputCharacterRemainerProps> = ({ inputCharsCount, inputMaxLength }) => {
  return <span className="block italic text-right text-body-sm mt-1">Characters remaining: {inputMaxLength - Number(inputCharsCount ?? 0)}</span>;
};

export default InputCharacterRemainer;
