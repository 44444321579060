import * as api from '@/utils/http';
import setPagingSortFilter from '@/utils/setPagingSortFilter';

export const shippingList = (labId, page, perPage, search = null, order = null, dir = null) => {
  const endpoint = `labs/${labId}/shipping-rates`;
  const args = setPagingSortFilter(page, perPage, search, order, dir);

  return api.get(endpoint, args).then((res) => {
    const { data, headers } = res;
    const pager = api.getPaginationParams(headers);

    return { data, pager };
  });
};

export const getShippingSummary = (payload) => {
  const endpoint = `jobs/${payload.jobId}/shipping-summary`;

  return api.get(endpoint);
};

export const getBulkShippingOrderList = (payload) => {
  const endpoint = `jobs/${payload.jobId}/bulk-ship-orders`;

  return api.get(endpoint);
};

export const getBulkShippingOrder = (payload) => {
  const endpoint = `bulk-ship-orders/${payload.orderId}/orders`;

  return api.get(endpoint);
};
