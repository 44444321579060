import * as api from '@/utils/http';

export const createStudioPromotion = (payload) => {
  const endpoint = 'studio-promotions';
  const args = {
    job_ids: payload.jobs,
    offer_id: payload.offer,
    studio_id: payload.studio,
    audience: payload.audience,
    is_enabled: payload.enable,
    promotion_id: payload.promotion
  };

  if (payload.startDate) {
    args.start_at = payload.startDate;
  }

  if (payload.endDate) {
    args.end_at = payload.endDate;
  }

  return api.post(endpoint, args);
};

export const getStudioPromotion = (payload) => {
  const endpoint = 'studio-promotions';
  const args = { ...payload };

  return api.get(endpoint, args).then((res) => {
    return {
      archived: args.archived || false,
      custom: args.custom || false,
      data: res.data,
      headers: res.headers
    };
  });
};

export const updateStudioPromotion = (payload) => {
  const endpoint = `studio-promotions/${payload.id}`;
  const args = {
    job_ids: payload.jobs,
    offer_id: payload.offer,
    audience: payload.audience,
    is_enabled: payload.enable
  };

  if (payload.startDate) {
    args.start_at = payload.startDate;
  }

  if (payload.endDate) {
    args.end_at = payload.endDate;
  }

  return api.put(endpoint, args);
};

export const getPhotoDayPromotionList = (payload) => {
  const endpoint = 'promotions';
  const args = { ...payload };

  return api.get(endpoint, args).then((res) => {
    return {
      custom: args.custom || false,
      data: res.data,
      headers: res.headers
    };
  });
};

export const getStudioPromotionJobList = (payload) => {
  const endpoint = 'promotion-jobs';
  const args = { ...payload };

  return api.get(endpoint, args).then((res) => {
    return { ...res.data };
  });
};

export const cancelStudioPromotion = (payload) => {
  const endpoint = `studio-promotions/${payload.id}/cancel`;

  return api.patch(endpoint);
};
