import React from 'react';

import { connect } from 'react-redux';
import { offerGenerateCodeRequest } from './../actions';

import { Input, InputGroup } from '@/components/Shared/Forms';

const mapStateToProps = (state) => {
  const {
    login: {
      studio: { id: studioId }
    }
  } = state;

  return { studioId };
};

const mapDispatchToProps = { offerGenerateCodeRequest };

const OfferMain = ({ studioId, offerGenerateCodeRequest }) => {
  const onGenerate = () => {
    offerGenerateCodeRequest(studioId);
  };

  return (
    <div className="flex gap-2.5">
      <div className="basis-6/12">
        <Input required name="name" label="Offer Name" placeholder="e.g. Summer Special" type="text" maxLength50 />
      </div>
      <div className="basis-6/12">
        <InputGroup required name="code" label="Offer Code" placeholder="e.g. SUMMER2017" type="text" maxLength20>
          <span className="text-primary-blue-link-group-addon" onClick={onGenerate}>
            Generate
          </span>
        </InputGroup>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferMain);
