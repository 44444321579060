import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Switch, NavLink, Redirect, useLocation } from 'react-router-dom';

// Components
import Labs from './Labs';
import Payment from './Payment';
import StudioUsers from './Users';
import StudioTaxes from './Taxes';
import StudioWallet from './Wallet';
import StudioInfo from './Information';
import StudioVerification from './Verification';
import StudioWatermark from './Watermark';
import PrivateRoute from '@/components/Shared/PrivateRoute';

// Styles
import './studio.css';
import './../styles.css';

const SERVICES_ENABLED = import.meta.env.VITE_SERVICES_ENABLED === '1';

const mapStateToProps = (state) => {
  const {
    login: { user },
    users: { studio }
  } = state;

  return { user, studio };
};

const MyStudio = ({ user, studio }) => {
  const location = useLocation();

  const [section, setSection] = useState('Studio Information');
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

  const isStudioVerificationRequired = studio?.verification_required;
  const roles = (user && user.roles) || [];

  const setSectionTitle = (path) => {
    const pathArr = path.split('/');
    const section = pathArr[pathArr.length - 1];

    if (section === 'my-studio' && section !== 'Studio Information') {
      setSection('Studio Information');
    }

    if (section === 'verification' && section !== 'Verification') {
      setSection('Verification');
    }

    if (section === 'labs' && section !== 'Labs') {
      setSection('Labs');
    }

    if (section === 'payment' && section !== 'Payment') {
      setSection('Payment');
    }

    if (section === 'taxes' && section !== 'Taxes') {
      setSection('Taxes');
    }

    if (section === 'watermark' && section !== 'Logo and Watermark') {
      setSection('Logo and Watermark');
    }

    if (section === 'credits' && section !== 'Credits') {
      setSection('Credits');
    }

    if (section === 'users' && section !== 'Users') {
      setSection('Users');
    }
  };

  const handleToggleSidebar = () => {
    setSidebarIsOpen(!sidebarIsOpen);
  };

  useEffect(() => {
    setSectionTitle(location.pathname);
  }, []);

  return (
    <>
      <aside className="basis-3/12 md:basis-full sidebar-container">
        <header className="flex items-center justify-between sidebar-header">
          <h2 className="text-headline-sm">My Studio</h2>
          <button className="sidebar__mobile-action button button--outline" onClick={handleToggleSidebar}>
            {section}
          </button>
        </header>

        <nav className={`sidebar-navigation ${sidebarIsOpen ? 'sidebar-navigation--open' : ''}`} onClick={handleToggleSidebar}>
          {roles.includes('manage_studio_settings') && (
            <>
              <NavLink className="sidebar-navigation__item" activeClassName="sidebar-navigation__item--active" to={`/user/my-studio/information`}>
                Studio Information
              </NavLink>
              {isStudioVerificationRequired && (
                <NavLink className="sidebar-navigation__item" activeClassName="sidebar-navigation__item--active" to={`/user/my-studio/verification`}>
                  Verification
                </NavLink>
              )}
              <NavLink className="sidebar-navigation__item" activeClassName="sidebar-navigation__item--active" to={`/user/my-studio/labs`}>
                Labs
              </NavLink>
            </>
          )}
          {roles.includes('manage_stripe') && (
            <NavLink className="sidebar-navigation__item" activeClassName="sidebar-navigation__item--active" to={`/user/my-studio/payment`}>
              Payment
            </NavLink>
          )}
          {roles.includes('manage_studio_settings') && (
            <>
              <NavLink className="sidebar-navigation__item" activeClassName="sidebar-navigation__item--active" to={`/user/my-studio/taxes`}>
                Taxes
              </NavLink>
              <NavLink className="sidebar-navigation__item" activeClassName="sidebar-navigation__item--active" to={`/user/my-studio/watermark`}>
                Logo and Watermark
              </NavLink>
            </>
          )}
          {SERVICES_ENABLED && (
            <NavLink className="sidebar-navigation__item" activeClassName="sidebar-navigation__item--active" to={`/user/my-studio/wallet`}>
              Wallet
            </NavLink>
          )}
          {roles.includes('manage_users') && (
            <NavLink className="sidebar-navigation__item" activeClassName="sidebar-navigation__item--active" to={`/user/my-studio/users`}>
              Users
            </NavLink>
          )}
        </nav>
      </aside>

      <section className="basis-9/12 md:basis-full my-studio__section">
        <Switch>
          {roles.includes('manage_studio_settings') ? (
            <Redirect exact from="/user/my-studio" to="/user/my-studio/information" />
          ) : (
            <Redirect exact from="/user/my-studio" to="/user/my-studio/payment" />
          )}
          <PrivateRoute path="/user/my-studio/information" component={StudioInfo} requiredPermission={'manage_studio_settings'} />
          <PrivateRoute path="/user/my-studio/verification" component={StudioVerification} requiredPermission={'manage_studio_settings'} />
          <PrivateRoute path="/user/my-studio/labs" component={Labs} requiredPermission={'manage_studio_settings'} />
          <PrivateRoute path="/user/my-studio/payment" component={Payment} requiredPermission={'manage_stripe'} />
          <PrivateRoute path="/user/my-studio/taxes" component={StudioTaxes} requiredPermission={'manage_studio_settings'} />
          <PrivateRoute path="/user/my-studio/watermark" component={StudioWatermark} requiredPermission={'manage_studio_settings'} />
          <PrivateRoute path="/user/my-studio/wallet" component={StudioWallet} />
          <PrivateRoute path="/user/my-studio/users" component={StudioUsers} requiredPermission={'manage_users'} />
        </Switch>
      </section>
    </>
  );
};

export default connect(mapStateToProps)(MyStudio);
