import * as types from './constants';
import { handleErrorState, handleRequestingState } from '@/utils/reducerHelpers';

const initialState = {
  collections: [],
  pdCollections: [],
  collection: {},
  customThemeLabs: [],
  customThemeProducts: [],
  previewList: [],
  studioPagination: { page: 1, perPage: 15, total: 0 },
  pdPagination: { page: 1, perPage: 15, total: 0 },

  errors: [],
  requesting: false,
  successful: false
};

const handleGetStudioThemeCollectionsSuccess = (state, { payload }) => {
  return {
    ...state,
    collections: payload.data,
    studioPagination: {
      page: Number(payload.headers['x-page']),
      perPage: Number(payload.headers['x-per-page']),
      total: Number(payload.headers['x-total'])
    },
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetPhotoDayThemeCollectionsSuccess = (state, { payload }) => {
  return {
    ...state,
    pdCollections: payload.data,
    pdPagination: {
      page: Number(payload.headers['x-page']),
      perPage: Number(payload.headers['x-per-page']),
      total: Number(payload.headers['x-total'])
    },
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetAllCustomThemeLabsSuccess = (state, { payload }) => {
  return {
    ...state,
    customThemeLabs: payload.data,
    customThemeProducts: [],
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetLabCustomThemeProductsSuccess = (state, { payload }) => {
  return {
    ...state,
    customThemeProducts: payload.data,
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleCreateThemeCollectionSuccess = (state, { payload }) => {
  return {
    ...state,
    collections: [...state.collections, payload.data],
    collection: payload.data,
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetThemeCollectionSuccess = (state, { payload }) => {
  return {
    ...state,
    collection: payload.data,
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleUpdateThemeCollectionSuccess = (state, { payload }) => {
  let newCollections = state.collections;

  newCollections = newCollections.map((collection) => {
    if (collection.id === payload.data.id) {
      collection.name = payload.data.name;
      collection.themes_count = payload.data.themes_count;
    }
    return collection;
  });

  return {
    ...state,
    collections: newCollections,
    collection: payload.data,
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleDeleteThemeCollectionSuccess = (state, { payload }) => {
  let newCollections = state.collections;
  const { collectionId } = payload.payload;

  if (collectionId) {
    newCollections = newCollections.filter((collection) => collection.id !== collectionId);
  }

  return {
    ...state,
    collections: newCollections,
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetCustomThemeProductPreviewSuccess = (state, { payload }) => {
  const { data: payloadPreview } = payload;
  const { previewList: statePreviewList } = state;

  return {
    ...state,
    previewList: payloadPreview ? [...statePreviewList, payloadPreview] : [...statePreviewList],
    errors: [],
    successful: true,
    requesting: false
  };
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_STUDIO_THEME_COLLECTIONS_REQUEST:
    case types.GET_ALL_CUSTOM_THEME_LABS_REQUEST:
    case types.GET_LAB_CUSTOM_THEME_PRODUCTS_REQUEST:
    case types.CREATE_THEME_COLLECTION_REQUEST:
    case types.DELETE_THEME_COLLECTION_REQUEST:
    case types.GET_THEME_COLLECTION_REQUEST:
    case types.UPDATE_THEME_COLLECTION_REQUEST:
    case types.GET_CUSTOM_THEME_PRODUCT_PREVIEW_REQUEST:
    case types.GET_PHOTODAY_THEME_COLLECTIONS_REQUEST:
    case types.GET_PHOTODAY_THEME_COLLECTIONS_BY_LAB_REQUEST:
      return handleRequestingState(state);

    case types.CREATE_THEME_COLLECTION_SUCCESS:
      return handleCreateThemeCollectionSuccess(state, payload);

    case types.GET_THEME_COLLECTION_SUCCESS:
      return handleGetThemeCollectionSuccess(state, payload);

    case types.UPDATE_THEME_COLLECTION_SUCCESS:
      return handleUpdateThemeCollectionSuccess(state, payload);

    case types.DELETE_THEME_COLLECTION_SUCCESS:
      return handleDeleteThemeCollectionSuccess(state, payload);

    case types.GET_STUDIO_THEME_COLLECTIONS_SUCCESS:
      return handleGetStudioThemeCollectionsSuccess(state, payload);

    case types.GET_PHOTODAY_THEME_COLLECTIONS_SUCCESS:
    case types.GET_PHOTODAY_THEME_COLLECTIONS_BY_LAB_SUCCESS:
      return handleGetPhotoDayThemeCollectionsSuccess(state, payload);

    case types.GET_ALL_CUSTOM_THEME_LABS_SUCCESS:
      return handleGetAllCustomThemeLabsSuccess(state, payload);

    case types.GET_LAB_CUSTOM_THEME_PRODUCTS_SUCCESS:
      return handleGetLabCustomThemeProductsSuccess(state, payload);

    case types.GET_CUSTOM_THEME_PRODUCT_PREVIEW_SUCCESS:
      return handleGetCustomThemeProductPreviewSuccess(state, payload);

    case types.GET_STUDIO_THEME_COLLECTIONS_ERROR:
    case types.GET_ALL_CUSTOM_THEME_LABS_ERROR:
    case types.GET_LAB_CUSTOM_THEME_PRODUCTS_ERROR:
    case types.CREATE_THEME_COLLECTION_ERROR:
    case types.DELETE_THEME_COLLECTION_ERROR:
    case types.UPDATE_THEME_COLLECTION_ERROR:
    case types.GET_THEME_COLLECTION_ERROR:
    case types.GET_CUSTOM_THEME_PRODUCT_PREVIEW_ERROR:
    case types.GET_PHOTODAY_THEME_COLLECTIONS_ERROR:
    case types.GET_PHOTODAY_THEME_COLLECTIONS_BY_LAB_ERROR:
      return handleErrorState(state, payload);

    default:
      return state;
  }
};

export default reducer;
