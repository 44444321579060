import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

// Plugins
import Select from 'react-select';
import { Tooltip } from 'react-tippy';

// Redux
import { createPeopleAssociationPhotos, deletePhotoPeople } from '../../../actions';
import { useSelector, useDispatch } from 'react-redux';

// Styles
import './style.css';

function PeopleManager({ photo, onPeopleManagerShow, onPhotosPeopleFilter }) {
  const dispatch = useDispatch();

  const {
    job,
    people: { list: people }
  } = useSelector((state) => state.jobs);

  const [options, setOptions] = useState([]);
  const [selectInput, setSelectInput] = useState('');
  const [peopleRequesting, setPeopleRequesting] = useState(false);

  const [selectPeople, setSelectPeople] = useState([]);
  const [initialMatchedPeople, setInitialMatchedPeople] = useState([]);
  const [initialMatchedRemovePeople, setInitialMatchedRemovePeople] = useState([]);

  const createOptions = () => {
    const options = people
      .filter((person) => !selectPeople.some((selectPerson) => selectPerson.value === person.id))
      .map((person) => ({ value: person.id, label: `${person.last_name}, ${person.first_name}` }));

    setOptions(options);
  };

  const handleInputChange = (input) => {
    setSelectInput(input);
  };

  const handlePeopleChange = (person = []) => {
    const selectPeopleArray = selectPeople && selectPeople.length > 0 ? selectPeople : [];
    const personArray = person && person.length > 0 ? person : [];

    const newPeople = [...selectPeopleArray, ...personArray].sort(function (a, b) {
      let labelA = a.label.toUpperCase();
      let labelB = b.label.toUpperCase();

      if (labelA < labelB) {
        return -1;
      }

      if (labelA > labelB) {
        return 1;
      }

      return 0;
    });

    setSelectPeople(newPeople);

    if (personArray.length && initialMatchedPeople.find((initial) => initial.value === personArray[0].value)) {
      setInitialMatchedRemovePeople(initialMatchedRemovePeople.filter((removed) => removed !== personArray[0].value));
    }
  };

  const handleSelectPeopleRemove = (person) => {
    setSelectPeople(selectPeople.filter((current) => current.value !== person));

    if (initialMatchedPeople.some((initial) => initial.value === person)) {
      setInitialMatchedRemovePeople([...initialMatchedRemovePeople, person]);
    }
  };

  const handleAddPhotoToPeople = () => {
    const peopleChanged = JSON.stringify(initialMatchedPeople) !== JSON.stringify(selectPeople);

    if (peopleChanged) {
      setPeopleRequesting(true);

      const peopleSelected = selectPeople
        .filter((person) => !initialMatchedPeople.find((initial) => initial.value === person.value))
        .map((person) => person.value);
      const peopleRemoved = initialMatchedRemovePeople.map((person) => person);

      if (peopleSelected.length) {
        dispatch(
          createPeopleAssociationPhotos({ id: job.id, photo_ids: [photo.id], subject_ids: peopleSelected }, () => {
            if (peopleRemoved.length) {
              dispatch(deletePhotoPeople({ id: photo.id, subject_ids: peopleRemoved }));
              onPeopleManagerShow(photo.id);
            } else {
              onPeopleManagerShow(photo.id);
            }
          })
        );
      } else if (peopleRemoved.length) {
        dispatch(deletePhotoPeople({ id: photo.id, subject_ids: peopleRemoved }));
        onPeopleManagerShow(photo.id);
      }
    } else {
      typeof onPeopleManagerShow === 'function' && onPeopleManagerShow(photo.id);
    }
  };

  const handleSelectPhotosPeopleFilter = (person) => {
    const foundPerson = people.find((listPerson) => person.value === listPerson.id);

    if (foundPerson) {
      onPhotosPeopleFilter(foundPerson);
    }
  };

  useEffect(() => {
    if (photo) {
      const currentPeople = people
        .filter((person) => photo.subject_ids && photo.subject_ids.includes(person.id))
        .map((person) => ({ value: person.id, label: `${person.last_name}, ${person.first_name}` }));

      setSelectPeople(currentPeople);
      setInitialMatchedPeople(currentPeople);
    }
  }, []);

  useEffect(() => {
    createOptions();
  }, [selectPeople]);

  return (
    <aside className="people-manager animate" key={photo.id}>
      <main className="people-manager__main">
        <Select
          className="select people-manager__select"
          classNamePrefix="select"
          isMulti={true}
          value={[]}
          options={options}
          inputValue={selectInput}
          onInputChange={handleInputChange}
          onChange={(person) => handlePeopleChange(person)}
          placeholder="Add a person and hit Enter"
        />

        <div className="capitalize people-manager__tag-cloud">
          {selectPeople.length > 0 &&
            selectPeople.map((person) => (
              <div key={person.value}>
                <small className="pill pill--tag">
                  <Tooltip
                    title={person.label}
                    position="bottom"
                    arrow={false}
                    delay={400}
                    distance="18"
                    disabled={person && person.label && person.label.length > 15 ? false : true}
                    zIndex={10000}
                  >
                    <div className="people-manager__tag-label" onClick={() => handleSelectPhotosPeopleFilter(person)}>
                      {person.label}
                    </div>
                  </Tooltip>

                  <button className="people-manager__tag-remove" type="button" onClick={() => handleSelectPeopleRemove(person.value)}>
                    <i className="icon-close-light icon-close-light--small"></i>
                  </button>
                </small>
                <br />
              </div>
            ))}
        </div>
      </main>

      <footer className="flex items-center justify-center people-manager__footer ">
        <button
          className="button button--outline people-manager__button"
          name="button"
          type="button"
          onClick={handleAddPhotoToPeople}
          data-loading-dark={peopleRequesting}
          disabled={peopleRequesting}
        >
          Update
        </button>
      </footer>
    </aside>
  );
}

PeopleManager.propTypes = {
  photo: PropTypes.object.isRequired,
  onPeopleManagerShow: PropTypes.func.isRequired,
  onPhotosPeopleFilter: PropTypes.func.isRequired
};

PeopleManager.defaultProps = {
  photo: {},
  onPeopleManagerShow: () => {},
  onPhotosPeopleFilter: () => {}
};

export default PeopleManager;
