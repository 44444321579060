export const emailValidate = (email) => {
  const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  return emailPattern.test(email);
};

export const integerValidate = (value) => {
  const integerPattern = /^-?(0|([1-9][0-9]*))$/;
  return integerPattern.test('' + value);
};

export const stringSpecialCharactersValidator = (name) => {
  const specialChars = /[`!@#$%^&*()_+\-=[\]{};:"\\|,.<>/?~]/;
  return specialChars.test(name);
};

export const urlSlugValidator = (value = '') => {
  const integerPattern = /^[a-zA-Z0-9](-?[a-zA-Z0-9])*$/;
  return integerPattern.test(value);
};

export const phoneNumberValidator = (value) =>
  value && !/^(?:\+?1[ .*-]?)?(?:\(? ?)?\d{3}(?: ?\)?)? ?(?:\*|(?:\.|-){1,2})? ?\d{3} ?(?:\*|(?:\.|-){1,2})? ?\d{4}$/.test(value) ? true : false;
