import { Component } from 'react';
import { string, array, bool, shape, object } from 'prop-types';

// Plugins
import queryString from 'query-string';

// Redux
import { connect } from 'react-redux';
import { getOffersDDLRequest } from '@/components/Shared/OffersDDL/actions';
import { getStudioPromotion, createStudioPromotion, updateStudioPromotion, getPhotoDayPromotionList, getStudioPromotionJobList } from './actions';

// Plugins
import moment from 'moment';

// Components
import PromosCurrent from './Current';
import PromosPast from './Past';
import PromosCustom from './Custom';

// Helpers
import { pickerDate, convertToUTC } from '@/utils/displayFormats';

// Style
import './promos.css';

const SEASONAL = 'Seasonal';
const PAST = 'Past';
const CUSTOM = 'Custom';
const CUSTOM_SMS_PROMOS_ENABLED = import.meta.env.VITE_CUSTOM_SMS_PROMOS_ENABLED === '1';

const mapStateToProps = (state) => {
  const {
    login: {
      studio: { id: studioId },
      user
    },
    offersDDL: { options: optionsOffers, requesting: studioOffersRequesting },
    promos: { jobs, promos: studioPromos, promotions: photoDayPromotions, requesting: promosRequesting }
  } = state;
  const userPermissions = (user && user.roles) || [];
  const studioJobs = jobs ? jobs.sort((a, b) => a.name.localeCompare(b.name)) : [];
  const studioOffers = optionsOffers.sort((a, b) => a.label.localeCompare(b.label));

  return {
    studioId,
    studioJobs,
    studioPromos,
    studioOffers,
    userPermissions,
    promosRequesting,
    photoDayPromotions,
    studioOffersRequesting
  };
};

const mapDispatchToProps = {
  getOffersDDLRequest,
  getStudioPromotion,
  createStudioPromotion,
  updateStudioPromotion,
  getPhotoDayPromotionList,
  getStudioPromotionJobList
};

class Promos extends Component {
  state = {
    section: CUSTOM,
    newSection: undefined,
    request: undefined,
    promo: undefined,
    promotion: {},
    jobs: {
      select: [],
      add: []
    },
    dateFilteredJobs: [],
    offer: '',
    audience: null,
    sms: '',
    currentJob: undefined,
    currentOffer: undefined,
    startDate: undefined,
    endDate: undefined,
    isCustom: false,
    showDialog: false
  };

  static propTypes = {
    studioId: string,
    studioJobs: array,
    studioPromos: shape({ list: array, pagination: object }),
    studioOffers: array,
    promosRequesting: bool,
    photoDayPromotions: shape({ list: array, pagination: object }),
    studioOffersRequesting: bool
  };

  static defaultProps = {
    studioId: '',
    studioJobs: [],
    studioPromos: {
      current: { list: [], pagination: {} },
      past: { list: [], pagination: {} },
      custom: { list: [], pagination: {} }
    },
    studioOffers: [],
    promosRequesting: true,
    photoDayPromotions: {
      non_custom: { list: [], pagination: {} },
      custom: { list: [], pagination: {} }
    },
    studioOffersRequesting: true
  };

  componentDidMount() {
    const propLocation = this.props.location;
    const { fromJobId } = propLocation.state || {};
    const { section } = queryString.parse(propLocation.search);
    const isCustom = section === CUSTOM;

    if (section) {
      this.setState({ section, isCustom });
    }

    // PhotoDay custom promotions
    this.props.getPhotoDayPromotionList({ custom: true }, () => {
      // PhotoDay promotions
      this.props.getPhotoDayPromotionList(null, () => {
        // Set default Live promotion
        const promotions = isCustom ? this.props.photoDayPromotions?.custom?.list : this.props.photoDayPromotions?.non_custom?.list;
        const promotion = promotions?.find((promotion) => (promotion.end_at ? moment() <= moment(promotion.end_at) : true)) || {};
        const sms = promotion && promotion.promotion_messages && promotion.promotion_messages[0]?.id;

        // Studio promotion past
        this.props.getStudioPromotion({ archived: true, ...this.props.studioPromos?.past?.pagination }, () => {
          // Studio promotion custom
          this.props.getStudioPromotion({ custom: true }, () => {
            // Studio promotion current and populate Live promotion
            this.props.getStudioPromotion(null, () => {
              const promos = isCustom ? this.props.studioPromos?.custom?.list : this.props.studioPromos?.current?.list;
              const promo = promos?.find((promo) => promo.promotion?.id === promotion.id);

              if (promo) {
                this.setState({
                  promo,
                  offer: !isCustom ? promo?.offer?.id : '',
                  jobs: Object.assign({}, this.state.jobs, {
                    select: !isCustom ? promo.job_ids : [],
                    add: !isCustom ? promo.job_ids : []
                  }),
                  startDate: promo.start_at ? convertToUTC(promo.start_at) : undefined,
                  endDate: promo.end_at ? convertToUTC(promo.end_at) : undefined
                });
              }
            });

            this.setState({ promotion: !isCustom ? promotion : undefined, sms });

            // Fetch studio promotion job list
            this.props.getStudioPromotionJobList(null, () => {
              // Auto add Job if from Promo inner job link
              if (fromJobId) {
                this.setState((prevState) => {
                  if (prevState.jobs) {
                    return {
                      jobs: { ...prevState.jobs, select: [...prevState.jobs.select, fromJobId], add: [...prevState.jobs.add, fromJobId] }
                    };
                  }
                });
              }
            });
          });
        });
      });
    });

    // Studio offers
    this.props.getOffersDDLRequest();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.jobs.add !== this.state.jobs.add) {
      this.handleFirstJobGet(this.state.jobs.add[0]);
    }

    if (prevState.offer !== this.state.offer) {
      if (this.state.offer) {
        this.handleCurrentOfferGet(this.state.offer);
      } else {
        this.setState({ currentOffer: undefined });
      }
    }

    if (prevProps.studioOffers !== this.props.studioOffers) {
      if (this.props.studioOffers.length && this.state.offer) {
        this.handleCurrentOfferGet(this.state.offer);
      }
    }

    if (prevProps.studioJobs !== this.props.studioJobs) {
      this.setState({ dateFilteredJobs: this.props.studioJobs });
    }
  };

  handleSectionChange = (e, section) => {
    const { isCustom, request, promotion, jobs, startDate, endDate } = this.state;

    e.preventDefault();
    window.scroll(0, 0);

    if (request && ((isCustom && promotion) || !!jobs.select.length || startDate || endDate)) {
      this.setState({ newSection: section }, () => {
        this.handleShowDialog(true);
      });
    } else {
      this.setState({ section, request: undefined }, () => {
        this.handleConfirmDialog();
      });
    }
  };

  handleConfirmDialog = () => {
    const { section, newSection } = this.state;
    const isCustom = newSection ? newSection === CUSTOM : section === CUSTOM;

    // Set default Live promotion
    const promotions = isCustom ? this.props.photoDayPromotions?.custom?.list : this.props.photoDayPromotions?.non_custom?.list;
    const promotion = promotions?.find((promotion) => (promotion.end_at ? moment() <= moment(promotion.end_at) : true)) || {};
    const sms = promotion && promotion.promotion_messages && promotion.promotion_messages[0]?.id;
    const promos = isCustom ? this.props.studioPromos?.custom?.list : this.props.studioPromos?.current?.list;
    const promo = promos?.find((promo) => promo.promotion?.id === promotion.id);

    if (newSection) {
      this.setState({ section: newSection, newSection: undefined, request: undefined });
    }

    if (promo) {
      this.setState({
        promo,
        offer: !isCustom ? promo?.offer?.id : '',
        jobs: Object.assign({}, this.state.jobs, {
          select: !isCustom ? promo.job_ids : [],
          add: !isCustom ? promo.job_ids : []
        }),
        startDate: !isCustom && promo.start_at ? convertToUTC(promo.start_at) : undefined,
        endDate: !isCustom && promo.end_at ? convertToUTC(promo.end_at) : undefined
      });
    }
    this.setState({ isCustom, promotion: !isCustom ? promotion : undefined, sms });

    this.handleShowDialog(false);

    this.props.history.push({ search: `?section=${section}` });
  };

  handleCancelDialog = () => {
    this.setState({ section: CUSTOM }, () => {
      this.handleShowDialog(false);
    });
  };

  handleShowDialog = (showDialog) => {
    this.setState({ showDialog });
  };

  handleRequestChange = (e, newRequest) => {
    const { isCustom, request, promotion, jobs, startDate, endDate } = this.state;

    e.preventDefault();
    window.scroll(0, 0);

    if (request && ((isCustom && promotion) || !!jobs.select.length || startDate || endDate)) {
      this.setState({ newSection: CUSTOM }, () => {
        this.handleShowDialog(true);
      });
    } else {
      this.setState({ request: newRequest, startDate: undefined, endDate: undefined, promotion: undefined }, () => {
        this.handleConfirmDialog();
      });
    }
  };

  handleStartDateChange = (startDate) => {
    this.setState({ startDate });
  };

  handleEndDateChange = (endDate) => {
    this.setState({ endDate });
  };

  handleRequestCustomPromo = (e) => {
    this.handleSectionChange(e, CUSTOM);
    this.handleRequestChange(e, CUSTOM);
  };

  handlePromotionChange = (e) => {
    const value = e ? e.value : '';
    const promotions = this.state.isCustom ? this.props.photoDayPromotions?.custom?.list : this.props.photoDayPromotions?.non_custom?.list;
    const promotion = promotions?.find((promotion) => promotion.id === value) || {};
    const sms = promotion && promotion.promotion_messages && promotion.promotion_messages[0]?.id;
    const promos = this.state.isCustom ? this.props.studioPromos?.custom?.list : this.props.studioPromos?.current?.list;
    const promo = promos?.find((promo) => promo.promotion?.id === value);

    if (promo && !this.state.isCustom) {
      this.setState({
        promo,
        promotion,
        offer: promo?.offer?.id,
        jobs: { select: [], add: promo.job_ids },
        startDate: promo.start_at ? convertToUTC(promo.start_at) : undefined,
        endDate: promo.end_at ? convertToUTC(promo.end_at) : undefined,
        sms
      });
    } else {
      this.setState({
        promo: undefined,
        promotion,
        offer: '',
        jobs: { select: [], add: [] },
        startDate: undefined,
        endDate: undefined,
        sms
      });
    }
  };

  handleRefreshOffers = () => this.props.getOffersDDLRequest();

  handleJobDateFilter = (filteredJobs) => this.setState({ dateFilteredJobs: filteredJobs });

  handleJobChange = (e) => {
    const job = e.target.dataset.job;
    const {
      dateFilteredJobs,
      jobs: { select }
    } = this.state;

    let selection;

    if (job === 'all') {
      if (select.length === dateFilteredJobs.length) {
        selection = [];
      } else {
        selection = dateFilteredJobs.map((job) => job.id);
      }
    } else {
      let active = select.some((select) => select === job);

      if (active) {
        selection = select.filter((select) => select !== job);
      } else {
        const unsorted = [...select, job].map((select) => dateFilteredJobs?.find((job) => job.id === select));
        selection = unsorted.sort((a, b) => a.name.localeCompare(b.name)).map((select) => select && select.id);
      }
    }

    this.setState({
      jobs: Object.assign({}, this.state.jobs, { select: selection })
    });
  };

  handleJobAdd = () => {
    this.setState({
      jobs: Object.assign({}, this.state.jobs, { add: this.state.jobs.select })
    });
  };

  handleJobRemove = (e) => {
    this.setState({
      jobs: Object.assign({}, this.state.jobs, {
        select: [],
        add: this.state.jobs.add.filter((job) => job !== e.target.dataset.job)
      })
    });
  };

  handleFirstJobGet = (jobId) => {
    if (jobId) {
      const firstJob = this.props.studioJobs?.find((job) => job.id === jobId);
      firstJob && this.setState({ firstJob });
    } else {
      this.setState({ firstJob: undefined });
    }
  };

  handleCurrentOfferGet = (offerId) => {
    if (offerId && this.props.studioOffers.length) {
      const currentOffer = this.props.studioOffers?.find((offer) => offer.value === this.state.offer);
      this.setState({ currentOffer });
    }
  };

  handleOfferChange = (e) => {
    const value = e ? e.value : '';
    this.setState({ offer: value });
  };

  handleAudienceChange = (e) => {
    const value = e ? e.value : '';
    this.setState({ audience: value });
  };

  handleSmsChange = (e) => {
    const value = e.target.dataset.sms;

    if (value) {
      this.setState({ sms: value });
    }
  };

  handleSave = (enable) => {
    let func;
    let payload;

    if (this.state.promo) {
      payload = {
        enable,
        id: this.state.promo.id,
        offer: this.state.offer,
        audience: this.state.audience,
        jobs: this.state.jobs.add
      };
      func = this.props.updateStudioPromotion;
    } else {
      payload = {
        enable,
        offer: this.state.offer,
        audience: this.state.audience,
        jobs: this.state.jobs.add,
        promotion: this.state.promotion.id,
        studio: this.props.studioId
      };
      func = this.props.createStudioPromotion;
    }

    if (this.state.startDate) {
      payload.startDate = pickerDate(this.state.startDate);
    }

    if (this.state.endDate) {
      payload.endDate = pickerDate(this.state.endDate);
    }

    func(payload, ({ data }) => {
      const { section, request } = this.state;
      const newCustomPromoRequest = section === CUSTOM && request === section;

      if (newCustomPromoRequest) {
        // Studio promotion custom
        this.props.getStudioPromotion({ custom: true }, () => {
          const promotion = this.props.photoDayPromotions?.custom?.list.find((promotion) => promotion.id === data.promotion_id) || {};
          const sms = promotion && promotion.promotion_messages && promotion.promotion_messages[0]?.id;

          this.setState({
            promo: undefined,
            promotion,
            offer: '',
            audience: 'without_orders',
            jobs: { select: [], add: [] },
            startDate: undefined,
            endDate: undefined,
            sms,
            request: undefined
          });
        });
      } else {
        this.setState({ promo: data, audience: 'all' });
      }
    });
  };

  handleToggleSidebar = () => {
    this.setState({ sidebarIsOpen: !this.state.sidebarIsOpen });
  };

  render() {
    return (
      <section className="flex promos">
        {/* Sidebar navigation */}
        <aside className="basis-3/12 md:basis-full sidebar-container">
          <header className="flex items-center justify-between sidebar-header">
            <h2 className="text-headline-sm">Promos</h2>
            <button className="sidebar__mobile-action button button--outline" onClick={this.handleToggleSidebar}>
              {this.state.section}
            </button>
          </header>
          <nav className={`sidebar-navigation ${this.state.sidebarIsOpen ? 'sidebar-navigation--open' : ''}`} onClick={this.handleToggleSidebar}>
            {CUSTOM_SMS_PROMOS_ENABLED && (
              <button
                className={`button--link sidebar-navigation__item ${this.state.section === CUSTOM ? 'sidebar-navigation__item--active' : ''}`}
                onClick={(e) => this.handleSectionChange(e, CUSTOM)}
              >
                {CUSTOM}
              </button>
            )}
            {this.props.userPermissions.includes('manage_promos') && (
              <button
                className={`button--link sidebar-navigation__item ${this.state.section === SEASONAL ? 'sidebar-navigation__item--active' : ''}`}
                onClick={(e) => this.handleSectionChange(e, SEASONAL)}
              >
                Seasonal
              </button>
            )}
            <button
              className={`button--link sidebar-navigation__item ${this.state.section === PAST ? 'sidebar-navigation__item--active' : ''}`}
              onClick={(e) => this.handleSectionChange(e, PAST)}
            >
              History
            </button>
          </nav>
        </aside>

        {/* Current promos */}
        <PromosCurrent
          section={this.state.section}
          request={this.state.request}
          audience={this.state.audience}
          promos={{
            all: this.state.isCustom ? this.props.studioPromos?.custom : this.props.studioPromos?.current,
            select: !this.state.isCustom ? this.state.promo : undefined
          }}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          sms={this.state.sms}
          jobs={{ all: this.props.studioJobs, ...this.state.jobs }}
          offers={{
            all: this.props.studioOffers,
            select: this.state.offer,
            requesting: this.props.studioOffersRequesting
          }}
          promotions={{
            all: this.state.isCustom ? this.props.photoDayPromotions?.custom : this.props.photoDayPromotions?.non_custom,
            select: this.state.promotion,
            requesting: this.props.promosRequesting
          }}
          dateFilteredJobs={this.state.dateFilteredJobs}
          firstJob={this.state.firstJob}
          currentOffer={this.state.currentOffer}
          onPromotionChange={this.handlePromotionChange}
          onRefreshOffers={this.handleRefreshOffers}
          onRequestChange={this.handleRequestChange}
          onJobDateFilter={this.handleJobDateFilter}
          onJobChange={this.handleJobChange}
          onJobAdd={this.handleJobAdd}
          onJobRemove={this.handleJobRemove}
          onOfferChange={this.handleOfferChange}
          onAudienceChange={this.handleAudienceChange}
          onSmsChange={this.handleSmsChange}
          onSave={this.handleSave}
          onStartDateChange={this.handleStartDateChange}
          onEndDateChange={this.handleEndDateChange}
          onRequestCustomPromo={this.handleRequestCustomPromo}
        />

        {/* Past promos */}
        <PromosPast section={this.state.section} promos={this.props.studioPromos.past} />

        {CUSTOM_SMS_PROMOS_ENABLED && (
          <>
            {/* Custom promos */}
            <PromosCustom
              section={this.state.section}
              request={this.state.request}
              jobs={this.props.studioJobs}
              promos={this.props.studioPromos.custom}
              onRequestChange={this.handleRequestChange}
              promosRequesting={this.props.promosRequesting}
            />
            {/* Lose progress modal */}
            <aside className={`modal ${this.state.showDialog ? '' : 'transparent'} text-left`}>
              <div className="modal__box modal__box--dark modal__box--small modal__box--nomin">
                <header className="modal__header">
                  <button className="button button--action modal__close" name="close" type="button" onClick={this.handleCancelDialog}>
                    <i className="icon-close"></i>
                  </button>
                  <h3>Warning</h3>
                </header>
                <main className="modal__content">
                  <p>All unsaved data will be lost. Do you want to continue?</p>
                </main>
                <footer className="text-center modal__footer button-group">
                  <button className="button button--medium" name="yes" type="button" onClick={this.handleConfirmDialog}>
                    Yes
                  </button>
                  <button className="button button--outline button--medium" name="no" type="button" onClick={this.handleCancelDialog}>
                    No
                  </button>
                </footer>
              </div>
            </aside>
          </>
        )}
      </section>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Promos);
