import { useEffect, useState } from 'react';

// Plugins
import { Tooltip } from 'react-tippy';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { getPeopleList, getSubject, updatePeople } from '../../../actions';

// Components
import FeatureManager from '../../People/FeaturedManager';
import GridLoader from '@/components/Shared/ContentLoader/GridLoader';

// Helpers
import imageScaling from '@/utils/imageScaling';

const SubjectFeatureGrid = ({ jobId, people, filterPhotos, onPhotosPeopleFilter }) => {
  const dispatch = useDispatch();

  const {
    job,
    people: { requesting: peopleRequesting },
    query: { people: peopleQuery }
  } = useSelector((state) => state.jobs);

  const [filteredPeople, setFilteredPeople] = useState([]);
  const [showFeatureManager, setShowFeatureManager] = useState(false);
  const [subjectSelected, setSubjectSelected] = useState();

  const handleToggleFeatureManager = (person) => {
    if (person) {
      dispatch(
        getSubject({ id: person.id, with_photos: true }, ({ data }) => {
          setSubjectSelected(data);
        })
      );
      setShowFeatureManager(true);
    } else {
      setSubjectSelected();
      setShowFeatureManager(false);
    }
  };

  const handleFeatureSelect = ({ photoId, moveToNext = false }) => {
    function moveOrCloseModal() {
      if (moveToNext) {
        const currentSubjectIndex = filteredPeople.findIndex((person) => person.id === subjectSelected.id);

        if (typeof currentSubjectIndex === 'number') {
          const nextSubject = currentSubjectIndex === filteredPeople.length - 1 ? filteredPeople[0] : filteredPeople[currentSubjectIndex + 1];

          if (nextSubject) {
            handleToggleFeatureManager(nextSubject);
          }
        }
      } else {
        handleToggleFeatureManager();
      }
    }

    // Check if its already subject feature photo, otherwise just dismiss modal
    if (subjectSelected.primary_photo_id !== photoId) {
      dispatch(updatePeople({ id: subjectSelected.id, primary_photo_id: photoId }, () => moveOrCloseModal()));
    } else {
      moveOrCloseModal();
    }
  };

  useEffect(() => {
    dispatch(getPeopleList({ id: job.id || jobId, per_page: 10000, order: 'last_name', dir: 'asc' }));
  }, []);

  useEffect(() => {
    if (filterPhotos === 'featured') {
      setFilteredPeople(
        people.filter((person) => {
          // All subjects
          if (peopleQuery === 'featured' || peopleQuery === 'subjectsAll') {
            return person;
          }

          // Subjects with a featured photo
          if (peopleQuery === 'subjectsWith') {
            return person.primary_photo_id;
          }

          // Subjects with no featured photo
          if (peopleQuery === 'subjectsWithout') {
            return person.primary_photo_id === null;
          }

          // Subjects with matched photo(s) and no featured photo
          if (peopleQuery === 'subjectsMatchedWithout') {
            return person.photos_count > 0 && person.primary_photo_id === null;
          }

          return false;
        })
      );
    }
  }, [people, peopleQuery, filterPhotos]);

  return (
    <div className="job-feature-grid">
      {filteredPeople.length ? (
        <div className="grid gap-5 animate job-feature-grid__row">
          {filteredPeople.map((person) => (
            <div className="job-feature-grid__item" key={person.id} onClick={() => handleToggleFeatureManager(person)}>
              <figure>
                <div className="job-feature-grid__image-header">
                  <span className="job-feature-grid__image-name">
                    <Tooltip
                      title={person.primary_photo?.image_filename}
                      arrow={false}
                      distance="18"
                      position="top"
                      delay={300}
                      touchHold="true"
                      disabled={!person.primary_photo}
                    >
                      {person.photos_count && person.primary_photo ? person.primary_photo?.image_filename : `${person.photos_count} photos available`}
                    </Tooltip>
                  </span>
                </div>

                <div className={`job-feature-grid__image-container ${person.primary_photo?.processing ? 'job-feature-grid__image-container--processing' : ''}`}>
                  <LazyLoadImage
                    className="job-feature-grid__image"
                    src={imageScaling({ url: person.primary_photo ? person.primary_photo.image_url : null, size: 'large' })}
                    alt={person.session_photo_filename}
                    effect="opacity"
                    draggable="false"
                  />
                </div>

                <div className="job-feature-grid__image-footer">
                  <span className="job-feature-grid__image-name">
                    <Tooltip
                      title={person ? `${person.first_name} ${person.last_name}` : ''}
                      arrow={false}
                      distance="18"
                      position="top"
                      delay={300}
                      touchHold="true"
                    >
                      {`${person.first_name} ${person.last_name}`}
                    </Tooltip>
                  </span>
                </div>
              </figure>
            </div>
          ))}
        </div>
      ) : (
        <>
          {peopleRequesting ? (
            <GridLoader rows={2} columns={5} gap={20} minHeight={225} />
          ) : (
            <aside className="flex items-center justify-center panel panel--secondary panel--tall animate">
              <h3 className="m-0">{`${
                peopleQuery === 'subjectsWithout'
                  ? 'All subjects have feature photos.'
                  : peopleQuery === 'subjectsWith'
                    ? 'No subjects have feature photos.'
                    : 'No subjects were found.'
              }`}</h3>
            </aside>
          )}
        </>
      )}

      {showFeatureManager && (
        <FeatureManager
          imageTitle="feature photo"
          subject={subjectSelected}
          requesting={peopleRequesting}
          onSelect={handleFeatureSelect}
          onClose={() => handleToggleFeatureManager()}
          onPhotosPeopleFilter={onPhotosPeopleFilter ? onPhotosPeopleFilter : null}
        />
      )}
    </div>
  );
};

export default SubjectFeatureGrid;
