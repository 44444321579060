import { call, put, takeLatest, all } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import pricesheetSchema from './schema';
import * as api from '@/api/pricesheet-api';
import * as itemApi from '@/api/pricesheet-item-api';
import * as labApi from '@/api/lab-api';
import errorHandler from '@/utils/errorResponseHandler';
import successHandler from '@/utils/successResponseHandler';

import * as types from './constants';
import * as actions from './actions';
import parsePriceSheetListResponse from '@/utils/responseParsers';

function* getPricesheetListRequestFlow(action) {
  try {
    yield put(showLoading());

    const { callback } = action;
    const { page, perPage, search, order, dir } = action.payload;
    const response = yield call(api.pricesheetList, page, perPage, search, order, dir);
    const payload = parsePriceSheetListResponse(response, pricesheetSchema, order, dir, search);

    yield put(actions.getPricesheetListRequestSuccess(payload));

    if (callback) callback(payload);
  } catch (error) {
    yield call(errorHandler, actions.getPricesheetListRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getPricesheetRequestFlow(payload) {
  const { callback } = payload;
  const pricesheetId = payload.payload;
  try {
    yield put(showLoading());

    const response = yield call(api.pricesheet, pricesheetId);

    yield put(actions.getPricesheetRequestSuccess(response));

    if (callback) yield call(callback, response.data);
  } catch (error) {
    yield call(errorHandler, actions.getPricesheetRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* createPricesheetRequestFlow(payload) {
  try {
    yield put(showLoading());

    const { callback } = payload;
    const { data: createdPricesheet } = yield call(api.createPricesheet, payload);
    yield call(successHandler('Successfully created'), actions.createPricesheetRequestSuccess, {
      pricesheet: createdPricesheet
    });
    if (callback) yield call(callback, createdPricesheet);
  } catch (error) {
    yield call(errorHandler, actions.createPricesheetRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* updatePricesheetRequestFlow(payload) {
  const { callback } = payload;

  try {
    yield put(showLoading());

    const { data: updatedPricesheet } = yield call(api.updatePricesheet, payload);

    yield call(successHandler('Successfully saved'), actions.updatePricesheetRequestSuccess, {
      pricesheet: updatedPricesheet
    });

    if (callback) yield call(callback, updatedPricesheet);
  } catch (error) {
    yield call(errorHandler, actions.updatePricesheetRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* deletePricesheetRequestFlow({ priceSheetId, force, callback }) {
  try {
    yield put(showLoading());

    const response = yield call(api.deletePricesheet, priceSheetId, force);

    yield put(actions.deletePricesheetRequestSuccess({ ...response, priceSheetId }));

    if (callback) callback({ ...response, priceSheetId });
  } catch (error) {
    yield call(errorHandler, actions.deletePricesheetRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* createPricesheetItemRequestFlow(action) {
  const { callback } = action;
  try {
    yield put(showLoading());

    const {
      payload: { priceSheetId, pricesheetItemInfo }
    } = action;
    const { data: pricesheetItem } = yield call(itemApi.createPricesheetItem, {
      priceSheetId,
      pricesheetItemInfo
    });
    yield call(successHandler('Successfully created'), actions.createPricesheetItemRequestSuccess, {
      pricesheetItem
    });
    if (callback) yield call(callback, pricesheetItem);
  } catch (error) {
    yield call(errorHandler, actions.createPricesheetItemRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* createPricesheetItemByProductsRequestFlow(action) {
  const { callback } = action;

  try {
    yield put(showLoading());

    const {
      payload: { priceSheetId, products }
    } = action;
    const { data: pricesheetItem } = yield call(itemApi.createPricesheetItemByProducts, {
      priceSheetId,
      products
    });

    yield call(successHandler('Successfully created'), actions.createPricesheetItemByProductsRequestSuccess, { pricesheetItem });

    if (callback) {
      yield call(callback, pricesheetItem);
    }
  } catch (error) {
    yield call(errorHandler, actions.createPricesheetItemByProductsRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* createPricesheetItemDigitalBundleRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const { data: pricesheetItem } = yield call(itemApi.createPriceSheetItemDigitalBundle, payload);

    yield call(successHandler('Successfully created'), actions.createPriceSheetItemDigitalBundleSuccess, { pricesheetItem });

    if (callback) {
      callback(pricesheetItem);
    }
  } catch (error) {
    yield call(errorHandler, actions.createPriceSheetItemDigitalBundleError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* copyPricesheetItemRequestFlow(action) {
  try {
    yield put(showLoading());

    const {
      payload: { pricesheetItemId, isCopy }
    } = action;
    const { data: pricesheetItem } = yield call(itemApi.copyPricesheetItem, pricesheetItemId);
    yield call(successHandler('Successfully duplicated'), actions.copyPricesheetItemRequestSuccess, {
      pricesheetItem,
      isCopy
    });
  } catch (error) {
    yield call(errorHandler, actions.copyPricesheetItemRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* updatePricesheetItemRequestFlow(action) {
  const { callback } = action;

  try {
    yield put(showLoading());

    const {
      payload: { pricesheetItemInfo }
    } = action;
    const { data: pricesheetItem } = yield call(itemApi.updatePricesheetItem, {
      pricesheetItemInfo
    });

    yield call(successHandler('Successfully saved'), actions.updatePricesheetItemRequestSuccess, {
      pricesheetItem
    });

    if (callback) {
      yield call(callback, pricesheetItem);
    }
  } catch (error) {
    yield call(errorHandler, actions.updatePricesheetItemRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* deletePricesheetItemRequestFlow(action) {
  const { payload, callback } = action;
  try {
    yield put(showLoading());

    yield call(itemApi.deletePricesheetItem, payload);
    yield call(successHandler('Successfully deleted'), actions.deletePricesheetItemRequestSuccess, payload);

    if (callback) yield call(callback, payload);
  } catch (error) {
    yield call(errorHandler, actions.deletePricesheetItemRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getLabRequestFlow(action) {
  try {
    yield put(showLoading());

    const { payload, callback } = action;
    const { labId } = payload;
    const response = yield call(labApi.getLab, labId);
    const { data: lab } = response;
    yield put(actions.getLabRequestSuccess({ lab }));
    if (callback) callback(response);
  } catch (error) {
    yield put(actions.getLabRequestError(error));
  } finally {
    yield put(hideLoading());
  }
}

function* copyPricesheetRequestFlow(payload) {
  try {
    yield put(showLoading());

    const {
      payload: { id },
      callback
    } = payload;
    const { data: priceSheet } = yield call(api.copyPricesheet, { id });
    yield call(successHandler('Successfully copied'), actions.copyPricesheetRequestSuccess, {
      priceSheet
    });
    if (callback) yield call(callback, priceSheet);
  } catch (error) {
    yield call(errorHandler, actions.copyPricesheetRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* featurePriceSheetProductRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(itemApi.featurePriceSheetProduct, payload);

    yield call(successHandler('Price Sheet Product Successfully featured'), actions.featurePriceSheetProductSuccess, response);

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.featurePriceSheetProductError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* unfeaturePriceSheetProductRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(itemApi.unfeaturePriceSheetProduct, payload);

    yield call(successHandler('Price Sheet Product Successfully un-featured'), actions.unfeaturePriceSheetProductSuccess, response);

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.unfeaturePriceSheetProductError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getPriceSheetCSVRequest(action) {
  try {
    yield put(showLoading());

    const { payload } = action;
    const response = yield call(api.pricesheetCSV, payload.pricesheetId, payload.fileName);
    yield put(actions.getPriceSheetCsvSuccess(response));
  } catch (error) {
    yield call(errorHandler, actions.getPriceSheetCsvError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getPriceSheetThemePropertiesRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.getPriceSheetThemeProperties, payload);

    yield put(actions.getPriceSheetThemePropertiesSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getPriceSheetThemePropertiesError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getPricesheetWatcher() {
  yield all([
    takeLatest(types.GET_PRICESHEET_LIST_REQUEST, getPricesheetListRequestFlow),
    takeLatest(types.GET_PRICESHEET_REQUEST, getPricesheetRequestFlow),
    takeLatest(types.CREATE_PRICESHEET_REQUEST, createPricesheetRequestFlow),
    takeLatest(types.UPDATE_PRICESHEET_REQUEST, updatePricesheetRequestFlow),
    takeLatest(types.DELETE_PRICESHEET_REQUEST, deletePricesheetRequestFlow),
    takeLatest(types.CREATE_PRICESHEET_ITEM_REQUEST, createPricesheetItemRequestFlow),
    takeLatest(types.CREATE_PRICESHEET_ITEM_BY_PRODUCTS_REQUEST, createPricesheetItemByProductsRequestFlow),
    takeLatest(types.CREATE_PRICESHEET_ITEM_DIGITAL_BUNDLE_REQUEST, createPricesheetItemDigitalBundleRequest),
    takeLatest(types.COPY_PRICESHEET_ITEM_REQUEST, copyPricesheetItemRequestFlow),
    takeLatest(types.UPDATE_PRICESHEET_ITEM_REQUEST, updatePricesheetItemRequestFlow),
    takeLatest(types.DELETE_PRICESHEET_ITEM_REQUEST, deletePricesheetItemRequestFlow),
    takeLatest(types.GET_LAB_REQUEST, getLabRequestFlow),
    takeLatest(types.COPY_PRICESHEET_REQUEST, copyPricesheetRequestFlow),
    takeLatest(types.FEATURE_PRICE_SHEET_PRODUCT, featurePriceSheetProductRequest),
    takeLatest(types.UNFEATURE_PRICE_SHEET_PRODUCT, unfeaturePriceSheetProductRequest),
    takeLatest(types.GET_PRICESHEET_CSV_REQUEST, getPriceSheetCSVRequest),
    takeLatest(types.GET_PRICESHEET_THEME_PROPERTIES_REQUEST, getPriceSheetThemePropertiesRequest)
  ]);
}

export default getPricesheetWatcher;
