// Plugins
import axios from 'axios';

// Helpers
import * as api from '@/utils/http';

export const createJobKnockout = (payload) => {
  const endpoint = `jobs/${payload.jobId}/knockouts`;
  const args = { ...payload };

  return api.post(endpoint, args);
};

export const createJobKnockoutReady = (payload) => {
  const endpoint = `knockouts/${payload.knockoutId}/ready`;
  const args = { ...payload };

  return api.post(endpoint, args);
};

export const createJobKnockoutPrepare = (payload) => {
  const endpoint = `knockouts/${payload.knockoutId}/prepare`;

  return api.post(endpoint);
};

export const createJobKnockoutTransfer = (payload) => {
  const endpoint = `knockouts/${payload.knockoutId}/transfer`;
  const args = { ...payload };

  return api.post(endpoint, args);
};

export const getJobKnockoutList = (payload) => {
  const endpoint = `jobs/${payload.jobId}/knockouts`;
  const args = { ...payload };

  return api.get(endpoint, args);
};

export const getJobKnockout = (payload) => {
  const endpoint = `knockouts/${payload.knockoutId}`;

  return api.get(endpoint);
};

export const updateJobKnockout = (payload) => {
  const endpoint = `knockouts/${payload.knockoutId}`;
  const args = { ...payload };

  return api.put(endpoint, args);
};

export const deleteJobKnockout = (payload) => {
  const endpoint = `knockouts/${payload.knockoutId}`;

  return api.del(endpoint);
};

// Knockout-items
export const createKnockoutItem = (payload) => {
  const endpoint = `knockouts/${payload.knockoutId}/knockout-items`;
  const args = {
    ...(payload.name ? { filename: payload.name } : {}),
    ...(payload.type ? { type: payload.type } : {}),
    ...(payload.tags ? { tags: payload.tags } : {})
  };

  return api.post(endpoint, args);
};

export const updateKnockoutItemS3 = (payload) => {
  const endpoint = payload.url;

  return axios.put(endpoint, payload.file, { headers: { 'Content-Type': payload.type } });
};

export const createKnockoutItemUploaded = (payload) => {
  const endpoint = `knockout-items/${payload.knockoutItemId}/uploaded`;
  const args = { ...payload };

  return api.post(endpoint, args);
};

export const getJobKnockoutItems = (payload) => {
  const { knockoutId, sort } = payload;

  const endpoint = `knockouts/${knockoutId}/knockout-items`;
  const args = { ...sort };

  return api.get(endpoint, args);
};

export const deleteJobKnockoutItems = (payload) => {
  const endpoint = `knockouts/${payload.knockoutId}/knockout-items/delete`;
  const args = { ids: payload.ids };

  return api.del(endpoint, args);
};
