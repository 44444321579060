import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import matchValidatePropToValidators from './validators';

const Custom = ({ name, component: Component, label, placeholder, disabled, onChange }) => {
  const renderInput = ({ input, type, meta: { touched, error } }) => (
    <div>
      <Component {...input} disabled={disabled} placeholder={placeholder} type={type} className="input--block" />
      {touched && error && <small className="block text-error-500">{error}</small>}
    </div>
  );

  return (
    <div>
      {label && <label>{label}</label>}
      <Field
        name={name}
        component={renderInput}
        validate={matchValidatePropToValidators({ name, component: Component, label, placeholder, disabled, onChange })}
        onChange={(event, newValue) => {
          onChange && onChange(newValue);
        }}
      />
    </div>
  );
};

Custom.propTypes = {
  name: PropTypes.string.isRequired,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.string,
  onChange: PropTypes.func
};

export default Custom;
