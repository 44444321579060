import { memo } from 'react';

// Components
import AllPhotosGrid from './AllPhotos';
import SubjectFeatureGrid from './SubjectFeature';
import SubjectYearbookGrid from './SubjectYearbook';

//Styles
import './style.css';

const PhotoGrid = memo(function PhotoGrid({
  job,
  people,
  photos,
  fields,
  filterTags,
  pagination,
  nextSubject,
  galleryType,
  biometricsEnabled,
  qrEnabled,
  filterPhotos,
  filterPeople,
  photoSelected,
  photosRequesting,
  unselectedPhotos,
  allPhotosSelected,
  isSidebarCollapsed,
  isGalleryGridDisabled,
  shiftHoverSelected,
  tagManagerOpenList,
  peopleManagerOpenList,

  onMouseEnter,
  onPhotoSelect,
  onPhotoDragEnd,
  onPhotoFeature,
  onAddEditToggle,
  onInfiniteScroll,
  onPhotoDragStart,
  onTagManagerShow,
  onDigitalDownload,
  onShowMatchToggle,
  onNavigateSubjects,
  onPhotoSelectClear,
  onPeopleManagerShow,
  onShowLightboxToggle,
  onPhotosPeopleFilter
}) {
  // Featured (primary) Photos
  if (filterPhotos === 'featured') {
    return <SubjectFeatureGrid people={people} filterPhotos={filterPhotos} onPhotosPeopleFilter={onPhotosPeopleFilter} />;
  }

  // Yearbook Photos
  if (filterPhotos === 'yearbook') {
    return <SubjectYearbookGrid onPhotosPeopleFilter={onPhotosPeopleFilter} />;
  }

  return (
    <AllPhotosGrid
      job={job}
      photos={photos}
      fields={fields}
      filterTags={filterTags}
      pagination={pagination}
      galleryType={galleryType}
      biometricsEnabled={biometricsEnabled}
      qrEnabled={qrEnabled}
      nextSubject={nextSubject}
      filterPhotos={filterPhotos}
      filterPeople={filterPeople}
      photoSelected={photoSelected}
      requesting={photosRequesting}
      unselectedPhotos={unselectedPhotos}
      allPhotosSelected={allPhotosSelected}
      shiftHoverSelected={shiftHoverSelected}
      tagManagerOpenList={tagManagerOpenList}
      peopleManagerOpenList={peopleManagerOpenList}
      isSidebarCollapsed={isSidebarCollapsed}
      isGalleryGridDisabled={isGalleryGridDisabled}
      onMouseEnter={onMouseEnter}
      onPhotoSelect={onPhotoSelect}
      onPhotoDragEnd={onPhotoDragEnd}
      onPhotoFeature={onPhotoFeature}
      onAddEditToggle={onAddEditToggle}
      onTagManagerShow={onTagManagerShow}
      onInfiniteScroll={onInfiniteScroll}
      onPhotoDragStart={onPhotoDragStart}
      onDigitalDownload={onDigitalDownload}
      onShowMatchToggle={onShowMatchToggle}
      onNavigateSubjects={onNavigateSubjects}
      onPhotoSelectClear={onPhotoSelectClear}
      onPeopleManagerShow={onPeopleManagerShow}
      onPhotosPeopleFilter={onPhotosPeopleFilter}
      onShowLightboxToggle={onShowLightboxToggle}
    />
  );
});

export default PhotoGrid;
