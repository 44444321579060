import {
  CREATE_GIFT_CARD_REQUEST,
  CREATE_GIFT_CARD_SUCCESS,
  GIFT_CARD_LIST_REQUEST,
  GIFT_CARD_LIST_SUCCESS,
  UPDATE_GIFT_CARD_REQUEST,
  UPDATE_GIFT_CARD_SUCCESS,
  UPDATE_GIFT_CARD_ERROR,
  DESTROY_GIFT_CARD_REQUEST,
  DESTROY_GIFT_CARD_SUCCESS,
  GET_CREDIT_SHEET_DETAIL_REQUEST,
  GET_CREDIT_SHEET_DETAIL_SUCCESS,
  CREATE_CREDITSHEET_REQUEST,
  CREATE_CREDITSHEET_SUCCESS,
  CREATE_CREDITSHEET_ERROR,
  UPDATE_CREDITSHEET_REQUEST,
  UPDATE_CREDITSHEET_SUCCESS,
  UPDATE_CREDITSHEET_ERROR,
  CREDITSHEET_LIST_REQUEST,
  CREDITSHEET_LIST_SUCCESS,
  CREDITSHEET_LIST_ERROR,
  DESTROY_CREDITSHEET_REQUEST,
  DESTROY_CREDITSHEET_SUCCESS,
  DESTROY_CREDITSHEET_ERROR
} from './constants';

import defaultPager from '@/utils/defaultPager';
import { handleErrorState, handleRequestingState } from '@/utils/reducerHelpers';

const initialState = {
  result: [],
  pager: Object.assign({}, defaultPager),
  entities: {
    gift_card_sheets: {},
    gift_cards: []
  },
  requesting: false,
  successful: false,
  errors: [],
  saveSuccessRedirect: false,
  creditSheetDetail: null
};

const handleGiftCardListSuccess = (state, payload) => {
  const { entities: origEntities } = state;
  const newEntities = Object.assign({}, origEntities, { gift_cards: payload });
  return {
    ...state,
    ...{
      entities: newEntities,
      errors: [],
      requesting: false,
      successful: true
    }
  };
};

const handleUpdateGiftCardSuccess = (state, payload) => {
  const { data } = payload;
  return {
    ...state,
    entities: {
      ...state.entities,
      gift_cards: state.entities.gift_cards.map((item) => {
        if (item.id === data.id) {
          return data;
        }

        return item;
      })
    },
    errors: [],
    requesting: false,
    successful: true
  };
};

const handleCreateGiftCardSuccess = (state, payload) => {
  const { data } = payload;
  return {
    ...state,
    entities: {
      ...state.entities,
      gift_cards: state.entities.gift_cards.concat(data)
    },
    errors: [],
    requesting: false,
    successful: true
  };
};

const handleDeleteGiftCardSuccess = (state, payload) => {
  return {
    ...state,
    entities: {
      ...state.entities,
      gift_cards: state.entities.gift_cards.filter((item) => item.id !== payload)
    },
    errors: [],
    requesting: false,
    successful: true
  };
};

const handleGetCreditSheetDetailSuccess = (state, payload) => {
  return {
    ...state,
    creditSheetDetail: payload,
    errors: [],
    requesting: false,
    successful: true
  };
};

const handleCreditSheetListSuccess = (state, payload) => {
  const { entities, pager, result } = payload;
  const { entities: origEntities } = state;
  const newEntities = Object.assign({}, origEntities, entities);
  return {
    ...state,
    ...{
      result,
      entities: newEntities,
      pager,
      errors: [],
      requesting: false,
      successful: true
    }
  };
};

const handleCreditSheetDestroySuccess = (state, { offerId }) => {
  const { entities } = state;
  const { gift_card_sheets } = entities;
  delete gift_card_sheets[offerId];
  return {
    ...state,
    ...{
      entities: { ...entities, ...{ gift_card_sheets } },
      result: state.result.filter((id) => id !== offerId),
      errors: [],
      requesting: false,
      successful: true,
      saveSuccessRedirect: true
    }
  };
};

const handleCreditSheetRequestSuccess = (state, payload) => {
  const { entities } = state;
  const { creditSheet } = payload;
  const { gift_card_sheets } = entities;
  gift_card_sheets[creditSheet.id] = creditSheet;
  return {
    ...state,
    entities: {
      ...state.entities,
      gift_card_sheets: gift_card_sheets
    }
  };
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GIFT_CARD_LIST_REQUEST:
    case UPDATE_GIFT_CARD_REQUEST:
    case DESTROY_GIFT_CARD_REQUEST:
    case CREATE_GIFT_CARD_REQUEST:
    case CREDITSHEET_LIST_REQUEST:
    case GET_CREDIT_SHEET_DETAIL_REQUEST:
    case DESTROY_CREDITSHEET_REQUEST:
    case CREATE_CREDITSHEET_REQUEST:
    case UPDATE_CREDITSHEET_REQUEST:
      return handleRequestingState(state, payload);

    case GIFT_CARD_LIST_SUCCESS:
      return handleGiftCardListSuccess(state, payload);
    case UPDATE_GIFT_CARD_SUCCESS:
      return handleUpdateGiftCardSuccess(state, payload);
    case DESTROY_GIFT_CARD_SUCCESS:
      return handleDeleteGiftCardSuccess(state, payload);
    case CREATE_GIFT_CARD_SUCCESS:
      return handleCreateGiftCardSuccess(state, payload);
    case GET_CREDIT_SHEET_DETAIL_SUCCESS:
      return handleGetCreditSheetDetailSuccess(state, payload);
    case CREDITSHEET_LIST_SUCCESS:
      return handleCreditSheetListSuccess(state, payload);
    case CREATE_CREDITSHEET_SUCCESS:
    case UPDATE_CREDITSHEET_SUCCESS:
      return handleCreditSheetRequestSuccess(state, payload);
    case DESTROY_CREDITSHEET_SUCCESS:
      return handleCreditSheetDestroySuccess(state, payload);
    case CREDITSHEET_LIST_ERROR:
    case CREATE_CREDITSHEET_ERROR:
    case UPDATE_CREDITSHEET_ERROR:
    case UPDATE_GIFT_CARD_ERROR:
    case DESTROY_CREDITSHEET_ERROR:
      return handleErrorState(state, payload);
    default:
      return state;
  }
};

export default reducer;
