import { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// Plugins
import Select from 'react-select';
import { Tooltip } from 'react-tippy';
import { PatternFormat } from 'react-number-format';

// Redux
import { getJobTimezoneList } from '../../../Jobs/actions';
import { addressValidate } from '@/components/Shared/AddressVerification/actions';
import { getMyAccountStudioRequest, updateMyAccountStudioRequest } from '../../actions';

// Components
import StatesDDL from '@/components/Shared/StatesDDL';
import TextLoader from '@/components/Shared/ContentLoader/TextLoader';

// Helpers
import { normalizePhone } from '@/utils/formatters';
import { phoneNumberValidator, emailValidator } from '@/components/Shared/Forms/validators';
import { stringSpecialCharactersValidator, urlSlugValidator } from '@/utils/validateHelpers';

// Types
import { StudioVerificationStatus, StudioSellerType } from '@/types';

const MY_PHOTODAY_ORG_PAGE_URL = import.meta.env.VITE_MY_PHOTODAY_ORG_PAGE_URL;

const mapStateToProps = (state) => {
  const {
    login: { studio: loginStudio },
    users: { studio: usersStudio, requesting: usersRequesting },
    jobs: { timezones, requesting: jobRequesting }
  } = state;

  const studio = Object.keys(usersStudio ?? {}).length ? usersStudio : loginStudio;

  return {
    requesting: jobRequesting || usersRequesting,
    timezones,
    studioId: studio?.id,
    studio
  };
};

const mapDispatchToProps = {
  getJobTimezoneList,
  addressValidate,
  getMyAccountStudioRequest,
  updateMyAccountStudioRequest
};

const StudioInfo = (props) => {
  const {
    timezones,
    loadStudios,
    requesting,
    studio: propStudio,
    studioId,
    getMyAccountStudioRequest,
    getJobTimezoneList,
    addressValidate,
    updateMyAccountStudioRequest
  } = props;

  const [studio, setStudio] = useState(props.studio);
  const [addressError, setAddressError] = useState(false);
  const [addressDeliverability, setAddressDeliverability] = useState('deliverable');

  const [isStudioNameValid, setIsStudioNameValid] = useState(true);
  const [isStudioUrlSlugValid, setIsStudioUrlSlugValid] = useState(true);

  const [showUrlSlugConfirmationModal, setShowUrlSlugConfirmationModal] = useState(false);

  const [phoneErrorMsg, setPhoneErrorMsg] = useState(null);
  const [emailErrorMsg, setEmailErrorMsg] = useState(null);

  const handleInputOnChange = (evt) => {
    const target = evt.target;
    const input = target.name;
    const value = target.value;

    setStudio((prevState) => ({ ...prevState, [input]: value }));
    setPhoneErrorMsg(null);
    setEmailErrorMsg(null);
  };

  const handleAddressInputOnChange = (evt) => {
    evt.preventDefault();

    const target = evt.target;
    const input = target.name;
    const value = target.value;

    setStudio((prevState) => ({ ...prevState, address: { ...prevState.address, [input]: value } }));
    setAddressError(false);
  };

  const handleAddressValidate = () => {
    const { address } = studio;

    addressValidate({ line1: address.line1, zip: address.zip }, (response) => {
      if (response) {
        const { components } = response;

        setStudio((prevState) => ({
          ...prevState,
          address: {
            ...prevState.address,
            city: components.city,
            state: components.state,
            zip: components.zip_code
          }
        }));
        setAddressDeliverability(response.deliverability);
      } else {
        setAddressDeliverability('');
      }
    });
  };

  const handleStudioNameValidate = (evt) => {
    setIsStudioNameValid(!stringSpecialCharactersValidator(evt?.target?.value));
  };

  const handleStudioUrlSlugValidate = (evt) => {
    setIsStudioUrlSlugValid(urlSlugValidator(evt?.target?.value));
  };

  const handleStateDDLOnChange = (val) => {
    const { address } = studio;

    const newAddress = val ? { ...address, state: val.value } : { ...address, state: '' };

    setStudio({ ...studio, address: newAddress });
    setAddressError(false);
  };

  const handleResidentialAddressChange = (evt) => {
    const name = evt.target.name;
    const hasResidentialAddress = name === 'residentialYes';
    setStudio((prevState) => ({ ...prevState, has_residential_address: hasResidentialAddress }));
  };

  const handleTimezoneOnChange = (val) => {
    setStudio((prevState) => ({ ...prevState, time_zone: val.value }));
  };

  const handleUrlSlugConfirmationModalClose = () => setShowUrlSlugConfirmationModal(false);
  const handleUrlSlugConfirmationModalConfirm = () => {
    saveStudio();
    setShowUrlSlugConfirmationModal(false);
  };

  const validateStudioInfo = () => {
    const { address, email, phone, studio_slug: studioSlug } = studio;

    const { line1, city, state, zip } = address || {};
    const isAddressError = !line1 || !city || !state || !zip;
    const phoneBlank = !phone;
    const emailBlank = !email;
    const phoneInvalid = phoneNumberValidator(phone);
    const emailInvalid = emailValidator(email);

    if (phoneBlank) {
      setPhoneErrorMsg('Required');
      return;
    }

    if (phoneInvalid) {
      setPhoneErrorMsg('Invalid phone format');
      return;
    }

    if (isAddressError) {
      setAddressError(true);
      return;
    }

    if (emailBlank) {
      setEmailErrorMsg('Required');
      return;
    }

    if (emailInvalid) {
      setEmailErrorMsg('Invalid email format');
      return;
    }

    if (studioSlug && studio.studio_slug !== propStudio.studio_slug) {
      setShowUrlSlugConfirmationModal(true);
      return;
    }

    saveStudio();
  };

  const saveStudio = () => {
    const studioPayload = {
      id: studio.id,
      name: studio.name,
      web_url: studio.web_url,
      phone: studio.phone,
      email: studio.email,
      time_zone: studio.time_zone,
      address_attributes: studio.address,
      watermark_alpha: studio.watermark_alpha,
      watermark_position: studio.watermark_position,
      watermark_scale: studio.watermark_scale,
      watermark_url: studio.watermark_url,
      reporting_code: studio.reporting_code,
      studio_slug: studio.studio_slug,
      has_residential_address: studio.has_residential_address
    };

    updateMyAccountStudioRequest(studioPayload);
  };

  const {
    name,
    phone,
    email,
    web_url,
    address,
    reporting_code,
    studio_slug: studioUrlSlug,
    studio_verification: studioVerification,
    verification_status: verificationStatus,
    has_residential_address: hasResidentialAddress
  } = studio;
  const { line1, line2, city, state, zip } = address || {};
  const timezoneOptions = timezones.map(({ name }) => ({ value: name, label: name }));

  const isStudioUrlDisabled = !!propStudio.studio_slug;

  const selectedZone = timezoneOptions.find((zone) => zone.value === studio.time_zone);

  const stateDDLOptions = {
    elementName: 'state',
    country: 'USA',
    selectedState: state,
    parentHandleChange: handleStateDDLOnChange
  };

  useEffect(() => {
    if (studioId) {
      getMyAccountStudioRequest({ studioId }, ({ data }) => setStudio(data));
    }

    if (!timezones.length) {
      getJobTimezoneList();
    }
  }, []);

  return (
    <>
      <header className="flex items-center justify-between mb-5">
        <h2 className="text-headline-sm">Studio Information</h2>
        <button
          className="button button--medium"
          type="button"
          onClick={validateStudioInfo}
          disabled={requesting || addressDeliverability !== 'deliverable' || !isStudioNameValid || !isStudioUrlSlugValid}
          data-loading={requesting}
        >
          Save
        </button>
      </header>
      <section className="mb-10">
        <p className="mb-2">The information below will be shared with your customers.</p>
        <fieldset className="flex flex-nowrap wrap-md gap-5">
          <div className="basis-6/12 md:basis-full flex-nowrap wrap-sm">
            <label>Studio Name</label>
            {requesting && !studio ? (
              <TextLoader height={37} marginBottom={10} />
            ) : (
              <input
                name="name"
                type="text"
                value={name || ''}
                readOnly={studioVerification?.seller_type === StudioSellerType.Company && verificationStatus === StudioVerificationStatus.Verified}
                onBlur={handleStudioNameValidate}
                onChange={handleInputOnChange}
              />
            )}
            {!isStudioNameValid && <small className="block text-error-500">Invalid Studio Name! Please remove all special characters before proceeding.</small>}
          </div>
          <div className="basis-6/12 md:basis-full">
            <label> Website URL </label>
            {requesting && !studio ? (
              <TextLoader height={37} marginBottom={10} />
            ) : (
              <input value={web_url || ''} onChange={handleInputOnChange} name="web_url" type="text" className="input--block" />
            )}
          </div>
        </fieldset>
        <fieldset className="flex flex-nowrap wrap-md gap-5">
          <div className="basis-6/12 md:basis-full">
            <label>Customer Contact Phone</label>
            {requesting && !studio ? (
              <TextLoader height={37} marginBottom={10} />
            ) : (
              <PatternFormat
                className="input--block"
                format="+1 (###) ###-####"
                allowEmptyFormatting
                mask="_"
                value={(phone && normalizePhone(phone)) || ''}
                name="studio-phone"
                onValueChange={({ value }) => handleInputOnChange({ target: { name: 'phone', value } })}
              />
            )}
            {phoneErrorMsg && <small className="text-error-500">{phoneErrorMsg}.</small>}
          </div>
          <div className="basis-6/12 md:basis-full">
            <label>Customer Contact Email</label>
            {requesting && !studio ? (
              <TextLoader height={37} marginBottom={10} />
            ) : (
              <input value={email || ''} onChange={handleInputOnChange} name="email" type="email" className="input--block" />
            )}
            {emailErrorMsg && <small className="text-error-500">{emailErrorMsg}.</small>}
          </div>
        </fieldset>
        <fieldset className="flex flex-nowrap wrap-md gap-5">
          <div className="basis-6/12 md:basis-full">
            <label>
              Studio Reporting ID{' '}
              <Tooltip
                title="This field is for internal reporting purposes for multi-studio users and in most cases should be left blank"
                theme="light"
                arrow={true}
                position="right"
                distance="10"
              >
                <i className="icon-question-mark"></i>
              </Tooltip>
            </label>
            {requesting && !studio ? (
              <TextLoader height={37} marginBottom={10} />
            ) : (
              <input value={reporting_code || ''} onChange={handleInputOnChange} maxLength="100" name="reporting_code" type="text" className="input--block" />
            )}
          </div>
          <div className="basis-6/12 md:basis-full">
            <label>
              Studio Identifier {''}
              <Tooltip
                title="Customize the identifier for your Studio. Please make sure to double check spelling as this will be used in various communications as well as organization pages. This can only be done once."
                theme="light"
                arrow={true}
                position="right"
                distance="10"
              >
                <i className="icon-question-mark"></i>
              </Tooltip>
            </label>
            {requesting && !studio ? (
              <TextLoader height={37} marginBottom={10} />
            ) : (
              <div className="flex items-center nowrap">
                <input type="text" value={MY_PHOTODAY_ORG_PAGE_URL || ''} readOnly={true} />
                <input
                  className="basis-10/12"
                  name="studio_slug"
                  type="text"
                  maxLength={24}
                  value={studioUrlSlug || ''}
                  readOnly={isStudioUrlDisabled}
                  onBlur={handleStudioUrlSlugValidate}
                  onChange={handleInputOnChange}
                />
              </div>
            )}
            {!isStudioUrlSlugValid && (
              <small className="block text-error-500">Invalid URL slug! Please remove all special characters or spaces before proceeding.</small>
            )}
          </div>
        </fieldset>
      </section>
      <section className="mb-10">
        <h5 className="mb-5">Studio Address</h5>
        <fieldset className="flex gap-5 nowrap">
          <div className="basis-8/12">
            <label htmlFor="address-line1">Address</label>
            {requesting && !studio ? (
              <TextLoader height={37} marginBottom={10} />
            ) : (
              <input
                id="address-line1"
                type="text"
                name="line1"
                value={line1 || ''}
                placeholder="Street Address"
                readOnly={verificationStatus === StudioVerificationStatus.Verified}
                onBlur={handleAddressValidate}
                onChange={handleAddressInputOnChange}
              />
            )}
            {addressError || !line1 ? (
              <small className="block text-error-500">Required.</small>
            ) : (
              addressDeliverability !== 'deliverable' && <small className="block text-error-500">Undeliverable address.</small>
            )}
          </div>
          <div className="basis-2/6">
            <label htmlFor="address-line2">Apt/Unit #</label>
            {requesting && !studio ? (
              <TextLoader height={37} marginBottom={10} />
            ) : (
              <input
                id="address-line2"
                type="text"
                name="line2"
                value={line2 || ''}
                placeholder="Apt/Unit #"
                readOnly={verificationStatus === StudioVerificationStatus.Verified}
                onChange={handleAddressInputOnChange}
              />
            )}
          </div>
        </fieldset>
        <fieldset className="flex gap-5 nowrap">
          <div className="basis-2/6">
            <label htmlFor="address-city">City</label>
            {requesting && !studio ? (
              <TextLoader height={37} marginBottom={10} />
            ) : (
              <input
                id="address-city"
                type="text"
                name="city"
                value={city || ''}
                placeholder="City"
                readOnly={verificationStatus === StudioVerificationStatus.Verified}
                onChange={handleAddressInputOnChange}
              />
            )}
            {addressError && !city && <small className="block text-error-500">Required.</small>}
          </div>
          <div className="basis-2/6">
            <label htmlFor="address-state">State</label>
            {requesting && !studio ? (
              <TextLoader height={37} marginBottom={10} />
            ) : (
              <StatesDDL {...stateDDLOptions} disabled={verificationStatus === StudioVerificationStatus.Verified} />
            )}
            {addressError && !state && <small className="block text-error-500">Required.</small>}
          </div>
          <div className="basis-2/6">
            <label htmlFor="address-zip">Zip</label>
            {requesting && !studio ? (
              <TextLoader height={37} marginBottom={10} />
            ) : (
              <input
                id="address-zip"
                type="text"
                name="zip"
                value={zip || ''}
                placeholder="Zip Code"
                onBlur={handleAddressValidate}
                readOnly={verificationStatus === StudioVerificationStatus.Verified}
                onChange={handleAddressInputOnChange}
              />
            )}
            {addressError || !zip ? (
              <small className="block text-error-500">Required.</small>
            ) : (
              addressDeliverability !== 'deliverable' && <small className="block text-error-500">Undeliverable address.</small>
            )}
          </div>
        </fieldset>
        {verificationStatus === StudioVerificationStatus.Verified && (
          <fieldset>
            <label>Is the address you entered above residential?</label>
            <div className="flex gap-5">
              <div className="flex grow-0">
                <input
                  id="residentialYes"
                  type="radio"
                  name="residentialYes"
                  checked={hasResidentialAddress === true}
                  onChange={handleResidentialAddressChange}
                />
                <label htmlFor="residentialYes" className="m-0">
                  Yes
                </label>
              </div>
              <div className="flex">
                <input
                  id="residentialNo"
                  type="radio"
                  name="residentialNo"
                  checked={hasResidentialAddress === false}
                  onChange={handleResidentialAddressChange}
                />
                <label htmlFor="residentialNo" className="m-0">
                  No
                </label>
              </div>
            </div>
          </fieldset>
        )}
      </section>
      <section>
        <h5 className="mb-2">Studio Time Zone</h5>
        <p className="mb-5">Default time zone for your studio. You can set a custom time zone for a job when it's created or in the job's settings.</p>
        <div className="basis-2/6">
          <label htmlFor="time_zone">Time Zone</label>
          {requesting && !studio ? (
            <TextLoader height={37} marginBottom={10} />
          ) : (
            <Select
              id="time_zone"
              className="select"
              classNamePrefix="select"
              maxMenuHeight={200}
              name="time_zone"
              menuPlacement="top"
              value={selectedZone}
              options={timezoneOptions}
              onChange={handleTimezoneOnChange}
            />
          )}
        </div>
      </section>

      {/* Slug Identifier Confirmation Modal */}
      {showUrlSlugConfirmationModal && (
        <aside className="modal animate">
          <div className="modal__box modal__box--small modal__box--nomin">
            <main className="modal__content">
              <h2>Please note:</h2>
              <h3 className="text--normal">
                The Studio Identifier slug can only be set once and cannot be changed later. Are you sure you want to proceed with saving it?
              </h3>
            </main>
            <footer className="modal__footer flex gap-2.5 center">
              <button className="button button--outline" type="button" name="cancel" onClick={handleUrlSlugConfirmationModalClose}>
                Cancel
              </button>
              <button className="button" type="button" name="save" onClick={handleUrlSlugConfirmationModalConfirm}>
                Save
              </button>
            </footer>
          </div>
        </aside>
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(StudioInfo);
