import { DIGITAL_PRICE_MINIMUM } from './constants';

class ProfitCalculator {
  constructor(args) {
    this.args = args;
    const { baseCostCents, markupCents, priceCents, pdFee, digitalProductsCount, digitalMinimum } = this.args;

    this.baseCostCents = baseCostCents || 0;
    this.markupCents = markupCents || 0;
    this.priceCents = priceCents || 0;
    this.pdFee = pdFee;
    this.digitalProductsCount = digitalProductsCount || 0;
    this.digitalMinimum = digitalMinimum || DIGITAL_PRICE_MINIMUM;
    this.minimumPriceCents = 0;
  }

  calculate() {
    let { markup } = this.args;

    if (!this.priceCents && markup) {
      this.calculateFromMarkup(markup, this.digitalProductsCount);
    } else if (this.priceCents) {
      this.calculateFromPrice(this.digitalProductsCount);
    }

    this.priceCents = Math.round(this.priceCents);
    this.markupCents = Math.round(this.markupCents);
    this.photodayFeeCents = Math.round(this.photodayFeeCents);

    if (isNaN(this.photodayFeeCents)) {
      this.photodayFeeCents = 0;
    }

    this.calculateMinimum(this.baseCostCents, this.digitalProductsCount);
  }

  calculateMinimum(baseCostCents, digitalProductsCount) {
    this.minimumMarkupforCosts(baseCostCents, digitalProductsCount);
  }

  // When we calculate from markup we use the markup to determine the price and photoday fees
  calculateFromMarkup = (markup, digitalProductsCount) => {
    this.markupCents = this.markupForPrice(markup, this.baseCostCents) + digitalProductsCount * this.digitalMinimum;
    this.priceCents = this.markupCents + this.baseCostCents;
    this.photodayFeeCents = this.calculatePhotodayFee(this.priceCents);
  };

  // When we calculate from price we use the price to determine the markup and photoday fees
  calculateFromPrice = () => {
    this.markupCents = this.priceCents - this.baseCostCents;
    this.photodayFeeCents = this.calculatePhotodayFee(this.priceCents);
  };

  // Takes a markup object and calculates the markup based on a given price
  // Price is optional because if markup is fixed amount its a fixed markup
  markupForPrice = (markup, baseCostCents) => {
    if (markup.markup_type === 'fixed_amount') {
      return markup.fixed_amount_cents;
    } else if (markup.markup_type === 'fixed_percentage') {
      return Math.floor((baseCostCents * markup.fixed_percentage) / 100.0);
    } else {
      throw new Error('Unsupported markup type');
    }
  };

  // Get Minimum retail based on cost of products and PhotoDay Fee
  minimumMarkupforCosts = (baseCostCents, digitalProductsCount) => {
    const digitalMinimum = digitalProductsCount * this.digitalMinimum;
    const markupMinimum = Math.ceil(baseCostCents / ((100 - this.pdFee) / 100)) - baseCostCents + 1;

    this.minimumPriceCents = Math.max(markupMinimum, digitalMinimum) + baseCostCents;
  };

  // Calculates a photoday fee for a retail price
  calculatePhotodayFee = (retailPriceCents) => {
    return Math.round(retailPriceCents * (this.pdFee / 100.0));
  };
}

export default ProfitCalculator;
