import { call, put, takeLatest, all } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import * as types from './constants';
import * as actions from './actions';

import * as api from '@/api/organizations-api';

import errorHandler from '@/utils/errorResponseHandler';

function* createOrganizationRequest(action: any): any {
  const { payload, callback }: { payload: any; callback: (response: unknown) => void } = action.payload;

  try {
    yield put(showLoading());

    const response = yield call(api.createOrganization, payload);

    yield put(actions.createOrganizationSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createOrganizationError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getOrganizationListRequest(action: any): any {
  const { payload, callback }: { payload: any; callback: (response: unknown) => void } = action.payload;

  try {
    yield put(showLoading());

    const response = yield call(api.getOrganizationList, payload);

    yield put(actions.getOrganizationListSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getOrganizationListError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getOrganizationRequest(action: any): any {
  const { payload, callback }: { payload: any; callback: (response: unknown) => void } = action.payload;

  try {
    yield put(showLoading());

    const response = yield call(api.getOrganization, payload);

    yield put(actions.getOrganizationSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getOrganizationError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* updateOrganizationRequest(action: any): any {
  const {
    payload,
    successCallback,
    errorCallback
  }: { payload: any; successCallback: (response: unknown) => void; errorCallback: (response: unknown) => void } = action.payload;

  try {
    yield put(showLoading());

    const response = yield call(api.updateOrganization, payload);

    yield put(actions.updateOrganizationSuccess(response));

    if (successCallback) {
      successCallback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.updateOrganizationError, error);
    if (errorCallback) yield call(errorCallback, error);
  } finally {
    yield put(hideLoading());
  }
}

function* deleteOrganizationRequest(action: any): any {
  const { payload, callback }: { payload: any; callback: (response: unknown) => void } = action.payload;

  try {
    yield put(showLoading());

    const response = yield call(api.deleteOrganization, payload);

    yield put(actions.deleteOrganizationSuccess({ response, ...payload }));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.deleteOrganizationError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getOrganizationWatcher() {
  yield all([
    takeLatest(types.CREATE_ORGANIZATION_REQUEST, createOrganizationRequest),
    takeLatest(types.GET_ORGANIZATION_LIST_REQUEST, getOrganizationListRequest),
    takeLatest(types.GET_ORGANIZATION_REQUEST, getOrganizationRequest),
    takeLatest(types.UPDATE_ORGANIZATION_REQUEST, updateOrganizationRequest),
    takeLatest(types.DELETE_ORGANIZATION_REQUEST, deleteOrganizationRequest)
  ]);
}

export default getOrganizationWatcher;
