import * as api from '@/utils/http';
import setPagingSortFilter from '@/utils/setPagingSortFilter';

export const orderList = (studioId, page, perPage = 15, search = null, order = null, dir = null) => {
  const endpoint = `purchases`;
  const args = setPagingSortFilter(page, perPage, search, order, dir);
  return api.get(endpoint, args).then((res) => {
    const { data, headers } = res;
    const pager = api.getPaginationParams(headers);
    return { data, pager };
  });
};

export const orderDetail = (studioId, orderId) => {
  const endpoint = `orders/${orderId}`;
  return api.get(endpoint);
};

export const createOrderResendReceipt = (payload) => {
  const endpoint = `orders/${payload.id}/resend-receipt`;

  return api.post(endpoint);
};

export const getRetouchPhotos = ({ id }) => {
  const endpoint = `cart/${id}/retouch-configuration`;

  return api.get(endpoint);
};

export const getOrderReprints = (orderId) => {
  const endpoint = `orders/${orderId}/reprints`;

  return api.get(endpoint);
};

export const createOrderReprint = (payload) => {
  const endpoint = `orders/${payload.orderId}/reprints`;
  const args = { ...payload };

  return api.post(endpoint, args);
};
