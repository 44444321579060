import React from 'react';
import PropTypes from 'prop-types';
import { NestedField } from '@/components/Shared/Forms';
import ProductSelector from './ProductSelector';

const GiveawayOptions = ({ productCategories }) => {
  return (
    <div className="form-segment">
      <h4 className="font-bold">Customer gets a free...</h4>
      <NestedField name="free_products_attributes" component={ProductSelector} productCategories={productCategories} required={true} />
    </div>
  );
};

GiveawayOptions.propTypes = {
  productCategories: PropTypes.array
};

export default GiveawayOptions;
