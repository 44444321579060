import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { shortDate } from '@/utils/displayFormats';

const JobItem = ({ roles, item: { id, name, date } }) => {
  const formattedDate = useMemo(() => shortDate(date), [date]);

  return (
    <tr>
      <td>
        {roles.includes('manage_jobs') ? (
          <Link className="text--normalized" to={`/jobs/${id}/dashboard`}>
            <b>{name}</b>
          </Link>
        ) : (
          <span>{name}</span>
        )}
      </td>
      <td className="text-right">{formattedDate}</td>
    </tr>
  );
};

JobItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired
  }),
  roles: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default JobItem;
