import React from 'react';
import PropTypes from 'prop-types';

// Plugins
import moment from 'moment';

// Icons
import icon_settings_filled from '@/assets/images/icon-gear.png';
import icon_trash from '@/assets/images/icon-trash.png';

const OfferRow = ({ offer, offerType, onClickRemove, onClickSettings, canManageOffers }) => {
  const { name, code, description, expiration, max_redemptions, redemptions } = offer;

  const handleSettingsClick = () => {
    onClickSettings && onClickSettings(offer);
  };

  const handleRemoveClick = () => {
    onClickRemove && onClickRemove(offer);
  };

  if (offerType === 'credit_sheets') {
    return (
      <tr>
        <td>{name}</td>
        <td>{description}</td>
        {canManageOffers && (
          <td className="!flex">
            <img src={icon_settings_filled} onClick={handleSettingsClick} className="offer-table-actions" alt="Settings" />
            <img src={icon_trash} onClick={handleRemoveClick} className="offer-table-actions" alt="Delete" />
          </td>
        )}
      </tr>
    );
  }

  return (
    <tr>
      <td>{name}</td>
      <td>{code}</td>
      <td>{description}</td>
      <td>{expiration && moment(expiration).utc().format('L')}</td>
      <td>
        {redemptions}/{max_redemptions || 'Unlimited'}
      </td>
      {canManageOffers && (
        <td className="!flex">
          <img src={icon_settings_filled} onClick={handleSettingsClick} className="offer-table-actions" alt="Settings" />
          <img src={icon_trash} onClick={handleRemoveClick} className="offer-table-actions" alt="Delete" />
        </td>
      )}
    </tr>
  );
};

OfferRow.propTypes = {
  offer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    code: PropTypes.string,
    description: PropTypes.string,
    redemptions: PropTypes.number,
    expiration: PropTypes.string,
    max_redemptions: PropTypes.number
  }).isRequired,
  offerType: PropTypes.string,
  onClickRemove: PropTypes.func,
  onClickSettings: PropTypes.func,
  canManageOffers: PropTypes.bool
};

export default OfferRow;
