import { applyMiddleware, createStore, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

// reducer and sagas
import IndexReducer from '@/index-reducer';
import IndexSagas from '@/index-sagas';

const sagaMiddleware = createSagaMiddleware();

// Redux DevTools Setup
const composeSetup =
  import.meta.env.NODE_ENV !== 'production' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const store = createStore(
  IndexReducer,
  composeSetup(applyMiddleware(sagaMiddleware)) // allows redux devtools to watch sagas
);

sagaMiddleware.run(IndexSagas);

export default store;
