import { FC, useState, useEffect, ChangeEvent } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { createPeopleBulkYearbookSelection, getPeopleList, getPhotoTypeOptions } from '../../../actions';

// Plugins
import Select, { SingleValue } from 'react-select';

// Images
import imageYearbookBg from '@/assets/images/image-bulk-yearbook-bg.jpg';

// Types
import { SelectOptionType, PhotoType } from '@/types';

interface BulkYearbookSelectionProps {
  jobId: string;
  onShowBulkYearbookToggle: () => void;
}

const BulkYearbookSelection: FC<BulkYearbookSelectionProps> = ({ jobId, onShowBulkYearbookToggle }) => {
  const dispatch = useDispatch();

  const {
    job,
    photoTypeOptions,
    people: { requesting: peopleRequesting }
  } = useSelector((state: any) => state.jobs);

  // Remove yearbook options from photo type options
  const filteredPhotoTypeOptions = photoTypeOptions.filter(({ value: optionValue }: SelectOptionType) => !optionValue?.includes('yearbook'));

  // State
  const [selectedPoseOne, setSelectedPoseOne] = useState<SingleValue<SelectOptionType>>();
  const [filenameFilterPoseOne, setFilenameFilterPoseOne] = useState<string>();

  const [selectedPoseTwo, setSelectedPoseTwo] = useState<SingleValue<SelectOptionType>>();
  const [filenameFilterPoseTwo, setFilenameFilterPoseTwo] = useState<string>();

  // UI Handlers
  const handlePoseOneChange = (select: SingleValue<SelectOptionType>): void => setSelectedPoseOne(select);
  const handleFilenamePoseOneChange = ({ target }: ChangeEvent<HTMLInputElement>): void => setFilenameFilterPoseOne(target.value);

  const handlePoseTwoChange = (select: SingleValue<SelectOptionType>): void => setSelectedPoseTwo(select);
  const handleFilenamePoseTwoChange = ({ target }: ChangeEvent<HTMLInputElement>): void => setFilenameFilterPoseTwo(target.value);

  const handleSave = (): void => {
    function recallSubjectsAndCloseModal() {
      // Re-call Subjects
      dispatch(getPeopleList({ id: job?.id, with_yearbook_selection: true, per_page: 10000, order: 'last_name', dir: 'asc' }));
      onShowBulkYearbookToggle();
    }

    const yearbookSelectionOneObject = { jobId, field_id: 'photo_1', preference: selectedPoseOne?.value, filename_filter: filenameFilterPoseOne };
    const yearbookSelectionTwoObject = { jobId, field_id: 'photo_2', preference: selectedPoseTwo?.value, filename_filter: filenameFilterPoseTwo };

    if (selectedPoseOne && !selectedPoseTwo) {
      dispatch(createPeopleBulkYearbookSelection(yearbookSelectionOneObject, () => recallSubjectsAndCloseModal()));
    } else if (selectedPoseTwo && !selectedPoseOne) {
      dispatch(createPeopleBulkYearbookSelection(yearbookSelectionTwoObject, () => recallSubjectsAndCloseModal()));
    } else if (selectedPoseOne && selectedPoseTwo) {
      dispatch(
        createPeopleBulkYearbookSelection(yearbookSelectionOneObject, () => {
          dispatch(createPeopleBulkYearbookSelection(yearbookSelectionTwoObject, () => recallSubjectsAndCloseModal()));
        })
      );
    }
  };

  useEffect(() => {
    dispatch(getPhotoTypeOptions({ jobId: job.id }));
  }, []);

  return (
    <aside className="modal">
      <div className="modal__box modal__box--clean modal__box--medium">
        <header>
          <img src={imageYearbookBg} alt="Yearbook Background" width="100%" />
        </header>
        <main className="p-5">
          <h2>Set Your Yearbook Photos</h2>
          <p>
            Select options below for your yearbook photos and PhotoDay will automatically make the selections. This will only apply to subjects without an
            existing yearbook selection and will not override any current selections.
          </p>
          <div className="flex gap-5 nowrap">
            <fieldset className="basis-6/12">
              <label htmlFor="poseOne">{job?.yearbook_selection_photo_1_title}</label>
              <Select
                id="poseOne"
                className="select mb-2.5"
                classNamePrefix="select"
                name="poseOne"
                value={selectedPoseOne}
                placeholder="Select one..."
                maxMenuHeight={114}
                options={filteredPhotoTypeOptions.filter(({ value: optionValue }: SelectOptionType) => optionValue !== selectedPoseTwo?.value)}
                onChange={handlePoseOneChange}
              />
              {selectedPoseOne?.value === PhotoType.ContainsSpecificWord && (
                <input
                  id="filenameOne"
                  type="text"
                  name="filenameOne"
                  placeholder="Enter filename..."
                  value={filenameFilterPoseOne ?? ''}
                  maxLength={50}
                  onChange={handleFilenamePoseOneChange}
                />
              )}
            </fieldset>
            {job?.yearbook_selection_photo_count === 2 && (
              <fieldset className="basis-6/12">
                <label htmlFor="">{job?.yearbook_selection_photo_2_title}</label>
                <Select
                  id="poseTwo"
                  className="select mb-2.5"
                  classNamePrefix="select"
                  name="poseTwo"
                  value={selectedPoseTwo}
                  placeholder="Select one..."
                  maxMenuHeight={114}
                  options={filteredPhotoTypeOptions.filter(({ value: optionValue }: SelectOptionType) => optionValue !== selectedPoseOne?.value)}
                  onChange={handlePoseTwoChange}
                />
                {selectedPoseTwo?.value === PhotoType.ContainsSpecificWord && (
                  <input
                    id="filenameTwo"
                    type="text"
                    name="filenameTwo"
                    placeholder="Enter filename..."
                    value={filenameFilterPoseTwo ?? ''}
                    maxLength={50}
                    onChange={handleFilenamePoseTwoChange}
                  />
                )}
              </fieldset>
            )}
          </div>
        </main>
        <footer className="flex justify-end gap-2.5 modal__footer p-5">
          <button className="button button--outline" type="button" name="manual" onClick={onShowBulkYearbookToggle}>
            Manually Select Photos
          </button>
          <button
            className="button"
            type="button"
            name="save"
            data-loading={peopleRequesting}
            disabled={!selectedPoseOne && !selectedPoseTwo}
            onClick={handleSave}
          >
            Save
          </button>
        </footer>
      </div>
    </aside>
  );
};

export default BulkYearbookSelection;
