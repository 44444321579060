import * as api from '@/utils/http';
import setPagingSortFilter from '@/utils/setPagingSortFilter';

export const createGiftCard = ({ creditSheetId, amount, description }) => {
  const endpoint = `gift-card-sheets/${creditSheetId}/gift-card-options`;

  return api.post(endpoint, {
    amount_cents: amount,
    description
  });
};

export const updateGiftCard = ({ giftCardId, amount, description }) => {
  const endpoint = `gift-card-options/${giftCardId}`;

  return api.put(endpoint, {
    amount_cents: amount,
    description
  });
};

export const deleteGiftCard = (giftCardId) => {
  const endpoint = `gift-card-options/${giftCardId}`;

  return api.del(endpoint);
};

export const giftCardList = (creditSheetId) => {
  const endpoint = `gift-card-sheets/${creditSheetId}/gift-card-options`;

  return api.get(endpoint);
};

export const creditSheetDetail = (creditSheetId) => {
  const endpoint = `gift-card-sheets/${creditSheetId}`;

  return api.get(endpoint);
};

export const creditSheetList = (page, perPage = 15, search = null, order = null, dir = null) => {
  const endpoint = `gift-card-sheets`;
  const args = setPagingSortFilter(page, perPage, search, order, dir);

  return api.get(endpoint, args).then((res) => {
    const { data, headers } = res;
    const pager = api.getPaginationParams(headers);
    return { data, pager };
  });
};

export const createCreditSheetOffer = ({ studioId, creditSheet }) => {
  const endpoint = `studios/${studioId}/gift-card-sheets`;

  return api.post(endpoint, creditSheet);
};

export const updateCreditSheetOffer = ({ creditSheet }) => {
  const endpoint = `gift-card-sheets/${creditSheet.id}`;

  return api.put(endpoint, creditSheet);
};

export const deleteCreditSheetOffer = ({ creditSheetId }) => {
  const endpoint = `gift-card-sheets/${creditSheetId}`;

  return api.del(endpoint);
};
