// Components
import QuicktipItem from '../Quicktips/QuicktipItem';

// Images
import ReactImageDiscounts from '@/assets/images/image-discounts.svg?react';
import ReactImageFreeShipping from '@/assets/images/image-free-shipping.svg?react';

// Styles
import '../Quicktips/quicktips.css';

const QuickTips = () => {
  return (
    <div id="storefront__quicktips">
      <div className="flex items-center justify-between">
        <div>
          <h2 className="text-headline-sm">Offers</h2>
          <p>Offers are a great way to motivate your customers.</p>
        </div>
      </div>
      <div className="quick-tips">
        <QuicktipItem
          title="Discounts"
          description="Take a fixed percentage or a dollar amount off the order total."
          image={<ReactImageDiscounts className="self-end" />}
          url="./offers-and-credits/discounts"
          linkLabel={'View'}
          backgroundColor={'green'}
        />

        <QuicktipItem
          title="Free Shipping"
          description="Give free standard shipping on orders over a specified amount."
          image={<ReactImageFreeShipping className="self-end" />}
          url="./offers-and-credits/shipping"
          linkLabel="View"
          backgroundColor={'blue'}
        />
      </div>
    </div>
  );
};

export default QuickTips;
