import * as types from './constants';
import defaultPager from '@/utils/defaultPager';
import { handleErrorState, handleRequestingState } from '@/utils/reducerHelpers';

const initialState = {
  result: { monthly: [], years: [], year: null },
  summary: {},
  monthReport: {
    list: [],
    pagination: { page: 1, perPage: 15, total: 0 },
    requesting: false
  },
  monthSummary: {},
  productsReport: {
    list: [],
    pagination: { page: 1, perPage: 15, total: 0 },
    requesting: false
  },
  pager: Object.assign({}, defaultPager),

  requesting: false,
  successful: false,
  errors: []
};

// Global
const handleReportsRequestingState = (state, key) => ({
  ...state,
  [key]: {
    ...state[key],
    requesting: true
  },
  ...{ requesting: true, successful: false, errors: [] }
});

const handleReportsErrorState = (state, payload, key) => {
  const { error } = payload;
  const { message, response } = error;
  const { status, data } = response || {};
  const errorBody = data ? data : message;
  const errors = state.errors.concat([
    {
      code: status || 500,
      body: errorBody,
      time: new Date()
    }
  ]);

  return {
    ...state,
    [key]: {
      ...state[key],
      requesting: false
    },
    ...{ errors, requesting: false, successful: false }
  };
};

const handleSalesReportSuccess = (state, payload) => {
  const { result } = payload;
  return {
    ...state,
    ...{
      result,
      errors: [],
      requesting: false,
      successful: true
    }
  };
};

const handleProductsReportSuccess = (state, payload) => {
  const {
    result: { data, headers }
  } = payload;

  return {
    ...state,
    productsReport: {
      list: data,
      pagination: {
        page: Number(headers?.['x-page']),
        perPage: Number(headers?.['x-per-page']),
        total: Number(headers?.['x-total'])
      },
      requesting: false
    },
    errors: [],
    requesting: false,
    successful: true
  };
};

const handleSalesSummarySuccess = (state, payload) => {
  const { summary } = payload;
  return { ...state, ...{ summary } };
};

const handleMonthReportSuccess = (state, { payload }) => {
  const { data, headers } = payload;

  const monthReport = {
    list: data,
    pagination: {
      page: Number(headers?.['x-page']),
      perPage: Number(headers?.['x-per-page']),
      total: Number(headers?.['x-total'])
    },
    requesting: false
  };

  return { ...state, ...{ monthReport, requesting: false } };
};

const handleSalesMonthSummarySuccess = (state, payload) => {
  const { summary } = payload;
  return { ...state, monthSummary: summary };
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SALES_REPORT_REQUEST:
    case types.SALES_SUMMARY_REQUEST:
    case types.SALES_MONTH_SUMMARY_REQUEST:
      return handleRequestingState(state);

    case types.SALES_MONTH_REPORT_REQUEST:
      return handleReportsRequestingState(state, 'monthReport');

    case types.PRODUCTS_REPORT_REQUEST:
      return handleReportsRequestingState(state, 'productsReport');

    case types.SALES_REPORT_SUCCESS:
      return handleSalesReportSuccess(state, payload);
    case types.SALES_SUMMARY_SUCCESS:
      return handleSalesSummarySuccess(state, payload);
    case types.SALES_MONTH_REPORT_SUCCESS:
      return handleMonthReportSuccess(state, payload);
    case types.SALES_MONTH_SUMMARY_SUCCESS:
      return handleSalesMonthSummarySuccess(state, payload);
    case types.PRODUCTS_REPORT_SUCCESS:
      return handleProductsReportSuccess(state, payload);

    case types.SALES_REPORT_ERROR:
    case types.SALES_SUMMARY_ERROR:
    case types.SALES_MONTH_SUMMARY_ERROR:
    case types.PRODUCTS_REPORT_ERROR:
      return handleErrorState(state, payload);
    default:
      return state;

    case types.SALES_MONTH_REPORT_ERROR:
      return handleReportsErrorState(state, payload, 'monthReport');
  }
};

export default reducer;
