import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { getStatesListRequest } from './actions';

import States from './states';
import TextLoader from '@/components/Shared/ContentLoader/TextLoader';

import './styles.css';

const mapStateToProps = (state) => ({
  isStateLoaded: state.states.isLoaded,
  states: state.states.result
});

const mapDispatchToProps = { getStatesListRequest };

const StatesDDL = ({ isStateLoaded, getStatesListRequest, ...props }) => {
  useEffect(() => {
    if (!isStateLoaded) {
      getStatesListRequest();
    }
  }, [isStateLoaded, getStatesListRequest]);

  if (!isStateLoaded) {
    return <TextLoader height={37} />;
  }

  return <States {...props} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(StatesDDL);
