import { call, takeLatest, all, put } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import * as types from './constants';
import * as actions from './actions';
import * as addressApi from '@/api/address-api';

import errorHandler from '@/utils/errorResponseHandler';

function* validateAddressRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(addressApi.validateAddress, payload);

    yield put(actions.addressValidateSuccess(response.data));

    if (callback) {
      callback(response.data);
    }
  } catch (error) {
    yield call(errorHandler, actions.addressValidateError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* addressValidateWatcher() {
  yield all([takeLatest(types.ADDRESS_VALIDATE, validateAddressRequest)]);
}

export default addressValidateWatcher;
