export const GET_ACADEMY_PLAYLIST_REQUEST = 'GET_ACADEMY_PLAYLIST_REQUEST';
export const GET_ACADEMY_PLAYLIST_SUCCESS = 'GET_ACADEMY_PLAYLIST_SUCCESS';
export const GET_ACADEMY_PLAYLIST_ERROR = 'GET_ACADEMY_PLAYLIST_ERROR';

export const GET_UPCOMING_WEBINARS_REQUEST = 'GET_UPCOMING_WEBINARS_REQUEST';
export const GET_UPCOMING_WEBINARS_SUCCESS = 'GET_UPCOMING_WEBINARS_SUCCESS';
export const GET_UPCOMING_WEBINARS_ERROR = 'GET_UPCOMING_WEBINARS_ERROR';

export const GET_NPS_DOWNLOAD_REQUEST = 'GET_NPS_DOWNLOAD_REQUEST';
export const GET_NPS_DOWNLOAD_SUCCESS = 'GET_NPS_DOWNLOAD_SUCCESS';
export const GET_NPS_DOWNLOAD_ERROR = 'GET_NPS_DOWNLOAD_ERROR';
