import * as types from './constants';

const actionPriceSheetError = (type) => (error) => ({
  type: type,
  payload: {
    error
  },
  error: true
});

// Reset PriceSheets
export const resetPriceSheets = () => ({
  type: types.RESET_PRICESHEETS_REQUEST
});

export const getPricesheetListRequest = ({ page, perPage, order, dir }, callback) => ({
  type: types.GET_PRICESHEET_LIST_REQUEST,
  payload: {
    page,
    perPage,
    order,
    dir
  },
  callback
});

export const getPricesheetListRequestSuccess = ({ entities, pager, result }) => ({
  type: types.GET_PRICESHEET_LIST_SUCCESS,
  payload: {
    entities,
    pager,
    result
  }
});

export const getPricesheetListRequestError = actionPriceSheetError(types.GET_PRICESHEET_LIST_ERROR);

export const createPricesheetRequest = (studioId, pricesheetInfo, callback) => ({
  type: types.CREATE_PRICESHEET_REQUEST,
  studioId,
  pricesheetInfo,
  callback
});

export const createPricesheetRequestSuccess = ({ pricesheet }) => ({
  type: types.CREATE_PRICESHEET_SUCCESS,
  payload: {
    pricesheet
  }
});

export const createPricesheetRequestError = actionPriceSheetError(types.CREATE_PRICESHEET_ERROR);

export const updatePricesheetRequest = (pricesheetInfo, callback) => ({
  type: types.UPDATE_PRICESHEET_REQUEST,
  pricesheetInfo,
  callback
});

export const updatePricesheetRequestSuccess = ({ pricesheet }) => ({
  type: types.UPDATE_PRICESHEET_SUCCESS,
  payload: {
    pricesheet
  }
});

export const updatePricesheetRequestError = actionPriceSheetError(types.UPDATE_PRICESHEET_ERROR);

export const deletePricesheetRequest = (priceSheetId, force, callback) => ({
  type: types.DELETE_PRICESHEET_REQUEST,
  priceSheetId,
  force,
  callback
});

export const deletePricesheetRequestSuccess = (payload) => ({
  type: types.DELETE_PRICESHEET_SUCCESS,
  payload: { payload }
});

export const deletePricesheetRequestError = actionPriceSheetError(types.DELETE_PRICESHEET_ERROR);

export const getPricesheetRequest = (pricesheetId, callback) => ({
  type: types.GET_PRICESHEET_REQUEST,
  payload: {
    pricesheetId
  },
  callback
});

export const getPricesheetRequestSuccess = (pricesheet) => ({
  type: types.GET_PRICESHEET_SUCCESS,
  payload: {
    pricesheet
  }
});

export const getPricesheetRequestError = actionPriceSheetError(types.GET_PRICESHEET_ERROR);

export const createPricesheetItemRequest = ({ priceSheetId, pricesheetItemInfo }, callback) => ({
  type: types.CREATE_PRICESHEET_ITEM_REQUEST,
  payload: {
    priceSheetId,
    pricesheetItemInfo
  },
  callback
});

export const createPricesheetItemRequestSuccess = ({ pricesheetItem }) => ({
  type: types.CREATE_PRICESHEET_ITEM_SUCCESS,
  payload: {
    pricesheetItem
  }
});

export const createPricesheetItemRequestError = actionPriceSheetError(types.CREATE_PRICESHEET_ITEM_ERROR);

export const createPricesheetItemByProductsRequest = ({ priceSheetId, products }, callback) => ({
  type: types.CREATE_PRICESHEET_ITEM_BY_PRODUCTS_REQUEST,
  payload: {
    priceSheetId,
    products
  },
  callback
});

export const createPricesheetItemByProductsRequestSuccess = ({ pricesheetItem }) => ({
  type: types.CREATE_PRICESHEET_ITEM_BY_PRODUCTS_SUCCESS,
  payload: {
    pricesheetItem
  }
});

export const createPricesheetItemByProductsRequestError = actionPriceSheetError(types.CREATE_PRICESHEET_ITEM_BY_PRODUCTS_ERROR);

// Create PriceSheet Item (Digital Bundle)
export const createPriceSheetItemDigitalBundle = (payload, callback) => ({
  type: types.CREATE_PRICESHEET_ITEM_DIGITAL_BUNDLE_REQUEST,
  payload: { payload, callback }
});

export const createPriceSheetItemDigitalBundleSuccess = ({ pricesheetItem }) => ({
  type: types.CREATE_PRICESHEET_ITEM_DIGITAL_BUNDLE_SUCCESS,
  payload: { pricesheetItem }
});

export const createPriceSheetItemDigitalBundleError = (error) => ({
  type: types.CREATE_PRICESHEET_ITEM_DIGITAL_BUNDLE_ERROR,
  payload: { error }
});

export const copyPricesheetItemRequest = (pricesheetItemId) => ({
  type: types.COPY_PRICESHEET_ITEM_REQUEST,
  payload: {
    pricesheetItemId,
    isCopy: true
  }
});

export const copyPricesheetItemRequestSuccess = ({ pricesheetItem }) => ({
  type: types.COPY_PRICESHEET_ITEM_SUCCESS,
  payload: {
    pricesheetItem: pricesheetItem,
    isCopy: true
  }
});

export const copyPricesheetItemRequestError = actionPriceSheetError(types.COPY_PRICESHEET_ITEM_ERROR);

export const updatePricesheetItemRequest = (pricesheetItemInfo, callback) => ({
  type: types.UPDATE_PRICESHEET_ITEM_REQUEST,
  payload: {
    pricesheetItemInfo
  },
  callback
});

export const updatePricesheetItemRequestSuccess = ({ pricesheetItem }) => ({
  type: types.UPDATE_PRICESHEET_ITEM_SUCCESS,
  payload: {
    pricesheetItem
  }
});

export const updatePricesheetItemRequestError = actionPriceSheetError(types.UPDATE_PRICESHEET_ITEM_ERROR);

export const deletePricesheetItemRequest = (payload, callback) => ({
  type: types.DELETE_PRICESHEET_ITEM_REQUEST,
  payload: { ...payload },
  callback
});

export const deletePricesheetItemRequestSuccess = ({ pricesheetItemId }) => ({
  type: types.DELETE_PRICESHEET_ITEM_SUCCESS,
  payload: {
    pricesheetItemId
  }
});

export const deletePricesheetItemRequestError = actionPriceSheetError(types.DELETE_PRICESHEET_ITEM_ERROR);

export const getLabRequest = (labId, callback) => ({
  type: types.GET_LAB_REQUEST,
  payload: {
    labId
  },
  callback
});

export const getLabRequestSuccess = ({ lab }) => ({
  type: types.GET_LAB_SUCCESS,
  payload: {
    lab
  }
});

export const getLabRequestError = actionPriceSheetError(types.GET_LAB_ERROR);

export const copyPricesheetRequest = ({ id }, callback) => ({
  type: types.COPY_PRICESHEET_REQUEST,
  payload: {
    id
  },
  callback
});

export const copyPricesheetRequestSuccess = ({ priceSheet }) => ({
  type: types.COPY_PRICESHEET_SUCCESS,
  payload: {
    priceSheet
  }
});

export const copyPricesheetRequestError = actionPriceSheetError(types.COPY_PRICESHEET_ERROR);

// Feature Price Sheet Product
export const featurePriceSheetProduct = (payload, callback) => ({
  type: types.FEATURE_PRICE_SHEET_PRODUCT,
  payload: { payload, callback }
});

export const featurePriceSheetProductSuccess = (payload) => ({
  type: types.FEATURE_PRICE_SHEET_PRODUCT_SUCCESS,
  payload: { payload }
});

export const featurePriceSheetProductError = (error) => ({
  type: types.FEATURE_PRICE_SHEET_PRODUCT_ERROR,
  payload: { error }
});

// Un-feature Price Sheet Product
export const unfeaturePriceSheetProduct = (payload, callback) => ({
  type: types.UNFEATURE_PRICE_SHEET_PRODUCT,
  payload: { payload, callback }
});

export const unfeaturePriceSheetProductSuccess = (payload) => ({
  type: types.UNFEATURE_PRICE_SHEET_PRODUCT_SUCCESS,
  payload: { payload }
});

export const unfeaturePriceSheetProductError = (error) => ({
  type: types.UNFEATURE_PRICE_SHEET_PRODUCT_ERROR,
  payload: { error }
});

// Read Price Sheet CSV
export const getPriceSheetCsv = (pricesheetId, fileName) => ({
  type: types.GET_PRICESHEET_CSV_REQUEST,
  payload: { pricesheetId, fileName }
});

export const getPriceSheetCsvSuccess = (getPriceSheetCsv) => ({
  type: types.GET_PRICESHEET_CSV_SUCCESS,
  payload: { getPriceSheetCsv }
});

export const getPriceSheetCsvError = (error) => ({
  type: types.GET_PRICESHEET_CSV_ERROR,
  payload: {
    error
  }
});

// Read theme properties in Price Sheet
export const getPriceSheetThemeProperties = (payload, callback) => ({
  type: types.GET_PRICESHEET_THEME_PROPERTIES_REQUEST,
  payload: { payload, callback }
});

export const getPriceSheetThemePropertiesSuccess = (payload) => ({
  type: types.GET_PRICESHEET_THEME_PROPERTIES_SUCCESS,
  payload: { payload }
});

export const getPriceSheetThemePropertiesError = (error) => ({
  type: types.GET_PRICESHEET_THEME_PROPERTIES_ERROR,
  payload: { error }
});
