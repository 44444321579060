import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import matchValidatePropToValidators from './validators';
import matchNormalizePropToNormalizers from './normalize';

const Input = ({
  name,
  type = 'text',
  label = null,
  placeholder = null,
  autoComplete,
  disabled = null,
  loading = null,
  onChange,
  error = null,
  transformValueOnChange,
  readOnly,
  className,
  normalize,
  onBlur,
  required,
  dataCy
}) => {
  const renderInput = ({ input, type, meta: { touched, error } }) => {
    const handleOnChange = (event) => {
      input.onChange(transformValueOnChange ? transformValueOnChange(event.target.value) : event.target.value);
    };

    return (
      <div className={loading ? 'input--block-loading' : ''}>
        <input
          {...input}
          readOnly={readOnly}
          disabled={disabled || loading}
          placeholder={placeholder}
          type={type}
          className="input--block"
          autoComplete={autoComplete}
          data-cy={dataCy}
          onChange={handleOnChange}
        />
        {touched && (error || error) && <small className="block text-error-500">{error || error}</small>}
      </div>
    );
  };

  return (
    <fieldset className={className ?? ''}>
      {label && <label>{label}</label>}
      <Field
        name={name}
        component={renderInput}
        type={type}
        validate={matchValidatePropToValidators({ required: !!required })}
        normalize={normalize && ((value) => matchNormalizePropToNormalizers(normalize, value))}
        onChange={onChange}
        onBlur={onBlur}
      />
    </fieldset>
  );
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  loading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
  error: PropTypes.string,
  transformValueOnChange: PropTypes.func,
  readOnly: PropTypes.bool,
  className: PropTypes.string,
  normalize: PropTypes.func,
  onBlur: PropTypes.func
};

export default Input;
