import * as types from './constants';

// Create Organization
export const createOrganization = (payload: unknown, callback?: unknown) => ({
  type: types.CREATE_ORGANIZATION_REQUEST,
  payload: { payload, callback }
});

export const createOrganizationSuccess = (payload: unknown) => ({
  type: types.CREATE_ORGANIZATION_SUCCESS,
  payload: { payload }
});

export const createOrganizationError = (error: unknown) => ({
  type: types.CREATE_ORGANIZATION_ERROR,
  payload: { error }
});

// Read Organization List
export const getOrganizationList = (payload: unknown, callback?: unknown) => ({
  type: types.GET_ORGANIZATION_LIST_REQUEST,
  payload: { payload, callback }
});

export const getOrganizationListSuccess = (payload: unknown) => ({
  type: types.GET_ORGANIZATION_LIST_SUCCESS,
  payload: { payload }
});

export const getOrganizationListError = (error: unknown) => ({
  type: types.GET_ORGANIZATION_LIST_ERROR,
  payload: { error }
});

// Read Organization
export const getOrganization = (payload: unknown, callback?: unknown) => ({
  type: types.GET_ORGANIZATION_REQUEST,
  payload: { payload, callback }
});

export const getOrganizationSuccess = (payload: unknown) => ({
  type: types.GET_ORGANIZATION_SUCCESS,
  payload: { payload }
});

export const getOrganizationError = (error: unknown) => ({
  type: types.GET_ORGANIZATION_ERROR,
  payload: { error }
});

// Update Organization
export const updateOrganization = (payload: unknown, successCallback?: unknown, errorCallback?: unknown) => ({
  type: types.UPDATE_ORGANIZATION_REQUEST,
  payload: { payload, successCallback, errorCallback }
});

export const updateOrganizationSuccess = (payload: unknown) => ({
  type: types.UPDATE_ORGANIZATION_SUCCESS,
  payload: { payload }
});

export const updateOrganizationError = (error: unknown) => ({
  type: types.UPDATE_ORGANIZATION_ERROR,
  payload: { error }
});

// Delete Organization
export const deleteOrganization = (payload: unknown, callback?: unknown) => ({
  type: types.DELETE_ORGANIZATION_REQUEST,
  payload: { payload, callback }
});

export const deleteOrganizationSuccess = (payload: unknown) => ({
  type: types.DELETE_ORGANIZATION_SUCCESS,
  payload: { payload }
});

export const deleteOrganizationError = (error: unknown) => ({
  type: types.DELETE_ORGANIZATION_ERROR,
  payload: { error }
});
