import { forwardRef, ForwardedRef } from 'react';

// Types
interface GalleryDragElementProps {
  dragItemsTotal: number;
}

const GalleryDragElement = forwardRef(({ dragItemsTotal }: GalleryDragElementProps, ref: ForwardedRef<HTMLDivElement>) => (
  <div ref={ref} className="job-gallery__drag">
    <span className="font-bold">+{dragItemsTotal}</span>
  </div>
));

export default GalleryDragElement;
