import React from 'react';

// Redux
import { connect } from 'react-redux';
import { updateMyAccountRequest } from '../../actions';

import SecurityForm from './SecurityForm';

const mapStateToProps = (state) => {
  const { requesting, account } = state.users;
  return { account, requesting };
};
const mapDispatchToProps = { updateMyAccountRequest };

const Account = ({ requesting, updateMyAccountRequest }) => {
  const saveAccount = (values) => updateMyAccountRequest(values);

  return <SecurityForm onSubmit={saveAccount} requesting={requesting} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
