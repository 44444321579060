import { normalize } from 'normalizr';
import { v5 as uuidv5 } from 'uuid';

const parseNormalizeListResponse = (response, schema, order = null, dir = null, search = null, filter = null, searchField = null) => {
  const { data, pager: respPager } = response;
  const pager = Object.assign({}, respPager, {
    order,
    dir,
    search,
    filter,
    searchField
  });
  const normalizedData = normalize(data, schema);
  const { result, entities } = normalizedData;

  return { result, entities, pager };
};

export const parsePhotoListResponse = (response, schema) => {
  const { data: origPhotos, pager } = response;
  const photos = origPhotos.map((p) => Object.assign({}, p, { selected: false }));
  const normalizedData = normalize(photos, schema);
  const { result, entities } = normalizedData;
  return { result, entities, pager };
};

const sortTagsByName = (x, y) => {
  const tagX = x.name.toLowerCase();
  const tagY = y.name.toLowerCase();
  if (tagX < tagY) return -1;
  if (tagX > tagY) return 1;
  return 0;
};

export const parseTagListResponse = (response, schema) => {
  const { data: tags } = response;
  const data = tags.sort(sortTagsByName).map((t) => Object.assign({}, t, { id: uuidv5(t.name, uuidv5.DNS), selected: false }));
  const normalizedData = normalize(data, schema);
  const { result, entities } = normalizedData;
  return { result, entities };
};

export const parseTagResponse = ({ id, name }) => ({
  id: id || uuidv5(name, uuidv5.DNS),
  name,
  selected: false
});

export default parseNormalizeListResponse;
