import { call, put, takeLatest, all } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import * as types from './constants';
import * as api from '@/api/order-api';

import orderSchema from './schema';
import parseNormalizeListResponse from '@/utils/responseParsers';

import errorHandler from '@/utils/errorResponseHandler';
import successHandler from '@/utils/successResponseHandler';

import {
  orderListRequestSuccess,
  orderListRequestError,
  orderDetailRequestSuccess,
  orderDetailRequestError,
  createOrderResendReceiptSuccess,
  createOrderResendReceiptError,
  createOrderReprintSuccess,
  createOrderReprintError,
  getRetouchPhotosSuccess,
  getRetouchPhotosError,
  getOrderReprintsSuccess,
  getOrderReprintsError
} from './actions';

function* getOrderListRequestFlow(action) {
  try {
    yield put(showLoading());

    const { studioId, page, perPage, search, order, dir } = action.payload;
    const response = yield call(api.orderList, studioId, page, perPage, search, order, dir);
    const payload = parseNormalizeListResponse(response, orderSchema, order, dir, search);
    yield put(orderListRequestSuccess(payload));
  } catch (error) {
    yield call(errorHandler, orderListRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getOrderDetailRequestFlow(action) {
  try {
    yield put(showLoading());
    const {
      payload: {
        payload: { studioId, orderId },
        callback
      }
    } = action;

    const response = yield call(api.orderDetail, studioId, orderId);

    yield put(orderDetailRequestSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, orderDetailRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* createOrderResendReceiptRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.createOrderResendReceipt, payload);

    yield call(successHandler('Order receipt sent successfully!'), createOrderResendReceiptSuccess, response);

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, createOrderResendReceiptError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getRetouchPhotosRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    const response = yield call(api.getRetouchPhotos, payload);

    yield put(getRetouchPhotosSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, getRetouchPhotosError, error);
  }
}

function* getOrderReprintsRequest(action) {
  const {
    payload: {
      payload: { orderId },
      callback
    }
  } = action;

  try {
    const response = yield call(api.getOrderReprints, orderId);

    yield put(getOrderReprintsSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, getOrderReprintsError, error);
  }
}

function* createOrderReprintRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    const response = yield call(api.createOrderReprint, payload);

    yield put(createOrderReprintSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, createOrderReprintError, error);
  }
}

function* getJobsWatcher() {
  yield all([
    takeLatest(types.ORDER_LIST_REQUEST, getOrderListRequestFlow),
    takeLatest(types.ORDER_DETAIL_REQUEST, getOrderDetailRequestFlow),
    takeLatest(types.SET_ORDER_NAME_SEARCH, getOrderListRequestFlow),
    takeLatest(types.CREATE_ORDER_RESEND_RECEIPT_REQUEST, createOrderResendReceiptRequest),
    takeLatest(types.GET_RETOUCH_PHOTOS_REQUEST, getRetouchPhotosRequest),
    takeLatest(types.GET_ORDER_REPRINTS_REQUEST, getOrderReprintsRequest),
    takeLatest(types.CREATE_ORDER_REPRINT_REQUEST, createOrderReprintRequest)
  ]);
}

export default getJobsWatcher;
