import React from 'react';
import { withRouter } from 'react-router';
import { ActionCableProvider } from 'use-action-cable';

// Redux
import { useSelector } from 'react-redux';

const CableProvider = ({ children }) => {
  const { jwt } = useSelector((state) => state.login);

  // If we have a dedicated ENV variable use that if not just create it from the base api
  let wsUrl = import.meta.env.VITE_WS_URL != null ? import.meta.env.VITE_WS_URL : import.meta.env.VITE_BASE_URL?.replace('http', 'ws');

  if (wsUrl.endsWith('/')) {
    wsUrl = wsUrl.slice(0, wsUrl.length - 1);
  }

  if (jwt) {
    wsUrl = `${wsUrl}/cable?token=${jwt}`;

    return <ActionCableProvider url={wsUrl}>{children}</ActionCableProvider>;
  } else {
    return children;
  }
};

export default withRouter(CableProvider);
