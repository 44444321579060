import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Images
import icon_settings_filled from '@/assets/images/icon-gear.png';
import icon_trash from '@/assets/images/icon-trash.png';

const OfferRow = ({ offer, offerType, onClickRemove, onClickSettings, canManageAdvancePayCredits }) => {
  const { name, description } = offer;

  const handleSettingsClick = () => {
    onClickSettings && onClickSettings(offer);
  };

  const handleRemoveClick = () => {
    onClickRemove && onClickRemove(offer);
  };

  return (
    <tr>
      <td>{canManageAdvancePayCredits ? <Link to={`advance-pay/${offer.id}`}>{name}</Link> : <span>{offer.name}</span>}</td>
      <td>{description}</td>
      {canManageAdvancePayCredits && (
        <td className="!flex">
          <img src={icon_settings_filled} onClick={handleSettingsClick} className="offer-table-actions" alt="Settings" />
          <img src={icon_trash} onClick={handleRemoveClick} className="offer-table-actions" alt="Delete" />
        </td>
      )}
    </tr>
  );
};

OfferRow.propTypes = {
  offer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    code: PropTypes.string,
    description: PropTypes.string,
    redemptions: PropTypes.number,
    expiration: PropTypes.string,
    max_redemptions: PropTypes.number
  }).isRequired,
  offerType: PropTypes.string,
  onClickRemove: PropTypes.func,
  onClickSettings: PropTypes.func,
  canManageAdvancePayCredits: PropTypes.bool
};

export default OfferRow;
