import React from 'react';
import { Form, reduxForm } from 'redux-form';
import { Input } from '@/components/Shared/Forms/index';

const VerifyForm = ({ handleSubmit, initialValues }) => {
  return (
    <Form onSubmit={handleSubmit} className="sign-up-form">
      <div className="flex">
        <div className="basis-full">
          <Input label="Email Address" name="email" required email disabled={initialValues.email} />
        </div>
      </div>
      <button className="button button--block login-btn" type="submit">
        Resend Verification Email
      </button>
    </Form>
  );
};

export default reduxForm({ form: 'VerifyForm', destroyOnUnmount: false })(VerifyForm);
