import * as types from './constants';

import { handleErrorState } from '@/utils/reducerHelpers';

const initialState = {
  successful: false,
  requesting: false,
  errors: []
};

const handleOrderResolutionRequesting = (state) => {
  return {
    ...state,
    successful: false,
    requesting: true,
    errors: []
  };
};

const handleOrderResolutionSuccess = (state) => ({
  ...state,
  successful: true,
  requesting: false,
  errors: []
});

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.ORDER_RESOLUTION_REQUEST:
      return handleOrderResolutionRequesting(state);

    case types.ORDER_RESOLUTION_SUCCESS:
      return handleOrderResolutionSuccess(state);

    case types.ORDER_RESOLUTION_ERROR:
      return handleErrorState(state, payload);

    default:
      return state;
  }
};

export default reducer;
