import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import icon_trash from '@/assets/images/icon-trash.png';
import { Draggable } from 'react-beautiful-dnd';

class ItemComponent extends PureComponent {
  state = { quantity: 1 };

  changeQty = (inc) => {
    const {
      item: { quantity },
      index,
      onChange
    } = this.props;

    let newQty = quantity + inc;

    if (newQty < 1) return;

    onChange({ index, quantity: newQty });
  };

  handleOnClick = () => {
    const { index, removeHandler, selected, onSelectRow } = this.props;

    removeHandler(index);

    if (selected && selected > index) {
      onSelectRow(null, selected - 1);
    } else if (selected && selected === index) {
      onSelectRow(null, null);
    }
  };

  handleRowSelect = () => {
    const { onSelectRow, item, index } = this.props;

    onSelectRow && onSelectRow(item.id, index);
  };

  render() {
    const { item, selected, index, isPackage } = this.props;
    const active = selected === index;

    let { showQuantity } = this.props;

    if (item.is_digital_download) {
      showQuantity = false;
    }

    return (
      <Draggable draggableId={item.id + index} key={item.id + index} index={index} display={'table'} isDragDisabled={!isPackage}>
        {(provided, snapshot) => (
          <tr
            className={`item-component__row ${active ? 'item-component__row--active' : ''} ${item.error ? 'item-component__row--error' : ''} ${
              snapshot.isDragging ? 'item-component__row--drag' : ''
            } ${snapshot.isDragging && !snapshot.draggingOver ? 'item-component__row--drag-out' : ''}`}
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <td onClick={this.handleRowSelect} {...provided.dragHandleProps}>
              {item.name} {showQuantity && item.quantity > 1 && <small className="item-component__subtext">{`(Pack of ${item.quantity})`}</small>}
            </td>
            <td className="product-quantity">
              {showQuantity && (
                <>
                  <button className="product-quantity__button" disabled={item.quantity === 1} onClick={() => this.changeQty(-1)}>
                    -
                  </button>
                  <span className="product-quantity__value"> {item.quantity}</span>

                  <button className="product-quantity__button" onClick={() => this.changeQty(1)}>
                    +
                  </button>
                </>
              )}
            </td>
            <td>
              <img className="delete-action" onClick={this.handleOnClick} src={icon_trash} alt="Delete" />{' '}
            </td>
          </tr>
        )}
      </Draggable>
    );
  }
}

ItemComponent.propTypes = {
  item: PropTypes.object.isRequired,
  removeHandler: PropTypes.func.isRequired,
  showQuantity: PropTypes.bool,
  selected: PropTypes.number,
  onChange: PropTypes.func,
  onSelectRow: PropTypes.func,
  isPackage: PropTypes.bool
};

ItemComponent.defaultProps = {
  showQuantity: false,
  onChange: () => ({}),
  onSelectRow: () => ({}),
  selected: null,
  isPackage: false
};

export default ItemComponent;
