import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

// Plugins
import Select from 'react-select';

// Helpers
import matchValidatePropToValidators from './validators';

const ReduxSelect = ({ name, options = [], label = null, placeholder, onChange, disabled }) => {
  const renderSelect = ({ input, meta: { touched, error } }) => (
    <div>
      <Select
        {...input}
        className={`select mb-2.5 ${input.className ? input.className : ''}`}
        classNamePrefix="select"
        placeholder={placeholder}
        isDisabled={disabled}
        isMulti={false}
        onBlur={() => false}
        onChange={(value) => input.onChange(value)}
        options={options}
      />
      {touched && error && <small className="block text-error-500">{error}</small>}
    </div>
  );

  return (
    <div>
      {label && <label>{label}</label>}
      <Field
        validate={matchValidatePropToValidators({ name, options, label, placeholder, onChange, disabled })}
        name={name}
        component={renderSelect}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
};

ReduxSelect.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    })
  ),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

export default ReduxSelect;
