import { FC, ChangeEvent, useState, useEffect } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { updateOrganization } from '../actions';

// Constants
const DELETE_CONFIRMATION_WORD: string = 'delete';

// Types
import { OrganizationContact } from '@/types';

interface DeleteContactModalProps {
  organizationId: string;
  contactId: string | null;
  onModalClose: () => void;
}

const DeleteContactModal: FC<DeleteContactModalProps> = ({ organizationId, contactId, onModalClose }) => {
  const dispatch = useDispatch();
  const { organization, requesting } = useSelector((state: any) => state.organizations);

  const [contactInfo, setContactInfo] = useState<Partial<OrganizationContact>>();
  const [deleteConfirmationWord, setDeleteConfirmationWord] = useState<string>('');

  const handleConfirmationWordChange = (event: ChangeEvent<HTMLInputElement>): void => setDeleteConfirmationWord(event.target.value);

  const handleDelete = (): void => {
    dispatch(
      updateOrganization({ organizationId: organizationId, updatedOrganizationInfo: { contacts_attributes: [{ ...contactInfo, _destroy: true }] } }, () =>
        onModalClose()
      )
    );
  };

  const handleDeleteCancel = (): void => onModalClose();

  useEffect(() => {
    const foundContact = organization.contacts.find((contact: OrganizationContact) => contact.id === contactId);

    if (foundContact) {
      setContactInfo(foundContact);
    }
  }, []);

  return (
    <aside className="modal animate">
      <div className="modal__box modal__box--small">
        <header className="text-left">
          <h2>Delete Contact</h2>
        </header>
        <main className="modal__content text-left">
          <p>
            Are you sure you want to delete the contact{' '}
            <b>
              {contactInfo?.first_name} {contactInfo?.last_name}
            </b>
            ?
          </p>
          <p>This action cannot be undone.</p>
          <p>
            Type <b>"delete"</b> to permanently delete this contact.
          </p>
          <input className="input--block" type="text" name="delete" value={deleteConfirmationWord} maxLength={10} onChange={handleConfirmationWordChange} />
        </main>
        <footer className="modal__footer flex justify-end gap-2.5">
          <button className="button button--outline" type="button" name="cancel" onClick={handleDeleteCancel}>
            Cancel
          </button>
          <button
            className="button button--danger"
            name="delete"
            type="button"
            data-loading={requesting}
            onClick={handleDelete}
            disabled={!(deleteConfirmationWord.toLowerCase() === DELETE_CONFIRMATION_WORD.toLowerCase())}
          >
            Delete
          </button>
        </footer>
      </div>
    </aside>
  );
};

export default DeleteContactModal;
