import { GET_STATES_REQUEST, GET_STATES_SUCCESS, GET_STATES_ERROR } from './constants';

import { handleErrorState, handleRequestingState } from '@/utils/reducerHelpers';

const initialState = {
  result: {
    USA: []
  },
  requesting: false,
  successful: false,
  isLoaded: false,
  errors: []
};

const handleStateSuccessState = (state, payload) => {
  const { states } = payload;
  const { data } = states;
  return {
    ...state,
    ...{
      result: { USA: data },
      errors: [],
      requesting: false,
      successful: true,
      isLoaded: true
    }
  };
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_STATES_REQUEST:
      return handleRequestingState(state);
    case GET_STATES_SUCCESS:
      return handleStateSuccessState(state, payload);
    case GET_STATES_ERROR:
      return handleErrorState(state, payload);
    default:
      return state;
  }
};

export default reducer;
