import { FC, useEffect, useState } from 'react';

// Plugins
import { Tooltip } from 'react-tippy';
import { SingleValue } from 'react-select';

// Redux
import {
  getPhotoList,
  getSidebarPeopleList,
  getJobQrCodeBySession,
  createJobQrCodeResolution,
  deleteJobQrSessionPhotos,
  createJobQrIgnoreErrors,
  createJobQrSessionMatching
} from '../../actions';
import { useSelector, useDispatch } from 'react-redux';

// Components
import QrRecords from './QrRecords';
import QrPhotos from './QrPhotos';
import QrConfirmation from './QrConfirmation';

// Styles
import './style.css';

// Types
import {
  QrSession,
  QrSessionFilter,
  QrCode,
  QrCodeResolutionAction,
  QrCodeResolutionMethod,
  QrCodeConfirmation,
  QrCodeConfirmationSettings,
  SelectOptionType
} from '@/types';

interface JobsState {
  jobs: {
    job: { id: string };
    sessionQrCodes: QrCode[];
    requesting: boolean;
  };
}

interface QrResolutionProps {
  qrSessions: QrSession[];
  onQrResolutionModalToggle: () => void;
}

const QrResolution: FC<QrResolutionProps> = ({ qrSessions, onQrResolutionModalToggle }) => {
  const dispatch = useDispatch();

  const {
    job: { id: jobId },
    sessionQrCodes,
    requesting
  } = useSelector((state: JobsState) => state.jobs);

  // State
  const [activeSession, setActiveSession] = useState<QrSession | null>(null);
  const [activeSessionOption, setActiveSessionOption] = useState<SingleValue<SelectOptionType>>(null);
  const [sessionFilter, setSessionFilter] = useState<QrSessionFilter | null>(null);
  const [activeQrCode, setActiveQrCode] = useState<QrCode | null>(null);

  const [selectedPhotoId, setSelectedPhotoId] = useState<string | null>(null);
  const [subsequentialPhotosIds, setSubsequentialPhotosIds] = useState<string[] | null>(null);

  const [confirmationSettings, setConfirmationSettings] = useState<QrCodeConfirmation>({
    [QrCodeResolutionAction.PhotoAssociation]: {
      isVisible: false,
      isAlwaysVisible: true
    },
    [QrCodeResolutionAction.CodeIdentification]: {
      isVisible: false,
      isAlwaysVisible: true
    },
    [QrCodeResolutionAction.IgnoreIssue]: {
      isVisible: false,
      isAlwaysVisible: true
    },
    [QrCodeResolutionAction.RestoreQrCode]: {
      isVisible: false,
      isAlwaysVisible: true
    },
    [QrCodeResolutionAction.IgnoreAllIssues]: {
      isVisible: false,
      isAlwaysVisible: true
    },
    [QrCodeResolutionAction.DeleteAllImages]: {
      isVisible: false,
      isAlwaysVisible: true
    }
  });

  const [isMatchNextVisible, setIsMatchNextVisible] = useState<boolean>(false);

  // Helper functions
  const refreshJobGallery = (): void => {
    dispatch(getPhotoList({ id: jobId, page: 1, per_page: 100, reset: true }));
    dispatch(getSidebarPeopleList({ id: jobId, per_page: 10000, order: 'last_name', dir: 'asc' }));
  };

  const formatSessionOption = (qrSession: QrSession): SelectOptionType => ({
    label: (
      <span>
        Session ID - {qrSession.device_name} - {qrSession.user?.full_name}{' '}
        <strong>
          (<span className="text-warning-500">{qrSession.errors_count}</span>/{qrSession.total_count})
        </strong>
      </span>
    ),
    value: qrSession.id
  });

  const filterQrCodes = (): QrCode[] => {
    const hasError = (qrCode: QrCode) => qrCode.resolution_error !== null;

    if (sessionFilter === QrSessionFilter.Issues) return sessionQrCodes.filter((qrCode) => hasError(qrCode) && !qrCode.resolution);
    if (sessionFilter === QrSessionFilter.Resolved) return sessionQrCodes.filter((qrCode) => hasError(qrCode) && qrCode.resolution);

    return sessionQrCodes;
  };

  const getCodeTextStyle = (qrCode: QrCode): string => {
    if (qrCode.discarded_at) return 'text-error-500';
    if (qrCode.resolution_error && !qrCode.resolution) return 'text-warning-500';

    return '';
  };

  const initializeSession = (qrSessionId: string): void => {
    const foundSession = qrSessions.find((qrSession) => qrSession.id === qrSessionId) ?? qrSessions[0];

    setSessionFilter(foundSession.errors_count > 0 ? QrSessionFilter.Issues : QrSessionFilter.All);
    setActiveSession(foundSession);
    setActiveSessionOption(formatSessionOption(foundSession));

    dispatch(getJobQrCodeBySession({ jobId, sessionId: qrSessionId }));
  };

  const resetSessionQrItems = (): void => {
    setActiveQrCode(null);
    setSelectedPhotoId(null);
  };

  const moveToNextQrCode = (): void => {
    const sessionFilteredQrCodes = filterQrCodes();
    const foundCurrIndex = sessionFilteredQrCodes.findIndex((qrCode) => qrCode.id === activeQrCode?.id);

    if (foundCurrIndex < sessionFilteredQrCodes.length - 1) {
      setActiveQrCode(sessionFilteredQrCodes[foundCurrIndex + 1]);
    }
  };

  const setConfirmation = ({ actionType, settings }: { actionType: QrCodeResolutionAction; settings: Partial<QrCodeConfirmationSettings> }): void => {
    setConfirmationSettings((prevSettings) => ({
      ...prevSettings,
      [actionType]: {
        ...prevSettings[actionType],
        ...settings
      }
    }));
  };

  const resolveCodeIdentification = (qrCodeId?: string): void => {
    dispatch(
      createJobQrCodeResolution(
        {
          jobId,
          qrCodeId,
          resolutionMethod: QrCodeResolutionMethod.ResolutionIdentifiedPqr,
          photoId: selectedPhotoId
        },
        () => {
          resetSessionQrItems();
          moveToNextQrCode();
          dispatch(getJobQrCodeBySession({ jobId, sessionId: activeSession?.id }));
        }
      )
    );
  };

  const resolvePhotosAssociation = (qrCodeId?: string): void => {
    dispatch(
      createJobQrCodeResolution(
        {
          jobId,
          qrCodeId,
          resolutionMethod: QrCodeResolutionMethod.ResolutionManualMatch,
          photoIds: [selectedPhotoId, ...(subsequentialPhotosIds ?? [])]
        },
        () => {
          resetSessionQrItems();
          moveToNextQrCode();
          dispatch(getJobQrCodeBySession({ jobId, sessionId: activeSession?.id }));
        }
      )
    );
  };

  const ignoreIssue = (qrCodeId?: string): void => {
    dispatch(
      createJobQrCodeResolution(
        {
          jobId,
          qrCodeId,
          resolutionMethod: QrCodeResolutionMethod.ResolutionIgnored
        },
        () => {
          resetSessionQrItems();
        }
      )
    );
  };

  const restoreQrCode = (qrCodeId?: string): void => {
    dispatch(
      createJobQrCodeResolution(
        {
          jobId,
          qrCodeId,
          resolutionMethod: QrCodeResolutionMethod.ResolutionRestored
        },
        () => {
          resetSessionQrItems();
        }
      )
    );
  };

  const ignoreAllIssues = (): void => {
    dispatch(
      createJobQrIgnoreErrors({ jobId, sessionId: activeSession?.id }, () => {
        resetSessionQrItems();
        setSessionFilter(QrSessionFilter.All);
      })
    );
  };

  const deleteSessionPhotos = (): void => {
    dispatch(
      deleteJobQrSessionPhotos({ jobId, sessionId: activeSession?.id }, () => {
        refreshJobGallery();
        onQrResolutionModalToggle();
      })
    );
  };

  const performResolutionAction = ({ actionType, qrCodeId }: { actionType: QrCodeResolutionAction; qrCodeId?: string }): void => {
    const resolutionActions: Partial<Record<QrCodeResolutionAction, (qrCodeId?: string) => void>> = {
      [QrCodeResolutionAction.CodeIdentification]: resolveCodeIdentification,
      [QrCodeResolutionAction.PhotoAssociation]: resolvePhotosAssociation,
      [QrCodeResolutionAction.IgnoreIssue]: ignoreIssue,
      [QrCodeResolutionAction.RestoreQrCode]: restoreQrCode,
      [QrCodeResolutionAction.IgnoreAllIssues]: ignoreAllIssues,
      [QrCodeResolutionAction.DeleteAllImages]: deleteSessionPhotos
    };

    const actionFunction = resolutionActions[actionType];

    if (actionFunction) {
      actionFunction(qrCodeId);
    }
  };

  // UI Handlers
  const handleSessionChange = (selectedOption: SingleValue<SelectOptionType>): void => initializeSession(selectedOption?.value ?? '');

  const handleSessionFilterChange = (filter: QrSessionFilter): void => {
    resetSessionQrItems();
    setSessionFilter(filter);
  };

  const handleQrCodeChange = ({ event, qrCode }: { event: React.MouseEvent<HTMLElement>; qrCode: QrCode }): void => {
    const isNewQrCodeSelected = activeQrCode?.id !== qrCode.id;

    if (isNewQrCodeSelected) {
      const element = event.currentTarget as HTMLElement;

      const onTransitionEnd = () => {
        element.removeEventListener('transitionend', onTransitionEnd);
        element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      };

      element.addEventListener('transitionend', onTransitionEnd);

      setActiveQrCode(qrCode);
    } else {
      setActiveQrCode(null);
    }

    setSelectedPhotoId(null);
  };

  const handlePhotoSelection = (photoId: string): void => {
    // Add all subsequential photo ids from selected photo id
    const selectedPhotoIndex: number = activeQrCode?.photo_candidates?.findIndex((photo) => photo.id === photoId) ?? 0;
    const subsequentialIds: string[] = activeQrCode?.photo_candidates?.slice(selectedPhotoIndex + 1).map((photo) => photo.id) ?? [];

    setSubsequentialPhotosIds(subsequentialIds);
    setSelectedPhotoId(photoId);
  };

  const handleResolutionActions = ({
    event,
    actionType,
    qrCodeId
  }: {
    event?: React.MouseEvent<HTMLElement>;
    actionType: QrCodeResolutionAction;
    qrCodeId?: string;
  }): void => {
    if (event) event.stopPropagation();

    // 1. Check for confirmation
    if (confirmationSettings[actionType].isAlwaysVisible) {
      // 2. Open confirmation dialog
      setConfirmation({ actionType, settings: { isVisible: true } });
    } else {
      // 2. Perform action
      performResolutionAction({ actionType, qrCodeId });
    }
  };

  const handleResolutionConfirmation = ({
    actionType,
    qrCodeId,
    confirm
  }: {
    actionType: QrCodeResolutionAction;
    qrCodeId?: string;
    confirm: boolean;
  }): void => {
    if (confirm) {
      performResolutionAction({ actionType, qrCodeId });
    }

    setConfirmation({ actionType, settings: { isVisible: false } });
  };

  const handleConfirmationAlwaysVisible = ({ actionType, always }: { actionType: QrCodeResolutionAction; always: boolean }): void => {
    setConfirmation({ actionType, settings: { isAlwaysVisible: always } });
  };

  const handleMatchAndNext = (): void => {
    const currentQrSessionId: string = activeSession!.id;
    const currentQrSessionIndex: number = qrSessions.findIndex((qrSession: QrSession) => qrSession.id === currentQrSessionId);
    const nextQrSessionId: string = qrSessions[currentQrSessionIndex + 1]?.id;

    if (nextQrSessionId) {
      setIsMatchNextVisible(false);
      initializeSession(nextQrSessionId);
    } else {
      refreshJobGallery();
      onQrResolutionModalToggle();
    }

    dispatch(createJobQrSessionMatching({ jobId, sessionId: currentQrSessionId }));
  };

  const handleMatchAndNextClose = (): void => setIsMatchNextVisible(false);

  const handleResolutionDone = (): void => setIsMatchNextVisible(true);

  const handleResolutionModalClose = (): void => onQrResolutionModalToggle();

  useEffect(() => {
    // Set initial values
    const firstQrSession = qrSessions.find((session: QrSession) => session.errors_count > 0) ?? qrSessions[0];

    setActiveSession(firstQrSession);
    setSessionFilter(firstQrSession.errors_count > 0 ? QrSessionFilter.Issues : QrSessionFilter.All);
    setActiveSessionOption(formatSessionOption(firstQrSession));

    dispatch(getJobQrCodeBySession({ jobId, sessionId: firstQrSession?.id }));
  }, []);

  useEffect(() => {
    const foundActiveSession = qrSessions.find((qrSession) => qrSession.id === activeSession?.id);

    if (foundActiveSession) {
      setActiveSession(foundActiveSession);
      setActiveSessionOption(formatSessionOption(foundActiveSession));
    }
  }, [qrSessions]);

  return (
    <aside className="modal animate">
      <div className="modal__box modal__box--secondary modal__box--xxlarge">
        <button className="button button--action modal__close" name="close" type="button" onClick={handleResolutionModalClose}>
          <i className="icon-close"></i>
        </button>

        <main className="job-qr__content modal__content">
          {/* QR Records */}
          <QrRecords
            qrSessions={qrSessions}
            requesting={requesting}
            activeSessionOption={activeSessionOption}
            sessionFilter={sessionFilter}
            activeSession={activeSession}
            activeQrCode={activeQrCode}
            selectedPhotoId={selectedPhotoId}
            formatSessionOption={formatSessionOption}
            filterQrCodes={filterQrCodes}
            getCodeTextStyle={getCodeTextStyle}
            onSessionChange={handleSessionChange}
            onSessionFilterChange={handleSessionFilterChange}
            onQrCodeChange={handleQrCodeChange}
            onResolutionActions={handleResolutionActions}
          />
          {/* QR Photos */}
          <QrPhotos activeQrCode={activeQrCode} selectedPhotoId={selectedPhotoId} getCodeTextStyle={getCodeTextStyle} onPhotoSelection={handlePhotoSelection} />
        </main>
        <footer className="flex justify-end modal__footer modal__footer--secondary">
          <Tooltip {...{ title: 'Please resolve all issues before matching.', disabled: (activeSession?.errors_count ?? 0) === 0 }}>
            <button
              className="button button--outline"
              name="done"
              type="button"
              disabled={Number(activeSession?.errors_count ?? 0) > 0 || Number(activeSession?.resolved_count ?? 0) === 0}
              onClick={handleResolutionDone}
            >
              Done
            </button>
          </Tooltip>
        </footer>

        {/* QR Confirmation Modals */}
        <QrConfirmation
          activeQrCode={activeQrCode}
          selectedPhotoId={selectedPhotoId}
          subsequentialPhotosIds={subsequentialPhotosIds}
          confirmationSettings={confirmationSettings}
          isMatchNextVisible={isMatchNextVisible}
          onConfirmationAlwaysVisible={handleConfirmationAlwaysVisible}
          onResolutionConfirmation={handleResolutionConfirmation}
          onMatchAndNext={handleMatchAndNext}
          onMatchAndNextClose={handleMatchAndNextClose}
        />
      </div>
    </aside>
  );
};

export default QrResolution;
