import * as api from '@/utils/http';

const VITE_STUDIO_URL = import.meta.env.VITE_STUDIO_URL;

export const createStudio = (payload) => {
  const endpoint = 'studio-signup';
  const restricted = false;

  return api.post(endpoint, payload, restricted);
};

export const getWhccLink = (session_id) => {
  const endpoint = 'whcc-login';
  const restricted = false;

  return api.post(
    endpoint,
    {
      callback_url: `${VITE_STUDIO_URL}whcc-callback`,
      session_id: session_id
    },
    restricted
  );
};

export const resendVerificationEmail = (email) => {
  const endpoint = 'register/resend-verification';
  const restricted = false;

  return api.post(endpoint, { email: email }, restricted);
};

export const verify = (token) => {
  const endpoint = 'register/verify';
  const restricted = false;

  return api.post(endpoint, { token: token }, restricted);
};
