export const requiredValidator = (value) => (value ? undefined : 'Required');
export const noEmptyArrayAllowed = (value) => (value.length ? undefined : 'Required');
export const maxLengthValidator = (max) => (value) => (value && value.length > max ? `Must be ${max} characters or less` : undefined);
export const maxLength_5 = maxLengthValidator(5);
export const maxLength_10 = maxLengthValidator(10);
export const maxLength_20 = maxLengthValidator(20);
export const maxLength_50 = maxLengthValidator(50);
export const minLengthValidator = (min) => (value) => (value && value.length < min ? `Must be ${min} characters or more` : undefined);
export const minLength_3 = minLengthValidator(3);
export const numberValidator = (value) => (value && isNaN(Number(value)) ? 'Must be a number' : undefined);
export const minValueValidator = (min) => (value) => (value && value < min ? `Must be at least ${min}` : undefined);
export const maxValueValidator = (max) => (value) => (value && value > max ? `Must be at great ${max}` : undefined);
export const noSpecialCharacters = (value) => {
  return value && /[!@#$%^&*(),.?":{}|<>=]/.test(value) ? 'No special characters' : undefined;
};
export const noSpacesAllowed = (value) => {
  return value && /\s+/.test(value) ? 'No spaces' : undefined;
};
export const emailValidator = (value) => {
  return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i.test(value) ? 'Invalid email format' : undefined;
};
export const accessCodeAlphaNumeric = (value) => {
  return value && /[^A-Za-z0-9-]+/.test(value) ? 'Letters numbers & dashes only' : undefined;
};
export const phoneNumberValidator = (value) =>
  value && !/^(?:\+?1[ .*-]?)?(?:\(? ?)?\d{3}(?: ?\)?)? ?(?:\*|(?:\.|-){1,2})? ?\d{3} ?(?:\*|(?:\.|-){1,2})? ?\d{4}$/.test(value)
    ? 'Invalid phone number, must be 10 digits'
    : undefined;

const matchValidatePropToValidators = (props) => {
  const {
    required,
    maxLength,
    minLength,
    minLength3,
    maxLength5,
    maxLength10,
    maxLength20,
    maxLength50,
    number,
    noSpaces,
    minValue,
    maxValue,
    specialCharacters,
    accessCodeFormat,
    noEmptyArray,
    phoneNumber,
    email
  } = props;

  let validators = [];

  if (required) validators = [...validators, requiredValidator];
  if (maxLength) validators = [...validators, maxLengthValidator(maxLength)];
  if (maxLength5) validators = [...validators, maxLength_5];
  if (maxLength10) validators = [...validators, maxLength_10];
  if (maxLength20) validators = [...validators, maxLength_20];
  if (maxLength50) validators = [...validators, maxLength_50];
  if (minLength) validators = [...validators, minLengthValidator(minLength)];
  if (minLength3) validators = [...validators, minLength_3];
  if (number) validators = [...validators, numberValidator];
  if (noSpaces) validators = [...validators, noSpacesAllowed];
  if (minValue) validators = [...validators, minValueValidator(minValue)];
  if (maxValue) validators = [...validators, maxValueValidator(maxValue)];
  if (specialCharacters) validators = [...validators, noSpecialCharacters];
  if (accessCodeFormat) validators = [...validators, accessCodeAlphaNumeric];
  if (noEmptyArray) validators = [...validators, noEmptyArrayAllowed];
  if (phoneNumber) validators = [...validators, phoneNumberValidator];
  if (email) validators = [...validators, emailValidator];

  return validators;
};

export default matchValidatePropToValidators;
