import { call, put, takeLatest, select, all } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import loginSchema from './schema';
import loginApi from '@/api/auth-api';

import * as actions from './actions';
import * as types from './constants';
import * as userSettingsActions from '../Settings/actions';

import { normalize } from 'normalizr';

import * as storage from '@/utils/storage';
import * as profileApi from '@/api/profile-api';

import errorHandler from '@/utils/errorResponseHandler';

const JWT_TOKEN = import.meta.env.VITE_JWT_TOKEN;
const LOGIN_ENTITIES = import.meta.env.VITE_LOGIN_ENTITIES;

export const parseLoginResponse = (response) => {
  const data = normalize(response.data, loginSchema);
  const { result: jwt, entities } = data;
  const user = response?.data?.user;
  const user_id = user?.id;
  const isAdmin = user?.is_photoday || user?.roles.includes('studios_admin') ? true : false;

  return {
    jwt,
    user_id,
    user_hash: entities?.login[jwt]?.user_hash,
    entities,
    isAuthenticated: true,
    isAdmin
  };
};

export const storeValues = (payload, rememberMe, isAdmin) => {
  const { jwt, entities } = payload;

  storage.set(JWT_TOKEN, jwt, rememberMe);
  storage.set(LOGIN_ENTITIES, JSON.stringify(entities), true);
  storage.set(types.STORAGE_IS_ADMIN, isAdmin, true);
};

export const getLoginEntities = (state) => {
  const {
    login: { entities }
  } = state;

  return entities;
};

export const setProfileFlagsWebStorage = (entities) => {
  storage.set(LOGIN_ENTITIES, JSON.stringify(entities), true);
};

function* initLoginStateFlow() {
  try {
    // Check if LocalStorage is available first
    if (storage.isLocalStorageAvailable() === true) {
      yield put(actions.initSuccess());
    } else {
      yield put(actions.initError());
    }

    const jwt = yield call(storage.get, JWT_TOKEN);
    const json = yield call(storage.get, LOGIN_ENTITIES);

    let isAdmin = yield call(storage.get, types.STORAGE_IS_ADMIN);

    isAdmin = isAdmin === true || isAdmin === 'true';

    if (!isAdmin) isAdmin = false; // In case undefined

    const entities = yield call(JSON.parse, json);

    // Reset login state only for valid store
    if (jwt && entities && entities.login[jwt]) {
      const { user_hash, user: user_id } = entities.login[jwt];

      yield put(actions.resetLoginState({ jwt, user_id, user_hash, entities, isAdmin }));
    }
  } catch (error) {
    yield call(errorHandler, actions.resetLoginStateError, error);
  }
}

function* logoutFlow() {
  try {
    yield put(showLoading());

    yield call(storage.clearAll);

    // Reset user settings state
    yield put(userSettingsActions.resetUserSettingsState());

    yield put(actions.logoutSuccess());
  } catch (error) {
    yield call(errorHandler, actions.loginError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* loginFlow(action) {
  const {
    payload: { email, password, rememberMe }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(loginApi, email, password);
    const payload = yield call(parseLoginResponse, response);

    yield call(storeValues, payload, rememberMe, payload.isAdmin);
    yield put(actions.loginSuccess(payload));

    return payload.jwt;
  } catch (error) {
    yield call(errorHandler, actions.loginError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getProfileFlagsFlow() {
  try {
    yield put(showLoading());

    const { data: flags } = yield call(profileApi.getProfileFlags);

    yield put(actions.getProfileFlagsSuccess(flags));
  } catch (error) {
    yield call(errorHandler, actions.getProfileFlagsError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* setProfileFlagsFlow(action) {
  try {
    yield put(showLoading());

    const {
      payload: { args }
    } = action;
    const { data: flags } = yield call(profileApi.setProfileFlags, args);

    yield put(actions.setProfileFlagsSuccess(flags));

    const entities = yield select(getLoginEntities);
    const jwt = yield call(storage.get, JWT_TOKEN);

    if (jwt && entities) {
      yield call(setProfileFlagsWebStorage, entities);
      yield put(actions.resetLoginState({ jwt, entities }));
    }
  } catch (error) {
    yield call(errorHandler, actions.setProfileFlagsError, error);
  } finally {
    yield put(hideLoading());
  }
}

export function* loginWatcher() {
  yield all([
    takeLatest(types.LOGIN_REQUEST, loginFlow),
    takeLatest(types.GET_PROFILE_FLAGS_REQUEST, getProfileFlagsFlow),
    takeLatest(types.SET_PROFILE_FLAGS_REQUEST, setProfileFlagsFlow),
    takeLatest(types.INIT, initLoginStateFlow),
    takeLatest(types.LOGOUT_REQUEST, logoutFlow)
  ]);
}
