import * as types from './constants';

import defaultPager from '@/utils/defaultPager';
import { handleErrorState, handleRequestingState } from '@/utils/reducerHelpers';

const initialState = {
  result: [],
  entities: {
    orders: {},
    order: {}
  },
  pager: Object.assign({}, defaultPager),
  requesting: false,
  successful: false,
  isSearchRequesting: false,
  errors: [],
  saveSuccessRedirect: false
};

const handleOrderListSuccess = (state, payload) => {
  const { entities, pager, result } = payload;
  const { entities: origEntities } = state;
  const newEntities = Object.assign({}, origEntities, entities);
  return {
    ...state,
    ...{
      result,
      entities: newEntities,
      pager,
      errors: [],
      requesting: false,
      successful: true,
      isSearchRequesting: false
    }
  };
};

const handleOrderDetailSuccess = (state, payload) => {
  const { result } = payload;
  const { data } = result;
  return {
    ...state,
    entities: { ...state.entities, order: data },
    requesting: false,
    successful: true,
    errors: []
  };
};

const handleSetOrderNameSearch = (state, payload) => {
  const { search } = payload;
  const { pager } = state;
  const newPager = { ...pager, ...{ search } };
  return { ...state, ...{ pager: newPager }, isSearchRequesting: true };
};

const handlegetRetouchPhotosSuccess = (state, { payload }) => {
  return {
    ...state,
    entities: { ...state.entities, retouchPhotos: payload.data },
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetOrderReprintsSuccess = (state, { payload }) => {
  return {
    ...state,
    entities: { ...state.entities, reprintRequests: payload.data },
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleCreateOrderReprintSuccess = (state) => {
  return {
    ...state,
    errors: [],
    successful: true,
    requesting: false
  };
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.ORDER_LIST_REQUEST:
    case types.ORDER_DETAIL_REQUEST:
    case types.GET_RETOUCH_PHOTOS_REQUEST:
    case types.GET_ORDER_REPRINTS_REQUEST:
    case types.CREATE_ORDER_REPRINT_REQUEST:
      return handleRequestingState(state);

    case types.ORDER_LIST_SUCCESS:
      return handleOrderListSuccess(state, payload);

    case types.ORDER_DETAIL_LIST_SUCCESS:
      return handleOrderDetailSuccess(state, payload);

    case types.GET_RETOUCH_PHOTOS_SUCCESS:
      return handlegetRetouchPhotosSuccess(state, payload);

    case types.GET_ORDER_REPRINTS_SUCCESS:
      return handleGetOrderReprintsSuccess(state, payload);

    case types.CREATE_ORDER_REPRINT_SUCCESS:
      return handleCreateOrderReprintSuccess(state);

    case types.ORDER_LIST_ERROR:
    case types.ORDER_DETAIL_LIST_ERROR:
    case types.GET_RETOUCH_PHOTOS_ERROR:
    case types.GET_ORDER_REPRINTS_ERROR:
    case types.CREATE_ORDER_REPRINT_ERROR:
      return handleErrorState(state, payload);

    case types.SET_ORDER_NAME_SEARCH:
      return handleSetOrderNameSearch(state, payload);

    default:
      return state;
  }
};

export default reducer;
