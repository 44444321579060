import { useState } from 'react';

// Components
import Navigation from '@/components/Shared/Navigation';

// Styles
import './style.css';

const Header = ({ path }) => {
  const [navigationIsOpen, setNavigationIsOpen] = useState(false);

  const items = [
    { name: 'Collateral', link: '/collateral', icon: 'icon-gear' },
    { name: 'Support', link: '/support', icon: 'icon-gear' }
  ];

  const currentItem = items.find((item) => path.includes(item.link)) || items[0];

  const handleToggleNavigation = () => {
    setNavigationIsOpen(!navigationIsOpen);
  };

  return (
    <header className="resources-header">
      <div className="container flex items-center justify-between">
        <div className="basis-3/12 md:basis-full resources__mobile-header">
          <h1 className="text-headline-md">Resources</h1>
          <button className="button button--outline resources__mobile-action" onClick={handleToggleNavigation}>
            <i className={currentItem.icon} />
            {currentItem.name}
          </button>
        </div>

        <div className="flex basis-9/12 justify-between">
          <Navigation items={items} isOpen={navigationIsOpen} onToggleNavigation={handleToggleNavigation} />
        </div>
      </div>
    </header>
  );
};

export default Header;
