import { useState } from 'react';

// Components
import Navigation from '@/components/Shared/Navigation';

// Styles
import './style.css';

const CUSTOM_THEMES_ENABLED = import.meta.env.VITE_CUSTOM_THEMES_ENABLED === '1';
const KNOCK_OUT_BACKGROUND_ENABLED = import.meta.env.VITE_KNOCK_OUT_BACKGROUND_ENABLED === '1';

const Header = ({ path, user, studioId, studioFeatureFlags, studioLabs }) => {
  const [navigationIsOpen, setNavigationIsOpen] = useState(false);
  const roles = (user && user.roles) || [];
  const canViewPriceSheet = roles.includes('view_price_sheets');

  const studioCustomThemeLabs = studioLabs && studioLabs.length ? studioLabs.filter((lab) => lab.allow_custom_themes).map((lab) => lab.name) : [];
  const showCustomThemes =
    CUSTOM_THEMES_ENABLED && studioId !== 'all-studios' && canViewPriceSheet && studioFeatureFlags?.allow_custom_themes
      ? [...studioFeatureFlags.allow_custom_themes, ...studioCustomThemeLabs].length > 0
      : false;

  const studioBackgroundLabs = studioLabs && studioLabs.length ? studioLabs.filter((lab) => lab.allow_backgrounds).map((lab) => lab.name) : [];
  const showBackgrounds = KNOCK_OUT_BACKGROUND_ENABLED && studioId !== 'all-studios' && canViewPriceSheet && studioBackgroundLabs.length > 0;

  const items = [
    { name: 'Quick Tips', link: '/storefront/quicktips', icon: 'icon-quicktips' },
    {
      name: 'AdvancePay',
      link: '/storefront/advance-pay',
      icon: 'icon-advancepay',
      hidden: studioId !== 'all-studios' && roles.includes('view_gift_cards') ? false : true
    },
    { name: 'Price Sheets', link: '/storefront/price-sheets', icon: 'icon-pricesheet', hidden: roles.includes('view_price_sheets') ? false : true },
    { name: 'Offers', link: '/storefront/offers-and-credits', icon: 'icon-offers', hidden: roles.includes('view_offers') ? false : true },
    { name: 'Promos', link: '/storefront/promos', icon: 'icon-promos', hidden: studioId !== 'all-studios' && roles.includes('view_promos') ? false : true },
    { name: 'Custom Themes', link: '/storefront/custom-themes', icon: 'icon-themes', hidden: showCustomThemes === false },
    {
      name: 'Backgrounds',
      link: '/storefront/backgrounds',
      icon: 'icon-backgrounds',
      hidden: showBackgrounds === false
    },
    { name: 'Orders', link: '/storefront/orders', icon: 'icon-receipt', hidden: roles.includes('view_sales') ? false : true }
  ];

  const currentItem = items.find((item) => path.includes(item.link)) || items[0];

  const handleToggleNavigation = () => {
    setNavigationIsOpen(!navigationIsOpen);
  };

  return (
    <header className="store-header">
      <div className="container flex items-center justify-between">
        <div className="basis-3/12 md:basis-full items-center store-header__mobile-header">
          <h1 className="text-headline-md">Store</h1>
          <button className="store-header__mobile-action button button--outline" onClick={handleToggleNavigation}>
            <i className={currentItem.icon} />
            {currentItem.name}
          </button>
        </div>

        <div className="flex basis-9/12 justify-between">
          <Navigation items={items} isOpen={navigationIsOpen} onToggleNavigation={handleToggleNavigation} />
        </div>
      </div>
    </header>
  );
};

export default Header;
