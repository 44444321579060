import { useState, useEffect } from 'react';

// Plugins
import moment from 'moment';
import Select from 'react-select';

// Components
import Sidebar from '../Sidebar';
import Header from '../../Header';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { updateJob } from '../../actions';
import { getOffersDDLRequest } from '@/components/Shared/OffersDDL/actions';

// Styles
import './style.css';

const Promotions = ({ match, history }) => {
  const dispatch = useDispatch();

  const {
    params: { jobId },
    path
  } = match;

  const { job, requesting: jobRequesting } = useSelector((state) => state.jobs);
  const { options: optionsOffers, requesting: offersDDLRequesting } = useSelector((state) => state.offersDDL);

  const [initialInformation, setInitialInformation] = useState({});
  const [information, setInformation] = useState({});
  const [showApWarningModal, setShowApWarningModal] = useState(false);

  const selectedOffer = optionsOffers.find((offer) => offer.value === information.offer_id);

  const initialSetup = (data) => {
    const { offer } = data;

    setInformation({ offer: data.offer, offer_id: data.offer_id, expiration: offer ? offer.expiration : null, expired: offer ? offer.expired : null });
    setInitialInformation({ offer: data.offer, offer_id: data.offer_id, expiration: offer ? offer.expiration : null, expired: offer ? offer.expired : null });
  };

  const isAlreadyJobApOffer = ({ jobApOfferId, selectedOfferId }) => {
    return jobApOfferId === selectedOfferId ? true : false;
  };

  const saveInformation = ({ jobId, selectedOfferId }) => {
    dispatch(updateJob({ id: jobId, offer_id: selectedOfferId }, ({ data }) => initialSetup(data)));
  };

  const handleApWarningClose = () => setShowApWarningModal(false);

  const handleApWarningConfirm = () => {
    setShowApWarningModal(false);
    saveInformation({ jobId, selectedOfferId: information.offer_id });
  };

  const handleSave = () => {
    // Check if selected offer is as same as job advancepay offer
    if (isAlreadyJobApOffer({ jobApOfferId: job.advancepay_offer?.id, selectedOfferId: information.offer_id })) {
      setShowApWarningModal(true);
    } else {
      saveInformation({ jobId, selectedOfferId: information.offer_id });
    }
  };

  const handleSelectChange = (value) => {
    if (value) {
      setInformation({ ...information, offer_id: value.value, expiration: value.expiration, expired: value.expired });
    } else {
      setInformation({ offer: null, offer_id: null, expiration: null, expired: null });
    }
  };

  const handleOffersRefresh = () => dispatch(getOffersDDLRequest());

  useEffect(() => {
    if (job?.id) {
      initialSetup(job);

      if (!optionsOffers.length) {
        dispatch(getOffersDDLRequest());
      }
    }
  }, [job?.id]);

  return (
    <>
      <Header history={history} jobId={jobId} title="Promotions" />

      <main className="container flex job-settings-promotions">
        <Sidebar jobType={job.access_mode} jobId={jobId} path={path} />

        <section className="basis-9/12 md:basis-full job-settings-store__container">
          <header className="flex justify-between items-center flex-nowrap job-settings-promotions__header">
            <h2 className="text-headline-sm">Gallery Promotions</h2>
            <button
              type="button"
              className="button button--medium"
              onClick={handleSave}
              data-loading={jobRequesting}
              disabled={offersDDLRequesting || information.offer_id === initialInformation.offer_id}
            >
              Save
            </button>
          </header>

          <form className="panel panel--section">
            <fieldset className="job-settings-store__row">
              <h3>Gallery Offer</h3>
              <p className="job-settings-promotions__sub-title">
                Add an offer to be displayed as a banner at the top of your gallery. All customers will have access to this offer. Do not add your AdvancePay
                offer here.{' '}
                <a
                  href="https://support.photoday.io/en/articles/5002408-all-about-offers-advancepay-gallery-offers-and-promos#h_ff06074530"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more.
                </a>
              </p>

              <div className="flex justify-start items-center">
                <div className="job-settings-store__select-container">
                  <Select
                    className="select select--nomargin job-settings-store__select"
                    classNamePrefix="select"
                    isClearable={true}
                    isLoading={offersDDLRequesting}
                    value={optionsOffers.filter((offer) => offer.value === information.offer_id)}
                    options={optionsOffers}
                    onChange={handleSelectChange}
                  />
                  <button className="button button--clean" type="button" onClick={handleOffersRefresh}>
                    <i className="icon-refresh"></i>
                  </button>
                </div>
                <span className="job-settings-store__select-link">
                  or{' '}
                  <a href="/storefront/offers-and-credits" target="_blank" rel="noopener noreferrer">
                    add a new offer
                  </a>
                </span>
              </div>

              <div className="text-body-sm job-settings-promotions__offer-preview">
                {selectedOffer
                  ? `Get ${selectedOffer.description.toLowerCase()} Limit: ${selectedOffer.max_redemptions || 'none'}. Expires: ${
                      selectedOffer.expiration ? moment(selectedOffer.expiration).format('MM/DD/YY') : 'none'
                    }`
                  : ''}
              </div>
            </fieldset>
          </form>
        </section>
      </main>

      {/* AdvancePay offer warning */}
      {showApWarningModal && (
        <aside className="modal animate">
          <div className="modal__box modal__box--medium">
            <main className="modal__content">
              <h2>Heads up!</h2>
              <p>
                This offer is already the AdvancePay offer for the gallery. Selecting it will make the offer available to all customers visiting the gallery.
              </p>
            </main>
            <footer className="modal__footer flex justify-center gap-2.5">
              <button className="button button--medium" name="confirm" type="button" onClick={handleApWarningConfirm}>
                Confirm
              </button>
              <button className="button button--outline button--medium" name="cancel" type="button" onClick={handleApWarningClose}>
                Cancel
              </button>
            </footer>
          </div>
        </aside>
      )}
    </>
  );
};

export default Promotions;
