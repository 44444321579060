import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import matchValidatePropToValidators from './validators';

const Select = ({ name, options = [], label = null, placeholder, onChange, disabled }) => {
  const renderSelect = ({ input, meta: { touched, error } }) => (
    <div>
      <div className="select-caret">
        <select {...input} disabled={disabled}>
          {placeholder && <option value="">{placeholder}</option>}
          {options.map(({ value, label }) => (
            <option key={`select-${name}-${value}`} value={value}>
              {label}
            </option>
          ))}
        </select>
      </div>
      {touched && error && <small className="block text-error-500">{error}</small>}
    </div>
  );

  return (
    <div>
      {label && <label>{label}</label>}
      <Field
        validate={matchValidatePropToValidators({ name, options, label, placeholder, onChange, disabled })}
        name={name}
        component={renderSelect}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    })
  ),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

export default Select;
