import PropTypes from 'prop-types';
import { useState } from 'react';

export default function DeleteOfferModal({ offer, onConfirm, onCancel }) {
  const [confirmText, setConfirmText] = useState('');

  const cancel = () => {
    onCancel && onCancel();
  };

  const confirm = () => {
    if (confirmText.toLowerCase() === 'delete') {
      onConfirm && onConfirm(offer);
    }
  };

  const handleInputOnChange = (event) => {
    setConfirmText(event.target.value);
  };

  const handleInputOnKeyPress = (event) => {
    if (event.key === 'Enter') {
      confirm();
    }
  };

  const { name } = offer;

  return (
    <aside className="modal text-left">
      <div className="modal__box modal__box--small modal__box--nomin">
        <header className="modal__header">
          <button className="button button--action modal__close" name="button" type="button" onClick={cancel}>
            <i className="icon-close"></i>
          </button>
          <h3>Delete an offer</h3>
        </header>
        <main className="modal__content">
          <p>
            Are you sure you want to delete this offer, <strong>{name}</strong>?
            <br />
            This action cannot be undone.
          </p>
          <p>
            Type <b>"delete"</b> to permanently delete this offer.
          </p>
          <input className="input--block" type="text" name="delete" value={confirmText} onChange={handleInputOnChange} maxLength="50" />
        </main>
        <footer className="text-center modal__footer">
          <button
            className="button button--danger button--large"
            name="button"
            type="button"
            onClick={confirm}
            disabled={!(confirmText.toLowerCase() === 'delete')}
          >
            Delete
          </button>
        </footer>
      </div>
    </aside>
  );
}

DeleteOfferModal.propTypes = {
  offer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};
