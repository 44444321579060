// Helpers
import * as api from '@/utils/http';

// Types
import { QrCodeResolutionMethod } from '@/types';

export const getJobQrSessions = ({ jobId }: { jobId: string }) => {
  const endpoint = `capture-qr/${jobId}/qr-sessions`;

  return api.get(endpoint);
};

export const getJobQrCodeBySession = ({ jobId, sessionId }: { jobId: string; sessionId: string }) => {
  const endpoint = `capture-qr/${jobId}/qr-codes`;
  const args = { qr_session_id: sessionId, include_photos: true };

  return api.get(endpoint, args);
};

export const createJobQrCodeResolution = ({
  jobId,
  qrCodeId,
  resolutionMethod,
  photoIds,
  photoId
}: {
  jobId: string;
  qrCodeId: string;
  resolutionMethod: QrCodeResolutionMethod;
  photoIds: string[];
  photoId: string;
}) => {
  const endpoint = `capture-qr/${jobId}/qr-codes/${qrCodeId}/resolve-error`;
  const args = {
    resolution_method: resolutionMethod,
    ...(photoIds ? { photo_ids: photoIds } : {}),
    ...(photoId ? { photo_id: photoId } : {})
  };

  return api.post(endpoint, args);
};

export const deleteJobQrSessionPhotos = ({ jobId, sessionId }: { jobId: string; sessionId: string }) => {
  const endpoint = `capture-qr/${jobId}/qr-sessions/${sessionId}/delete-photos`;

  return api.del(endpoint);
};

export const createJobQrIgnoreErrors = ({ jobId, sessionId }: { jobId: string; sessionId: string }) => {
  const endpoint = `capture-qr/${jobId}/qr-sessions/${sessionId}/ignore-errors`;

  return api.post(endpoint);
};

export const createJobQrSessionMatching = ({ jobId, sessionId }: { jobId: string; sessionId: string }) => {
  const endpoint = `capture-qr/${jobId}/qr-sessions/${sessionId}/perform-matching`;

  return api.post(endpoint);
};
