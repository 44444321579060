import { call, takeLatest, all, put } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import * as types from './constants';
import * as actions from './actions';

import errorHandler from '@/utils/errorResponseHandler';

import * as api from '@/api/resources-api';

function* orderResolutionFlow(action) {
  const {
    payload: {
      payload: { fields },
      callback
    }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.postOrderResolutionForm, { ...fields });

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createOrderResolutionError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getResourcesWatcher() {
  yield all([takeLatest(types.ORDER_RESOLUTION_REQUEST, orderResolutionFlow)]);
}

export default getResourcesWatcher;
