import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { isEqual } from 'lodash';
import Select from 'react-select';

import matchValidatePropToValidators from '../validators';

// Styles
import './style.css';

const ReactSelect = ({
  name,
  options = [],
  label = null,
  resetOnUpdate = false,
  disabled = false,
  loading = false,
  multi = false,
  required = false,
  showRefresh = false,
  onRefresh = null,
  onChange,
  placeholder,
  dataCy
}) => {
  const inputRef = useRef(null);

  const filteredOptions = (options, multi) => {
    const filtered = options?.filter((item) => {
      if (typeof inputRef.current.value === 'string') {
        return multi ? inputRef.current.value.includes(item.value) : item.value === inputRef.current.value;
      } else {
        if (multi) {
          return inputRef.current.value.map(({ value }) => value).includes(item.value);
        }
        return item.value === inputRef.current.value && inputRef.current.value.value;
      }
    });

    return multi ? filtered : filtered[0];
  };

  const handleChange = (input) => (value) => {
    onChange && onChange(value);
    input.onChange(value);
  };

  const renderSelect = ({ input, meta: { touched, error } }) => {
    inputRef.current = input;

    return (
      <div>
        <div style={{ display: 'flex', width: '100%' }}>
          <Select
            {...input}
            className="select basis-full"
            classNamePrefix="select"
            onChange={handleChange(input)}
            isDisabled={disabled || loading}
            isMulti={multi}
            isLoading={loading}
            onBlur={() => input.onBlur(undefined)}
            clearable={!required}
            options={options}
            placeholder={placeholder}
            data-cy={dataCy}
          />
          {showRefresh && <i role="button" className="icon-refresh" style={{ padding: '10px' }} onClick={onRefresh} />}
        </div>
        {touched && error && <small className="block text-error-500">{error}</small>}
      </div>
    );
  };

  useEffect(() => {
    if (inputRef.current && !isEqual(inputRef.current.props?.options, options)) {
      inputRef.current.onChange(resetOnUpdate ? null : filteredOptions(options, multi));
    }
  }, [options, resetOnUpdate, multi]);

  return (
    <div id="shared__forms__react_select">
      <div>
        {label && <label>{label}</label>}
        <Field
          loading={loading}
          name={name}
          component={renderSelect}
          validate={matchValidatePropToValidators({
            options,
            label,
            resetOnUpdate,
            disabled,
            loading,
            multi,
            required,
            showRefresh,
            onRefresh,
            onChange,
            placeholder
          })}
        />
      </div>
    </div>
  );
};

ReactSelect.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    })
  ),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  resetOnUpdate: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  multi: PropTypes.bool,
  required: PropTypes.bool,
  showRefresh: PropTypes.bool,
  onRefresh: PropTypes.func,
  onChange: PropTypes.func
};

export default ReactSelect;
