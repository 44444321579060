const matchNormalizePropToNormalizers = (props, value) => {
  if (typeof props === 'object') {
    if (props?.maxLength) {
      if (value.length <= props.maxLength) {
        return value;
      } else {
        return value.slice(0, props.maxLength);
      }
    }
  }

  return value;
};

export default matchNormalizePropToNormalizers;
