import { FC } from 'react';

// Helpers
import { convertToText } from '@/utils/currency';

// Images
import image_retouch from '@/assets/images/addons-basic-retouching.png';

// Types
import { Photo } from '@/types';

interface OrderBasicRetouchProps {
  orderImages: { [key: string]: Photo };
  basicRetouchPriceCents: number;
  basicRetouchingCount: number;
  basicRetouchSubtotal: number;
  retouchArray: {
    photo_id: string;
    enabled: boolean;
  }[];
}

const OrderBasicRetouch: FC<OrderBasicRetouchProps> = ({ orderImages, basicRetouchPriceCents, basicRetouchingCount, basicRetouchSubtotal, retouchArray }) => (
  <div className="order-product__row">
    <div className="order-product__container">
      <div className="order-image__wrapper">
        <img className="order-image" src={image_retouch} alt="basic retouching" width="100" />
      </div>
      <div className="order-product__item-details">
        <p className="order-invoice__label">Basic Retouching </p>
        <p>{basicRetouchingCount > 1 && <small>{`${basicRetouchingCount} photos at ${convertToText(basicRetouchPriceCents, '$')} each`}</small>}</p>
      </div>
    </div>
    <div className="selected-images__container"></div>
    <div className="order-product__details">
      <div className="order-invoice__label">Selected Photos:</div>
      <ul>
        {retouchArray.map((photo: { photo_id: string }, index) => {
          const orderImage = orderImages[photo.photo_id];

          return (
            <li key={index}>
              <span className="order-invoice__label">Photo Name: </span>
              {orderImage?.image_filename}
            </li>
          );
        })}
      </ul>
    </div>
    <div className="order-product__unit-cost">{convertToText(basicRetouchSubtotal, '$')}</div>
  </div>
);

export default OrderBasicRetouch;
