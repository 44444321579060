export const GET_STUDIO_THEME_COLLECTIONS_REQUEST = 'GET_STUDIO_THEME_COLLECTIONS_REQUEST';
export const GET_STUDIO_THEME_COLLECTIONS_SUCCESS = 'GET_STUDIO_THEME_COLLECTIONS_SUCCESS';
export const GET_STUDIO_THEME_COLLECTIONS_ERROR = 'GET_STUDIO_THEME_COLLECTIONS_ERROR';

export const CREATE_THEME_COLLECTION_REQUEST = 'CREATE_THEME_COLLECTION_REQUEST';
export const CREATE_THEME_COLLECTION_SUCCESS = 'CREATE_THEME_COLLECTION_SUCCESS';
export const CREATE_THEME_COLLECTION_ERROR = 'CREATE_THEME_COLLECTION_ERROR';

export const GET_THEME_COLLECTION_REQUEST = 'GET_THEME_COLLECTION_REQUEST';
export const GET_THEME_COLLECTION_SUCCESS = 'GET_THEME_COLLECTION_SUCCESS';
export const GET_THEME_COLLECTION_ERROR = 'GET_THEME_COLLECTION_ERROR';

export const UPDATE_THEME_COLLECTION_REQUEST = 'UPDATE_THEME_COLLECTION_REQUEST';
export const UPDATE_THEME_COLLECTION_SUCCESS = 'UPDATE_THEME_COLLECTION_SUCCESS';
export const UPDATE_THEME_COLLECTION_ERROR = 'UPDATE_THEME_COLLECTION_ERROR';

export const DELETE_THEME_COLLECTION_REQUEST = 'DELETE_THEME_COLLECTION_REQUEST';
export const DELETE_THEME_COLLECTION_SUCCESS = 'DELETE_THEME_COLLECTION_SUCCESS';
export const DELETE_THEME_COLLECTION_ERROR = 'DELETE_THEME_COLLECTION_ERROR';

export const GET_ALL_CUSTOM_THEME_LABS_REQUEST = 'GET_ALL_CUSTOM_THEME_LABS_REQUEST';
export const GET_ALL_CUSTOM_THEME_LABS_SUCCESS = 'GET_ALL_CUSTOM_THEME_LABS_SUCCESS';
export const GET_ALL_CUSTOM_THEME_LABS_ERROR = 'GET_ALL_CUSTOM_THEME_LABS_ERROR';

export const GET_LAB_CUSTOM_THEME_PRODUCTS_REQUEST = 'GET_LAB_CUSTOM_THEME_PRODUCTS_REQUEST';
export const GET_LAB_CUSTOM_THEME_PRODUCTS_SUCCESS = 'GET_LAB_CUSTOM_THEME_PRODUCTS_SUCCESS';
export const GET_LAB_CUSTOM_THEME_PRODUCTS_ERROR = 'GET_LAB_CUSTOM_THEME_PRODUCTS_ERROR';

export const GET_CUSTOM_THEME_PRODUCT_PREVIEW_REQUEST = 'GET_CUSTOM_THEME_PRODUCT_PREVIEW_REQUEST';
export const GET_CUSTOM_THEME_PRODUCT_PREVIEW_SUCCESS = 'GET_CUSTOM_THEME_PRODUCT_PREVIEW_SUCCESS';
export const GET_CUSTOM_THEME_PRODUCT_PREVIEW_ERROR = 'GET_CUSTOM_THEME_PRODUCT_PREVIEW_ERROR';

export const GET_PHOTODAY_THEME_COLLECTIONS_REQUEST = 'GET_PHOTODAY_THEME_COLLECTIONS_REQUEST';
export const GET_PHOTODAY_THEME_COLLECTIONS_SUCCESS = 'GET_PHOTODAY_THEME_COLLECTIONS_SUCCESS';
export const GET_PHOTODAY_THEME_COLLECTIONS_ERROR = 'GET_PHOTODAY_THEME_COLLECTIONS_ERROR';

export const GET_PHOTODAY_THEME_COLLECTIONS_BY_LAB_REQUEST = 'GET_PHOTODAY_THEME_COLLECTIONS_BY_LAB_REQUEST';
export const GET_PHOTODAY_THEME_COLLECTIONS_BY_LAB_SUCCESS = 'GET_PHOTODAY_THEME_COLLECTIONS_BY_LAB_SUCCESS';
export const GET_PHOTODAY_THEME_COLLECTIONS_BY_LAB_ERROR = 'GET_PHOTODAY_THEME_COLLECTIONS_BY_LAB_ERROR';
