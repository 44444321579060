import * as types from './constants';
import { handleErrorState, handleRequestingState } from '@/utils/reducerHelpers';

const initialState = {
  collections: [],
  collection: {},
  background: {},
  pagination: { page: 1, perPage: 15, total: 0 },

  errors: [],
  requesting: false,
  successful: false
};

const handleCreateBackgroundsCollectionSuccess = (state, { payload }) => {
  return {
    ...state,
    collections: [...state.collections, payload.data],
    collection: payload.data,
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetBackgroundsCollectionListSuccess = (state, { payload }) => {
  const { data, headers } = payload;

  return {
    ...state,
    collections: data,
    pagination: {
      page: Number(headers['x-page']),
      perPage: Number(headers['x-per-page']),
      total: Number(headers['x-total'])
    },
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetBackgroundsCollectionSuccess = (state, { payload }) => {
  return {
    ...state,
    collection: payload.data,
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetBackgroundSuccess = (state, { payload }) => {
  return {
    ...state,
    background: payload.data,
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleUpdateBackgroundsCollectionSuccess = (state, { payload }) => {
  const { data } = payload;
  const updatedCollections = state.collections.map((collection) => (collection.id === data.id ? data : collection));

  return {
    ...state,
    collections: updatedCollections,
    collection: data,
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleDeleteBackgroundsCollectionSuccess = (state, { payload }) => {
  const { collections } = state;
  const { collectionId } = payload;

  return {
    ...state,
    collections: collections.filter((collection) => collection.id !== collectionId),
    collection: {},
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleResetBackgroundSuccess = (state) => {
  return { ...state, background: {} };
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.CREATE_BACKGROUNDS_COLLECTION_REQUEST:
    case types.GET_BACKGROUNDS_COLLECTION_LIST_REQUEST:
    case types.GET_BACKGROUNDS_COLLECTION_REQUEST:
    case types.GET_BACKGROUND_REQUEST:
    case types.UPDATE_BACKGROUNDS_COLLECTION_REQUEST:
    case types.DELETE_BACKGROUNDS_COLLECTION_REQUEST:
    case types.RESET_BACKGROUND_REQUEST:
      return handleRequestingState(state);

    case types.CREATE_BACKGROUNDS_COLLECTION_SUCCESS:
      return handleCreateBackgroundsCollectionSuccess(state, payload);

    case types.GET_BACKGROUNDS_COLLECTION_LIST_SUCCESS:
      return handleGetBackgroundsCollectionListSuccess(state, payload);

    case types.GET_BACKGROUNDS_COLLECTION_SUCCESS:
      return handleGetBackgroundsCollectionSuccess(state, payload);

    case types.GET_BACKGROUND_SUCCESS:
      return handleGetBackgroundSuccess(state, payload);

    case types.UPDATE_BACKGROUNDS_COLLECTION_SUCCESS:
      return handleUpdateBackgroundsCollectionSuccess(state, payload);

    case types.DELETE_BACKGROUNDS_COLLECTION_SUCCESS:
      return handleDeleteBackgroundsCollectionSuccess(state, payload);

    case types.RESET_BACKGROUND_SUCCESS:
      return handleResetBackgroundSuccess(state);

    case types.CREATE_BACKGROUNDS_COLLECTION_ERROR:
    case types.GET_BACKGROUNDS_COLLECTION_LIST_ERROR:
    case types.GET_BACKGROUNDS_COLLECTION_ERROR:
    case types.GET_BACKGROUND_ERROR:
    case types.DELETE_BACKGROUNDS_COLLECTION_ERROR:
    case types.UPDATE_BACKGROUNDS_COLLECTION_ERROR:
      return handleErrorState(state, payload);

    default:
      return state;
  }
};

export default reducer;
