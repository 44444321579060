import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

const DeleteModal = ({ isVisible, title, children, entity, currentId, toggleModal, onConfirm, requesting }) => {
  const [deleteText, setDeleteText] = useState('');
  const [currentIdState, setCurrentIdState] = useState(currentId);

  const close = () => {
    toggleModal(false);
  };

  const handleDelete = () => {
    onConfirm(currentIdState);
  };

  const handleInputOnChange = (evt) => {
    evt.preventDefault();
    setDeleteText(evt.target.value);
  };

  useEffect(() => {
    setCurrentIdState(currentId);
  }, [currentId]);

  return (
    <aside className={`modal ${isVisible ? '' : 'transparent'} text-left`}>
      <div className="modal__box modal__box--small modal__box--nomin">
        <header className="modal__header">
          <button className="button button--action modal__close" name="button" type="button" onClick={close}>
            <i className="icon-close"></i>
          </button>
          <h3>{title}</h3>
        </header>
        <main className="modal__content">
          {children}
          <p>
            Type <b>"delete"</b> to permanently delete this {entity}.
          </p>
          <input className="input--block" type="text" name="delete" value={deleteText} onChange={handleInputOnChange} maxLength="50" />
        </main>
        <footer className="text-center modal__footer">
          <button
            className="button button--danger button--large"
            name="button"
            type="button"
            onClick={handleDelete}
            data-loading={requesting}
            disabled={requesting || !(deleteText.toLowerCase() === 'delete')}
          >
            Delete
          </button>
        </footer>
      </div>
    </aside>
  );
};

DeleteModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  entity: PropTypes.string.isRequired,
  currentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]).isRequired,
  toggleModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

export default DeleteModal;
