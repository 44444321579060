import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import matchValidatePropToValidators from './validators';

const Textarea = ({ name, label, placeholder, rows = '6', className = '', subMessage = '', onInput = null, transformValueOnChange, children, required }) => {
  const renderTextarea = ({ input, meta: { touched, error } }) => {
    const { onChange } = input;

    const handleOnChange = (event) => {
      onChange(transformValueOnChange ? transformValueOnChange(event.target.value) : event.target.value);
    };

    return (
      <div>
        <div>
          <textarea {...input} rows={rows} placeholder={placeholder} className={className || 'input--block'} onChange={handleOnChange} onInput={onInput} />
          {children}
        </div>
        {touched && error && <small className="block text-error-500">{error}</small>}
      </div>
    );
  };

  return (
    <div>
      {label && (
        <label>
          {label}
          {subMessage && <span className="sub-message">{subMessage}</span>}
        </label>
      )}
      <Field name={name} component={renderTextarea} validate={matchValidatePropToValidators({ required })} />
    </div>
  );
};

Textarea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  rows: PropTypes.string,
  className: PropTypes.string,
  subMessage: PropTypes.string,
  onInput: PropTypes.func,
  transformValueOnChange: PropTypes.func,
  children: PropTypes.node,
  required: PropTypes.bool
};

export default Textarea;
