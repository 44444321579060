import {
  OFFER_LIST_REQUEST,
  OFFER_LIST_SUCCESS,
  OFFER_LIST_ERROR,
  OFFER_GENERATE_CODE_REQUEST,
  OFFER_GENERATE_CODE_SUCCESS,
  OFFER_GENERATE_CODE_ERROR,
  CREATE_OFFER_REQUEST,
  CREATE_OFFER_SUCCESS,
  CREATE_OFFER_ERROR,
  UPDATE_OFFER_REQUEST,
  UPDATE_OFFER_SUCCESS,
  UPDATE_OFFER_ERROR,
  DESTROY_OFFER_REQUEST,
  DESTROY_OFFER_SUCCESS,
  DESTROY_OFFER_ERROR,
  PAUSE_OFFER_REQUEST,
  PAUSE_OFFER_SUCCESS,
  PAUSE_OFFER_ERROR,
  UNPAUSE_OFFER_REQUEST,
  UNPAUSE_OFFER_SUCCESS,
  UNPAUSE_OFFER_ERROR
} from './constants';

export const offerGenerateCodeRequest = (studioId) => ({
  type: OFFER_GENERATE_CODE_REQUEST,
  payload: {
    studioId
  }
});

export const offerGenerateCodeRequestSuccess = (code) => ({
  type: OFFER_GENERATE_CODE_SUCCESS,
  payload: {
    code
  }
});

export const offerGenerateCodeRequestError = (error) => ({
  type: OFFER_GENERATE_CODE_ERROR,
  payload: {
    error
  }
});

export const destroyOfferRequest = ({ offerId }, callback) => ({
  type: DESTROY_OFFER_REQUEST,
  payload: {
    offerId,
    callback
  }
});

export const destroyOfferRequestSuccess = ({ offerId }) => ({
  type: DESTROY_OFFER_SUCCESS,
  payload: {
    offerId
  }
});

export const destroyOfferRequestError = (error) => ({
  type: DESTROY_OFFER_ERROR,
  payload: {
    error
  }
});

// Generic actions
const actionOfferRequest =
  (type) =>
  ({ studioId, offer }, callback) => ({
    type: type,
    payload: {
      studioId,
      offer,
      callback
    }
  });

const actionOfferSuccess =
  (type) =>
  ({ studioId, offer }) => ({
    type: type,
    payload: {
      studioId,
      offer
    }
  });

const actionOfferError = (type) => (error) => ({
  type: type,
  payload: {
    error
  }
});

export const offerListRequest = ({ page, perPage, order, dir, search, searchField, full }, callback) => ({
  type: OFFER_LIST_REQUEST,
  payload: {
    page,
    perPage,
    order,
    dir,
    search,
    searchField,
    full
  },
  callback
});

export const offerListRequestSuccess = ({ entities, pager, result }) => ({
  type: OFFER_LIST_SUCCESS,
  payload: {
    entities,
    pager,
    result
  }
});

export const offerListRequestError = (error) => ({
  type: OFFER_LIST_ERROR,
  payload: {
    error
  }
});

export const createOfferRequest = actionOfferRequest(CREATE_OFFER_REQUEST);
export const createOfferRequestSuccess = actionOfferSuccess(CREATE_OFFER_SUCCESS);
export const createOfferRequestError = actionOfferError(CREATE_OFFER_ERROR);

export const updateOfferRequest = actionOfferRequest(UPDATE_OFFER_REQUEST);
export const updateOfferRequestSuccess = actionOfferSuccess(UPDATE_OFFER_SUCCESS);
export const updateOfferRequestError = actionOfferError(UPDATE_OFFER_ERROR);

export const pauseOfferRequest = actionOfferRequest(PAUSE_OFFER_REQUEST);
export const pauseOfferRequestSuccess = actionOfferSuccess(PAUSE_OFFER_SUCCESS);
export const pauseOfferRequestError = actionOfferError(PAUSE_OFFER_ERROR);

export const unpauseOfferRequest = actionOfferRequest(UNPAUSE_OFFER_REQUEST);
export const unpauseOfferRequestSuccess = actionOfferSuccess(UNPAUSE_OFFER_SUCCESS);
export const unpauseOfferRequestError = actionOfferError(UNPAUSE_OFFER_ERROR);
