import { useState, useEffect } from 'react';
import { NavLink, Link, Switch, Route, Redirect } from 'react-router-dom';

// Plugins
import moment from 'moment-timezone';

// Components
import Sidebar from '../Sidebar';
import Header from '../../Header';
import DropShip from './DropShip';
import BulkShip from './BulkShip';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { updateJob } from '../../actions';
import { addressValidate } from '@/components/Shared/AddressVerification/actions';
import { getPricesheetRequest } from '../../../Storefront/PriceSheets/actions';

// Helpers
import { pickerDate } from '@/utils/displayFormats';

// Images
import imageDropShip from '@/assets/images/icon-drop-ship.png';
import imageBulkShip from '@/assets/images/icon-bulk-ship.png';

// Styles
import './style.css';

const Shipping = function Shipping({ match, history }) {
  const dispatch = useDispatch();

  const {
    params: { jobId, section },
    path
  } = match;
  const { job, requesting: jobRequesting } = useSelector((state) => state.jobs);
  const { lab_id: jobLabId, lab_name: jobLabName } = job.meta || {};

  const {
    entities: { pricesheet },
    requesting: pricesheetRequesting
  } = useSelector((state) => state.pricesheets);
  const { requesting: addressRequesting } = useSelector((state) => state.address);

  const requesting = jobRequesting || pricesheetRequesting || addressRequesting;

  const [initialInformation, setInitialInformation] = useState({});
  const [information, setInformation] = useState({
    expires_at: '',
    recipient: '',
    line1: '',
    line2: '',
    city: '',
    state: '',
    zip: '',
    notes: '',
    bulk_ship_group: []
  });

  const setInitialValues = (job) => {
    if (job.bulk_ship) {
      const { expires_at, recipient, address, notes } = job.bulk_ship;

      const { bulk_ship_group, bulk_ship_group_fields } = job;

      const values = { expires_at: moment(expires_at).toDate(), recipient, ...address, notes, bulk_ship_group, bulk_ship_group_fields };

      setInformation(values);
      setInitialInformation(values);
    } else {
      const { bulk_ship_group_fields } = job;

      const values = { ...information, bulk_ship_group: bulk_ship_group_fields, bulk_ship_group_fields };
      setInformation(values);
      setInitialInformation(values);
    }
  };

  const handleRemoveGroupField = (group) => {
    const newBulkShipGroup = information.bulk_ship_group.filter((item) => item !== group);
    setInformation({ ...information, bulk_ship_group: newBulkShipGroup });
  };

  const handleResetGroupList = () => {
    setInformation({ ...information, bulk_ship_group: information?.bulk_ship_group_fields });
  };

  const handleSave = ({ isAddressConfirmed }) => {
    dispatch(
      addressValidate(
        {
          line1: information.line1,
          zip: information.zip,
          ...(information.city && { city: information.city }),
          ...(information.state && { state: information.state })
        },
        ({ deliverability, components }) => {
          if (!isAddressConfirmed) {
            setInformation({ ...information, addressDeliverability: deliverability, state: components?.state });
          }

          if (deliverability === 'deliverable' || isAddressConfirmed) {
            const formValues = {
              expires_at: pickerDate(information.expires_at),
              recipient: information.recipient,
              line1: information.line1,
              line2: information.line2,
              zip: components?.zip_code ?? information.zip,
              city: information.city,
              state: information.state,
              notes: information.notes.trim()
            };
            dispatch(updateJob({ id: jobId, shipping_type: 'bulk', bulk_ship: formValues, bulk_ship_group: information.bulk_ship_group }));
          }
        }
      )
    );
  };

  useEffect(() => {
    if (job?.id) setInitialValues(job);
    if (job?.price_sheet_id) dispatch(getPricesheetRequest(job.price_sheet_id));
  }, [job?.id]);

  return (
    <>
      <Header history={history} jobId={jobId} title="Shipping" />

      <main className="container flex job-settings-shipping">
        <Sidebar jobType={job.access_mode} jobId={jobId} path={path} shippingSection={section} />

        <form
          className="basis-9/12 md:basis-full job-settings-shipping__form"
          onSubmit={(e) => {
            e.preventDefault();
            handleSave({ isAddressConfirmed: false });
          }}
        >
          <header className="flex justify-between items-center job-settings-shipping__header">
            <h2 className="text-headline-sm">Shipping</h2>
            <button
              type="submit"
              className="button button--medium"
              disabled={section !== 'bulk' || requesting || JSON.stringify(information) === JSON.stringify(initialInformation)}
              data-loading={requesting}
            >
              Save
            </button>
          </header>

          <aside className="flex flex-row">
            <div className="basis-6/12">
              <NavLink
                className={`panel panel--nomargin job-settings-shipping__nav ${
                  pricesheet?.shipping_type?.includes('drop') ? '' : 'job-settings-shipping__nav--disabled'
                }`}
                to={`/jobs/${jobId}/settings/shipping/drop`}
                activeClassName="job-settings-shipping__nav--active"
              >
                <div className="flex gap-5 middle">
                  <figure className="sm:hidden">
                    <img src={imageDropShip} alt="Drop Ship" height="38" />
                  </figure>
                  <hgroup>
                    <h2 className="text-headline-sm">Drop Ship</h2>
                    <h5 className="text--normal sm:hidden">Ship orders directly to the customer.</h5>
                  </hgroup>
                </div>
              </NavLink>
            </div>
            <div className="basis-6/12">
              <NavLink
                className={`panel panel--nomargin job-settings-shipping__nav ${
                  job.access_mode === 'access_per_subject' && pricesheet?.shipping_type?.includes('bulk') ? '' : 'job-settings-shipping__nav--disabled'
                }`}
                to={`/jobs/${jobId}/settings/shipping/bulk`}
                activeClassName="job-settings-shipping__nav--active"
              >
                <div className="flex gap-5 middle">
                  <figure className="sm:hidden">
                    <img src={imageBulkShip} alt="Bulk Ship" height="48" />
                  </figure>
                  <hgroup>
                    <h2 className="text-headline-sm">Bulk Ship</h2>
                    <h5 className="text--normal sm:hidden">Ship orders for free to one location.</h5>
                  </hgroup>
                </div>
              </NavLink>
            </div>
          </aside>

          {job.price_sheet_id && (
            <Switch>
              {(job.access_mode !== 'access_per_subject' || (pricesheet.id && !pricesheet?.shipping_type?.includes('bulk'))) && (
                <Redirect exact from="/jobs/:jobId/settings/shipping/bulk" to="/jobs/:jobId/settings/shipping/drop" />
              )}
              <Route path="/jobs/:jobId/settings/shipping/drop" render={() => <DropShip labId={jobLabId} labName={jobLabName} shippingType={'drop'} />} />
              <Route
                path="/jobs/:jobId/settings/shipping/bulk"
                render={() => (
                  <BulkShip
                    labId={jobLabId}
                    labName={jobLabName}
                    shippingType={'bulk'}
                    information={information}
                    onSetInformation={(newInformation) => setInformation(newInformation)}
                    onSave={handleSave}
                    onResetGroupList={handleResetGroupList}
                    onRemoveGroupField={handleRemoveGroupField}
                  />
                )}
              />
            </Switch>
          )}

          {!requesting && !job.price_sheet_id && (
            <div className="flex items-center justify-center panel panel--tall animate">
              <p>
                You must <Link to={`/jobs/${jobId}/settings/store/products`}>set up a price sheet</Link> first in order to see estimated delivery.
              </p>
            </div>
          )}
        </form>
      </main>
    </>
  );
};

export default Shipping;
