import React from 'react';
import PropTypes from 'prop-types';

import ProductSelector from './ProductSelector';
import { NestedField } from '@/components/Shared/Forms';

const BogoRequiredOptions = ({ productCategories }) => {
  return (
    <div id="giveaway-offer-bogo-step1" className="form-segment">
      <h4 className="font-bold">Buy...</h4>
      <p> If the customer purchases any of the products below… </p>
      <NestedField name="required_products_attributes" component={ProductSelector} productCategories={productCategories} required={true} />
    </div>
  );
};

BogoRequiredOptions.propTypes = {
  productCategories: PropTypes.array
};

export default BogoRequiredOptions;
