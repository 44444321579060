import * as types from './constants';

import { handleErrorState, handleRequestingState } from '@/utils/reducerHelpers';

const initialState = {
  successful: false,
  requesting: false,
  registerStage: 1,
  studio: {
    name: '',
    phone: '',
    web_url: '',
    address_attributes: {}
  },
  user: {
    first_name: '',
    last_name: '',
    email: ''
  },
  lab: {},
  meta: {
    types_of_photography: null,
    subjects_per_year: null,
    hear_about_us: null,
    hear_about_us_specify: null,
    years_in_business: null,
    next_picture_day: null
  },
  errors: []
};

const handleSuccessGetWhccLink = (state, payload) => ({
  ...state,
  ...payload,
  successful: true,
  requesting: false
});

const handleSuccessRegister = (state, payload) => ({
  ...state,
  ...payload,
  successful: true,
  requesting: false,
  registerStage: state.registerStage + 1
});

const handleSuccessVerify = (state, payload) => ({
  ...state,
  ...payload,
  successful: true,
  verificationSucceeded: true,
  requesting: false
});

const handleFailedVerify = (state, payload) => ({
  ...state,
  ...payload,
  successful: true,
  verificationFailed: true,
  requesting: false
});

const handleSetStudioInformationSuccess = (state, payload) => {
  return {
    ...state,
    studio: { ...state.studio, ...payload.studio },
    user: { ...state.user, ...payload.user },
    lab: { ...state.lab, ...payload.lab },
    meta: { ...state.meta, ...payload.meta },
    registerStage: state.registerStage === 1 ? 2 : state.registerStage + 1,
    requesting: false,
    successful: true
  };
};

const handleSetWhccStudioInformationSuccess = (state, payload) => ({
  ...state,
  ...payload,
  registerStage: types.REGISTER_STAGE_INFORMATION,
  requesting: false,
  successful: true
});

const handleSetRegisterStageSuccess = (state, payload) => ({
  ...state,
  ...{ registerStage: payload }
});

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.REGISTER_REQUEST:
      return handleRequestingState(state);
    case types.REGISTER_SUCCESS:
      return handleSuccessRegister(state, payload);
    case types.VERIFICATION_REQUEST:
      return handleRequestingState(state);
    case types.VERIFICATION_SUCCESS:
      return handleSuccessVerify(state, payload);
    case types.VERIFICATION_ERROR:
      return handleFailedVerify(state, payload);
    case types.SET_STUDIO_INFORMATION_SUCCESS:
      return handleSetStudioInformationSuccess(state, payload);
    case types.SET_REGISTER_STAGE_SUCCESS:
      return handleSetRegisterStageSuccess(state, payload);
    case types.GET_WHCC_LINK_REQUEST:
      return handleRequestingState(state);
    case types.GET_WHCC_LINK_SUCCESS:
      return handleSuccessGetWhccLink(state, payload);
    case types.GET_WHCC_STUDIO_INFORMATION_REQUEST:
      return handleRequestingState(state);
    case types.GET_WHCC_STUDIO_INFORMATION_SUCCESS:
      return handleSetWhccStudioInformationSuccess(state, payload);
    case types.GET_WHCC_LINK_ERROR:
    case types.REGISTER_ERROR:
    case types.RESEND_VERIFICATION_ERROR:
    case types.GET_WHCC_STUDIO_INFORMATION_ERROR:
      return handleErrorState(state, payload);
    default:
      return state;
  }
};

export default reducer;
