import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import Pagination from '@/components/Shared/Pagination';
import TableLoadingState from './TableLoadingState';
import TableLoader from '@/components/Shared/ContentLoader/TableLoader';

// Plugins
import { Tooltip } from 'react-tippy';

// Helpers
import Dropdown from './Dropdown';

// Styles
import 'react-tippy/dist/tippy.css';

const PhotoDayGrid = (props) => {
  const {
    headers: initialHeaders,
    pager,
    fetchRecordsPage,
    requesting = false,
    children,
    loadingContent = <TableLoadingState />,
    defaultContent = 'There is no data to be displayed.',
    table_id = `table_${Math.floor(Math.random() * (9999 - 1000 + 1) + 1000)}`,
    extraClasses = null
  } = props;

  const [headers, setHeaders] = useState(initialHeaders);
  const [sortable, setSortable] = useState(null);
  const [filterable, setFilterable] = useState(null);

  const handlePagerOnClick = (page) => {
    const newPager = { ...pager, page };
    fetchRecordsPage(newPager);
  };

  const setNewHeadersState = (cell) => {
    const newHeaders = resetHeaders(headers).map((h) => (h.fieldName === cell.fieldName ? cell : h));
    setHeaders(newHeaders);
  };

  const resetHeaders = (headers) => headers.map((h) => ({ ...h, direction: '', filter: '' }));

  const handleFilterOnClick = (event) => {
    const newPager = { ...pager, page: 1, filter: event };
    fetchRecordsPage(newPager);
  };

  const sortRecords = (cell) => {
    const { fieldName: order, direction: dir } = cell;
    const newPager = { ...pager, page: 1, order, dir };
    fetchRecordsPage(newPager);
  };

  const handleSortOnClick = (cell) => {
    if (!cell.sortable || requesting) return;

    const newCell = { ...cell, direction: nextSortDirection(cell.direction) };
    setSortable(newCell.fieldName);
    setNewHeadersState(newCell);
    sortRecords(newCell);
  };

  const nextSortDirection = (direction) => {
    switch (direction || '') {
      case '':
        return 'ASC';
      case 'ASC':
        return 'DESC';
      default:
        return '';
    }
  };

  const onClick = (td) => {
    if (td.sortable === true) {
      handleSortOnClick(td);
    }
  };

  const content = () => {
    if (!children || (Array.isArray(children) && !children.length)) {
      if (requesting && typeof loadingContent !== 'string')
        return React.cloneElement(loadingContent, {
          cells: headers.length,
          rows: pager.perPage
        });

      return (
        <tr>
          <td colSpan={headers.length}>{requesting ? loadingContent : defaultContent}</td>
        </tr>
      );
    }
    return children;
  };

  const sortClass = (direction) => {
    return classNames({
      fa: true,
      'fa-long-arrow-up': direction === 'DESC',
      'fa-long-arrow-down': direction === 'ASC'
    });
  };

  const headerStyle = (sortable, filterable) =>
    sortable ? { cursor: 'pointer', whiteSpace: 'nowrap' } : filterable ? { cursor: 'pointer', whiteSpace: 'nowrap' } : null;

  const headerCells = headers.map((td, i) => {
    let { fieldName, displayName, sortable, filterable, direction, tooltip } = td;

    if (fieldName === pager.order) direction = pager.dir;

    const sortStyle = {
      fontSize: 12,
      marginLeft: 2
    };

    const sortIcon = sortable ? <i className={sortClass(direction)} style={sortStyle} /> : null;

    const filterDropdown = filterable?.length ? (
      <Dropdown buttonName={'filter'} buttonExtraClass={'button--filter'}>
        <ul className="panel panel-dropdown panel-dropdown--small panel-dropdown--left">
          {filterable.map((filter) => (
            <li
              key={filter.value}
              className={`panel-dropdown__item ${filter.value === pager.filter ? 'panel-dropdown__item--active' : ''}`}
              onClick={handleFilterOnClick}
            >
              {filter.name}
            </li>
          ))}
        </ul>
      </Dropdown>
    ) : null;

    if (fieldName === 'price_sheet_type') {
      return false;
    }

    return (
      <th style={headerStyle(sortable, filterable)} onClick={() => onClick(td)} key={i}>
        {displayName} {filterDropdown} {sortIcon}
        {sortable && sortable === fieldName} {filterable && filterable === fieldName}
        {tooltip && (
          <Tooltip {...{ title: tooltip, position: 'bottom', trigger: 'mouseenter', size: 'big' }}>
            <i className="fa fa-question-circle-o" />
          </Tooltip>
        )}
      </th>
    );
  });

  const tableStyle = {
    width: '100%'
  };
  const tableClasses = (extraClasses ? `table-container ${extraClasses}` : 'table-container') + (requesting ? ' requesting' : '');

  useEffect(() => {
    if (requesting && !props.requesting && sortable && filterable) {
      setSortable(null);
      setFilterable(null);
    }
  }, [requesting, props.requesting, sortable, filterable]);

  return (
    <>
      <article className={`table-box ${tableClasses}`}>
        <table id={table_id} className={`table ${tableStyle}`}>
          <thead className="table__header">
            <tr>{headerCells}</tr>
          </thead>
          <tbody className="table__body">
            {requesting ? (
              <tr>
                <td colSpan="10">
                  <TableLoader rows={extraClasses === 'shipping-table' ? 2 : 15} rowHeight={60} />
                </td>
              </tr>
            ) : (
              content()
            )}
          </tbody>
        </table>
      </article>
      {pager.total > pager.perPage && <Pagination pagination={pager} onPagination={handlePagerOnClick} />}
    </>
  );
};

PhotoDayGrid.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      displayName: PropTypes.string,
      sortable: PropTypes.bool,
      direction: PropTypes.string
    })
  ).isRequired,
  table_id: PropTypes.string,
  extraClasses: PropTypes.string,
  pager: PropTypes.shape({
    page: PropTypes.number,
    perPage: PropTypes.number,
    total: PropTypes.number,
    totalPages: PropTypes.number,
    order: PropTypes.string,
    dir: PropTypes.string,
    search: PropTypes.string
  }),
  fetchRecordsPage: PropTypes.func,
  defaultContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  loadingContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  requesting: PropTypes.bool
};

export default PhotoDayGrid;
