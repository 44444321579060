import * as types from './constants';

export const initSuccess = () => ({
  type: types.INIT_SUCCESS
});

export const initError = () => ({
  type: types.INIT_ERROR
});

export const loginRequest = ({ email, password, rememberMe, history }) => ({
  type: types.LOGIN_REQUEST,
  payload: {
    email,
    password,
    rememberMe,
    history
  }
});

export const loginSuccess = ({ jwt, user_id, user_hash, entities, isAuthenticated, isAdmin }) => ({
  type: types.LOGIN_SUCCESS,
  payload: {
    jwt,
    user_id,
    user_hash,
    entities,
    isAuthenticated,
    isAdmin
  }
});

export const loginError = (error) => ({
  type: types.LOGIN_ERROR,
  payload: {
    error
  }
});

export const logoutRequest = () => ({
  type: types.LOGOUT_REQUEST
});

export const logoutSuccess = () => ({
  type: types.LOGOUT_SUCCESS
});

export const getProfileFlagsRequest = () => ({
  type: types.GET_PROFILE_FLAGS_REQUEST
});

export const getProfileFlagsSuccess = (flags) => ({
  type: types.GET_PROFILE_FLAGS_SUCCESS,
  payload: {
    flags
  }
});

export const getProfileFlagsError = (error) => ({
  type: types.GET_PROFILE_FLAGS_ERROR,
  payload: {
    error
  }
});

export const setProfileFlagsRequest = (args) => ({
  type: types.SET_PROFILE_FLAGS_REQUEST,
  payload: {
    args
  }
});

export const setProfileFlagsSuccess = (flags) => ({
  type: types.SET_PROFILE_FLAGS_SUCCESS,
  payload: {
    flags
  }
});

export const setProfileFlagsError = (error) => ({
  type: types.SET_PROFILE_FLAGS_ERROR,
  payload: {
    error
  }
});

export const updateLoginStudio = (payload) => ({
  type: types.UPDATE_LOGIN_STUDIO_SUCCESS,
  payload: {
    payload
  }
});

export const updateLoginStudioLabs = (payload) => ({
  type: types.UPDATE_LOGIN_STUDIO_LABS_SUCCESS,
  payload: {
    payload
  }
});

export const resetLoginState = ({ jwt, user_id, user_hash, entities, isAdmin }) => ({
  type: types.RESET_STATE,
  payload: {
    jwt,
    user_id,
    user_hash,
    entities,
    isAdmin
  }
});

export const resetLoginStateError = (error) => ({
  type: types.RESET_STATE_ERROR,
  payload: {
    error
  }
});

export const expiredToken = () => ({
  type: types.JWT_EXPIRED
});
