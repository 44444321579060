import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import { ReactSelect } from '@/components/Shared/Forms';

import Select from 'react-select';

const mapStateToProps = (state, ownProps) => {
  const { name, pricesheetItems = [] } = ownProps;
  const productId = formValueSelector('OfferForm')(state, `${name}.id`);

  let selected = { products: [] };

  if (pricesheetItems) {
    selected = pricesheetItems.find(({ products }) => products.map(({ value }) => value).includes(productId));
  }

  return { selected: selected, pricesheetItems };
};

const ProductsForm = ({ name, index, onRemove, pricesheetItems, selected }) => {
  const [state, setState] = useState({
    products: (selected || {}).products,
    selected: selected
  });

  const onChangeSelect = (selected) => {
    setState({ products: (selected || {}).products, selected });
  };

  const { products } = state;

  return (
    <div>
      <div className="basis-6/12">
        <Select onChange={onChangeSelect} options={pricesheetItems} value={state.selected} />
      </div>
      <div className=" basis-5/12">
        <ReactSelect name={`${name}.id`} options={products} />
      </div>
      {index > 0 && (
        <div className="basis-1/12">
          <button className="button button--dark" onClick={onRemove}>
            <i className="fa fa-trash-o" aria-hidden="true" />
          </button>
        </div>
      )}
    </div>
  );
};

ProductsForm.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
  pricesheetItems: PropTypes.array
};

export default connect(mapStateToProps)(ProductsForm);
