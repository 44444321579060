import { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// Components
import TopNav from '@/components/Shared/TopNav';
import JobsList from './List';
import JobsNotFound from './NotFound';
import JobsDashboard from './Dashboard';
import JobsGallery from './Gallery';
import JobsPeople from './Gallery/People/List';
import JobsInsightsCustomers from './Insights/Customers/';
import JobsInsightsSales from './Insights/Sales/';
import JobsMarketing from './Marketing';
import JobsServices from './Services';
import JobsShipping from './Shipping';
import JobsSettingsDetails from './Settings/Details';
import JobsSettingsAccess from './Settings/Access';
import JobsSettingsStore from './Settings/Store';
import JobsSettingsStoreCustomThemes from './Settings/Store/Products/CustomThemes';
import JobsSettingsStoreBackgrounds from './Settings/Store/Products/Backgrounds';
import JobsSettingsPromotions from './Settings/Promotions';
import JobsSettingsShipping from './Settings/Shipping';
import JobsSettingsYearbook from './Settings/Yearbook';
import JobsAdminSms from './Admin/Sms';

// Helpers
import PrivateRoute from '@/components/Shared/PrivateRoute';

const Jobs: FC = () => {
  return (
    <>
      <TopNav />

      <section className="job">
        <Switch>
          <Route path="/jobs/:jobId/notfound" component={JobsNotFound} />

          <PrivateRoute path="/jobs/:jobId/dashboard" component={JobsDashboard} requiredPermission={'manage_jobs'} />
          <PrivateRoute path="/jobs/:jobId/people" component={JobsPeople} requiredPermission={'manage_jobs'} />
          <PrivateRoute path="/jobs/:jobId/gallery" component={JobsGallery} requiredPermission={'manage_jobs'} />

          <Redirect exact from="/jobs/:jobId/insights" to="/jobs/:jobId/insights/sales" />
          <PrivateRoute path="/jobs/:jobId/insights/customers" component={JobsInsightsCustomers} requiredPermission={'manage_jobs'} />
          <PrivateRoute path="/jobs/:jobId/insights/sales" component={JobsInsightsSales} requiredPermission={'manage_jobs'} />

          <PrivateRoute path="/jobs/:jobId/marketing" component={JobsMarketing} requiredPermission={'manage_jobs'} />

          <PrivateRoute path="/jobs/:jobId/services" component={JobsServices} requiredPermission={'manage_jobs'} />
          <PrivateRoute path="/jobs/:jobId/shipping" component={JobsShipping} requiredPermission={'manage_jobs'} />

          <Redirect exact from="/jobs/:jobId/settings" to="/jobs/:jobId/settings/details" />
          <PrivateRoute path="/jobs/:jobId/settings/details" component={JobsSettingsDetails} requiredPermission={'manage_jobs'} />
          <PrivateRoute path="/jobs/:jobId/settings/access" component={JobsSettingsAccess} requiredPermission={'manage_jobs'} />

          <PrivateRoute path="/jobs/:jobId/settings/store/custom-themes" component={JobsSettingsStoreCustomThemes} requiredPermission={'manage_jobs'} />
          <PrivateRoute path="/jobs/:jobId/settings/store/backgrounds" component={JobsSettingsStoreBackgrounds} requiredPermission={'manage_jobs'} />
          <PrivateRoute path="/jobs/:jobId/settings/store" component={JobsSettingsStore} requiredPermission={'manage_jobs'} />

          <PrivateRoute path="/jobs/:jobId/settings/promotions" component={JobsSettingsPromotions} requiredPermission={'manage_jobs'} />
          <PrivateRoute path="/jobs/:jobId/settings/shipping/:section?" component={JobsSettingsShipping} requiredPermission={'manage_jobs'} />
          <PrivateRoute path="/jobs/:jobId/settings/Yearbook" component={JobsSettingsYearbook} requiredPermission={'manage_jobs'} />

          <Redirect exact from="/jobs/:jobId/admin" to="/jobs/:jobId/admin/sms" />
          <PrivateRoute path="/jobs/:jobId/admin/sms" component={JobsAdminSms} requiredPermission={'manage_jobs'} />

          <Route component={JobsList} />
        </Switch>
      </section>
    </>
  );
};

export default Jobs;
