import { FC, useRef, useState, useEffect } from 'react';

// Plugins
import { Tooltip } from 'react-tippy';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { createPeopleYearbookSelection, getSubject, getPeopleList } from '../../../actions';

// Components
import Lightbox from '@/components/Shared/Lightbox';
import GridLoader from '@/components/Shared/ContentLoader/GridLoader';
import TextLoader from '@/components/Shared/ContentLoader/TextLoader';

// Helpers
import imageScaling from '@/utils/imageScaling';

// Images
import imagePeopleThumbnailPlaceholder from '@/assets/images/people-thumbnail-placeholder.png';

// Types
import { Subject, Photo } from '@/types';

interface YearbookSelectionModalProps {
  personSelectedId: string;
  onPhotosPeopleFilter: (subject: Subject) => void;
  onSelectionModalClose: () => void;
}

const YearbookSelectionModal: FC<YearbookSelectionModalProps> = ({ personSelectedId, onPhotosPeopleFilter, onSelectionModalClose }) => {
  const dispatch = useDispatch();
  const poseOneRef = useRef<HTMLInputElement>(null);
  const poseTwoRef = useRef<HTMLInputElement>(null);

  const {
    job,
    people: { list: peopleList, subject: currSubject, requesting: peopleRequesting }
  } = useSelector((state: any) => state.jobs);

  const shouldDisableSave: boolean = peopleRequesting || currSubject.photos?.length === 0;

  // State
  const [showLightbox, setShowLightbox] = useState<boolean>(false);
  const [showPhotoPoseSelection, setShowPhotoPoseSelection] = useState<boolean>(false);

  const [selectedPhotoId, setSelectedPhotoId] = useState<string>();
  const [selectedSubjectPoses, setSelectedSubjectPoses] = useState<{ poseOnePhotoId?: string; poseTwoPhotoId?: string }>();

  // Helpers
  const setCurrentSubject = (currSubjectId: string): void => {
    dispatch(
      getSubject({ id: currSubjectId, with_yearbook_selection: true, with_photos: true }, ({ data }: { data: Subject }) => {
        const poseOnePhotoId: string = data.yearbook_photos_photo_1_image_id;
        const poseTwoPhotoId: string = data.yearbook_photos_photo_2_image_id;

        setSelectedSubjectPoses({
          ...(poseOnePhotoId ? { poseOnePhotoId } : {}),
          ...(poseTwoPhotoId ? { poseTwoPhotoId } : {})
        });
      })
    );
  };

  // UI Handlers
  const handleLightboxOpen = (selectedPhotoId: string): void => {
    setSelectedPhotoId(selectedPhotoId);
    setShowLightbox(true);
  };
  const handleLightboxClose = (): void => {
    setSelectedPhotoId(undefined);
    setShowLightbox(false);
  };

  const handlePhotoPoseOpen = (selectedPhotoId: string): void => {
    setSelectedPhotoId(selectedPhotoId);
    setShowPhotoPoseSelection(true);
  };
  const handlePhotoPoseClose = (): void => {
    setSelectedPhotoId(undefined);
    setShowPhotoPoseSelection(false);
  };
  const handlePhotoPoseUpdate = (): void => {
    const poseOneElem = poseOneRef.current!;
    const poseTwoElem = poseTwoRef.current!;

    const poseOnePhotoId: string = String(poseOneElem.value);
    const poseOnePhotoChecked: boolean = !!poseOneElem.checked;

    const poseTwoPhotoId: string = String(poseTwoElem?.value);
    const poseTwoPhotoChecked: boolean = !!poseTwoElem?.checked;

    setSelectedSubjectPoses((prevState) => ({
      ...prevState,
      ...(poseOnePhotoChecked ? { poseOnePhotoId } : !poseOnePhotoChecked && prevState?.poseOnePhotoId === poseOnePhotoId ? { poseOnePhotoId: undefined } : {}),
      ...(poseTwoPhotoChecked ? { poseTwoPhotoId } : !poseTwoPhotoChecked && prevState?.poseTwoPhotoId === poseTwoPhotoId ? { poseTwoPhotoId: undefined } : {})
    }));

    setShowPhotoPoseSelection(false);
  };

  const handleSaveContinue = (): void => {
    dispatch(
      createPeopleYearbookSelection(
        {
          subjectId: currSubject.id,
          photo_1_id: selectedSubjectPoses?.poseOnePhotoId ?? null,
          photo_2_id: selectedSubjectPoses?.poseTwoPhotoId ?? null
        },
        () => {
          // Reset current state
          setSelectedPhotoId(undefined);
          setSelectedSubjectPoses(undefined);

          // Set next subject
          const currSubjectIndex: number = peopleList.findIndex((subject: Subject) => subject.id === currSubject.id);
          const nextSubjectIndex: number = currSubjectIndex === peopleList.length - 1 ? 0 : currSubjectIndex + 1;
          const nextSubjectId: string = peopleList[nextSubjectIndex]?.id;

          setCurrentSubject(nextSubjectId);
        }
      )
    );
  };

  const handleSave = (): void => {
    dispatch(
      createPeopleYearbookSelection(
        {
          subjectId: currSubject.id,
          photo_1_id: selectedSubjectPoses?.poseOnePhotoId ?? null,
          photo_2_id: selectedSubjectPoses?.poseTwoPhotoId ?? null
        },
        () => {
          // Re-call Subjects
          dispatch(getPeopleList({ id: job?.id, with_yearbook_selection: true, per_page: 10000, order: 'last_name', dir: 'asc' }));

          onSelectionModalClose();
        }
      )
    );
  };

  useEffect(() => {
    setCurrentSubject(personSelectedId);
  }, []);

  return (
    <>
      <aside className="modal animate">
        <div className="modal__box modal__box--large">
          <header className="flex justify-between items-center gap-5 flex-nowrap mb-10">
            {peopleRequesting ? (
              <TextLoader height={80} />
            ) : (
              <>
                <LazyLoadImage
                  className="image--cover"
                  src={currSubject.session_photo_url ? imageScaling({ url: currSubject.session_photo_url, size: 'xsmall' }) : imagePeopleThumbnailPlaceholder}
                  alt={currSubject.first_name ?? ''}
                  height={40}
                  width={40}
                  draggable="false"
                />
                <dl className="flex justify-start nowrap">
                  <div className="flex flex-col">
                    <dt className="font-bold">Subject Name</dt>
                    <dd>
                      {currSubject.first_name} {currSubject.last_name}
                    </dd>
                  </div>
                  <div className="flex flex-col">
                    <dt className="font-bold">Access Code</dt>
                    <dd>{currSubject.access_id ?? '-----'}</dd>
                  </div>
                  <div className="flex flex-col">
                    <dt className="font-bold">Student Id</dt>
                    <dd>{currSubject.student_id ?? '-----'}</dd>
                  </div>
                  <div className="flex flex-col">
                    <dt className="font-bold">Grade</dt>
                    <dd>{currSubject.grade ?? '-----'}</dd>
                  </div>
                  <div className="flex flex-col">
                    <dt className="font-bold">Teacher</dt>
                    <dd>{currSubject.teacher_name ?? '-----'}</dd>
                  </div>
                  <div className="flex flex-col">
                    <dt className="font-bold">School</dt>
                    <dd>{currSubject.school ?? '-----'}</dd>
                  </div>
                </dl>
                <button className="button button--outline" type="button" name="detail" onClick={() => onPhotosPeopleFilter(currSubject)}>
                  Detail
                </button>
              </>
            )}
          </header>
          <main className="modal__content">
            {peopleRequesting ? (
              <TextLoader height={40} />
            ) : (
              <>
                <p>
                  To change the current yearbook selection, select photo(s) below. Changing selections before the deadline will send email messages to the
                  customer.
                </p>
                <span
                  className={`italic font-bold ${Object.keys(selectedSubjectPoses ?? {})?.length < currSubject.yearbook_photos_required_count ? 'text-error-500' : 'text-primary-blue-500'}`}
                >
                  {Object.keys(selectedSubjectPoses ?? {})?.length} of {currSubject.yearbook_photos_required_count} selections made
                </span>
              </>
            )}
            <section className="h-600 max-h-600 overflow-auto mt-5">
              {peopleRequesting ? (
                <GridLoader rows={1} columns={4} gap={20} minHeight={264} />
              ) : (
                <>
                  {currSubject.photos?.length ? (
                    <div className="job-photo-grid__row animate">
                      {currSubject.photos.map((subjectPhoto: Photo) => (
                        <div key={subjectPhoto.id} className="job-photo-grid__item" draggable="false">
                          {showPhotoPoseSelection && selectedPhotoId === subjectPhoto.id ? (
                            <div className="job-photo-card__figure">
                              <header className="job-photo-card__image-header">
                                <span className="job-photo-card__image-name">{subjectPhoto.image_filename}</span>
                                <button
                                  className="button button--outline button--small button--noborder"
                                  type="button"
                                  name="close"
                                  onClick={handlePhotoPoseClose}
                                >
                                  <i className="icon-close"></i>
                                </button>
                              </header>
                              <form className="flex-auto p-2.5 animate">
                                <fieldset className="mb-0">
                                  <input
                                    ref={poseOneRef}
                                    id="poseOne"
                                    className="hidden"
                                    type="checkbox"
                                    name="poseOnePhotoId"
                                    value={subjectPhoto.id}
                                    defaultChecked={selectedSubjectPoses?.poseOnePhotoId === subjectPhoto.id}
                                  />
                                  <label htmlFor="poseOne" className="label--checkbox">
                                    {job?.yearbook_selection_photo_1_title}
                                  </label>
                                </fieldset>
                                {job?.yearbook_selection_photo_count === 2 && (
                                  <fieldset>
                                    <input
                                      ref={poseTwoRef}
                                      id="poseTwo"
                                      className="hidden"
                                      type="checkbox"
                                      name="poseTwoPhotoId"
                                      value={subjectPhoto.id}
                                      defaultChecked={selectedSubjectPoses?.poseTwoPhotoId === subjectPhoto.id}
                                    />
                                    <label htmlFor="poseTwo" className="label--checkbox">
                                      {job?.yearbook_selection_photo_2_title}
                                    </label>
                                  </fieldset>
                                )}
                              </form>
                              <footer className="p-2.5">
                                <button className="button button--outline button--block" type="button" name="update" onClick={handlePhotoPoseUpdate}>
                                  Update
                                </button>
                              </footer>
                            </div>
                          ) : (
                            <div className="job-photo-card__figure animate">
                              <header className="job-photo-card__image-header">
                                <span className="job-photo-card__image-name">{subjectPhoto.image_filename}</span>
                                <Tooltip {...{ title: 'Expand View', arrow: false }}>
                                  <button
                                    className="button button--outline button--small button--noborder"
                                    type="button"
                                    name="view"
                                    onClick={() => handleLightboxOpen(subjectPhoto.id)}
                                  >
                                    <i className="icon-view"></i>
                                  </button>
                                </Tooltip>
                              </header>
                              <figure className="job-photo-card__image-container">
                                <LazyLoadImage
                                  className="job-photo-card__image"
                                  src={imageScaling({ url: subjectPhoto.image_url ?? null, size: 'small' })}
                                  effect="opacity"
                                  draggable={false}
                                  alt={subjectPhoto.image_filename}
                                />
                              </figure>
                              <footer className="job-photo-card__actions">
                                {/* Pose pills */}
                                <aside>
                                  {selectedSubjectPoses?.poseOnePhotoId === subjectPhoto.id && (
                                    <span className="pill pill--blue pill--xsmall mr-1">{job?.yearbook_selection_photo_1_title}</span>
                                  )}
                                  {selectedSubjectPoses?.poseTwoPhotoId === subjectPhoto.id && (
                                    <span className="pill pill--blue pill--xsmall">{job?.yearbook_selection_photo_2_title}</span>
                                  )}
                                </aside>
                                <Tooltip {...{ title: 'Pose Selection', arrow: false }}>
                                  <button
                                    className="button button--outline button--small button--noborder"
                                    type="button"
                                    name="pose"
                                    onClick={() => handlePhotoPoseOpen(subjectPhoto.id)}
                                  >
                                    <i className="icon-pose"></i>
                                  </button>
                                </Tooltip>
                              </footer>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <aside className="flex items-center justify-center panel panel--tall panel--nomargin animate">
                      <h3 className="m-0">No photos were found.</h3>
                    </aside>
                  )}
                </>
              )}
            </section>
          </main>
          <footer className="modal__footer flex justify-end gap-2.5">
            <button className="button button--outline" type="button" name="cancel" onClick={onSelectionModalClose}>
              Cancel
            </button>
            <button
              className="button button--blue-outline"
              type="button"
              name="saveContinue"
              data-loading={peopleRequesting}
              disabled={shouldDisableSave}
              onClick={handleSaveContinue}
            >
              Save & Continue
            </button>
            <button className="button" type="button" name="save" data-loading={peopleRequesting} disabled={shouldDisableSave} onClick={handleSave}>
              Save
            </button>
          </footer>
        </div>
      </aside>

      {/* Lightbox */}
      {showLightbox && (
        <Lightbox
          lightboxPhotos={currSubject.photos
            ?.filter((photo: Photo) => photo.id === selectedPhotoId)
            .map((photo: Photo) => ({ imageUrl: photo.image_url, imageName: photo.image_filename }))}
          onLightboxClose={handleLightboxClose}
        />
      )}
    </>
  );
};

export default YearbookSelectionModal;
