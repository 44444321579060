import { FC } from 'react';
import { Switch, Route } from 'react-router-dom';

// Components
import ExportsList from './List';
import ExportsSettings from './Settings';
import ExportsReview from './Review';

// Styles
import './style.css';

const Exports: FC = () => {
  return (
    <Switch>
      <Route exact path="/jobs/:jobId/services/exports/:id/settings" component={ExportsSettings} />
      <Route exact path="/jobs/:jobId/services/exports/:id/review" component={ExportsReview} />
      <Route component={ExportsList} />
    </Switch>
  );
};

export default Exports;
