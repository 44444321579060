import React, { useState } from 'react';

// Plugins
import { toast, Slide } from 'react-toastify';
import { NumericFormat } from 'react-number-format';

// Styles
import 'react-toastify/dist/ReactToastify.min.css';

const MIN_CREDIT_VALUE = 5;
const MAX_CREDIT_VALUE = 500;

const CreateOrEditGiftCardModal = ({ giftCard, onSave, onCancel, onDelete, valuesGiftCards, requesting }) => {
  const [selectedValue, setSelectedValue] = useState(giftCard ? giftCard.amount_cents.toString().slice(0, -2) : MIN_CREDIT_VALUE);
  const [description, setDescription] = useState(giftCard ? giftCard.description : '');

  const save = () => {
    if (checkAmount(selectedValue)) {
      onSave && onSave(giftCard, selectedValue, description);
    }
  };

  const cancel = () => {
    onCancel && onCancel();
  };

  const remove = () => {
    onDelete && onDelete(giftCard.id);
  };

  const modalTitle = giftCard ? 'Edit Credit' : 'Add Credit';

  const modalSubTitle = giftCard
    ? `To edit a credit value, please select a dollar amount below. You can also enter your own dollar amount $${MIN_CREDIT_VALUE} increments. Limit ${MAX_CREDIT_VALUE} per credit created.`
    : `To add a credit value to the credit sheet, please select a dollar amount below. You can also enter your own dollar amount in $${MIN_CREDIT_VALUE} increments. Limit ${MAX_CREDIT_VALUE} per credit created.`;

  const selectValue = (value = 0) => {
    const valString = String(value) ?? '';
    const newValue = valString?.charAt() === '0' ? valString?.slice(1) : valString;

    if (newValue?.length > 3) {
      setSelectedValue(newValue.slice(0, 3) || 0);
    } else {
      setSelectedValue(newValue || 0);
    }
  };

  const checkAmount = (value) => {
    const options = {
      autoClose: 3000,
      transition: Slide,
      closeOnClick: false,
      pauseOnHover: false,
      position: 'top-right',
      type: 'error'
    };

    if (value % MIN_CREDIT_VALUE !== 0) {
      toast(`The dollar amount must be in $${MIN_CREDIT_VALUE} increments.`, options);
      return false;
    }

    if (value > MAX_CREDIT_VALUE) {
      toast(`The limit is $${MAX_CREDIT_VALUE} per credit.`, options);
      return false;
    }

    return true;
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  return (
    <aside id="add-credit-modal" className="modal animate">
      <div className="modal__box">
        <header className="modal__header">
          <button className="button button--action modal__close" name="button" type="button" onClick={cancel}>
            <i className="icon-close"></i>
          </button>
          <h3>{modalTitle}</h3>
        </header>
        <main className="modal__content">
          <p>{modalSubTitle}</p>
          <div className="credit-amount-container">
            {valuesGiftCards.includes(2500) ? (
              <div className="credit-amount-number used-credit">$25</div>
            ) : (
              <div className={selectedValue === 25 ? 'credit-amount-number enabled-credit' : 'credit-amount-number'} onClick={() => selectValue(25)}>
                {' '}
                $25
              </div>
            )}
            {valuesGiftCards.includes(5000) ? (
              <div className="credit-amount-number used-credit">$50</div>
            ) : (
              <div className={selectedValue === 50 ? 'credit-amount-number enabled-credit' : 'credit-amount-number'} onClick={() => selectValue(50)}>
                {' '}
                $50
              </div>
            )}
            {valuesGiftCards.includes(7500) ? (
              <div className="credit-amount-number used-credit">$75</div>
            ) : (
              <div className={selectedValue === 75 ? 'credit-amount-number enabled-credit' : 'credit-amount-number'} onClick={() => selectValue(75)}>
                {' '}
                $75
              </div>
            )}
            {valuesGiftCards.includes(10000) ? (
              <div className="credit-amount-number used-credit">$100</div>
            ) : (
              <div className={selectedValue === 100 ? 'credit-amount-number enabled-credit' : 'credit-amount-number'} onClick={() => selectValue(100)}>
                {' '}
                $100
              </div>
            )}
            <div className="credit-amount-number credit-amount-number--custom">
              <NumericFormat
                className="input--block custom-credit-amount"
                prefix={'$'}
                value={selectedValue}
                allowNegative={false}
                isAllowed={({ floatValue }) => Number(floatValue) <= MAX_CREDIT_VALUE || floatValue === undefined}
                onValueChange={(values) => selectValue(values.floatValue ?? MIN_CREDIT_VALUE)}
              />
            </div>
          </div>

          <div>
            <p>Add an optional description that will let customers know what they can use this credit for.</p>
            <textarea className="textarea--block" rows="3" value={description} maxLength="310" onChange={handleDescriptionChange} />
          </div>
        </main>
        <footer className="modal__footer flex flex-col items-center gap-2.5">
          {giftCard && (
            <button className="button button--clean button--large" name="remove" type="button" onClick={remove}>
              Remove Credit
            </button>
          )}
          <button className="button button--large" type="button" disabled={requesting || selectedValue < 1} data-loading={requesting} onClick={save}>
            Save
          </button>
          <button className="button button--outline button--large" onClick={cancel}>
            Cancel
          </button>
        </footer>
      </div>
    </aside>
  );
};

export default CreateOrEditGiftCardModal;
