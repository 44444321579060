import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
import { getWhccLinkRequest } from '../../actions';

// Plugins
import { ToastContainer } from 'react-toastify';

// Components
import FbPixel from '@/components/SignUp/Shared/FbPixel';
import Container from '@/components/SignUp/Shared/Container';

// Images
import whcc_logo from '@/assets/images/whcc_logo.png';

const mapStateToProps = ({ signUp: { redirect_uri, session_id, login_url, successful } }) => ({
  redirect_uri,
  session_id,
  login_url,
  successful
});
const mapDispatchToProps = { getWhccLinkRequest };

const ConnectWHCC = ({ lab, successful, redirect_uri, getWhccLinkRequest }) => {
  const location = useLocation();

  useEffect(() => {
    getWhccLinkRequest();
  }, []);

  const openWHCCAuth = () => {
    if (redirect_uri) {
      window.location = `${redirect_uri}${location.search}`;
    }
  };

  return (
    <>
      <FbPixel />
      <ToastContainer />
      <Container id="register" labImage={lab.image}>
        <div className="whcc-connect">
          <img src={whcc_logo} alt="WHCC Logo" />
          <p>Get started by connecting your WHCC account.</p>
          <button className="button" onClick={openWHCCAuth} disabled={!successful}>
            Sign in to WHCC
          </button>
          <Link to={`/whcc-register${location.search}`}> Don't have a WHCC account? </Link>
        </div>
      </Container>
    </>
  );
};

ConnectWHCC.propTypes = {
  lab: PropTypes.shape({
    key: PropTypes.string,
    image: PropTypes.string
  }),
  whcc: PropTypes.shape({
    session_id: PropTypes.string,
    login_url: PropTypes.string,
    redirect_uri: PropTypes.string
  })
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectWHCC);
