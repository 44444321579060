// Plugins
import ExifReader from 'exifreader';

// Types
import { ImageFile } from '@/types';

const loadExif = async (file: ImageFile): Promise<void> => {
  return new Promise((resolve) => {
    ExifReader.load(file)
      .then(function (tags) {
        const getKeywords = (): string[] => {
          const keywords: string[] = [];
          const tagKeywords: any = tags['Keywords'];

          if (tagKeywords?.length) {
            tagKeywords.forEach((keyword: any) => keywords.push(keyword.description));
          } else if (tags?.subject) {
            const { subject } = tags;

            if (subject.description) {
              keywords.push(...subject.description.split(/[,;]+/).map((keyword) => keyword.trim()));
            } else if (subject.value?.length) {
              const subjectValues: any = subject.value;

              subjectValues.forEach((keyword: any) => keywords.push(keyword.description));
            }
          }

          return keywords;
        };

        // Assign needed properties to file
        file.data = { ...tags, keywords: getKeywords() };

        return resolve();
      })
      .catch(function (error) {
        console.error(`Failed to load exif: ${error}`);
      });
  });
};

export default loadExif;
