export const pricesheetsToOptions = (state) => {
  const pricesheets = state.pricesheets.result;
  const pricesheetsEntity = state.pricesheets.entities.pricesheets;

  return pricesheets.map((item) => {
    const { id: value, name: label } = pricesheetsEntity[item] || {};
    return { value, label };
  });
};

const baseOfferValues = ({ id, lab_id, name, code, ap_incentive, offer_type, min_order_price_cents, expiration, max_redemptions, shipping_type }) => {
  return {
    id,
    lab_id,
    name,
    code,
    ap_incentive,
    offer_type,
    min_order_price_cents,
    expiration,
    max_redemptions,
    shipping_type
  };
};

const fixedAmountOffer = ({ fixed_amount_cents }) => {
  return {
    fixed_amount_cents,
    fixed_percentage: null
  };
};
const fixedPercentageOffer = ({ fixed_percentage }) => {
  return {
    fixed_percentage,
    fixed_amount_cents: null
  };
};
const shippingOffer = () => {
  return {
    fixed_amount_cents: null,
    fixed_percentage: null
  };
};

const freebieOffer = ({
  qualifier,
  required_products_attributes,
  free_products_attributes,
  discount_type,
  fixed_amount_cents,
  fixed_percentage,
  freebie_type,
  price_sheet_id
}) => {
  if (freebie_type === 'free_product') {
    required_products_attributes = [];
    fixed_percentage = null;
    fixed_amount_cents = null;
  }

  if (discount_type === 'fixed_amount' || discount_type === 'free') {
    fixed_percentage = null;
  }

  if (discount_type === 'fixed_percentage' || discount_type === 'free') {
    fixed_amount_cents = null;
  }

  return {
    qualifier: qualifier || 'all',
    required_products_attributes,
    fixed_percentage,
    fixed_amount_cents,
    price_sheet_id,
    free_products_attributes
  };
};

const OFFER_VALUES_FUNCTION = {
  fixed_amount: fixedAmountOffer,
  fixed_percentage: fixedPercentageOffer,
  freebie: freebieOffer,
  shipping: shippingOffer
};

export const offerValuesByType = (offer) => {
  const typeValues = OFFER_VALUES_FUNCTION[offer.offer_type];
  return { ...baseOfferValues(offer), ...typeValues(offer) };
};

export const selectInitialValuesFromOffer = (offer) => {
  return {
    ...offer,
    fixed_amount_cents: offer.fixed_amount_cents || offer.fixed_percentage,
    required_products_attributes: offer.required_products,
    free_products_attributes: offer.free_products
  };
};

export const getOfferType = (props) => {
  const {
    match: {
      params: { offerType }
    }
  } = props;
  return offerType;
};
