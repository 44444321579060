import * as types from './constants';

import defaultPager from '@/utils/defaultPager';
import { handleErrorState, handleRequestingState, handleSuccessState } from '@/utils/reducerHelpers';

const initialState = {
  result: [],
  entities: {
    gift_cards: {},
    gift_card: {},
    transactions: []
  },
  pager: Object.assign({}, defaultPager),
  requesting: false,
  successful: false,
  errors: []
};

const handleGetGiftCardTransactionsListSuccess = (state, { payload }) => {
  return {
    ...state,
    entities: {
      ...state.entities,
      transactions: payload.data
    },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGiftCardDetailSuccess = (state, payload) => {
  const { result } = payload;
  const { data } = result;

  const newState = {
    ...state,
    entities: { ...state.entities, gift_card: data },
    requesting: false,
    successful: true,
    errors: []
  };

  return newState;
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.CREATE_GIFT_CARD_RESEND_RECEIPT_REQUEST:
    case types.GET_GIFT_CARD_TRANSACTIONS_LIST_REQUEST:
    case types.GIFT_CARD_DETAIL_REQUEST:
      return handleRequestingState(state);
    case types.CREATE_GIFT_CARD_RESEND_RECEIPT_SUCCESS:
      return handleSuccessState(state);
    case types.GET_GIFT_CARD_TRANSACTIONS_LIST_SUCCESS:
      return handleGetGiftCardTransactionsListSuccess(state, payload);
    case types.GIFT_CARD_DETAIL_SUCCESS:
      return handleGiftCardDetailSuccess(state, payload);
    case types.CREATE_GIFT_CARD_RESEND_RECEIPT_ERROR:
    case types.GET_GIFT_CARD_TRANSACTIONS_LIST_ERROR:
    case types.GIFT_CARD_DETAIL_ERROR:
      return handleErrorState(state, payload);
    default:
      return state;
  }
};

export default reducer;
