import React from 'react';
import { Form, reduxForm } from 'redux-form';

import { Input } from '@/components/Shared/Forms';

const AccountForm = ({ loading, requesting, handleSubmit }) => {
  return (
    <section className="basis-full">
      <Form onSubmit={handleSubmit}>
        <header className="flex items-center justify-between user-container__header">
          <h2 className="text-headline-sm">Account Information</h2>
          <button type="submit" className="button button--medium" disabled={requesting || loading}>
            Save
          </button>
        </header>

        <main className="panel panel--section account-settings-item">
          <div className="flex gap-2.5">
            <div className="basis-3/12">
              <Input label="First Name" name="first_name" required loading={loading} type="text" />
            </div>
            <div className="basis-3/12">
              <Input label="Last Name" name="last_name" required loading={loading} type="text" />
            </div>
          </div>
          <div className="basis-6/12">
            <Input label="Phone Number" name="phone" loading={loading} type="tel" />
          </div>
          <div className="basis-6/12">
            <Input label="Email Address" name="email" required email loading={loading} type="email" />
          </div>
        </main>
      </Form>
    </section>
  );
};

export default reduxForm({ form: 'AccountForm', enableReinitialize: true, destroyOnUnmount: false })(AccountForm);
