import { call, put, takeLatest, all } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import * as types from './constants';
import * as actions from './actions';
import * as api from '@/api/promos-api';

import errorHandler from '@/utils/errorResponseHandler';
import successHandler from '@/utils/successResponseHandler';

function* createStudioPromotionRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.createStudioPromotion, payload);

    yield call(successHandler('Promotion successfully created'), actions.createStudioPromotionSuccess, response);

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.createStudioPromotionError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getStudioPromotionRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.getStudioPromotion, payload);

    yield put(actions.getStudioPromotionSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getStudioPromotionError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* updateStudioPromotionRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.updateStudioPromotion, payload);

    yield call(successHandler('Promotion successfully updated'), actions.updateStudioPromotionSuccess, response);

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.updateStudioPromotionError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* cancelStudioPromotionRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.cancelStudioPromotion, payload);

    yield call(successHandler('Promotion successfully canceled'), actions.cancelStudioPromotionSuccess, response);

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.cancelStudioPromotionError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getPhotoDayPromotionListRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.getPhotoDayPromotionList, payload);

    yield put(actions.getPhotoDayPromotionListSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getPhotoDayPromotionListError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getStudioPromotionJobListRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.getStudioPromotionJobList, payload);

    yield put(actions.getStudioPromotionJobListSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getStudioPromotionJobListError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getPromosWatcher() {
  yield all([
    takeLatest(types.CREATE_STUDIO_PROMOTION, createStudioPromotionRequest),
    takeLatest(types.GET_STUDIO_PROMOTION, getStudioPromotionRequest),
    takeLatest(types.UPDATE_STUDIO_PROMOTION, updateStudioPromotionRequest),
    takeLatest(types.CANCEL_STUDIO_PROMOTION, cancelStudioPromotionRequest),
    takeLatest(types.GET_PHOTODAY_PROMOTION_LIST, getPhotoDayPromotionListRequest),
    takeLatest(types.GET_STUDIO_PROMOTION_JOB_LIST, getStudioPromotionJobListRequest)
  ]);
}

export default getPromosWatcher;
