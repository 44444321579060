import { call, put, takeLatest, all } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import errorHandler from '@/utils/errorResponseHandler';
import * as api from '@/api/report-api';
import * as types from './constants';

import {
  salesReportRequestSuccess,
  salesReportRequestError,
  salesSummaryRequestSuccess,
  salesSummaryRequestError,
  salesMonthReportRequestSuccess,
  salesMonthReportRequestError,
  salesMonthSummaryRequestSuccess,
  salesMonthSummaryRequestError,
  productsReportRequestSuccess,
  productsReportRequestError
} from './actions';

function* getSalesReportRequestFlow(action) {
  try {
    yield put(showLoading());

    const response = yield call(api.salesReport, action.payload);
    const payload = { result: { ...response } };

    yield put(salesReportRequestSuccess(payload));
  } catch (error) {
    yield call(errorHandler, salesReportRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getSalesMonthReportRequestFlow(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.salesMonthReport, payload);

    yield put(salesMonthReportRequestSuccess(response));

    if (callback) callback(response);
  } catch (error) {
    yield call(errorHandler, salesMonthReportRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getSalesSummaryRequestFlow(action) {
  try {
    yield put(showLoading());

    const payload = yield call(api.salesSummary, action.payload.year);

    yield put(salesSummaryRequestSuccess(payload));
  } catch (error) {
    yield call(errorHandler, salesSummaryRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getSalesMonthSummaryRequestFlow(action) {
  try {
    yield put(showLoading());

    const { month, year } = action.payload;
    const payload = yield call(api.salesMonthSummary, month, year);

    yield put(salesMonthSummaryRequestSuccess(payload));
  } catch (error) {
    yield call(errorHandler, salesMonthSummaryRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* downloadSalesReportFlow(action) {
  try {
    yield put(showLoading());

    const { payload } = action;

    yield call(api.salesReportDownload, payload);
  } catch (error) {
    yield call(errorHandler, salesReportRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* downloadSalesMonthTaxReportFlow(action) {
  try {
    yield put(showLoading());

    const { payload } = action;

    yield call(api.salesMonthTaxReportDownload, payload);
  } catch (error) {
    yield call(errorHandler, salesReportRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* downloadProductsReportFlow(action) {
  try {
    yield put(showLoading());

    const { payload } = action;

    yield call(api.productsReportDownload, payload);
  } catch (error) {
    yield call(errorHandler, productsReportRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getProductsReportRequestFlow(action) {
  try {
    yield put(showLoading());

    const response = yield call(api.productsReport, action.payload);
    const payload = { result: { ...response } };

    yield put(productsReportRequestSuccess(payload));
  } catch (error) {
    yield call(errorHandler, productsReportRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getReportsWatcher() {
  yield all([
    takeLatest(types.SALES_REPORT_REQUEST, getSalesReportRequestFlow),
    takeLatest(types.SALES_MONTH_REPORT_REQUEST, getSalesMonthReportRequestFlow),
    takeLatest(types.SALES_SUMMARY_REQUEST, getSalesSummaryRequestFlow),
    takeLatest(types.SALES_MONTH_SUMMARY_REQUEST, getSalesMonthSummaryRequestFlow),
    takeLatest(types.SALES_REPORT_DOWNLOAD_REQUEST, downloadSalesReportFlow),
    takeLatest(types.SALES_MONTH_REPORT_DOWNLOAD_REQUEST, downloadSalesMonthTaxReportFlow),
    takeLatest(types.PRODUCTS_REPORT_REQUEST, getProductsReportRequestFlow),
    takeLatest(types.PRODUCTS_REPORT_DOWNLOAD_REQUEST, downloadProductsReportFlow)
  ]);
}

export default getReportsWatcher;
