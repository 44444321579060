import { schema } from 'normalizr';

const flags = new schema.Entity('flags');
const user = new schema.Entity('user', { flags });
const studio = new schema.Entity('studio');
const login = new schema.Entity(
  'login',
  {
    user,
    studio
  },
  { idAttribute: 'jwt' }
);

export default login;
