import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const QuantityInput = ({ className = 'quantity-container', value = 1, onChange }) => {
  const [currentValue, setCurrentValue] = useState(value);

  const onIncrement = (e) => {
    e.preventDefault();
    const newVal = currentValue + 1;
    setCurrentValue(newVal);
    if (onChange) onChange(newVal);
  };

  const onDecrement = (e) => {
    e.preventDefault();
    const newVal = currentValue - 1;
    setCurrentValue(newVal);
    if (onChange) onChange(newVal);
  };

  const minusDisabled = currentValue === 1;

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <div className={className}>
      <p className="font-bold">Product Quantity</p>
      <p>Please edit the amount if you want the customer to purchase the product in specific quantities.</p>
      <div className="flex items-center">
        <button className="button button--outline button--small" disabled={minusDisabled} type="button" onClick={onDecrement}>
          -
        </button>
        <span className="quantity-number">{currentValue}</span>
        <button className="button button--outline button--small" type="button" onClick={onIncrement}>
          +
        </button>
      </div>
    </div>
  );
};

QuantityInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func
};

export default QuantityInput;
