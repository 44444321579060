import * as types from './constants';

import defaultPager from '@/utils/defaultPager';
import { handleErrorState, handleRequestingState } from '@/utils/reducerHelpers';

const initialState = {
  result: [],
  entities: {
    shippings: {},
    shippingSummary: null,
    bulkShipOrderList: [],
    bulkShipOrder: []
  },
  pager: Object.assign({}, defaultPager),
  requesting: false,
  successful: false,
  errors: [],
  saveSuccessRedirect: false
};

const handleListSuccess = (state, payload) => {
  const { entities, pager, result } = payload;
  const { entities: origEntities } = state;
  const newEntities = Object.assign({}, origEntities, entities);

  return {
    ...state,
    ...{
      result,
      entities: newEntities,
      pager,
      errors: [],
      requesting: false,
      successful: true
    }
  };
};

const handleGetShippingSummarySuccess = (state, { payload }) => {
  const { data } = payload;

  return {
    ...state,
    entities: { ...state.entities, shippingSummary: data.recent_bulk_shipment },
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetBulkShippingOrderListSuccess = (state, { payload }) => {
  const { data } = payload;

  return {
    ...state,
    entities: { ...state.entities, bulkShipOrderList: data },
    errors: [],
    successful: true,
    requesting: false
  };
};
const handleGetBulkShippingOrderSuccess = (state, { payload }) => {
  const { data } = payload;

  return {
    ...state,
    entities: { ...state.entities, bulkShipOrder: data },
    errors: [],
    successful: true,
    requesting: false
  };
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.SHIPPING_LIST_REQUEST:
    case types.GET_SHIPPING_SUMMARY_REQUEST:
    case types.GET_BULK_SHIPPING_ORDER_LIST_REQUEST:
    case types.GET_BULK_SHIPPING_ORDER_REQUEST:
      return handleRequestingState(state);

    case types.GET_SHIPPING_SUMMARY_SUCCESS:
      return handleGetShippingSummarySuccess(state, payload);
    case types.GET_BULK_SHIPPING_ORDER_LIST_SUCCESS:
      return handleGetBulkShippingOrderListSuccess(state, payload);
    case types.GET_BULK_SHIPPING_ORDER_SUCCESS:
      return handleGetBulkShippingOrderSuccess(state, payload);

    case types.SHIPPING_LIST_SUCCESS:
      return handleListSuccess(state, payload);

    case types.SHIPPING_LIST_ERROR:
    case types.GET_SHIPPING_SUMMARY_ERROR:
    case types.GET_BULK_SHIPPING_ORDER_LIST_ERROR:
    case types.GET_BULK_SHIPPING_ORDER_ERROR:
      return handleErrorState(state, payload);

    default:
      return state;
  }
};

export default reducer;
