import { call, put, takeLatest, all } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import * as types from './constants';
import * as actions from './actions';
import errorHandler from '@/utils/errorResponseHandler';
import successHandler from '@/utils/successResponseHandler';
import * as loginActions from '@/components/Login/actions';
import * as api from '@/api/signup-api';
import * as sagas from '../Login/sagas';
import { getSessionHash } from '@/utils/http';

function* setStudioInformationFlow(action) {
  yield put({
    type: types.SET_STUDIO_INFORMATION_SUCCESS,
    payload: action.payload.params
  });
}

function* setRegisterStageFlow(action) {
  yield put({
    type: types.SET_REGISTER_STAGE_SUCCESS,
    payload: action.payload.params
  });
}

function* registerRequestFlow(action) {
  try {
    yield put(showLoading());

    const {
      payload: { params }
    } = action;
    const session_id = getSessionHash();
    const response = yield call(api.createStudio, { ...params, session_id });
    const { data } = response;

    if (data['jwt']) {
      const payload = yield call(sagas.parseLoginResponse, response);
      yield call(sagas.storeValues, payload, true);
      yield put(loginActions.loginSuccess(payload));
      yield put(actions.createStudioRequestSuccess({ isAuthenticated: true }));
    } else {
      yield call(successHandler('Successfully created'), actions.createStudioRequestSuccess, data);
    }
  } catch (error) {
    yield call(errorHandler, actions.createStudioRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* resendVerificationEmailRequestFlow(action) {
  try {
    yield put(showLoading());

    const { payload: email } = action;
    const { data } = yield call(api.resendVerificationEmail, email);
    yield call(successHandler('Successfully sent'), actions.resendVerificationEmailSuccess, data);
  } catch (error) {
    yield call(errorHandler, actions.resendVerificationEmailError, error);
  } finally {
    yield put(hideLoading());
  }
}
function* verificationRequestFlow(action) {
  try {
    yield put(showLoading());

    const { payload: token } = action;
    const response = yield call(api.verify, token);
    const payload = yield call(sagas.parseLoginResponse, response);
    yield call(sagas.storeValues, payload, true);
    yield put(loginActions.loginSuccess(payload));
    return payload.jwt;
  } catch (error) {
    yield put({ type: types.VERIFICATION_ERROR, payload: error });
  } finally {
    yield put(hideLoading());
  }
}

function* getLinkRequestFlow() {
  try {
    yield put(showLoading());

    const sessionHash = getSessionHash();
    const { data } = yield call(api.getWhccLink, sessionHash);
    yield put(actions.getWhccLinkRequestSuccess(data));
  } catch (error) {
    yield call(errorHandler, actions.getWhccLinkRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* parseStudioInformationFlow(action) {
  try {
    yield put(showLoading());

    const { payload: whData } = action;
    const parsed = yield call(JSON.parse, whData);
    const address = {
      line1: parsed['addr'],
      line2: parsed['addr2'],
      city: parsed['city'],
      state: parsed['state'],
      zip: parsed['zip']
    };
    const studio = {
      name: parsed['studio'],
      phone: parsed['phone'],
      ex_id: parsed['client_id'],
      address_attributes: address
    };
    const user = {
      email: parsed['email'],
      phone: parsed['phone']
    };
    yield put(
      actions.getWhccStudioInformationSuccess({
        user,
        studio,
        isEmailDisabled: true
      })
    );
  } catch (error) {
    yield call(errorHandler, actions.getWhccStudioInformationError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getSignUpWatcher() {
  yield all([
    takeLatest(types.SET_STUDIO_INFORMATION, setStudioInformationFlow),
    takeLatest(types.SET_REGISTER_STAGE, setRegisterStageFlow),
    takeLatest(types.GET_WHCC_LINK_REQUEST, getLinkRequestFlow),
    takeLatest(types.GET_WHCC_STUDIO_INFORMATION_REQUEST, parseStudioInformationFlow),
    takeLatest(types.REGISTER_REQUEST, registerRequestFlow),
    takeLatest(types.RESEND_VERIFICATION_REQUEST, resendVerificationEmailRequestFlow),
    takeLatest(types.VERIFICATION_REQUEST, verificationRequestFlow)
  ]);
}

export default getSignUpWatcher;
