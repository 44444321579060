import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useRef, useState, useEffect } from 'react';

// Plugins
import moment from 'moment';
import { isEqual } from 'lodash';
import Select from 'react-select';

// Helpers
import { successToast, errorToast } from '@/utils/toast';

// Components
import Email from './Email';
import AddEdit from '../AddEdit';
import Download from './Download';
import LayoutBasic from './Layouts/Basic';
import LayoutClassic from './Layouts/Classic';
import LayoutModernHz from './Layouts/ModernHorizontal';
import LayoutModernVert from './Layouts/ModernVertical';
import Dropdown from '@/components/Shared/Dropdown';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { getOffersDDLRequest } from '@/components/Shared/OffersDDL/actions';
import { getFlyer, getFlyerPdf, updateFlyer, getPeopleFieldOptions, getPeopleList } from '../../../actions';

// Images
import imageSendFlyerPeople from '@/assets/images/icon_flyer_person.png';
import imageDesignBasicFull from '@/assets/images/flyer-design-basic-private.png';
import imageDesignClassic from '@/assets/images/flyer-design-classic-private.png';
import imageDesignModernHz from '@/assets/images/flyer-design-modern-horizontal.png';
import imageDesignModernVert from '@/assets/images/flyer-design-modern-vertical.png';
import imageWebFlyer from '@/assets/images/icon_globe.png';
import imageCopyFlyerUrl from '@/assets/images/icon_copy.png';
import imageSendFlyerOrganization from '@/assets/images/icon_flyer_org.png';

// Styles
import './style.css';

import layoutStyleBasic from './Layouts/Basic/style.css?inline';
import layoutStyleClassic from './Layouts/Classic/style.css?inline';
import layoutStyleModernHz from './Layouts/ModernHorizontal/style.css?inline';
import layoutStyleModernVert from './Layouts/ModernVertical/style.css?inline';

const Design = ({ match }) => {
  const layoutRef = useRef();
  const flyerUrlRef = useRef();

  const dispatch = useDispatch();

  const {
    params: { jobId, flyerId }
  } = match;
  const {
    job,
    people: { fields, list },
    flyer,
    requesting
  } = useSelector((state) => state.jobs);
  const { options: optionsOffers, requesting: offersRequesting } = useSelector((state) => state.offersDDL);

  const offers = optionsOffers?.filter((offer) => moment(offer.expiration).isBefore(moment().subtract(1, 'days')) === false) || [];

  const [values, setValues] = useState({
    sorting: [],
    theme: 'classic'
  });

  const [step, setStep] = useState('details');

  const [showSettings, setShowSettings] = useState(false);
  const [showEmail, setShowEmail] = useState({ show: false, type: '' });
  const [showDownload, setShowDownload] = useState(false);

  const isPrivateJob = job.access_mode === 'access_per_subject';
  const emailFields = ['email', 'email_2', 'email_3'];
  const hasEmailFields = Object.keys(fields).filter((key) => emailFields.includes(key)).length > 0;

  const handleSettingsToggle = () => setShowSettings(!showSettings);

  const handlePdfDownload = () => {
    if (isPrivateJob) {
      handleDownloadToggle();
    } else {
      const { updated_at: jobUpdatedAt } = job;
      const { offers: flyerOffers, updated_at: flyerUpdatedAt } = flyer;

      // Check if job or offers has changed since last time flyer updated
      // if true save flyer first to update it before download
      let shouldSaveFlyer = false;

      if (moment(flyerUpdatedAt).isBefore(jobUpdatedAt) || flyerOffers.some((offer) => moment(flyerUpdatedAt).isBefore(offer.updated_at))) {
        shouldSaveFlyer = true;
      }

      if (shouldSaveFlyer) {
        handleSave(() => dispatch(getFlyerPdf(flyer)));
      } else {
        dispatch(getFlyerPdf(flyer));
      }

      handleDownloadToggle();
    }
  };

  const handleDownloadToggle = () => setShowDownload(!showDownload);

  const handleEmailToggle = (type = '') => setShowEmail({ show: !showEmail.show, type });

  const handleStepChange = (selectStep) => setStep(selectStep === step ? '' : selectStep);

  const handleDesignChange = (design) => setValues({ ...values, theme: design });

  const handleShowImage = () => {
    setValues({ ...values, show_image: !values.show_image });
  };

  const handleImageUpload = ({ target }) => {
    const image = target.files[0];
    const reader = new FileReader();

    reader.readAsDataURL(image);

    reader.onload = () => {
      setValues({
        ...values,
        image_attachment: {
          content: reader.result,
          filename: image.name,
          content_type: image.type
        },
        image_filename: image.name,
        image_url: reader.result,
        show_image: true
      });
    };
  };

  const handleImageRemove = () => {
    setValues({ ...values, image_url: '', image_attachment: {}, show_image: false });
  };

  const handleRemoveGroupField = (group) => {
    const newSorting = values.sorting?.filter((item) => item !== group);

    setValues({ ...values, sorting: newSorting });
  };

  const handleResetGroupList = () => {
    setValues({ ...values, sorting: values.sorting_fields });
  };

  const compileStyleFromTheme = (flyerTheme) => {
    const layoutStyle = (theme) => {
      switch (theme) {
        case 'basic':
          return layoutStyleBasic;
        case 'classic':
          return layoutStyleClassic;
        case 'modern_hz':
          return layoutStyleModernHz;
        case 'modern_vert':
          return layoutStyleModernVert;

        default:
          return layoutStyleBasic;
      }
    };

    return `<style type="text/css">${layoutStyle(flyerTheme)
      .replace(/(\s|\r\n|\n|\r)/gm, '')
      .replace('@mediaprint', '@media print')}</style>`;
  };

  const handleCopyFlyerUrl = (e) => {
    flyerUrlRef.current.select();
    document.execCommand('copy');
    e.target.focus();
    successToast('The flyer URL has been successfully copied!');
  };

  const validateFlyer = () => {
    if (values.theme === 'modern_hz' && values.show_image && !values.image_url) {
      errorToast('Please upload an image for this flyer');
      return false;
    }
    return true;
  };

  const handleSave = (callback) => {
    if (!validateFlyer()) return;

    const trimmedValues = { ...values };
    const compiledStyles = compileStyleFromTheme(values.theme);

    const regexBase64 = /"data:image.jpeg.*?"/gs;
    const urlRegex =
      /(\s)+(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/gim;
    const ampRegex = /(&amp;)/g;

    let markupString = layoutRef.current.innerHTML;

    const replaceUrl = (substring) => {
      let url = substring.trim();

      const prefix = new RegExp('^(https?|ftp)://.*$', 'gim');
      const hasPrefix = url.match(prefix);

      if (!hasPrefix) {
        // this will be converted to pdf and email, we can't rely on a browser to interpret href, the prefix must be explicit
        url = 'http://' + url;
      }

      return ` <a href="${url}" target='_blank' style='color: #22bffd; text-decoration: none;'>${substring}</a>`;
    };

    // Replace inline image with dynamic and add valid ampersand
    markupString = markupString.replace(regexBase64, '"{{flyer_image}}"').replace(ampRegex, '&');
    // Wrap any url string with <a> tag
    markupString = markupString.replace(urlRegex, replaceUrl);

    delete trimmedValues.job;

    if (trimmedValues.image_attachment) {
      delete trimmedValues.image_url;
    }

    dispatch(
      updateFlyer(
        {
          ...trimmedValues,
          id: values.id,
          status: 'saved',
          flyers_version: 2,
          layout: 'full',
          layout_style: compiledStyles,
          layout_markup: markupString,
          offer_ids: offers?.length ? values?.offers?.map((offer) => offer.id) : null
        },
        typeof callback === 'function' ? callback : undefined
      )
    );
  };

  useEffect(() => {
    if (!flyer.id || flyer.id !== flyerId) {
      dispatch(getFlyer({ id: flyerId }, ({ data }) => setValues({ ...data, flyers_version: 2 })));
    }
  }, []);

  useEffect(() => {
    if (job) {
      if (isPrivateJob) {
        // Get subjects
        if (list.length === 0 || (list.length > 0 && !list[0]?.purchased_order) || (list.length > 0 && list.length !== job.subjects_count)) {
          dispatch(getPeopleList({ id: jobId, per_page: 10000, order: 'last_name', dir: 'asc', with_purchases: true }));
        }

        // Get subjects properties
        if (Object.keys(fields).length === 0) {
          dispatch(getPeopleFieldOptions({ id: job.id }));
        }
      }
    }
  }, [job]);

  useEffect(() => {
    if (flyer) {
      // Get offers
      if (flyer.flyer_offer_type !== 'advance_pay') {
        dispatch(getOffersDDLRequest());
      }

      if (flyer.id) {
        setValues({
          ...flyer,
          theme: flyer.theme || 'classic',
          show_job_logo: flyer.show_job_logo !== null ? flyer.show_job_logo : job.logo_url ? true : false,
          show_studio_logo: flyer.show_studio_logo !== null ? flyer.show_studio_logo : job.meta && job.meta.studio_logo ? true : false,
          show_image:
            flyer.show_image !== null
              ? flyer.show_image
              : job.access_mode === 'access_per_subject' && flyer.flyer_offer_type !== 'advance_pay' && job.setup_steps.uploaded_photos
                ? true
                : false
        });
      }
    }
  }, [flyer]);

  useEffect(() => {
    if (values?.offers?.length > 2) {
      const trimmedOffers = values.offers.filter((_, i) => i <= 1);

      setValues({ ...values, offers: trimmedOffers });
    }
  }, [values]);

  useEffect(() => {
    if (values.show_image === false && values.image_url) {
      setValues({ ...values, image_attachment: null, image_url: '' });
    }
  }, [values.show_image]);

  return (
    <>
      <header className="flex items-center justify-between job-marketing-flyers__design-header">
        <div className="flex justify-start items-center">
          <Link className="button button--outline button--noborder" to={`/jobs/${jobId}/marketing/flyers`}>
            <i className="icon-back"></i>
          </Link>
          <h2 className="text-headline-md mb-0">Design</h2>
        </div>
        <aside className="flex justify-end flex-nowrap button-group">
          <button className="button button--outline button--small" name="settings" type="button" onClick={handleSettingsToggle}>
            <i className="icon-gear"></i>
          </button>
          <button
            className="button button--outline button--small"
            name="download"
            type="button"
            data-loading-dark={requesting}
            disabled={requesting || !isEqual(values, flyer)}
            onClick={handlePdfDownload}
          >
            <i className="icon-download-sharp"></i>
          </button>
          <Dropdown buttonIcon={'icon-share'} buttonExtraClass={'button-dropdown button--clean button--lean'} buttonDisabled={!isEqual(values, flyer)}>
            <ul className="panel panel-dropdown panel-dropdown--large">
              <li className="flex justify-start panel-dropdown__item" onClick={() => handleEmailToggle('organization')}>
                <figure className="flex basis-3/12 items-center justify-center">
                  <img src={imageSendFlyerOrganization} alt="Send flyer to organization" width="50" />
                </figure>
                {isPrivateJob ? (
                  <p className="basis-9/12 m-0">
                    <b>Send flyers to organization</b>
                    <br />
                    <small>We'll send an email to the organization containing a PDF of all the flyers.</small>
                  </p>
                ) : (
                  <p className="basis-9/12 m-0">
                    <b>Send flyer to organization</b>
                    <br />
                    <small>Create an email with a personalized message and a PDF of the flyer.</small>
                  </p>
                )}
              </li>
              <li className="flex justify-start panel-dropdown__item" onClick={() => handleEmailToggle('people')}>
                <figure className="flex basis-3/12 items-center justify-center">
                  <img src={imageSendFlyerPeople} alt="Send flyer to people" width="50" />
                </figure>
                {isPrivateJob ? (
                  <p className="basis-9/12 m-0">
                    <b>Send flyer to each person</b>
                    <br />
                    <small>We’ll send each subject that has an email address their own flyer.</small>
                  </p>
                ) : (
                  <p className="basis-9/12 m-0">
                    <b>Send flyer to people</b>
                    <br />
                    <small>Upload a CSV file containing email addresses and we’ll send the flyer out to those people.</small>
                  </p>
                )}
              </li>
            </ul>
          </Dropdown>

          {!isPrivateJob && (
            <Dropdown
              buttonIcon={'icon-preview'}
              buttonExtraClass={'button-dropdown button--clean button--lean sm:hidden'}
              buttonDisabled={!isEqual(values, flyer)}
            >
              <ul className="panel panel-dropdown panel-dropdown--large">
                <li>
                  <a className="flex justify-start panel-dropdown__item" href={flyer.public_url} target="_blank" rel="noopener noreferrer">
                    {' '}
                    <figure className="flex basis-3/12 items-center justify-center">
                      <img src={imageWebFlyer} alt="view flyer on web" height="29" />
                    </figure>
                    <p className="basis-9/12 m-0">
                      <b>View on the Web</b>
                      <br />
                      <small>Preview the flyer on the web</small>
                    </p>
                  </a>
                </li>
                <li className="flex justify-start panel-dropdown__item" onClick={handleCopyFlyerUrl}>
                  <figure className="flex basis-3/12 items-center justify-center">
                    <img src={imageCopyFlyerUrl} alt="copy flyer url" height="29" />
                  </figure>
                  <p className="basis-9/12 m-0">
                    <b>Copy Web Flyer</b>
                    <br />
                    <small>Copy the web URL to the clipboard</small>
                    <textarea className="off" ref={flyerUrlRef} value={flyer.public_url} readOnly={true} />
                  </p>
                </li>
              </ul>
            </Dropdown>
          )}

          <button className="button button--medium" name="save" disabled={requesting || isEqual(values, flyer)} onClick={handleSave} data-loading={requesting}>
            Save
          </button>
        </aside>
      </header>

      <section className="flex flex-row">
        <aside className="basis-2/6 md:basis-full">
          <ul className="job-marketing-flyers__design-panel panel">
            {/* Design */}
            <li
              className={`job-marketing-flyers__design-customization job-marketing-flyers__design-customization--tall ${
                step === 'design' ? '' : 'job-marketing-flyers__design-customization--collapsed'
              }`}
            >
              <header
                className="flex items-center justify-between job-marketing-flyers__design-customization-header"
                onClick={() => handleStepChange('design')}
              >
                <h4 className="text-headline-xs">
                  Design
                  {step !== 'design' && (
                    <span className="text--normal capitalize animate">
                      : {values.theme === 'modern_hz' ? 'Modern Horizontal' : values.theme === 'modern_vert' ? 'Modern Vertical' : values.theme}
                    </span>
                  )}
                </h4>
                <i className={`icon-arrow-up ${step === 'design' ? '' : 'icon-arrow-up--rotated'}`}></i>
              </header>
              <ul className="grid grid-cols-3 gap-2.5">
                <li
                  className="flex flex-col justify-start items-center job-marketing-flyers__design-layout-container"
                  onClick={() => handleDesignChange('classic')}
                >
                  <div className="job-marketing-flyers__design-layout-aspect">
                    <figure
                      className={`job-marketing-flyers__design-layout ${values.theme === 'classic' ? 'job-marketing-flyers__design-layout--active' : ''}`}
                    >
                      <img className="" src={imageDesignClassic} alt="classic" height="100%" />
                    </figure>
                  </div>
                  <span className="text-center">Classic</span>
                </li>
                <li
                  className="flex flex-col justify-start items-center job-marketing-flyers__design-layout-container"
                  onClick={() => handleDesignChange('basic')}
                >
                  <div className="job-marketing-flyers__design-layout-aspect">
                    <figure className={`job-marketing-flyers__design-layout ${values.theme === 'basic' ? 'job-marketing-flyers__design-layout--active' : ''}`}>
                      <img className="" src={imageDesignBasicFull} alt="Basic" height="100%" />
                    </figure>
                  </div>
                  <span className="text-center">Basic</span>
                </li>
                <li
                  className="flex flex-col justify-start items-center job-marketing-flyers__design-layout-container"
                  onClick={() => handleDesignChange('modern_vert')}
                >
                  <div className="job-marketing-flyers__design-layout-aspect">
                    <figure
                      className={`job-marketing-flyers__design-layout ${values.theme === 'modern_vert' ? 'job-marketing-flyers__design-layout--active' : ''}`}
                    >
                      <img className="" src={imageDesignModernVert} alt="Modern Vertical" height="100%" />
                    </figure>
                  </div>
                  <span className="text-center">Modern Vertical</span>
                </li>
                <li
                  className="flex flex-col justify-start items-center job-marketing-flyers__design-layout-container"
                  onClick={() => handleDesignChange('modern_hz')}
                >
                  <div className="job-marketing-flyers__design-layout-aspect">
                    <figure
                      className={`job-marketing-flyers__design-layout ${values.theme === 'modern_hz' ? 'job-marketing-flyers__design-layout--active' : ''}`}
                    >
                      <img className="" src={imageDesignModernHz} alt="Modern Horizontal" height="100%" />
                    </figure>
                  </div>
                  <span className="text-center">Modern Horizontal</span>
                </li>
              </ul>
            </li>
            {/* Details */}
            <li
              className={`job-marketing-flyers__design-customization job-marketing-flyers__design-customization--tall ${
                step === 'details' ? '' : 'job-marketing-flyers__design-customization--collapsed'
              }`}
            >
              <header
                className="flex items-center justify-between job-marketing-flyers__design-customization-header"
                onClick={() => handleStepChange('details')}
              >
                <h4 className="text-headline-xs">Details</h4>
                <i className={`icon-arrow-up ${step === 'details' ? '' : 'icon-arrow-up--rotated'}`}></i>
              </header>
              {job.logo_url && (
                <fieldset>
                  <div className="flex items-center justify-between">
                    <h5 className="m-0">Job Logo</h5>
                    <input
                      id="jobLogoSwitch"
                      className="hidden"
                      name="logo"
                      type="checkbox"
                      checked={values.show_job_logo || false}
                      onChange={() => setValues({ ...values, show_job_logo: !values.show_job_logo })}
                    />
                    <label className="label-switch label-switch--small" htmlFor="jobLogoSwitch" />
                  </div>
                </fieldset>
              )}

              <fieldset>
                <div className="flex justify-between">
                  <h5>Image</h5>
                  <input id="imageSwitch" className="hidden" name="image" type="checkbox" checked={values.show_image || false} onChange={handleShowImage} />
                  <label className="label-switch label-switch--small" htmlFor="imageSwitch" />
                </div>

                {(job.access_mode === 'access_per_subject' || values.theme === 'modern_hz') && (
                  <>
                    {values.theme === 'modern_hz' ? (
                      <p className="text-body-sm job-marketing-flyers__design-customization-note">
                        For best results, please upload an image with an aspect ratio of 2.20 and dimensions of 1200 by 545.
                      </p>
                    ) : (
                      <p className="text-body-sm job-marketing-flyers__design-customization-note">
                        PhotoDay will automatically show the person's image on each individual flyer if the uploaded photos have been matched to the person's
                        data. You can choose to turn off the photo or upload your own photo that will be used on every flyer
                      </p>
                    )}
                  </>
                )}

                {values.show_image && (
                  <>
                    {values.image_url || (values.image_attachment && values.image_attachment.content) ? (
                      <button
                        className="button button--outline button--medium font-bold job-marketing-flyers__design-customization-upload-button"
                        type="button"
                        onClick={handleImageRemove}
                      >
                        Remove
                      </button>
                    ) : (
                      <>
                        <label
                          className="button button--medium button--outline font-bold animate job-marketing-flyers__design-customization-upload-button"
                          htmlFor="upload"
                        >
                          Upload
                        </label>
                        <input id="upload" className="hidden" type="file" accept="image/jpeg, image/jpg" onChange={handleImageUpload} />
                      </>
                    )}
                  </>
                )}
              </fieldset>

              <fieldset>
                <hgroup className="flex justify-between">
                  <h5>Title</h5>
                  <h6>{(values.name && values.name.length) || 0}/24</h6>
                </hgroup>
                <input
                  className="input--block"
                  type="text"
                  name="title"
                  value={values.name || ''}
                  onChange={({ target }) => setValues({ ...values, name: target.value })}
                  maxLength="24"
                />
              </fieldset>

              <fieldset>
                <div className="flex items-center justify-between">
                  <h5 className="m-0">Picture Day Date</h5>
                  <input
                    id="pictureDayDateSwitch"
                    className="hidden"
                    name="pictureDayDate"
                    type="checkbox"
                    checked={values.show_job_date || ''}
                    onChange={() => setValues({ ...values, show_job_date: !values.show_job_date })}
                  />
                  <label className="label-switch label-switch--small" htmlFor="pictureDayDateSwitch" />
                </div>
              </fieldset>

              <fieldset>
                <hgroup className="flex justify-between">
                  <h5>Description</h5>
                  <h6>{(values.body && values.body.length) || 0}/280</h6>
                </hgroup>
                <textarea
                  name="body"
                  value={values.body || ''}
                  onChange={({ target }) => setValues({ ...values, body: target.value })}
                  rows="8"
                  maxLength="280"
                />
              </fieldset>
              {values.flyer_offer_type !== 'advance_pay' && (
                <fieldset>
                  <h5>Offers</h5>
                  <Select
                    className="select"
                    classNamePrefix="select"
                    isMulti={true}
                    isLoading={offersRequesting}
                    menuPlacement={'top'}
                    noOptionsMessage={() => (values?.offers?.length === 2 ? "You've reached the max offers allowed" : 'No options available')}
                    value={values?.offers?.map((offer) => ({ value: offer.id, label: offer.name }))}
                    options={values?.offers?.length === 2 ? [] : offers}
                    onChange={(selectOffers) =>
                      setValues({
                        ...values,
                        offers: selectOffers ? offers?.filter((offer) => selectOffers.some((selectOffer) => selectOffer.value === offer.value)) : []
                      })
                    }
                  />
                </fieldset>
              )}

              {job.meta && job.meta.studio_logo && (
                <fieldset className="fieldset--clean">
                  <div className="flex items-center justify-between">
                    <h5 className="m-0">Studio Logo</h5>
                    <input
                      id="studioLogoSwitch"
                      className="hidden"
                      name="logo"
                      type="checkbox"
                      checked={values.show_studio_logo || false}
                      onChange={() => setValues({ ...values, show_studio_logo: !values.show_studio_logo })}
                    />
                    <label className="label-switch label-switch--small" htmlFor="studioLogoSwitch" />
                  </div>
                </fieldset>
              )}
            </li>
            {/* Sorting */}
            {isPrivateJob && (
              <li
                className={`job-marketing-flyers__design-customization job-marketing-flyers__design-customization--tall ${
                  step === 'sorting' ? '' : 'job-marketing-flyers__design-customization--collapsed'
                }`}
              >
                <header
                  className="flex items-center justify-between job-marketing-flyers__design-customization-header"
                  onClick={() => handleStepChange('sorting')}
                >
                  <h4 className="text-headline-xs">Sorting</h4>
                  <i className={`icon-arrow-up ${step === 'sorting' ? '' : 'icon-arrow-up--rotated'}`}></i>
                </header>

                <fieldset className="flex flex-col fieldset--clean">
                  <p>{`The email or download will contain a pdf that includes flyers sorted by: last name${
                    values.sorting && values.sorting.length > 0 ? ', first name, and by the items listed below. ' : ' and first name.'
                  }`}</p>

                  {values.sorting?.length > 0 &&
                    values.sorting.map((group, i) => (
                      <div className="flexflex-nowrap justify-between items-center job-marketing-flyers__sort-item capitalize" key={i}>
                        <span>{`${i + 1}. ${group.replace('subjects.', '').replace('session_start', 'check-in_date').replace('_', ' ')}`}</span>
                        <button className="button button--link button--small" type="button" onClick={() => handleRemoveGroupField(group)}>
                          <i className="icon-trash"></i>
                          Remove
                        </button>
                      </div>
                    ))}

                  {!isEqual(values.sorting, values.sorting_fields) && (
                    <>
                      {values.sorting?.length === 0 ? (
                        <>
                          <aside className="panel">
                            <h6>You have the following additional sort fields to add:</h6>
                            <ul>
                              {values.sorting_fields &&
                                values.sorting_fields.map((field) => (
                                  <li key={field}>
                                    <small className="capitalize">
                                      &#8226; {field.replace('subjects.', '').replace('session_start', 'check-in_date').replace('_', ' ')}
                                    </small>
                                  </li>
                                ))}
                            </ul>
                          </aside>
                          <button className="button button--block" type="button" onClick={handleResetGroupList}>
                            Add them all
                          </button>
                        </>
                      ) : (
                        <button className="button button--link" type="button" onClick={handleResetGroupList}>
                          Reset fields
                        </button>
                      )}
                    </>
                  )}
                </fieldset>
              </li>
            )}
          </ul>
        </aside>
        <section className="job-marketing-flyers__layout-container">
          {values.theme === 'basic' && <LayoutBasic layoutRef={layoutRef} showImage={values.show_image} job={job} flyer={values} />}

          {values.theme === 'classic' && <LayoutClassic layoutRef={layoutRef} showImage={values.show_image} job={job} flyer={values} />}

          {values.theme === 'modern_vert' && <LayoutModernVert layoutRef={layoutRef} showImage={values.show_image} job={job} flyer={values} />}

          {values.theme === 'modern_hz' && <LayoutModernHz layoutRef={layoutRef} showImage={values.show_image} job={job} flyer={values} />}
        </section>
      </section>

      {/* Settings modal */}
      {showSettings && <AddEdit job={job} flyer={flyer} requesting={requesting} onAddEditToggle={handleSettingsToggle} />}

      {/* Download modal */}
      {showDownload && <Download job={job} flyer={flyer} isPrivateJob={isPrivateJob} onDownloadToggle={handleDownloadToggle} onFlyerSave={handleSave} />}

      {/* Email modal */}
      {showEmail.show && (
        <Email type={showEmail.type} job={job} flyer={flyer} isPrivateJob={isPrivateJob} onEmailToggle={handleEmailToggle} onFlyerSave={handleSave} />
      )}
    </>
  );
};

Design.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      jobId: PropTypes.string.isRequired,
      flyerId: PropTypes.string.isRequired
    })
  })
};

Design.defaultProps = {
  match: {
    params: {
      jobId: '',
      flyerId: ''
    }
  }
};

export default Design;
