import React from 'react';
import PropTypes from 'prop-types';

// Images
import logo from '@/assets/images/image_PD_logo.svg';
import logo_oto_hair from '@/assets/images/logo_oto_hair.png';

// Styles
import './container.css';

const Container = ({ children, labImage, title = 'Hi There!', subtitle = "You're just seconds away from using PhotoDay." }) => {
  return (
    <div id="register" className="onboarding-bg">
      <img src={logo} className="gray-logo" alt="Logo" />

      <div className="container-fluid flex-page-centered">
        <div className="white-container basis-9/12 login-screen">
          <div className="left-onboarding-container">
            <div>
              <div className="register-logos">
                <img src={logo_oto_hair} alt="PhotoDay Logo" />
                {!!labImage && <p>+</p>}
                {!!labImage && <img src={labImage} alt="Lab Logo" />}
              </div>
              <h1 className="font-bold"> {title} </h1>
              <p> {subtitle} </p>
            </div>
          </div>
          <div className="right-onboarding-container">{children}</div>
        </div>
      </div>
    </div>
  );
};

Container.propTypes = {
  labImage: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string
};

export default Container;
