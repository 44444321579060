import defaultPager from './defaultPager';

const setSortingOptions = (order = null, dir = null) => (dir && dir.length > 0 && order && order.length > 0 ? { order, dir } : null);

const setSearchOptions = (search = null) => (search && search.length > 0 ? { search } : null);

const setFilterOptions = (filter = null) => (filter && filter.length > 0 ? { filter } : null);

const setSearchFieldOptions = (searchField = null) => (searchField && searchField.length > 0 ? { search_field: searchField } : null);

const setPagingSortFilter = (
  page = defaultPager.page,
  perPage = defaultPager.perPage,
  search = null,
  order = null,
  dir = null,
  filter = null,
  searchField = null
) =>
  Object.assign(
    {},
    { page, per_page: Number(perPage) || 100 },
    setSortingOptions(order, dir),
    setSearchOptions(search),
    setFilterOptions(filter),
    setSearchFieldOptions(searchField)
  );

export default setPagingSortFilter;
