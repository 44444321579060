import { FC } from 'react';
import { Switch, Route } from 'react-router-dom';

// Components
import TopNav from '@/components/Shared/TopNav';
import List from './List';
import Details from './Details';

// Styles
import './styles.css';

const Organizations: FC = () => {
  return (
    <>
      <TopNav />

      <section className="container organizations">
        <Switch>
          <Route path="/organizations/:organizationId" component={Details} />
          <Route component={List} />
        </Switch>
      </section>
    </>
  );
};

export default Organizations;
