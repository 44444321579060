import { FC, useState, useEffect } from 'react';

// Plugins
import Select, { SingleValue, OnChangeValue } from 'react-select';

//  Types
import { SelectOptionType, Export, ExportType as ExportTypeOpts } from '@/types';

interface ExportTypeProps {
  jobExport: Export;
  onExportStepModalClose: () => void;
  onExportStepComplete: (newValues: Export, step: string) => void;
}

const exportFormatOptions = [
  {
    label: '.csv (comma separated values)',
    value: 'csv'
  },
  {
    label: '.txt (tab delimited)',
    value: 'txt'
  }
];

const txtAvailableRegex = /subject_data|service_item|compositing/;

const ExportType: FC<ExportTypeProps> = ({ jobExport, onExportStepModalClose, onExportStepComplete }) => {
  const [selectExportType, setSelectExportType] = useState<ExportTypeOpts | null>(null);
  const [exportFormat, setExportFormat] = useState<SingleValue<SelectOptionType>>(exportFormatOptions[0]);
  const isTxtAvailable = selectExportType !== null && selectExportType.match(txtAvailableRegex) !== null;

  const handleExportFormatChange = (select: OnChangeValue<SelectOptionType, false>) => {
    setExportFormat(select);
  };

  const handleConfirm = () => {
    if (selectExportType !== jobExport.export_type) {
      onExportStepComplete(
        {
          export_type: selectExportType,
          export_format: isTxtAvailable ? (exportFormat?.value as any) : 'txt',
          crop_preset: null,
          crop: null
        },
        'type'
      );
    } else {
      onExportStepModalClose();
    }
  };

  useEffect(() => {
    if (jobExport?.export_type) {
      setSelectExportType(jobExport.export_type);
    }
  }, [jobExport]);

  return (
    <aside className="modal animate">
      <div className="modal__box modal__box--dark modal__box--xsmall">
        <header className="modal__header text-left">
          <button className="button button--action modal__close" name="button" type="button" onClick={onExportStepModalClose}>
            <i className="icon-close"></i>
          </button>
          <h3 className="m-0">Export Type</h3>
          <p>
            <small className="text-body-xs">Select an export file to generate.</small>
          </p>
        </header>
        <main className="modal__content">
          <h5 className="text-left">
            Industry Standard Exports{' '}
            <a
              href="https://www.schoolphotographersofamerica.com/post/new-guidelines-standards-for-school-administrative-software"
              className="text-body-sm text--normal"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn More
            </a>
          </h5>
          <ul className="flex gap-5">
            <li className="flex nowrap">
              <input
                id="yearbook"
                type="radio"
                name="type"
                checked={selectExportType === 'yearbook'}
                onChange={() => setSelectExportType(ExportTypeOpts.Yearbook)}
              />
              <label htmlFor="yearbook" className="whitespace-pre-line m-0">
                Yearbook - PSPA <small className="text--normal block">Export a data file (.txt) with photos of each selected subject for yearbooks.</small>
              </label>
            </li>
            <li className="flex flex-nowrap mb-5">
              <input id="spoa" type="radio" name="type" checked={selectExportType === 'spoa'} onChange={() => setSelectExportType(ExportTypeOpts.Spoa)} />
              <label htmlFor="spoa" className="whitespace-pre-line m-0">
                School Admin Software - SPOA{' '}
                <small className="text--normal block">
                  Export a data file (.txt) with photos of each selected subject for your organization to import into their management software.
                </small>
              </label>
            </li>
          </ul>
          <h5 className="text-left">PhotoDay Exports</h5>
          <ul className="flex gap-5">
            <li className="flex nowrap">
              <input
                id="subject_data"
                type="radio"
                name="type"
                checked={selectExportType === 'subject_data'}
                onChange={() => setSelectExportType(ExportTypeOpts.SubjectData)}
              />
              <label htmlFor="subject_data" className="whitespace-pre-line m-0">
                Subject Data <small className="text--normal block">Export a .csv or .txt file that contains information about each selected subject.</small>
              </label>
            </li>
            <li className="flex nowrap">
              <input
                id="service"
                type="radio"
                name="type"
                checked={selectExportType === 'service_item'}
                onChange={() => setSelectExportType(ExportTypeOpts.ServiceItem)}
              />
              <label htmlFor="service" className="whitespace-pre-line m-0">
                Service Item{' '}
                <small className="text--normal block">Export a .csv or .txt file with photos of each subject selected for ID card photos, and more.</small>
              </label>
            </li>
            <li className="flex nowrap">
              <input
                id="compositing"
                type="radio"
                name="type"
                checked={selectExportType === 'compositing'}
                onChange={() => setSelectExportType(ExportTypeOpts.Compositing)}
              />
              <label htmlFor="compositing" className="whitespace-pre-line m-0">
                Compositing Data{' '}
                <small className="text--normal block">
                  Export a .csv or .txt file to help with compositing workflows. Includes subject data with a separate row for each matched image.
                </small>
              </label>
            </li>
          </ul>
          <aside className="mt-2.5">
            <fieldset>
              <label className={`${!isTxtAvailable && 'disabled'}`}>Data File Type</label>
              <Select
                className="select"
                classNamePrefix="select"
                value={exportFormat}
                options={exportFormatOptions}
                onChange={handleExportFormatChange}
                isDisabled={!isTxtAvailable}
              />
            </fieldset>
          </aside>
        </main>
        <footer className="modal__footer">
          <button className="button button--center button--medium" name="confirm" type="button" onClick={handleConfirm} disabled={selectExportType === null}>
            Confirm
          </button>
          <hr />
          <p className="m-0">
            <small>
              Need help? Check out our{' '}
              <a
                href="https://support.photoday.io/en/articles/3379907-what-types-of-data-exports-are-available-to-me"
                target="_blank"
                rel="noopener noreferrer"
              >
                support article on export files
              </a>
              .
            </small>
          </p>
        </footer>
      </div>
    </aside>
  );
};

export default ExportType;
