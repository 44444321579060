import * as types from './constants';

export const salesReportRequest = (payload) => ({
  type: types.SALES_REPORT_REQUEST,
  payload: { payload }
});

export const salesReportRequestSuccess = ({ result }) => ({
  type: types.SALES_REPORT_SUCCESS,
  payload: { result }
});

export const salesReportRequestError = (error) => ({
  type: types.SALES_REPORT_ERROR,
  payload: { error }
});

export const salesMonthReportRequest = (payload, callback) => ({
  type: types.SALES_MONTH_REPORT_REQUEST,
  payload: { payload, callback }
});

export const salesMonthReportRequestSuccess = (payload) => ({
  type: types.SALES_MONTH_REPORT_SUCCESS,
  payload: { payload }
});

export const salesMonthReportRequestError = (error) => ({
  type: types.SALES_MONTH_REPORT_ERROR,
  payload: { error }
});

export const salesSummaryRequest = (year) => ({
  type: types.SALES_SUMMARY_REQUEST,
  payload: { year }
});

export const salesSummaryRequestSuccess = ({ summary }) => ({
  type: types.SALES_SUMMARY_SUCCESS,
  payload: { summary }
});

export const salesSummaryRequestError = (error) => ({
  type: types.SALES_SUMMARY_ERROR,
  payload: { error }
});

export const salesMonthSummaryRequest = (month, year) => ({
  type: types.SALES_MONTH_SUMMARY_REQUEST,
  payload: { month, year }
});

export const salesMonthSummaryRequestSuccess = ({ summary }) => ({
  type: types.SALES_MONTH_SUMMARY_SUCCESS,
  payload: { summary }
});

export const salesMonthSummaryRequestError = (error) => ({
  type: types.SALES_MONTH_SUMMARY_ERROR,
  payload: { error }
});

export const salesReportDownloadRequest = (payload) => ({
  type: types.SALES_REPORT_DOWNLOAD_REQUEST,
  payload: { payload }
});

export const salesMonthReportDownloadRequest = (payload) => ({
  type: types.SALES_MONTH_REPORT_DOWNLOAD_REQUEST,
  payload: { payload }
});

// Products Report
export const productsReportRequest = (payload) => ({
  type: types.PRODUCTS_REPORT_REQUEST,
  payload: { payload }
});

export const productsReportRequestSuccess = ({ result }) => ({
  type: types.PRODUCTS_REPORT_SUCCESS,
  payload: { result }
});

export const productsReportRequestError = (error) => ({
  type: types.PRODUCTS_REPORT_ERROR,
  payload: { error }
});

export const productsReportDownloadRequest = (payload) => ({
  type: types.PRODUCTS_REPORT_DOWNLOAD_REQUEST,
  payload: { payload }
});
