import React from 'react';

const DownloadFileLink = ({ filename, psbFilename, title, psdtFilename, description, videoUrl, height }) => {
  return (
    <div className="resources-container__item-footer">
      {title && description ? (
        <>
          <h6 className="block m-0">{title}</h6>
          <p className="text-body-sm m-0 mt-2.5 mb-2.5">{description}</p>
        </>
      ) : (
        <h6 className="block m-0 mt-2.5 mb-2.5">{title}</h6>
      )}
      <aside className="flex gap-5">
        {filename && (
          <a href={filename} className="text-body-sm" target="_blank" rel="noopener noreferrer" download>
            Download PDF
          </a>
        )}
        {psbFilename && (
          <a href={`https://media.photoday.io/psds/${psbFilename}.psb`} className="text-body-sm" download>
            Download PSB
          </a>
        )}
        {psdtFilename && (
          <a href={psdtFilename} className="text-body-sm" target="_blank" rel="noopener noreferrer" download>
            Download PSDT
          </a>
        )}
        {videoUrl && (
          <a href={videoUrl} className="text-body-sm" target="_blank" rel="noopener noreferrer" download>
            Download Video
          </a>
        )}
      </aside>
    </div>
  );
};

export default DownloadFileLink;
