const imageUpscaler: number = 1;

interface Sizes {
  xsmall: number;
  small: number;
  medium: number;
  large: number;
  xlarge: number;
}

const sizes: Sizes = {
  xsmall: 100,
  small: 200,
  medium: 300,
  large: 600,
  xlarge: 1200
};

// https://docs.imagizer.com/api_reference/
const imagizerScaling = (url: string, width: number | null = null, height: number | null = null): string | undefined => {
  if (width) {
    url = `${url}&w=${width * imageUpscaler}`;
  }

  if (height) {
    url = `${url}&h=${height * imageUpscaler}`;
  }

  return url;
};

interface ImageScalingParams {
  url?: URL | string;
  width?: number | null;
  height?: number | null;
  size?: keyof Sizes;
}

const imageScaling = ({ url, width = null, height = null, size }: ImageScalingParams): string | undefined => {
  if (size) {
    width = sizes[size];
  }

  if (('' + url).slice(0, 5).toLowerCase() === 'data:') {
    return String(url);
  }

  return imagizerScaling(String(url), width, height);
};

export default imageScaling;
