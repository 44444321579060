import { useState } from 'react';
import PropTypes from 'prop-types';
import { InputGroup, Select } from '@/components/Shared/Forms';

const BogoDiscountSelector = ({ onChange, discountType: initialDiscountType, amount: initialAmount }) => {
  const [discountType, setDiscountType] = useState(initialDiscountType || 'free');
  const [amount, setAmount] = useState(initialAmount);

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
    onChange &&
      onChange({
        amount: event.target.value,
        discount_type: discountType
      });
  };

  const handleOfferTypeChange = (event) => {
    setDiscountType(event.target.value);
    onChange &&
      onChange({
        amount,
        discount_type: event.target.value
      });
  };

  return (
    <div id="bogo-discount-container" className="form-segment">
      <label>Discount</label>
      <div className="flex">
        <div className="basis-3/12">
          <Select
            required
            options={[
              { value: 'free', label: 'Free' },
              { value: 'fixed_amount', label: '$ Off' },
              { value: 'fixed_percentage', label: '% Off' }
            ]}
            name="discount_type"
            placeholder="Select..."
            onChange={handleOfferTypeChange}
          />
        </div>
        <div className="basis-3/12">
          {discountType !== 'free' && (
            <InputGroup
              name="amount"
              placeholder={discountType === 'fixed_percentage' ? '0' : '0.00'}
              addonPosition={discountType === 'fixed_percentage' ? 'right' : 'left'}
              onChange={handleAmountChange}
            >
              <span>{discountType === 'fixed_percentage' ? '%' : '$'}</span>
            </InputGroup>
          )}
        </div>
      </div>
    </div>
  );
};

BogoDiscountSelector.propTypes = {
  onChange: PropTypes.func,
  discountType: PropTypes.string,
  amount: PropTypes.string
};

export default BogoDiscountSelector;
