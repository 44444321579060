import { call, put, takeLatest, all } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import * as actions from './actions';
import * as types from './constants';
import * as reportsApi from '@/api/report-api';
import * as academyApi from '@/api/academy-api';

function* getAcademyPlaylist(action) {
  try {
    yield put(showLoading());

    const { payload, callback } = action;
    const response = yield call(academyApi.getAcademyPlaylist, payload);
    const { data } = response;

    yield put(actions.getAcademyPlaylistSuccess({ data }));
    if (callback) callback(response);
  } catch (error) {
    yield put(actions.getAcademyPlaylistError(error));
  } finally {
    yield put(hideLoading());
  }
}

function* getUpcomingWebinars(action) {
  try {
    yield put(showLoading());

    const { payload, callback } = action;
    const response = yield call(academyApi.getUpcomingWebinars, payload);
    const { data } = response;

    yield put(actions.getUpcomingWebinarsSuccess({ data }));
    if (callback) callback(response);
  } catch (error) {
    yield put(actions.getUpcomingWebinarsError(error));
  } finally {
    yield put(hideLoading());
  }
}

function* npsScoreDownload(action) {
  try {
    yield put(showLoading());

    const { payload } = action;

    yield call(reportsApi.npsScoreDownload, payload);
  } catch (error) {
    yield put(actions.getNpsDownloadError(error));
  } finally {
    yield put(hideLoading());
  }
}

function* getAcademyWatcher() {
  yield all([
    takeLatest(types.GET_ACADEMY_PLAYLIST_REQUEST, getAcademyPlaylist),
    takeLatest(types.GET_UPCOMING_WEBINARS_REQUEST, getUpcomingWebinars),
    takeLatest(types.GET_NPS_DOWNLOAD_REQUEST, npsScoreDownload)
  ]);
}

export default getAcademyWatcher;
