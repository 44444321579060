import * as api from '@/utils/http';
const API_URL = import.meta.env.VITE_API_URL;

export const salesReport = ({ payload }) => {
  const endpoint = 'reports/studio_taxes';
  const args = { ...payload };

  return api.get(endpoint, args).then((res) => {
    return { ...res.data };
  });
};

export const salesSummary = (year) => {
  const endpoint = 'reports/studio_taxes';
  const args = { year, summary: true };

  return api.get(endpoint, args).then((res) => {
    return { ...res.data };
  });
};

export const salesMonthReport = (payload) => {
  const endpoint = `${API_URL}reports/studio_taxes/monthly_tax`;
  const args = { ...payload };

  return api.get(endpoint, args);
};

export const salesMonthSummary = (month, year) => {
  const endpoint = `${API_URL}reports/studio_taxes/monthly_tax`;
  const args = { month, year, summary: true };

  return api.get(endpoint, args).then((res) => {
    return { ...res.data };
  });
};

export const salesMonthTaxReportDownload = ({ payload }) => {
  const endpoint = `${API_URL}reports/studio_taxes/monthly_tax`;
  const args = { ...payload };

  api.getBlob(endpoint, args, payload.fileName);
};

export const salesReportDownload = ({ payload }) => {
  const endpoint = `${API_URL}reports/studio_taxes`;
  const args = { ...payload };

  api.getBlob(endpoint, args, payload.fileName);
};

export const productsReport = ({ payload }) => {
  const endpoint = `${API_URL}reports/studio_taxes/products_report`;
  const args = { ...payload };

  return api.get(endpoint, args);
};

export const productsReportDownload = ({ payload }) => {
  const endpoint = `${API_URL}reports/studio_taxes/products_report`;
  const args = { ...payload };

  api.getBlob(endpoint, args, payload.fileName);
};

export const npsScoreDownload = ({ payload }) => {
  const endpoint = `studios/${payload.id}/nps-download`;

  api.getBlob(endpoint, {}, 'studio-nps-score.csv');
};
