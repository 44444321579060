import { FC } from 'react';

// Images
import imageJobDeleted from '@/assets/images/job-illustration-deleted.svg';

const NotFoundDeleted: FC = () => {
  return (
    <main className="container flex flex-col items-center justify-center flex-nowrap h-screen-4/5">
      <img className="mb-7" src={imageJobDeleted} width="335" alt="Job Deleted" />
      <h1 className="md:text-headline">Whoops...it looks like this job has been deleted</h1>
    </main>
  );
};

export default NotFoundDeleted;
