export const CREATE_GIFT_CARD_RESEND_RECEIPT_REQUEST = 'CREATE_GIFT_CARD_RESEND_RECEIPT_REQUEST';
export const CREATE_GIFT_CARD_RESEND_RECEIPT_SUCCESS = 'CREATE_GIFT_CARD_RESEND_RECEIPT_SUCCESS';
export const CREATE_GIFT_CARD_RESEND_RECEIPT_ERROR = 'CREATE_GIFT_CARD_RESEND_RECEIPT_ERROR';

export const GET_GIFT_CARD_TRANSACTIONS_LIST_REQUEST = 'GET_GIFT_CARD_TRANSACTIONS_LIST_REQUEST';
export const GET_GIFT_CARD_TRANSACTIONS_LIST_SUCCESS = 'GET_GIFT_CARD_TRANSACTIONS_LIST_SUCCESS';
export const GET_GIFT_CARD_TRANSACTIONS_LIST_ERROR = 'GET_GIFT_CARD_TRANSACTIONS_LIST_ERROR';

export const GIFT_CARD_DETAIL_REQUEST = 'GIFT_CARD_DETAIL_REQUEST';
export const GIFT_CARD_DETAIL_SUCCESS = 'GIFT_CARD_DETAIL_SUCCESS';
export const GIFT_CARD_DETAIL_ERROR = 'GIFT_CARD_DETAIL_ERROR';
