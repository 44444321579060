export enum PhotoType {
  Featured = 'featured',
  YearbookPhoto1 = 'yearbook_selection_photo_1',
  YearbookPhoto2 = 'yearbook_selection_photo_2',
  FirstCaptured = 'first_captured',
  LastCaptured = 'last_captured',
  FirstCapturedByFileName = 'first_filename',
  LastCapturedByFileName = 'last_filename',
  ContainsSpecificWord = 'filename_filter'
}

export interface Photo {
  id: string;
  captured_at: string;
  image_filename: string;
  forbid_download: boolean;
  face_count: number;
  face_ignored_count: number;
  has_transparency: boolean;
  position: number;
  created_at: string;
  updated_at: string;
  subject_count: number;
  primary_count: number;
  subject_ids: string[];
  missing_subjects_count: number;
  matched: boolean;
  image_url: string;
  retouched_image_url?: string | null;
  width: number;
  height: number;
  confidence?: number | null;
  processing?: boolean;
}
