import * as types from './constants';

// Read Theme Collections
export const getStudioThemeCollections = (payload, callback) => ({
  type: types.GET_STUDIO_THEME_COLLECTIONS_REQUEST,
  payload: { payload, callback }
});

export const getStudioThemeCollectionsSuccess = (payload) => ({
  type: types.GET_STUDIO_THEME_COLLECTIONS_SUCCESS,
  payload: { payload }
});

export const getStudioThemeCollectionsError = (error) => ({
  type: types.GET_STUDIO_THEME_COLLECTIONS_ERROR,
  payload: { error }
});

// Create Theme Collection
export const createThemeCollection = (payload, callback) => ({
  type: types.CREATE_THEME_COLLECTION_REQUEST,
  payload: { payload, callback }
});

export const createThemeCollectionSuccess = (payload) => ({
  type: types.CREATE_THEME_COLLECTION_SUCCESS,
  payload: { payload }
});

export const createThemeCollectionError = (error) => ({
  type: types.CREATE_THEME_COLLECTION_ERROR,
  payload: { error }
});

// Read Theme Collection
export const getThemeCollection = (payload, callback) => ({
  type: types.GET_THEME_COLLECTION_REQUEST,
  payload: { payload, callback }
});

export const getThemeCollectionSuccess = (payload) => ({
  type: types.GET_THEME_COLLECTION_SUCCESS,
  payload: { payload }
});

export const getThemeCollectionError = (error) => ({
  type: types.GET_THEME_COLLECTION_ERROR,
  payload: { error }
});

// Update Theme Collection
export const updateThemeCollection = (payload, callback) => ({
  type: types.UPDATE_THEME_COLLECTION_REQUEST,
  payload: { payload, callback }
});

export const updateThemeCollectionSuccess = (payload) => ({
  type: types.UPDATE_THEME_COLLECTION_SUCCESS,
  payload: { payload }
});

export const updateThemeCollectionError = (error) => ({
  type: types.UPDATE_THEME_COLLECTION_ERROR,
  payload: { error }
});

// Delete Theme Collection
export const deleteThemeCollection = (payload, callback) => ({
  type: types.DELETE_THEME_COLLECTION_REQUEST,
  payload: { payload, callback }
});

export const deleteThemeCollectionSuccess = (payload) => ({
  type: types.DELETE_THEME_COLLECTION_SUCCESS,
  payload: { payload }
});

export const deleteThemeCollectionError = (error) => ({
  type: types.DELETE_THEME_COLLECTION_ERROR,
  payload: { error }
});

// Read Custom Theme Labs
export const getAllCustomThemeLabs = (payload, callback) => ({
  type: types.GET_ALL_CUSTOM_THEME_LABS_REQUEST,
  payload: { payload, callback }
});

export const getAllCustomThemeLabsSuccess = (payload) => ({
  type: types.GET_ALL_CUSTOM_THEME_LABS_SUCCESS,
  payload: { payload }
});

export const getAllCustomThemeLabsError = (error) => ({
  type: types.GET_ALL_CUSTOM_THEME_LABS_ERROR,
  payload: { error }
});

// Read Lab Custom Theme Products
export const getLabCustomThemeProducts = (payload, callback) => ({
  type: types.GET_LAB_CUSTOM_THEME_PRODUCTS_REQUEST,
  payload: { payload, callback }
});

export const getLabCustomThemeProductsSuccess = (payload) => ({
  type: types.GET_LAB_CUSTOM_THEME_PRODUCTS_SUCCESS,
  payload: { payload }
});

export const getLabCustomThemeProductsError = (error) => ({
  type: types.GET_LAB_CUSTOM_THEME_PRODUCTS_ERROR,
  payload: { error }
});

// Read Custom Theme Product Preview
export const getCustomThemeProductPreview = (payload, callback) => ({
  type: types.GET_CUSTOM_THEME_PRODUCT_PREVIEW_REQUEST,
  payload: { payload, callback }
});

export const getCustomThemeProductPreviewSuccess = (payload) => ({
  type: types.GET_CUSTOM_THEME_PRODUCT_PREVIEW_SUCCESS,
  payload: { payload }
});

export const getCustomThemeProductPreviewError = (error) => ({
  type: types.GET_CUSTOM_THEME_PRODUCT_PREVIEW_ERROR,
  payload: { error }
});

// Read PhotoDay Theme Collections
export const getPhotoDayThemeCollections = (payload, callback) => ({
  type: types.GET_PHOTODAY_THEME_COLLECTIONS_REQUEST,
  payload: { payload, callback }
});

export const getPhotoDayThemeCollectionsSuccess = (payload) => ({
  type: types.GET_PHOTODAY_THEME_COLLECTIONS_SUCCESS,
  payload: { payload }
});

export const getPhotoDayThemeCollectionsError = (error) => ({
  type: types.GET_PHOTODAY_THEME_COLLECTIONS_ERROR,
  payload: { error }
});

// Read PhotoDay Theme Collections by Lab
export const getPhotoDayThemeCollectionsByLab = (payload, callback) => ({
  type: types.GET_PHOTODAY_THEME_COLLECTIONS_BY_LAB_REQUEST,
  payload: { payload, callback }
});

export const getPhotoDayThemeCollectionsByLabSuccess = (payload) => ({
  type: types.GET_PHOTODAY_THEME_COLLECTIONS_BY_LAB_SUCCESS,
  payload: { payload }
});

export const getPhotoDayThemeCollectionsByLabError = (error) => ({
  type: types.GET_PHOTODAY_THEME_COLLECTIONS_BY_LAB_ERROR,
  payload: { error }
});
