export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const LOGIN_EXISTING = 'LOGIN_EXISTING';

export const STORAGE_IS_ADMIN = 'isAdmin';

export const GET_PROFILE_FLAGS_REQUEST = 'GET_PROFILE_FLAGS_REQUEST';
export const GET_PROFILE_FLAGS_SUCCESS = 'GET_PROFILE_FLAGS_SUCCESS';
export const GET_PROFILE_FLAGS_ERROR = 'GET_PROFILE_FLAGS_ERROR';

export const SET_PROFILE_FLAGS_REQUEST = 'SET_PROFILE_FLAGS_REQUEST';
export const SET_PROFILE_FLAGS_SUCCESS = 'SET_PROFILE_FLAGS_SUCCESS';
export const SET_PROFILE_FLAGS_ERROR = 'SET_PROFILE_FLAGS_ERROR';

export const INIT = 'INIT';
export const INIT_SUCCESS = 'INIT_SUCCESS';
export const INIT_ERROR = 'INIT_ERROR';

export const RESET_STATE = 'RESET_STATE';
export const RESET_STATE_ERROR = 'RESET_STATE_ERROR';

export const JWT_EXPIRED = 'JWT_EXPIRED';

export const UPDATE_LOGIN_STUDIO_SUCCESS = 'UPDATE_LOGIN_STUDIO_SUCCESS';
export const UPDATE_LOGIN_STUDIO_LABS_SUCCESS = 'UPDATE_LOGIN_STUDIO_LABS_SUCCESS';
