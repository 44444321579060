import { useCallback } from 'react';
import PriceSheetForm from './PriceSheetForm';

const PriceSheetsModal = ({ priceSheet, labs, isVisible, labId, disableLabs, onSubmit, requesting, labSelected, toggleModal }) => {
  const close = useCallback(() => {
    toggleModal(false);
  }, [toggleModal]);

  const shippingOptions = [
    { value: 'drop_shipping', label: 'Drop' },
    { value: 'bulk_shipping', label: 'Bulk' },
    { value: 'drop_or_bulk_shipping', label: 'Bulk or Drop' }
  ];

  const markupOptions = [
    { value: 'fixed_amount', label: 'Fixed Amount' },
    { value: 'fixed_percentage', label: 'Percent' }
  ];

  const modalTitle = !priceSheet.id ? 'Add a New Price Sheet' : 'View/Edit a Price Sheet';

  const initialValues = {
    shipping_type: shippingOptions.find((option) => option.value === priceSheet.shipping_type) || { value: 'drop_shipping', label: 'Drop' },
    name: priceSheet.name,
    markup_attributes: {
      amount: priceSheet.markup_attributes.amount || '',
      markup_type: markupOptions.find((option) => option.value === priceSheet.markup_attributes.markup_type) || '',
      fixed_amount_cents: priceSheet.markup_attributes.fixed_amount_cents,
      fixed_percentage: priceSheet.markup_attributes.fixed_percentage
    },
    lab_id: (labs || []).find((lab) => lab.selected),
    jobs_count: priceSheet.jobs_count
  };

  return (
    <aside className={`modal pricesheets-add-modal ${isVisible ? '' : 'transparent'}`}>
      <div className="modal__box modal__box--dark modal__box--small">
        <header className="modal__header">
          <button className="button button--action modal__close" name="button" type="button" onClick={close}>
            <i className="icon-close"></i>
          </button>
          <h3 className="text-left">{modalTitle}</h3>
        </header>
        <main className="modal__content">
          <PriceSheetForm
            labs={labs}
            labId={labId}
            onSubmit={onSubmit}
            submitLabel="Add Package"
            disableLabs={disableLabs}
            requesting={requesting}
            priceSheetId={priceSheet.id}
            initialValues={initialValues}
            labSelected={labSelected}
          />
        </main>
      </div>
    </aside>
  );
};

export default PriceSheetsModal;
