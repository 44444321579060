import { PRODUCT_CATEGORY_LIST_REQUEST, PRODUCT_CATEGORY_LIST_SUCCESS, PRODUCT_CATEGORY_LIST_ERROR } from './constants';

export const productCategoryListRequest = ({ labId }, callback) => ({
  type: PRODUCT_CATEGORY_LIST_REQUEST,
  payload: {
    labId
  },
  callback
});

export const productCategoryListRequestSuccess = ({ entities }, labId) => ({
  type: PRODUCT_CATEGORY_LIST_SUCCESS,
  payload: {
    entities,
    labId
  }
});

export const productCategoryListRequestError = (error) => ({
  type: PRODUCT_CATEGORY_LIST_ERROR,
  payload: {
    error
  }
});
