import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

// Plugins
import moment from 'moment';
import Select from 'react-select';
import DatePicker from 'react-datepicker';

// Components
import Sidebar from '../Sidebar';
import Header from '../../Header';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { getJobTimezoneList, updateJob, deleteJob } from '../../actions';
import { getOrganizationList } from '../../../Organizations/actions';

// Hooks
import { useJobTimeZone } from '@/hooks';

// Helpers
import validator from '@/utils/validator';

// Styles
import './style.css';

const CAPTURE_QR_ENABLED = import.meta.env.VITE_CAPTURE_QR_ENABLED === '1';
const CUSTOM_THEMES_ENABLED = import.meta.env.VITE_CUSTOM_THEMES_ENABLED === '1';
const ORG_ITEMS_ORDER = 'name';
const ORG_ITEMS_PER_PAGE = 10000;

const Details = ({ match, history }) => {
  const dispatch = useDispatch();
  const [jobTimezone] = useJobTimeZone();

  const {
    params: { jobId },
    path
  } = match;
  const {
    studio: { feature_flags: studioFeatureFlags }
  } = useSelector((state) => state.login);
  const { job, timezones, jobTypes } = useSelector((state) => state.jobs);
  const { organizations, requesting: organizationsRequesting } = useSelector((state) => state.organizations);

  const requesting = job.requesting || organizationsRequesting;
  const isStudioCaptureQrAllowed = CAPTURE_QR_ENABLED && studioFeatureFlags?.allow_capture_qr ? true : false;

  const [showArchiveModal, setShowArchiveModal] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState('');

  const [initialInformation, setInitialInformation] = useState({});
  const [information, setInformation] = useState({
    logo_url: '',
    logo_filename: '',
    logo_attachment: {},
    use_logo_for_themes: false,
    name: '',
    date: '',
    client_id: '',
    job_type: '',
    time_zone: '',
    gallery_v4: true,
    biometrics_enabled: false,
    estimated_subjects_count: '',
    reporting_code: undefined,
    search_opt_tags: false,
    search_opt_face_find: false,
    search_opt_show_all_photos: false
  });

  const handleLogoUpload = (e) => {
    const image = e.target.files[0];

    if (image) {
      const { name, type } = image;
      const reader = new FileReader();

      reader.addEventListener(
        'load',
        () => {
          setInformation({
            ...information,
            logo_attachment: {
              content: reader.result,
              filename: name,
              content_type: type
            }
          });
        },
        false
      );

      reader.readAsDataURL(image);
    }
  };

  const handleLogoRemove = () => setInformation({ ...information, logo_url: null, logo_filename: null, logo_attachment: {}, use_logo_for_themes: false });

  const handleInputChange = (e, validation) => {
    const input = e.target;
    const valid = validator({ ...validation, value: input.value });

    input.classList[`${valid ? 'remove' : 'add'}`]('input--invalid');
    setInformation({ ...information, [input.name]: valid ? input.value : information[input.name] });
  };

  const handleSelectChange = (select) => setInformation({ ...information, [select.name]: select.value });

  const handleCheckboxChange = (e) => setInformation({ ...information, [e.target.name]: e.target.checked });

  const handleSwitchChange = (e) => setInformation({ ...information, [e.target.name]: e.target.checked });

  const handleDateChange = (date) => setInformation({ ...information, date });

  const handleSave = () => {
    const newInformation = { ...information };

    if (!information.logo_attachment?.content && information.logo_url) {
      delete newInformation.logo_attachment;
    }

    dispatch(updateJob({ id: jobId, ...newInformation }));
  };

  const handleOrganizationListRefresh = () =>
    dispatch(getOrganizationList({ studioId: job.studio_id, searchParams: { order: ORG_ITEMS_ORDER, per_page: ORG_ITEMS_PER_PAGE } }));

  const handleArchiveJob = () => {
    dispatch(deleteJob({ id: job.id }, () => history.replace(`/jobs/archived`)));
  };

  const handleDeleteChange = (e) => setConfirmDelete(e.target.value);

  const handleDeleteJob = () => {
    dispatch(updateJob({ id: job.id, hidden: true, reporting_code: null }, () => history.replace(`/jobs`)));
  };

  useEffect(() => {
    if (!timezones.length) {
      dispatch(getJobTimezoneList());
    }
  }, []);

  useEffect(() => {
    if (job?.id && job?.requesting === false) {
      const information = {
        logo_url: job.logo_url,
        logo_filename: job.logo_filename,
        logo_attachment: {},
        use_logo_for_themes: job.use_logo_for_themes,
        name: job.name,
        date: job.date,
        job_type: job.job_type,
        client_id: job.client_id,
        time_zone: job.time_zone,
        gallery_v4: job.gallery_v4,
        biometrics_enabled: job.biometrics_enabled,
        reporting_code: job.reporting_code,
        search_opt_tags: job.search_opt_tags,
        search_opt_face_find: job.search_opt_face_find,
        search_opt_show_all_photos: job.search_opt_show_all_photos,

        estimated_subjects_count: job.estimated_subjects_count || ''
      };

      setInformation(information);
      setInitialInformation(information);
    }

    if (organizations.length === 0 && job.studio_id) {
      dispatch(getOrganizationList({ studioId: job.studio_id, searchParams: { order: ORG_ITEMS_ORDER, per_page: ORG_ITEMS_PER_PAGE } }));
    }
  }, [job?.id]);

  return (
    <>
      <Header history={history} jobId={jobId} title="Settings" />

      <main className="container flex job-settings-details">
        <Sidebar jobType={job.access_mode} jobId={jobId} path={path} />

        <section className="basis-9/12 md:basis-full job-settings-store__container">
          <header className="flex justify-between items-center job-settings-details__header">
            <h2 className="text-headline-sm">Job Details</h2>
            <button
              type="button"
              className="button button--medium"
              onClick={handleSave}
              disabled={requesting || JSON.stringify(information) === JSON.stringify(initialInformation)}
            >
              Save
            </button>
          </header>

          <form className="panel panel--section">
            <h3 className="mb-5">Job Information</h3>
            <div className="flex gap-4">
              <div className="basis-2/6 sm:basis-full">
                <fieldset className="flex flex-col">
                  <label htmlFor="job-logo">Logo (optional)</label>
                  <figure className="flex justify-center items-center job-settings-details__logo">
                    {information.logo_attachment?.content || information.logo_url ? (
                      <>
                        {information.logo_attachment?.content ? (
                          <img
                            className="job-settings-details__logo-image animate"
                            src={information.logo_attachment.content}
                            alt={information.logo_attachment.filename}
                          />
                        ) : (
                          <img className="job-settings-details__logo-image animate" src={information.logo_url} alt={information.logo_filename} />
                        )}
                      </>
                    ) : (
                      <i className="icon-logo-placeholder animate" />
                    )}
                  </figure>
                  <label htmlFor="job-logo" className="button button--outline button--block">
                    {information.logo_attachment?.content || information.logo_url ? 'Replace' : 'Upload Logo'}
                  </label>
                  <input id="job-logo" className="hidden" type="file" accept="image/png" onChange={handleLogoUpload} />
                  {(information.logo_attachment?.content || information.logo_url) && (
                    <button className="button button--outline button--grey" type="button" name="remove" onClick={handleLogoRemove}>
                      Remove
                    </button>
                  )}
                </fieldset>
                {CUSTOM_THEMES_ENABLED && (information.logo_attachment?.content || information.logo_url) && (
                  <>
                    <fieldset>
                      <p className="text-body-sm">
                        Job logos appear as the Gallery icon for Group & Public (& Private until subject images are uploaded) Galleries.
                      </p>
                      <h5 className="flex items-center justify-between">
                        <span>Use logo on themes</span>
                        <input
                          id="use_logo_for_themes"
                          className="hidden"
                          name="use_logo_for_themes"
                          type="checkbox"
                          checked={information.use_logo_for_themes}
                          onChange={handleSwitchChange}
                        />
                        <label htmlFor="use_logo_for_themes" className="label-switch label-switch--small" />
                      </h5>
                      <p className="text-body-sm">
                        When toggled on, the job logo may appear on Memory Mates with some labs.{' '}
                        <a href="https://support.photoday.io/en/articles/4427775-best-practices-for-custom-job-logos" target="_blank" rel="noopener noreferrer">
                          Learn more
                        </a>{' '}
                        about eligible products and job logo best practices.
                      </p>
                    </fieldset>
                  </>
                )}
              </div>
              <div className="basis-8/12 sm:basis-full">
                <div className="flex mb-5 gap-4">
                  <fieldset className="flex flex-col basis-6/12 sm:basis-full">
                    <label htmlFor="name">Job Name</label>
                    <input id="name" type="text" name="name" value={information.name} required placeholder="job name" onChange={handleInputChange} />
                  </fieldset>
                  <fieldset className="flex flex-col basis-6/12 sm:basis-full">
                    <label htmlFor="client_id">Organization</label>
                    <div className="job-details__select">
                      <Select
                        id="client_id"
                        className="select"
                        classNamePrefix="select"
                        isLoading={requesting}
                        placeholder="Select an organization"
                        value={organizations
                          .filter((organization) => organization.id === information.client_id)
                          .map((organization) => ({ value: organization.id, label: organization.name }))}
                        options={organizations.map((organization) => ({ value: organization.id, label: organization.name }))}
                        onChange={(value) => handleSelectChange({ ...value, name: 'client_id' })}
                      />
                      <button className="button button--clean" type="button" onClick={handleOrganizationListRefresh}>
                        <i className="icon-refresh"></i>
                      </button>
                    </div>
                  </fieldset>
                </div>
                <div className="flex gap-4 mb-5">
                  <fieldset className="flex flex-col basis-6/12 sm:basis-full">
                    <label htmlFor="job_type">Job Category</label>
                    <Select
                      id="job_type"
                      className="select"
                      classNamePrefix="select"
                      name="job_type"
                      value={jobTypes.filter((type) => type.value === information.job_type)}
                      options={jobTypes}
                      onChange={(value) => handleSelectChange({ ...value, name: 'job_type' })}
                    />
                  </fieldset>
                  <fieldset className="flex flex-col basis-6/12 sm:basis-full">
                    <label htmlFor="date">Picture Day Date</label>
                    <DatePicker
                      id="date"
                      className="input--date"
                      name="date"
                      isClearable={true}
                      dateFormat="MM/dd/yy"
                      placeholderText="MM/DD/YY"
                      selected={jobTimezone ? moment(information.date)?.tz(jobTimezone)?.format('L') : new Date()}
                      onChange={handleDateChange}
                      autoComplete="off"
                    />
                  </fieldset>
                </div>
                <div className="flex gap-4">
                  <fieldset className="flex flex-col basis-6/12 sm:basis-full">
                    <label htmlFor="estimated_subjects_count"># Being Photographed</label>
                    <input
                      id="estimated_subjects_count"
                      type="number"
                      name="estimated_subjects_count"
                      value={information.estimated_subjects_count}
                      onChange={(e) => handleInputChange(e, { name: 'maxLength', rule: 5 })}
                      placeholder="Estimate (Optional)"
                      min="1"
                      max="10000"
                    />
                  </fieldset>
                  <fieldset className="flex flex-col basis-6/12 sm:basis-full">
                    <label htmlFor="time_zone">Time Zone</label>
                    <Select
                      id="time_zone"
                      className="select"
                      classNamePrefix="select"
                      name="time_zone"
                      value={{ value: information.time_zone, label: information.time_zone }}
                      options={timezones.map(({ name }) => ({ value: name, label: name }))}
                      onChange={(value) => handleSelectChange({ ...value, name: 'time_zone' })}
                    />
                  </fieldset>
                  {job.studio_reporting_code && (
                    <fieldset className="basis-full sm:basis-full">
                      <label htmlFor="reporting_code">Job Reporting ID</label>
                      <input
                        id="reporting_code"
                        type="text"
                        name="reporting_code"
                        value={information.reporting_code || ''}
                        onChange={(e) => handleInputChange(e, { name: 'maxLength', rule: 100 })}
                        placeholder="Job Reporting ID"
                        min="1"
                        max="100"
                      />
                    </fieldset>
                  )}
                </div>
                <aside className="panel panel--round panel--secondary panel--nomargin">
                  <h5>PhotoDay Galleries</h5>
                  <p className="m-0">
                    <small>
                      Your customers can access their photos at{' '}
                      <a href="https://my.photoday.com" target="_blank" rel="noopener noreferrer">
                        my.photoday.com
                      </a>
                      .
                    </small>
                  </p>
                </aside>
              </div>
            </div>
          </form>

          {job.access_mode !== 'access_per_subject' && job.setup_steps.gallery_access && (
            <form className="panel panel--section">
              <h3>Gallery Search Options</h3>
              <p>
                Select how you want your customers to search for their photos. By default, they will be able to search using all three methods listed below
                unless otherwise specified. {isStudioCaptureQrAllowed && 'If Biometrics is disabled FaceFind will not be available as a search method.'}
              </p>
              <fieldset className="flex flex-col job-settings-details__panel-description">
                <input
                  id="search_opt_show_all_photos"
                  className="hidden"
                  type="checkbox"
                  name="search_opt_show_all_photos"
                  onChange={handleCheckboxChange}
                  checked={information.search_opt_show_all_photos}
                />
                <label htmlFor="search_opt_show_all_photos" className="label--checkbox">
                  All Photos
                </label>

                <input
                  id="search_opt_face_find"
                  className="hidden"
                  type="checkbox"
                  name="search_opt_face_find"
                  onChange={handleCheckboxChange}
                  disabled={job.biometrics_enabled === false}
                  checked={information.search_opt_face_find}
                />
                <label htmlFor="search_opt_face_find" className={`label--checkbox ${job.biometrics_enabled === false ? 'disabled' : ''}`}>
                  FaceFind
                </label>

                <input
                  id="search_opt_tags"
                  className="hidden"
                  type="checkbox"
                  name="search_opt_tags"
                  onChange={handleCheckboxChange}
                  checked={information.search_opt_tags}
                />
                <label htmlFor="search_opt_tags" className="label--checkbox">
                  Tags
                </label>
              </fieldset>
            </form>
          )}
          {isStudioCaptureQrAllowed && job.access_mode && (
            <div className="job-settings__section">
              <h5>
                Biometrics{' '}
                {job.biometrics_enabled ? (
                  <span className="pill pill--large pill--green font-bold">Enabled</span>
                ) : (
                  <span className="pill pill--large pill--orange font-bold">Disabled</span>
                )}
              </h5>
              {job.biometrics_enabled ? (
                <p>
                  FaceFind is enabled and will be used to match subjects to their photos.{' '}
                  <a href="https://support.photoday.io/en/articles/5580626-photoday-s-facefind-feature" target="_blank" rel="noopener noreferrer">
                    Learn more
                  </a>
                  .
                </p>
              ) : (
                <p>
                  FaceFind is disabled and will not be used to match subjects to their photos.{' '}
                  <a href="https://support.photoday.io/en/articles/5580626-photoday-s-facefind-feature" target="_blank" rel="noopener noreferrer">
                    Learn more
                  </a>
                  .
                </p>
              )}
            </div>
          )}
          <div className="job-settings__section">
            <h5>Archive Job</h5>
            <p>
              Archiving will hide this job from the current list of jobs. It will NOT be deleted. You can access and restore this job by viewing the archives
              and selecting the restore button.{' '}
              <button className="button button--link" type="button" onClick={() => setShowArchiveModal(true)}>
                Archive this job.
              </button>
            </p>
          </div>
          <div className="job-settings__section">
            <h5>Delete Job</h5>
            <p>
              <span className="text-error-500">WARNING!</span> This action cannot be undone. If you'd like to delete this job, you may do so{' '}
              <button className="button button--link" type="button" onClick={() => setShowDeleteModal(true)}>
                here.
              </button>
            </p>
          </div>
        </section>

        {/* Archive modal */}
        <aside className={`modal ${showArchiveModal ? '' : 'transparent'} text-left`}>
          <div className="modal__box modal__box--xsmall modal__box--nomin">
            <header className="modal__header">
              <button className="button button--action modal__close" name="button" type="button" onClick={() => setShowArchiveModal(false)}>
                <i className="icon-close"></i>
              </button>
              <h3>Archive Job</h3>
            </header>
            <main className="modal__content">
              <p>
                Archiving will hide this job from the current list of jobs. It will NOT be deleted. You can access and restore this job by viewing the archives
                and selecting the restore button.
              </p>
            </main>
            <footer className="text-center modal__footer">
              <button className="button button--danger button--large" name="button" type="button" onClick={handleArchiveJob} disabled={requesting}>
                Archive
              </button>
            </footer>
          </div>
        </aside>

        {/* Delete modal */}
        <aside className={`modal ${showDeleteModal ? '' : 'transparent'}`}>
          <div className="modal__box modal__box--small modal__box--nomin">
            <header className="modal__header">
              <button className="button button--action modal__close" name="button" type="button" onClick={() => setShowDeleteModal(false)}>
                <i className="icon-close"></i>
              </button>
              <h3>Delete Job</h3>
            </header>
            <main className="modal__content">
              <p>
                Are you sure you want to delete <strong>{job.name || 'this'}</strong> job?
              </p>
              <p>
                Type <b>"delete"</b> to permanently delete this job.
              </p>
              <input className="input--block" type="text" name="delete" value={confirmDelete} maxLength={50} onChange={handleDeleteChange} />
            </main>
            <footer className="grid justify-center gap-5 modal__footer">
              <button
                className="button button--danger button--large"
                name="button"
                type="button"
                onClick={handleDeleteJob}
                disabled={!(confirmDelete.toLowerCase() === 'delete')}
              >
                Delete
              </button>
              <button className="button button--clean button--large" name="button" type="button" onClick={() => setShowDeleteModal(false)}>
                Cancel
              </button>
            </footer>
          </div>
        </aside>
      </main>
    </>
  );
};

Details.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      jobId: PropTypes.string.isRequired
    })
  }),
  history: PropTypes.object.isRequired
};

export default Details;
