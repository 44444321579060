import { call, put, takeLatest, all } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import * as types from './constants';
import * as actions from './actions';
import * as api from '@/api/shipping-api';

import shippingSchema from './schema';
import errorHandler from '@/utils/errorResponseHandler';
import parseNormalizeListResponse from '@/utils/responseParsers';

function* getListRequestFlow(action) {
  try {
    yield put(showLoading());

    const { labId, page, perPage, search, order, dir } = action.payload;
    const response = yield call(api.shippingList, labId, page, perPage, search, order, dir);
    const payload = parseNormalizeListResponse(response, shippingSchema, order, dir, search);

    yield put(actions.shippingListRequestSuccess(payload));
  } catch (error) {
    yield call(errorHandler, actions.shippingListRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getShippingSummaryRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.getShippingSummary, payload);

    yield put(actions.getShippingSummarySuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getShippingSummaryError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getBulkShippingOrderListRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.getBulkShippingOrderList, payload);

    yield put(actions.getBulkShippingOrderListSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getBulkShippingOrderListError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getBulkShippingOrderRequest(action) {
  const {
    payload: { payload, callback }
  } = action;

  try {
    yield put(showLoading());

    const response = yield call(api.getBulkShippingOrder, payload);

    yield put(actions.getBulkShippingOrderSuccess(response));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorHandler, actions.getBulkShippingOrderError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getShippingWatcher() {
  yield all([
    takeLatest(types.SHIPPING_LIST_REQUEST, getListRequestFlow),
    takeLatest(types.GET_SHIPPING_SUMMARY_REQUEST, getShippingSummaryRequest),
    takeLatest(types.GET_BULK_SHIPPING_ORDER_LIST_REQUEST, getBulkShippingOrderListRequest),
    takeLatest(types.GET_BULK_SHIPPING_ORDER_REQUEST, getBulkShippingOrderRequest)
  ]);
}

export default getShippingWatcher;
