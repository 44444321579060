import * as types from './constants';

export const getAcademyPlaylist = (payload, callback) => ({
  type: types.GET_ACADEMY_PLAYLIST_REQUEST,
  payload: {
    payload,
    callback
  }
});

export const getAcademyPlaylistSuccess = (payload) => ({
  type: types.GET_ACADEMY_PLAYLIST_SUCCESS,
  payload: { payload }
});

export const getAcademyPlaylistError = (error) => ({
  type: types.GET_ACADEMY_PLAYLIST_ERROR,
  payload: { error }
});

export const getUpcomingWebinars = (payload, callback) => ({
  type: types.GET_UPCOMING_WEBINARS_REQUEST,
  payload: {
    payload,
    callback
  }
});

export const getUpcomingWebinarsSuccess = (payload) => ({
  type: types.GET_UPCOMING_WEBINARS_SUCCESS,
  payload: { payload }
});

export const getUpcomingWebinarsError = (error) => ({
  type: types.GET_UPCOMING_WEBINARS_ERROR,
  payload: { error }
});

export const getNpsScoreDownload = (payload, callback) => ({
  type: types.GET_NPS_DOWNLOAD_REQUEST,
  payload: { payload, callback }
});

export const getNpsScoreDownloadSuccess = (payload) => ({
  type: types.GET_NPS_DOWNLOAD_SUCCESS,
  payload: { payload }
});

export const getNpsDownloadError = (error) => ({
  type: types.GET_NPS_DOWNLOAD_ERROR,
  payload: { error }
});
