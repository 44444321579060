import * as types from './constants';
import defaultPager from '@/utils/defaultPager';
import { handleErrorState, handleRequestingState, handleSuccessState } from '@/utils/reducerHelpers';

const initialState = {
  result: [],
  entities: {
    pricesheets: {},
    pricesheet: {},
    labs: {}
  },
  themes: [],
  themeProperties: {},
  pager: Object.assign({}, defaultPager),
  requesting: false,
  successful: false,
  errors: []
};

const handleResetPriceSheets = (state) => {
  return {
    ...state,
    result: [],
    entities: {
      pricesheets: {},
      pricesheet: {},
      labs: {}
    },
    themes: [],
    themeProperties: {}
  };
};

const handlePricesheetListSuccessState = (state, payload) => {
  const { entities, pager, result } = payload;
  const { entities: pricesheets } = state;
  const newEntities = Object.assign({}, pricesheets, entities);
  return {
    ...state,
    ...{
      result,
      entities: newEntities,
      pager,
      errors: [],
      requesting: false,
      successful: true
    }
  };
};

const handlePricesheetSuccessState = (state, payload) => {
  const { pricesheet } = payload;
  const { data } = pricesheet;
  const state_entities = state.entities;
  const entities = { ...state_entities, ...{ pricesheet: data } };
  const themes = data.primary_theme_properties[0]?.theme_property_values;

  return {
    ...state,
    ...{
      entities,
      themes,
      errors: [],
      requesting: false,
      successful: true,
      pricesheetIdDup: null
    }
  };
};

const handleCreatePricesheetSuccessState = (state, payload) => {
  const { pricesheet } = payload;
  return {
    ...state,
    ...{
      result: state.result.concat(pricesheet.id),
      entities: {
        ...state.entities,
        pricesheets: {
          ...state.entities.pricesheets,
          [pricesheet.id]: pricesheet
        }
      },
      errors: [],
      requesting: false,
      successful: true
    }
  };
};

const handleUpdatePricesheetSuccessState = (state, payload) => {
  const { pricesheet } = payload;
  return {
    ...state,
    ...{
      entities: { ...state.entities, pricesheet },
      errors: [],
      requesting: false,
      successful: true
    }
  };
};

const handleCreatePricesheetItemSuccessState = (state, payload) => {
  const { pricesheetItem } = payload;
  const {
    entities: { pricesheet }
  } = state;
  const newPricesheetItems = pricesheet.price_sheet_items.concat(pricesheetItem);

  return {
    ...state,
    ...{
      entities: {
        ...state.entities,
        pricesheet: { ...pricesheet, price_sheet_items: newPricesheetItems }
      },
      errors: [],
      pricesheetIdDup: null,
      requesting: false,
      successful: true
    }
  };
};

const handleUpdatePricesheetItemSuccessState = (state, payload) => {
  const { pricesheetItem } = payload;
  const {
    entities: { pricesheet }
  } = state;
  const newPricesheetItems = pricesheet.price_sheet_items.map((price_sheet_item) =>
    pricesheetItem.id === price_sheet_item.id ? pricesheetItem : price_sheet_item
  );
  return {
    ...state,
    ...{
      entities: {
        ...state.entities,
        pricesheet: { ...pricesheet, price_sheet_items: newPricesheetItems }
      },
      errors: [],
      requesting: false,
      successful: true
    }
  };
};

const handleDeletePricesheetItemSuccessState = (state, payload) => {
  const { pricesheetItemId } = payload;
  const {
    entities: { pricesheet }
  } = state;
  const newPricesheetItems = pricesheet.price_sheet_items.filter(({ id }) => id !== pricesheetItemId);
  return {
    ...state,
    ...{
      entities: {
        ...state.entities,
        pricesheet: { ...pricesheet, price_sheet_items: newPricesheetItems }
      },
      errors: [],
      requesting: false,
      successful: true
    }
  };
};

const handleGetLabSuccessState = (state, payload) => {
  const {
    entities: { labs }
  } = state;
  const { lab } = payload;

  return {
    ...state,
    entities: {
      ...state.entities,
      labs: {
        ...labs,
        [lab.id]: lab
      }
    },
    errors: [],
    requesting: false,
    successful: true
  };
};

const handleCopyPricesheetRequestingState = (state, payload) => {
  return {
    ...state,
    requesting: true,
    pricesheetIdDup: payload.pricesheetItemId
  };
};

const handleCopyPricesheetSuccessState = (state) => {
  return {
    ...state,
    errors: [],
    requesting: false,
    successful: true
  };
};

const handleFeaturePriceSheetProductSuccess = (state) => {
  return {
    ...state,
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleUnfeaturePriceSheetProductSuccess = (state) => {
  return {
    ...state,
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetPriceSheetThemePropertiesSuccess = (state, { payload }) => {
  return {
    ...state,
    themeProperties: payload.data,
    errors: [],
    successful: true,
    requesting: false
  };
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.RESET_PRICESHEETS_REQUEST:
      return handleResetPriceSheets(state);

    case types.GET_PRICESHEET_LIST_REQUEST:
    case types.GET_PRICESHEET_REQUEST:
    case types.CREATE_PRICESHEET_REQUEST:
    case types.UPDATE_PRICESHEET_REQUEST:
    case types.DELETE_PRICESHEET_REQUEST:
    case types.CREATE_PRICESHEET_ITEM_REQUEST:
    case types.CREATE_PRICESHEET_ITEM_BY_PRODUCTS_REQUEST:
    case types.CREATE_PRICESHEET_ITEM_DIGITAL_BUNDLE_REQUEST:
    case types.UPDATE_PRICESHEET_ITEM_REQUEST:
    case types.DELETE_PRICESHEET_ITEM_REQUEST:
    case types.GET_LAB_REQUEST:
    case types.COPY_PRICESHEET_REQUEST:
    case types.GET_PRICESHEET_ACTIVITIES:
    case types.FEATURE_PRICE_SHEET_PRODUCT:
    case types.UNFEATURE_PRICE_SHEET_PRODUCT:
    case types.GET_PRICESHEET_CSV_REQUEST:
    case types.GET_PRICESHEET_THEME_PROPERTIES_REQUEST:
      return handleRequestingState(state);

    case types.COPY_PRICESHEET_ITEM_REQUEST:
      return handleCopyPricesheetRequestingState(state, payload);

    case types.GET_PRICESHEET_LIST_SUCCESS:
      return handlePricesheetListSuccessState(state, payload);
    case types.GET_PRICESHEET_SUCCESS:
      return handlePricesheetSuccessState(state, payload);

    case types.CREATE_PRICESHEET_SUCCESS:
      return handleCreatePricesheetSuccessState(state, payload);

    case types.UPDATE_PRICESHEET_SUCCESS:
      return handleUpdatePricesheetSuccessState(state, payload);

    case types.CREATE_PRICESHEET_ITEM_SUCCESS:
    case types.CREATE_PRICESHEET_ITEM_BY_PRODUCTS_SUCCESS:
    case types.CREATE_PRICESHEET_ITEM_DIGITAL_BUNDLE_SUCCESS:
    case types.COPY_PRICESHEET_ITEM_SUCCESS:
      return handleCreatePricesheetItemSuccessState(state, payload);

    case types.UPDATE_PRICESHEET_ITEM_SUCCESS:
      return handleUpdatePricesheetItemSuccessState(state, payload);

    case types.DELETE_PRICESHEET_ITEM_SUCCESS:
      return handleDeletePricesheetItemSuccessState(state, payload);

    case types.GET_LAB_SUCCESS:
      return handleGetLabSuccessState(state, payload);

    case types.COPY_PRICESHEET_SUCCESS:
      return handleCopyPricesheetSuccessState(state, payload);

    case types.FEATURE_PRICE_SHEET_PRODUCT_SUCCESS:
      return handleFeaturePriceSheetProductSuccess(state, payload);

    case types.UNFEATURE_PRICE_SHEET_PRODUCT_SUCCESS:
      return handleUnfeaturePriceSheetProductSuccess(state, payload);

    case types.GET_PRICESHEET_THEME_PROPERTIES_SUCCESS:
      return handleGetPriceSheetThemePropertiesSuccess(state, payload);

    case types.DELETE_PRICESHEET_SUCCESS:
    case types.GET_PRICESHEET_CSV_SUCCESS:
      return handleSuccessState(state);

    case types.GET_PRICESHEET_LIST_ERROR:
    case types.GET_PRICESHEET_ERROR:
    case types.CREATE_PRICESHEET_ERROR:
    case types.UPDATE_PRICESHEET_ERROR:
    case types.DELETE_PRICESHEET_ERROR:
    case types.CREATE_PRICESHEET_ITEM_ERROR:
    case types.CREATE_PRICESHEET_ITEM_BY_PRODUCTS_ERROR:
    case types.CREATE_PRICESHEET_ITEM_DIGITAL_BUNDLE_ERROR:
    case types.COPY_PRICESHEET_ITEM_ERROR:
    case types.UPDATE_PRICESHEET_ITEM_ERROR:
    case types.DELETE_PRICESHEET_ITEM_ERROR:
    case types.GET_LAB_ERROR:
    case types.COPY_PRICESHEET_ERROR:
    case types.GET_PRICESHEET_ACTIVITIES_ERROR:
    case types.FEATURE_PRICE_SHEET_PRODUCT_ERROR:
    case types.UNFEATURE_PRICE_SHEET_PRODUCT_ERROR:
    case types.GET_PRICESHEET_CSV_ERROR:
    case types.GET_PRICESHEET_THEME_PROPERTIES_ERROR:
      return handleErrorState(state, payload);

    default:
      return state;
  }
};

export default reducer;
