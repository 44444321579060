import * as api from '@/utils/http';

export const getLab = (labId) => {
  const endpoint = `labs/${labId}`;

  return api.get(endpoint);
};

export const getAllLabs = () => {
  const endpoint = `labs`;

  return api.get(endpoint);
};

export const getStudioLabsList = (payload) => {
  const endpoint = `studios/${payload}/studio-labs`;

  return api.get(endpoint).then((res) => {
    return { ...res.data };
  });
};

export const addStudioLabToStudio = (payload) => {
  const { studio_id, lab_id } = payload;
  const params = { lab_id: lab_id, primary: 'false' };
  const endpoint = `studios/${studio_id}/studio-labs`;

  return api.post(endpoint, params).then((res) => {
    return { ...res.data };
  });
};
