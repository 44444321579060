import { FC } from 'react';
import { useLocation, useParams, useHistory, Switch, Redirect, Route } from 'react-router-dom';

// Components
import Header from '../Header';
import SideBar from './Sidebar';
import GettingStarted from './GettingStarted';
import ActiveMessages from './ActiveMessages';
import Flyers from './Flyers';
import FlyersHistory from './Flyers/History';
import FlyersDesign from './Flyers/Design';
import CampaignSettings from './CampaignSettings';

// Styles
import './style.css';

const MARKETING_TIMELINE_ENABLED = import.meta.env.VITE_MARKETING_TIMELINE_ENABLED === '1';

const Marketing: FC = () => {
  const location = useLocation();
  const params: { jobId: string } = useParams();
  const history = useHistory();

  const { jobId } = params;

  return (
    <>
      <Header {...{ history, jobId, title: 'Marketing' }} />

      <main className="container flex">
        <SideBar path={location.pathname} jobId={jobId} />

        <section className="basis-9/12 md:basis-full">
          <Switch>
            <Redirect exact from="/jobs/:jobId/marketing" to={`/jobs/:jobId/marketing/${MARKETING_TIMELINE_ENABLED ? 'getting-started' : 'flyers'}`} />
            <Route path="/jobs/:jobId/marketing/getting-started" component={GettingStarted} />
            <Route path="/jobs/:jobId/marketing/active-messages" component={ActiveMessages} />
            <Route exact path="/jobs/:jobId/marketing/flyers" component={Flyers} />
            <Route path="/jobs/:jobId/marketing/flyers/:flyerId/design" component={FlyersDesign} />
            <Route path="/jobs/:jobId/marketing/flyers/:flyerId/history" component={FlyersHistory} />
            <Route path="/jobs/:jobId/marketing/campaign-settings" component={CampaignSettings} />
          </Switch>
        </section>
      </main>
    </>
  );
};

export default Marketing;
