import { FC, useRef, useEffect, useState, ChangeEvent, FormEvent } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { updateJob } from '../../actions';

// Plugins
import DatePicker from 'react-datepicker';

// Components
import Sidebar from '../Sidebar';
import Header from '../../Header';
import GridLoader from '@/components/Shared/ContentLoader/GridLoader';
import InputCharacterRemainer from '@/components/Shared/InputCharacterRemainer';

// Helpers
import { shortDate } from '@/utils/displayFormats';

// Styles
import './style.css';

// Types
import { Job } from '@/types';

const INPUT_MAX_LENGTH: number = 32;
const TEXTAREA_MAX_LENGTH: number = 280;

const YearbookSelection: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location: { state: { sendBack?: boolean }; pathname: string } = useLocation();

  const jobInfoSnapshotRef = useRef<Partial<Job> | null>(null);

  const params: { jobId: string } = useParams();
  const { jobId } = params;

  const { job } = useSelector((state: any) => state.jobs);

  // State
  const [jobInfoState, setJobInfoState] = useState<Partial<Job>>();

  // UI Handlers
  const handleJobInfoChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>): void => {
    const targetName = target.name;
    const targetValue = target.type === 'checkbox' ? target.checked : target.value;

    setJobInfoState((prevState?: Partial<Job>) => ({ ...prevState, [targetName]: targetValue }));
  };

  const handleJobDateChange = (date: Date | null): void => {
    setJobInfoState((prevState?: Partial<Job>) => ({ ...prevState, yearbook_selection_deadline: date ?? undefined }));
  };

  const handleSave = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    dispatch(
      updateJob(
        {
          id: jobId,
          ...jobInfoState,
          yearbook_selection_photo_count: Number(jobInfoState?.yearbook_selection_photo_count) // Coerce to number
        },
        () => location.state?.sendBack && history.goBack()
      )
    );

    // Update the initial snapshot
    jobInfoSnapshotRef.current = { ...jobInfoState };
  };

  useEffect(() => {
    if (job?.id) {
      const initialYearbookInfo = {
        yearbook_selection_enabled: job.yearbook_selection_enabled ?? false,
        yearbook_selection_deadline: job.yearbook_selection_deadline,
        yearbook_selection_photo_count: job.yearbook_selection_photo_count ?? 1,
        yearbook_selection_photo_1_title: job.yearbook_selection_photo_1_title ?? '',
        yearbook_selection_photo_2_title: job.yearbook_selection_photo_2_title ?? '',
        yearbook_selection_instructions: job.yearbook_selection_instructions ?? ''
      };

      // Set initial state hydration
      setJobInfoState(initialYearbookInfo);
      jobInfoSnapshotRef.current = initialYearbookInfo;
    }
  }, [job?.id]);

  return (
    <>
      <Header {...{ history, jobId, title: 'Settings' }} />

      <main className="container flex job-settings__yearbook">
        <Sidebar jobType={job?.access_mode} jobId={jobId} path={location?.pathname} />

        <form className="basis-9/12 md:basis-full" onSubmit={handleSave}>
          <header className="flex items-center justify-between job-settings-yearbook__header">
            <h2 className="text-headline-sm">Yearbook Selection</h2>
            <button
              type="submit"
              className="button button--medium"
              disabled={job?.requesting || JSON.stringify(jobInfoSnapshotRef.current) === JSON.stringify(jobInfoState)}
            >
              Save
            </button>
          </header>
          <section className="panel">
            {job?.requesting ? (
              <GridLoader rows={3} columns={1} gap={20} minHeight={100} />
            ) : (
              <>
                <header className="flex justify-between items-center mb-5">
                  <h4>Enable Yearbook Selection</h4>
                  <input
                    id="yearbookSelectionEnable"
                    className="hidden"
                    name="yearbook_selection_enabled"
                    type="checkbox"
                    checked={!!jobInfoState?.yearbook_selection_enabled}
                    onChange={handleJobInfoChange}
                  />
                  <label className="label-switch" htmlFor="yearbookSelectionEnable" />
                </header>

                <p className="mb-7">
                  Allow your customers to select their preferred photo(s) for their school's yearbook. Add Instructions, set the number of photos, add names for
                  the selections, and set a deadline for completion.{' '}
                  <a href="https://support.photoday.io/en/articles/9503825-yearbook-selection" target="_blank" rel="noopener noreferrer">
                    Learn More
                  </a>
                  .
                </p>

                <div className={jobInfoState?.yearbook_selection_enabled ? '' : 'disabled'}>
                  <h4>Settings</h4>
                  <fieldset className="mb-5">
                    <label htmlFor="yearbookPoses"># of Required Yearbook Selections</label>
                    <div className="flex items-center mb-2.5">
                      <input
                        id="yearbookNumPosesOne"
                        type="radio"
                        name="yearbook_selection_photo_count"
                        value={1}
                        checked={Number(jobInfoState?.yearbook_selection_photo_count) === 1}
                        onChange={handleJobInfoChange}
                      />
                      <label htmlFor="yearbookNumPosesOne" className="label--clean text--normal capitalize">
                        1
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="yearbookNumPosesTwo"
                        type="radio"
                        name="yearbook_selection_photo_count"
                        value={2}
                        checked={Number(jobInfoState?.yearbook_selection_photo_count) === 2}
                        onChange={handleJobInfoChange}
                      />
                      <label htmlFor="yearbookNumPosesTwo" className="label--clean text--normal capitalize">
                        2
                      </label>
                    </div>
                  </fieldset>

                  <fieldset className="basis-6/12 mb-2.5">
                    <label htmlFor="yearbookPoseOneLabel">Label Yearbook Selection 1</label>
                    <input
                      id="earbookPoseOneLabel"
                      type="text"
                      name="yearbook_selection_photo_1_title"
                      value={jobInfoState?.yearbook_selection_photo_1_title ?? ''}
                      maxLength={32}
                      placeholder="Formal Pose"
                      onChange={handleJobInfoChange}
                    />
                    <InputCharacterRemainer
                      inputCharsCount={Number(jobInfoState?.yearbook_selection_photo_1_title?.length ?? 0)}
                      inputMaxLength={INPUT_MAX_LENGTH}
                    />
                  </fieldset>
                  {Number(jobInfoState?.yearbook_selection_photo_count) === 2 && (
                    <fieldset className="basis-6/12 mb-2.5 animate">
                      <label htmlFor="yearbookPoseTwoLabel">Label Yearbook Selection 2</label>
                      <input
                        id="yearbookPoseTwoLabel"
                        type="text"
                        name="yearbook_selection_photo_2_title"
                        value={jobInfoState?.yearbook_selection_photo_2_title ?? ''}
                        maxLength={32}
                        placeholder="Informal Pose"
                        onChange={handleJobInfoChange}
                      />
                      <InputCharacterRemainer
                        inputCharsCount={Number(jobInfoState?.yearbook_selection_photo_2_title?.length ?? 0)}
                        inputMaxLength={INPUT_MAX_LENGTH}
                      />
                    </fieldset>
                  )}

                  <fieldset className="basis-6/12 mb-5">
                    <label htmlFor="yearbookDeadline">Deadline</label>
                    <DatePicker
                      id="yearbookDeadline"
                      className="input--date"
                      name="yearbook_selection_deadline"
                      selected={shortDate(jobInfoState?.yearbook_selection_deadline)}
                      startDate={new Date()}
                      minDate={new Date()}
                      isClearable={true}
                      placeholderText="MM/DD/YYYY"
                      onChange={handleJobDateChange}
                    />
                  </fieldset>

                  <fieldset className="basis-6/12 mb-0">
                    <label htmlFor="yearbookInstructions">Customer Instructions (Optional)</label>
                    <textarea
                      id="yearbookInstructions"
                      className="whitespace-pre-line"
                      name="yearbook_selection_instructions"
                      rows={6}
                      maxLength={TEXTAREA_MAX_LENGTH}
                      value={jobInfoState?.yearbook_selection_instructions ?? ''}
                      placeholder={`Example Instructions:
                  Only two photo selections are required.

                  Make sure subject is cropped with only showing upper body. No standing poses.

                  Cap and gown photos only. No hats, suits, blouses, etc.`}
                      onChange={handleJobInfoChange}
                    />
                    <InputCharacterRemainer
                      inputCharsCount={Number(jobInfoState?.yearbook_selection_instructions?.length ?? 0)}
                      inputMaxLength={TEXTAREA_MAX_LENGTH}
                    />
                  </fieldset>
                </div>
              </>
            )}
          </section>
        </form>
      </main>
    </>
  );
};

export default YearbookSelection;
