import { call, put, takeLatest, all } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { change } from 'redux-form';

import parseNormalizeListResponse from '@/utils/responseParsers';
import errorHandler from '@/utils/errorResponseHandler';
import successHandler from '@/utils/successResponseHandler';
import * as api from '@/api/offer-api';
import offerSchema from './schema';
import { offerValuesByType } from './selectors';

import {
  OFFER_LIST_REQUEST,
  OFFER_GENERATE_CODE_REQUEST,
  CREATE_OFFER_REQUEST,
  UPDATE_OFFER_REQUEST,
  DESTROY_OFFER_REQUEST,
  PAUSE_OFFER_REQUEST,
  UNPAUSE_OFFER_REQUEST
} from './constants';

import {
  offerListRequestSuccess,
  offerListRequestError,
  offerGenerateCodeRequestSuccess,
  offerGenerateCodeRequestError,
  createOfferRequestSuccess,
  createOfferRequestError,
  updateOfferRequestSuccess,
  updateOfferRequestError,
  destroyOfferRequestSuccess,
  destroyOfferRequestError,
  pauseOfferRequestSuccess,
  pauseOfferRequestError,
  unpauseOfferRequestSuccess,
  unpauseOfferRequestError
} from './actions';

function* getOfferListRequestFlow(action) {
  try {
    yield put(showLoading());

    const { callback } = action;
    const { page, perPage, search, order, dir, searchField, full } = action.payload;
    const response = yield call(api.offerList, page, perPage, search, order, dir, null, searchField, full);
    const payload = parseNormalizeListResponse(response, offerSchema, order, dir, search, null, searchField, full);

    yield put(offerListRequestSuccess(payload));

    if (callback) callback(payload);
  } catch (error) {
    yield call(errorHandler, offerListRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* generateOfferCodeRequestFlow({ payload }) {
  try {
    yield put(showLoading());

    const {
      data: { code }
    } = yield call(api.generateCode, payload);
    yield put(offerGenerateCodeRequestSuccess(code));
    yield put(change('OfferForm', 'code', code, true));
  } catch (error) {
    yield call(errorHandler, offerGenerateCodeRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* createOfferRequestFlow(action) {
  try {
    yield put(showLoading());

    const {
      payload: { studioId, offer, callback }
    } = action;
    const { data: newOffer } = yield call(api.createOffer, {
      studioId,
      offer: offerValuesByType(offer)
    });

    yield call(successHandler('Offer successfully created'), createOfferRequestSuccess, {
      studioId,
      offer: newOffer
    });
    if (callback) callback(newOffer);
  } catch (error) {
    yield call(errorHandler, createOfferRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* updateOfferRequestFlow(action) {
  try {
    yield put(showLoading());

    const {
      payload: { offer, callback }
    } = action;
    const { data: updatedOffer } = yield call(api.updateOffer, { offer: offerValuesByType(offer) });

    yield call(successHandler('Offer successfully updated'), updateOfferRequestSuccess, { offer: updatedOffer });

    if (callback) yield call(callback, updatedOffer);
  } catch (error) {
    yield call(errorHandler, updateOfferRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* deleteOfferRequestFlow({ payload }) {
  try {
    yield put(showLoading());

    const { offerId, callback } = payload;

    yield call(api.deleteOffer, payload);
    yield call(successHandler('Offer successfully deleted'), destroyOfferRequestSuccess, payload);

    if (callback) yield call(callback, { offerId });
  } catch (error) {
    yield call(errorHandler, destroyOfferRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* pauseOfferRequestFlow(action) {
  try {
    yield put(showLoading());

    const {
      payload: { id }
    } = action;
    const { data } = yield call(api.pauseOffer, id);
    yield put(pauseOfferRequestSuccess(data));
  } catch (error) {
    yield call(errorHandler, pauseOfferRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* unpauseOfferRequestFlow(action) {
  try {
    yield put(showLoading());

    const {
      payload: { id }
    } = action;
    const { data } = yield call(api.unpauseOffer, id);
    yield put(unpauseOfferRequestSuccess(data));
  } catch (error) {
    yield call(errorHandler, unpauseOfferRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getOffersWatcher() {
  yield all([
    takeLatest(OFFER_LIST_REQUEST, getOfferListRequestFlow),
    takeLatest(OFFER_GENERATE_CODE_REQUEST, generateOfferCodeRequestFlow),
    takeLatest(CREATE_OFFER_REQUEST, createOfferRequestFlow),
    takeLatest(UPDATE_OFFER_REQUEST, updateOfferRequestFlow),
    takeLatest(DESTROY_OFFER_REQUEST, deleteOfferRequestFlow),
    takeLatest(PAUSE_OFFER_REQUEST, pauseOfferRequestFlow),
    takeLatest(UNPAUSE_OFFER_REQUEST, unpauseOfferRequestFlow)
  ]);
}

export default getOffersWatcher;
