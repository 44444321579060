import React from 'react';
import PropTypes from 'prop-types';

import Skeleton from '@/components/Shared/Skeleton';

const TableLoadingState = ({ rows = 5, rowOptions = {}, cells = 1, cellOptions = {} }) => {
  return Array(rows)
    .fill(null)
    .map((_, row) => (
      <tr key={row} {...rowOptions}>
        <td {...cellOptions} colSpan={cells}>
          <Skeleton height="20px" width="100%" />
        </td>
      </tr>
    ));
};

TableLoadingState.propTypes = {
  rows: PropTypes.number,
  rowOptions: PropTypes.object,
  cells: PropTypes.number,
  cellOptions: PropTypes.object
};

export default TableLoadingState;
