import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { shortDate } from '@/utils/displayFormats';
import icon_order_received from '@/assets/images/icon_order_received.png';
import icon_new from '@/assets/images/icon_new.png';

const NotificationItem = ({ item }) => {
  const { type, description, date } = item;
  const icons = {
    order: icon_order_received,
    new: icon_new
  };
  const icon = (type) => icons[type];
  const formattedDate = shortDate(date);

  return (
    <tr>
      <td>
        <img src={icon(type)} className="table-icon" alt="notification_icon" />
      </td>
      <td>{description}</td>
      <td className="text-right">{formattedDate}</td>
    </tr>
  );
};

NotificationItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    date: PropTypes.instanceOf(moment)
  })
};

export default NotificationItem;
