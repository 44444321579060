import React from 'react';

// Plugins
import moment from 'moment';
import Select from 'react-select';
import { Tooltip } from 'react-tippy';

// Styles
import '../style.css';

const AdvancePay = function AdvancePay({
  information,
  jobAdvancePayOffer,
  optionsOffers,
  giftCardSheets,
  offersDDLRequesting,
  advancePayRequesting,

  onSelectChange,
  onSwitchChange,
  onOffersRefresh,
  onSectionSelect,
  onCreditSheetsRefresh
}) {
  // Check first if job have an ap offer so there is a possibility that the
  // job ap offer is inactive and filtered out from the list.
  const getSelectedOffer = () => {
    if (!information.advancePayOfferId) return null;

    return (
      optionsOffers?.find((offer) => offer.value === information.advancePayOfferId) || {
        ...jobAdvancePayOffer,
        label: jobAdvancePayOffer?.name,
        value: jobAdvancePayOffer?.id,
        apIncentive: jobAdvancePayOffer?.ap_incentive
      }
    );
  };

  return (
    <>
      <section className="panel panel--section animate">
        <fieldset className="job-settings-store__row">
          <h3 className="text-headline-xs">Credit Sheet</h3>
          <p className="job-settings-store__sub-title">Offer AdvancePay credits in this job by adding a credit sheet.</p>

          <div className="flex justify-start items-center">
            <div className="job-settings-store__select-container">
              <Select
                className="select select--nomargin job-settings-store__select"
                classNamePrefix="select"
                isClearable={true}
                isLoading={advancePayRequesting}
                value={Object.values(giftCardSheets)
                  .filter((card) => card.id === information.giftCardSheetId)
                  .map((card) => ({ value: card.id, label: card.name }))}
                options={Object.values(giftCardSheets).map((card) => ({ value: card.id, label: card.name }))}
                onChange={(value) => onSelectChange(value, 'giftCardSheetId')}
              />
              <button className="button button--clean" type="button" onClick={onCreditSheetsRefresh}>
                <i className="icon-refresh"></i>
              </button>
            </div>
            <span className="job-settings-store__select-link">
              or{' '}
              <a href="/storefront/advance-pay" target="_blank" rel="noopener noreferrer">
                create a new credit sheet
              </a>
            </span>
          </div>
        </fieldset>

        <fieldset className="job-settings-store__row">
          <h3 className="text-headline-xs">
            AdvancePay Offer
            <Tooltip
              title="Make sure that the AdvancePay Offer expiration date is beyond the gallery publish date to give customers time to purchase."
              position="top"
              arrow="true"
            >
              <span className="job-settings-store__select-tip">Tip</span>
            </Tooltip>
          </h3>
          <p className="job-settings-store__sub-title">Add an offer with AdvancePay and incentivize customers to purchase.</p>

          <div className="flex justify-start items-center">
            <div className="job-settings-store__select-container">
              <Select
                className="select select--nomargin job-settings-store__select"
                classNamePrefix="select"
                isClearable={true}
                isLoading={offersDDLRequesting}
                value={getSelectedOffer()}
                options={optionsOffers}
                onChange={(value) => onSelectChange(value, 'advancePayOfferId')}
              />
              <button className="button button--clean" type="button" onClick={onOffersRefresh}>
                <i className="icon-refresh"></i>
              </button>
            </div>
            <span className="job-settings-store__select-link">
              or{' '}
              <a href="/storefront/offers-and-credits" target="_blank" rel="noopener noreferrer">
                add a new AdvancePay offer
              </a>
            </span>
          </div>
          <div className="text-body-sm job-settings-store__offer-preview">
            {getSelectedOffer()
              ? `Get ${getSelectedOffer().description?.toLowerCase()} Limit: ${getSelectedOffer().max_redemptions || 'none'}. Expires: ${
                  getSelectedOffer().expiration ? moment(getSelectedOffer().expiration).format('MM/DD/YY') : 'none'
                }`
              : ''}
          </div>
        </fieldset>

        <fieldset className="job-settings-store__row">
          <figure className="job-settings-store__switch-group m-0">
            <figcaption>
              <h3 className="text-headline-xs">Customer Data</h3>
              <p>Require the customer to add the name of the subject being photographed to the AdvancePay credit.</p>
            </figcaption>
            <div className="job-settings-store__switch">
              <input
                id="advancepayRequireSubject"
                className="hidden"
                name="advancepayRequireSubject"
                type="checkbox"
                checked={information.advancepayRequireSubject}
                onChange={onSwitchChange}
              />
              <label className="label-switch" htmlFor="advancepayRequireSubject" />
            </div>
          </figure>
        </fieldset>

        <fieldset className="job-settings-store__row m-0">
          <div className="flex job-settings-store__fieldset-header">
            <h3 className="text-headline-xs">Preview Storefront</h3>

            {!information.priceSheetId && (
              <p className="job-settings-inline m-0">
                <button className="button button--link" type="button" onClick={() => onSectionSelect('products')}>
                  Add a price sheet
                </button>{' '}
                to preview the storefront.
              </p>
            )}
          </div>

          <figure className="job-settings-store__switch-group m-0">
            <figcaption>
              <p className={`${information.priceSheetId ? '' : 'job-settings-store__disabled'}`}>
                When enabled, customers who view <strong>AdvancePay</strong> galleries will see a preview of the products and packages you’ll be selling. A
                price sheet is required.
              </p>
            </figcaption>
            <div className={`job-settings-store__switch ${information.priceSheetId ? '' : 'job-settings-store__disabled'}`}>
              <input
                id="previewStore"
                className="hidden"
                name="previewStore"
                type="checkbox"
                checked={information.previewStore}
                onChange={onSwitchChange}
                disabled={!information.priceSheetId}
              />
              <label className="label-switch" htmlFor="previewStore" />
            </div>
          </figure>
        </fieldset>
      </section>
    </>
  );
};

export default AdvancePay;
