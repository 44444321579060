const validator = ({ name, rule, value }) => {
  const validation = {
    maxLength: Number(rule) >= value.length ? true : false,
    minLength: Number(rule) <= value.length ? true : false,
    maxNum: Number(rule) >= Number(value) ? true : false,
    minNum: Number(rule) <= Number(value) ? true : false
  };

  return name ? validation[name] : true;
};

export default validator;
