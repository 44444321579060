import * as types from './constants';

export const shippingListRequest = ({ labId, page, perPage, order, dir, search }) => ({
  type: types.SHIPPING_LIST_REQUEST,
  payload: {
    labId,
    page,
    perPage,
    order,
    dir,
    search
  }
});

export const shippingListRequestSuccess = ({ entities, pager, result }) => ({
  type: types.SHIPPING_LIST_SUCCESS,
  payload: {
    entities,
    pager,
    result
  }
});

export const shippingListRequestError = (error) => ({
  type: types.SHIPPING_LIST_ERROR,
  payload: {
    error
  }
});

export const getShippingSummary = (payload, callback) => ({
  type: types.GET_SHIPPING_SUMMARY_REQUEST,
  payload: { payload, callback }
});

export const getShippingSummarySuccess = (payload) => ({
  type: types.GET_SHIPPING_SUMMARY_SUCCESS,
  payload: { payload }
});

export const getShippingSummaryError = (error) => ({
  type: types.GET_SHIPPING_SUMMARY_ERROR,
  payload: { error }
});

export const getBulkShippingOrderList = (payload, callback) => ({
  type: types.GET_BULK_SHIPPING_ORDER_LIST_REQUEST,
  payload: { payload, callback }
});

export const getBulkShippingOrderListSuccess = (payload) => ({
  type: types.GET_BULK_SHIPPING_ORDER_LIST_SUCCESS,
  payload: { payload }
});

export const getBulkShippingOrderListError = (error) => ({
  type: types.GET_BULK_SHIPPING_ORDER_LIST_ERROR,
  payload: { error }
});

export const getBulkShippingOrder = (payload, callback) => ({
  type: types.GET_BULK_SHIPPING_ORDER_REQUEST,
  payload: { payload, callback }
});

export const getBulkShippingOrderSuccess = (payload) => ({
  type: types.GET_BULK_SHIPPING_ORDER_SUCCESS,
  payload: { payload }
});

export const getBulkShippingOrderError = (error) => ({
  type: types.GET_BULK_SHIPPING_ORDER_ERROR,
  payload: { error }
});
