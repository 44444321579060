import { PureComponent } from 'react';
import { Redirect, Switch } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Components
import Header from './Header';
import OrdersRoute from './Orders';
import PromosRoute from './Promos';
import OffersRoute from './Offers';
import TopNav from '@/components/Shared/TopNav';
import QuicktipsRoute from './Quicktips';
import AdvancePayRoute from './AdvancePay';
import PriceSheetsRoute from './PriceSheets';
import CustomThemesRoute from './CustomThemes';
import BackgroundsRoute from './Backgrounds';
import PrivateRoute from '@/components/Shared/PrivateRoute';
import OrderDetailRoute from './Orders/order-detail';
import AdvancePayGiftRoute from './AdvancePay/detail';
import OffersAndCreditsRoute from './OffersAndCredits';
import PriceSheetsDetailRoute from './PriceSheets/detail';
import AdvancePayGiftCardRoute from './Orders/Credits/credit-detail';

// Styles
import './styles.css';

const mapStateToProps = (state, ownProps) => {
  const { pathname: path } = ownProps.location;
  const { user, entities } = state.login;

  const studio = Object.values(entities.studio)[0] || {};
  const { id: studioId, feature_flags: studioFeatureFlags, labs: studioLabs } = studio;

  return { path, user, studioId, studioFeatureFlags, studioLabs };
};

class Storefront extends PureComponent {
  render() {
    const { path, user, studioId, studioFeatureFlags, studioLabs } = this.props;

    return (
      <>
        <TopNav />
        <section className="storefront">
          <Header path={path} user={user} studioId={studioId} studioFeatureFlags={studioFeatureFlags} studioLabs={studioLabs} />

          <div className="container">
            <Switch>
              <Redirect exact from="/storefront" to="/storefront/quicktips" />
              <PrivateRoute exact path="/storefront/quicktips" component={QuicktipsRoute} />
              <PrivateRoute exact path="/storefront/orders" component={OrdersRoute} requiredPermission={'view_sales'} />
              <PrivateRoute exact path="/storefront/orders/:orderId" component={OrderDetailRoute} requiredPermission={'view_sales'} />
              <PrivateRoute exact path="/storefront/price-sheets" component={PriceSheetsRoute} requiredPermission={'view_price_sheets'} />
              <PrivateRoute exact path="/storefront/price-sheets/:pricesheetId" component={PriceSheetsDetailRoute} requiredPermission={'manage_price_sheets'} />
              <PrivateRoute exact path="/storefront/offers-and-credits" component={OffersAndCreditsRoute} />
              <PrivateRoute exact path="/storefront/offers-and-credits/:offerType" component={OffersRoute} requiredPermission={'view_offers'} />
              <PrivateRoute exact path="/storefront/advance-pay" component={AdvancePayRoute} requiredPermission={'view_gift_cards'} />
              <PrivateRoute exact path="/storefront/advance-pay/:creditsheetId" component={AdvancePayGiftRoute} requiredPermission={'manage_gift_cards'} />
              <PrivateRoute
                exact
                path="/storefront/orders/advance-pay/credits/:gift_card_id"
                component={AdvancePayGiftCardRoute}
                requiredPermission={'manage_gift_cards'}
              />
              <PrivateRoute exact path="/storefront/promos" component={PromosRoute} requiredPermission={'view_promos'} />
              <PrivateRoute exact path="/storefront/custom-themes" component={CustomThemesRoute} requiredPermission={'view_price_sheets'} />
              <PrivateRoute exact path="/storefront/backgrounds" component={BackgroundsRoute} requiredPermission={'view_price_sheets'} />
            </Switch>
          </div>
        </section>
      </>
    );
  }
}

export default connect(mapStateToProps)(Storefront);
