import { call, all, put, takeLatest } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import errorHandler from '@/utils/errorResponseHandler';
import { GET_OFFERS_DDL_REQUEST } from './constants';
import { CREATE_OFFER_SUCCESS } from '../../Storefront/Offers/constants';
import { getOffersDDLRequestSuccess, getOffersDDLRequestError } from './actions';
import { offersDDL } from '@/api/offer-api';

const parseResponse = (response) => {
  const { data } = response;

  return data
    .filter((offer) => offer.active)
    .map((offer) => {
      const { name: label, id: value, ap_incentive: apIncentive } = offer;

      return Object.assign({}, { label, value, apIncentive, ...offer });
    });
};

function* getOffersDDLRequestFlow() {
  try {
    yield put(showLoading());

    const response = yield call(offersDDL);
    const options = parseResponse(response);

    yield put(getOffersDDLRequestSuccess(options));
    yield;
  } catch (error) {
    yield call(errorHandler, getOffersDDLRequestError, error);
  } finally {
    yield put(hideLoading());
  }
}

function* getOffersDDLWatcher() {
  yield all([yield takeLatest(GET_OFFERS_DDL_REQUEST, getOffersDDLRequestFlow), yield takeLatest(CREATE_OFFER_SUCCESS, getOffersDDLRequestFlow)]);
}

export default getOffersDDLWatcher;
