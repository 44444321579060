export const SHIPPING_LIST_REQUEST = 'SHIPPING_LIST_REQUEST';
export const SHIPPING_LIST_SUCCESS = 'SHIPPING_LIST_SUCCESS';
export const SHIPPING_LIST_ERROR = 'SHIPPING_LIST_ERROR';

export const GET_SHIPPING_SUMMARY_REQUEST = 'GET_SHIPPING_SUMMARY_REQUEST';
export const GET_SHIPPING_SUMMARY_SUCCESS = 'GET_SHIPPING_SUMMARY_SUCCESS';
export const GET_SHIPPING_SUMMARY_ERROR = 'GET_SHIPPING_SUMMARY_ERROR';

export const GET_BULK_SHIPPING_ORDER_LIST_REQUEST = 'GET_BULK_SHIPPING_ORDER_LIST_REQUEST';
export const GET_BULK_SHIPPING_ORDER_LIST_SUCCESS = 'GET_BULK_SHIPPING_ORDER_LIST_SUCCESS';
export const GET_BULK_SHIPPING_ORDER_LIST_ERROR = 'GET_BULK_SHIPPING_ORDER_LIST_ERROR';

export const GET_BULK_SHIPPING_ORDER_REQUEST = 'GET_BULK_SHIPPING_ORDER_REQUEST';
export const GET_BULK_SHIPPING_ORDER_SUCCESS = 'GET_BULK_SHIPPING_ORDER_SUCCESS';
export const GET_BULK_SHIPPING_ORDER_ERROR = 'GET_BULK_SHIPPING_ORDER_ERROR';
