import React from 'react';
import PropTypes from 'prop-types';

const EmptyState = ({ roles, type, requesting, isEmpty }) => {
  const orderEmptyState = () => {
    if (!isEmpty || requesting) return null;

    return (
      <tr className="orders-empty">
        <td colSpan="2" className="empty-table">
          <h4 className="text-center text--normal">No orders have been placed</h4>
        </td>
      </tr>
    );
  };

  const jobEmptyState = () => {
    if (!isEmpty) return null;

    return (
      <tr className="jobs-empty">
        <td colSpan="3" className="empty-table">
          <h4 className="text-center text--normal">You have no upcoming jobs</h4>
        </td>
      </tr>
    );
  };

  const salesEmptyState = () => {
    if (!isEmpty) return null;

    return (
      <tr className="sales-empty">
        <td colSpan="3">
          <p className="text-center" />
        </td>
      </tr>
    );
  };

  const renderEmptyState = () => {
    switch (type) {
      case 'order':
        return orderEmptyState();
      case 'job':
        return jobEmptyState();
      case 'sales':
        return salesEmptyState();
      default:
        return null;
    }
  };

  return renderEmptyState();
};

EmptyState.propTypes = {
  roles: PropTypes.array,
  type: PropTypes.string.isRequired,
  requesting: PropTypes.bool,
  isEmpty: PropTypes.bool.isRequired
};

export default EmptyState;
