import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector, Field } from 'redux-form';

import { Input } from '@/components/Shared/Forms';
import matchValidatePropToValidators from '@/components/Shared/Forms/validators';

const mapStateToProps = (state, ownProps) => {
  const { name, productCategories } = ownProps;
  const productId = formValueSelector('OfferForm')(state, `${name}.id`);

  let products = [];
  let selectedCategory;

  if (productCategories) {
    const selected = productCategories.find(({ products }) => products.map(({ value }) => value).includes(productId)) || {};

    selectedCategory = selected.value;
    products = selected.products || [];
  }

  return { products, selectedCategory, productCategories };
};

const ProductSelector = ({
  name,
  index,
  onRemove,
  pricesheetItems = [],
  products: initialProducts,
  selectedCategory: initialSelectedCategory,
  productCategories
}) => {
  const [products, setProducts] = useState(initialProducts);
  const [selectedCategory, setSelectedCategory] = useState(initialSelectedCategory);

  const renderSelect = ({ input, meta: { touched, error } }) => {
    const onChange = (evt) => {
      input.onChange(null);
      onChangeSelect(evt);
    };

    return (
      <div>
        <div className="basis-2/6">
          <div>
            <label>Select Category</label>
            <div className="select-caret">
              <select onChange={onChange} value={selectedCategory} className="input--block">
                <option value=""> Select... </option>
                {productCategories.map(({ value, label }) => (
                  <option key={`category-${name}-${value}`} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="basis-5/12">
          <div>
            <label>Select Product</label>
            <div className="select-caret">
              <select {...input} className="input--block">
                <option value=""> Select... </option>
                {products.map(({ value, label }) => (
                  <option key={`select-${name}-${value}`} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
            {touched && error && <small className="block text-error-500">{error}</small>}
          </div>
        </div>
        <div className="basis-2/12">
          <Input
            validate={matchValidatePropToValidators({ name, index, onRemove, pricesheetItems })}
            name={`${name}[quantity]`}
            label="Qty"
            required={true}
            type="number"
          />
        </div>
        {index > 0 && (
          <div className="basis-1/12" style={{ marginTop: '27px' }}>
            <button className="button button--dark" onClick={onRemove}>
              <i className="fa fa-trash-o" aria-hidden="true" />
            </button>
          </div>
        )}
      </div>
    );
  };

  const onChangeSelect = ({ target: { value } }) => {
    const selected = productCategories.find(({ value: id }) => id === value);
    setProducts((selected || {}).products || []);
    setSelectedCategory(value);
  };

  return (
    <div>
      <Field validate={matchValidatePropToValidators({ name, index, onRemove, pricesheetItems })} name={`${name}[id]`} component={renderSelect} />
    </div>
  );
};

ProductSelector.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
  pricesheetItems: PropTypes.array
};

export default connect(mapStateToProps)(ProductSelector);
